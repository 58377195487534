export const pad = (string, digits) => ('0'.repeat(digits - 1) + string.toString()).slice(-digits);

export const validateTimeInput = (e) => {
  const value = e.target.value;
  const replacedValue = value.replace(/[^0-9:]/g, "");
  const timeParts = replacedValue.split(":");
  if (timeParts.length > 4) return null;

  const hhh = timeParts[0], mmm = timeParts[1], sss = timeParts[2], mss = timeParts[3]

  if (hhh.length > 2) return null;
  if (mmm && mmm.length > 2) return null;
  if (sss && sss.length > 2) return null;
  if (mss && mss.length > 3) return null;

  const hh = parseInt(hhh, 10);
  const mm = parseInt(mmm, 10);
  const ss = parseInt(sss, 10);
  const ms = parseInt(mss, 10);

  if (hh > 99) return null;
  if (mm > 59) return null;
  if (ss > 59) return null;
  if (ms > 999) return null;

  return replacedValue;
};