import React, { Component } from "react";
import Navbar from './Navbar';
import './App.css';
import detectBrowserLanguage from 'detect-browser-language';
import { HashRouter as Router } from 'react-router-dom';
import Main from "./containers/Main";
import ReactGA from 'react-ga4';
import { Provider } from "react-redux";
import { configureStore } from "./store";
import { setAuthorizationToken, setCurrentUser, logout } from "./store/actions/auth";
import { setUserLanguage,setSidebarStatus } from "./store/actions/users";
import jwtDecode from "jwt-decode";
import GlobalTopBar from "./components/global-navbar/GlobalTopBar";
const store = configureStore();
const browserLang = detectBrowserLanguage().toLowerCase();

let language = "en";
if (browserLang.indexOf("en") < 0) {
  language = "es";
} else if (browserLang.indexOf("es") < 0) {
  language = "en";
}

if (localStorage.enginJwtToken) {
  setAuthorizationToken(localStorage.enginJwtToken);
  // prevent someone from manually tampering with the key of jwtToken in localStorage
  try {
    const decodedJwt = jwtDecode(localStorage.enginJwtToken);
    let allowedDate = new Date(decodedJwt.iat * 1000);
    allowedDate.setDate(allowedDate.getDate() + 30);
    if ((!decodedJwt.exp && (allowedDate < Date.now())) || decodedJwt.exp * 1000 < Date.now()) {
      store.dispatch(logout());
      store.dispatch(setCurrentUser({}))
    } else {
      store.dispatch(setCurrentUser(jwtDecode(localStorage.enginJwtToken)));
    }
  } catch (e) {
    store.dispatch(setCurrentUser({}));
  }
}


if (localStorage.language) {
  store.dispatch(setUserLanguage(localStorage.language));
} else {
  store.dispatch(setUserLanguage(language));
}

if (localStorage.isSidebarExpanded) {
  let isExpanded = true;
  if(localStorage.isSidebarExpanded === 'false'){
    isExpanded = false;
  }
  store.dispatch(setSidebarStatus(isExpanded));
} else {
  store.dispatch(setSidebarStatus(true));
}

class App extends Component {
  constructor(props) {
    super(props);
  }


  componentDidMount() {
    ReactGA.initialize('G-SLC5KLG37Z');
  }

  render() {

    return (
      <Provider store={store}>
        <div className="App">
          <Router>
            {process.env.REACT_APP_USE_GLOBAL_NAV === 'true' ?  (
              <GlobalTopBar browserLang={language} />
            ) : (
              <Navbar browserLang={language} />
            )}
            <Main browserLang={language} />
          </Router>
        </div>
      </Provider>
    );
  }
}

export default App;
