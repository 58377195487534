import React, { useState, useEffect } from 'react';

import {
    Button,
    Container,
    Divider,
    Grid,
    Header,
    Icon,
    Image,
    Message,
    Pagination,
    Segment
} from 'semantic-ui-react';

import UploadFolders from './UploadFolders';
import Upload from '../../images/upload-files.svg';
import Directory from '../../images/directory.svg';

import { useCreateProjectContext } from '../../context/CreateProjectContext';

const UploadFiles = ({ handlePrevStep, handleNextStep }) => {
    const {
        selectedFiles,
        updateSelectedFiles,
        uploadComplete,
        updateUploadComplete,
        errorMessages,
        updateErrorMessages,
        fileStructure,
        updateFileStructure,
        currentPage,
        updateCurrentPage,
        currentPath,
        updateCurrentPath
    } = useCreateProjectContext();

    const [dragging, setDragging] = useState(false);
    const [foldersUploaded, setFoldersUploaded] = useState(false);
    const [confirmed, setConfirmed] = useState(false);

    const filesPerPage = 20;
    const [currentFolderPage, setCurrentFolderPage] = useState(1);
    const [currentFolderFilesPerPage] = useState(20);


    useEffect(() => {
        if (selectedFiles.length > 0) {
            updateUploadComplete(true);
        }
    }, [selectedFiles, updateUploadComplete]);

    const validFileTypes = ['video/mp4', 'video/x-msvideo', 'image/jpeg', 'image/png', 'application/xml'];

    const isValidFileType = (file) => validFileTypes.includes(file.type);

    const addErrorMessage = (message) => {
        updateErrorMessages(prevMessages => [...prevMessages, message]);
        setTimeout(() => {
            updateErrorMessages(prevMessages => prevMessages.filter(msg => msg !== message));
        }, 3000);
    };

    const handleFiles = (files) => {
        const newFiles = files.filter(isValidFileType);
        const invalidFiles = files.filter(file => !isValidFileType(file));

        if (invalidFiles.length > 0) {
            invalidFiles.forEach(file => addErrorMessage(`File ${file.name} is not a supported type.`));
        }

        const individualFiles = [];
        const folderStructures = [];

        newFiles.forEach(file => {
            if (file.webkitRelativePath) {
                folderStructures.push(file);
            } else {
                individualFiles.push(file);
            }
        });

        if (individualFiles.length > 0) {
            const uniqueIndividualFiles = individualFiles.filter(file => {
                const filePath = file.webkitRelativePath || file.name;
                return !fileStructure[filePath];
            });

            if (uniqueIndividualFiles.length > 0) {
                updateSelectedFiles((prevFiles) => [...prevFiles, ...uniqueIndividualFiles]);
                setFoldersUploaded(false);
            }
        }

        if (folderStructures.length > 0) {
            const folderData = folderStructures.reduce((acc, file) => {
                const folders = file.webkitRelativePath.split('/');
                folders.reduce((folderAcc, folder, idx) => {
                    if (!folderAcc[folder]) {
                        folderAcc[folder] = { files: [] };
                    }
                    if (idx === folders.length - 1) {
                        folderAcc[folder].files.push(file);
                    }
                    return folderAcc[folder];
                }, acc);
                return acc;
            }, {});

            updateFileStructure(folderData);
            setFoldersUploaded(true);
        }

        updateUploadComplete(true);
    };

    const handleFoldersSelect = (folderStructure) => {
        const validFiles = [];
        const invalidFiles = [];

        const traverseFileStructure = (structure) => {
            Object.keys(structure).forEach(key => {
                if (key === 'files') {
                    structure.files.forEach(file => {
                        if (validFileTypes.includes(file.type)) {
                            validFiles.push(file);
                        } else {
                            invalidFiles.push(file);
                        }
                    });
                } else {
                    traverseFileStructure(structure[key]);
                }
            });
        };

        traverseFileStructure(folderStructure);

        if (invalidFiles.length > 0) {
            invalidFiles.forEach(file => addErrorMessage(`File ${file.name} is not a supported type.`));
        }

        const filteredFolderStructure = filterInvalidFilesFromStructure(folderStructure);

        const mergedStructure = mergeFileStructures({ ...fileStructure }, filteredFolderStructure);
        updateFileStructure(mergedStructure);
        updateUploadComplete(true);
        setFoldersUploaded(true);
    };

    const filterInvalidFilesFromStructure = (structure) => {
        if (structure.files) {
            structure.files = structure.files.filter(file => validFileTypes.includes(file.type));
        }
        Object.keys(structure).forEach(key => {
            if (key !== 'files') {
                filterInvalidFilesFromStructure(structure[key]);
            }
        });
        return structure;
    };


    const renderFiles = () => {
        const startIndex = (currentPage - 1) * filesPerPage;
        const endIndex = startIndex + filesPerPage;
        const filesToRender = selectedFiles.slice(startIndex, endIndex);
        return filesToRender.map((file, fileIndex) => (
            <Grid.Column key={fileIndex} style={{ marginBottom: '10px', textAlign: 'center', position: 'relative' }}>
                <Icon
                    name='delete'
                    color='red'
                    style={{
                        position: 'absolute',
                        top: '5px',
                        right: '5px',
                        cursor: 'pointer',
                        zIndex: 10,
                    }}
                    onClick={() => handleDeleteFile(file, [])}
                />
                {file.type.startsWith('image/') && (
                    <Image src={URL.createObjectURL(file)} size='small' rounded />
                )}
                {file.type.startsWith('video/') && (
                    <div style={{ marginBottom: '10px' }}>
                        <video width='120' height='90' controls>
                            <source src={URL.createObjectURL(file)} type={file.type} />
                            Your browser does not support the video tag.
                        </video>
                    </div>
                )}
                <span style={{ display: 'block', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: '120px' }}>{file.name}</span>
            </Grid.Column>
        ));
    };

    const mergeFileStructures = (currentStructure, newStructure) => {
        const merge = (curr, toMerge) => {
            Object.keys(toMerge).forEach(key => {
                if (!curr[key]) {
                    curr[key] = toMerge[key];
                } else if (key === 'files') {
                    curr[key] = [...curr[key], ...toMerge[key]];
                } else {
                    merge(curr[key], toMerge[key]);
                }
            });
        };

        merge(currentStructure, newStructure);
        return currentStructure;
    };

    const handleDeleteFile = (fileToDelete, path) => {
        if (path.length === 0) {
            updateSelectedFiles(prevFiles => prevFiles.filter(file => file !== fileToDelete));
        } else {
            const newFileStructure = { ...fileStructure };
            let currentFolder = newFileStructure;

            for (let i = 0; i < path.length - 1; i++) {
                currentFolder = currentFolder[path[i]];
            }

            const folderName = path[path.length - 1];
            currentFolder[folderName].files = currentFolder[folderName].files.filter(file => file !== fileToDelete);

            updateFileStructure(newFileStructure);
        }
    };

    const handleDeleteFolder = (path) => {
        if (path.length === 0) return;

        const newFileStructure = { ...fileStructure };
        let currentFolder = newFileStructure;

        for (let i = 0; i < path.length - 1; i++) {
            currentFolder = currentFolder[path[i]];
        }

        const folderName = path[path.length - 1];
        delete currentFolder[folderName];

        updateFileStructure(newFileStructure);
    };

    const countFilesAndFoldersInFolder = (structure) => {
        let fileCount = 0;
        let folderCount = 0;

        const traverse = (folder) => {
            Object.keys(folder).forEach(key => {
                if (key === 'files') {
                    fileCount += folder[key].length;
                } else {
                    folderCount += 1; // Contar la carpeta actual
                    traverse(folder[key]);
                }
            });
        };

        traverse(structure);
        return { fileCount, folderCount };
    };


    const renderFilesAndFolders = (structure, path) => {
        const currentFolder = path.reduce((acc, folder) => {
            if (acc && acc[folder]) {
                return acc[folder];
            } else {
                return null;
            }
        }, structure);

        if (!currentFolder) {
            return null;
        }

        const renderFolders = Object.keys(currentFolder)
            .filter(key => key !== 'files')
            .map((folder, index) => (
                <Grid.Column key={index} style={{ textAlign: 'center', flex: '0 0 auto', position: 'relative' }}>
                    <div onClick={() => navigateTo([...path, folder])} style={{ cursor: 'pointer', position: 'relative' }}>
                        <Icon
                            name='delete'
                            color='red'
                            style={{
                                position: 'absolute',
                                top: '5px',
                                right: '5px',
                                cursor: 'pointer',
                                zIndex: 10,
                            }}
                            onClick={(e) => {
                                e.stopPropagation();
                                handleDeleteFolder([...path, folder]);
                            }}
                        />
                        <Image src={Directory} size='small' style={{ marginBottom: '10px' }} />
                        <strong>
                            <span className="folder-counter">
                                {(() => {
                                    const { fileCount, folderCount } = countFilesAndFoldersInFolder(currentFolder[folder]);

                                    if (folderCount > 0) {
                                        return `${folderCount} ${folderCount > 1 ? 'folders' : 'folder'}`;
                                    } else if (fileCount > 0) {
                                        return `${fileCount} ${fileCount > 1 ? 'files' : 'file'}`;
                                    } else {
                                        return 'Empty';
                                    }
                                })()}
                            </span>
                        </strong>

                        <span style={{ display: 'block', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: '120px' }}>{folder}</span>
                    </div>
                </Grid.Column>
            ));

        const allFiles = currentFolder.files || [];
        const totalFiles = allFiles.length;
        const totalPages = Math.ceil(totalFiles / currentFolderFilesPerPage);
        const startIndex = (currentFolderPage - 1) * currentFolderFilesPerPage;
        const endIndex = startIndex + currentFolderFilesPerPage;
        const filesToRender = allFiles.slice(startIndex, endIndex);

        const renderFiles = filesToRender.map((file, index) => (
            <Grid.Column key={index} style={{ marginBottom: '10px', textAlign: 'center', position: 'relative' }}>
                <Icon
                    name='delete'
                    color='red'
                    style={{
                        position: 'absolute',
                        top: '5px',
                        right: '5px',
                        cursor: 'pointer',
                        zIndex: 10,
                    }}
                    onClick={() => handleDeleteFile(file, path)}
                />
                {file.type.startsWith('image/') && (
                    <Image src={URL.createObjectURL(file)} size='small' rounded />
                )}
                {file.type.startsWith('video/') && (
                    <div style={{ marginBottom: '10px' }}>
                        <video width='120' height='90' controls>
                            <source src={URL.createObjectURL(file)} type={file.type} />
                            Your browser does not support the video tag.
                        </video>
                    </div>
                )}
                <span title={file.name} style={{ display: 'block', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: '120px' }}>{file.name}</span>
            </Grid.Column>
        ));

        return (
            <>
                <Grid>
                    <Grid.Row columns={5} style={{ justifyContent: 'flex-start' }}>
                        {renderFolders}
                    </Grid.Row>
                    <Grid.Row columns={5}>
                        {renderFiles}
                    </Grid.Row>
                </Grid>
                {totalPages > 1 && (
                    <div style={{ textAlign: 'center', marginTop: '20px', marginBottom: '20px' }}>
                        <Pagination
                            activePage={currentFolderPage}
                            onPageChange={(e, { activePage }) => setCurrentFolderPage(activePage)}
                            ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
                            firstItem={{ content: <Icon name='angle double left' />, icon: true }}
                            lastItem={{ content: <Icon name='angle double right' />, icon: true }}
                            prevItem={{ content: <Icon name='angle left' />, icon: true }}
                            nextItem={{ content: <Icon name='angle right' />, icon: true }}
                            totalPages={totalPages}
                        />
                    </div>
                )}
            </>
        );
    };

    const navigateTo = (newPath) => {
        updateCurrentPath(newPath);
        setCurrentFolderPage(1); // Reset folder page when navigating
    };

    const handlePaginationChange = (e, { activePage }) => {
        updateCurrentPage(activePage);
    };

    const renderBreadcrumb = () => {
        return (
            <div className="breadcrumb mb-30" style={{ marginTop: '20px', marginBottom: '20px' }}>
                <span className="breadcrumb-link" onClick={() => navigateTo([])}>
                    <Icon name='home' />
                </span>
                {currentPath.map((folder, index) => (
                    <span key={index}>
                        <span className="breadcrumb-separator">/</span>
                        <span className="breadcrumb-link" onClick={() => navigateTo(currentPath.slice(0, index + 1))}>{folder}</span>
                    </span>
                ))}
            </div>
        );
    };

    return (
        <Container className="inside-container">
            <Container textAlign='left' style={{ width: '100%' }} className="inside-container">
                <p className="label-grey">Upload videos/images</p>

                {renderBreadcrumb()}
                <div
                    onDragStart={() => setDragging(true)}
                    onDragEnd={() => setDragging(false)}
                    onDragOver={(e) => e.preventDefault()}
                    onDrop={(e) => {
                        e.preventDefault();
                        setDragging(false);
                        const files = Array.from(e.dataTransfer.files);
                        handleFiles(files);
                    }}
                >
                    <Segment
                        color={dragging ? 'blue' : null}
                        textAlign='center'
                        style={{
                            height: '100%',
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: 'rgb(248, 248, 249)',
                            padding: '20px',
                            margin: 'auto',
                            marginBottom: '20px',
                            borderRadius: '12px',
                        }}
                    >
                        {!uploadComplete && <Image src={Upload} centered />}
                        <div style={{ width: '100%', textAlign: uploadComplete ? 'left' : 'center' }}>
                            <Header as='h3' style={{ color: '#101828' }}>Drag and drop videos/images to upload</Header>
                            <p>or</p>
                            <Button icon labelPosition='left' onClick={() => document.getElementById('upload-files').click()} color='teal'>
                                <Icon name='file' />
                                Select Files
                            </Button>
                            <input type="file" id="upload-files" style={{ display: 'none' }} accept="video/*, image/*" multiple onChange={(e) => handleFiles(Array.from(e.target.files))} />
                            <UploadFolders onFoldersSelect={handleFoldersSelect} />
                        </div>

                        {errorMessages.length > 0 && (
                            errorMessages.map((errorMessage, index) => (
                                <Message negative key={index}>
                                    <p>{errorMessage}</p>
                                </Message>
                            ))
                        )}
                    </Segment>
                </div>

                {uploadComplete && Object.keys(fileStructure).length > 0 && (
                    <div>
                        <Header as='h3' style={{ color: '#101828' }}>{currentPath.length === 0 ? "Folders:" : "Files in folder:"}</Header>
                        {renderFilesAndFolders(fileStructure, currentPath)}
                    </div>
                )}
                {uploadComplete && selectedFiles.length > 0 && currentPath.length === 0 && (
                    <div>
                        <Header as='h3' style={{ color: '#101828' }}>Files:</Header>
                        <Grid>
                            <Grid.Row columns={5}>
                                {renderFiles()}
                            </Grid.Row>
                        </Grid>
                        <div style={{ textAlign: 'center', marginTop: '20px', marginBottom: '20px' }}>
                            <Pagination
                                activePage={currentPage}
                                onPageChange={handlePaginationChange}
                                ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
                                firstItem={{ content: <Icon name='angle double left' />, icon: true }}
                                lastItem={{ content: <Icon name='angle double right' />, icon: true }}
                                prevItem={{ content: <Icon name='angle left' />, icon: true }}
                                nextItem={{ content: <Icon name='angle right' />, icon: true }}
                                totalPages={Math.ceil(selectedFiles.length / filesPerPage)}
                            />
                        </div>
                    </div>

                )}

                <div className='mt-30'>
                    <Divider />
                </div>

                <div className='right-aligned-container'>
                    <Button onClick={handlePrevStep} style={{ marginRight: '10px' }}>Back</Button>
                    <Button className="ui teal button" onClick={handleNextStep} disabled={!uploadComplete}>Continue</Button>
                </div>
            </Container>
        </Container>
    );
};

export default UploadFiles;
