import React from 'react';


class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }
  
  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo
    })
    // You can also log error messages to an error reporting service here
  }
  
  render() {
    if (this.state.errorInfo) {
      let backgroundImg = `linear-gradient(45deg, rgb(229, 44, 78) 1%, rgb(233, 74, 99) 30%, rgb(174, 82, 157) 69%, rgb(154, 85, 177) 100%)`;
      // Error path
      return (
        <div>
         <header className="App-hero" style={{
          "minHeight":"75px",
          "backgroundImage":backgroundImg,
          "color":"white"
        }}>
        </header>
          <h2>Something went wrong.</h2>
          <details style={{ whiteSpace: 'pre-wrap' }}>
            {this.state.error && this.state.error.toString()}
            <br />
            {this.state.errorInfo.componentStack}
          </details>
        </div>
      );
    }
    // Normally, just render children
    return this.props.children;
  }  
}


export default ErrorBoundary;