import React, { Component, Fragment } from 'react';
import ErrorBoundary from './ErrorBoundary';
import { Container } from 'semantic-ui-react';
import Menubar from './Menubar';
import ProjectsGrid from './ProjectsGrid';
import GradientHeader from '../containers/GradientHeader';

export default class LabelHome extends Component {

  componentDidMount() {
    const { currentUser: { user } } = this.props;
    if (!user) {
      this.props.history.push("/");
    }
  }


  render() {
    const { browserLang } = this.props;
    return (
      <ErrorBoundary>
        <Fragment>
          {(process.env.REACT_APP_USE_GLOBAL_NAV === 'false' ||
            process.env.REACT_APP_USE_GLOBAL_NAV === '' ||
            typeof process.env.REACT_APP_USE_GLOBAL_NAV === 'undefined') ? (
            <>
              <GradientHeader />
              <div>  
          <Menubar userRole={this.props.currentUser.user.role} {...this.props} active="label">
            <ProjectsGrid
              {...this.props}
              title={browserLang === "en"?  "Start Labeling:": "Comienza a Etiquetar"}
              linkPrefix="/label/"
              newButton={false}
            />
          </Menubar>
        </div>
            </>
          ) : (
            <div style={{ background: '#f7f7f7', minHeight: '100vh' }}>

                <ProjectsGrid
                  {...this.props}
                  title={browserLang === "en" ? "Start Labeling:" : "Comienza a Etiquetar"}
                  linkPrefix="/label/"
                  newButton={false}
                />
            </div>
          )}

        </Fragment>
      </ErrorBoundary>
    );
  }
}
