import React, { Component, Fragment } from 'react';
import Hotkeys from 'react-hot-keys';
import update from 'immutability-helper';

// import 'semantic-ui-css/semantic.min.css';

import Canvas from './Canvas';
import HotkeysPanel from './HotkeysPanel';
import Sidebar from './Sidebar';
// import { PathToolbar, MakePredictionToolbar } from './CanvasToolbar';
import Reference from './Reference';
import './LabelingApp.css';
import GradientHeader from '../containers/GradientHeader';

import { handleIntersectionTools } from '../videoLabel/utils/geo';
import { genId, colors } from './utils';
import { computeTrace } from './tracing';
import { withHistory } from './LabelingAppHistoryHOC';
import { withLoadImageData } from './LoadImageDataHOC';
import { withPredictions } from './MakePredictionsHOC';

import {apiCall} from "../services/api";
import {generateRandomAvatar} from "../services/defaultAvatars";

import ErrorBoundary from '../containers/ErrorBoundary';

import { connect } from "react-redux";

import { v4 as uuidv4 } from 'uuid';

// import DrawableVideoPlayer from '../videoLabel/DrawableVideoPlayer/DrawableVideoPlayer';
import TwoDimensionalVideo from '../videoLabel/TwoDimensionalVideo/TwoDimensionalVideo';
import { cloneDeep } from 'lodash';

/*
 type Figure = {
   type: 'bbox' | 'polygon';
   points: [{ lat: Number, lng: Number }];
   ?color: Color;
 };
*/

class LabelingApp extends Component {
  constructor(props) {
    super(props);
    const { labels, figures } = props;
    const toggles = {};
    const figureToggles = {};
    if(labels){
      labels.map(label => (toggles[label.id] = true));
    }
    
    for (let labelId in figures ){
      if(figures[labelId].length >0){
        figures[labelId].forEach((figure)=>{ 
          figureToggles[figure.id] = true;
        });
      }
    }
    this.state = {
      selected: null,
      toggles,
      figureToggles,
      selectedFigureIds: [],

      // UI
      reassigning: { status: false, type: null },
      hotkeysPanel: false,
      imageName:"",
      webContentLink:null,
      commentThreadArr:[],
      userAvatarURL:generateRandomAvatar(),
      didCtrlZ:false,
      isFigureToolbarOpen:false,
      selectedFigureObj:null,
      engineeringData:null
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSelected = this.handleSelected.bind(this);
    this.handleSelectionChange = this.handleSelectionChange.bind(this);
    this.handleFiguresVisiblilityToggle = this.handleFiguresVisiblilityToggle.bind(this);
    this.canvasRef = React.createRef();
    this.labelingAppRef = React.createRef();
    this.handleNewCommentThread = this.handleNewCommentThread.bind(this);
    this.handleNewComment = this.handleNewComment.bind(this);
    this.handleRemoveCommentThread = this.handleRemoveCommentThread.bind(this);
    this.handleToggleResolvedThread = this.handleToggleResolvedThread.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleEditorOptionsChange = this.handleEditorOptionsChange.bind(this);
  }

  componentDidMount(){
    // console.log("Canvas ref in LabelingApp", this.canvasRef);
    // console.log("LabelingApp props", this.props);
    const {image, video} = this.props;
    let file =null;
    let externalUrl = null;
    if(image){
      file=image;
      externalUrl = this.props.externalImageUrl;
    }else if(video){
      file=video;
      externalUrl = this.props.videoUrl;
    }
    if(image && "commentThreadArr" in image && image.commentThreadArr){
      this.setState({
        commentThreadArr:image.commentThreadArr
      });
    }
    const fileId = file ? file._id: null;
    let fileMetadata = null;
    if(image){
      fileMetadata = image && "imageMetadata" in image && image.imageMetadata ? image.imageMetadata : null;
    }else if(video){
      fileMetadata = video && "videoMetadata" in video && video.videoMetadata ? video.videoMetadata : null;
    }
    if(!fileMetadata && externalUrl && (externalUrl.indexOf("https://drive.google.com/uc?export=view&id=") >= 0)){
      // console.log("Getting IMG Metadata");
      try{
        const driveFileId = externalUrl.replace("https://drive.google.com/uc?export=view&id=","");
        apiCall("GET", `${process.env.REACT_APP_PRODUCTION_SERVER_URL}/api/images/drive/getFileMetadata?fileId=${driveFileId}`)
          .then((imgData)=>{
            this.setState({
              fileName:imgData.name,
              webContentLink:imgData.webContentLink
            });
            if(image){
              apiCall("PATCH",`${process.env.REACT_APP_PRODUCTION_SERVER_URL}/api/images/${fileId}`,{ imageMetadata: imgData})
                .then((response)=>{
                  // consolsce.log("Success updaing image metadata");
                })
                .catch((error)=>{
                  console.log("Error updating image metadata");
                  console.log(error);
                });
            }
          })
          .catch((err)=>{
            console.log("Error getting file metadata");
            console.log(err);
          });
      }catch(err){
        console.log(err);
      }
    }else if(fileMetadata && "webContentLink" in fileMetadata){
      this.setState({
        fileName: fileMetadata.name,
        webContentLink:fileMetadata.webContentLink
      })
    }else if(!fileMetadata && externalUrl && (externalUrl.indexOf("https://storage.googleapis.com/") >= 0)){
        // console.log("Parsing GCS link");
        const imageUrlArr = externalUrl.replace("https://storage.googleapis.com/","").split("/");
        imageUrlArr.shift()
        const parsedImgName = imageUrlArr.join("/");
        if(parsedImgName){
          this.setState({
            fileName:parsedImgName
          });       
        }
    }


  }

  handleKeyDown(key, e, keyname){
    console.log(key);

    this.props.popState();
    
    //This will handle contionuous CTRL Z at some point

    // const didCtrlZ = this.state.didCtrlZ;
    
    // if(e.repeat){
    //     if(didCtrlZ){
    //     this.setState({
    //       didCtrlZ:false
    //     });
    //   }else{
    //     this.props.popState();
    //     this.setState({
    //       didCtrlZ:true
    //     })
    //   }
    // }else{
    //   this.props.popState();
    //   this.setState({
    //     didCtrlZ:false
    //   })
    // }
  }

  async handleNewCommentThread(event){
    if(this.props.currentUser && "user" in this.props.currentUser && this.props.currentUser.user){
      const newCommentThread = {
        position:event.latlng,
        isResolved:false,
        createdAt:Date.now(),
        author_fname:this.props.currentUser.user.f_name,
        author_lname:this.props.currentUser.user.l_name,
        commentsArr:[],
        author_id:this.props.currentUser.user.id,
      };
      // const newCommentThreadArr = [...this.state.commentThreadArr,newCommentThread];
      const postedCommentThread = await apiCall("POST", `${process.env.REACT_APP_PRODUCTION_SERVER_URL}/api/images/6184aa830e0e325200a2d86b/commentsThread`,newCommentThread);
      this.setState({
        commentThreadArr:postedCommentThread
      });
    }
  }

  handleRemoveCommentThread(threadId){
    if(!threadId){
      console.log("No thread ID specified");
      return;
    }else{
      // console.log("threadId", threadId);
      let commentThreadArr = this.state.commentThreadArr.filter((thread)=>{
        return !(thread.id === threadId);
      });
      // console.log("with removed commentThreadArr", commentThreadArr);

        this.setState({
          commentThreadArr
        });
    }
  }

  handleToggleResolvedThread(threadId){
    if(!threadId){
      console.log("No thread ID specified");
      return;
    }else{
      // console.log("threadId", threadId);
      let commentThreadArr = this.state.commentThreadArr.map((thread)=>{
        if (thread.id === threadId){
          return {
            ...thread,
            isResolved: !thread.isResolved
          };
        }else{
          return thread;
        }
      });

        this.setState({
          commentThreadArr
        });
    }
  }

  handleNewComment(threadId, text){
    if(!threadId){
      console.log("No thread ID specified");
      return;
    }
    if(!text){
      console.log("No text");
      return;

    }
    const user = this.props.currentUser.user || null;
    if(!user){
      console.log("No user");
      return;
    } 
    const newComment = {
        author_id:user.id,
        author_fname:user.f_name,
        author_lname:user.l_name,
        createdAt:Date.now(),
        text:text,
        id:uuidv4(),
        authorLogoURL:user.profileImageUrl || this.state.userAvatarURL
      }; 
      console.log("new comment", newComment);
    const newCommentThreadArr = this.state.commentThreadArr.map((thread)=>{
      if(thread.id === threadId){
        return {
          ...thread,
          commentsArr:[...thread.commentsArr, newComment]
        }
      }else{
        return thread;
      }
    });
    this.setState({
      commentThreadArr:newCommentThreadArr
    });

  }

  handleSelected(selected) {
    //selected is the id of the label in formparts
    if (selected === this.state.selected) return;
    const { pushState } = this.props;

    if (["erase", "expand"].includes(selected)) {
      pushState(
        state => ({
          unfinishedFigure: {
            id: null,
            color: selected === "erase" ? "white" : "black",
            type: selected,
            points: [],
            isVisible: true
          }
        }),
      );
      return;
    }

    if (!selected) {
      pushState(
        state => ({
          unfinishedFigure: null,
        }),
      );
      return;
    }

    const { labels } = this.props;

    const labelIdx = labels.findIndex(label => label.id === selected);
    const type = labels[labelIdx].type;
    const color = colors[labelIdx];

    pushState(
      state => ({
        unfinishedFigure: {
          id: null,
          color,
          type,
          points: [],
          isVisible:true
        },
      }),
      () => this.setState({ selected })
    );
  }

  handleEditorOptionsChange(editorOptsObj){
    const {project,onProjectUpdate} = this.props;
    //editorOptsObj: {
      //   lcmsIntDisplay:false,
      //   lcms3dDisplay:false,
      //   lcmsRightOfWayDisplay:false
      // }
      if(project && onProjectUpdate){
        onProjectUpdate({editorOptions:{...editorOptsObj}})
      }
    }

  handleSelectionChange(figureIds) {
    if (figureIds && figureIds.length > 0) {
      this.setState({ selectedFigureIds: figureIds });
    } else {
      this.setState({
        reassigning: { status: false, type: null },
        selectedFigureIds: [],
      });
    }
  }


  handleFiguresVisiblilityToggle(visible){
    // console.log("handleFiguresVisiblilityToggle");
    // console.log("visible", visible);

    let allFiguresVisibility = this.state.figureToggles;
    if(visible){
      for (let figureId in allFiguresVisibility){
        allFiguresVisibility[figureId] = true;
      }
    }else{
      for (let figureId in allFiguresVisibility){
        allFiguresVisibility[figureId] = false;
      }
    }
    this.setState({
      figureToggles:allFiguresVisibility
    })
  }

  handleSingleChange(pushFigures, eventType, figure) {
    if (!figure.color){
      return;
    } 
    const { labels, height, width, imageData } = this.props;
    const label =
      figure.color === 'gray'
        ? { id: '__temp' }
        : labels[colors.indexOf(figure.color)];
    const idx = (pushFigures[label.id] || []).findIndex(f => f.id === figure.id);
    const figureId = figure.id || genId();
    pushFigures[label.id] = pushFigures[label.id] || [];
    switch (eventType) {
      case 'new':
        pushFigures[label.id].push({
          id: figureId,
          type: figure.type,
          points: figure.points,
          isVisible:true
        });
        this.setState({
          figureToggles: {...this.state.figureToggles, [figureId]: true},
          selected: null,
        })
        break;
      case 'finishHandrawnLine':
        pushFigures[label.id] = {
          id: figure.id || genId(),
          type: figure.type,
          points: figure.points,
          isVisible: true
        }
        this.setState({ selected: null })
      break;
      case 'replace':
        let { tracingOptions } = figure;
        if (tracingOptions && tracingOptions.enabled) {
          const imageInfo = {
            height,
            width,
            imageData,
          };
          tracingOptions = {
            ...tracingOptions,
            trace: computeTrace(figure.points, imageInfo, tracingOptions),
          };
        } else {
          tracingOptions = { ...tracingOptions, trace: [] };
        }
        pushFigures[label.id].splice(idx, 1, {
          id: figure.id,
          type: figure.type,
          points: figure.points,
          tracingOptions,
        });
        break;
      case 'expand':
      case 'erase': {
        const [figureToggles] = handleIntersectionTools(eventType, this.state.figureToggles, pushFigures, figure);
        this.setState({ figureToggles });
        break;
      }
      case 'delete':
        pushFigures[label.id].splice(idx, 1);
        break;
      case 'unfinished':
        break;
      case 'recolor':
        if (label.id === figure.label.id) return;
        pushFigures[figure.label.id].push({
          id: figureId,
          type: figure.type,
          points: figure.points,
          tracingOptions: figure.tracingOptions,
        });
        pushFigures[label.id].splice(idx, 1);
        break;
      default:
        throw new Error('unknown event type ' + eventType);
    }
  }

  handleChange(eventType, newFigures) {
    const { figures, pushState, unfinishedFigure } = this.props;
    let pushFigures = cloneDeep(figures);
    let newUnfinishedFigure = cloneDeep(unfinishedFigure);
    newFigures.forEach((figure) => {
      this.handleSingleChange(pushFigures, eventType, figure);
    });
    pushState(() => {
      if (['new', 'finishHandrawnLine', 'erase', 'expand'].includes(eventType)) newUnfinishedFigure = null;
      else if (eventType === 'unfinished') newUnfinishedFigure = newFigures[0];
      return {
        figures: pushFigures,
        unfinishedFigure: newUnfinishedFigure,
      };
    });
  }

  render() {
    const {
      labels,
      imageUrl,
      reference,
      onBack,
      onGoToAdminView,
      onSkip,
      onSubmit,
      pushState,
      figures,
      unfinishedFigure,
      height,
      width,
      models,
      makePrediction,
      project,
      imageFound,
      image,
      video,
      labelMetadata,
      videoUrl,
      videoAnnotationMethods,
      section
    } = this.props;
    const {
      selected,
      selectedFigureIds,
      reassigning,
      toggles,
      figureToggles,
      hotkeysPanel,
    } = this.state;

    const forwardedProps = {
      onBack,
      onSkip,
      onSubmit,
      models,
      makePrediction,
      onGoToAdminView
    };

    let selectedFigure = null;
    const allFigures = [];
    let fileUrl = null;
    if(imageUrl){
      fileUrl = imageUrl;
    }else if(videoUrl){
      fileUrl = videoUrl;
    }

    if(labels){
        labels.forEach((label, i) => {
          figures[label.id].forEach(figure => {
            if (toggles[label.id] && (label.type === 'bbox' || label.type === 'polygon' || label.type === 'polyline')) {
              allFigures.push({
                color: colors[i],
                points: figure.points,
                id: figure.id,
                type: figure.type,
                tracingOptions: figure.tracingOptions,
                label,
                isVisible:figureToggles[figure.id]
              });
              if (selectedFigureIds.includes(figure.id)) {
                selectedFigure = { ...figure, color: colors[i] };
              }
            }
          });
      });
    }
    
    
    figures.__temp.forEach(figure => {
      allFigures.push({
        color: 'gray',
        ...figure,
        isVisible:true
      });
    });

    const onFiguresToggle = (figures) => {
      const newFigureToggles = { ...figureToggles };
      for (const figure of figures) {
        newFigureToggles[figure.id] = !newFigureToggles[figure.id];
      }
      this.setState({
        figureToggles: newFigureToggles,
      });
    };

    const sidebarProps = reassigning.status
      ? {
          title: 'Select the new label',
          selected: null,
          onSelect: selected => {
            const figure = this.canvasRef.current.getSelectedFigure();
            if (figure) {
              this.handleChange('recolor', [figure], selected);
            }

            this.setState({ reassigning: { status: false, type: null } });
          },
          filter: label => label.type === reassigning.type,
          labelData: figures,
          imageName:this.state.fileName,
          labeled:this.props.labeled,
          assignedLabelerName:project && ("assignedLabeler" in project) && project.assignedLabeler ? project.assignedLabeler : "",
          fileUrl:fileUrl,
          section:section,
          engineeringData:image?.engineeringData
        }
      : {
          title: project && ("name" in project) && project.name ? project.name : "Labeling",
          selected,
          onSelect: this.handleSelected,
          toggles,
          figureToggles,
          onToggle: label =>
            this.setState({
              toggles: update(toggles, {
                [label.id]: { $set: !toggles[label.id] },
              }),
            }),
          onFiguresToggle,
          openHotkeys: () => this.setState({ hotkeysPanel: true }),
          onFormChange: (labelId, newValue) =>
            pushState(state => ({
              figures: update(figures, { [labelId]: { $set: newValue } }),
            })),
          labelData: figures,
          imageName:this.state.fileName,
          labeled:this.props.labeled,
          assignedLabelerName:project && ("assignedLabeler" in project) && project.assignedLabeler && "f_name" in project.assignedLabeler ? `${project.assignedLabeler.f_name} ${project.assignedLabeler.l_name}` : "Unassigned Labeler",
          fileUrl:fileUrl,
          section:section,
          engineeringData:image?.engineeringData
        };

    const hotkeysPanelDOM = hotkeysPanel ? (
      <HotkeysPanel
        labels={labels.map(label => label.name)}
        onClose={() => this.setState({ hotkeysPanel: false })}
        projectCustomShortcuts = {project?.projectConfiguration?.shortcuts}
      />
    ) : null;

    let toolbarDOM = null;
    const toolbarStyle = {
      position: 'absolute',
      top: 0,
      left: 0,
      zIndex: 10000,
    };

    const demoBar = this.props.demo ? (
      <div
        style={{
          width: '100%',
          flex: '0 0 auto',
          background: '#FFD700',
          padding: '5px 10px',
          fontWeight: 800,
          textAlign: 'center',
        }}
      >
        This is a demo page. The changes will not be saved and all network
        responses are static.{' '}
        <a target="_blank" href="https://github.com/Slava/label-tool">
          GitHub repo
        </a>
      </div>
    ) : null;
    let backgroundImg = `linear-gradient(45deg, rgb(229, 44, 78) 1%, rgb(233, 74, 99) 30%, rgb(174, 82, 157) 69%, rgb(154, 85, 177) 100%)`;
      let imageFinalURL = imageFound ? imageUrl : "https://res.cloudinary.com/dqwgbheqj/image/upload/v1633492043/no_image_found-01_ehvdre.png";
      const handleSubmit = r => console.log(r);
  
 
  const previewNoticeList = [];
  const previewHeader = 'Please scan the video and observe the following to help you complete the task:';
  const emptyCheckSubmissionWarningText = 'Please annotate AND track one unmarked cell to complete this task.';
  const emptyCheckAnnotationItemWarningText = '';
  const emptyAnnotationReminderText = 'No labels found yet. Please label the video as you find relevant objects.';
    return (
    <Fragment>
    <ErrorBoundary>
    {(process.env.REACT_APP_USE_GLOBAL_NAV === 'false' || process.env.REACT_APP_USE_GLOBAL_NAV === '' || typeof process.env.REACT_APP_USE_GLOBAL_NAV === 'undefined') && (
				<GradientHeader/>
			)}
      <div
        style={{ display: 'flex', height: 'calc(100vh - 75px)', flexDirection: 'column', overflow: 'hidden' }}
      >
        {demoBar}
        <div style={{ display: 'flex', flex: 1, height: '100%' }} id="labelingApp" tabIndex="0">
          <Hotkeys keyName={video ? "" : "ctrl+z"} onKeyDown={this.handleKeyDown} allowRepeat={false}> 
            <Sidebar
              labels={labels}
              {...sidebarProps}
              {...forwardedProps}
              project={project}
              figures={allFigures}
              style={{ flex: 1, maxWidth: 300 }}
              onFigureVisibilityToggle = {this.handleFiguresVisiblilityToggle}
            />
            {hotkeysPanelDOM}
            <div style={{ flex: 4, display: 'flex', flexDirection: 'column' }}>
              <Reference {...reference} />
              <div style={{ position: 'relative', height: '100%' }}>
                {image && (<Canvas
                    handleSelected={this.handleSelected}
                    onNewCommentThread={this.handleNewCommentThread}
                    commentThread={this.state.commentThreadArr}
                    onNewComment={this.handleNewComment}
                    url={this.state.webContentLink? this.state.webContentLink : imageFinalURL}
                    height={height}
                    width={width}
                    figures={allFigures}
                    unfinishedFigure={unfinishedFigure}
                    onChange={this.handleChange}
                    onReassignment={type =>
                      this.setState({ reassigning: { status: true, type } })
                    }
                    onSelectionChange={this.handleSelectionChange}
                    ref={this.canvasRef}
                    style={{ flex: 1 }}
                    onRemoveCommentThread = {this.handleRemoveCommentThread}
                    onToggleResolve = {this.handleToggleResolvedThread}
                    /* labels is being set in the next props, lefting this here just in case.
                    labels={this.props.labels}
                    */
                    lcms3dImageUrl={(image && "lcms3dImageUrl" in image && image.lcms3dImageUrl)? image.lcms3dImageUrl : "https://res.cloudinary.com/dqwgbheqj/image/upload/v1675108863/No%20Image%20Available/3d-img-not-available-corrected-01_u7zrjo.png"}
                    lcms3dOverlayImgUrl={(image && "lcms3dOverlayImgUrl" in image && image.lcms3dOverlayImgUrl)? image.lcms3dOverlayImgUrl : "https://res.cloudinary.com/dqwgbheqj/image/upload/v1675121746/No%20Image%20Available/3d-overlay-img-not-available-01_ibrda4.png"}
                    lcmsIntImageUrl={(image && "lcmsIntImageUrl" in image && image.lcmsIntImageUrl)? image.lcmsIntImageUrl : "https://res.cloudinary.com/dqwgbheqj/image/upload/v1675108682/No%20Image%20Available/int-img-not-available-01_vqnybe.png"}
                    lcmsRightOfWayImageUrl={(image && "lcmsRightOfWayImageUrl" in image && image.lcmsRightOfWayImageUrl)? image.lcmsRightOfWayImageUrl : "https://res.cloudinary.com/dqwgbheqj/image/upload/v1675108682/No%20Image%20Available/row-img-not-available-01_faawlt.png"}
                    editorOptions={"editorOptions" in project ? project.editorOptions : null}
                    pavementType={"pavementDataType" in project? project.pavementDataType : "rgb"}
                    onEditorOptionsChange = {this.handleEditorOptionsChange}
                    project={project}
                    onFiguresToggle={onFiguresToggle}
                    projectCustomShortcuts = {project?.projectConfiguration?.shortcuts}
                    labels={labels}
                    setFigureToolbarOpen={(isToolbarOpen,figure)=>this.setState({ isFigureToolbarOpen: isToolbarOpen })}
                  />)}
                {video && (
                  <TwoDimensionalVideo
                    onSubmit={ handleSubmit }
                    url={videoUrl}
                    videoWidth={ 800 }
                    hasReview
                    isEmptyCheckEnable
                    isShowHideEnable
                    emptyCheckSubmissionWarningText={ emptyCheckSubmissionWarningText }
                    emptyCheckAnnotationItemWarningText={ emptyCheckAnnotationItemWarningText }
                    emptyAnnotationReminderText={ emptyAnnotationReminderText }
                    numAnnotationsToBeAdded={ 20 }
                    defaultAnnotations={ [] }
                    previewHeader={ previewHeader }
                    previewNoticeList={ previewNoticeList }
                    selectedLabel={selected}
                    labels={labels}
                    handleSelected={this.handleSelected}
                    videoAnnotationMethods={videoAnnotationMethods}
                    video={video}
                    labelMetadata={labelMetadata}
                    projectCustomShortcuts = {project?.projectConfiguration?.shortcuts}
                  />
                  )}

              </div>
            </div>
          </Hotkeys>
        </div>
      </div>
      </ErrorBoundary>
    </Fragment>
     
    );
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.currentUser,
    errors: state.errors,
    language:state.language
  };
}



export default connect(mapStateToProps, null)(withLoadImageData(withHistory(withPredictions(LabelingApp))));
