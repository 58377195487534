import React, { useState, useEffect } from 'react';
import { Button, Dropdown, Form, Segment, Input } from 'semantic-ui-react';
import {useTrafficSettingsContext} from '../../context/TrafficSettingsContext';

// Assuming TimePlot is a component that you've already implemented
import TimePlot from './TimePlot';


const prepareStudyOptions = (fileContent) => {
  return Object.keys(fileContent).map(key => ({
    key,
    text: `Study ${key}`,
    value: key,
  }));
};

function generateSchedule(startDate, hours) {
  const result = [];
  let currentDate = new Date(startDate);
  
  while (hours.length > 0) {
      let availableHours = hours.slice(0, 24).map(formatHour);
      hours = hours.slice(24);
      
      result.push({
          date: formatDate(currentDate),
          availableHours: availableHours
      });
      
      currentDate.setDate(currentDate.getDate() + 1);
  }
  
  return result;
}

function formatDate(date) {
  const mm = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() is zero-based
  const dd = String(date.getDate()).padStart(2, '0');
  const yyyy = date.getFullYear();
  
  return mm + '-' + dd + '-' + yyyy;
}

function formatHour(hour) {
  const intHour = parseInt(hour, 10) % 2400;
  return String(intHour).padStart(4, '0');
}

const findUniqueDates = (studyData) => {
  if(!studyData){
    return;
  }
  const dates = new Set();
  studyData.forEach(folder => {
    folder.captureFolders.forEach(captureFolder => {
      console.log("captureFolder",captureFolder);
      //Check if availableHours extend further than 24H
      const availableHrs = generateSchedule(captureFolder.availableDate,captureFolder.availableHours);
      console.log("availableHrs",availableHrs);
      for (let avDateHour of availableHrs){
        dates.add(avDateHour.date);
      }
      
    });
  });
  console.log("datesset",dates);
  return Array.from(dates).sort();
};

const StudySelector = (props) => {
  const {studyData,handleStudyDataChange,currentSelectedStudy,onHandleSelectedStudy,onAvailableStudiesChange,fileContent,onFileContentChange,studyOptions,trims,onStudyOptionsChange,onTrimsChange} = useTrafficSettingsContext();

  useEffect(()=>{
    if(currentSelectedStudy){
      const selectedStudyData = fileContent[currentSelectedStudy];
      const dates = findUniqueDates(selectedStudyData);
      const initialTrims = dates.reduce((acc, date) => ({ ...acc, [date]: [] }), {});
      onTrimsChange(initialTrims);
    }
  },[currentSelectedStudy]);

  const handleFileChange = (event) => {
    const fileReader = new FileReader();
    fileReader.readAsText(event.target.files[0], "UTF-8");
    fileReader.onload = e => {
      const content = JSON.parse(e.target.result);
      console.log("handleFileChange",content);
      onFileContentChange(content);
      const preparedStudyOptions = prepareStudyOptions(content);
      const selectedStudy = Object.keys(content)[0];
      const avStudies = Object.keys(content);
      const selectedStudyData = content[selectedStudy];
      const dates = findUniqueDates(selectedStudyData);
      const initialTrims = dates.reduce((acc, date) => ({ ...acc, [date]: [] }), {});
      onTrimsChange(initialTrims);
      onStudyOptionsChange(preparedStudyOptions);
      onHandleSelectedStudy(selectedStudy);
      onAvailableStudiesChange(avStudies);
      handleStudyDataChange({ ...studyData,fileContent:content,trims:initialTrims });
    };
  };

  const handleAddTrim = (date) => {
    const newTrims = { ...trims, [date]: [...trims[date], { start: '', end: '' }] };
    onTrimsChange(newTrims);
    handleStudyDataChange({ fileContent, currentSelectedStudy, trims: newTrims });
  };

  const handleDeleteTrim = (date, index) => {
    const newTrimsForDate = trims[date].filter((_, i) => i !== index);
    const newTrims = { ...trims, [date]: newTrimsForDate };
    onTrimsChange(newTrims);
    handleStudyDataChange({ fileContent, currentSelectedStudy, trims: newTrims });
  };

  const handleTrimChange = (date, index, type, value) => {
    const newTrimsForDate = [...trims[date]];
    newTrimsForDate[index][type] = value;
    const newTrims = { ...trims, [date]: newTrimsForDate };
    onTrimsChange(newTrims);
    handleStudyDataChange({ fileContent, currentSelectedStudy, trims: newTrims });
  };

  return (
    <Segment>
      <Form>
        {!studyData && (
          <Form.Field>
          <label>Upload JSON File</label>
          <input type="file" onChange={handleFileChange} accept=".json" />
        </Form.Field>
        )}
        
        {/* {!currentSelectedStudy && (<Form.Field>
          <label>Select Study</label>
          <Dropdown
            placeholder='Select Study'
            fluid
            selection
            options={studyOptions}
            value={currentSelectedStudy}
            onChange={handleStudySelection}
          />
        </Form.Field>)} */}
        {currentSelectedStudy && (
          <>
            {fileContent[currentSelectedStudy]?.map(folder => (
              <Segment key={folder.folderName}>
                <h3>{folder.folderName}</h3>
                {folder.captureFolders.map(captureFolder => (
                  <div key={captureFolder.captureFolderName}>
                    <div><strong>{captureFolder.captureFolderName}</strong></div>
                      {generateSchedule(captureFolder.availableDate,captureFolder.availableHours).map((dateHrObj)=>{
                        return (
                        <>
                        <p>Date: {`${dateHrObj?.date}`}</p>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                          <TimePlot availableHours={dateHrObj?.availableHours} width={600} height={50} />
                        </div>
                        </>
                        )
                      })
                      }
                  </div>
                ))}
              </Segment>
            ))}
            {Object.keys(trims).length > 0 && (
              <Segment>
                <h4>Trims</h4>
                {Object.keys(trims)?.map((date, index) => (
                  <Segment key={index}>
                    <h5>Date: {date}</h5>
                    {trims[date].map((trim, trimIndex) => (
                      <>
                      <h5>Please type dates in military time. Example: 6AM = 0600, 6PM = 1800</h5>
                      <Form.Group key={trimIndex} style={{display:"flex",justifyContent:"center"}}>
                        <Input
                          placeholder="Start Time"
                          value={trim.start}
                          onChange={(e) => handleTrimChange(date, trimIndex, 'start', e.target.value)}
                        />
                        <Input
                          placeholder="End Time"
                          value={trim.end}
                          onChange={(e) => handleTrimChange(date, trimIndex, 'end', e.target.value)}
                        />
                        <Button negative type="button" onClick={() => handleDeleteTrim(date, trimIndex)}>Delete</Button>
                      </Form.Group>
                      </>
                    ))}
                    <Button primary type="button" onClick={() => handleAddTrim(date)}>Add Trim</Button>
                  </Segment>
                ))}
              </Segment>
            )}
          </>
        )}
      </Form>
    </Segment>
  );
};

export default StudySelector;

