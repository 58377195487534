import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'semantic-ui-react';
import { pad, validateTimeInput } from '../../utils/input';

const format = (seconds, showHour) => {
	const date = new Date(seconds * 1000);
	const hh = date.getUTCHours();
	const mm = pad(date.getUTCMinutes(), 2);
	const ss = pad(date.getUTCSeconds(), 2);
	const ms = pad(date.getUTCMilliseconds(), 3);
	if (hh || showHour) {
		return `${hh}:${mm}:${ss}:${ms}`;
	}
	return `${mm}:${ss}:${ms}`;
};

const FormattedTime = ({ className, seconds, handleSaveTime }) => {

	const [isEditing, setIsEditing] = React.useState(false);
	const [inputValue, setInputValue] = React.useState('');
	const inputRef = React.useRef(null);

	const resetInputValue = () => {
		setInputValue(format(seconds, true));
	}

	const handleChangeTime = () => {
		const timeParts = inputValue.split(':');
		if (timeParts.length !== 4) {
			resetInputValue();
			return;
		}
		const [hh, mm, ss, ms] = timeParts.map(Number);
		const date = new Date();
		date.setHours(0, 0, 0, 0);
		const midNight = date.getTime();
		date.setHours(hh, mm, ss, ms);
		const newSeconds = (date.getTime() - midNight) / 1000;
		handleSaveTime(newSeconds);
		setIsEditing(false);
	}

	const focusInput = () => {
		if (inputRef.current) {
			resetInputValue();
			inputRef.current.focus();
		}
	}

	const blurInput = () => {
		handleChangeTime();
	}

	const keyPressInput = (e) => {
		if (e.key === "Enter") handleChangeTime();
	}

	const handleValue = (e) => {
		const validated = validateTimeInput(e);
		if (validated !== null) setInputValue(validated)
	}

	React.useEffect(() => {
		if (isEditing) focusInput()
	}, [isEditing])

	return (
		<>
			{
				isEditing ?
					<Input
						ref={inputRef}
						value={inputValue}
						onBlur={blurInput}
						onKeyPress={keyPressInput}
						onChange={handleValue}
						className="incident-list__item-duration-input"
					/>
					:
					<time
						dateTime={`P${Math.round(seconds)}S`}
						className={className}
						onDoubleClick={(e) => setIsEditing(true)}
					>
						{format(seconds)}
					</time>
			}
		</>
	)
};

FormattedTime.propTypes = {
	className: PropTypes.string,
	seconds: PropTypes.number,
};
FormattedTime.defaultProps = {
	className: '',
	seconds: 0,
};

export default FormattedTime;
