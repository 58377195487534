import axios from "axios";

export function setTokenHeader(token) {
  if (token) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common["Authorization"];
  }
}

export function apiCall(method, path, data) {
  //debugger;
  return new Promise((resolve, reject) => {
    return axios[method.toLowerCase()](path, data)
      .then(res => {
        return resolve(res.data);
      })
      .catch(err => {
        if(("response" in err ) && (err.response)&& ("data" in err.response)){
          return reject(err.response.data.error);
        }else {
          return reject(err);
        }
      });
  });
}


export function multipartApiCall(method, path, data) {
  //debugger;
  return new Promise((resolve, reject) => {
    const config = {
        headers: {
            'content-type': 'multipart/form-data'
        }
    }
    return axios[method.toLowerCase()](path, data, config)
      .then(res => {
        return resolve(res.data);
      })
      .catch(err => {
        return reject(err.response.data.error);
      });
  });
}
