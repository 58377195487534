import React, {useState, useEffect} from 'react';
import { Segment, Button, Icon, Grid, Form, TextArea, Input, Image, Divider, Loader, Dimmer, Container, Label} from 'semantic-ui-react';
import '../App.css';import { HashLink as Link } from 'react-router-hash-link';
import {Helmet} from "react-helmet";
import LCMSImage from '../images/May_Apple_Drive1420.0_ImageRng_20_1-15_2_1_3_10_1_10_2 (1).jpg';
import {apiCall} from "../services/api";

const DamageAnalysis = (props)=>{

	useEffect(()=>{
		const getApiHealth = async ()=>{
			try{
				await apiCall("GET", `${process.env.REACT_APP_PRODUCTION_SERVER_URL}/api/health`);
			}catch(err){
				console.log(err);
			}
		};
		getApiHealth();
	},[]);

	const [isLoading, setLoading] = useState(false);
	const [isDemoRun, setDemoRun] = useState(false);
	const [results, setResults] = useState({});
	const browserLang = props.browserLang;
	const spanish = browserLang.indexOf("es") > -1;
	const imgUrl = "images/background-image-hero.jpg";
	let backgroundImg = `linear-gradient(45deg, rgb(229, 44, 78) 1%, rgb(233, 74, 99) 30%, rgb(174, 82, 157) 69%, rgb(154, 85, 177) 100%),url(${imgUrl})`;
	const analyzeApi = async ()=>{
		setLoading(true);
		try{
			const results = await apiCall("POST", `${process.env.REACT_APP_PRODUCTION_SERVER_URL}/api/analyze`,{fileUrl:"../images/May_Apple_Drive1420.0_ImageRng_20_1-15_2_1_3_10_1_10_2 (1).jpg"});
			setResults(results);
			setDemoRun(true);
			setLoading(false);
		}catch(err){
			console.log(err);
		}

	};

	let distressElements = []; 
	if(results && "distresses" in results){
		distressElements = results.distresses.map((distress)=>{
			let severityPretty = spanish ? "Baja" : "Low";
			let severityLabelColor = "yellow";
			switch(distress.severity){
				case 2:
					severityPretty =  spanish ? "Media" : "Medium";
					severityLabelColor = "orange";
					break;
				case 3:
					severityPretty =  spanish ? "Alta" : "High";
					severityLabelColor = "red";
					break;

			}

			return(
					<Segment>
						<h4><strong>{distress.type}</strong></h4>
						<Grid stackable>
							<Grid.Row columns={2}>
								<Grid.Column style={{"textAlign":"left"}}>
									<p><strong>{spanish ? "Ancho" : "Width"}:</strong> {distress.width} {spanish ? "metros" : "meters"}</p>
								</Grid.Column>
								<Grid.Column style={{"textAlign":"left"}}>
									<p><strong>{spanish ? "Alto" : "Height"}:</strong> {distress.height} {spanish ? "metros" : "meters"}</p>
								</Grid.Column>
							</Grid.Row>
						</Grid>
						<br/>
						<p><strong>{spanish ? "Severidad" : "Severity"}:</strong> <Label color={severityLabelColor}>{severityPretty}</Label></p>
					</Segment>
				);

			});
	}	

	return(
		<>
		<header className="App-hero" style={{
			"minHeight":"100vh",
			"backgroundImage":backgroundImg,
			"color":"white"
		}}>
         <Helmet>
			<title>
				Engin.AI - Pavement Damage Analysis |Pavement Inspection and Auditing
			</title>
			<meta name="description" content="Save money and increase accuracy of your pavement inspections using our AI -powered software to detect damages in pavement and its different categories." />
		    <meta property="og:title" content="Engin.AI - AI Consulting | Data Strategy | AI Strategy"/>
		    <meta property="og:type" content="website"/>
		    <meta property="og:url" content="https://www.engin.ai"/>
		    <meta property="og:description" content="Save money and increase accuracy of your pavement inspections using our AI -powered software to detect damages in pavement and its different categories."/>
		    <meta name="keywords" content="engin ai, enginai, engin.ai, AI for civil engineering, computer vision, pavement inspection, pavement inspection with ai, pavement inspection machine learning"/>
		    <meta name="robots" content="index, follow"/>
		    <meta http-equiv="Content-Type" content="text/html; charset=utf-8"/>
		    <meta name="language" content="English"/>
		    <meta name="author" content="Javier Urbina"/>
		    <meta property="og:image" content="%PUBLIC_URL%/full-color-logo-only-01-01.png" />
		</Helmet>
		<Segment basic style={{width:"70%","zIndex":"10"}} className="cta-hero-section">
		<Grid stackable>
			<Grid.Row columns={2}>
				<Grid.Column style={{"textAlign":"left"}}>
					<h1 style={{"textTransform": "uppercase"}}>
			{spanish ? (
				"Análisis de Daños en Pavimentos"
				):
				"Analysis of Pavement Damage"}
			
			</h1>
			<br/>
			<br/>
			<h2 style={{"lineHeight":"1.5"}}>{spanish ? 
				"¿Tiene fotos de pavimentos para analizar? Con nuestros algoritmos de visión computacional, nosotros podemos analizarlos con gran precisión y a un tiempo récord en tiempo récord." : 
				"Do you have photos pavements to analyze? With our computer vision algorithms, we can analyze them with great accuracy and in a record time."}</h2>
			<br/>
		
			
				</Grid.Column>
				<Grid.Column>
					<Segment id="contact-us">
						<Form action="https://formspree.io/engin.ai.solutions@gmail.com" method="POST" style={{"color":"black"}}>
							<h2>
								{spanish? "Contáctenos para Hacer un Demo"
									:
									"Request a Demo"
								}
							</h2>
							<Segment basic style={{"textAlign":"left"}}>
							<Form.Field>
								<label htmlFor="user_name">Name</label>	
								<Input name="name" id="user_name" placeholder="John Doe">
								</Input>
							</Form.Field>
							<Form.Field>
								<label htmlFor="email">Email</label>	
								<Input required name="name" id="email" placeholder="jdoe@example.com">
								</Input>
							</Form.Field>
							<Form.Field>
								<label htmlFor="company">Company</label>	
								<Input name="name" id="company" placeholder="My Business">
								</Input>
							</Form.Field>
							<Form.Field>
								<label htmlFor="comment">Comment</label>	
								<TextArea name="name" id="comment">
									
								</TextArea>
								
							</Form.Field>
							</Segment>
							
								<Button type="submit" color="red" size="huge" syle={{"width":"50%"}} >
								{spanish?
									<span className="buttonText">Contáctenos</span> 
									 : 
									<span>Contact Us</span>}
								<span className ="iconContainer" style={{"display":"inline-block", "width":"10px"}}>
									<Icon name="chevron right">
										
									</Icon>
								</span>
							</Button>
						</Form>
					</Segment>
				</Grid.Column>
			</Grid.Row>
		</Grid>
			
					<Link to="/#contact-us">
				

			</Link>
		</Segment>

      </header>
     	<Segment className="offsetSegment" style={{position: "relative", margin:"0 auto", width:"95%"}}>
			<h2>{spanish? "Prueba Nuestra Aplicación":"Try it Yourself"}</h2>
			{isDemoRun && (
				<Segment raised inverted color="purple">
					<p style={{fontSize:"20px"}}>{spanish ? "¿Quieres hacer un demo con tus propios datos? Analizamos 10 km" : "Do you want a demo with your own data? We'll analyze 10 miles"} <strong>{spanish ? "¡completamente gratis!" : "at no cost!"}</strong></p>  <Link style= {{color:"white", fontSize:"30px"}} to="/pavements-analysis#contact-us"><Button color="red">{spanish ? "Contáctenos Ahora" : "Contact Us Now"}</Button></Link>
				</Segment>
				)}
			<Dimmer.Dimmable as={Container} dimmed={isLoading} style={{minHeight:"400px"}}>
				{isLoading ? 
				<Dimmer active inverted>
					<Loader size="large" active={isLoading}/>
				</Dimmer>
					:
					(isDemoRun? 
						<Grid stackable>
								<Grid.Column width={10}>
									<Segment>
										<h2>{spanish ? "Reporte" : "Report"}</h2>
										<Segment textAlign='left' basic>
											<h3 style={{alignSelf:"left"}}>{spanish ? "Activaciones" : "Activations"}</h3>
											<Image src={results.activationsUrl}/>
											<h3 style={{alignSelf:"left"}}>{spanish ? "Daños Encontrados" : "Distresses"}</h3>
											{distressElements}
										</Segment>
									</Segment>
								</Grid.Column>
								<Grid.Column width={6}>
									<Image centered src={LCMSImage}/>		
								</Grid.Column>
						</Grid>
						:
						<Image centered src={LCMSImage}/>

					)
				}
			</Dimmer.Dimmable>
			<Divider hidden/>
			<Button disabled={isDemoRun} onClick={()=>analyzeApi()} loading={isLoading} color="green">{spanish? "Analizar":"Analize"}</Button>
		</Segment>
		</>
		);	
} 

export default DamageAnalysis;