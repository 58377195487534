import React  from 'react';
import { Table, Progress} from 'semantic-ui-react';
import moment from "moment";

const UploadHistory = (props)=>{
	const browserLang = props.browserLang;
	const uploads = props.uploads ? props.uploads: [];
	const uploadRows = uploads.map((uploadObj, idx)=>{
		if(!uploadObj){
			return null;
		}
		const progress = Math.floor((uploadObj.uploadedFileCount / uploadObj.numFiles)*100);
		return (
			<Table.Row key={idx}>
		        <Table.Cell>{moment(uploadObj.createdAt).format("MMM DD YYYY")}</Table.Cell>
		        <Table.Cell>{uploadObj.customFolderName}</Table.Cell>
		        <Table.Cell>{uploadObj.numFiles}</Table.Cell>
		        <Table.Cell><Progress percent={progress} indicating progress /></Table.Cell>
		        <Table.Cell>{`${(uploadObj.uploadSizeBytes/1000000000).toFixed(2) > 1 ? `${(uploadObj.uploadSizeBytes/1000000000).toFixed(2)} GB` : `${(uploadObj.uploadSizeBytes/1000000).toFixed(2)} MB` }`}</Table.Cell>
		      </Table.Row>
			)
	});

	return(
		<Table celled>
		    <Table.Header>
		      <Table.Row>
		        <Table.HeaderCell>{browserLang === "en"? "Upload date": "Fecha de Carga"}</Table.HeaderCell>
		        <Table.HeaderCell>{browserLang === "en"? "Folder Name": "Nombre de Carpeta"}</Table.HeaderCell>
		        <Table.HeaderCell>{browserLang === "en"? "Number of Files": "Número de Archivos"}</Table.HeaderCell>
		        <Table.HeaderCell>{browserLang === "en"? "Progress":"Progreso"}</Table.HeaderCell>
		        <Table.HeaderCell>{browserLang === "en"? "Size":"Tamaño"}</Table.HeaderCell>
		      </Table.Row>
		    </Table.Header>
		    <Table.Body>
		    	{uploadRows}
		    </Table.Body>
		  </Table>
		);	
}; 

export default UploadHistory;