import React from 'react'
import { Table, Button, Icon, Dropdown } from 'semantic-ui-react';
import { DefaultMetadataItem, LabelMetadataBooleanValues, LabelMetadataTypes } from '../../services/utils/labelMetadata';
import MetadataInput from './MetadataInput';

const MetadataItems = (props) => {

  const [items, setItems] = props.itemsState;

  const changeValues = (key, index, value) => {
    const newItems = [...items];
    newItems[index][key] = value;
    if (key === 'type') {
      if (value === 'boolean') newItems[index].defaultValue = true;
      else if (value === 'number') newItems[index].defaultValue = 1;
      else newItems[index].defaultValue = '';
    }
    setItems(newItems);
  }

  const addItem = () => {
    setItems([...items, { ...DefaultMetadataItem }]);
  }

  const deleteItem = (index) => {
    const newItems = [...items];
    newItems.splice(index, 1);
    setItems(newItems);
  }

  const renderInput = (key, index, value, type, style) => {
    return (
      <MetadataInput
        key={`${key}-${value}`}
        keyValue={key}
        index={index}
        value={value}
        type={type}
        changeValues={changeValues}
        style={style}
      />
    )
  }

  return (
    <>
      <Table celled >
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell collapsing >Key</Table.HeaderCell>
            <Table.HeaderCell collapsing >Name</Table.HeaderCell>
            <Table.HeaderCell>Description</Table.HeaderCell>
            <Table.HeaderCell collapsing >Type</Table.HeaderCell>
            <Table.HeaderCell collapsing >Default Value</Table.HeaderCell>
            <Table.HeaderCell># of Labels</Table.HeaderCell>
            <Table.HeaderCell textAlign='center' >Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {
            items.map((item, index) => {
              return (
                <Table.Row key={item.key} >
                  <Table.Cell>
                    {renderInput("key", index, item.key, undefined, { maxWidth: "100px" })}
                  </Table.Cell>
                  <Table.Cell>
                    {renderInput("name", index, item.name)}
                  </Table.Cell>
                  <Table.Cell>
                    {renderInput("description", index, item.description, undefined, { width: "100%" })}
                  </Table.Cell>
                  <Table.Cell>
                    <Dropdown
                      search
                      placeholder="Select a value type"
                      options={LabelMetadataTypes}
                      selection
                      value={item.type}
                      onChange={(e, { value }) =>
                        changeValues('type', index, value)
                      }
                      style={{ minWidth: "120px" }}
                    />
                  </Table.Cell>
                  <Table.Cell>
                    {
                      item.type === "boolean" ?
                        <Dropdown
                          search
                          placeholder="Select a value type"
                          options={LabelMetadataBooleanValues}
                          selection
                          value={item.defaultValue}
                          onChange={(e, { value }) =>
                            changeValues('defaultValue', index, value)
                          }
                          style={{ minWidth: "150px" }}
                        />
                        :
                        renderInput("defaultValue", index, item.defaultValue, item.type === "number" ? "number" : undefined, { maxWidth: "150px" })
                    }
                  </Table.Cell>
                  <Table.Cell>
                    {item.labels?.length ?? 0}
                    <Icon
                      className="hoveredWidget"
                      name='edit'
                      onClick={() => props.setSelectedItem(items[index])}
                      style={{ marginLeft: '0.5em' }}
                    />
                  </Table.Cell>
                  <Table.Cell textAlign='center' >
                    <Button onClick={() => deleteItem(index)} icon color='red' >
                      <Icon name='trash' />
                    </Button>
                  </Table.Cell>
                </Table.Row>
              )
            })
          }
        </Table.Body>
      </Table>
      <Button onClick={addItem} icon color="teal" labelPosition="left" style={{ marginTop: '2em' }}>
        <Icon name="plus" />
        Add Metadata Item
      </Button>
    </>
  );
}

export default MetadataItems;