import React from 'react';
import PropTypes from 'prop-types';
import {
  Modal, Header, Button, Checkbox,
} from 'semantic-ui-react';
import './popupdialog.css';

const PopupDialog = ({
  isOpen,
  title,
  message,
  onToggle,
  onDontShowAgainChange,
  onYesClick,
  hasDontShowAgain,
  hasCloseButton,
  hasYesNoButton,
}) => (
  <Modal open={isOpen} onClose={onToggle} closeOnEscape={false} closeOnDimmerClick={false}>
    <Modal.Header>{title}</Modal.Header>
    <Modal.Content>
      <Modal.Description>{message}</Modal.Description>
    </Modal.Content>
    <Modal.Actions>
      {hasDontShowAgain && (
        <Checkbox
          label="Don't show again"
          onChange={onDontShowAgainChange}
          className="d-flex align-items-center"
        />
      )}
      {hasYesNoButton && (
        <React.Fragment>
          <Button color='blue' onClick={onYesClick}>Yes</Button>
          <Button color='grey' onClick={onToggle}>No</Button>
        </React.Fragment>
      )}
      {hasCloseButton && <Button color='grey' onClick={onToggle}>Close</Button>}
    </Modal.Actions>
  </Modal>
);

PopupDialog.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  message: PropTypes.string,
  hasDontShowAgain: PropTypes.bool,
  hasCloseButton: PropTypes.bool,
  hasYesNoButton: PropTypes.bool,
  onToggle: PropTypes.func,
  onYesClick: PropTypes.func,
  onDontShowAgainChange: PropTypes.func,
};

PopupDialog.defaultProps = {
  isOpen: false,
  title: '',
  message: '',
  hasDontShowAgain: false,
  hasCloseButton: false,
  hasYesNoButton: false,
  onToggle: () => {},
  onYesClick: () => {},
  onDontShowAgainChange: () => {},
};

export default PopupDialog;
