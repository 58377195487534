import React, { useState, useEffect, useRef, useCallback } from 'react';
import { apiCall } from '../../services/api';
import { Image } from 'semantic-ui-react';
import { useLocation } from 'react-router-dom';
import ReactDOM from 'react-dom';
import BackHome from '../../images/global-images/menus/back-home.svg';
import DropDown from '../../images/global-images/menus/dropdown-icon.svg';
import DropUp from '../../images/global-images/menus/dropup-icon.svg';
import Magnifying from '../../images/global-images/menus/magnifyging.svg';
import HomeIcon from '../../images/global-images/menus/home-icon.svg';
import DataExplorer from '../../images/global-images/menus/data-explorer.svg';
import Reports from '../../images/global-images/menus/reports.svg';
import '../../styles/ProjectNavigator.css';
import _ from 'lodash';

const ProjectOverview = ({ user, isSidebarExpanded }) => {
    const companyIds = user.companyIds[0];
    const userRole = user.role;
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [projectTitle, setProjectTitle] = useState('');
    const [filterText, setFilterText] = useState('');
    const [activeItem, setActiveItem] = useState('General details');
    const dropdownRef = useRef(null);
    const [filteredProjects, setFilteredProjects] = useState([]);

    const location = useLocation();
    const currentPath = location.pathname;
    const projectId = currentPath.split('/').pop();


    const handleItemClick = (name) => {
        setActiveItem(name);
    };

    const handleToggleDropdown = () => {
        setIsDropdownOpen(prevState => {
            if(!prevState === true){
                queryProjectsByName("");  
            }
            return !prevState
        });
    };

    const capitalize = (str) => {
        return str.replace(/\b\w/g, (char) => char.toUpperCase());
    };
    
    const queryProjectsByName = async (name)=>{
        const foundProjects = await apiCall("GET",`${process.env.REACT_APP_PRODUCTION_SERVER_URL}/api/projects/search?q=${name}`);
        setFilteredProjects(foundProjects);
        return foundProjects;
    }

    useEffect(() => {

        const fetchCurrentProject = async () => {
            try {
                const response = await apiCall('get', `${process.env.REACT_APP_PRODUCTION_SERVER_URL}/api/projects/${projectId}`);
                const project = response;
                setProjectTitle(project?.name || '');
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };
        fetchCurrentProject();
    }, [companyIds, projectId]);

    const debouncedQuery = useCallback(
        _.debounce((filter) => {
            queryProjectsByName(filter);
        }, 300), // Adjust the delay as needed
        []
    );

    useEffect(() => {
        if (filterText) {
            debouncedQuery(filterText);
        }else{
            debouncedQuery("");
        }
        // Cleanup function to cancel the debounced call if component unmounts or filterText changes
        return () => {
            debouncedQuery.cancel();
        };
    }, [filterText, debouncedQuery]);

    const handleFilterChange = (event) => {
        const filter = event.target.value;
        setFilterText(filter);
    };

    const handleProjectClick = (id) => {
        window.location.href = `#/admin/${id}`;
        window.location.reload();
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsDropdownOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const portalRoot = document.getElementById('portal-root');

    return (
        <>
            <div style={{ textAlign: 'center', borderBottom: 'solid 1px rgba(255, 255, 255, 0.08)' }}>
                <a
                    href="/#/admin"
                    className='back-home-link'
                    style={{
                        justifyContent: isSidebarExpanded ? 'flex-start' : 'center',
                    }}
                >
                    <Image src={BackHome} style={{ marginRight: '10px' }} />
                    {isSidebarExpanded && <span>Back to All Projects</span>}
                </a>
            </div>
            <div className='border-bottom'>
                <div
                    className='project-header-container'
                    onClick={handleToggleDropdown} 
                >
                    <span style={{ flexGrow: 1, textAlign: 'left' }}>
                        {isSidebarExpanded && <>{capitalize(projectTitle)}</>}

                    </span>
                    <div style={{ flexShrink: 0, textAlign: isSidebarExpanded ? 'left' : 'center', width: isSidebarExpanded ? 'auto' : '100%' }}>
                        <button
                            style={{
                                backgroundColor: 'transparent',
                                border: 'none',
                                cursor: 'pointer',
                                padding: '0',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: isSidebarExpanded ? 'auto' : '24px',
                                marginLeft: isSidebarExpanded ? '0' : 'auto',
                                marginRight: isSidebarExpanded ? '0' : 'auto'
                            }}
                        >
                            <Image
                                src={isDropdownOpen ? DropUp : DropDown}
                                style={{ width: '20px', height: '20px' }}
                            />
                        </button>
                    </div>

                </div>
            </div>
            {isDropdownOpen && portalRoot && ReactDOM.createPortal(
                <div className='dropdown-box' ref={dropdownRef}>
                    <div className='dropdown-input-container'>
                        <input
                            type="text"
                            placeholder="Filter projects..."
                            value={filterText}
                            onChange={handleFilterChange}
                            className='custom-input'
                        />
                        <Image src={Magnifying} style={{ width: '20px', height: '20px' }} />
                    </div>

                    {filteredProjects.length > 0 ? (
                        filteredProjects.map(project => (
                            <div
                                key={project._id}
                                onClick={() => handleProjectClick(project._id)}
                                className='projects-grid'
                            >
                                {capitalize(project.name)}
                            </div>
                        ))
                    ) : (
                        <div style={{ padding: '8px' }}>
                            No projects found.
                        </div>
                    )}
                </div>,
                portalRoot
            )}
            <div style={{ textAlign: 'left', padding: '20px 10px' }}>
                <div
                    className={`menu-projects-item ${activeItem === 'General details' ? 'active' : ''}`}
                    style={{ justifyContent: isSidebarExpanded ? 'flex-start' : 'center', }}
                    onClick={() => handleItemClick('General details')}
                >
                    <Image src={HomeIcon} className='menu-icons' />
                    {isSidebarExpanded && <p style={{ marginLeft: '10px' }}>General details</p>}
                </div>
                <div
                    className={`menu-projects-item ${activeItem === 'Data Explorer' ? 'active' : ''}`}
                    style={{ justifyContent: isSidebarExpanded ? 'flex-start' : 'center', }}
                    onClick={() => handleItemClick('Data Explorer')}
                >
                    <Image src={DataExplorer} className='menu-icons' />
                    {isSidebarExpanded && <p style={{ marginLeft: '10px' }}>Data Explorer</p>}
                </div>
                <div
                    className={`menu-projects-item ${activeItem === 'Reports' ? 'active' : ''}`}
                    style={{ justifyContent: isSidebarExpanded ? 'flex-start' : 'center', }}
                    onClick={() => handleItemClick('Reports')}
                >
                    <Image src={Reports} className='menu-icons' />
                    {isSidebarExpanded && <p style={{ marginLeft: '10px' }}>Reports</p>}
                </div>
            </div>
        </>
    );
};

export default ProjectOverview;
