import { apiCall } from "../../services/api";
import { addError } from "./errors";
import { UPDATE_USER } from "../actionTypes";
import { SET_USER_LANGUAGE,SET_USER_SIDEBAR } from "../actionTypes";


//Set user preferred language
export const setUserLanguage = (lang)=>{
 if(!lang){return;}

  localStorage.setItem("language", lang);

 return {
  type:SET_USER_LANGUAGE,
  language:lang
 }

}

//updates the current user as a tutor or student
export const updateCurrentUser = (data)=>{
  if(data){
    return {
      type:UPDATE_USER,
      user:{...data}
    }
  }
  return {};
}


//updates current user
export const updateUser = obj => (dispatch, getState) => {
  //
  //debugger;
  let { currentUser } = getState();
  const id = currentUser.user.id;
  	return apiCall("put", `${process.env.REACT_APP_PRODUCTION_SERVER_URL}/api/users/${id}`, obj)
    .then(res => {
      dispatch(updateCurrentUser(res));
    })
    .catch(err => {
      if(err && err.hasOwnProperty("message")){
        addError(err.message)  
      }else{
        addError(err);
      }
      
    });
  
};


export const setSidebarStatus = (isExpanded=true)=>{ 
   localStorage.setItem("isSidebarExpanded", isExpanded);
 
  return {
   type:SET_USER_SIDEBAR,
   isSidebarExpanded:isExpanded
  }
 
 }