import React, { Suspense, lazy } from "react";
import '../App.css';
import withAuth from "../hocs/withAuth";
import { HashRouter as Router, Route, Switch, withRouter } from "react-router-dom";
import { authUser, logout, setCurrentUser } from "../store/actions/auth";
import { removeError } from "../store/actions/errors";
import Homepage from "../Homepage";
import DataLabeling from "../components/DataLabeling";
import AuthPage from "../components/AuthPage";
import DamageAnalysis from "../components/DamageAnalysis";
import AiConsulting from "../components/AiConsulting";
import Demo from "./Demo";
import ImageVisualizer from "./ImageVisualizer";
import Dashboard from "./Dashboard";
import Menubar from "./Menubar";
import ReactGA from 'react-ga';
import LabelHome from './LabelHome';
import AdminApp from './AdminApp';
import { connect } from "react-redux";
import LabelingLoader from "../label/LabelingLoader";
import OverScreen from "../label/OverScreen";
import ContinuousLabelingApp from "../rate/ContinuousLabelingApp";
import NotFound from "../components/NotFound";
import jwtDecode from "jwt-decode";
import RatingTool from "../components/RatingTool";
import AdminDash from "../components/AdminDash";
import FileTree from "../components/FileTree";
import ReportVisualizer from '../components/ReportVisualizer';
import TrafficCountsConfigScreen from '../components/traffic-counts/TrafficCountsConfigScreen';
import UserSettingsPage from '../containers/UserSettingsPage';


const EnhancedDashboard = withAuth(Dashboard);
const EnhancedAdminDashboard = withAuth(AdminDash);
const EnhancedReportVisualizer = withAuth(ReportVisualizer);
const ResetPasswordForm = lazy(() => import("./ResetPasswordForm"));
const PageHeaderWrapper = lazy(() => import("../components/PageHeaderWrapper"));

const trackPage = (page) => {
  ReactGA.set({
    page
  });
  ReactGA.send({ hitType: "pageview", page: page });
};

class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.isTokenExpired = this.isTokenExpired.bind(this);
  }
  componentDidMount() {
    this.props.history.listen(() => {
      if (this.isTokenExpired()) {
        this.props.logout();
        this.timeoutID = this.timer();
      }
    });
  }

  timer = () => setTimeout(() => { // return the timeoutID
    this.props.history.push("/");
  }, 3000);

  componentWillUnmount() {
    clearTimeout(this.timeoutID);
  }

  isTokenExpired = () => {
    if (localStorage.enginJwtToken) {
      const decodedJwt = jwtDecode(localStorage.enginJwtToken);
      // console.log("decodedJwt",decodedJwt);
      // console.log("decodedJwt.exp",decodedJwt.exp);
      const iatDate = new Date(decodedJwt.iat * 1000);
      let allowedDate = new Date(decodedJwt.iat * 1000);
      allowedDate.setDate(allowedDate.getDate() + 30);
      // console.log("allowedDate",allowedDate);
      if ((!decodedJwt.exp && (allowedDate < Date.now())) || (decodedJwt.exp * 1000 < Date.now())) {
        return true;
      } else {
        return false;
      }
    }
  }

  render() {
    return (
      <Router>
        <Switch>
          <Route
            path="/user/settings"
            render={
              props => {
                const page = this.props.location.pathname;
                return (
                  <Suspense fallback={<div>Loading...</div>}>
                    <PageHeaderWrapper>
                      <UserSettingsPage currentUser={this.props.currentUser} browserLang={this.props.language} errors={this.props.errors}/>
                    </PageHeaderWrapper>
                  </Suspense>
                )
              }
            }
          />
          <Route
            path="/data-labeling"
            render={
              props => {
                const page = this.props.location.pathname;
                trackPage(page);
                return (
                  <DataLabeling browserLang={this.props.language} {...this.props}></DataLabeling>
                );
              }
            }
          >
          </Route>
          <Route
            path="/pavements-analysis"
            render={
              props => {
                const page = this.props.location.pathname;
                trackPage(page);
                return (
                  <DamageAnalysis browserLang={this.props.language} {...this.props}></DamageAnalysis>
                );
              }
            }
          >
          </Route>
          <Route
            path="/ai-consulting"
            render={
              props => {
                const page = this.props.location.pathname;
                trackPage(page);
                return (
                  <AiConsulting browserLang={this.props.language} {...this.props}></AiConsulting>
                )
              }
            }
          >
          </Route>
          <Route
            path="/demo"
            render={
              props => {
                const page = this.props.location.pathname;
                trackPage(page);
                return (
                  <Demo browserLang={this.props.language} {...this.props}></Demo>
                )
              }
            }
          >
          </Route>
          <Route
            path="/visualize"
            render={
              props => {
                const page = this.props.location.pathname;
                trackPage(page);
                return (
                  <ImageVisualizer browserLang={this.props.language} {...this.props}></ImageVisualizer>
                )
              }
            }
          >
          </Route>
          <Route
            path="/rate"
            render={
              props => {
                const page = this.props.location.pathname;
                trackPage(page);
                return (
                  <RatingTool browserLang={this.props.language} {...this.props}></RatingTool>
                )
              }
            }
          >
          </Route>
          <Route
            exact
            path="/label/:projectId/continuous"
            render={
              props => {
                const page = this.props.location.pathname;
                trackPage(page);
                return (
                  <ContinuousLabelingApp browserLang={this.props.language} {...this.props}></ContinuousLabelingApp>
                )
              }
            }
          />
          <Route
            exact
            path="/label/:projectId/:imageId"
            render={props =>
              props.match.params.imageId === 'over' ? (
                <OverScreen browserLang={this.props.language} {...props} currentUser={this.props.currentUser} />
              ) : (
                <LabelingLoader browserLang={this.props.language} {...props} currentUser={this.props.currentUser} />
              )
            }
          />

          <Route
            exact
            path="/label/projects/:projectId/videos/:videoId"
            render={props =>
              props.match.params.videoId === 'over' ? (
                <OverScreen browserLang={this.props.language} {...props} currentUser={this.props.currentUser} />
              ) : (
                <LabelingLoader browserLang={this.props.language} {...props} currentUser={this.props.currentUser} />
              )
            }
          />

          <Route exact path="/label/:projectId" component={LabelingLoader} />
          <Route exact path="/label/projects/:projectId/videos" component={LabelingLoader} />
          <Route path="/label"
            render={
              props => {
                const page = this.props.location.pathname;
                trackPage(page);
                return (
                  <LabelHome browserLang={this.props.language} {...this.props}></LabelHome>
                )
              }
            }
          />
          <Route path="/admin"
            render={
              props => {
                const page = this.props.location.pathname;
                trackPage(page);
                return (
                  <AdminApp browserLang={this.props.language} {...this.props}></AdminApp>
                )
              }
            }
          />
          <Route
            path="/dashboard"
            render={
              props => {
                const page = this.props.location.pathname;
                trackPage(page);
                return (
                  <EnhancedDashboard browserLang={this.props.language} {...this.props}></EnhancedDashboard>
                )
              }
            }
          >
          </Route>

          <Route
            path="/admin-dashboard"
            render={
              props => {
                const page = this.props.location.pathname;
                trackPage(page);
                return (
                  <EnhancedAdminDashboard browserLang={this.props.language} {...this.props}></EnhancedAdminDashboard>
                )
              }
            }
          />

          <Route
            path="/signin"
            render={
              props => {
                const page = this.props.location.pathname;
                trackPage(page);
                return (
                  <AuthPage onAuth={this.props.authUser} browserLang={this.props.language} errors={this.props.errors} buttonText={this.props.language === "en" ? "Login Now" : "Ingresar Ahora"} heading={this.props.language === "en" ? "Welcome Back" : "Bienvenido de Vuelta"} removeError={this.props.removeError} signUp={false} removeAdditionalField={true}  {...this.props}></AuthPage>
                )
              }
            }
          >
          </Route>
          <Route
            exact
            path="/recover"
            render={props => {
              const page = this.props.location.pathname;
              trackPage(page);
              return (
                <Suspense fallback={<div>Loading...</div>}>
                  <PageHeaderWrapper>
                    <ResetPasswordForm
                      removeError={removeError}
                      errors={this.props.errors}
                      authType={"recover"}
                      buttonText={this.props.language === "en" ? "Send Password Reset Email" : "Enviar correo para restablecer contraseña"}
                      heading={this.props.language === "en" ? "Reset Your Password" : "Restablecer Contraseña"}
                      language={this.props.language}
                      {...props}
                    />
                  </PageHeaderWrapper>
                </Suspense>

              );
            }}
          />
          <Route
            exact
            path="/auth/reset/:token"
            render={props => {
              const page = this.props.location.pathname;
              trackPage(page);
              return (
                <Suspense fallback={<div>Loading...</div>}>
                  <PageHeaderWrapper>
                    <ResetPasswordForm
                      removeError={removeError}
                      errors={this.props.errors}
                      authType={"reset"}
                      buttonText={this.props.language === "en" ? "Send Password Reset Email" : "Enviar correo para restablecer conttraseña"}
                      heading={this.props.language === "en" ? "Reset Your Password" : "Restablecer Contraseña"}
                      language={this.props.language}
                      {...props}
                    />
                  </PageHeaderWrapper>
                </Suspense>
              );
            }}
          />

          <Route
            exact path="/filedUpload"
            render={
              props => {
                const page = this.props.location.pathname;
                return (
                  <Suspense fallback={<div>Loading...</div>}>
                    <PageHeaderWrapper>
                      <FileTree browserLang={this.props.language} {...this.props}></FileTree>
                    </PageHeaderWrapper>
                  </Suspense>
                )
              }
            }
          />
          <Route
            exact path="/trafficSettings"
            render={
              props => {
                const page = this.props.location.pathname;
                return (
                  <Suspense fallback={<div>Loading...</div>}>
                    <PageHeaderWrapper>
                      <TrafficCountsConfigScreen />
                    </PageHeaderWrapper>
                  </Suspense>
                )
              }
            }
          />

          <Route
            exact path="/projects/:projectId/visualize"
            render={
              props => {
                const page = this.props.location.pathname;
                trackPage(page);
                return (
                  <Suspense fallback={<div>Loading...</div>}>
                    <PageHeaderWrapper removePadding={true}>
                      <Menubar fluidContent={true} userRole={this.props.currentUser.user.role}>
                        <EnhancedReportVisualizer {...this.props} />
                      </Menubar>
                    </PageHeaderWrapper>
                  </Suspense>
                )
              }
            }
          />

          <Route
            exact path="/"
            render={
              props => {
                const page = this.props.location.pathname;
                trackPage(page);
                return (
                  <Homepage browserLang={this.props.language} {...this.props}></Homepage>
                )
              }
            }
          >

          </Route>
          <Route
            render={
              props => {
                const page = this.props.location.pathname;
                trackPage(page);
                return (
                  <NotFound browserLang={this.props.language} {...this.props}></NotFound>
                )
              }
            }
          />
        </Switch>

      </Router>

    );
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.currentUser,
    errors: state.errors,
    language: state.language
  };
}

export default withRouter(
  connect(mapStateToProps, { authUser, removeError, logout })(Main)
);
