import React,{useState} from 'react';
import { Stage, Layer, Rect, Text } from 'react-konva';

const TimePlot = ({ availableHours, width = 600, height = 50 }) => {
    const [hoveredHour, setHoveredHour] = useState(null);
    const hourWidth = width / 24; // Divide the total width by 24 hours

    // Function to convert hours into rectangle positions
    const hourToRect = (hour) => ({
        x: hourWidth * hour,
        y: 0,
        width: hourWidth,
        height: height,
    });

    const formatHour = (hour) => {
        if (hour === 0) {
        return '12 AM';
        } else if (hour < 12) {
        return `${hour} AM`;
        } else if (hour === 12) {
        return '12 PM';
        } else {
        return `${hour - 12} PM`;
        }
    };


    return (
        <Stage width={width} height={height}>
        <Layer>
                {Array.from({ length: 24 }, (_, i) => {
                    const formattedHour = formatHour(i);
                    return(
                <Text key={i} x={hourWidth * i} y={0} text={formattedHour} fontSize={10} />
                )})}
                {/* Plot available hours */}
                {availableHours.map(hour => {
                    const parsedHour = parseInt(hour) / 100;
                    const { x, y, width, height } = hourToRect(parsedHour);
                    return (
                        <Rect
                        key={hour}
                        x={x}
                        y={y}
                        width={width}
                        height={height}
                        fill="blue"
                        opacity={0.5}
                        onMouseEnter={() => setHoveredHour(hour)}
                        onMouseLeave={() => setHoveredHour(null)}
                        />
                    );
            })}
            {/* Tooltip display */}
            {hoveredHour && (
            <Text
                x={hourToRect(parseInt(hoveredHour) / 100).x}
                y={20}
                text={`${hoveredHour.slice(0, 2)}:00 - ${parseInt(hoveredHour.slice(0, 2)) + 1}:00`}
                fontSize={12}
                fill='white'
                fontStyle='bold'
            />
            )}
        </Layer>
        </Stage>
    );
    };

    export default TimePlot;