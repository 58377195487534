import React, { Component } from 'react';
import LabelingApp from './LabelingApp';

import { Loader } from 'semantic-ui-react';
import DocumentMeta from 'react-document-meta';

import { demoMocks } from './demo';

import {apiCall} from "../services/api";
import _ from "lodash";

import ErrorBoundary from '../containers/ErrorBoundary';


export default class LabelingLoader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      project: null,
      image: null,
      isLoaded: false,
      error: null,
      video: null,
      labelMetadata: {items: []},
      section:null,
      engineeringData: null
    };
    this.handleProjectUpdate = this.handleProjectUpdate.bind(this);
    this.findNextImage = this.findNextImage.bind(this);
    this.findPrevImage = this.findPrevImage.bind(this);
  }

  async fetch(...args) {
    const { projectId } = this.props.match.params;
    if (projectId === 'demo') {
      const path = typeof args[0] === 'string' ? args[0] : args[0].pathname;
      return demoMocks[path](...args);
    }

    return await fetch(...args);
  }

  componentDidMount() {
      this.refetch();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.imageId !== this.props.match.params.imageId) {
      this.refetch();
    }else if (prevProps.match.params.videoId !== this.props.match.params.videoId) {
      this.refetch();
    }
  }

  handleProjectUpdate(projectProps){
    const {project} = this.state;
    if(!project){
      console.log("No project available");
      return;
    }
    // console.log("projectProps",projectProps);
    apiCall("PATCH",`${process.env.REACT_APP_PRODUCTION_SERVER_URL}/api/projects/${project._id}`,{project:{...projectProps}})
      .then((updatedProject)=>{
        this.setState({
          project:{...updatedProject,form:JSON.parse(updatedProject.form)}
        });
      })
      .catch((err)=>{console.log(err)})

  }

  async refetch() {
    this.setState({
      isLoaded: false,
      error: null,
      project: null,
      image: null,
    });

    const { match, history } = this.props;
    let { projectId, imageId,videoId } = match.params;

    try {
      if(history.location.pathname.indexOf("/videos") >= 0){
        const { project, video, labelMetadata } = await apiCall("get",`${process.env.REACT_APP_PRODUCTION_SERVER_URL}/api/videos/getLabelingInfo?projectId=${projectId}${videoId? (`&videoId=${videoId}`):""}`);
        // console.log(project);
        // console.log("image",image);
        if (!video) {
          history.replace(`/label/${projectId}/over`);
          return;
        }

        history.replace(`/label/projects/${project._id}/videos/${video._id}`);

        this.setState({
          isLoaded: true,
          project,
          video,
          labelMetadata,
        });
        return;
      }else{
        const { project, image } = await apiCall("get",`${process.env.REACT_APP_PRODUCTION_SERVER_URL}/api/images/getLabelingInfo?projectId=${projectId}${imageId? (`&imageId=${imageId}`):""}`);
        let section = null;
        if(image && "sectionId" in image && image?.sectionId){
          try{
            section = await apiCall("GET",`${process.env.REACT_APP_PRODUCTION_SERVER_URL}/api/roadSections/${image?.sectionId}`);
          }catch(err){
            console.log(err);
          }
        }
        if (!image) {
          history.replace(`/label/${projectId}/over`);
          return;
        }

        history.replace(`/label/${project._id}/${image._id}`);

        this.setState({
          isLoaded: true,
          project,
          image,
          section
        });
      }
    } catch (error) {
      this.setState({
        isLoaded: true,
        error,
      });
    }
  }

  async pushUpdate(labelData) {
    const { imageId } = this.props.match.params;
    if(imageId){
      await apiCall("PATCH",`${process.env.REACT_APP_PRODUCTION_SERVER_URL}/api/images/${imageId}`,{ labelData:JSON.stringify(labelData) });
    }
  }

  async markComplete() {
    const { imageId,videoId } = this.props.match.params;
    // console.log("markComplete imageId",imageId);
    if(imageId){
      await apiCall("PATCH",`${process.env.REACT_APP_PRODUCTION_SERVER_URL}/api/images/${imageId}`,{ labeled:true });
    }else if(videoId){
      await apiCall("PUT",`${process.env.REACT_APP_PRODUCTION_SERVER_URL}/api/videos/${videoId}`,{ labeled:true });
    }
  }

  findNextImage(project,image){
    let nextImageId = "";
    let projectImages = project?.images; 
    if(image && projectImages){
      // console.log("project images", project.images);
      let imgIdx = _.findIndex(projectImages,(img)=> { return img === image._id});
      // console.log("image index: ", imgIdx);
      const {matchingFiltersImages,projectConfiguration:{filtersAction}} = project;
      if(matchingFiltersImages && matchingFiltersImages.length > 0){
        //Reduce the projectImages array
        if(filtersAction === 'showOnly'){
          projectImages = projectImages.filter((imageId)=>{
            return matchingFiltersImages.indexOf(imageId) >=0;
          });
        }else{
          projectImages = projectImages.filter((imageId)=>{
            return matchingFiltersImages.indexOf(imageId) <0;
          });
        }
          imgIdx = _.findIndex(projectImages,(img)=> { return img === image._id});
      }

      if(imgIdx > -1 && ((imgIdx +1) < projectImages.length)){
        nextImageId = projectImages[imgIdx+1];
      }else{
        //Find the next image that is also not filtered out
        const imgOriginalIdx = _.findIndex(project.images,(img)=> { return img === image._id});
        for (let i=imgOriginalIdx + 1; i<project.images.length; i++){
          const imgFilteredIdx =  _.findIndex(projectImages,(img)=> { return img === project.images[i]});
          if(imgFilteredIdx >= 0){
            nextImageId = projectImages[imgFilteredIdx];
            break;
          }
        }

      }
      // console.log("project images", project.images);
      // console.log("current image index", imgIdx);
      // console.log("nextimage", nextImageId);
    }
      console.log("nextImageId",nextImageId);
      return nextImageId;
  }

  findPrevImage(project,image){
    let prevImageId = "";
    let projectImages = project?.images; 
    if(image && projectImages){
      // console.log("project images", project.images);
      let imgIdx = _.findIndex(projectImages,(img)=> { return img === image._id});
      // console.log("image index: ", imgIdx);
      const {matchingFiltersImages, projectConfiguration:{filtersAction}} = project;
      if(matchingFiltersImages && matchingFiltersImages.length > 0){
        //Reduce the projectImages array
        if(filtersAction === 'showOnly'){
          projectImages = projectImages.filter((imageId)=>{
            return matchingFiltersImages.indexOf(imageId) >=0;
          });
        }else{
          projectImages = projectImages.filter((imageId)=>{
            return matchingFiltersImages.indexOf(imageId) <0;
          });
        }

        imgIdx = _.findIndex(projectImages,(img)=> { return img === image._id});
        console.log("imgIdx",imgIdx);
      }
      if(imgIdx > -1 && ((imgIdx -1) > 0)){
        prevImageId = projectImages[imgIdx-1];
      }else if (imgIdx === -1){
        //Find the next image that is also not filtered out
        const imgOriginalIdx = _.findIndex(project.images,(img)=> { return img === image._id});
        for (let i=imgOriginalIdx - 1; i > 0; i--){
          const imgFilteredIdx =  _.findIndex(projectImages,(img)=> { return img === project.images[i]});
          if(i<0){
            prevImageId = 0;
            break;
          }
          if(imgFilteredIdx >= 0){
            prevImageId = projectImages[imgFilteredIdx];
            break;
          }
        }

      }
      // console.log("project images", project.images);
      // console.log("current image index", imgIdx);
      // console.log("nextimage", prevImageId);
    }
      console.log("prevImageId",prevImageId);
      return prevImageId;
  }

  findNextVideo(project,video){
    let nextVidId = "";
    let projectVideos = project?.videos; 
    if(video && projectVideos){
       let vidIdx = _.findIndex(projectVideos,(vid)=> { return vid === video._id});
       if(vidIdx > -1 && ((vidIdx +1) < projectVideos.length)){
        nextVidId = projectVideos[vidIdx+1];
      }
    }
    return nextVidId;
  }

  findPrevVideo(project,video){
    let prevVidId = "";
    let projectVideos = project?.videos; 
    if(video && projectVideos){
       let vidIdx = _.findIndex(projectVideos,(vid)=> { return vid === video._id});
       if(vidIdx > -1 && ((vidIdx -1) >= 0)){
          prevVidId = projectVideos[vidIdx-1];
        }else if (vidIdx < 0){
          prevVidId = projectVideos[0];
        }
    }
    return prevVidId;
  }

  render() {
    const { history } = this.props;
    const { project, image, isLoaded, error, video, labelMetadata,section} = this.state;

    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <Loader active inline="centered" />;
    }

    const title = `Image ${image && image._id? image._id:""} for project ${project && project.name?project.name: "" } -- Label Tool`;

    const props = {
      onBack: () => {
        const {video, image, project} = this.state;
        if(image){
          let prevImageId = this.findPrevImage(project,image);
          if(prevImageId && prevImageId !== ""){
            history.push(`/label/${project._id}/${prevImageId}`);
            return;  
          }
          history.push(`/label/${project._id}`);
        }else if(video){ 
          let prevVidId = this.findPrevVideo(project,video);
          if(prevVidId && prevVidId !== ""){
            history.push(`/label/projects/${project._id}/videos/${prevVidId}`);
            return;  
          }
          history.push(`/label/projects/${project._id}/videos`);
        }
      },
      onGoToAdminView : ()=>{
        history.push(`/admin/${project._id}`)
      },
      onSkip: () => {
        const {video, image, project} = this.state;
        if(image){
          const nextImageId = this.findNextImage(project,image);
          if(nextImageId && nextImageId !== ""){
            history.push(`/label/${project._id}/${nextImageId}`);
            return;  
          }
          history.push(`/label/${project._id}`);
        }else if(video){
          const nextVidId = this.findNextVideo(project,video);
          if(nextVidId && nextVidId !== ""){
            history.push(`/label/projects/${project._id}/videos/${nextVidId}`);
            return;  
          }
          history.push(`/label/${project._id}/videos`);
        }
      },
      onSubmit: () => {
        this.markComplete();
        const {video, image, project} = this.state;
        if(image){
          const nextImageId = this.findNextImage(project,image);

          if(nextImageId && nextImageId !== ""){
            history.push(`/label/${project._id}/${nextImageId}`);
            return;  
          }
          history.push(`/label/${project._id}`);
        }else if(video){
          const nextVidId = this.findNextVideo(project,video);

          if(nextVidId && nextVidId !== ""){
            history.push(`/label/projects/${project._id}/videos/${nextVidId}`);
            return;  
          }
          history.push(`/label/projects/${project._id}/videos`);
        }
      },
      onLabelChange: this.pushUpdate.bind(this)
    };

    const { referenceLink, referenceText } = project;
    let labelData = {};
    let imageUrl = null;
    let videoUrl = null;
    if(image){
      labelData = image.labelData.labels || {};
      if("externalLink" in image && image.externalLink){
        imageUrl =  image.externalLink;       
      }else{
        imageUrl = `${process.env.REACT_APP_PRODUCTION_SERVER_URL}${image.link}`;
      }
    }else if(video){
      labelData = video.labelData || [];
      videoUrl=video.externalLink;
    }

    return (
      <ErrorBoundary>  
      <DocumentMeta title={title}>
        <LabelingApp
          video={video}
          labelMetadata={labelMetadata}
          videoUrl={videoUrl}
          labels={project.form.formParts}
          reference={{ referenceLink, referenceText }}
          labelData={labelData}
          image={image}
          imageUrl={imageUrl}
          externalImageUrl={image?.externalLink}
          fetch={this.fetch.bind(this)}
          demo={project._id === 'demo'}
          labeled={image?.labeled || video?.labeled}
          project={project}
          onProjectUpdate={this.handleProjectUpdate}
          section={section}
          {...props}
        />
      </DocumentMeta>
      </ErrorBoundary>
    );
  }
}
