import React from 'react';
import '../styles/UsersStyles.css';
import GlobalSideBar from '../components/global-navbar/GlobalSideBar';
import UserSettings from '../components/user-component/UserSettings';

const UserSettingsPage = ({ currentUser, browserLang, errors }) => {

    return (
        <>
            <div style={{ display: 'flex' }}>
                <GlobalSideBar/>
                <UserSettings currentUser={currentUser} browserLang={browserLang} errors={errors} />
            </div>
        </>
    );
}

export default UserSettingsPage;