import React, { useState, useEffect } from 'react';
import { Button, Grid,StepGroup, Segment, Form, Input, Select, Container, Checkbox, Menu,MenuItem} from 'semantic-ui-react';
import moment from 'moment';
import 'moment-timezone';
import { saveAs } from 'file-saver';
import StudySelector from './StudySelector';
import AnnotateStudy from './AnnotateStudy';
import {TrafficSettingsProvider} from '../../context/TrafficSettingsContext';
import PreventClose from "../global-components/PreventClose";


function isEmptyObject(obj) {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
}

const TrafficCountsConfigScreen = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [formValues, setFormValues] = useState({
    projectId:"",
    projectName:"",
    studyName:"",
    latitude:"",
    longitude:"",
    projectType:"TMC",
    classifications:"Lights/Other Vehicles",
    timeZone:"US/Eastern"
  });
  const [studyData, setStudyData] = useState(null);
  const [legData,setLegData] = useState([{ direction: '', name: '', oneWay: false, uTurnAvailable:true,crosswalk:true }]);
  const [savedData,setSavedData] = useState({});
  const [currentSelectedStudy,setSelectedStudy] = useState("");
  const [availableStudies,setAvailableStudies] = useState([]);
  const [fileContent, setFileContent] = useState({});
  const [studyOptions, setStudyOptions] = useState([]);
  const [trims, setTrims] = useState({});
  const [stepErrors,setStepErrors] = useState({1:{},2:{},3:{},4:{}});

  // useEffect(() => {
  //   if (studyData) {
  //     const { fileContent: fc, currentSelectedStudy: ss, trims: tr } = studyData;
  //     console.log("fileContent",fc);
  //     console.log("currentSelectedStudy",ss);
  //     console.log("trims",tr);
  //     // setFileContent(fc || {});
  //     if(!ss){
  //       setSelectedStudy(Object.keys(fileContent)[0]);
  //     }
  //     setStudyOptions(prepareStudyOptions(fc || {}));
  //     setAvailableStudies(Object.keys(fileContent));
  //     setTrims(tr || {});
  //   }
  // },[]);


  const timeZones = moment.tz.names().map(zone => ({ key: zone, text: zone, value: zone }));
  const projectTypes = [
    { key: 'TMC', text: 'TMC', value: 'TMC' },
    { key: 'ADT', text: 'ADT', value: 'ADT' },
  ];
  const classificationsOptions = [
    'All Vehicles', 
    'Lights/Other Vehicles',
    'Motorcycles/Other Vehicles',
    'Lights/Mediums/Articulated Trucks',
    'Lights/Buses/Trucks',
    'Motorcycles/Cars & Light Goods/Other Vehicles',
    'Lights/Buses/Single-Unit Trucks/Articulated Trucks',
    'Motorcycles/Cars & Light Goods/Buses/Single-Unit Trucks/Articulated Trucks'
    // Add more classifications as necessary
  ].map(classification => ({ key: classification, text: classification, value: classification }));
  const legsOptions = Array.from({ length: 8 }, (_, i) => ({ key: i + 1, text: `${i + 1}`, value: i + 1 }));

  const steps = [
    { key: 'Select', icon: 'file', title: 'Select', description: 'Select Videos' },
    { key: 'Inputs', icon: 'edit', title: 'Inputs', description: 'Enter project details' },
    { key: 'Configure', icon: 'cog', title: 'Configure', description: 'Configure information about the road' },
    { key: 'Download', icon: 'download', title: 'Download', description: 'Download your data' }
  ];

  const handleChange = (e, { name, value }) => {
    setFormValues({ ...formValues, [name]: value });
  };

  const validateFields = () => {
    const errors = {};
    if ((!formValues.projectId) || (formValues.projectId === "")) errors.projectId = 'Project Id required';
    if ((!formValues.projectName)|| (formValues.projectName === "")) errors.projectName = 'Project Name required';
    if ((!formValues.studyName) || (formValues.studyName === "")) errors.studyName = 'Study Name required';
    if ((!formValues.projectType) || (formValues.projectType === "")) errors.projectType = 'Project Type required';
    if ((!formValues.classifications) || (formValues.classifications === "")) errors.classifications = 'Classification settings required';
    setStepErrors({...stepErrors,[currentStep]:errors});
  };

  const handleDownload = (data) => {
    const blob = new Blob([JSON.stringify({ data }, null, 2)], { type: 'application/json' });
    saveAs(blob, `${formValues?.projectId}-${formValues?.projectName}_project-data.json`);
  };

  const resetStudyFields = ()=>{
    setCurrentStep(1);
    setFormValues({...formValues,"studyName":""});
    setLegData([{ direction: '', name: '', oneWay: false, uTurnAvailable:true,crosswalk:true }]);
  }

  const handleSaveStudyData = (downloadData=false)=>{
    if(!studyData){
      console.log("No study data available. Can't save");
      return;
    }
    const studies = availableStudies;
    const currentStudy = currentSelectedStudy;
    const currStudyIdx = studies.indexOf(currentStudy);
    const dataToSave = { ...formValues, studyData,legData };
    setSavedData({...savedData,[currentSelectedStudy]:dataToSave});
    if(currStudyIdx>=0 && (currStudyIdx +1 <= availableStudies.length-1)){
      setSelectedStudy(availableStudies[currStudyIdx+1]);
      resetStudyFields();
    }
    if(downloadData === true){
      handleDownload({...savedData,[currentSelectedStudy]:dataToSave});
    }
  };

  const handleStudyDataChange = (data)=>{
    setStudyData(data);
  };

  const handleSetLegData = (data)=>{
    setLegData(data);
  }

  const handleStudySelectionChange = (data) =>{
    setSelectedStudy(data);
  }

  const handleAvailableStudiesChange = (data) =>{
    setAvailableStudies(data);
  }

  const handleFileContentChange = (data) =>{
    setFileContent(data);
  }

  const handleStudyOptionsChange = (data) =>{
    setStudyOptions(data);
  }

  const handleTrimsChange = (data) =>{
    setTrims(data);
  }

  const trafficSettingsContext = {
    savedData,
    currentStep,
    fileContent,
    studyOptions,
    trims,
    currentSelectedStudy,
    formValues,
    studyData, 
    legData,
    availableStudies,
    setCurrentStep,
    onHandleSelectedStudy:handleStudySelectionChange,
    setFormValues,
    handleStudyDataChange,
    handleSetLegData,
    onHandleFormChange:handleChange,
    onHandleDownload:handleDownload,
    onAvailableStudiesChange:handleAvailableStudiesChange,
    onFileContentChange:handleFileContentChange,
    onStudyOptionsChange: handleStudyOptionsChange,
    onTrimsChange:handleTrimsChange
  };
  return (
    <div>
      <PreventClose/>
      <TrafficSettingsProvider value={trafficSettingsContext}>
      <Container>
        <Grid>
          <Grid.Row columns={2}>
            <Grid.Column width={2}>
            <h2>Available Studies</h2>
            {studyData && (
              <>
              <Menu vertical compact>
                {availableStudies.map((study,idx)=>{
                    return <MenuItem key={idx} name={study} active={study === currentSelectedStudy} icon={Object.keys(savedData).indexOf(study) >=0 ? "check":""}></MenuItem>    
                })}
              </Menu>
              </>
            )}
            </Grid.Column>
            <Grid.Column width={14}>
              <StepGroup fluid items={steps.map(step => ({...step, active: steps[currentStep-1].key === step.key}))} />
              {currentStep === 1 && (
                <StudySelector/>
              )}
              {currentStep === 2 && (
                  <Segment>
                    <Form>
                      <Form.Field 
                        required
                        error={stepErrors.projectId ? { content: stepErrors.projectId } : null}
                        >
                        <label>Project ID</label>
                        <Input placeholder='Project ID' name='projectId' value={formValues.projectId || ''} onChange={handleChange} />
                      </Form.Field>
                      <Form.Field required>
                        <label>Project Name</label>
                        <Input placeholder='Project Name' name='projectName' value={formValues.projectName || ''} onChange={handleChange} />
                      </Form.Field>
                      <Form.Field required>
                        <label>Study Name</label>
                        <Input placeholder='Study Name' name='studyName' value={formValues.studyName || ''} onChange={handleChange} />
                      </Form.Field>
                      <Form.Group widths='equal'>
                        <Form.Field required>
                          <label>Study GPS Coordinates (Latitude)</label>
                          <Input placeholder='Latitude' name='latitude' value={formValues.latitude || ''} onChange={handleChange} />
                        </Form.Field>
                        <Form.Field required>
                          <label>Study GPS Coordinates (Longitude)</label>
                        <Input placeholder='Longitude' name='longitude' value={formValues.longitude || ''} onChange={handleChange} />
                        </Form.Field>
                        
                      </Form.Group>
                      <Form.Field>
                          <label>Time Zone</label>
                          <Select search placeholder='Select Time Zone' options={timeZones} name='timeZone' value={formValues.timeZone || 'US/Eastern'} onChange={handleChange} />
                        </Form.Field>
                      <Form.Field required>
                        <label>Project Type</label>
                        <Select search placeholder='Select Project Type' options={projectTypes} name='projectType' value={formValues.projectType || ''} onChange={handleChange} />
                      </Form.Field>
                      <h3>Classification Settings</h3>
                      <Form.Field required>
                        <label>How do you want the data to be grouped?</label>
                        <Select search placeholder='Select Classifications' options={classificationsOptions} name='classifications' value={formValues.classifications || ''} onChange={handleChange} />
                      </Form.Field>
                      <Form.Field>
                        <Checkbox toggle label='Count Bycicles on Road' name='countBikes' checked ={formValues.countBikes || false} onChange={(e,{name,checked})=>handleChange(e,{name,value:checked})} />
                      </Form.Field>
                    </Form>
                  </Segment>
              )}


              {currentStep === 3 && (
                <AnnotateStudy/>
              )}

              {currentStep === 4 && (
                <Segment>
                  {
                    studyData && (availableStudies.indexOf(currentSelectedStudy) !== availableStudies.length - 1) ? 
                    (
                      <Button color="teal" onClick={handleSaveStudyData}>Configure Next study</Button>    
                    ):
                    (
                      <Button positive onClick={()=>handleSaveStudyData(true)}>Download JSON</Button>
                    )
                  }
                </Segment>
              )}

              <Button.Group>
                {currentStep > 1 && <Button color='red' onClick={() => setCurrentStep(currentStep - 1)}>Previous</Button>}
                {currentStep < steps.length && <Button disabled={!studyData || !isEmptyObject(stepErrors[currentStep]) } color='teal' onClick={() => setCurrentStep(currentStep + 1)}>Next</Button>}
              </Button.Group>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        </Container>
      </TrafficSettingsProvider>
    </div>
  );
};

export default TrafficCountsConfigScreen;