import React, { useState, useEffect, useRef } from 'react';
import { Dropdown, Icon, List, Button, Loader, Divider } from 'semantic-ui-react';
import { apiCall } from '../services/api';
import moment from 'moment';
import 'moment-timezone';
import _ from 'lodash';
import LoaderButton from './LoaderButton';

const BASE_URL = process.env.REACT_APP_PRODUCTION_SERVER_URL;

const ProjectFilters = ({ dataLabels, project }) => {
  const [foundProject,setFoundProject] = useState(null);
  const [filters, setFilters] = useState([]);
  const [labelsInFilters, setLabelsInFilters] = useState([]);
  const [localFilters, setLocalFilters] = useState([]);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [loading, setLoading] = useState(false);
  const [filtersSavingStatus, setFiltersSavingStatus] = useState('upToDate');
  const [filterAction,setFilterAction] = useState('exclude');

  // Timer interval ID for status updates
  const statusIntervalRef = useRef(null);

  useEffect(() => {
    if(project && !foundProject){
      setFoundProject(project);
    }
    if (
      foundProject &&
      'projectConfiguration' in foundProject &&
      foundProject.projectConfiguration &&
      'filters' in foundProject.projectConfiguration &&
      foundProject.projectConfiguration.filters
    ) {
      const labelList = foundProject.projectConfiguration.filters.map((filter) => {
        return filter.label;
      });
      setFilters(foundProject.projectConfiguration.filters);
      setLocalFilters([...foundProject.projectConfiguration.filters]);
      setLabelsInFilters(labelList);
      setFiltersSavingStatus(foundProject?.projectConfiguration?.filtersUpdateStatus || 'upToDate');
      if(foundProject?.projectConfiguration?.filtersAction){
        setFilterAction(foundProject?.projectConfiguration?.filtersAction);
      }
    }
  }, [foundProject]);

  const handleAddFilter = () => {
    const availableLabels = dataLabels.filter((label) => {
      return labelsInFilters.indexOf(label.name) < 0;
    });
    if(availableLabels.length <= 1){
      console.log("No labels available");
      return;
    }
    const newFilter = {
      type: 'exactlyOne',
      label: availableLabels[0].name,
    };
    setLocalFilters((prevFilters) => [...prevFilters, newFilter]);
    setLabelsInFilters((prevLabels) => [...prevLabels, dataLabels[0].name]);
    setHasUnsavedChanges(true); // Mark changes as unsaved when adding a new filter
  };

  const handleDropdownChange = (index, field, value) => {
  const updatedFilters = [...localFilters];
  const updatedFilter = { ...updatedFilters[index] }; // Create a copy of the filter object
  updatedFilter[field] = value;
  updatedFilters[index] = updatedFilter;

  if (field === 'label') {
    setLabelsInFilters((prevLabels) => {
      const updatedLabels = [...prevLabels];
      updatedLabels[index] = value;
      return updatedLabels;
    });
  }
  setLocalFilters(updatedFilters);
  setHasUnsavedChanges(true);
};

const handleFilterActionsDropdownChange = async (e, { value })=>{
  setFilterAction(value);
  await apiCall("PATCH", `${BASE_URL}/api/projects/${foundProject._id}`,{
    project:{"projectConfiguration.filtersAction":value}
  })
}

const emailMathingFilterImages = async (includeUnlabeled)=>{
  await apiCall("POST",`${process.env.REACT_APP_PRODUCTION_SERVER_URL}/api/jobs/projects/${foundProject?._id}/reports/`,{includeUnlabeled,format:"csv",dataType:"imageList",matchingFiltersOnly:true});
}


  const handleDeleteFilter = (index) => {
    setLocalFilters((prevFilters) => {
      const updatedFilters = [...prevFilters];
      updatedFilters.splice(index, 1);
      return updatedFilters;
    });
    setLabelsInFilters((prevLabels) => {
      const updatedLabels = [...prevLabels];
      updatedLabels.splice(index, 1);
      return updatedLabels;
    });
    setHasUnsavedChanges(true); // Mark changes as unsaved when deleting a filter
  };

  const handleSaveFilters = async () => {
    // Create a diff between the original filters and the local changes
    setLoading(true);
    const addedFilters = localFilters.filter(
      (filter) => !filters.some((originalFilter) => originalFilter._id === filter._id)
    );
    const updatedFilters = localFilters.filter((filter) =>
      filters.some((originalFilter) => originalFilter._id === filter._id && !isEqual(originalFilter, filter))
    );
    const deletedFilters = filters.filter(
      (originalFilter) => !localFilters.some((filter) => filter._id === originalFilter._id)
    );

    try {
      // Create the body for the bulk update API call
      const bulkUpdateBody = {
        add: addedFilters,
        update: updatedFilters,
        delete: deletedFilters.map((filter) => filter._id),
      };

      // Make API call for bulk update
      const updatedProject = await apiCall(
        'POST',
        `${BASE_URL}/api/projects/${foundProject._id}/filters/bulk`,
        bulkUpdateBody
      );

      setFilters(updatedProject.projectConfiguration.filters);
      setFiltersSavingStatus("importing");
      setLocalFilters(updatedProject.projectConfiguration.filters);
      setHasUnsavedChanges(false); // Reset the flag since changes are saved
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const fetchStatus = async () => {
    const updatedProject = await apiCall('GET', `${BASE_URL}/api/projects/${foundProject._id}`);
    setFiltersSavingStatus(updatedProject.projectConfiguration?.filtersUpdateStatus);
    setFoundProject(updatedProject);
  };

  // Start interval when filtersUpdateStatus is 'importing'
  useEffect(() => {
    if (filtersSavingStatus === 'importing') {
      statusIntervalRef.current = setInterval(fetchStatus, 4000);
    }else{
      clearInterval(statusIntervalRef.current);
    }

    return () => {
      clearInterval(statusIntervalRef.current); // Clear interval on unmount
    };
  }, [filtersSavingStatus]);

    // Get the user's timezone
  const userTimezone = moment.tz.guess();
  const filtersLastUpdated = foundProject?.projectConfiguration?.filtersLastUpdated || foundProject?.lastUpdated;
  const readableDate = moment.utc(filtersLastUpdated).tz(userTimezone).format('MMMM Do, YYYY h:mm:ss A');
  const actionWord = filterAction === 'exclude' ? "Exclude" : "Show";

  return (
    <>
    <h3>Actions</h3>
    <p>Select the filtering actions below. Selecting <strong><em>Exclude</em></strong> will ensure images that match the filters are excluded from the Editor. Selecting <strong><em>Show Only</em></strong> will ensure you can only see images that match the criteria.</p>
    <Dropdown
      selection
      value={filterAction}
      options={[
        { key: 'exclude', text: 'Exclude', value: 'exclude' },
        { key: 'showOnly', text: 'Show Only', value: 'showOnly' }
        ]}
      onChange={handleFilterActionsDropdownChange}
      ></Dropdown>
    <Divider/>
    <h3>Criteria</h3>
      <p>Select the filtering criteria below</p>
      <List>
        {localFilters.map((filter, index) => (
          <List.Item key={index} style={{ display: 'flex', alignItems: 'center', padding: '5px' }}>
            <span style={{ marginLeft: '5px', marginRight: '5px' }}>{actionWord} image if </span>
            <Dropdown
              search
              selection
              value={filter.type}
              options={[
                { key: 'exactlyOne', text: 'Only', value: 'exactlyOne' },
                { key: 'atLeastOne', text: 'At Least One Of', value: 'atLeastOne' },
              ]}
              style={{ marginLeft: '5px', marginRight: '5px' }}
              onChange={(e, { value }) => handleDropdownChange(index, 'type', value)}
            />
            <Dropdown
              search
              selection
              value={filter.label}
              options={dataLabels
                .map((label) => {
                  return { key: label.id, text: label.name, value: label.name };
                })
                .filter((label) => label.value === filter.label || labelsInFilters.indexOf(label.value) < 0)}
              onChange={(e, { value }) => handleDropdownChange(index, 'label', value)}
              style={{ marginLeft: '5px', marginRight: '5px' }}
            />

            <span style={{ marginLeft: '5px', marginRight: '5px' }}>Is found</span>
            <Button
              color="red"
              icon="trash"
              label="Delete"
              size="tiny"
              onClick={() => handleDeleteFilter(index)}
              style={{ marginLeft: '5px', marginRight: '5px' }}
            />
          </List.Item>
        ))}
        <List.Item style={{ display: 'flex', alignItems: 'center' }}>
          <Button
            icon
            color="teal"
            labelPosition="left"
            onClick={handleAddFilter}
            style={{ float: 'right', marginTop: '2em' }}
          >
            <Icon name="plus" />
            Add Filter
          </Button>
        </List.Item>
        <List.Item style={{ display: 'flex', alignItems: 'center' }}>
          <Button
            positive
            onClick={handleSaveFilters}
            disabled={!hasUnsavedChanges || filtersSavingStatus === 'importing'} // Disable the button if there are no changes
            style={{ float: 'right', marginTop: '2em' }}
            loading={loading || filtersSavingStatus === 'importing'}
          >
            Save Filters
          </Button>
        </List.Item>
      </List>
      {foundProject?.projectConfiguration?.filtersUpdateStatus && (
        <div>
          {foundProject?.projectConfiguration?.filtersUpdateStatus === 'importing' ? (
            <Loader content="Updating Filters"></Loader>
          ) : (
            <div>
              {foundProject?.projectConfiguration?.filtersLastUpdated && (
                <div>Last updated {readableDate}</div>
              )}
              {foundProject?.matchingFiltersImages?.length >=0 && (
                <span>{foundProject?.matchingFiltersImages?.length} images match the filter
                </span>)} 
            </div>
          )}
        </div>
      )}
      <Divider/>
      <LoaderButton hasPromise={true} color="blue" iconName="mail" text="Email List of Images Matching Filters (CSV)" onClick={()=>emailMathingFilterImages(false)} successMessage="Please check your email in a few minutes. If many images, this may take a few hours." disabledSeconds={20}/>
    </>
  );
};

export default ProjectFilters;

// Helper function to check if two objects are equal
function isEqual(obj1, obj2) {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
}