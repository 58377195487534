import React from 'react'
import { Header, Icon } from 'semantic-ui-react';

const MetadataHeader = () => {
  return (
    <>
      <Header as='h4'><Icon name='tag' />Metadata Items</Header>
      <p>Add, edit or delete metadata items that will be available for labels.</p>
    </>
  );
};

export default MetadataHeader;