import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import TwoDimensionalVideoContext from '../TwoDimensionalVideo/twoDimensionalVideoContext';
import VideoPlayerScreen from '../VideoPlayer/Screen/Screen';
import VideoPlayerControl from '../VideoPlayer/Control/Control';
import Canvas from '../Canvas/Canvas';
import './drawableVideoPlayer.css';
import { getInitTransform } from '../utils/canvas';

const DrawableVideoPlayer = ({
	className
}) => {
	const twoDimensionalVideoContext = useContext(TwoDimensionalVideoContext);
	const {
		url,
		played,
		isPlaying,
		playbackRate,
		isLoop,
		entities,
		annotations,
		focusing,
    videoWidth,
    videoHeight,
		width,
		height,
		duration,
		isAdding,
		isEmptyCheckEnable,
		playerRef,
		onVideoReady,
		onVideoProgress,
		onVideoDuration,
		onVideoEnded,
		onVideoSliderMouseUp,
		onVideoSliderMouseDown,
		onVideoSliderChange,
		onVideoRewind,
		onVideoPlayPause,
		onVideoSpeedChange,
		onCanvasStageMouseDown,
		onCanvasGroupMouseDown,
		onCanvasGroupDragEnd,
		onCanvasDotMouseDown,
		onCanvasDotDragEnd,
		onPrevFrame,
		onNextFrame,
    isPanning,
    togglePanMode
	} = twoDimensionalVideoContext;

	const [transform, setTransform] = useState({ x: 0, y: 0, scale: 1 });
  const [isDragging, setIsDragging] = useState(false);
  const [initialPosition, setInitialPosition] = useState({ x: 0, y: 0 });

  const handleZoomIn = () => {
    setTransform((prev) => ({ ...prev, scale: Math.min(prev.scale + 0.25,10) }));
  };

  const handleZoomOut = () => {
    setTransform((prev) => ({ ...prev, scale: Math.max(prev.scale - 0.25,0.2) }));
  };

  const handleScroll = (e) => {
  	e.stopPropagation();
    if (e.deltaY < 0) handleZoomIn();
    else handleZoomOut();
  };

  const togglePanning = () => {
    togglePanMode(!isPanning);
    setIsDragging(false);
  };

  const handleMouseDown = (e) => {
    if (isPanning) {
      setIsDragging(true);
      setInitialPosition({ x: e.clientX, y: e.clientY });
    }
  };

  const handleMouseUp = () => setIsDragging(false);

  const handleMouseMove = (e) => {
    if (!isPanning || !isDragging) return;

    const deltaX = e.clientX - initialPosition.x;
    const deltaY = e.clientY - initialPosition.y;
    setTransform((prev) => ({
      ...prev,
      x: prev.x + deltaX,
      y: prev.y + deltaY,
    }));
    setInitialPosition({ x: e.clientX, y: e.clientY });
  };

  const resetCanvas = () => {
    setTransform(getInitTransform(width, videoWidth, videoHeight));
  };

  useEffect(() => {
    if (width !== undefined) resetCanvas();
  }, [width, videoWidth])

  const rootClassName = `drawable-video-player${className ? ` ${className}` : ''}`;

  return (
    <div className={rootClassName} onWheel={handleScroll}>
      <div 
        className='drawable-video-player__player-canvas-wrapper' 
        onMouseDown={handleMouseDown} 
        onMouseUp={handleMouseUp} 
        onMouseMove={handleMouseMove} 
        style={{
          width: `${videoWidth}px`,
          height: `${videoHeight}px`,
          transform: `translate(${transform.x}px, ${transform.y}px) scale(${transform.scale})`,
          cursor:isPanning ? "pointer" : "inherit"
        }}
      >
        <VideoPlayerScreen
          playerRef={playerRef}
          onReady={onVideoReady}
          onProgress={onVideoProgress}
          onDuration={onVideoDuration}
          onEnded={onVideoEnded}
          url={url}
          width={width}
          height={height}
          isPlaying={isPlaying}
          isLoop={isLoop}
          playbackRate={playbackRate}
        />
        <Canvas
          width={ width }
          height={ height }
          played={ played }
          focusing={ focusing }
          isAdding={ isAdding }
          entities={ entities }
          annotations={ annotations }
          onStageMouseDown={ onCanvasStageMouseDown }
          onGroupMouseDown={ onCanvasGroupMouseDown }
          onGroupDragEnd={ onCanvasGroupDragEnd }
          onDotMouseDown={ onCanvasDotMouseDown }
          onDotDragEnd={ onCanvasDotDragEnd }
          isEmptyCheckEnable={ isEmptyCheckEnable }
        />
      </div>
      <VideoPlayerControl
        isPlaying={isPlaying}
        played={played}
        playbackRate={playbackRate}
        duration={duration}
        onSliderMouseUp={onVideoSliderMouseUp}
        onSliderMouseDown={onVideoSliderMouseDown}
        onSliderChange={onVideoSliderChange}
        onRewind={onVideoRewind}
        onPlayPause={onVideoPlayPause}
        onSpeedChange={onVideoSpeedChange}
        onPrevFrame={onPrevFrame}
        onNextFrame={onNextFrame}
        handleZoomIn={handleZoomIn}
        handleZoomOut={handleZoomOut}
        togglePanMode={togglePanning}
        resetCanvas={resetCanvas}
        isPanning={isPanning}
      />
    </div>
  );
};

DrawableVideoPlayer.propTypes = {
	className: PropTypes.string,
};
DrawableVideoPlayer.defaultProps = {
	className: '',
};
export default DrawableVideoPlayer;
