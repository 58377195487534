import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { Container, Menu, Icon } from 'semantic-ui-react';


export default class Menubar extends Component {
  render() {
    const { active,language,userRole,fluidContent=false} = this.props;
    return (
      <>      
      <div style={{ background: '#f7f7f7', minHeight: '100vh'}}>
        <Menu inverted style={{marginBottom:0}}>
          <Container>
            <Menu.Item header>{language ==="es" ? "RoadEngin": "RoadEngin"}</Menu.Item>
            {
              (userRole === "admin" || userRole === "labeler_lead") ? 
                (
                  <Link to="/label/">
                  <Menu.Item active={active === 'label'}>
                    <Icon name="tag" style={{ marginRight: '5px' }} />
                    {language === "es" ? "Etiquetar" : "Label"}
                  </Menu.Item>
                </Link>
                  )
              :
              (
                  <Link to="/label/">
                  <Menu.Item active={active === 'label'}>
                    <Icon name="tag" style={{ marginRight: '5px' }} />
                    {language === "es" ? "Diagnosticar" : "Rate"}
                  </Menu.Item>
                </Link>
                  )
            }

            {
              (userRole === "admin" || userRole === "labeler_lead" || userRole === "client") && (
                  <Link to="/admin/">
                  <Menu.Item active={active === 'admin'}>
                    <Icon name="pencil" style={{ marginRight: '5px' }} />
                    {language === "es" ? "Administrador" : "Admin"}
                  </Menu.Item>
                </Link>
                )
            }
            {/*<Link to="/help/">
                          <Menu.Item active={active === 'help'}>
                            <Icon name="help circle" style={{ marginRight: '5px' }} />
                            Help
                          </Menu.Item>
                        </Link>*/}
          </Container>
        </Menu>
        {fluidContent ? 
          <div>{this.props.children}</div>
            :
          <Container>{this.props.children}</Container>
        }
      </div>
      </>
    );
  }
}
