import L from 'leaflet';
import React from 'react';
import ReactDOMServer from 'react-dom/server';

const SvgIcon = ()=>{
    const perc = this.props.perc || 0;
    return (
      <svg width="50px" height="50px" viewBox="0 0 42 42" className="donut" aria-labelledby="beers-title beers-desc" role="img">
        <circle className="donut-hole" cx="21" cy="21" r="15.91549430918954" fill="white" role="presentation"></circle>
        <circle className="donut-ring" cx="21" cy="21" r="15.91549430918954" fill="transparent" stroke="#d2d3d4" strokeWidth="3" role="presentation"></circle>
        <circle className="donut-segment" cx="21" cy="21" r="15.91549430918954" fill="transparent" stroke="#ce4b99" strokeWidth="3" strokeDasharray={`${perc} ${100 - perc}`} strokeDashoffset="25" aria-labelledby="donut-segment-1-title donut-segment-1-desc">
        </circle>
        <g className="chart-text">
          <text className="chart-number" x="35%" y="60%">
            {perc}
          </text>
        </g>
      </svg>
    );
  };

const CommentPinIcon = (isResolved) =>{
 const pinIcon = new L.Icon({
        iconUrl: isResolved? "https://res.cloudinary.com/dqwgbheqj/image/upload/v1638592027/Custom%20Icons/comment-pin-green-01_jiftzj.png" : "https://res.cloudinary.com/dqwgbheqj/image/upload/v1637211353/Custom%20Icons/comment-pin-01_pecabf.png",
        iconRetinaUrl: isResolved? "https://res.cloudinary.com/dqwgbheqj/image/upload/v1638592027/Custom%20Icons/comment-pin-green-01_jiftzj.png" : "https://res.cloudinary.com/dqwgbheqj/image/upload/v1637211353/Custom%20Icons/comment-pin-01_pecabf.png",
        iconAnchor: [22,50],
        popupAnchor: [0,0],
        shadowUrl: null,
        shadowSize: null,
        shadowAnchor: null,
        iconSize: [50,50],
        className: 'leaflet-div-icon'
      });
 return pinIcon;
}


// const CommentPinIcon = new L.divIcon({
// 	className:'leaflet-div-field',
// 	iconSize: [50,50]
// });

export default CommentPinIcon;