import React from 'react';

import { 
        Button, 
        Icon } from 'semantic-ui-react';

const UploadFolders = ({ onFoldersSelect }) => {
    const handleFoldersSelect = async (e) => {
        const folders = e.target.files;
        const folderStructure = processFolders(folders);
        onFoldersSelect(folderStructure);

        e.target.value = null;
    };

    const processFolders = (folders) => {
        let folderStructure = {};
        Array.from(folders).forEach(file => {
            const pathParts = file.webkitRelativePath.split('/');
            let currentLevel = folderStructure;
            pathParts.forEach((part, index) => {
                if (index === pathParts.length - 1) {
                    if (!currentLevel.files) {
                        currentLevel.files = [];
                    }
                    currentLevel.files.push(file);
                } else {
                    if (!currentLevel[part]) {
                        currentLevel[part] = { files: [] };
                    }
                    currentLevel = currentLevel[part];
                }
            });
        });
        return folderStructure;
    };

    return (
        <>
            <div style={{ display: 'inline-block' }}>
                <Button icon labelPosition='left' onClick={() => document.getElementById('upload-folders').click()} color='teal'>
                    <Icon name='folder' />
                    Select Folders
                </Button>
                <input
                    type="file"
                    id="upload-folders"
                    style={{ display: 'none' }}
                    webkitdirectory="true"
                    directory="true"
                    multiple
                    onChange={handleFoldersSelect}
                />
            </div>
        </>
    );
};

export default UploadFolders;
