import { apiCall, setTokenHeader } from "../../services/api";
import { SET_CURRENT_USER } from "../actionTypes";
import { addError, removeError } from "./errors";

export function setCurrentUser(user) {
  return {
    type: SET_CURRENT_USER,
    user
  };
}

export function setAuthorizationToken(token) {
  setTokenHeader(token);
}

export function logout() {
  console.log("called action - logout");
  return dispatch => {
    console.log("Logged user out");
    localStorage.removeItem("enginJwtToken");
    setAuthorizationToken(false);
    dispatch(setCurrentUser({}));
    //redirect to main page

  };
}

export function authUser(type, userData) {
  return dispatch => {
    if (type === "refreshToken") {
      return new Promise((resolve, reject) => {
        apiCall("post",`${process.env.REACT_APP_PRODUCTION_SERVER_URL}/api/auth/refresh-token`)
        .then(({ refreshToken, ...user }) => {
          localStorage.setItem("enginJwtToken", refreshToken);
          dispatch(setCurrentUser(user))
          dispatch(removeError());
          resolve();
        })
        .catch(err => {
          let error = "Something Went Wrong, Please refresh the page and try again";
          if(err && err.hasOwnProperty("message")){
            error= err.message;
          }
          dispatch(addError(error));
          reject();
        });
      })
    } else{
      // wrap our thunk in a promise so we can wait for the API call
      return new Promise((resolve, reject) => {
  
        return apiCall("post", `${process.env.REACT_APP_PRODUCTION_SERVER_URL}/api/auth/${type}`, userData)
          .then(({ token, ...user }) => {
            localStorage.setItem("enginJwtToken", token);
            setAuthorizationToken(token);
            dispatch(setCurrentUser(user));
            dispatch(removeError());
            resolve(); // indicate that the API call succeeded
          })
          .catch(err => {
            let error = "Something Went Wrong, Please refresh the page and try again";
            if(err && err.hasOwnProperty("message")){
              error= err.message;
            }
            dispatch(addError(error));
            reject(); // indicate the API call failed
          });
      });
    }
  };
}
