import React, { Component } from "react";
import PropTypes from "prop-types";
import { Form, Message } from 'semantic-ui-react';
import ReactGA from 'react-ga';

class AuthForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      profileImageUrl: "",
      f_name:"",
      l_name:"",
      password_confirm:"",
      error:false,
      errorMsg:"",
      agreedToTerms:false,
      a_password:"",
      company:""
    };
    this.onCheckboxSelect = this.onCheckboxSelect.bind(this);
  }


  handleSubmit = e => {
    e.preventDefault();
    const authType = this.props.signUp ? "signup" : "signin";
    if(this.props.signUp){
      if(this.state.password !== this.state.password_confirm){
        this.setState({error: true, errorMsg:"Your passwords don't match. Try again"});
        return;
      }
      e.target.reset();
    }
    if(this.state.a_password !== ""){
      ReactGA.event({
                 category: 'Fake Sign Up',
                 action: 'Captcha triggered'
                }); 
      this.setState({error: true, errorMsg:"Your sign up is suspicious. Please try again later"});
      return;
    }
    if(authType === "signup"){
    this.props
      .onAuth(authType, {
        email:this.state.email.toLowerCase(),
        password:this.state.password,
        f_name:this.state.f_name,
        l_name: this.state.l_name,
        profileImageUrl: this.state.profileImageUrl
        })
      .then(() => {
        ReactGA.event({
                 category: 'Sign Up',
                 action: 'User Signed Up'
                }); 
        this.props.history.push("/");
      })
      .catch(() => {
        return;
      });
    }else if(authType === "signin"){
     this.props
      .onAuth(authType, {
        email:this.state.email.toLowerCase(),
        password:this.state.password
        })
      .then(() => {
        ReactGA.event({
                 category: 'Sign In',
                 action: 'User Signed In'
                }); 
        this.props.history.push("/label");
        
      })
      .catch(() => {
        return;
      });
    }


  };

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value.trim() });
  };
   onCheckboxSelect(){
    this.setState({
      agreedToTerms: !this.state.agreedToTerms
    });
  }

  

  render() {
    const { email, password, f_name, l_name , password_confirm, a_password} = this.state;
    const {
      signUp,
      heading,
      buttonText,
      errors,
      history,
      removeError
    } = this.props;
    let pageLang = this.props.language;
    history.listen(() => {
      removeError();
    });
    const removeAdditionalField = this.props.removeAdditionalField ? this.props.removeAdditionalField  : false;
    const customWidth = this.props.customWidth ? this.props.customWidth : "50%";
    return (
      <div>
        <div className="section">
    <div className="ui container">
        
        <div className="ui one column centered grid">
        <div className="row">
            <div className="ui segments" id="authform" style={{"width": customWidth}}>
             <div className="ui segment">
                 <h1>{heading}</h1> 
                  {errors.message && (
                  <Message 
                  error
                  content={errors.message}/>) }
                  {this.state.error && this.state.errorMsg !== "" && (
                  <Message 
                  error
                  content={this.state.errorMsg}/>) }
             </div>
               <div className="ui left aligned segment">
                    <div className="ui form registerForm">
          <Form error={this.state.error} onSubmit={this.handleSubmit}>
          <div style={{"display":"none"}}>
            <Form.Input
                      style={{"display":"none"}}
                      required={false}
                      alt = "Please leave this field empty"
                      tabIndex="-1"
                      autoComplete="somepassword"
                      id="a_password"
                      name="a_password"
                      onChange={this.handleChange}
                      value={a_password}
                      placeholder="**********"
                   />
          </div>
          {signUp && (
               <div className="required field">
                   <Form.Input
                      required={true}
                      label= {pageLang === "en" ? "First Name" : "Nombre"} 
                      autoComplete="off"
                      id="f_name"
                      name="f_name"
                      onChange={this.handleChange}
                      type="text"
                      value={f_name}
                      placeholder="John"
                   />
                    <div className="required field">
                  <Form.Input
                      required={true}
                      label= {pageLang === "en" ?  "Last Name": "Apellido"} 
                      autoComplete="off"
                      id="l_name"
                      name="l_name"
                      onChange={this.handleChange}
                      type="text"
                      value={l_name}
                      placeholder="Doe"
                   />
          </div>
          </div>
            ) }
         
         
          <div className="required field">
              <Form.Input
                      required={true}
                      label= "Email" 
                      autoComplete="off"
                      id="email"
                      name="email"
                      onChange={this.handleChange}
                      type="email"
                      value={email}
                      placeholder="johndoe@engin-ai.com"
                   />
          </div>
          <div className="required field">  
            <Form.Input
                      required={true}
                      label= {pageLang === "en" ? "Password" : "Contraseña"} 
                      autoComplete="off"
                      id="password"
                      name="password"
                      onChange={this.handleChange}
                      type="password"
                      value={password}
                      placeholder="**********"
                   />
          </div>
          {!signUp && (
            <p><a href="/#/recover">{pageLang === "en" ? "Forgot your password?" : "¿Olvidaste tu contraseña?"}</a></p>
            )}
          {signUp && (
            <div className="required field">  
            <Form.Input
                      required={true}
                      label= {pageLang === "en"? "Confirm password" : "Confirma la Contraseña"} 
                      autoComplete="off"
                      id="password_confirm"
                      name="password_confirm"
                      onChange={this.handleChange}
                      type="password"
                      value={password_confirm}
                      placeholder="**********"
                   />
          </div>
            )}
          {signUp && (
            <Form.Field required>
              <div className= "ui checkbox">
                  <input checked={this.state.agreedToTerms} onChange={this.onCheckboxSelect} required type="checkbox" name="agree"/> 
                <label>
                  <span>
                    {pageLang === "en" ? "I accept the": "Acepto los"} <a href="/terms-of-use">{pageLang === "en"? "Terms and Conditions" : "Términos y Condiciones"}</a> {pageLang=== "en" ? "and the":"y la"} <a href="/privacy-policy">{pageLang === "en"? "Privacy Policy" : "Política de Privacidad"}</a>  
                  </span>
                </label> 
            </div>
            </Form.Field>
            )}

          <button disabled={signUp ? (!this.state.agreedToTerms || this.state.email === "" || this.state.password === "" || this.state.f_name === "" || this.state.l_name === "") : (this.state.email === "" || this.state.password === "")} className="ui red large fluid button" type="submit">{buttonText}</button>
         {signUp && (
                <Message 
                  error
                  content={pageLang === "en"? "Passwords do not match" : "Las contraseñas no son iguales"}
                  />)}
          </Form>
                </div>
                </div> 
                 </div>
        </div>
        {!removeAdditionalField && (
          <div className="row">
                   <div className="ui segment">
                    {signUp ? (pageLang === "en" ? "Already have an account?" : "¿Ya tienes una cuenta?") : (pageLang === "en"? "Not a member yet? Create a free account" : "Crea una cuenta gratuita")}  <a href={signUp? "/signin" : "/signup" }>{signUp? (pageLang === "en" ? "Sign In" : "Ingresa") : (pageLang === "en" ? "here" : "aquí")}</a>.
                 </div>    
            </div>
          )
                           
        }

      
        </div>
        
        
    </div>
   
</div>
      </div>
    );
  }
}
AuthForm.propTypes = {
  buttonText: PropTypes.string,
  errors: PropTypes.object,
  heading: PropTypes.string,
  history: PropTypes.object,
  onAuth: PropTypes.func,
  signIn: PropTypes.bool,
  removeError: PropTypes.func
};

export default AuthForm;
