import React, { useState, useEffect } from 'react';
import axios from "axios";

import {
    Button,
    Dropdown,
    Header,
    Image,
    Message,
    Modal,
    Segment,
    Grid } from 'semantic-ui-react';

import Upload from '../../images/upload-files.svg';
import FileIcon from '../../images/file-kml.svg';
import { useCreateProjectContext } from '../../context/CreateProjectContext';

const UploaderFilesProccesor = ({ setFilesUploaded, bucketName }) => {
    const {
        uploadedFiles,
        updateUploadedFiles,
        baseUrlFile, 
        SetBaseUrlFile,
        errorMessages,
        setErrorMessages,
        selectedKeys,
        setSelectedKeys,
        confirmed,
        setConfirmed,
        shpFieldFor,
        setShpFieldFor,
        aboutBucketName
    } = useCreateProjectContext();

    const [fileNames, setFileNames] = useState(new Set());
    const [uploading, setUploading] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [featureKeys, setFeatureKeys] = useState([]);
    const [fileHeaders, setFileHeaders] = useState([]);
    const [selectedFeatureKeys, setSelectedFeatureKeys] = useState(new Set());
    const [selectedFileHeaders, setSelectedFileHeaders] = useState(new Set());
    const [dropdownOptions, setDropdownOptions] = useState([]);

    let compGcsBucketName = bucketName;

    if (compGcsBucketName === '') {
        compGcsBucketName = aboutBucketName
    }

    useEffect(() => {
        updateUploadedFiles(uploadedFiles);
        setFileNames(new Set(fileNames));
        setSelectedKeys(selectedKeys);
        setConfirmed(confirmed);
        setFilesUploaded(confirmed);

        if (confirmed) {
            setUploading(confirmed);
        }

    }, [confirmed]);

    const validateFiles = (files) => {
        const allowedExtensions = ['.shp', '.shx', '.kml', '.csv'];
        const fileExtensions = Array.from(files, file => file.name.toLowerCase().slice(file.name.lastIndexOf(".")));

        const countByExtension = fileExtensions.reduce((acc, extension) => {
            acc[extension] = (acc[extension] || 0) + 1;
            return acc;
        }, {});

        const hasShp = countByExtension['.shp'] > 0;
        const hasShx = countByExtension['.shx'] > 0;
        const hasKml = countByExtension['.kml'] > 0;
        const hasCsv = countByExtension['.csv'] > 0;

        if (hasShp && !hasShx) {
            setErrorMessages(["Please upload the .shx file corresponding to the .shp file."]);
            return false;
        }

        if (!hasShp && hasShx) {
            setErrorMessages(["Please upload the .shp file corresponding to the .shx file."]);
            return false;
        }

        if (hasKml > 1) {
            setErrorMessages(["Only one KML file at a time is allowed."]);
            return false;
        }

        if (hasCsv > 1) {
            setErrorMessages(["Only one CSV file at a time is allowed."]);
            return false;
        }

        const invalidExtensions = fileExtensions.filter(ext => !allowedExtensions.includes(ext));
        if (invalidExtensions.length > 0) {
            setErrorMessages([`Invalid file types: ${invalidExtensions.join(', ')}. Please upload valid files (.shp, .shx, .kml, .csv).`]);
            return false;
        }

        setErrorMessages([]);
        return true;
    };

    const handleDrop = (event) => {
        event.preventDefault();
        const files = Array.from(event.dataTransfer.files);

        if (validateFiles(files)) {
            const newUploadedFiles = { ...uploadedFiles };
            const newFileNamesSet = new Set(fileNames);

            files.forEach(file => {
                const extension = file.name.toLowerCase().slice(file.name.lastIndexOf("."));
                if ([".shp", ".shx", ".kml", ".csv"].includes(extension)) {
                    let fileType;
                    if (extension === '.shp') {
                        fileType = 'shp';
                    } else if (extension === '.shx') {
                        fileType = 'shx';
                    } else if (extension === '.kml') {
                        fileType = 'kml';
                    } else if (extension === '.csv') {
                        fileType = 'csv';
                    }
                    newUploadedFiles[fileType] = file;
                    newFileNamesSet.add(file.name);
                }
            });

            const hasShp = newUploadedFiles.shp;
            const hasShx = newUploadedFiles.shx;
            const hasKml = newUploadedFiles.kml;
            const hasCsv = newUploadedFiles.csv;

            if ((hasShp && !hasShx) || (!hasShp && hasShx)) {
                setErrorMessages(["Please upload both .shp and .shx files."]);
                return;
            }

            if (Object.values(newUploadedFiles).filter(file => file !== null).length > 1) {
                if (hasCsv && (hasKml || hasShp || hasShx)) {
                    setErrorMessages(["Only one CSV file at a time is allowed."]);
                    return;
                }
                if (hasKml && (hasCsv || hasShp || hasShx)) {
                    setErrorMessages(["Only one KML file at a time is allowed."]);
                    return;
                }
            }

            updateUploadedFiles(newUploadedFiles);
            setFileNames(newFileNamesSet);
            setErrorMessages([]);
        }
    };

    
    const handleUpload = async () => {
        try {
            setUploading(true);

            const token = localStorage.getItem("enginJwtToken");
            let bucketName = compGcsBucketName || process.env.REACT_APP_APLICATION_BUCKET_DEFAULT;
        
            const folderPath = "projectData/";
            const kmlEndpoint = `${process.env.REACT_APP_PRODUCTION_SERVER_URL}/api/uploads/kml-headers`; // Endpoint específico para KML
            const csvShpShxEndpoint = 'https://us-central1-dataset-storage-367616.cloudfunctions.net/files-processor'; // Endpoint específico para CSV, SHP, y SHX

            const hasShp = uploadedFiles.shp;
            const hasShx = uploadedFiles.shx;
            const hasKml = uploadedFiles.kml;
            const hasCsv = uploadedFiles.csv;

            if ((hasShp && !hasShx) || (!hasShp && hasShx)) {
                setErrorMessages(["Please upload both .shp and .shx files."]);
                return;
            }

            if ((hasCsv && (hasKml || hasShp || hasShx)) || (hasKml && (hasCsv || hasShp || hasShx))) {
                setErrorMessages(["Please upload either CSV, KML, or SHP/SHX files, not multiple types at once."]);
                return;
            }

            const uploadFile = async (file) => {
                const response = await axios.get(`${process.env.REACT_APP_PRODUCTION_SERVER_URL}/api/gcloud/signUrl?file-name=${folderPath}${file.name}&file-type=${file.type}&bucketName=${bucketName}`, {
                    headers: {
                        "Authorization": `Bearer ${token}`,
                    }
                });

                const { signedUrl } = response.data;

                await fetch(signedUrl, {
                    method: 'PUT',
                    body: file,
                    headers: {
                        "Content-Type": "application/octet-stream",
                    },
                }).then(response => {
                    if (response.ok) {
                        const fileUrl = new URL(signedUrl);
                        fileUrl.search = ''; 
                        const finalUrl = fileUrl.toString();
 
                        SetBaseUrlFile(finalUrl)
                    
                    } else {
                        console.error("File upload failed", response.statusText);
                    }
                });
            };

            // Subir todos los archivos (SHP, SHX, KML, CSV)
            for (const [key, file] of Object.entries(uploadedFiles)) {
                if (file && typeof file === 'object') {
                    await uploadFile(file);
                }
            }

            const fileNamesString = Object.values(uploadedFiles)
                .filter(file => file && file.name)
                .map(file => file.name)
                .join(', ');

            const endpoint = hasKml ? kmlEndpoint : csvShpShxEndpoint;
            const method = hasKml ? 'PUT' : 'POST';
            const payload = {
                bucket_name: bucketName,
                file_name: folderPath + (hasKml ? uploadedFiles.kml.name : uploadedFiles.csv ? uploadedFiles.csv.name : uploadedFiles.shp.name)
            };

            const response = await axios({
                method: method,
                url: endpoint,
                data: payload,
                headers: {
                    "Authorization": `Bearer ${token}`,
                }
            });

            const data = response.data;

            if (hasKml) {
                setFeatureKeys(data.featureKeys);

                const options = data.featureKeys.map((key, index) => ({
                    key: index,
                    text: key,
                    value: key,
                }));
                setDropdownOptions(options);
            } else {
                if (Array.isArray(data.file_headers) && data.file_headers.length > 0) {
                    const headers = data.file_headers[0];
                    setFileHeaders(headers);

                    const options = headers.map((header, index) => ({
                        key: index,
                        text: header,
                        value: header,
                    }));
                    setDropdownOptions(options);
                }
            }
            setOpenModal(true);

        } catch (error) {
            console.error('Error uploading and signing file:', error);
        } finally {
            setUploading(false);
        }
    };

    const handleCancelUpload = () => {
        updateUploadedFiles({
            shp: null,
            shx: null,
            kml: null,
            csv: null
        });
        setFileNames(new Set());
        setErrorMessages([]);
        setSelectedKeys([]);
        setConfirmed(false);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        setConfirmed(true);
        setUploading(true);
    };

    useEffect(() => {

    }, [confirmed]);

    const options = fileHeaders.map((header, index) => ({
        key: index,
        text: header,
        value: header,
    }));

    const getFileType = (fileType) => {
        switch (fileType) {
            case 'shp':
                return 'Shapefile';
            case 'shx':
                return 'Shape Index';
            case 'kml':
                return 'KML File';
            case 'csv':
                return 'CSV File';
            default:
                return 'Unknown File Type';
        }
    };

    return (
        <>
            <div>
                {confirmed ? (
                    <Segment
                        textAlign='center'
                        style={{ height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgb(248, 248, 249)' }}
                    >
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <Header as='h3'>Files uploaded successfully</Header>
                            <Button
                                onClick={handleCancelUpload}
                                style={{ marginTop: '20px' }}
                            >
                                Cancel
                            </Button>
                        </div>
                    </Segment>
                ) : (
                    <Segment
                        onDragOver={(event) => event.preventDefault()}
                        onDrop={handleDrop}
                        textAlign='center'
                        style={{ height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgb(248, 248, 249)' }}
                    >
                        <div>
                            <Image src={Upload} centered />
                            <Header as='h3'>Drag and Drop a KML, SHP or CSV file</Header>
                            <p className='subtitle'>Supported formats: KML, SHP or CSV<br />(KML or SHP are recommended to speed up PCI sectioning)</p>
                            {errorMessages.length > 0 && errorMessages.map((msg, index) => (
                                <Message key={index} negative>{msg}</Message>
                            ))}
                            {[...fileNames].map((fileName, index) => (
                                <Message key={index}>
                                    <Image src={FileIcon} inline />
                                    {fileName}
                                </Message>
                            ))}
                            {fileNames.size > 0 && (
                                <>
                                    <Button
                                        onClick={handleCancelUpload}
                                        disabled={uploading}
                                        style={{ marginTop: '20px', marginLeft: '10px' }}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        color='teal'
                                        onClick={handleUpload}
                                        loading={uploading}
                                        disabled={uploading}
                                        style={{ marginTop: '20px' }}
                                    >
                                        Upload
                                    </Button>
                                </>
                            )}
                        </div>
                    </Segment>
                )}
            </div>
            <Modal open={openModal} onClose={() => setOpenModal(false)}>
                <Modal.Header>Metadata settings</Modal.Header>
   
                <Modal.Content>
                    <div>
                        <Header as='h4'>SHP Field for:</Header>
                        {Object.keys(uploadedFiles).map((fileType, index) => (
                            uploadedFiles[fileType] && (
                                <Segment key={index}>
                                    <span className='label-grey'>
                                        {getFileType(fileType)}
                                    </span>
                                    <span className='label-grey' style={{ display: 'flex', alignItems: 'center' }}>
                                        <Image src={FileIcon} />
                                        <span style={{ marginLeft: '0.5em' }}>{uploadedFiles[fileType]?.name}</span>
                                    </span>
                                </Segment>
                            )
                        ))}
                        <Grid>
                            {Object.keys(shpFieldFor).map((field, index) => (
                                <Grid.Column width={8} key={index}>
                                    <p>{shpFieldFor[field].text}</p>
                                    <Dropdown
                                        placeholder={`Select ${shpFieldFor[field].text}`}
                                        fluid
                                        selection
                                        options={dropdownOptions}
                                        value={shpFieldFor[field].value}
                                        onChange={(e, { value }) => setShpFieldFor({ ...shpFieldFor, [field]: { ...shpFieldFor[field], value } })}
                                    />
                                </Grid.Column>
                            ))}
                        </Grid>
                    </div>
                </Modal.Content>
                <Modal.Actions>
                    <Button className="ui teal button" onClick={handleCloseModal} disabled={uploading}>Confirm</Button>
                </Modal.Actions>
            </Modal>
        </>
    );
};

export default UploaderFilesProccesor;
