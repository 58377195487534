import React, {useState,useEffect, useRef} from 'react';
import { Stage, Layer, Rect, Text, Circle, Line, Image } from 'react-konva';

//canvas width - window width
// canvas height - 500

const CanvasEditor = ({imageUrl, imgWidth, imgHeight, metricSystem, onImageLoad}) => {
  const [canvasWidth,setCanvasWidth] = useState(window.innerWidth - 300);
  const [canvasHeight, setCanvasHeight] = useState(window.innerHeight - 200);
  const [backgroundImage, setBackgroundImage] = useState(null);
  const [backgroundImageWidth, setBackgroundImageWidth] = useState(200);
  const [backgroundImageHeight, setBackgroundImageHeight] = useState(200);
  const [backgroundImageX, setBackgroundImageX] = useState(0);
  const [backgroundImageY, setBackgroundImageY] = useState(0);
  const [measurementPoints,setMeasurementPoints] = useState([]);
  const [currentPos,setCurrentPos] = useState({x:0,y:0});
  const [isMeasuring,setIsMeasuring] = useState(false);
  const [widthRatio, setWidthRatio] = useState(0);
  const [heightRatio, setHeightRatio] = useState(0);
  const [pointsDistance, setPointsDistance] = useState(0);
  const [distanceTextPos,setDistanceTextPos] = useState({x:0,y:0});


  const handleMouseDown =(e) =>{
    let w = canvasWidth;
    let h = canvasHeight;
    // console.log("currentPos");
    // console.log(e.target.getStage().getPointerPosition());
    let pencilPoints = measurementPoints.slice();
    pencilPoints.push(e.target.getStage().getPointerPosition());
    setCurrentPos(e.target.getStage().getPointerPosition());
    setMeasurementPoints(pencilPoints);
    setIsMeasuring(true);
  };
  const onMouseMove = (e)=>{
    var w = canvasWidth;
    var h = canvasHeight;
    if(isMeasuring){
      let pencilPoints = [];
      pencilPoints[0] = measurementPoints[0];
      pencilPoints[1]= e.target.getStage().getPointerPosition();
      setMeasurementPoints([...pencilPoints]);
      setCurrentPos(e.target.getStage().getPointerPosition());
      let distance = 0;
      if(measurementPoints.length > 1){
        console.log("measurementPoints",measurementPoints);
        distance = Math.floor(lineDistance(measurementPoints[1],measurementPoints[0]));
      }
      setPointsDistance(distance);
      const newTextPosition = calculateTextPosition(currentPos,100, 30,canvasWidth,canvasHeight);
      setDistanceTextPos(newTextPosition);
    }
  };
  const handleMouseUp = ()=>{
    setIsMeasuring(false);
    setMeasurementPoints([]);
    setPointsDistance(0);
  };

  const stageRef = useRef(null);

  useEffect(()=>{
    const image = new window.Image();
          image.src = imageUrl;
          image.onload = () => {
            const imgWidth = image.naturalWidth;
            const imgHeight = image.naturalHeight;
            console.log("imgWidth",imgWidth);
            console.log("imgHeight",imgHeight);
            if(onImageLoad){
                onImageLoad(imgWidth,imgHeight);
            }
            const aspectRatio = imgWidth/imgHeight;

            setBackgroundImage(image);
            //Fit in 1000 x 500

            //1900 x 800

            if(imgWidth > canvasWidth){
              //max width == canvasWidth
              setBackgroundImageWidth(canvasWidth);
              setWidthRatio(imgWidth/canvasWidth);
              //resize height
              const newHeight = canvasWidth / aspectRatio;
              setBackgroundImageHeight(newHeight);
              setHeightRatio(imgHeight/newHeight);
              const yDistance = canvasHeight - newHeight;
              setBackgroundImageY(yDistance/2);
              setBackgroundImageX(0);

            }else if(imgHeight > canvasHeight){
              //max height == canvasHeight
              setBackgroundImageHeight(canvasHeight);
              //resize width
              const newWidth = canvasHeight * aspectRatio;
              setBackgroundImageWidth(newWidth);
              setWidthRatio(imgWidth/newWidth);
              setHeightRatio(imgHeight/canvasHeight);

              const xDistance = canvasWidth - newWidth;
              setBackgroundImageX(xDistance/2);
              setBackgroundImageY(0);
            }

          }
  },[imageUrl]);

 const lineDistance = ( point1, point2 ) =>{
  console.log("point1",point1);
  console.log("point2",point2);
    var xs = 0;
    var ys = 0;

    xs = (point2.x*widthRatio) - (point1.x*widthRatio);
    xs = xs * xs;

    ys = (point2.y*heightRatio) - (point1.y*heightRatio);
    ys = ys * ys;

    return Math.sqrt( xs + ys );
} 
  const calculateTextPosition = (currentPos,textboxWidth, textboxHeight,boundaryWidth,boundaryHeight)=>{
    if(!currentPos || !("x" in currentPos) || !("y" in currentPos) ){
      return {x:0,y:0};
    }
    console.log("calculateTextPosition");
    console.log("currentPos",currentPos);
    let newPos = JSON.parse(JSON.stringify(currentPos));;
    if(currentPos.x + textboxWidth > boundaryWidth){
      newPos.x = boundaryWidth - textboxWidth;
    }
    if(currentPos.y + textboxHeight > boundaryHeight){
      newPos.y = boundaryHeight - textboxHeight;
    }
    console.log("newPos",newPos);
    return newPos;
  };

  const convertToMetric = (distancePxl, originalWidth, originalHeight, system)=>{

  };

     const fitStageIntoParentContainer = ()=> {
        var container = document.querySelector('#stage-parent');

        // now we need to fit stage into parent container
        var containerWidth = container.offsetWidth;

        // but we also make the full scene visible
        // so we need to scale all objects on canvas
        var scale = containerWidth / canvasWidth;
        if("width" in stageRef){
          stageRef.width(canvasWidth * scale);
          stageRef.height(canvasHeight * scale);
          stageRef.scale({ x: scale, y: scale });
        }
      };

  return (
    <div id="stage-parent" style={{zIndex:"2"}}>
    <Stage 
      width={canvasWidth} 
      height={canvasHeight} 
      ref={stageRef}
      onMouseDown={handleMouseDown} 
      onMouseMove={onMouseMove} 
      onTouchMove={onMouseMove} 
      onMouseUp={handleMouseUp} 
      onTouchEnd={handleMouseUp}
      >
      <Layer>
        <Rect
            x={0}
            y={0}
            width={canvasWidth}
            height={canvasHeight}
            fill="black"
            shadowBlur={10}
          />
        <Image image={backgroundImage} x={backgroundImageX} y={backgroundImageY} width={backgroundImageWidth} height={backgroundImageHeight}></Image>
        <Line
          pencilPoints={measurementPoints}
          sceneFunc={(context, shape)=>{
             if (!shape) { return; }
          var w = window.innerWidth,
              h = window.innerHeight;
          let pencilPoints = shape.getAttr('pencilPoints');
            if(pencilPoints && pencilPoints.length >1){
              context.beginPath();
              context.moveTo(pencilPoints[0].x, pencilPoints[0].y);
              context.lineTo(pencilPoints[1].x, pencilPoints[1].y);
              context.strokeStyle = "red";
              context.lineWidth = 3;
              context.lineCap = "round";
              context.lineJoin = "round";
              context.stroke();              
            }
             }}
        />
        {isMeasuring && measurementPoints.length > 1 && (
          <>
          <Rect
            x={distanceTextPos.x+10}
            y={distanceTextPos.y+10}
            width={100}
            height={30}
            fill = '#555'
          />
          <Text 
            x={distanceTextPos.x+10}
            y={distanceTextPos.y+10}
            text={`${pointsDistance}`}
            fontSize ={30}
            fill = 'white'
            width =  {100}
            align = 'center'
            />
          </>
            )}
      </Layer>
    </Stage>
    </div>
  );
};

export default CanvasEditor;