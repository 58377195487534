import React, {useState} from 'react';
import {Dropdown} from 'semantic-ui-react';

const FilterItem = ({browserLang = "en", label=null, options=[], filterKey, onSelectionChange, currentValue, multipleSelect})=>{
	const [itemValue, setItemValue] = useState("all");
	let formattedValue = null;
	if(multipleSelect && currentValue){
		formattedValue = [...currentValue]
	}else if(multipleSelect && !currentValue){
		formattedValue = []
	}else{
		formattedValue = currentValue
	}
	const handleChange = (evt, data)=>{
		const {name, value} = data;
		let newVal = value;
		if(onSelectionChange){
			if(value === "all"){
				newVal = null;
			}
			setItemValue(value);
			onSelectionChange({[filterKey]:newVal});
		}
	};
	return (
		<div style={{marginLeft:"50px",marginRight:"50px"}}>
			{label && (
				<div style={{color:"#3B86CF", textAlign:"left"}}>
				    {label}
				</div>
				)}
			{
				multipleSelect ? 
					<Dropdown onChange={handleChange} multiple value={formattedValue === null ? "all":formattedValue } placeholder="Any" selection options={options}></Dropdown>
					:
					<Dropdown onChange={handleChange} value={currentValue === null ? "all":currentValue } className="filterItem" style={{minHeight:"20px",padding:"8px",lineHeight:0,minWidth:"124px"}} placeholder="Any" selection options={options}></Dropdown>
			}
		</div>
)
}; 

export default FilterItem;