import React from "react";
import "./style.css"
import TwoDimensionalVideoContext from "../TwoDimensionalVideo/twoDimensionalVideoContext";
import { Checkbox, Input } from "semantic-ui-react";
import { debounce } from "lodash";

const AnnotationMetadata = (props) => {

  const twoDimensionalVideoContext = React.useContext(TwoDimensionalVideoContext);
  const {
    entities,
    labelMetadata,
    onMetadataUpdate,
  } = twoDimensionalVideoContext;

  const debounceUpdate = React.useCallback(
    debounce((id, metadata) => {
      onMetadataUpdate(id, metadata);
    }, 500),
    []
  );

  const annotation = entities.annotations[props.annotationId];
  const [metadata, setMetadata] = React.useState(annotation?.metadata ?? []);

  const findValue = (key) => {
    const item = metadata.find(i => i.key === key);
    if (item) return item.value;
    else {
      const metadataItem = labelMetadata.items.find(i => i.key === key);
      if (metadataItem) return metadataItem.defaultValue;
    }
  }

  const handleChange = (key, value) => {
    const itemIndex = metadata.findIndex(i => i.key === key);
    const newMetadata = [...metadata];
    if (itemIndex >= 0) {
      newMetadata[itemIndex].value = value;
    } else {
      newMetadata.push({ key, value });
    }
    setMetadata(newMetadata);
    debounceUpdate(props.annotationId, newMetadata);
  }

  return (
    <div className="annotation-metadata__container">
      {
         labelMetadata.items.filter(i => i.labels.includes(props.labelKey)).length <= 0
         ? <div>No Metadata available for this label</div>
         :labelMetadata.items.filter(i => i.labels.includes(props.labelKey)).map(item => {
          return (
            <div className="annotation-metadata__item">
              <div className="annotation-metadata__item-title">
                {item.name}
              </div>
              <div className="annotation-metadata__item-description">
                {item.description}
              </div>
              <div className="annotation-metadata__item-input">
                {
                  item.type === "string" &&
                  <Input value={findValue(item.key)} onChange={(e, {value}) => handleChange(item.key, value)}/>
                }
                {
                  item.type === "number" &&
                  <Input value={findValue(item.key)} onChange={(e, {value}) => handleChange(item.key, value)} type="number" />
                }
                {
                  item.type === "boolean" &&
                  <>
                    <Checkbox checked={findValue(item.key)} onChange={(e, {checked}) => handleChange(item.key, checked)}/>
                    Yes
                  </>
                }
              </div>
            </div>
          )
        })
      }
    </div>
  )
}

export default AnnotationMetadata;