import _ from "lodash";

export const fullColorList = [
  {
    "colorId": 0,
    "hexString": "#000000",
    "rgb": {
      "r": 0,
      "g": 0,
      "b": 0
    },
    "hsl": {
      "h": 0,
      "s": 0,
      "l": 0
    },
    "name": "Black"
  },
  {
    "colorId": 1,
    "hexString": "#800000",
    "rgb": {
      "r": 128,
      "g": 0,
      "b": 0
    },
    "hsl": {
      "h": 0,
      "s": 100,
      "l": 25
    },
    "name": "Maroon"
  },
  {
    "colorId": 2,
    "hexString": "#008000",
    "rgb": {
      "r": 0,
      "g": 128,
      "b": 0
    },
    "hsl": {
      "h": 120,
      "s": 100,
      "l": 25
    },
    "name": "Green"
  },
  {
    "colorId": 3,
    "hexString": "#808000",
    "rgb": {
      "r": 128,
      "g": 128,
      "b": 0
    },
    "hsl": {
      "h": 60,
      "s": 100,
      "l": 25
    },
    "name": "Olive"
  },
  {
    "colorId": 4,
    "hexString": "#000080",
    "rgb": {
      "r": 0,
      "g": 0,
      "b": 128
    },
    "hsl": {
      "h": 240,
      "s": 100,
      "l": 25
    },
    "name": "Navy"
  },
  {
    "colorId": 5,
    "hexString": "#800080",
    "rgb": {
      "r": 128,
      "g": 0,
      "b": 128
    },
    "hsl": {
      "h": 300,
      "s": 100,
      "l": 25
    },
    "name": "Purple1"
  },
  {
    "colorId": 6,
    "hexString": "#008080",
    "rgb": {
      "r": 0,
      "g": 128,
      "b": 128
    },
    "hsl": {
      "h": 180,
      "s": 100,
      "l": 25
    },
    "name": "Teal"
  },
  {
    "colorId": 9,
    "hexString": "#ff0000",
    "rgb": {
      "r": 255,
      "g": 0,
      "b": 0
    },
    "hsl": {
      "h": 0,
      "s": 100,
      "l": 50
    },
    "name": "Red"
  },
  {
    "colorId": 10,
    "hexString": "#00ff00",
    "rgb": {
      "r": 0,
      "g": 255,
      "b": 0
    },
    "hsl": {
      "h": 120,
      "s": 100,
      "l": 50
    },
    "name": "Lime"
  },
  {
    "colorId": 11,
    "hexString": "#ffff00",
    "rgb": {
      "r": 255,
      "g": 255,
      "b": 0
    },
    "hsl": {
      "h": 60,
      "s": 100,
      "l": 50
    },
    "name": "Yellow"
  },
  {
    "colorId": 12,
    "hexString": "#0000ff",
    "rgb": {
      "r": 0,
      "g": 0,
      "b": 255
    },
    "hsl": {
      "h": 240,
      "s": 100,
      "l": 50
    },
    "name": "Blue"
  },
  {
    "colorId": 13,
    "hexString": "#ff00ff",
    "rgb": {
      "r": 255,
      "g": 0,
      "b": 255
    },
    "hsl": {
      "h": 300,
      "s": 100,
      "l": 50
    },
    "name": "Fuchsia"
  },
  {
    "colorId": 14,
    "hexString": "#00ffff",
    "rgb": {
      "r": 0,
      "g": 255,
      "b": 255
    },
    "hsl": {
      "h": 180,
      "s": 100,
      "l": 50
    },
    "name": "Aqua"
  },
  {
    "colorId": 15,
    "hexString": "#ffffff",
    "rgb": {
      "r": 255,
      "g": 255,
      "b": 255
    },
    "hsl": {
      "h": 0,
      "s": 0,
      "l": 100
    },
    "name": "White"
  },
  {
    "colorId": 17,
    "hexString": "#00005f",
    "rgb": {
      "r": 0,
      "g": 0,
      "b": 95
    },
    "hsl": {
      "h": 240,
      "s": 100,
      "l": 18
    },
    "name": "NavyBlue"
  },
  {
    "colorId": 18,
    "hexString": "#000087",
    "rgb": {
      "r": 0,
      "g": 0,
      "b": 135
    },
    "hsl": {
      "h": 240,
      "s": 100,
      "l": 26
    },
    "name": "DarkBlue"
  },
  {
    "colorId": 19,
    "hexString": "#0000af",
    "rgb": {
      "r": 0,
      "g": 0,
      "b": 175
    },
    "hsl": {
      "h": 240,
      "s": 100,
      "l": 34
    },
    "name": "Blue3"
  },
  {
    "colorId": 20,
    "hexString": "#0000d7",
    "rgb": {
      "r": 0,
      "g": 0,
      "b": 215
    },
    "hsl": {
      "h": 240,
      "s": 100,
      "l": 42
    },
    "name": "Blue4"
  },
  {
    "colorId": 21,
    "hexString": "#0000ff",
    "rgb": {
      "r": 0,
      "g": 0,
      "b": 255
    },
    "hsl": {
      "h": 240,
      "s": 100,
      "l": 50
    },
    "name": "Blue1"
  },
  {
    "colorId": 22,
    "hexString": "#005f00",
    "rgb": {
      "r": 0,
      "g": 95,
      "b": 0
    },
    "hsl": {
      "h": 120,
      "s": 100,
      "l": 18
    },
    "name": "DarkGreen"
  },
  {
    "colorId": 23,
    "hexString": "#005f5f",
    "rgb": {
      "r": 0,
      "g": 95,
      "b": 95
    },
    "hsl": {
      "h": 180,
      "s": 100,
      "l": 18
    },
    "name": "DeepSkyBlue4"
  },
  {
    "colorId": 24,
    "hexString": "#005f87",
    "rgb": {
      "r": 0,
      "g": 95,
      "b": 135
    },
    "hsl": {
      "h": 197.777777777778,
      "s": 100,
      "l": 26
    },
    "name": "DeepSkyBlue6"
  },
  {
    "colorId": 25,
    "hexString": "#005faf",
    "rgb": {
      "r": 0,
      "g": 95,
      "b": 175
    },
    "hsl": {
      "h": 207.428571428571,
      "s": 100,
      "l": 34
    },
    "name": "DeepSkyBlue7"
  },
  {
    "colorId": 26,
    "hexString": "#005fd7",
    "rgb": {
      "r": 0,
      "g": 95,
      "b": 215
    },
    "hsl": {
      "h": 213.488372093023,
      "s": 100,
      "l": 42
    },
    "name": "DodgerBlue3"
  },
  {
    "colorId": 27,
    "hexString": "#005fff",
    "rgb": {
      "r": 0,
      "g": 95,
      "b": 255
    },
    "hsl": {
      "h": 217.647058823529,
      "s": 100,
      "l": 50
    },
    "name": "DodgerBlue2"
  },
  {
    "colorId": 28,
    "hexString": "#008700",
    "rgb": {
      "r": 0,
      "g": 135,
      "b": 0
    },
    "hsl": {
      "h": 120,
      "s": 100,
      "l": 26
    },
    "name": "Green4"
  },
  {
    "colorId": 29,
    "hexString": "#00875f",
    "rgb": {
      "r": 0,
      "g": 135,
      "b": 95
    },
    "hsl": {
      "h": 162.222222222222,
      "s": 100,
      "l": 26
    },
    "name": "SpringGreen4"
  },
  {
    "colorId": 30,
    "hexString": "#008787",
    "rgb": {
      "r": 0,
      "g": 135,
      "b": 135
    },
    "hsl": {
      "h": 180,
      "s": 100,
      "l": 26
    },
    "name": "Turquoise4"
  },
  {
    "colorId": 31,
    "hexString": "#0087af",
    "rgb": {
      "r": 0,
      "g": 135,
      "b": 175
    },
    "hsl": {
      "h": 193.714285714286,
      "s": 100,
      "l": 34
    },
    "name": "DeepSkyBlue3"
  },
  {
    "colorId": 32,
    "hexString": "#0087d7",
    "rgb": {
      "r": 0,
      "g": 135,
      "b": 215
    },
    "hsl": {
      "h": 202.325581395349,
      "s": 100,
      "l": 42
    },
    "name": "DeepSkyBlue5"
  },
  {
    "colorId": 33,
    "hexString": "#0087ff",
    "rgb": {
      "r": 0,
      "g": 135,
      "b": 255
    },
    "hsl": {
      "h": 208.235294117647,
      "s": 100,
      "l": 50
    },
    "name": "DodgerBlue1"
  },
  {
    "colorId": 34,
    "hexString": "#00af00",
    "rgb": {
      "r": 0,
      "g": 175,
      "b": 0
    },
    "hsl": {
      "h": 120,
      "s": 100,
      "l": 34
    },
    "name": "Green5"
  },
  {
    "colorId": 35,
    "hexString": "#00af5f",
    "rgb": {
      "r": 0,
      "g": 175,
      "b": 95
    },
    "hsl": {
      "h": 152.571428571429,
      "s": 100,
      "l": 34
    },
    "name": "SpringGreen3"
  },
  {
    "colorId": 36,
    "hexString": "#00af87",
    "rgb": {
      "r": 0,
      "g": 175,
      "b": 135
    },
    "hsl": {
      "h": 166.285714285714,
      "s": 100,
      "l": 34
    },
    "name": "DarkCyan"
  },
  {
    "colorId": 37,
    "hexString": "#00afaf",
    "rgb": {
      "r": 0,
      "g": 175,
      "b": 175
    },
    "hsl": {
      "h": 180,
      "s": 100,
      "l": 34
    },
    "name": "LightSeaGreen"
  },
  {
    "colorId": 38,
    "hexString": "#00afd7",
    "rgb": {
      "r": 0,
      "g": 175,
      "b": 215
    },
    "hsl": {
      "h": 191.162790697674,
      "s": 100,
      "l": 42
    },
    "name": "DeepSkyBlue2"
  },
  {
    "colorId": 39,
    "hexString": "#00afff",
    "rgb": {
      "r": 0,
      "g": 175,
      "b": 255
    },
    "hsl": {
      "h": 198.823529411765,
      "s": 100,
      "l": 50
    },
    "name": "DeepSkyBlue1"
  },
  {
    "colorId": 40,
    "hexString": "#00d700",
    "rgb": {
      "r": 0,
      "g": 215,
      "b": 0
    },
    "hsl": {
      "h": 120,
      "s": 100,
      "l": 42
    },
    "name": "Green6"
  },
  {
    "colorId": 41,
    "hexString": "#00d75f",
    "rgb": {
      "r": 0,
      "g": 215,
      "b": 95
    },
    "hsl": {
      "h": 146.511627906977,
      "s": 100,
      "l": 42
    },
    "name": "SpringGreen5"
  },
  {
    "colorId": 42,
    "hexString": "#00d787",
    "rgb": {
      "r": 0,
      "g": 215,
      "b": 135
    },
    "hsl": {
      "h": 157.674418604651,
      "s": 100,
      "l": 42
    },
    "name": "SpringGreen2"
  },
  {
    "colorId": 43,
    "hexString": "#00d7af",
    "rgb": {
      "r": 0,
      "g": 215,
      "b": 175
    },
    "hsl": {
      "h": 168.837209302326,
      "s": 100,
      "l": 42
    },
    "name": "Cyan3"
  },
  {
    "colorId": 44,
    "hexString": "#00d7d7",
    "rgb": {
      "r": 0,
      "g": 215,
      "b": 215
    },
    "hsl": {
      "h": 180,
      "s": 100,
      "l": 42
    },
    "name": "DarkTurquoise"
  },
  {
    "colorId": 45,
    "hexString": "#00d7ff",
    "rgb": {
      "r": 0,
      "g": 215,
      "b": 255
    },
    "hsl": {
      "h": 189.411764705882,
      "s": 100,
      "l": 50
    },
    "name": "Turquoise2"
  },
  {
    "colorId": 46,
    "hexString": "#00ff00",
    "rgb": {
      "r": 0,
      "g": 255,
      "b": 0
    },
    "hsl": {
      "h": 120,
      "s": 100,
      "l": 50
    },
    "name": "Green1"
  },
  {
    "colorId": 47,
    "hexString": "#00ff5f",
    "rgb": {
      "r": 0,
      "g": 255,
      "b": 95
    },
    "hsl": {
      "h": 142.352941176471,
      "s": 100,
      "l": 50
    },
    "name": "SpringGreen6"
  },
  {
    "colorId": 48,
    "hexString": "#00ff87",
    "rgb": {
      "r": 0,
      "g": 255,
      "b": 135
    },
    "hsl": {
      "h": 151.764705882353,
      "s": 100,
      "l": 50
    },
    "name": "SpringGreen1"
  },
  {
    "colorId": 49,
    "hexString": "#00ffaf",
    "rgb": {
      "r": 0,
      "g": 255,
      "b": 175
    },
    "hsl": {
      "h": 161.176470588235,
      "s": 100,
      "l": 50
    },
    "name": "MediumSpringGreen"
  },
  {
    "colorId": 50,
    "hexString": "#00ffd7",
    "rgb": {
      "r": 0,
      "g": 255,
      "b": 215
    },
    "hsl": {
      "h": 170.588235294118,
      "s": 100,
      "l": 50
    },
    "name": "Cyan2"
  },
  {
    "colorId": 51,
    "hexString": "#00ffff",
    "rgb": {
      "r": 0,
      "g": 255,
      "b": 255
    },
    "hsl": {
      "h": 180,
      "s": 100,
      "l": 50
    },
    "name": "Cyan1"
  },
  {
    "colorId": 52,
    "hexString": "#5f0000",
    "rgb": {
      "r": 95,
      "g": 0,
      "b": 0
    },
    "hsl": {
      "h": 0,
      "s": 100,
      "l": 18
    },
    "name": "DarkRed"
  },
  {
    "colorId": 53,
    "hexString": "#5f005f",
    "rgb": {
      "r": 95,
      "g": 0,
      "b": 95
    },
    "hsl": {
      "h": 300,
      "s": 100,
      "l": 18
    },
    "name": "DeepPink4"
  },
  {
    "colorId": 54,
    "hexString": "#5f0087",
    "rgb": {
      "r": 95,
      "g": 0,
      "b": 135
    },
    "hsl": {
      "h": 282.222222222222,
      "s": 100,
      "l": 26
    },
    "name": "Purple2"
  },
  {
    "colorId": 55,
    "hexString": "#5f00af",
    "rgb": {
      "r": 95,
      "g": 0,
      "b": 175
    },
    "hsl": {
      "h": 272.571428571429,
      "s": 100,
      "l": 34
    },
    "name": "Purple4"
  },
  {
    "colorId": 56,
    "hexString": "#5f00d7",
    "rgb": {
      "r": 95,
      "g": 0,
      "b": 215
    },
    "hsl": {
      "h": 266.511627906977,
      "s": 100,
      "l": 42
    },
    "name": "Purple3"
  },
  {
    "colorId": 57,
    "hexString": "#5f00ff",
    "rgb": {
      "r": 95,
      "g": 0,
      "b": 255
    },
    "hsl": {
      "h": 262.352941176471,
      "s": 100,
      "l": 50
    },
    "name": "BlueViolet"
  },
  {
    "colorId": 58,
    "hexString": "#5f5f00",
    "rgb": {
      "r": 95,
      "g": 95,
      "b": 0
    },
    "hsl": {
      "h": 60,
      "s": 100,
      "l": 18
    },
    "name": "Orange5"
  },
  {
    "colorId": 60,
    "hexString": "#5f5f87",
    "rgb": {
      "r": 95,
      "g": 95,
      "b": 135
    },
    "hsl": {
      "h": 240,
      "s": 17,
      "l": 45
    },
    "name": "MediumPurple4"
  },
  {
    "colorId": 61,
    "hexString": "#5f5faf",
    "rgb": {
      "r": 95,
      "g": 95,
      "b": 175
    },
    "hsl": {
      "h": 240,
      "s": 33,
      "l": 52
    },
    "name": "SlateBlue3"
  },
  {
    "colorId": 62,
    "hexString": "#5f5fd7",
    "rgb": {
      "r": 95,
      "g": 95,
      "b": 215
    },
    "hsl": {
      "h": 240,
      "s": 60,
      "l": 60
    },
    "name": "SlateBlue4"
  },
  {
    "colorId": 63,
    "hexString": "#5f5fff",
    "rgb": {
      "r": 95,
      "g": 95,
      "b": 255
    },
    "hsl": {
      "h": 240,
      "s": 100,
      "l": 68
    },
    "name": "RoyalBlue1"
  },
  {
    "colorId": 64,
    "hexString": "#5f8700",
    "rgb": {
      "r": 95,
      "g": 135,
      "b": 0
    },
    "hsl": {
      "h": 77.7777777777778,
      "s": 100,
      "l": 26
    },
    "name": "Chartreuse4"
  },
  {
    "colorId": 65,
    "hexString": "#5f875f",
    "rgb": {
      "r": 95,
      "g": 135,
      "b": 95
    },
    "hsl": {
      "h": 120,
      "s": 17,
      "l": 45
    },
    "name": "DarkSeaGreen4"
  },
  {
    "colorId": 66,
    "hexString": "#5f8787",
    "rgb": {
      "r": 95,
      "g": 135,
      "b": 135
    },
    "hsl": {
      "h": 180,
      "s": 17,
      "l": 45
    },
    "name": "PaleTurquoise4"
  },
  {
    "colorId": 67,
    "hexString": "#5f87af",
    "rgb": {
      "r": 95,
      "g": 135,
      "b": 175
    },
    "hsl": {
      "h": 210,
      "s": 33,
      "l": 52
    },
    "name": "SteelBlue"
  },
  {
    "colorId": 68,
    "hexString": "#5f87d7",
    "rgb": {
      "r": 95,
      "g": 135,
      "b": 215
    },
    "hsl": {
      "h": 220,
      "s": 60,
      "l": 60
    },
    "name": "SteelBlue3"
  },
  {
    "colorId": 69,
    "hexString": "#5f87ff",
    "rgb": {
      "r": 95,
      "g": 135,
      "b": 255
    },
    "hsl": {
      "h": 225,
      "s": 100,
      "l": 68
    },
    "name": "CornflowerBlue"
  },
  {
    "colorId": 70,
    "hexString": "#5faf00",
    "rgb": {
      "r": 95,
      "g": 175,
      "b": 0
    },
    "hsl": {
      "h": 87.4285714285714,
      "s": 100,
      "l": 34
    },
    "name": "Chartreuse3"
  },
  {
    "colorId": 71,
    "hexString": "#5faf5f",
    "rgb": {
      "r": 95,
      "g": 175,
      "b": 95
    },
    "hsl": {
      "h": 120,
      "s": 33,
      "l": 52
    },
    "name": "DarkSeaGreen7"
  },
  {
    "colorId": 72,
    "hexString": "#5faf87",
    "rgb": {
      "r": 95,
      "g": 175,
      "b": 135
    },
    "hsl": {
      "h": 150,
      "s": 33,
      "l": 52
    },
    "name": "CadetBlue"
  },
  {
    "colorId": 73,
    "hexString": "#5fafaf",
    "rgb": {
      "r": 95,
      "g": 175,
      "b": 175
    },
    "hsl": {
      "h": 180,
      "s": 33,
      "l": 52
    },
    "name": "CadetBlue1"
  },
  {
    "colorId": 74,
    "hexString": "#5fafd7",
    "rgb": {
      "r": 95,
      "g": 175,
      "b": 215
    },
    "hsl": {
      "h": 200,
      "s": 60,
      "l": 60
    },
    "name": "SkyBlue3"
  },
  {
    "colorId": 75,
    "hexString": "#5fafff",
    "rgb": {
      "r": 95,
      "g": 175,
      "b": 255
    },
    "hsl": {
      "h": 210,
      "s": 100,
      "l": 68
    },
    "name": "SteelBlue1"
  },
  {
    "colorId": 76,
    "hexString": "#5fd700",
    "rgb": {
      "r": 95,
      "g": 215,
      "b": 0
    },
    "hsl": {
      "h": 93.4883720930233,
      "s": 100,
      "l": 42
    },
    "name": "Chartreuse6"
  },
  {
    "colorId": 77,
    "hexString": "#5fd75f",
    "rgb": {
      "r": 95,
      "g": 215,
      "b": 95
    },
    "hsl": {
      "h": 120,
      "s": 60,
      "l": 60
    },
    "name": "PaleGreen3"
  },
  {
    "colorId": 78,
    "hexString": "#5fd787",
    "rgb": {
      "r": 95,
      "g": 215,
      "b": 135
    },
    "hsl": {
      "h": 140,
      "s": 60,
      "l": 60
    },
    "name": "SeaGreen3"
  },
  {
    "colorId": 79,
    "hexString": "#5fd7af",
    "rgb": {
      "r": 95,
      "g": 215,
      "b": 175
    },
    "hsl": {
      "h": 160,
      "s": 60,
      "l": 60
    },
    "name": "Aquamarine3"
  },
  {
    "colorId": 80,
    "hexString": "#5fd7d7",
    "rgb": {
      "r": 95,
      "g": 215,
      "b": 215
    },
    "hsl": {
      "h": 180,
      "s": 60,
      "l": 60
    },
    "name": "MediumTurquoise"
  },
  {
    "colorId": 81,
    "hexString": "#5fd7ff",
    "rgb": {
      "r": 95,
      "g": 215,
      "b": 255
    },
    "hsl": {
      "h": 195,
      "s": 100,
      "l": 68
    },
    "name": "SteelBlue2"
  },
  {
    "colorId": 82,
    "hexString": "#5fff00",
    "rgb": {
      "r": 95,
      "g": 255,
      "b": 0
    },
    "hsl": {
      "h": 97.6470588235294,
      "s": 100,
      "l": 50
    },
    "name": "Chartreuse2"
  },
  {
    "colorId": 83,
    "hexString": "#5fff5f",
    "rgb": {
      "r": 95,
      "g": 255,
      "b": 95
    },
    "hsl": {
      "h": 120,
      "s": 100,
      "l": 68
    },
    "name": "SeaGreen2"
  },
  {
    "colorId": 84,
    "hexString": "#5fff87",
    "rgb": {
      "r": 95,
      "g": 255,
      "b": 135
    },
    "hsl": {
      "h": 135,
      "s": 100,
      "l": 68
    },
    "name": "SeaGreen1"
  },
  {
    "colorId": 85,
    "hexString": "#5fffaf",
    "rgb": {
      "r": 95,
      "g": 255,
      "b": 175
    },
    "hsl": {
      "h": 150,
      "s": 100,
      "l": 68
    },
    "name": "SeaGreen4"
  },
  {
    "colorId": 86,
    "hexString": "#5fffd7",
    "rgb": {
      "r": 95,
      "g": 255,
      "b": 215
    },
    "hsl": {
      "h": 165,
      "s": 100,
      "l": 68
    },
    "name": "Aquamarine1"
  },
  {
    "colorId": 87,
    "hexString": "#5fffff",
    "rgb": {
      "r": 95,
      "g": 255,
      "b": 255
    },
    "hsl": {
      "h": 180,
      "s": 100,
      "l": 68
    },
    "name": "DarkSlateGray2"
  },
  {
    "colorId": 88,
    "hexString": "#870000",
    "rgb": {
      "r": 135,
      "g": 0,
      "b": 0
    },
    "hsl": {
      "h": 0,
      "s": 100,
      "l": 26
    },
    "name": "DarkRed1"
  },
  {
    "colorId": 89,
    "hexString": "#87005f",
    "rgb": {
      "r": 135,
      "g": 0,
      "b": 95
    },
    "hsl": {
      "h": 317.777777777778,
      "s": 100,
      "l": 26
    },
    "name": "DeepPink7"
  },
  {
    "colorId": 90,
    "hexString": "#870087",
    "rgb": {
      "r": 135,
      "g": 0,
      "b": 135
    },
    "hsl": {
      "h": 300,
      "s": 100,
      "l": 26
    },
    "name": "DarkMagenta"
  },
  {
    "colorId": 91,
    "hexString": "#8700af",
    "rgb": {
      "r": 135,
      "g": 0,
      "b": 175
    },
    "hsl": {
      "h": 286.285714285714,
      "s": 100,
      "l": 34
    },
    "name": "DarkMagenta1"
  },
  {
    "colorId": 92,
    "hexString": "#8700d7",
    "rgb": {
      "r": 135,
      "g": 0,
      "b": 215
    },
    "hsl": {
      "h": 277.674418604651,
      "s": 100,
      "l": 42
    },
    "name": "DarkViolet"
  },
  {
    "colorId": 93,
    "hexString": "#8700ff",
    "rgb": {
      "r": 135,
      "g": 0,
      "b": 255
    },
    "hsl": {
      "h": 271.764705882353,
      "s": 100,
      "l": 50
    },
    "name": "Purple"
  },
  {
    "colorId": 94,
    "hexString": "#875f00",
    "rgb": {
      "r": 135,
      "g": 95,
      "b": 0
    },
    "hsl": {
      "h": 42.2222222222222,
      "s": 100,
      "l": 26
    },
    "name": "Orange4"
  },
  {
    "colorId": 95,
    "hexString": "#875f5f",
    "rgb": {
      "r": 135,
      "g": 95,
      "b": 95
    },
    "hsl": {
      "h": 0,
      "s": 17,
      "l": 45
    },
    "name": "LightPink4"
  },
  {
    "colorId": 96,
    "hexString": "#875f87",
    "rgb": {
      "r": 135,
      "g": 95,
      "b": 135
    },
    "hsl": {
      "h": 300,
      "s": 17,
      "l": 45
    },
    "name": "Plum4"
  },
  {
    "colorId": 97,
    "hexString": "#875faf",
    "rgb": {
      "r": 135,
      "g": 95,
      "b": 175
    },
    "hsl": {
      "h": 270,
      "s": 33,
      "l": 52
    },
    "name": "MediumPurple5"
  },
  {
    "colorId": 98,
    "hexString": "#875fd7",
    "rgb": {
      "r": 135,
      "g": 95,
      "b": 215
    },
    "hsl": {
      "h": 260,
      "s": 60,
      "l": 60
    },
    "name": "MediumPurple3"
  },
  {
    "colorId": 99,
    "hexString": "#875fff",
    "rgb": {
      "r": 135,
      "g": 95,
      "b": 255
    },
    "hsl": {
      "h": 255,
      "s": 100,
      "l": 68
    },
    "name": "SlateBlue1"
  },
  {
    "colorId": 100,
    "hexString": "#878700",
    "rgb": {
      "r": 135,
      "g": 135,
      "b": 0
    },
    "hsl": {
      "h": 60,
      "s": 100,
      "l": 26
    },
    "name": "Yellow4"
  },
  {
    "colorId": 101,
    "hexString": "#87875f",
    "rgb": {
      "r": 135,
      "g": 135,
      "b": 95
    },
    "hsl": {
      "h": 60,
      "s": 17,
      "l": 45
    },
    "name": "Wheat4"
  },
  // {
  //   "colorId": 102,
  //   "hexString": "#878787",
  //   "rgb": {
  //     "r": 135,
  //     "g": 135,
  //     "b": 135
  //   },
  //   "hsl": {
  //     "h": 0,
  //     "s": 0,
  //     "l": 52
  //   },
  //   "name": "Grey53"
  // },
  {
    "colorId": 103,
    "hexString": "#a8a8e6",
    "rgb": {
      "r": 135,
      "g": 135,
      "b": 175
    },
    "hsl": {
      "h": 240,
      "s": 20,
      "l": 60
    },
    "name": "LightSlateGrey"
  },
  {
    "colorId": 104,
    "hexString": "#8787d7",
    "rgb": {
      "r": 135,
      "g": 135,
      "b": 215
    },
    "hsl": {
      "h": 240,
      "s": 50,
      "l": 68
    },
    "name": "MediumPurple"
  },
  {
    "colorId": 105,
    "hexString": "#8787ff",
    "rgb": {
      "r": 135,
      "g": 135,
      "b": 255
    },
    "hsl": {
      "h": 240,
      "s": 100,
      "l": 76
    },
    "name": "LightSlateBlue"
  },
  {
    "colorId": 106,
    "hexString": "#87af00",
    "rgb": {
      "r": 135,
      "g": 175,
      "b": 0
    },
    "hsl": {
      "h": 73.7142857142857,
      "s": 100,
      "l": 34
    },
    "name": "Yellow6"
  },
  {
    "colorId": 107,
    "hexString": "#87af5f",
    "rgb": {
      "r": 135,
      "g": 175,
      "b": 95
    },
    "hsl": {
      "h": 90,
      "s": 33,
      "l": 52
    },
    "name": "DarkOliveGreen3"
  },
  {
    "colorId": 108,
    "hexString": "#87af87",
    "rgb": {
      "r": 135,
      "g": 175,
      "b": 135
    },
    "hsl": {
      "h": 120,
      "s": 20,
      "l": 60
    },
    "name": "DarkSeaGreen"
  },
  {
    "colorId": 109,
    "hexString": "#87afaf",
    "rgb": {
      "r": 135,
      "g": 175,
      "b": 175
    },
    "hsl": {
      "h": 180,
      "s": 20,
      "l": 60
    },
    "name": "LightSkyBlue3"
  },
  {
    "colorId": 110,
    "hexString": "#87afd7",
    "rgb": {
      "r": 135,
      "g": 175,
      "b": 215
    },
    "hsl": {
      "h": 210,
      "s": 50,
      "l": 68
    },
    "name": "LightSkyBlue4"
  },
  {
    "colorId": 111,
    "hexString": "#87afff",
    "rgb": {
      "r": 135,
      "g": 175,
      "b": 255
    },
    "hsl": {
      "h": 220,
      "s": 100,
      "l": 76
    },
    "name": "SkyBlue2"
  },
  {
    "colorId": 112,
    "hexString": "#87d700",
    "rgb": {
      "r": 135,
      "g": 215,
      "b": 0
    },
    "hsl": {
      "h": 82.3255813953488,
      "s": 100,
      "l": 42
    },
    "name": "Chartreuse5"
  },
  {
    "colorId": 113,
    "hexString": "#87d75f",
    "rgb": {
      "r": 135,
      "g": 215,
      "b": 95
    },
    "hsl": {
      "h": 100,
      "s": 60,
      "l": 60
    },
    "name": "DarkOliveGreen4"
  },
  {
    "colorId": 114,
    "hexString": "#87d787",
    "rgb": {
      "r": 135,
      "g": 215,
      "b": 135
    },
    "hsl": {
      "h": 120,
      "s": 50,
      "l": 68
    },
    "name": "PaleGreen4"
  },
  {
    "colorId": 115,
    "hexString": "#87d7af",
    "rgb": {
      "r": 135,
      "g": 215,
      "b": 175
    },
    "hsl": {
      "h": 150,
      "s": 50,
      "l": 68
    },
    "name": "DarkSeaGreen3"
  },
  {
    "colorId": 116,
    "hexString": "#87d7d7",
    "rgb": {
      "r": 135,
      "g": 215,
      "b": 215
    },
    "hsl": {
      "h": 180,
      "s": 50,
      "l": 68
    },
    "name": "DarkSlateGray3"
  },
  {
    "colorId": 117,
    "hexString": "#87d7ff",
    "rgb": {
      "r": 135,
      "g": 215,
      "b": 255
    },
    "hsl": {
      "h": 200,
      "s": 100,
      "l": 76
    },
    "name": "SkyBlue1"
  },
  {
    "colorId": 118,
    "hexString": "#87ff00",
    "rgb": {
      "r": 135,
      "g": 255,
      "b": 0
    },
    "hsl": {
      "h": 88.2352941176471,
      "s": 100,
      "l": 50
    },
    "name": "Chartreuse1"
  },
  {
    "colorId": 119,
    "hexString": "#87ff5f",
    "rgb": {
      "r": 135,
      "g": 255,
      "b": 95
    },
    "hsl": {
      "h": 105,
      "s": 100,
      "l": 68
    },
    "name": "LightGreen"
  },
  {
    "colorId": 120,
    "hexString": "#87ff87",
    "rgb": {
      "r": 135,
      "g": 255,
      "b": 135
    },
    "hsl": {
      "h": 120,
      "s": 100,
      "l": 76
    },
    "name": "LightGreen1"
  },
  {
    "colorId": 121,
    "hexString": "#87ffaf",
    "rgb": {
      "r": 135,
      "g": 255,
      "b": 175
    },
    "hsl": {
      "h": 140,
      "s": 100,
      "l": 76
    },
    "name": "PaleGreen1"
  },
  {
    "colorId": 122,
    "hexString": "#87ffd7",
    "rgb": {
      "r": 135,
      "g": 255,
      "b": 215
    },
    "hsl": {
      "h": 160,
      "s": 100,
      "l": 76
    },
    "name": "Aquamarine2"
  },
  {
    "colorId": 123,
    "hexString": "#87ffff",
    "rgb": {
      "r": 135,
      "g": 255,
      "b": 255
    },
    "hsl": {
      "h": 180,
      "s": 100,
      "l": 76
    },
    "name": "DarkSlateGray1"
  },
  {
    "colorId": 124,
    "hexString": "#af0000",
    "rgb": {
      "r": 175,
      "g": 0,
      "b": 0
    },
    "hsl": {
      "h": 0,
      "s": 100,
      "l": 34
    },
    "name": "Red3"
  },
  {
    "colorId": 125,
    "hexString": "#af005f",
    "rgb": {
      "r": 175,
      "g": 0,
      "b": 95
    },
    "hsl": {
      "h": 327.428571428571,
      "s": 100,
      "l": 34
    },
    "name": "DeepPink8"
  },
  {
    "colorId": 126,
    "hexString": "#af0087",
    "rgb": {
      "r": 175,
      "g": 0,
      "b": 135
    },
    "hsl": {
      "h": 313.714285714286,
      "s": 100,
      "l": 34
    },
    "name": "MediumVioletRed"
  },
  {
    "colorId": 127,
    "hexString": "#af00af",
    "rgb": {
      "r": 175,
      "g": 0,
      "b": 175
    },
    "hsl": {
      "h": 300,
      "s": 100,
      "l": 34
    },
    "name": "Magenta3"
  },
  {
    "colorId": 128,
    "hexString": "#af00d7",
    "rgb": {
      "r": 175,
      "g": 0,
      "b": 215
    },
    "hsl": {
      "h": 288.837209302326,
      "s": 100,
      "l": 42
    },
    "name": "DarkViolet1"
  },
  {
    "colorId": 129,
    "hexString": "#af00ff",
    "rgb": {
      "r": 175,
      "g": 0,
      "b": 255
    },
    "hsl": {
      "h": 281.176470588235,
      "s": 100,
      "l": 50
    },
    "name": "Purple5"
  },
  {
    "colorId": 130,
    "hexString": "#af5f00",
    "rgb": {
      "r": 175,
      "g": 95,
      "b": 0
    },
    "hsl": {
      "h": 32.5714285714286,
      "s": 100,
      "l": 34
    },
    "name": "DarkOrange3"
  },
  {
    "colorId": 131,
    "hexString": "#af5f5f",
    "rgb": {
      "r": 175,
      "g": 95,
      "b": 95
    },
    "hsl": {
      "h": 0,
      "s": 33,
      "l": 52
    },
    "name": "IndianRed"
  },
  {
    "colorId": 132,
    "hexString": "#af5f87",
    "rgb": {
      "r": 175,
      "g": 95,
      "b": 135
    },
    "hsl": {
      "h": 330,
      "s": 33,
      "l": 52
    },
    "name": "HotPink3"
  },
  {
    "colorId": 133,
    "hexString": "#af5faf",
    "rgb": {
      "r": 175,
      "g": 95,
      "b": 175
    },
    "hsl": {
      "h": 300,
      "s": 33,
      "l": 52
    },
    "name": "MediumOrchid3"
  },
  {
    "colorId": 134,
    "hexString": "#af5fd7",
    "rgb": {
      "r": 175,
      "g": 95,
      "b": 215
    },
    "hsl": {
      "h": 280,
      "s": 60,
      "l": 60
    },
    "name": "MediumOrchid"
  },
  {
    "colorId": 135,
    "hexString": "#af5fff",
    "rgb": {
      "r": 175,
      "g": 95,
      "b": 255
    },
    "hsl": {
      "h": 270,
      "s": 100,
      "l": 68
    },
    "name": "MediumPurple2"
  },
  {
    "colorId": 136,
    "hexString": "#af8700",
    "rgb": {
      "r": 175,
      "g": 135,
      "b": 0
    },
    "hsl": {
      "h": 46.2857142857143,
      "s": 100,
      "l": 34
    },
    "name": "DarkGoldenrod"
  },
  {
    "colorId": 137,
    "hexString": "#af875f",
    "rgb": {
      "r": 175,
      "g": 135,
      "b": 95
    },
    "hsl": {
      "h": 30,
      "s": 33,
      "l": 52
    },
    "name": "LightSalmon3"
  },
  {
    "colorId": 138,
    "hexString": "#af8787",
    "rgb": {
      "r": 175,
      "g": 135,
      "b": 135
    },
    "hsl": {
      "h": 0,
      "s": 20,
      "l": 60
    },
    "name": "RosyBrown"
  },
  // {
  //   "colorId": 139,
  //   "hexString": "#af87af",
  //   "rgb": {
  //     "r": 175,
  //     "g": 135,
  //     "b": 175
  //   },
  //   "hsl": {
  //     "h": 300,
  //     "s": 20,
  //     "l": 60
  //   },
  //   "name": "Grey63"
  // },
  {
    "colorId": 140,
    "hexString": "#af87d7",
    "rgb": {
      "r": 175,
      "g": 135,
      "b": 215
    },
    "hsl": {
      "h": 270,
      "s": 50,
      "l": 68
    },
    "name": "MediumPurple6"
  },
  {
    "colorId": 141,
    "hexString": "#af87ff",
    "rgb": {
      "r": 175,
      "g": 135,
      "b": 255
    },
    "hsl": {
      "h": 260,
      "s": 100,
      "l": 76
    },
    "name": "MediumPurple1"
  },
  {
    "colorId": 142,
    "hexString": "#afaf00",
    "rgb": {
      "r": 175,
      "g": 175,
      "b": 0
    },
    "hsl": {
      "h": 60,
      "s": 100,
      "l": 34
    },
    "name": "Gold3"
  },
  {
    "colorId": 143,
    "hexString": "#afaf5f",
    "rgb": {
      "r": 175,
      "g": 175,
      "b": 95
    },
    "hsl": {
      "h": 60,
      "s": 33,
      "l": 52
    },
    "name": "DarkKhaki"
  },
  {
    "colorId": 144,
    "hexString": "#afaf87",
    "rgb": {
      "r": 175,
      "g": 175,
      "b": 135
    },
    "hsl": {
      "h": 60,
      "s": 20,
      "l": 60
    },
    "name": "NavajoWhite3"
  },
  // {
  //   "colorId": 145,
  //   "hexString": "#afafaf",
  //   "rgb": {
  //     "r": 175,
  //     "g": 175,
  //     "b": 175
  //   },
  //   "hsl": {
  //     "h": 0,
  //     "s": 0,
  //     "l": 68
  //   },
  //   "name": "Grey69"
  // },
  {
    "colorId": 146,
    "hexString": "#afafd7",
    "rgb": {
      "r": 175,
      "g": 175,
      "b": 215
    },
    "hsl": {
      "h": 240,
      "s": 33,
      "l": 76
    },
    "name": "LightSteelBlue3"
  },
  {
    "colorId": 147,
    "hexString": "#afafff",
    "rgb": {
      "r": 175,
      "g": 175,
      "b": 255
    },
    "hsl": {
      "h": 240,
      "s": 100,
      "l": 84
    },
    "name": "LightSteelBlue"
  },
  {
    "colorId": 148,
    "hexString": "#afd700",
    "rgb": {
      "r": 175,
      "g": 215,
      "b": 0
    },
    "hsl": {
      "h": 71.1627906976744,
      "s": 100,
      "l": 42
    },
    "name": "Yellow3"
  },
  {
    "colorId": 149,
    "hexString": "#afd75f",
    "rgb": {
      "r": 175,
      "g": 215,
      "b": 95
    },
    "hsl": {
      "h": 80,
      "s": 60,
      "l": 60
    },
    "name": "DarkOliveGreen5"
  },
  {
    "colorId": 150,
    "hexString": "#afd787",
    "rgb": {
      "r": 175,
      "g": 215,
      "b": 135
    },
    "hsl": {
      "h": 90,
      "s": 50,
      "l": 68
    },
    "name": "DarkSeaGreen6"
  },
  {
    "colorId": 151,
    "hexString": "#afd7af",
    "rgb": {
      "r": 175,
      "g": 215,
      "b": 175
    },
    "hsl": {
      "h": 120,
      "s": 33,
      "l": 76
    },
    "name": "DarkSeaGreen5"
  },
  {
    "colorId": 152,
    "hexString": "#afd7d7",
    "rgb": {
      "r": 175,
      "g": 215,
      "b": 215
    },
    "hsl": {
      "h": 180,
      "s": 33,
      "l": 76
    },
    "name": "LightCyan3"
  },
  {
    "colorId": 153,
    "hexString": "#afd7ff",
    "rgb": {
      "r": 175,
      "g": 215,
      "b": 255
    },
    "hsl": {
      "h": 210,
      "s": 100,
      "l": 84
    },
    "name": "LightSkyBlue1"
  },
  {
    "colorId": 154,
    "hexString": "#afff00",
    "rgb": {
      "r": 175,
      "g": 255,
      "b": 0
    },
    "hsl": {
      "h": 78.8235294117647,
      "s": 100,
      "l": 50
    },
    "name": "GreenYellow"
  },
  {
    "colorId": 155,
    "hexString": "#afff5f",
    "rgb": {
      "r": 175,
      "g": 255,
      "b": 95
    },
    "hsl": {
      "h": 90,
      "s": 100,
      "l": 68
    },
    "name": "DarkOliveGreen2"
  },
  {
    "colorId": 156,
    "hexString": "#afff87",
    "rgb": {
      "r": 175,
      "g": 255,
      "b": 135
    },
    "hsl": {
      "h": 100,
      "s": 100,
      "l": 76
    },
    "name": "PaleGreen2"
  },
  {
    "colorId": 157,
    "hexString": "#afffaf",
    "rgb": {
      "r": 175,
      "g": 255,
      "b": 175
    },
    "hsl": {
      "h": 120,
      "s": 100,
      "l": 84
    },
    "name": "DarkSeaGreen8"
  },
  {
    "colorId": 158,
    "hexString": "#afffd7",
    "rgb": {
      "r": 175,
      "g": 255,
      "b": 215
    },
    "hsl": {
      "h": 150,
      "s": 100,
      "l": 84
    },
    "name": "DarkSeaGreen1"
  },
  {
    "colorId": 159,
    "hexString": "#afffff",
    "rgb": {
      "r": 175,
      "g": 255,
      "b": 255
    },
    "hsl": {
      "h": 180,
      "s": 100,
      "l": 84
    },
    "name": "PaleTurquoise1"
  },
  {
    "colorId": 160,
    "hexString": "#d70000",
    "rgb": {
      "r": 215,
      "g": 0,
      "b": 0
    },
    "hsl": {
      "h": 0,
      "s": 100,
      "l": 42
    },
    "name": "Red4"
  },
  {
    "colorId": 161,
    "hexString": "#d7005f",
    "rgb": {
      "r": 215,
      "g": 0,
      "b": 95
    },
    "hsl": {
      "h": 333.488372093023,
      "s": 100,
      "l": 42
    },
    "name": "DeepPink3"
  },
  {
    "colorId": 162,
    "hexString": "#d70087",
    "rgb": {
      "r": 215,
      "g": 0,
      "b": 135
    },
    "hsl": {
      "h": 322.325581395349,
      "s": 100,
      "l": 42
    },
    "name": "DeepPink6"
  },
  {
    "colorId": 163,
    "hexString": "#d700af",
    "rgb": {
      "r": 215,
      "g": 0,
      "b": 175
    },
    "hsl": {
      "h": 311.162790697674,
      "s": 100,
      "l": 42
    },
    "name": "Magenta5"
  },
  {
    "colorId": 164,
    "hexString": "#d700d7",
    "rgb": {
      "r": 215,
      "g": 0,
      "b": 215
    },
    "hsl": {
      "h": 300,
      "s": 100,
      "l": 42
    },
    "name": "Magenta6"
  },
  {
    "colorId": 165,
    "hexString": "#d700ff",
    "rgb": {
      "r": 215,
      "g": 0,
      "b": 255
    },
    "hsl": {
      "h": 290.588235294118,
      "s": 100,
      "l": 50
    },
    "name": "Magenta2"
  },
  {
    "colorId": 166,
    "hexString": "#d75f00",
    "rgb": {
      "r": 215,
      "g": 95,
      "b": 0
    },
    "hsl": {
      "h": 26.5116279069767,
      "s": 100,
      "l": 42
    },
    "name": "DarkOrange4"
  },
  {
    "colorId": 167,
    "hexString": "#d75f5f",
    "rgb": {
      "r": 215,
      "g": 95,
      "b": 95
    },
    "hsl": {
      "h": 0,
      "s": 60,
      "l": 60
    },
    "name": "IndianRed3"
  },
  {
    "colorId": 168,
    "hexString": "#d75f87",
    "rgb": {
      "r": 215,
      "g": 95,
      "b": 135
    },
    "hsl": {
      "h": 340,
      "s": 60,
      "l": 60
    },
    "name": "HotPink4"
  },
  {
    "colorId": 169,
    "hexString": "#d75faf",
    "rgb": {
      "r": 215,
      "g": 95,
      "b": 175
    },
    "hsl": {
      "h": 320,
      "s": 60,
      "l": 60
    },
    "name": "HotPink2"
  },
  {
    "colorId": 170,
    "hexString": "#d75fd7",
    "rgb": {
      "r": 215,
      "g": 95,
      "b": 215
    },
    "hsl": {
      "h": 300,
      "s": 60,
      "l": 60
    },
    "name": "Orchid"
  },
  {
    "colorId": 171,
    "hexString": "#d75fff",
    "rgb": {
      "r": 215,
      "g": 95,
      "b": 255
    },
    "hsl": {
      "h": 285,
      "s": 100,
      "l": 68
    },
    "name": "MediumOrchid1"
  },
  {
    "colorId": 172,
    "hexString": "#d78700",
    "rgb": {
      "r": 215,
      "g": 135,
      "b": 0
    },
    "hsl": {
      "h": 37.6744186046512,
      "s": 100,
      "l": 42
    },
    "name": "Orange3"
  },
  {
    "colorId": 173,
    "hexString": "#d7875f",
    "rgb": {
      "r": 215,
      "g": 135,
      "b": 95
    },
    "hsl": {
      "h": 20,
      "s": 60,
      "l": 60
    },
    "name": "LightSalmon4"
  },
  {
    "colorId": 174,
    "hexString": "#d78787",
    "rgb": {
      "r": 215,
      "g": 135,
      "b": 135
    },
    "hsl": {
      "h": 0,
      "s": 50,
      "l": 68
    },
    "name": "LightPink3"
  },
  {
    "colorId": 175,
    "hexString": "#d787af",
    "rgb": {
      "r": 215,
      "g": 135,
      "b": 175
    },
    "hsl": {
      "h": 330,
      "s": 50,
      "l": 68
    },
    "name": "Pink3"
  },
  {
    "colorId": 176,
    "hexString": "#d787d7",
    "rgb": {
      "r": 215,
      "g": 135,
      "b": 215
    },
    "hsl": {
      "h": 300,
      "s": 50,
      "l": 68
    },
    "name": "Plum3"
  },
  {
    "colorId": 177,
    "hexString": "#d787ff",
    "rgb": {
      "r": 215,
      "g": 135,
      "b": 255
    },
    "hsl": {
      "h": 280,
      "s": 100,
      "l": 76
    },
    "name": "Violet"
  },
  {
    "colorId": 178,
    "hexString": "#d7af00",
    "rgb": {
      "r": 215,
      "g": 175,
      "b": 0
    },
    "hsl": {
      "h": 48.8372093023256,
      "s": 100,
      "l": 42
    },
    "name": "Gold4"
  },
  {
    "colorId": 179,
    "hexString": "#d7af5f",
    "rgb": {
      "r": 215,
      "g": 175,
      "b": 95
    },
    "hsl": {
      "h": 40,
      "s": 60,
      "l": 60
    },
    "name": "LightGoldenrod3"
  },
  {
    "colorId": 180,
    "hexString": "#d7af87",
    "rgb": {
      "r": 215,
      "g": 175,
      "b": 135
    },
    "hsl": {
      "h": 30,
      "s": 50,
      "l": 68
    },
    "name": "Tan"
  },
  {
    "colorId": 181,
    "hexString": "#d7afaf",
    "rgb": {
      "r": 215,
      "g": 175,
      "b": 175
    },
    "hsl": {
      "h": 0,
      "s": 33,
      "l": 76
    },
    "name": "MistyRose3"
  },
  {
    "colorId": 182,
    "hexString": "#d7afd7",
    "rgb": {
      "r": 215,
      "g": 175,
      "b": 215
    },
    "hsl": {
      "h": 300,
      "s": 33,
      "l": 76
    },
    "name": "Thistle3"
  },
  {
    "colorId": 183,
    "hexString": "#d7afff",
    "rgb": {
      "r": 215,
      "g": 175,
      "b": 255
    },
    "hsl": {
      "h": 270,
      "s": 100,
      "l": 84
    },
    "name": "Plum2"
  },
  {
    "colorId": 184,
    "hexString": "#d7d700",
    "rgb": {
      "r": 215,
      "g": 215,
      "b": 0
    },
    "hsl": {
      "h": 60,
      "s": 100,
      "l": 42
    },
    "name": "Yellow5"
  },
  {
    "colorId": 185,
    "hexString": "#d7d75f",
    "rgb": {
      "r": 215,
      "g": 215,
      "b": 95
    },
    "hsl": {
      "h": 60,
      "s": 60,
      "l": 60
    },
    "name": "Khaki3"
  },
  {
    "colorId": 186,
    "hexString": "#d7d787",
    "rgb": {
      "r": 215,
      "g": 215,
      "b": 135
    },
    "hsl": {
      "h": 60,
      "s": 50,
      "l": 68
    },
    "name": "LightGoldenrod2"
  },
  {
    "colorId": 187,
    "hexString": "#d7d7af",
    "rgb": {
      "r": 215,
      "g": 215,
      "b": 175
    },
    "hsl": {
      "h": 60,
      "s": 33,
      "l": 76
    },
    "name": "LightYellow3"
  },
  // {
  //   "colorId": 188,
  //   "hexString": "#d7d7d7",
  //   "rgb": {
  //     "r": 215,
  //     "g": 215,
  //     "b": 215
  //   },
  //   "hsl": {
  //     "h": 0,
  //     "s": 0,
  //     "l": 84
  //   },
  //   "name": "Grey84"
  // },
  {
    "colorId": 189,
    "hexString": "#d7d7ff",
    "rgb": {
      "r": 215,
      "g": 215,
      "b": 255
    },
    "hsl": {
      "h": 240,
      "s": 100,
      "l": 92
    },
    "name": "LightSteelBlue1"
  },
  {
    "colorId": 190,
    "hexString": "#d7ff00",
    "rgb": {
      "r": 215,
      "g": 255,
      "b": 0
    },
    "hsl": {
      "h": 69.4117647058823,
      "s": 100,
      "l": 50
    },
    "name": "Yellow2"
  },
  {
    "colorId": 191,
    "hexString": "#d7ff5f",
    "rgb": {
      "r": 215,
      "g": 255,
      "b": 95
    },
    "hsl": {
      "h": 75,
      "s": 100,
      "l": 68
    },
    "name": "DarkOliveGreen1"
  },
  {
    "colorId": 192,
    "hexString": "#d7ff87",
    "rgb": {
      "r": 215,
      "g": 255,
      "b": 135
    },
    "hsl": {
      "h": 80,
      "s": 100,
      "l": 76
    },
    "name": "DarkOliveGreen6"
  },
  {
    "colorId": 193,
    "hexString": "#d7ffaf",
    "rgb": {
      "r": 215,
      "g": 255,
      "b": 175
    },
    "hsl": {
      "h": 90,
      "s": 100,
      "l": 84
    },
    "name": "DarkSeaGreen2"
  },
  {
    "colorId": 194,
    "hexString": "#d7ffd7",
    "rgb": {
      "r": 215,
      "g": 255,
      "b": 215
    },
    "hsl": {
      "h": 120,
      "s": 100,
      "l": 92
    },
    "name": "Honeydew2"
  },
  {
    "colorId": 195,
    "hexString": "#d7ffff",
    "rgb": {
      "r": 215,
      "g": 255,
      "b": 255
    },
    "hsl": {
      "h": 180,
      "s": 100,
      "l": 92
    },
    "name": "LightCyan1"
  },
  {
    "colorId": 196,
    "hexString": "#ff0000",
    "rgb": {
      "r": 255,
      "g": 0,
      "b": 0
    },
    "hsl": {
      "h": 0,
      "s": 100,
      "l": 50
    },
    "name": "Red1"
  },
  {
    "colorId": 197,
    "hexString": "#ff005f",
    "rgb": {
      "r": 255,
      "g": 0,
      "b": 95
    },
    "hsl": {
      "h": 337.647058823529,
      "s": 100,
      "l": 50
    },
    "name": "DeepPink2"
  },
  {
    "colorId": 198,
    "hexString": "#ff0087",
    "rgb": {
      "r": 255,
      "g": 0,
      "b": 135
    },
    "hsl": {
      "h": 328.235294117647,
      "s": 100,
      "l": 50
    },
    "name": "DeepPink1"
  },
  {
    "colorId": 199,
    "hexString": "#ff00af",
    "rgb": {
      "r": 255,
      "g": 0,
      "b": 175
    },
    "hsl": {
      "h": 318.823529411765,
      "s": 100,
      "l": 50
    },
    "name": "DeepPink5"
  },
  {
    "colorId": 200,
    "hexString": "#ff00d7",
    "rgb": {
      "r": 255,
      "g": 0,
      "b": 215
    },
    "hsl": {
      "h": 309.411764705882,
      "s": 100,
      "l": 50
    },
    "name": "Magenta4"
  },
  {
    "colorId": 201,
    "hexString": "#ff00ff",
    "rgb": {
      "r": 255,
      "g": 0,
      "b": 255
    },
    "hsl": {
      "h": 300,
      "s": 100,
      "l": 50
    },
    "name": "Magenta1"
  },
  {
    "colorId": 202,
    "hexString": "#ff5f00",
    "rgb": {
      "r": 255,
      "g": 95,
      "b": 0
    },
    "hsl": {
      "h": 22.3529411764706,
      "s": 100,
      "l": 50
    },
    "name": "OrangeRed1"
  },
  {
    "colorId": 203,
    "hexString": "#ff5f5f",
    "rgb": {
      "r": 255,
      "g": 95,
      "b": 95
    },
    "hsl": {
      "h": 0,
      "s": 100,
      "l": 68
    },
    "name": "IndianRed2"
  },
  {
    "colorId": 204,
    "hexString": "#ff5f87",
    "rgb": {
      "r": 255,
      "g": 95,
      "b": 135
    },
    "hsl": {
      "h": 345,
      "s": 100,
      "l": 68
    },
    "name": "IndianRed1"
  },
  {
    "colorId": 205,
    "hexString": "#ff5faf",
    "rgb": {
      "r": 255,
      "g": 95,
      "b": 175
    },
    "hsl": {
      "h": 330,
      "s": 100,
      "l": 68
    },
    "name": "HotPink"
  },
  {
    "colorId": 206,
    "hexString": "#ff5fd7",
    "rgb": {
      "r": 255,
      "g": 95,
      "b": 215
    },
    "hsl": {
      "h": 315,
      "s": 100,
      "l": 68
    },
    "name": "HotPink1"
  },
  {
    "colorId": 207,
    "hexString": "#ff5fff",
    "rgb": {
      "r": 255,
      "g": 95,
      "b": 255
    },
    "hsl": {
      "h": 300,
      "s": 100,
      "l": 68
    },
    "name": "MediumOrchid4"
  },
  {
    "colorId": 208,
    "hexString": "#ff8700",
    "rgb": {
      "r": 255,
      "g": 135,
      "b": 0
    },
    "hsl": {
      "h": 31.7647058823529,
      "s": 100,
      "l": 50
    },
    "name": "DarkOrange"
  },
  {
    "colorId": 209,
    "hexString": "#ff875f",
    "rgb": {
      "r": 255,
      "g": 135,
      "b": 95
    },
    "hsl": {
      "h": 15,
      "s": 100,
      "l": 68
    },
    "name": "Salmon1"
  },
  {
    "colorId": 210,
    "hexString": "#ff8787",
    "rgb": {
      "r": 255,
      "g": 135,
      "b": 135
    },
    "hsl": {
      "h": 0,
      "s": 100,
      "l": 76
    },
    "name": "LightCoral"
  },
  {
    "colorId": 211,
    "hexString": "#ff87af",
    "rgb": {
      "r": 255,
      "g": 135,
      "b": 175
    },
    "hsl": {
      "h": 340,
      "s": 100,
      "l": 76
    },
    "name": "PaleVioletRed1"
  },
  {
    "colorId": 212,
    "hexString": "#ff87d7",
    "rgb": {
      "r": 255,
      "g": 135,
      "b": 215
    },
    "hsl": {
      "h": 320,
      "s": 100,
      "l": 76
    },
    "name": "Orchid2"
  },
  {
    "colorId": 213,
    "hexString": "#ff87ff",
    "rgb": {
      "r": 255,
      "g": 135,
      "b": 255
    },
    "hsl": {
      "h": 300,
      "s": 100,
      "l": 76
    },
    "name": "Orchid1"
  },
  {
    "colorId": 214,
    "hexString": "#ffaf00",
    "rgb": {
      "r": 255,
      "g": 175,
      "b": 0
    },
    "hsl": {
      "h": 41.1764705882353,
      "s": 100,
      "l": 50
    },
    "name": "Orange1"
  },
  {
    "colorId": 215,
    "hexString": "#ffaf5f",
    "rgb": {
      "r": 255,
      "g": 175,
      "b": 95
    },
    "hsl": {
      "h": 30,
      "s": 100,
      "l": 68
    },
    "name": "SandyBrown"
  },
  {
    "colorId": 216,
    "hexString": "#ffaf87",
    "rgb": {
      "r": 255,
      "g": 175,
      "b": 135
    },
    "hsl": {
      "h": 20,
      "s": 100,
      "l": 76
    },
    "name": "LightSalmon1"
  },
  {
    "colorId": 217,
    "hexString": "#ffafaf",
    "rgb": {
      "r": 255,
      "g": 175,
      "b": 175
    },
    "hsl": {
      "h": 0,
      "s": 100,
      "l": 84
    },
    "name": "LightPink1"
  },
  {
    "colorId": 218,
    "hexString": "#ffafd7",
    "rgb": {
      "r": 255,
      "g": 175,
      "b": 215
    },
    "hsl": {
      "h": 330,
      "s": 100,
      "l": 84
    },
    "name": "Pink1"
  },
  {
    "colorId": 219,
    "hexString": "#ffafff",
    "rgb": {
      "r": 255,
      "g": 175,
      "b": 255
    },
    "hsl": {
      "h": 300,
      "s": 100,
      "l": 84
    },
    "name": "Plum1"
  },
  {
    "colorId": 220,
    "hexString": "#ffd700",
    "rgb": {
      "r": 255,
      "g": 215,
      "b": 0
    },
    "hsl": {
      "h": 50.5882352941176,
      "s": 100,
      "l": 50
    },
    "name": "Gold1"
  },
  {
    "colorId": 221,
    "hexString": "#ffd75f",
    "rgb": {
      "r": 255,
      "g": 215,
      "b": 95
    },
    "hsl": {
      "h": 45,
      "s": 100,
      "l": 68
    },
    "name": "LightGoldenrod4"
  },
  {
    "colorId": 222,
    "hexString": "#ffd787",
    "rgb": {
      "r": 255,
      "g": 215,
      "b": 135
    },
    "hsl": {
      "h": 40,
      "s": 100,
      "l": 76
    },
    "name": "LightGoldenrod5"
  },
  {
    "colorId": 223,
    "hexString": "#ffd7af",
    "rgb": {
      "r": 255,
      "g": 215,
      "b": 175
    },
    "hsl": {
      "h": 30,
      "s": 100,
      "l": 84
    },
    "name": "NavajoWhite1"
  },
  {
    "colorId": 224,
    "hexString": "#ffd7d7",
    "rgb": {
      "r": 255,
      "g": 215,
      "b": 215
    },
    "hsl": {
      "h": 0,
      "s": 100,
      "l": 92
    },
    "name": "MistyRose1"
  },
  {
    "colorId": 225,
    "hexString": "#ffd7ff",
    "rgb": {
      "r": 255,
      "g": 215,
      "b": 255
    },
    "hsl": {
      "h": 300,
      "s": 100,
      "l": 92
    },
    "name": "Thistle1"
  },
  {
    "colorId": 226,
    "hexString": "#ffff00",
    "rgb": {
      "r": 255,
      "g": 255,
      "b": 0
    },
    "hsl": {
      "h": 60,
      "s": 100,
      "l": 50
    },
    "name": "Yellow1"
  },
  {
    "colorId": 227,
    "hexString": "#ffff5f",
    "rgb": {
      "r": 255,
      "g": 255,
      "b": 95
    },
    "hsl": {
      "h": 60,
      "s": 100,
      "l": 68
    },
    "name": "LightGoldenrod1"
  },
  {
    "colorId": 228,
    "hexString": "#ffff87",
    "rgb": {
      "r": 255,
      "g": 255,
      "b": 135
    },
    "hsl": {
      "h": 60,
      "s": 100,
      "l": 76
    },
    "name": "Khaki1"
  },
  {
    "colorId": 229,
    "hexString": "#ffffaf",
    "rgb": {
      "r": 255,
      "g": 255,
      "b": 175
    },
    "hsl": {
      "h": 60,
      "s": 100,
      "l": 84
    },
    "name": "Wheat1"
  },
  {
    "colorId": 230,
    "hexString": "#ffffd7",
    "rgb": {
      "r": 255,
      "g": 255,
      "b": 215
    },
    "hsl": {
      "h": 60,
      "s": 100,
      "l": 92
    },
    "name": "Cornsilk1"
  },
  // {
  //   "colorId": 231,
  //   "hexString": "#ffffff",
  //   "rgb": {
  //     "r": 255,
  //     "g": 255,
  //     "b": 255
  //   },
  //   "hsl": {
  //     "h": 0,
  //     "s": 0,
  //     "l": 100
  //   },
  //   "name": "Grey100"
  // },
  // {
  //   "colorId": 232,
  //   "hexString": "#080808",
  //   "rgb": {
  //     "r": 8,
  //     "g": 8,
  //     "b": 8
  //   },
  //   "hsl": {
  //     "h": 0,
  //     "s": 0,
  //     "l": 3
  //   },
  //   "name": "Grey3"
  // },
  // {
  //   "colorId": 233,
  //   "hexString": "#121212",
  //   "rgb": {
  //     "r": 18,
  //     "g": 18,
  //     "b": 18
  //   },
  //   "hsl": {
  //     "h": 0,
  //     "s": 0,
  //     "l": 7
  //   },
  //   "name": "Grey7"
  // },
  // {
  //   "colorId": 234,
  //   "hexString": "#1c1c1c",
  //   "rgb": {
  //     "r": 28,
  //     "g": 28,
  //     "b": 28
  //   },
  //   "hsl": {
  //     "h": 0,
  //     "s": 0,
  //     "l": 10
  //   },
  //   "name": "Grey11"
  // },
  // {
  //   "colorId": 235,
  //   "hexString": "#262626",
  //   "rgb": {
  //     "r": 38,
  //     "g": 38,
  //     "b": 38
  //   },
  //   "hsl": {
  //     "h": 0,
  //     "s": 0,
  //     "l": 14
  //   },
  //   "name": "Grey15"
  // },
  // {
  //   "colorId": 236,
  //   "hexString": "#303030",
  //   "rgb": {
  //     "r": 48,
  //     "g": 48,
  //     "b": 48
  //   },
  //   "hsl": {
  //     "h": 0,
  //     "s": 0,
  //     "l": 18
  //   },
  //   "name": "Grey19"
  // },
  // {
  //   "colorId": 237,
  //   "hexString": "#3a3a3a",
  //   "rgb": {
  //     "r": 58,
  //     "g": 58,
  //     "b": 58
  //   },
  //   "hsl": {
  //     "h": 0,
  //     "s": 0,
  //     "l": 22
  //   },
  //   "name": "Grey23"
  // },
  // {
  //   "colorId": 238,
  //   "hexString": "#444444",
  //   "rgb": {
  //     "r": 68,
  //     "g": 68,
  //     "b": 68
  //   },
  //   "hsl": {
  //     "h": 0,
  //     "s": 0,
  //     "l": 26
  //   },
  //   "name": "Grey27"
  // },
  // {
  //   "colorId": 239,
  //   "hexString": "#4e4e4e",
  //   "rgb": {
  //     "r": 78,
  //     "g": 78,
  //     "b": 78
  //   },
  //   "hsl": {
  //     "h": 0,
  //     "s": 0,
  //     "l": 30
  //   },
  //   "name": "Grey30"
  // },
  // {
  //   "colorId": 240,
  //   "hexString": "#585858",
  //   "rgb": {
  //     "r": 88,
  //     "g": 88,
  //     "b": 88
  //   },
  //   "hsl": {
  //     "h": 0,
  //     "s": 0,
  //     "l": 34
  //   },
  //   "name": "Grey35"
  // },
  // {
  //   "colorId": 241,
  //   "hexString": "#626262",
  //   "rgb": {
  //     "r": 98,
  //     "g": 98,
  //     "b": 98
  //   },
  //   "hsl": {
  //     "h": 0,
  //     "s": 0,
  //     "l": 37
  //   },
  //   "name": "Grey39"
  // },
  // {
  //   "colorId": 242,
  //   "hexString": "#6c6c6c",
  //   "rgb": {
  //     "r": 108,
  //     "g": 108,
  //     "b": 108
  //   },
  //   "hsl": {
  //     "h": 0,
  //     "s": 0,
  //     "l": 40
  //   },
  //   "name": "Grey42"
  // },
  // {
  //   "colorId": 243,
  //   "hexString": "#767676",
  //   "rgb": {
  //     "r": 118,
  //     "g": 118,
  //     "b": 118
  //   },
  //   "hsl": {
  //     "h": 0,
  //     "s": 0,
  //     "l": 46
  //   },
  //   "name": "Grey46"
  // },
  // {
  //   "colorId": 244,
  //   "hexString": "#808080",
  //   "rgb": {
  //     "r": 128,
  //     "g": 128,
  //     "b": 128
  //   },
  //   "hsl": {
  //     "h": 0,
  //     "s": 0,
  //     "l": 50
  //   },
  //   "name": "Grey50"
  // },
  // {
  //   "colorId": 245,
  //   "hexString": "#8a8a8a",
  //   "rgb": {
  //     "r": 138,
  //     "g": 138,
  //     "b": 138
  //   },
  //   "hsl": {
  //     "h": 0,
  //     "s": 0,
  //     "l": 54
  //   },
  //   "name": "Grey54"
  // },
  // {
  //   "colorId": 246,
  //   "hexString": "#949494",
  //   "rgb": {
  //     "r": 148,
  //     "g": 148,
  //     "b": 148
  //   },
  //   "hsl": {
  //     "h": 0,
  //     "s": 0,
  //     "l": 58
  //   },
  //   "name": "Grey58"
  // },
  // {
  //   "colorId": 247,
  //   "hexString": "#9e9e9e",
  //   "rgb": {
  //     "r": 158,
  //     "g": 158,
  //     "b": 158
  //   },
  //   "hsl": {
  //     "h": 0,
  //     "s": 0,
  //     "l": 61
  //   },
  //   "name": "Grey62"
  // },
  // {
  //   "colorId": 248,
  //   "hexString": "#a8a8a8",
  //   "rgb": {
  //     "r": 168,
  //     "g": 168,
  //     "b": 168
  //   },
  //   "hsl": {
  //     "h": 0,
  //     "s": 0,
  //     "l": 65
  //   },
  //   "name": "Grey66"
  // },
  // {
  //   "colorId": 249,
  //   "hexString": "#b2b2b2",
  //   "rgb": {
  //     "r": 178,
  //     "g": 178,
  //     "b": 178
  //   },
  //   "hsl": {
  //     "h": 0,
  //     "s": 0,
  //     "l": 69
  //   },
  //   "name": "Grey70"
  // },
  // {
  //   "colorId": 250,
  //   "hexString": "#bcbcbc",
  //   "rgb": {
  //     "r": 188,
  //     "g": 188,
  //     "b": 188
  //   },
  //   "hsl": {
  //     "h": 0,
  //     "s": 0,
  //     "l": 73
  //   },
  //   "name": "Grey74"
  // },
  // {
  //   "colorId": 251,
  //   "hexString": "#c6c6c6",
  //   "rgb": {
  //     "r": 198,
  //     "g": 198,
  //     "b": 198
  //   },
  //   "hsl": {
  //     "h": 0,
  //     "s": 0,
  //     "l": 77
  //   },
  //   "name": "Grey78"
  // },
  // {
  //   "colorId": 252,
  //   "hexString": "#d0d0d0",
  //   "rgb": {
  //     "r": 208,
  //     "g": 208,
  //     "b": 208
  //   },
  //   "hsl": {
  //     "h": 0,
  //     "s": 0,
  //     "l": 81
  //   },
  //   "name": "Grey82"
  // },
  // {
  //   "colorId": 253,
  //   "hexString": "#dadada",
  //   "rgb": {
  //     "r": 218,
  //     "g": 218,
  //     "b": 218
  //   },
  //   "hsl": {
  //     "h": 0,
  //     "s": 0,
  //     "l": 85
  //   },
  //   "name": "Grey85"
  // },
  // {
  //   "colorId": 254,
  //   "hexString": "#e4e4e4",
  //   "rgb": {
  //     "r": 228,
  //     "g": 228,
  //     "b": 228
  //   },
  //   "hsl": {
  //     "h": 0,
  //     "s": 0,
  //     "l": 89
  //   },
  //   "name": "Grey89"
  // },
  // {
  //   "colorId": 255,
  //   "hexString": "#eeeeee",
  //   "rgb": {
  //     "r": 238,
  //     "g": 238,
  //     "b": 238
  //   },
  //   "hsl": {
  //     "h": 0,
  //     "s": 0,
  //     "l": 93
  //   },
  //   "name": "Grey93"
  // }
];


export const defaultLabels = [
  {
    "Engin Name": "Longitudinales por Fatiga",
    "name": "FL_1",
    "type": "polyline"
  },
  {
    "Engin Name": "Longitudinales por Fatiga",
    "name": "FL_S",
    "type": "polygon"
  },
  {
    "Engin Name": "Longitudinales por Fatiga",
    "name": "FL_2",
    "type": "polyline"
  },
  {
    "Engin Name": "Fisura Ramificada",
    "name": "FR_1",
    "type": "polygon"
  },
  {
    "Engin Name": "Fisura Ramificada",
    "name": "FR_S",
    "type": "polygon"
  },
  {
    "Engin Name": "Longitudinales por Fatiga",
    "name": "FL_3",
    "type": "polyline"
  },
  {
    "Engin Name": "Fisura Ramificada",
    "name": "FR_3",
    "type": "polygon"
  },
  {
    "Engin Name": "Transversales",
    "name": "FT_1",
    "type": "polyline"
  },
  {
    "Engin Name": "Transversales",
    "name": "FT_S",
    "type": "polygon"
  },
  {
    "Engin Name": "Transversales",
    "name": "FT_2",
    "type": "polyline"
  },
  {
    "Engin Name": "Transversales",
    "name": "FT_3",
    "type": "polyline"
  },
  {
    "Engin Name": "Junta de Construcción Longitudinal",
    "name": "FCL_0",
    "type": "polyline"
  },
  {
    "Engin Name": "Junta de Construcción Longitudinal",
    "name": "FCL_1",
    "type": "polyline"
  },
  {
    "Engin Name": "Junta de Construcción Longitudinal",
    "name": "FCL_S",
    "type": "polygon"
  },
  {
    "Engin Name": "Junta de Construcción Longitudinal",
    "name": "FCL_2",
    "type": "polyline"
  },
  {
    "Engin Name": "Junta de Construcción Longitudinal",
    "name": "FCL_3",
    "type": "polyline"
  },
  {
    "Engin Name": "Junta de Construcción Transversal",
    "name": "FCT_0",
    "type": "polyline"
  },
  {
    "Engin Name": "Junta de Construcción Transversal",
    "name": "FCT_1",
    "type": "polyline"
  },
  {
    "Engin Name": "Junta de Construcción Transversal",
    "name": "FCT_S",
    "type": "polygon"
  },
  {
    "Engin Name": "Junta de Construcción Transversal",
    "name": "FCT_2",
    "type": "polyline"
  },
  {
    "Engin Name": "Junta de Construcción Transversal",
    "name": "FCT_3",
    "type": "polyline"
  },
  {
    "Engin Name": "Reflexión de Juntas Rígidas Longitudinal",
    "name": "FJL_1",
    "type": "polyline"
  },
  {
    "Engin Name": "Reflexión de Juntas Rígidas Longitudinal",
    "name": "FJL_S",
    "type": "polygon"
  },
  {
    "Engin Name": "Reflexión de Juntas Rígidas Longitudinal",
    "name": "FJL_2",
    "type": "polyline"
  },
  {
    "Engin Name": "Reflexión de Juntas Rígidas Longitudinal",
    "name": "FJL_3",
    "type": "polyline"
  },
  {
    "Engin Name": "Reflexión de Juntas Rígidas Transversal",
    "name": "FJT_1",
    "type": "polyline"
  },
  {
    "Engin Name": "Reflexión de Juntas Rígidas Transversal",
    "name": "FJT_S",
    "type": "polygon"
  },
  {
    "Engin Name": "Reflexión de Juntas Rígidas Transversal",
    "name": "FJT_2",
    "type": "polyline"
  },
  {
    "Engin Name": "Reflexión de Juntas Rígidas Transversal",
    "name": "FJT_3",
    "type": "polyline"
  },
  {
    "Engin Name": "Media Luna",
    "name": "FML_1",
    "type": "polygon"
  },
  {
    "Engin Name": "Media Luna",
    "name": "FML_2",
    "type": "polygon"
  },
  {
    "Engin Name": "Media Luna",
    "name": "FML_3",
    "type": "polygon"
  },
  {
    "Engin Name": "De Borde",
    "name": "FBD_1",
    "type": "polyline"
  },
  {
    "Engin Name": "De Borde",
    "name": "FBD_S",
    "type": "polygon"
  },
  {
    "Engin Name": "De Borde",
    "name": "FBD_2",
    "type": "polyline"
  },
  {
    "Engin Name": "De Borde",
    "name": "FBD_3",
    "type": "polyline"
  },
  {
    "Engin Name": "En Bloque",
    "name": "FB_1",
    "type": "polygon"
  },
  {
    "Engin Name": "En Bloque",
    "name": "FB_2",
    "type": "polygon"
  },
  {
    "Engin Name": "En Bloque",
    "name": "FB_3",
    "type": "polygon"
  },
  {
    "Engin Name": "En Bloque",
    "name": "FB_S",
    "type": "polygon"
  },
  // {
  //   "Engin Name": "En Bloque Parcialmente Sellada",
  //   "name": "FB_1_S",
  //   "type": "polygon"
  // },
  // {
  //   "Engin Name": "En Bloque Parcialmente Sellada",
  //   "name": "FB_2_S",
  //   "type": "polygon"
  // },
  // {
  //   "Engin Name": "En Bloque Parcialmente Sellada",
  //   "name": "FB_3_S",
  //   "type": "polygon"
  // },
  {
    "Engin Name": "Piel de Cocodrilo",
    "name": "PC_1",
    "type": "polygon"
  },
  {
    "Engin Name": "Piel de Cocodrilo",
    "name": "PC_2",
    "type": "polygon"
  },
  {
    "Engin Name": "Piel de Cocodrilo",
    "name": "PC_3",
    "type": "polygon"
  },
  // {
  //   "Engin Name": "Piel de Cocodrilo - Polygon",
  //   "name": "PC_1_POL",
  //   "type": "polygon"
  // },
  // {
  //   "Engin Name": "Piel de Cocodrilo - Polygon",
  //   "name": "PC_2_POL",
  //   "type": "polygon"
  // },
  // {
  //   "Engin Name": "Piel de Cocodrilo- Polygon",
  //   "name": "PC_3_POL",
  //   "type": "polygon"
  // },
  {
    "Engin Name": "Deslizamiento de Capas",
    "name": "FDC_1",
    "type": "polygon"
  },
  {
    "Engin Name": "Deslizamiento de Capas",
    "name": "FDC_2",
    "type": "polygon"
  },
  {
    "Engin Name": "Deslizamiento de Capas",
    "name": "FDC_3",
    "type": "polygon"
  },
  {
    "Engin Name": "Incipiente",
    "name": "FIN",
    "type": "polygon"
  },
  {
    "Engin Name": "Ondulación",
    "name": "OND_1",
    "type": "polygon"
  },
  {
    "Engin Name": "Ondulación",
    "name": "OND_2",
    "type": "polygon"
  },
  {
    "Engin Name": "Ondulación",
    "name": "OND_3",
    "type": "polygon"
  },
  {
    "Engin Name": "Hundimiento",
    "name": "HUN_1",
    "type": "polygon"
  },
  {
    "Engin Name": "Hundimiento",
    "name": "HUN_2",
    "type": "polygon"
  },
  {
    "Engin Name": "Hundimiento",
    "name": "HUN_3",
    "type": "polygon"
  },
  {
    "Engin Name": "Ahuellamiento",
    "name": "AHU_1",
    "type": "polygon"
  },
  {
    "Engin Name": "Ahuellamiento",
    "name": "AHU_2",
    "type": "polygon"
  },
  {
    "Engin Name": "Ahuellamiento",
    "name": "AHU_3",
    "type": "polygon"
  },
  {
    "Engin Name": "Inchamiento",
    "name": "AB_1",
    "type": "polygon"
  },
  {
    "Engin Name": "Inchamiento",
    "name": "AB_2",
    "type": "polygon"
  },
  {
    "Engin Name": "Inchamiento",
    "name": "AB_3",
    "type": "polygon"
  },
  {
    "Engin Name": "Descascaramiento",
    "name": "DC_1",
    "type": "polygon"
  },
  {
    "Engin Name": "Descascaramiento",
    "name": "DC_2",
    "type": "polygon"
  },
  {
    "Engin Name": "Descascaramiento",
    "name": "DC_3",
    "type": "polygon"
  },
  {
    "Engin Name": "Bache",
    "name": "BCH_1",
    "type": "polygon"
  },
  {
    "Engin Name": "Bache",
    "name": "BCH_2",
    "type": "polygon"
  },
  {
    "Engin Name": "Bache",
    "name": "BCH_3",
    "type": "polygon"
  },
  {
    "Engin Name": "Parche",
    "name": "PCH_1",
    "type": "polygon"
  },
  {
    "Engin Name": "Parche",
    "name": "PCH_2",
    "type": "polygon"
  },
  {
    "Engin Name": "Parche",
    "name": "PCH_3",
    "type": "polygon"
  },
  {
    "Engin Name": "Desgaste Superficial",
    "name": "DSU_1",
    "type": "polygon"
  },
  {
    "Engin Name": "Desgaste Superficial",
    "name": "DSU_2",
    "type": "polygon"
  },
  {
    "Engin Name": "Pérdida de Agregado",
    "name": "PA_1",
    "type": "polygon"
  },
  {
    "Engin Name": "Pérdida de Agregado",
    "name": "PA_2",
    "type": "polygon"
  },
  {
    "Engin Name": "Pérdida de Agregado",
    "name": "PA_3",
    "type": "polygon"
  },
  {
    "Engin Name": "Pulimiento de Agregado",
    "name": "PU_1",
    "type": "polygon"
  },
  {
    "Engin Name": "Pulimiento de Agregado",
    "name": "PU_2",
    "type": "polygon"
  },
  {
    "Engin Name": "Pulimiento de Agregado",
    "name": "PU_3",
    "type": "polygon"
  },
  {
    "Engin Name": "Cabezas Duras",
    "name": "DSU_3",
    "type": "polygon"
  },
  {
    "Engin Name": "Exudación",
    "name": "EX_1",
    "type": "polygon"
  },
  {
    "Engin Name": "Exudación",
    "name": "EX_2",
    "type": "polygon"
  },
  {
    "Engin Name": "Exudación",
    "name": "EX_3",
    "type": "polygon"
  },
  {
    "Engin Name": "Surcos",
    "name": "SU",
    "type": "polygon"
  },
  {
    "Engin Name": "Afloramiento de Finos",
    "name": "AFI_1",
    "type": "polygon"
  },
  {
    "Engin Name": "Afloramiento de Finos",
    "name": "AFI_2",
    "type": "polygon"
  },
  {
    "Engin Name": "Afloramiento de Finos",
    "name": "AFI_3",
    "type": "polygon"
  },
  {
    "Engin Name": "Afloramiento de Agua",
    "name": "AFA_1",
    "type": "polygon"
  },
  {
    "Engin Name": "Afloramiento de Agua",
    "name": "AFA_2",
    "type": "polygon"
  },
  {
    "Engin Name": "Afloramiento de Agua",
    "name": "AFA_3",
    "type": "polygon"
  },
  {
    "Engin Name": "Escalonamiento Calzada-Berma",
    "name": "CV_1",
    "type": "polygon"
  },
  {
    "Engin Name": "Escalonamiento Calzada-Berma",
    "name": "CV_2",
    "type": "polygon"
  },
  {
    "Engin Name": "Escalonamiento Calzada-Berma",
    "name": "CV_3",
    "type": "polygon"
  },
  {
    "Engin Name": "Separación de la Berma",
    "name": "SB_1",
    "type": "polygon"
  },
  {
    "Engin Name": "Separación de la Berma",
    "name": "SB_2",
    "type": "polygon"
  },
  {
    "Engin Name": "Separación de la Berma",
    "name": "SB_3",
    "type": "polygon"
  },
  {
    "Engin Name": "Desintegración de Bordes",
    "name": "DB_1",
    "type": "polygon"
  },
  {
    "Engin Name": "Desintegración de Bordes",
    "name": "DB_2",
    "type": "polygon"
  },
  {
    "Engin Name": "Desintegración de Bordes",
    "name": "DB_3",
    "type": "polygon"
  },
  {
    "Engin Name": "Erosión de las Bermas",
    "name": "EB_1",
    "type": "polygon"
  },
  {
    "Engin Name": "Erosión de las Bermas",
    "name": "EB_2",
    "type": "polygon"
  },
  {
    "Engin Name": "Erosión de las Bermas",
    "name": "EB_3",
    "type": "polygon"
  },
  {
    "Engin Name": "Segregación",
    "name": "S_1",
    "type": "polygon"
  },
  {
    "Engin Name": "Segregación",
    "name": "S_2",
    "type": "polygon"
  },
  {
    "Engin Name": "Segregación",
    "name": "S_3",
    "type": "polygon"
  },
  {
    "Engin Name": "Paso de Línea Férrea",
    "name": "PLF_1",
    "type": "polygon"
  },
  {
    "Engin Name": "Paso de Línea Férrea",
    "name": "PLF_2",
    "type": "polygon"
  },
  {
    "Engin Name": "Paso de Línea Férrea",
    "name": "PLF_3",
    "type": "polygon"
  },
  {
    "Engin Name": "Tratamiento Superficial",
    "name": "TS_1",
    "type": "polygon"
  },
  {
    "Engin Name": "Tratamiento Superficial",
    "name": "TS_2",
    "type": "polygon"
  },
  {
    "Engin Name": "Tratamiento Superficial",
    "name": "TS_3",
    "type": "polygon"
  },
  {
    "Engin Name": "Rayas de Maquinaria",
    "name": "RM",
    "type": "polygon"
  },
  {
    "Engin Name": "Junta de Puente",
    "name": "JP",
    "type": "polygon"
  },
  {
    "Engin Name": "Liqueo de Vehículos",
    "name": "LV",
    "type": "polygon"
  },
  {
    "Engin Name": "Marcas de Neumático por Freno",
    "name": "MNF",
    "type": "polygon"
  },
  {
    "Engin Name": "Marcas de Neumático Quemado",
    "name": "MNQ",
    "type": "polygon"
  },
  {
    "Engin Name": "Vegetación",
    "name": "VEG",
    "type": "polygon"
  },
  {
    "Engin Name": "Carros",
    "name": "CAR",
    "type": "polygon"
  },
  {
    "Engin Name": "Pavimento Rígido",
    "name": "P_RIG",
    "type": "polygon"
  },
  {
    "Engin Name": "Pavimento Afirmado",
    "name": "P_AFR",
    "type": "polygon"
  },
  {
    "Engin Name": "Pavimento Articulado",
    "name": "P_ART",
    "type": "polygon"
  },
  {
    "Engin Name": "Carcamo",
    "name": "CRM",
    "type": "polygon"
  },
  {
    "Engin Name": "Tapa Alcantarilla",
    "name": "ALC_1",
    "type": "polygon"
  },
  {
    "Engin Name": "Tapa Alcantarilla",
    "name": "ALC_2",
    "type": "polygon"
  },
  {
    "Engin Name": "Tapa Alcantarilla",
    "name": "ALC_3",
    "type": "polygon"
  },
  {
    "Engin Name": "Fin Pavimento",
    "name": "FPAV",
    "type": "polygon"
  },
  {
    "Engin Name": "Tierra",
    "name": "TRR",
    "type": "polygon"
  },
  {
    "Engin Name": "Fisura Ramificada",
    "name": "FR_2",
    "type": "polygon"
  },
  {
    "Engin Name": "Sombra",
    "name": "SOM",
    "type": "polygon"
  },
  {
    "Engin Name": "Piel de Cocodrilo Sellada",
    "name": "PC_S",
    "type": "polygon"
  },
  {
    "Engin Name": "Miscelaneo",
    "name": "MISC",
    "type": "polygon"
  },
  {
    "Engin Name": "Lineas de Demarcación",
    "name": "LDI_1",
    "type": "polygon"
  },
  {
    "Engin Name": "Lineas de Demarcación",
    "name": "LDI_2",
    "type": "polygon"
  },
  {
    "Engin Name": "Lineas de Demarcación",
    "name": "LDI_3",
    "type": "polygon"
  },
  {
    "Engin Name": "Lineas de Demarcación",
    "name": "LDD_1",
    "type": "polygon"
  },
  {
    "Engin Name": "Lineas de Demarcación",
    "name": "LDD_2",
    "type": "polygon"
  },
  {
    "Engin Name": "Lineas de Demarcación",
    "name": "LDD_3",
    "type": "polygon"
  },
  {
    "Engin Name": "Lineas de Demarcación",
    "name": "LDC_1",
    "type": "polygon"
  },
  {
    "Engin Name": "Lineas de Demarcación",
    "name": "LDC_2",
    "type": "polygon"
  },
  {
    "Engin Name": "Lineas de Demarcación",
    "name": "LDC_3",
    "type": "polygon"
  },
  {
    "Engin Name": "Lineas de Demarcación",
    "name": "LDCI_1",
    "type": "polygon"
  },
  {
    "Engin Name": "Lineas de Demarcación",
    "name": "LDCI_2",
    "type": "polygon"
  },
  {
    "Engin Name": "Lineas de Demarcación",
    "name": "LDCI_3",
    "type": "polygon"
  },
  {
    "Engin Name": "Lineas de Demarcación",
    "name": "LDCD_1",
    "type": "polygon"
  },
  {
    "Engin Name": "Lineas de Demarcación",
    "name": "LDCD_2",
    "type": "polygon"
  },
  {
    "Engin Name": "Lineas de Demarcación",
    "name": "LDCD_3",
    "type": "polygon"
  },
  {
    "Engin Name": "Lineas de Demarcación",
    "name": "LDCPART_1",
    "type": "polygon"
  },
  {
    "Engin Name": "Lineas de Demarcación",
    "name": "LDCPART_2",
    "type": "polygon"
  },
  {
    "Engin Name": "Lineas de Demarcación",
    "name": "LDCPART_3",
    "type": "polygon"
  },
  {
    "Engin Name": "Lineas de Demarcación",
    "name": "LDCPARTI_1",
    "type": "polygon"
  },
  {
    "Engin Name": "Lineas de Demarcación",
    "name": "LDCPARTI_2",
    "type": "polygon"
  },
  {
    "Engin Name": "Lineas de Demarcación",
    "name": "LDCPARTI_3",
    "type": "polygon"
  },
  {
    "Engin Name": "Lineas de Demarcación",
    "name": "LDCPARTD_1",
    "type": "polygon"
  },
  {
    "Engin Name": "Lineas de Demarcación",
    "name": "LDCPARTD_2",
    "type": "polygon"
  },
  {
    "Engin Name": "Lineas de Demarcación",
    "name": "LDCPARTD_3",
    "type": "polygon"
  },
  {
    "Engin Name": "Cuneta",
    "name": "CUN_1",
    "type": "polygon"
  },
  {
    "Engin Name": "Cuneta",
    "name": "CUN_2",
    "type": "polygon"
  },
  {
    "Engin Name": "Cuneta",
    "name": "CUN_3",
    "type": "polygon"
  },
  {
    "Engin Name": "Sumidero",
    "name": "SUM_1",
    "type": "polygon"
  },
  {
    "Engin Name": "Sumidero",
    "name": "SUM_2",
    "type": "polygon"
  },
  {
    "Engin Name": "Sumidero",
    "name": "SUM_3",
    "type": "polygon"
  },
  {
    "Engin Name": "Señales de transito",
    "name": "SENAL_1",
    "type": "polygon"
  },
  {
    "Engin Name": "Señales de transito",
    "name": "SENAL_2",
    "type": "polygon"
  },
  {
    "Engin Name": "Señales de transito",
    "name": "SENAL_3",
    "type": "polygon"
  },
  {
    "Engin Name": "Paso peatonal",
    "name": "CROSSW_1",
    "type": "polygon"
  },
  {
    "Engin Name": "Paso peatonal",
    "name": "CROSSW_2",
    "type": "polygon"
  },
  {
    "Engin Name": "Paso peatonal",
    "name": "CROSSW_3",
    "type": "polygon"
  },
  {
    "Engin Name": "Pinturas",
    "name": "PINT_1",
    "type": "polygon"
  },
  {
    "Engin Name": "Pinturas",
    "name": "PINT_2",
    "type": "polygon"
  },
  {
    "Engin Name": "Pinturas",
    "name": "PINT_3",
    "type": "polygon"
  },
  {
    "Engin Name": "Reductor de Velocidad",
    "name": "RV",
    "type": "polygon"
  },
  {
    "Engin Name": "Reductor de Velocidad",
    "name": "RVEL_1",
    "type": "polygon"
  },
  {
    "Engin Name": "Reductor de Velocidad",
    "name": "RVEL_2",
    "type": "polygon"
  },
  {
    "Engin Name": "Reductor de Velocidad",
    "name": "RVEL_3",
    "type": "polygon"
  },
  {
    "Engin Name": "Separadores",
    "name": "CENTRAL",
    "type": "polygon"
  },
  {
    "Engin Name": "Separadores",
    "name": "CENTRAL_PINT_1",
    "type": "polygon"
  },
  {
    "Engin Name": "Separadores",
    "name": "CENTRAL_PINT_2",
    "type": "polygon"
  },
  {
    "Engin Name": "Separadores",
    "name": "CENTRAL_PINT_3",
    "type": "polygon"
  },
  {
    "Engin Name": "Peatones",
    "name": "PEDE",
    "type": "polygon"
  }, 
  {
    "Engin Name": "Berma",
    "name": "BERMA",
    "type": "polygon"
  },   
  {
    "Engin Name": "Pavimento Nuevo",
    "name": "P_NUEVO",
    "type": "polygon"
  },
  {
    "Engin Name": "Carretera en Construcción",
    "name": "CONST",
    "type": "polygon"
  }
];

export const pciAsfaltLabels=[{'Engin Name': 'Longitudinal and Transverse Cracking',
  'name': '10_L',
  'type': 'polyline'},
 {'Engin Name': 'Longitudinal and Transverse Cracking',
  'name': '10_M',
  'type': 'polyline'},
 {'Engin Name': 'Longitudinal and Transverse Cracking',
  'name': '10_H',
  'type': 'polyline'},
 {'Engin Name': 'Longitudinal and Transverse Cracking',
  'name': '10_L',
  'type': 'polygon'},
 {'Engin Name': 'Longitudinal and Transverse Cracking',
  'name': '10_L',
  'type': 'polygon'},
 {'Engin Name': 'Longitudinal and Transverse Cracking',
  'name': '10_L',
  'type': 'polygon'},
 {'Engin Name': 'Longitudinal and Transverse Cracking',
  'name': '10_H',
  'type': 'polygon'},
 {'Engin Name': 'Longitudinal and Transverse Cracking',
  'name': '10_L',
  'type': 'polygon'},
 {'Engin Name': 'Longitudinal and Transverse Cracking',
  'name': '10_L',
  'type': 'polyline'},
 {'Engin Name': 'Longitudinal and Transverse Cracking',
  'name': '10_M',
  'type': 'polyline'},
 {'Engin Name': 'Longitudinal and Transverse Cracking',
  'name': '10_H',
  'type': 'polyline'},
 {'Engin Name': 'Longitudinal and Transverse Cracking',
  'name': '10_L',
  'type': 'polygon'},
 {'Engin Name': 'Joint Reflection Cracking',
  'name': '10_L',
  'type': 'polyline'},
 {'Engin Name': 'Joint Reflection Cracking',
  'name': '10_M',
  'type': 'polyline'},
 {'Engin Name': 'Joint Reflection Cracking',
  'name': '10_H',
  'type': 'polyline'},
 {'Engin Name': 'Joint Reflection Cracking', 'name': '8_L', 'type': 'polygon'},
 {'Engin Name': 'Joint Reflection Cracking',
  'name': '8_L',
  'type': 'polyline'},
 {'Engin Name': 'Joint Reflection Cracking',
  'name': '8_M',
  'type': 'polyline'},
 {'Engin Name': 'Joint Reflection Cracking',
  'name': '8_H',
  'type': 'polyline'},
 {'Engin Name': 'Joint Reflection Cracking', 'name': '8_L', 'type': 'polygon'},
 {'Engin Name': 'Joint Reflection Cracking',
  'name': '8_L',
  'type': 'polyline'},
 {'Engin Name': 'Joint Reflection Cracking',
  'name': '8_M',
  'type': 'polyline'},
 {'Engin Name': 'Joint Reflection Cracking',
  'name': '8_H',
  'type': 'polyline'},
 {'Engin Name': 'Joint Reflection Cracking', 'name': '8_L', 'type': 'polygon'},
 {'Engin Name': 'Joint Reflection Cracking',
  'name': '8_L',
  'type': 'polyline'},
 {'Engin Name': 'Joint Reflection Cracking',
  'name': '8_M',
  'type': 'polyline'},
 {'Engin Name': 'Joint Reflection Cracking',
  'name': '8_H',
  'type': 'polyline'},
 {'Engin Name': 'Joint Reflection Cracking', 'name': '8_L', 'type': 'polygon'},
 {'Engin Name': 'Alligator Cracking', 'name': '1_M', 'type': 'polygon'},
 {'Engin Name': 'Alligator Cracking', 'name': '1_M', 'type': 'polygon'},
 {'Engin Name': 'Alligator Cracking', 'name': '1_H', 'type': 'polygon'},
 {'Engin Name': 'Edge Cracking', 'name': '7_L', 'type': 'polyline'},
 {'Engin Name': 'Edge Cracking', 'name': '7_L', 'type': 'polygon'},
 {'Engin Name': 'Edge Cracking', 'name': '7_M', 'type': 'polyline'},
 {'Engin Name': 'Edge Cracking', 'name': '7_H', 'type': 'polyline'},
 {'Engin Name': 'Block Cracking', 'name': '3_L', 'type': 'polygon'},
 {'Engin Name': 'Block Cracking', 'name': '3_M', 'type': 'polygon'},
 {'Engin Name': 'Block Cracking', 'name': '3_H', 'type': 'polygon'},
 {'Engin Name': 'Alligator Cracking', 'name': '1_L', 'type': 'polygon'},
 {'Engin Name': 'Alligator Cracking', 'name': '1_M', 'type': 'polygon'},
 {'Engin Name': 'Alligator Cracking', 'name': '1_H', 'type': 'polygon'},
 {'Engin Name': 'Slippage Cracking', 'name': '17_L', 'type': 'polygon'},
 {'Engin Name': 'Slippage Cracking', 'name': '17_M', 'type': 'polygon'},
 {'Engin Name': 'Slippage Cracking', 'name': '17_H', 'type': 'polygon'},
 {'Engin Name': 'Corrugation', 'name': '5_L', 'type': 'polygon'},
 {'Engin Name': 'Corrugation', 'name': '5_M', 'type': 'polygon'},
 {'Engin Name': 'Corrugation', 'name': '5_H', 'type': 'polygon'},
 {'Engin Name': 'Bumps and Sags', 'name': '4_L', 'type': 'polygon'},
 {'Engin Name': 'Bumps and Sags', 'name': '4_M', 'type': 'polygon'},
 {'Engin Name': 'Bumps and Sags', 'name': '4_H', 'type': 'polygon'},
 {'Engin Name': 'Depression', 'name': '6_L', 'type': 'polygon'},
 {'Engin Name': 'Depression', 'name': '6_M', 'type': 'polygon'},
 {'Engin Name': 'Depression', 'name': '6_H', 'type': 'polygon'},
 {'Engin Name': 'Depression', 'name': '6_L', 'type': 'polygon'},
 {'Engin Name': 'Depression', 'name': '6_M', 'type': 'polygon'},
 {'Engin Name': 'Depression', 'name': '6_H', 'type': 'polygon'},
 {'Engin Name': 'Rutting', 'name': '15_L', 'type': 'polygon'},
 {'Engin Name': 'Rutting', 'name': '15_M', 'type': 'polygon'},
 {'Engin Name': 'Rutting', 'name': '15_H', 'type': 'polygon'},
 {'Engin Name': 'Shoving', 'name': '16_L', 'type': 'polygon'},
 {'Engin Name': 'Shoving', 'name': '16_M', 'type': 'polygon'},
 {'Engin Name': 'Shoving', 'name': '16_H', 'type': 'polygon'},
 {'Engin Name': 'Swell', 'name': '18_L', 'type': 'polygon'},
 {'Engin Name': 'Swell', 'name': '18_M', 'type': 'polygon'},
 {'Engin Name': 'Swell', 'name': '18_H', 'type': 'polygon'},
 {'Engin Name': 'Potholes', 'name': '13_L', 'type': 'polygon'},
 {'Engin Name': 'Potholes', 'name': '13_M', 'type': 'polygon'},
 {'Engin Name': 'Potholes', 'name': '13_M', 'type': 'polygon'},
 {'Engin Name': 'Potholes', 'name': '13_L', 'type': 'polygon'},
 {'Engin Name': 'Potholes', 'name': '13_M', 'type': 'polygon'},
 {'Engin Name': 'Potholes', 'name': '13_H', 'type': 'polygon'},
 {'Engin Name': 'Patching and Utility Cut Patching',
  'name': '11_L',
  'type': 'polygon'},
 {'Engin Name': 'Patching and Utility Cut Patching',
  'name': '11_M',
  'type': 'polygon'},
 {'Engin Name': 'Patching and Utility Cut Patching',
  'name': '11_H',
  'type': 'polygon'},
 {'Engin Name': 'Weathering (Surface Wear) – Dense Mix Asphalt',
  'name': '20_L',
  'type': 'polygon'},
 {'Engin Name': 'Weathering (Surface Wear) – Dense Mix Asphalt',
  'name': '20_M',
  'type': 'polygon'},
 {'Engin Name': 'Weathering (Surface Wear) – Dense Mix Asphalt',
  'name': '20_H',
  'type': 'polygon'},
 {'Engin Name': 'Raveling', 'name': '19_M', 'type': 'polygon'},
 {'Engin Name': 'Raveling', 'name': '19_H', 'type': 'polygon'},
 {'Engin Name': 'Polished Aggregate', 'name': 12, 'type': 'polygon'},
 {'Engin Name': 'Polished Aggregate', 'name': 12, 'type': 'polygon'},
 {'Engin Name': 'Polished Aggregate', 'name': 12, 'type': 'polygon'},
 {'Engin Name': 'Weathering (Surface Wear) – Dense Mix Asphalt',
  'name': '20_H',
  'type': 'polygon'},
 {'Engin Name': 'Bleeding', 'name': '2_L', 'type': 'polygon'},
 {'Engin Name': 'Bleeding', 'name': '2_M', 'type': 'polygon'},
 {'Engin Name': 'Bleeding', 'name': '2_H', 'type': 'polygon'},
 {'Engin Name': 'Raveling', 'name': '19_H', 'type': 'polygon'},
 {'Engin Name': 'Lane/Shoulder Drop-Off', 'name': '9_L', 'type': 'polygon'},
 {'Engin Name': 'Lane/Shoulder Drop-Off', 'name': '9_M', 'type': 'polygon'},
 {'Engin Name': 'Lane/Shoulder Drop-Off', 'name': '9_H', 'type': 'polygon'},
 {'Engin Name': 'Edge Cracking', 'name': '7_L', 'type': 'polygon'},
 {'Engin Name': 'Edge Cracking', 'name': '7_M', 'type': 'polygon'},
 {'Engin Name': 'Edge Cracking', 'name': '7_H', 'type': 'polygon'},
 {'Engin Name': 'Edge Cracking', 'name': '7_M', 'type': 'polygon'},
 {'Engin Name': 'Edge Cracking', 'name': '7_M', 'type': 'polygon'},
 {'Engin Name': 'Edge Cracking', 'name': '7_H', 'type': 'polygon'},
 {'Engin Name': 'Railroad Crossing', 'name': '14_B', 'type': 'polygon'},
 {'Engin Name': 'Railroad Crossing', 'name': '14_M', 'type': 'polygon'},
 {'Engin Name': 'Railroad Crossing', 'name': '14_H', 'type': 'polygon'}];

 export const vizirAsfaltLabels = [{'Engin Name': 'Fisura Longitudinal por Fatiga',
  'name': 'FLF_1',
  'type': 'polyline'},
 {'Engin Name': 'Fisura Longitudinal por Fatiga',
  'name': 'FLF_2',
  'type': 'polyline'},
 {'Engin Name': 'Fisura Longitudinal por Fatiga',
  'name': 'FLF_3',
  'type': 'polyline'},
 {'Engin Name': 'Fisura Longitudinal por Fatiga',
  'name': 'FLF_1',
  'type': 'polygon'},
 {'Engin Name': 'Fisura Longitudinal por Fatiga',
  'name': 'FLF_2',
  'type': 'polygon'},
 {'Engin Name': 'Fisura Longitudinal por Fatiga',
  'name': 'FLF_2',
  'type': 'polygon'},
 {'Engin Name': 'Fisura Longitudinal por Fatiga',
  'name': 'FLF_3',
  'type': 'polygon'},
 {'Engin Name': 'Fisura Longitudinal por Fatiga',
  'name': 'FLF_2',
  'type': 'polygon'},
 {'Engin Name': 'Fisura de Contracción Térmica',
  'name': 'FCT_1',
  'type': 'polyline'},
 {'Engin Name': 'Fisura de Contracción Térmica',
  'name': 'FCT_2',
  'type': 'polyline'},
 {'Engin Name': 'Fisura de Contracción Térmica',
  'name': 'FCT_3',
  'type': 'polyline'},
 {'Engin Name': 'Fisura de Contracción Térmica',
  'name': 'FCT_1',
  'type': 'polygon'},
 {'Engin Name': 'Fisura Longitudinal de Junta de Construcción',
  'name': 'FLJ_1',
  'type': 'polyline'},
 {'Engin Name': 'Fisura Longitudinal de Junta de Construcción',
  'name': 'FLJ_2',
  'type': 'polyline'},
 {'Engin Name': 'Fisura Longitudinal de Junta de Construcción',
  'name': 'FLJ_3',
  'type': 'polyline'},
 {'Engin Name': 'Fisura Longitudinal de Junta de Construcción',
  'name': 'FLJ_1',
  'type': 'polygon'},
 {'Engin Name': 'Fisura Transversal de Junta de Construcción',
  'name': 'FTJ_1',
  'type': 'polyline'},
 {'Engin Name': 'Fisura Transversal de Junta de Construcción',
  'name': 'FTJ_2',
  'type': 'polyline'},
 {'Engin Name': 'Fisura Transversal de Junta de Construcción',
  'name': 'FTJ_3',
  'type': 'polyline'},
 {'Engin Name': 'Fisura Transversal de Junta de Construcción',
  'name': 'FTJ_1',
  'type': 'polygon'},
 {'Engin Name': 'Fisura Longitudinal de Junta de Construcción',
  'name': 'FLJ_1',
  'type': 'polyline'},
 {'Engin Name': 'Fisura Longitudinal de Junta de Construcción',
  'name': 'FLJ_2',
  'type': 'polyline'},
 {'Engin Name': 'Fisura Longitudinal de Junta de Construcción',
  'name': 'FLJ_3',
  'type': 'polyline'},
 {'Engin Name': 'Fisura Longitudinal de Junta de Construcción',
  'name': 'FLJ_1',
  'type': 'polygon'},
 {'Engin Name': 'Fisura Transversal de Junta de Construcción',
  'name': 'FTJ_1',
  'type': 'polyline'},
 {'Engin Name': 'Fisura Transversal de Junta de Construcción',
  'name': 'FTJ_2',
  'type': 'polyline'},
 {'Engin Name': 'Fisura Transversal de Junta de Construcción',
  'name': 'FTJ_3',
  'type': 'polyline'},
 {'Engin Name': 'Fisura Transversal de Junta de Construcción',
  'name': 'FTJ_1',
  'type': 'polygon'},
 {'Engin Name': 'Fisura Piel de Cocodrilo',
  'name': 'FPC_1',
  'type': 'polygon'},
 {'Engin Name': 'Fisura Piel de Cocodrilo',
  'name': 'FPC_2',
  'type': 'polygon'},
 {'Engin Name': 'Fisura Piel de Cocodrilo',
  'name': 'FPC_3',
  'type': 'polygon'},
 {'Engin Name': 'Fisuras de Borde', 'name': 'FB_1', 'type': 'polyline'},
 {'Engin Name': 'Fisuras de Borde', 'name': 'FB_1', 'type': 'polygon'},
 {'Engin Name': 'Fisuras de Borde', 'name': 'FB_2', 'type': 'polyline'},
 {'Engin Name': 'Fisuras de Borde', 'name': 'FB_3', 'type': 'polyline'},
 {'Engin Name': 'Fisura de Contracción Térmica',
  'name': 'FCT_1',
  'type': 'polygon'},
 {'Engin Name': 'Fisura de Contracción Térmica',
  'name': 'FCT_2',
  'type': 'polygon'},
 {'Engin Name': 'Fisura de Contracción Térmica',
  'name': 'FCT_3',
  'type': 'polygon'},
 {'Engin Name': 'Fisura Piel de Cocodrilo',
  'name': 'FPC_1',
  'type': 'polygon'},
 {'Engin Name': 'Fisura Piel de Cocodrilo',
  'name': 'FPC_2',
  'type': 'polygon'},
 {'Engin Name': 'Fisura Piel de Cocodrilo',
  'name': 'FPC_3',
  'type': 'polygon'},
 {'Engin Name': 'Fisuras Parabólicas', 'name': 'FP_1', 'type': 'polygon'},
 {'Engin Name': 'Fisuras Parabólicas', 'name': 'FP_2', 'type': 'polygon'},
 {'Engin Name': 'Fisuras Parabólicas', 'name': 'FP_3', 'type': 'polygon'},
 {'Engin Name': 'Desplazamiento o Abultamiento o Ahuellamiento de la Mezcla',
  'name': 'DM_1',
  'type': 'polygon'},
 {'Engin Name': 'Desplazamiento o Abultamiento o Ahuellamiento de la Mezcla',
  'name': 'DM_2',
  'type': 'polygon'},
 {'Engin Name': 'Desplazamiento o Abultamiento o Ahuellamiento de la Mezcla',
  'name': 'DM_3',
  'type': 'polygon'},
 {'Engin Name': 'Desplazamiento o Abultamiento o Ahuellamiento de la Mezcla',
  'name': 'DM_1',
  'type': 'polygon'},
 {'Engin Name': 'Desplazamiento o Abultamiento o Ahuellamiento de la Mezcla',
  'name': 'DM_2',
  'type': 'polygon'},
 {'Engin Name': 'Desplazamiento o Abultamiento o Ahuellamiento de la Mezcla',
  'name': 'DM_3',
  'type': 'polygon'},
 {'Engin Name': 'Depresiones o hundimientos Longitudinales',
  'name': 'DL_1',
  'type': 'polygon'},
 {'Engin Name': 'Depresiones o hundimientos Longitudinales',
  'name': 'DL_2',
  'type': 'polygon'},
 {'Engin Name': 'Depresiones o hundimientos Longitudinales',
  'name': 'DL_3',
  'type': 'polygon'},
 {'Engin Name': 'Depresiones o hundimientos Transversales',
  'name': 'DT_1',
  'type': 'polygon'},
 {'Engin Name': 'Depresiones o hundimientos Transversales',
  'name': 'DT_2',
  'type': 'polygon'},
 {'Engin Name': 'Depresiones o hundimientos Transversales',
  'name': 'DT_3',
  'type': 'polygon'},
 {'Engin Name': 'Ahuellamiento', 'name': 'AH_1', 'type': 'polygon'},
 {'Engin Name': 'Ahuellamiento', 'name': 'AH_2', 'type': 'polygon'},
 {'Engin Name': 'Ahuellamiento', 'name': 'AH_3', 'type': 'polygon'},
 {'Engin Name': 'Desplazamiento o Abultamiento o Ahuellamiento de la Mezcla',
  'name': 'DM_1',
  'type': 'polygon'},
 {'Engin Name': 'Desplazamiento o Abultamiento o Ahuellamiento de la Mezcla',
  'name': 'DM_2',
  'type': 'polygon'},
 {'Engin Name': 'Desplazamiento o Abultamiento o Ahuellamiento de la Mezcla',
  'name': 'DM_3',
  'type': 'polygon'},
 {'Engin Name': 'Desplazamiento o Abultamiento o Ahuellamiento de la Mezcla',
  'name': 'DM_1',
  'type': 'polygon'},
 {'Engin Name': 'Desplazamiento o Abultamiento o Ahuellamiento de la Mezcla',
  'name': 'DM_2',
  'type': 'polygon'},
 {'Engin Name': 'Desplazamiento o Abultamiento o Ahuellamiento de la Mezcla',
  'name': 'DM_3',
  'type': 'polygon'},
 {'Engin Name': 'Descascaramiento', 'name': 'D_1', 'type': 'polygon'},
 {'Engin Name': 'Descascaramiento', 'name': 'D_2', 'type': 'polygon'},
 {'Engin Name': 'Descascaramiento', 'name': 'D_3', 'type': 'polygon'},
 {'Engin Name': 'Ojos de Pescado', 'name': 'O_1', 'type': 'polygon'},
 {'Engin Name': 'Ojos de Pescado', 'name': 'O_2', 'type': 'polygon'},
 {'Engin Name': 'Ojos de Pescado', 'name': 'O_3', 'type': 'polygon'},
 {'Engin Name': 'Bacheos y Parcheos', 'name': 'B_1', 'type': 'polygon'},
 {'Engin Name': 'Bacheos y Parcheos', 'name': 'B_2', 'type': 'polygon'},
 {'Engin Name': 'Bacheos y Parcheos', 'name': 'B_3', 'type': 'polygon'},
 {'Engin Name': 'Pérdida de Ligante', 'name': 'PL_1', 'type': 'polygon'},
 {'Engin Name': 'Pérdida de Ligante', 'name': 'PL_2', 'type': 'polygon'},
 {'Engin Name': 'Pérdida de Ligante', 'name': 'PL_3', 'type': 'polygon'},
 {'Engin Name': 'Pérdida de Agregados', 'name': 'PA_1', 'type': 'polygon'},
 {'Engin Name': 'Pérdida de Agregados', 'name': 'PA_2', 'type': 'polygon'},
 {'Engin Name': 'Pérdida de Agregados', 'name': 'PA_3', 'type': 'polygon'},
 {'Engin Name': 'Pulimiento de Agregados', 'name': 'PU_1', 'type': 'polygon'},
 {'Engin Name': 'Pulimiento de Agregados', 'name': 'PU_2', 'type': 'polygon'},
 {'Engin Name': 'Pulimiento de Agregados', 'name': 'PU_3', 'type': 'polygon'},
 {'Engin Name': 'Pérdida de Ligante', 'name': 'PL_3', 'type': 'polygon'},
 {'Engin Name': 'Exudación', 'name': 'EX_1', 'type': 'polygon'},
 {'Engin Name': 'Exudación', 'name': 'EX_2', 'type': 'polygon'},
 {'Engin Name': 'Exudación', 'name': 'EX_3', 'type': 'polygon'},
 {'Engin Name': 'Pérdida de Agregados', 'name': 'PA_3', 'type': 'polygon'},
 {'Engin Name': 'Afloramiento de Mortero', 'name': 'AM_1', 'type': 'polygon'},
 {'Engin Name': 'Afloramiento de Mortero', 'name': 'AM_2', 'type': 'polygon'},
 {'Engin Name': 'Afloramiento de Mortero', 'name': 'AM_3', 'type': 'polygon'},
 {'Engin Name': 'Afloramiento de Agua', 'name': 'AA_1', 'type': 'polygon'},
 {'Engin Name': 'Afloramiento de Agua', 'name': 'AA_2', 'type': 'polygon'},
 {'Engin Name': 'Afloramiento de Agua', 'name': 'AA_3', 'type': 'polygon'},
 {'Engin Name': 'Escalonamiento entre Calzada y Berma',
  'name': 'ECB_1',
  'type': 'polygon'},
 {'Engin Name': 'Escalonamiento entre Calzada y Berma',
  'name': 'ECB_2',
  'type': 'polygon'},
 {'Engin Name': 'Escalonamiento entre Calzada y Berma',
  'name': 'ECB_3',
  'type': 'polygon'},
 {'Engin Name': 'Fisuras de Borde', 'name': 'FB_3', 'type': 'polygon'},
 {'Engin Name': 'Fisuras de Borde', 'name': 'FB_3', 'type': 'polygon'},
 {'Engin Name': 'Fisuras de Borde', 'name': 'FB_3', 'type': 'polygon'},
 {'Engin Name': 'Desintegración de los Bordes del Pavimento',
  'name': 'DB_1',
  'type': 'polygon'},
 {'Engin Name': 'Desintegración de los Bordes del Pavimento',
  'name': 'DB_2',
  'type': 'polygon'},
 {'Engin Name': 'Desintegración de los Bordes del Pavimento',
  'name': 'DB_3',
  'type': 'polygon'},
 {'Engin Name': 'Erosión de las bermas', 'name': 'EB_1', 'type': 'polygon'},
 {'Engin Name': 'Erosión de las bermas', 'name': 'EB_2', 'type': 'polygon'},
 {'Engin Name': 'Erosión de las bermas', 'name': 'EB_3', 'type': 'polygon'},
 {'Engin Name': 'Segregación', 'name': 'S_1', 'type': 'polygon'},
 {'Engin Name': 'Segregación', 'name': 'S_2', 'type': 'polygon'},
 {'Engin Name': 'Segregación', 'name': 'S_3', 'type': 'polygon'}];

 export const inviasLabels = [{'Engin Name': 'Fisura Longitudinal por Fatiga',
  'name': 'FL_B',
  'type': 'polyline'},
 {'Engin Name': 'Fisura Longitudinal por Fatiga',
  'name': 'FL_M',
  'type': 'polyline'},
 {'Engin Name': 'Fisura Longitudinal por Fatiga',
  'name': 'FL_A',
  'type': 'polyline'},
 {'Engin Name': 'Fisura Longitudinal por Fatiga Sellada',
  'name': 'FL_B',
  'type': 'polygon'},
 {'Engin Name': 'Fisuras Ramificadas', 'name': 'FL_M', 'type': 'polygon'},
 {'Engin Name': 'Fisuras Ramificadas', 'name': 'FL_M', 'type': 'polygon'},
 {'Engin Name': 'Fisuras Ramificadas', 'name': 'FL_A', 'type': 'polygon'},
 {'Engin Name': 'Fisuras Ramificadas Selladas',
  'name': 'FL_M',
  'type': 'polygon'},
 {'Engin Name': 'Fisuras Transversales', 'name': 'FT_B', 'type': 'polyline'},
 {'Engin Name': 'Fisuras Transversales', 'name': 'FT_M', 'type': 'polyline'},
 {'Engin Name': 'Fisuras Transversales', 'name': 'FT_A', 'type': 'polyline'},
 {'Engin Name': 'Fisuras Transversales Selladas',
  'name': 'FT_B',
  'type': 'polygon'},
 {'Engin Name': 'Fisura de Junta de Construcción Longitudinal',
  'name': 'FCL_B',
  'type': 'polyline'},
 {'Engin Name': 'Fisura de Junta de Construcción Longitudinal',
  'name': 'FCL_M',
  'type': 'polyline'},
 {'Engin Name': 'Fisura de Junta de Construcción Longitudinal',
  'name': 'FCL_A',
  'type': 'polyline'},
 {'Engin Name': 'Fisura de Junta de Construcción Longitudinal S.',
  'name': 'FCL_B',
  'type': 'polygon'},
 {'Engin Name': 'Fisura de Junta de Construcción Transversal',
  'name': 'FCT_B',
  'type': 'polyline'},
 {'Engin Name': 'Fisura de Junta de Construcción Transversal',
  'name': 'FCT_M',
  'type': 'polyline'},
 {'Engin Name': 'Fisura de Junta de Construcción Transversal',
  'name': 'FCT_A',
  'type': 'polyline'},
 {'Engin Name': 'Fisura de Junta de Construcción Transversal S.',
  'name': 'FCT_B',
  'type': 'polygon'},
 {'Engin Name': 'Reflexión de Juntas Rígidas Longitudinal',
  'name': 'FJL_B',
  'type': 'polyline'},
 {'Engin Name': 'Reflexión de Juntas Rígidas Longitudinal',
  'name': 'FJL_M',
  'type': 'polyline'},
 {'Engin Name': 'Reflexión de Juntas Rígidas Longitudinal',
  'name': 'FJL_A',
  'type': 'polyline'},
 {'Engin Name': 'Reflexión de Juntas Rígidas Longitudinal S.',
  'name': 'FJL_B',
  'type': 'polygon'},
 {'Engin Name': 'Reflexión de Juntas Rígidas Transversal',
  'name': 'FJT_B',
  'type': 'polyline'},
 {'Engin Name': 'Reflexión de Juntas Rígidas Transversal',
  'name': 'FJT_M',
  'type': 'polyline'},
 {'Engin Name': 'Reflexión de Juntas Rígidas Transversal',
  'name': 'FJT_A',
  'type': 'polyline'},
 {'Engin Name': 'Reflexión de Juntas Rígidas Transversal S.',
  'name': 'FJT_B',
  'type': 'polygon'},
 {'Engin Name': 'Fisura Media Luna', 'name': 'FML_B', 'type': 'polygon'},
 {'Engin Name': 'Fisura Media Luna', 'name': 'FML_M', 'type': 'polygon'},
 {'Engin Name': 'Fisura Media Luna', 'name': 'FML_A', 'type': 'polygon'},
 {'Engin Name': 'Fisura de Borde', 'name': 'FBD_B', 'type': 'polyline'},
 {'Engin Name': 'Fisura de Borde', 'name': 'FBD_B', 'type': 'polygon'},
 {'Engin Name': 'Fisura de Borde', 'name': 'FBD_M', 'type': 'polyline'},
 {'Engin Name': 'Fisura de Borde', 'name': 'FBD_A', 'type': 'polyline'},
 {'Engin Name': 'Fisura en Bloque', 'name': 'FB_B', 'type': 'polygon'},
 {'Engin Name': 'Fisura en Bloque', 'name': 'FB_M', 'type': 'polygon'},
 {'Engin Name': 'Fisura en Bloque', 'name': 'FB_A', 'type': 'polygon'},
 {'Engin Name': 'Piel de Cocodrilo', 'name': 'PC_B', 'type': 'polygon'},
 {'Engin Name': 'Piel de Cocodrilo', 'name': 'PC_M', 'type': 'polygon'},
 {'Engin Name': 'Piel de Cocodrilo', 'name': 'PC_A', 'type': 'polygon'},
 {'Engin Name': 'Deslizamiento de Capas', 'name': 'FDC_B', 'type': 'polygon'},
 {'Engin Name': 'Deslizamiento de Capas', 'name': 'FDC_M', 'type': 'polygon'},
 {'Engin Name': 'Deslizamiento de Capas', 'name': 'FDC_A', 'type': 'polygon'},
 {'Engin Name': 'Fisura Incipiente', 'name': 'FIN', 'type': 'polygon'},
 {'Engin Name': 'Ondulación', 'name': 'OND_B', 'type': 'polygon'},
 {'Engin Name': 'Ondulación', 'name': 'OND_M', 'type': 'polygon'},
 {'Engin Name': 'Ondulación', 'name': 'OND_A', 'type': 'polygon'},
 {'Engin Name': 'Abultamiento', 'name': 'AB_B', 'type': 'polygon'},
 {'Engin Name': 'Abultamiento', 'name': 'AB_M', 'type': 'polygon'},
 {'Engin Name': 'Abultamiento', 'name': 'AB_A', 'type': 'polygon'},
 {'Engin Name': 'Hundimiento', 'name': 'HUN_B', 'type': 'polygon'},
 {'Engin Name': 'Hundimiento', 'name': 'HUN_M', 'type': 'polygon'},
 {'Engin Name': 'Hundimiento', 'name': 'HUN_A', 'type': 'polygon'},
 {'Engin Name': 'Hundimiento', 'name': 'HUN_B', 'type': 'polygon'},
 {'Engin Name': 'Hundimiento', 'name': 'HUN_M', 'type': 'polygon'},
 {'Engin Name': 'Hundimiento', 'name': 'HUN_A', 'type': 'polygon'},
 {'Engin Name': 'Ahuellamiento', 'name': 'AHU_B', 'type': 'polygon'},
 {'Engin Name': 'Ahuellamiento', 'name': 'AHU_M', 'type': 'polygon'},
 {'Engin Name': 'Ahuellamiento', 'name': 'AHU_A', 'type': 'polygon'},
 {'Engin Name': 'Empuje de Mezcla', 'name': 'AB_B', 'type': 'polygon'},
 {'Engin Name': 'Empuje de Mezcla', 'name': 'AB_M', 'type': 'polygon'},
 {'Engin Name': 'Empuje de Mezcla', 'name': 'AB_A', 'type': 'polygon'},
 {'Engin Name': 'Inchamiento', 'name': 'AB_B', 'type': 'polygon'},
 {'Engin Name': 'Inchamiento', 'name': 'AB_M', 'type': 'polygon'},
 {'Engin Name': 'Inchamiento', 'name': 'AB_A', 'type': 'polygon'},
 {'Engin Name': 'Descascaramiento', 'name': 'DC_B', 'type': 'polygon'},
 {'Engin Name': 'Descascaramiento', 'name': 'DC_M', 'type': 'polygon'},
 {'Engin Name': 'Descascaramiento', 'name': 'DC_A', 'type': 'polygon'},
 {'Engin Name': 'Bache', 'name': 'BCH_B', 'type': 'polygon'},
 {'Engin Name': 'Bache', 'name': 'BCH_M', 'type': 'polygon'},
 {'Engin Name': 'Bache', 'name': 'BCH_A', 'type': 'polygon'},
 {'Engin Name': 'Parche', 'name': 'PCH_B', 'type': 'polygon'},
 {'Engin Name': 'Parche', 'name': 'PCH_M', 'type': 'polygon'},
 {'Engin Name': 'Parche', 'name': 'PCH_A', 'type': 'polygon'},
 {'Engin Name': 'Desgaste Superficial', 'name': 'DSU_B', 'type': 'polygon'},
 {'Engin Name': 'Desgaste Superficial', 'name': 'DSU_M', 'type': 'polygon'},
 {'Engin Name': 'Desgaste Superficial', 'name': 'DSU_A', 'type': 'polygon'},
 {'Engin Name': 'Pérdida de Agregado', 'name': 'PA_B', 'type': 'polygon'},
 {'Engin Name': 'Pérdida de Agregado', 'name': 'PA_M', 'type': 'polygon'},
 {'Engin Name': 'Pérdida de Agregado', 'name': 'PA_A', 'type': 'polygon'},
 {'Engin Name': 'Pulimiento de Agregado', 'name': 'PU_B', 'type': 'polygon'},
 {'Engin Name': 'Pulimiento de Agregado', 'name': 'PU_M', 'type': 'polygon'},
 {'Engin Name': 'Pulimiento de Agregado', 'name': 'PU_A', 'type': 'polygon'},
 {'Engin Name': 'Cabezas Duras', 'name': 'CD', 'type': 'polygon'},
 {'Engin Name': 'Exudación', 'name': 'EX_B', 'type': 'polygon'},
 {'Engin Name': 'Exudación', 'name': 'EX_M', 'type': 'polygon'},
 {'Engin Name': 'Exudación', 'name': 'EX_A', 'type': 'polygon'},
 {'Engin Name': 'Surcos', 'name': 'SU', 'type': 'polygon'},
 {'Engin Name': 'Afloramiento de Finos', 'name': 'AFI_B', 'type': 'polygon'},
 {'Engin Name': 'Afloramiento de Finos', 'name': 'AFI_M', 'type': 'polygon'},
 {'Engin Name': 'Afloramiento de Finos', 'name': 'AFI_A', 'type': 'polygon'},
 {'Engin Name': 'Afloramiento de Agua', 'name': 'AFA_B', 'type': 'polygon'},
 {'Engin Name': 'Afloramiento de Agua', 'name': 'AFA_M', 'type': 'polygon'},
 {'Engin Name': 'Afloramiento de Agua', 'name': 'AFA_A', 'type': 'polygon'},
 {'Engin Name': 'Escalonamiento Calzada-Berma',
  'name': 'CV_B',
  'type': 'polygon'},
 {'Engin Name': 'Escalonamiento Calzada-Berma',
  'name': 'CV_M',
  'type': 'polygon'},
 {'Engin Name': 'Escalonamiento Calzada-Berma',
  'name': 'CV_A',
  'type': 'polygon'},
 {'Engin Name': 'Separación de la Berma', 'name': 'SB_B', 'type': 'polygon'},
 {'Engin Name': 'Separación de la Berma', 'name': 'SB_M', 'type': 'polygon'},
 {'Engin Name': 'Separación de la Berma', 'name': 'SB_A', 'type': 'polygon'},
 {'Engin Name': 'Desintegración de Bordes', 'name': 'DB_B', 'type': 'polygon'},
 {'Engin Name': 'Desintegración de Bordes', 'name': 'DB_M', 'type': 'polygon'},
 {'Engin Name': 'Desintegración de Bordes', 'name': 'DB_A', 'type': 'polygon'}];

// console.log("defaultLabels",defaultLabels);
// const distressIdx = _.findIndex(defaultLabels,{name:"DC_2"});
// console.log("foundDistress",defaultLabels[distressIdx]);
// console.log("distressIdx",distressIdx);
// console.log("foundColor", fullColorList[distressIdx]);
// console.log("fullColorList",fullColorList);

// const findDuplicates = (arr, checkBy) => {
//   let sorted_arr =  _.sortBy(arr.slice(), [function(o) { return o.name; }]); // You can define the comparing function here. 
//   // JS by default uses a crappy string compare.
//   // (we use slice to clone the array so the
//   // original array won't be modified)
//   console.log(sorted_arr);
//   let results = [];
//   for (let i = 0; i < sorted_arr.length - 1; i++) {
//     if (sorted_arr[i + 1][checkBy] == sorted_arr[i][checkBy]) {
//       results.push(sorted_arr[i][checkBy]);
//     }
//   }
//   return results;
// }

// const duplicateValues = findDuplicates(fullColorList,"name");
// console.log("duplicateValues",duplicateValues);
let orderedLabels = _.sortBy(defaultLabels, [function(o) { return o.name; }])

export {orderedLabels};


