import React, {Fragment} from "react";
import {Grid, Container, Button, Divider, Image, Segment} from 'semantic-ui-react';
import '../App.css';
import Dropzone from 'react-dropzone';
import axios from "axios";

class Demo extends React.Component {
constructor(props){
		super(props);
		this.state = {
      acceptedFiles:[],
      images:[],
      arrayBuffers:[],
      loading:false,
      detection:null,
      type:null,
      confidence:null,
      length:null,
      width:null,
      imgSize:null
    };
    this.handleDrop = this.handleDrop.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
	}

  handleDrop(acceptedFiles){
     this.setState({
      acceptedFiles:acceptedFiles
     });
    
  }

  handleSubmit(evt){  
    evt.preventDefault();
    this.setState({
      loading:true
    });
    
    // var imagefile = this.state.arrayBuffers;
    this.state.acceptedFiles.forEach((file)=>{
      var formData = new FormData();
    formData.append("image", file);
    axios.post(`${process.env.REACT_APP_PRODUCTION_SERVER_URL}/api/images/uploads`,formData,{
      headers:{
        'Content-Type': 'multipart/form-data'
      }
    }).then((result)=>{
      console.log(result);
        if(result.data.data.image && result.data.data.detection_img_url){
          this.setState({
            images:[...this.state.images, result.data.data.image],
            detection:result.data.data.detection_img_url,
            confidence:result.data.data.damage.confidence,
            length:result.data.data.damage.length,
            width:result.data.data.damage.name,
            imgSize:result.data.data.imgsize
          });
        }
        if(result.data.data.image){
          this.setState({
            images:[...this.state.images, result.data.data.image]
          });
        }
      })
      .catch((err)=>{
        console.log(err);
      }).finally(()=>{
        this.setState({
          loading:false
        });
      });
    });


  }

  render() {
    let backgroundImg = `linear-gradient(45deg, rgb(229, 44, 78) 1%, rgb(233, 74, 99) 30%, rgb(174, 82, 157) 69%, rgb(154, 85, 177) 100%)`;
    const browserLang = this.props.browserLang || "en";
    console.log(process.env.REACT_APP_PRODUCTION_SERVER_URL);
    let fileNames = this.state.acceptedFiles.map((file)=>{
      if("name" in file){
        return (<p>{file.name}</p>)
      }else if("path" in file){
        return (<p>{file.path}</p>)
      }else{
        return (<p>{"no-name"}</p>)
      }
      
    });
    let images = this.state.images.map((imgUrl)=>{
      return (<Image src={imgUrl} size="medium"></Image>)
    });
    return (
      <Fragment>
        <header className="App-hero" style={{
          "minHeight":"75px",
          "backgroundImage":backgroundImg,
          "color":"white"
        }}>
        </header>
        <Container style={{"paddingTop":"16px"}}>
          <Grid>
            <Grid.Row columns={2}>
                <Grid.Column style={{"textAlign":"left"}}>
                  <Grid.Row>
                    <h1>{browserLang === "en" ? "Select Photo":"Seleccionar Foto"}</h1>
                    </Grid.Row>
                    <Grid.Row>
                      <Dropzone  onDrop={acceptedFiles => this.handleDrop(acceptedFiles)}>
                      {({getRootProps, getInputProps}) => (
                        <section>
                          <div className="dropzone" {...getRootProps()}>
                            <input {...getInputProps()} />
                            <p>{browserLang === "en" ? "Drag 'n' drop some files here, or click to select files":"Arrastra los archivos aquí, o haz click para seleccionar archivo"}</p>
                          </div>
                        </section>
                      )}
                </Dropzone>
                    </Grid.Row>
                    <Grid.Row>
                      <h1>{browserLang === "en" ? "Selected Files" : "Archivos seleccionados"}</h1>
                    </Grid.Row>
                     <Grid.Row>
                      <Segment>
                        {fileNames}  
                      </Segment>
                    </Grid.Row>
                    <Divider></Divider>
                    <Grid.Row>
                      <Button loading={this.state.loading} color="red" disabled={this.state.acceptedFiles.length < 1} onClick={this.handleSubmit} >
                        {browserLang === "en" ? "Submit" : "Enviar"}
                      </Button>
                    </Grid.Row>
                </Grid.Column> 
                <Grid.Column>
                  {images}
                </Grid.Column>  
            </Grid.Row>
          
        </Grid>  
        </Container>
          
      </Fragment>
      
     
    );
  }
}

export default Demo;