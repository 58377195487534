import React, {useState, useEffect}  from 'react';
import { Icon, Label, Grid, Card } from 'semantic-ui-react';
import moment from "moment";

const ProjectCard = ({project, getImageCounts, getVideoCounts, getStatusTextAndIcons, language, linkPrefix, imgCountsPerProject,userRole})=>{
	const [imagesCount, setImagesCount] = useState(0);
	const [labelsCount, setLabelsCount] = useState(0);
	useEffect(()=>{
		if(project?.projectLabelingDataFormat === 'video'){
			getVideoCounts(project._id);
		}else{
			getImageCounts(project._id);
		}
	},[]);

	useEffect(()=>{
		if(project){
			const countsObj = imgCountsPerProject.filter((countObj)=>{
				return countObj.projectId === project._id;
			})[0];
			if(countsObj){
				setImagesCount(countsObj.imagesCount);
				setLabelsCount(countsObj.labelsCount);
			}
		}
	},[imgCountsPerProject]);

	const { name, form,status } = project;
	let statusObj = {};
	if(getStatusTextAndIcons){
		statusObj = getStatusTextAndIcons(status, language);
	}
	const id = project._id;
	let createdBy = "createdBy" in project? `Created by: ${project?.createdBy?.f_name} ${project?.createdBy?.l_name}.` : "";
	if(language === "es"){
	  createdBy = "createdBy" in project? `Creado por: ${project?.createdBy?.f_name} ${project?.createdBy?.l_name}.` : "";
	}
	let info = ""; 
	let desc = "";
	if(userRole === "admin" || userRole === "labeler_lead"){
		desc = <div>
	              <div style={{padding:"5px"}}>
	                <Icon name="user circle"/> {language === "es" ?  "Etiquetador Asignado:" : "Assigned Labeler:"} {"assignedLabeler" in project ? `${project.assignedLabeler.f_name} ${project.assignedLabeler.l_name}` : ""}
	              </div>
	              <Label style={{backgroundColor:statusObj.color, color:"white", padding:"5px"}}>
	                {/*language === "es" ?  "Estado:" : "Status:"*/} {statusObj.text}
	              </Label> 
	            </div>;
	    if(project?.projectLabelingDataFormat === 'video'){
			info = `${createdBy} ${imagesCount} ${language === "es" ? "videos": "videos"}, ${labelsCount} ${language === "es" ? "etiquetados": "labeled"}`;	    	
	    }else{
	    	info = `${createdBy} ${imagesCount} ${language === "es" ? "imágenes": "images"}, ${labelsCount} ${language === "es" ? "etiquetadas": "labeled"}`;
	    }
	}else{
	    if(project?.projectLabelingDataFormat === 'video'){
			info = `${imagesCount} ${language === "es" ? "videos": "videos"}, ${labelsCount} ${language === "es" ? "revisados": "rated"}`;
	    }else{
			info = `${imagesCount} ${language === "es" ? "imágenes": "images"}, ${labelsCount} ${language === "es" ? "revisadas": "rated"}`;
	    }
		desc = <div>
	              <Label style={{backgroundColor:statusObj.color, color:"white", padding:"5px"}}>
	                {/*language === "es" ?  "Estado:" : "Status:"*/} {statusObj.text}
	              </Label> 
	            </div>;
	}

	return (
		<>		
			<Grid.Column key={id}>
				<a href={project?.projectLabelingDataFormat === 'video' && linkPrefix.indexOf("/label") >=0 ?`/#/label/projects/${id}/videos` : `/#${linkPrefix}`}>
					<Card fluid link header={name} meta={info} description={desc} className="projectCard" />
				</a>
			</Grid.Column>
		</>

	);
}; 

export default ProjectCard;