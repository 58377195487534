import React, { useState } from 'react';
import {
	Button,
	Dropdown,
	Icon,
	Image,
	Menu,
	Popup,
	Responsive,
	Sidebar
} from 'semantic-ui-react';
import { connect } from "react-redux";
import { useHistory, Link } from 'react-router-dom';
import Logo from '../../images/global-images/identity/Logo.svg';
import Bell from '../../images/global-images/menus/bell-icon.svg';
import Help from '../../images/global-images/menus/help-icon.svg';
import { logout } from "../../store/actions/auth";
import Analize from '../../images/analize.svg';

const GlobalTopBar = ({ currentUser, logout }) => {
	const [sidebarVisible, setSidebarVisible] = useState(false);
	const history = useHistory();

	const handleToggleSidebar = () => {
		setSidebarVisible(!sidebarVisible);
	};

	const profileImageUrl = currentUser && currentUser.isAuthenticated ? currentUser.user.profileImageUrl : null;
	const userInitial = currentUser && currentUser.isAuthenticated ? currentUser.user.f_name.charAt(0).toUpperCase() : '';

	const handleMenuClick = (name) => {
		if (name === 'logout') {
			logout();
			history.push('/');
		} else if (name === 'login') {
			history.push('/signin');
		} else if (name === 'pavementAnalysis') {
			history.push('/pavements-analysis');
		} else if (name === 'dataLabeling') {
			history.push('/data-labeling');
		}else if(name === 'userSettings'){
			history.push('/user/settings');
		}
	};

	return (
		<>
			<Responsive as={Menu} borderless minWidth={Responsive.onlyTablet.minWidth}
				style={{
					marginBottom: '20px',
					position: 'sticky',
					top: 0,
					zIndex: 1000,
				}}
			>
				<Menu.Item header>
					<a href={currentUser && currentUser.isAuthenticated ? "/#/admin" : "/#"}>
						<Image size="small" src={Logo} style={{ marginLeft: '1em' }} alt="engin.ai logo" />
					</a>
				</Menu.Item>
				{currentUser && !currentUser.isAuthenticated && (
					<Menu.Menu>
						<Menu.Item name='Data Labeling' onClick={() => handleMenuClick('dataLabeling')} />
						<Dropdown item text='Industry-Specific Solutions'>
							<Dropdown.Menu>
								<Dropdown.Item onClick={() => handleMenuClick('pavementAnalysis')}>Pavement Damage Analysis</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
					</Menu.Menu>
				)}
				<Menu.Menu position='right'>
					{currentUser && currentUser.isAuthenticated && (
						<>
							<Menu.Item>
								<Link to="/" style={{ textDecoration: 'none' }}>
									<button style={{
										backgroundImage: 'linear-gradient(140.41deg, #8255FD 0.86%, #479CEE 34.01%, #27CFB9 67.15%, #22E895 100.29%)',
										color: 'white',
										border: 'none',
										padding: '10px 20px',
										borderRadius: '8px',
										cursor: 'pointer',
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center'
									}}>
										<img src={Analize} alt="Analyze" style={{ marginRight: '8px', height: '20px' }} />
										Upgrade
									</button>
								</Link>
							</Menu.Item>

							<Menu.Item as={Link} to="/#">
								<Image src={Help} alt="Help Icon" />
							</Menu.Item>
							<Menu.Item as={Link} to="#">
								<Image src={Bell} alt="Bell Icon" />
							</Menu.Item>
						</>
					)}
					<Menu.Item>
						{!currentUser || !currentUser.isAuthenticated ? (
							<span style={{ color: '#6622C0', cursor: 'pointer' }} onClick={() => handleMenuClick('login')}><strong>Login</strong></span>
						) : (
							<Popup
								trigger={
									profileImageUrl ? (
										<Image
											src={profileImageUrl}
											alt="Profile"
											avatar
											size="mini"
											style={{
												borderRadius: '50%',
												border: '2px solid #6622C0',
												objectFit: 'cover',
												cursor:'pointer'
											}}
										/>
									) : (
										<div
											style={{
												width: '32px',
												height: '32px',
												borderRadius: '50%',
												backgroundColor: '#6622C0',
												color: 'white',
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'center',
												fontSize: '16px',
												fontWeight: 'bold'
											}}
										>
											{userInitial}
										</div>
									)
								}
								content={
									currentUser && currentUser.isAuthenticated ? (
										<div>
											<p><strong>{currentUser.user.f_name} {currentUser.user.l_name}</strong></p>
											<p><strong>Email:</strong> {currentUser.user.email}</p>
											<p><strong>Company:</strong> {currentUser.user.company}</p>
											<p style={{color: '#43a2e7',cursor:'pointer'}} onClick={()=> handleMenuClick('userSettings')}>Change user setttings</p>
											<div style={{ textAlign: 'center' }}>
												<Button
													style={{ backgroundColor: '#6622C0', color: 'white' }}
													onClick={() => handleMenuClick('logout')}
												>
													Logout
												</Button>
											</div>
										</div>
									) : null
								}
								on='click'
								position='bottom right'
							/>
						)}
					</Menu.Item>
				</Menu.Menu>
			</Responsive>

			<Responsive as={Menu} borderless maxWidth={Responsive.onlyMobile.maxWidth}>
				<Menu.Item header>
					<Image size="small" src={Logo} style={{ marginLeft: '1em' }} alt="engin.ai logo" />
				</Menu.Item>
				<Menu.Menu position='right'>
					<Menu.Item onClick={handleToggleSidebar}>
						<Icon name='bars' size='large' />
					</Menu.Item>
				</Menu.Menu>
			</Responsive>

			<Sidebar
				as={Menu}
				animation='overlay'
				icon='labeled'
				inverted
				onHide={() => setSidebarVisible(false)}
				vertical
				visible={sidebarVisible}
				width='thin'
			>
				<Menu.Item name='Data Labeling' onClick={() => handleMenuClick('dataLabeling')} />
				<Dropdown item text='Industry-Specific Solutions'>
					<Dropdown.Menu>
						<Dropdown.Item onClick={() => handleMenuClick('pavementAnalysis')}>Pavement Damage Analysis</Dropdown.Item>
					</Dropdown.Menu>
				</Dropdown>
			</Sidebar>
		</>
	);
}

const mapStateToProps = (state) => ({
	currentUser: state.currentUser,
});

export default connect(mapStateToProps, { logout })(GlobalTopBar);
