import React, {Component, Fragment} from "react";
import moment from 'moment';
import { Table, Message, Button, Icon, Dropdown, Modal, Form } from 'semantic-ui-react';
import {apiCall} from '../services/api';
import { connect } from "react-redux";
import _ from 'lodash';

class DataTable extends Component {
  constructor(props){
    super(props);
    this.state = {
      data: null,
      error:"",
      column: null,
      direction: null,
      modalOn: false,
      editing:false,
      deleting: false,
      editableData: null,
      creating: false,
      createData:{}
    };
    this.onEdit = this.onEdit.bind(this);
  }

  componentDidMount(){
    if(this.props.hasOwnProperty("requestPath") && this.props.requestPath !== ""){
      let {requestPath} = this.props;
    apiCall("get",requestPath)
      .then((data)=>{
        this.setState({data});
     })
      .catch(err=>{
        this.setState({error: err});
      });
   
    }
  }
   handleSort = clickedColumn => () => {
    const { column, data, direction } = this.state

    if (column !== clickedColumn) {
      this.setState({
        column: clickedColumn,
        data: _.sortBy(data, [clickedColumn]),
        direction: 'ascending',
      })

      return
    }

    this.setState({
      data: data.reverse(),
      direction: direction === 'ascending' ? 'descending' : 'ascending',
    })
  }
//EDIT TABLE DATA
  onEdit(val, event){
    // console.log(event);
    // console.log(val);
    let editableData = {...val};
    this.setState({modalOn: true, editing: true, editableData});
  }
   close = () => {
    this.setState({ modalOn: false, editableData: null, creating: false, editing:false, createData:null })
   }
    

   handleChangeEditForm = (e, { name = "", value }) =>{
    let {editableData}= this.state;
    editableData[name] = value;
    this.setState({ editableData });
   } 

   handleEditFormSubmit = () =>{
    let {data, editableData} = this.state;
    let {currentUser} = this.props;
    let putRequestPath = this.props.putRequestPath || null;
    let tableLabels = this.props.tableLabels;
    let dataToSend = {};
    tableLabels.forEach((label)=>{
      if(label === "Edit" || label === "Delete" || label === "_id" || label === " registration_date"){
        return;
      }
      dataToSend[label] = editableData[label];
    });
    if(_.isEmpty(dataToSend)){
      this.setState({error: "There is no data to send data"});
      return;
    }
    if(putRequestPath === null){
      this.setState({error: "No put request path provided"});
      return;
    }
    putRequestPath = putRequestPath.replace(/:id/,currentUser.id).replace(/:userid/, editableData._id).replace(/:companyId/, editableData._id).replace(/:subject_id/, editableData._id).replace(/:session_id/,editableData._id);
    apiCall("put", putRequestPath, dataToSend)
    .then((editedUser)=>{
      let updatedData = data.slice();
      let editedUserIdx = _.findIndex(data, {"_id":editedUser._id});
      if(editedUserIdx > -1){
        updatedData[editedUserIdx] = editedUser
      }
      this.setState({data: updatedData, modalOn: false, editableData: null, editing:false, creating:false});
    })
    .catch((err)=>{
      this.setState({
        error: err
      });
    });
   }
//DELETE TABLE DATA
   onRemove = (userid)=>{
    let {currentUser} = this.props;
    let {data} = this.state;
    let deleteRequestPath = this.props.deleteRequestPath || null;

    if(userid === null || userid === undefined ||userid === ""){
      this.setState({error: "Can't find the id to delete"});
      return;
    }

    if(deleteRequestPath === null || !deleteRequestPath){
      this.setState({error: "No put request path provided"});
      return;
    }
     deleteRequestPath = deleteRequestPath.replace(/:id/,currentUser.id).replace(/:userid/, userid).replace(/:companyId/, userid).replace(/:subject_id/, userid);
     apiCall("delete", deleteRequestPath)
      .then(()=>{
        let updatedData = data.filter((obj)=>{
          return obj._id !== userid 
        });
        this.setState({data: updatedData, modalOn: false, editableData: null, editing:false, creating:false});
      })
      .catch((err)=>{
        this.setState({
          error: err
        });
      });
   }

//CREATE NEW
   onCreate = ()=>{
    this.setState({modalOn: true, editing: false, creating:true});
   }
    handleChangeCreateForm = (e, { name, value }) =>{
    let {createData}= this.state;
    createData[name] = value;
    this.setState({ createData });
   } 

 handleCreateFormSubmit = () =>{
    let {data, createData} = this.state;
    let {currentUser} = this.props;
    let postRequestPath = this.props.postRequestPath || null;

    let dataToSend = {};

    dataToSend = {...createData};
    if(_.isEmpty(dataToSend)){
      this.setState({error: "There is no data to send data"});
      return;
    }
    if(postRequestPath === null){
      this.setState({error: "No put request path provided"});
      return;
    }
    postRequestPath = postRequestPath.replace(/:id/,currentUser.id);
    apiCall("post", postRequestPath, dataToSend)
    .then((newUser)=>{
      let updatedData = data.slice();
      updatedData.push(newUser);
      this.setState({data: updatedData, modalOn: false, editableData: null, editing:false, creating:false, createData: null});
    })
    .catch((err)=>{
      this.setState({
        error: err
      });
    });
   }

//RENDER
 render(){
   const { column, direction, editing, creating } = this.state;
  let error = this.state.error;
  let data = this.state.data;
  let {editableData, createData} = this.state;
  let headerLabels = ["loading...","loading...","loading..." ];
  if(data && Array.isArray(data) && (this.props.tableLabels)){
    headerLabels = this.props.tableLabels;
  }

  let headers = headerLabels.map((label, idx)=>{
    return <Table.HeaderCell sorted={column===label? direction : null} onClick={this.handleSort(label)} key={idx} singleLine>{label}</Table.HeaderCell>
  });


  let cells = <Table.Cell>{"Loading..."}</Table.Cell>;
  let rowWithCells = <Table.Cell>{"Loading..."}</Table.Cell>;
  let formFieldEdit = [];
  let createFormFields = [];
    if(editableData !== null && Object.keys(editableData).length > 0){
    formFieldEdit = Object.keys(editableData).map((key)=>{
      if(key === "password" || key === "_id" || key==="registration_date" || key==="__v"){
        return([]);
      }

      if(typeof editableData[key] === "boolean"){
        return (
        <Form.Field>
          <label htmlFor={key}>{key}</label>
          <Dropdown
          id={key} 
          name={key}
          options={[{text: "True", value:true}, {text: "False", value:false}]}
          value={editableData[key]}
          onChange= {this.handleChangeEditForm}
          >
            
          </Dropdown>
        </Form.Field>
        );
      }
      return (
        <Form.Field>
          <label htmlFor={key}>{key}</label>
          <Form.Input id={key} name={key} onChange={this.handleChangeEditForm} value={editableData[key]}>            
          </Form.Input>
        </Form.Field>
        );
    });    
  }

  if(creating && this.props.tableLabels.length > 0){
    createFormFields = this.props.tableLabels.slice();
      if(this.props.passwordField){
        createFormFields.push("password");
      }
    createFormFields = createFormFields.map((key)=>{
      if(key === "_id" || key==="registration_date" || key==="__v" ||key === "Edit" ||key === "Delete"){
        return([]);
      }

      if(key ==="isActive" || key === "isVerified"){
        return (
        <Form.Field required>
          <label htmlFor={key}>{key}</label>
          <Dropdown
          id={key} 
          name={key}
          options={[{text: "True", value:true}, {text: "False", value:false}]}
          value={createData ? createData[key] : null}
          onChange= {this.handleChangeCreateForm}
          >
            
          </Dropdown>
        </Form.Field>
        );
      }
      if(key ==="password"){
        return (
        <Form.Field required>
          <label htmlFor={key}>{key}</label>
           <Form.Input type="password" id={key} name={key} onChange={this.handleChangeCreateForm} value={createData ? createData[key]:null}/>            
        </Form.Field>
        );
      }
      return (
        <Form.Field required>
          <label htmlFor={key}>{key}</label>
          <Form.Input id={key} name={key} onChange={this.handleChangeCreateForm} value={createData ? createData[key]:null}>            
          </Form.Input>
        </Form.Field>
        );
    });    
  }

  if(data && Array.isArray(data)){
      cells = data.map((obj)=>{
        return headerLabels.map((label, idx)=>{
          let cell = obj[label] || "-";
          if(typeof cell === "boolean"){
            if(cell === true){
              cell = "true";
            }else{
              cell = "false";
            }
          }else if(label.indexOf("date") > -1){
            cell = moment(cell).format('MMMM Do YYYY, h:mm:ss a');
          }

          if(label === "Edit"){
            return (<Table.Cell key={idx+100} selectable onClick={this.onEdit.bind(this,obj)}> <Button color="yellow">Edit</Button> </Table.Cell>);
          }
           if(label === "Delete"){
            return (<Table.Cell key={idx+1000} selectable onClick={(e)=>{
             // console.log(e.target.innerText);
            }}> <Button color="red" onClick={this.onRemove.bind(this, obj._id)}>Delete</Button> </Table.Cell>);
          }
            return <Table.Cell key={idx} selectable textAlign="center" onClick={(e)=>{
              //console.log(e.target.innerText);
            //}}>{typeof cell === "string" ? cell.substring(0,50):cell}</Table.Cell>;
            }}>{typeof cell === "string" ? cell.substring(0,50): JSON.stringify(cell)}</Table.Cell>;
        });
      });
      rowWithCells = cells.map((cell)=>{
        return <Table.Row  key={cell._id}>
                  {cell}
              </Table.Row>
      });
    }

    return (
     
<div className="row" style={{"maxWidth": "900px !important", "overflowX":"scroll"}}>
<Modal open={this.state.modalOn} closeOnDimmerClick={true} onClose={this.close}>
  <Modal.Header>
    {editing && ("Editing...")}
    {creating&& ("Create new...")}
  </Modal.Header>
  <Modal.Content>
    {editableData? 
      <Form onSubmit={this.handleEditFormSubmit}>
      {formFieldEdit}
        <Form.Button color="green" type="submit">
          Submit
        </Form.Button>
      </Form>
      : null}
      {creating && (
        <Form onSubmit={this.handleCreateFormSubmit}>
        {createFormFields}
        <Form.Button color="green" type="submit">
          Submit
        </Form.Button>
      </Form>
        )}
  </Modal.Content>
</Modal>
   <Fragment>
        {error !== "" && error.hasOwnProperty("message") && (<Message error>{error.message}</Message>)
        }
      </Fragment>
    <Table selectable sortable celled striped compact color="blue">
       <Table.Header>
      <Table.Row>
        {headers}
      </Table.Row>
    </Table.Header>
    <Table.Body>
         {rowWithCells}
    </Table.Body>
    <Table.Footer fullWidth>
      <Table.Row>
        <Table.HeaderCell>
          <Button floated='right' icon labelPosition='left' primary size='small' onClick={this.onCreate}>
            <Icon name='user' /> {this.props.createButtonText}
          </Button>
        </Table.HeaderCell>
      </Table.Row>
    </Table.Footer>
    </Table>
</div>
      );
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.currentUser.user,
    role: state.role,
    errors: state.errors
  };
}

export default connect(mapStateToProps)(DataTable);