const projectStatusList = ["new","active","review","completed"];

function getStatusTextAndIcons(status="new",language="en"){
  let statusObj = {};
    switch(status){
      case 'new':
        if(language === "es"){
          statusObj.text = "Nuevo";
        }else{
          statusObj.text = "New";
        }
        statusObj.icon = "idea";
        statusObj.color = "#e73b58";
        break;
      case 'active':
        if(language === "es"){
          statusObj.text = "Activo";
        }else{
          statusObj.text = "Active";
        }
        statusObj.icon = "pencil alternate";
        statusObj.color = "#9c55af";
        break;
      case 'review':
        if(language === "es"){
          statusObj.text = "En Revisión";
        }else{
          statusObj.text = "In Review";
        }
        statusObj.icon = "clipboard";
        statusObj.color = "#e19264";
        break;
      case 'completed':
        if(language === "es"){
          statusObj.text = "Completo";
        }else{
          statusObj.text = "Completed";
        }
        statusObj.icon = "checkmark";
        statusObj.color = "green";
        break;
      }
      return statusObj;
}

const formattedStatusList = (language = "en")=>{
	const statusList = projectStatusList.map((status)=>{
		return {
			value:status,
			...getStatusTextAndIcons(status,language)
		};
	});

	return statusList;
};

 
export default formattedStatusList;

