import React, { Component } from 'react';

import { Form, Segment, Button, Loader } from 'semantic-ui-react';

import {apiCall} from "../services/api";

import FileUploadAndReader from "../components/FileUploadAndReader";


export default class ImportData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: null,
      loading: false,
      jsonData:{},
      labels:[]
    };
  }

  render() {
    const { message, loading } = this.state;
    const browserLang = this.props.browserLang? this.props.browserLang : "en";
    const loader = loading ? <Loader /> : null;
    const messages = (message || '').split('\n').map((m, idx) => <div key={idx}>{m}</div>);
    const onFileRead = (jsonData)=>{
      console.log("jsonData",jsonData);
      let uniqueFormParts = [];
      //Loop over all of the JSON data and compile a list of labels to add as formParts
      if("images" in jsonData && jsonData.images){
        console.log("images found in jsonData");
        const foundformParts = jsonData.images.reduce((acc,imgObj)=>{
          if("shapes" in imgObj){
            for (let shape of imgObj.shapes){
              acc.push({label:shape.label,labelType:shape.labelType});
            }
          }
          return acc;
        },[]);

      uniqueFormParts = foundformParts.filter(function (rec){
        var key = rec.label + '|' + rec.labelType;
        if(!this[key]){
          this[key] = true;
          return true;
        }
      }, Object.create(null));
      }
      this.setState({
        jsonData,
        labels:uniqueFormParts
      })
    }
    const callImport = async ()=>{
        if("images" in this.state.jsonData && this.state.jsonData.images.length > 500){
          alert("Upload a file with less than 500 images");
          return;
        }
        this.setState({
          loading:true
        })
        const res = await apiCall("POST",`${process.env.REACT_APP_PRODUCTION_SERVER_URL}/api/projects/${this.props.projectId}/import/`,{jsonData: this.state.jsonData,labels:this.state.labels});
        console.log("response",res);
        this.setState({
          loading: false,
        });
        window.location.reload();
    };

    return (
      <Segment>
        <p>
          Import image labels exported from tools like{' '}
          <a href="https://labelbox.com">LabelBox</a>.
        </p>
        <p>
          The files must be formatted as a valid JSON, with corresponding image
          name in the "imagePath" field.
        </p>
        <p>
          Objects from the "shapes" field will be imported as polygons with
          corresponding labels created.
        </p>
        <FileUploadAndReader 
          label={browserLang === "en" ? "Drag 'n' drop some files here, or click to select files":"Arrastra los archivos aquí, o haz click para seleccionar archivo"}
          callback={onFileRead}
          />
        {Object.keys(this.state.jsonData).length > 0 && "projectName" in this.state.jsonData && (
          <div>
            {`Importing from: ${this.state.jsonData.projectName} - ${this.state.jsonData.projectId ? this.state.jsonData.projectId:""}`}
          </div>
          )}
        <Button onClick={callImport} disabled={Object.keys(this.state.jsonData).length === 0? true:false} loading={this.state.loading} positive>
          Import Data
        </Button>
        <div style={{ marginTop: '2em' }}>{messages}</div>
      </Segment>
    );
  }
}
