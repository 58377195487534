import React, { useState, useRef, useEffect } from 'react';
import { Button, Divider, Image, Input, Popup, Select } from 'semantic-ui-react';
import '../../styles/UsersStyles.css';
import UserIcon from '../../images/global-images/config/user-icon.svg';
import { apiCall } from '../../services/api';
import moment from 'moment-timezone';
import ModalResetPassword from './ModalResetPassword';
import ModalDeleteAccount from './ModalDeleteAccount';
import GlobalToastNotification from '../global-components/GlobalToastNotification';

import { useDispatch, useSelector } from 'react-redux';
import { updateUser, setUserLanguage } from '../../store/actions/users';
import { authUser } from '../../store/actions/auth';

const UserSettings = ({errors}) => {
    const dispatch = useDispatch();
    const currentUser = useSelector(state => state.currentUser);
    const { f_name, l_name, profileImageUrl: currentProfileImageUrl, email, role, id, language, timeZone } = currentUser.user;
    const [defaultLanguage, setDefaultLanguage] = localStorage.getItem("language");
    const [newLanguage, setNewLanguage] = useState(language || defaultLanguage);
    const [profileImageUrl, setProfileImageUrl] = useState(currentProfileImageUrl || UserIcon);
    const [originalProfileImageUrl, setOriginalProfileImageUrl] = useState(profileImageUrl);
    const [selectedLanguage, setSelectedLanguage] = useState('');
    const [selectedTimeZone, setSelectedTimeZone] = useState(timeZone || 'America/New_York');
    const [responseMessage, setResponseMessage] = useState('');
    const [responseApiMessage, setResponseApiMessage] = useState('');
    const [isSuccess, setIsSuccess] = useState(true);
    const [isSaveButtonEnabled, setIsSaveButtonEnabled] = useState(false);
    const [isSaveButtonEnabledRegion, setIsSaveButtonEnabledRegion] = useState(false);
    const [newFName, setNewFName] = useState(f_name || '');
    const [newLName, setNewLName] = useState(l_name || '');
    const [showPopup, setShowPopup] = useState(false);
    const fileInputRef = useRef(null);
    const authType = 'refreshToken';

    useEffect(() => {

    }, [currentUser])

    useEffect(() => {
        if(errors?.message){
            console.log("Errors changed",errors);
            setIsSuccess(false);
            setResponseApiMessage(errors?.message);
            setShowPopup(true);
            setTimeout(() => {
                setShowPopup(false);
                setResponseApiMessage(null);
            }, 5000);
        }else if(responseApiMessage){
            setIsSuccess(true);
            setShowPopup(true);
            setTimeout(() => {
                setShowPopup(false);
                setResponseApiMessage(null);
            }, 5000);
        }
    }, [errors])



    const handleLanguageChange = (e, { value }) => {
        setNewLanguage(value);
        setIsSaveButtonEnabledRegion(true);
    };

    const handleTimeZoneChange = (e, { value }) => {
        setSelectedTimeZone(value);
        setIsSaveButtonEnabledRegion(true);
    };

    const options = [
        { key: 'en', text: 'English', value: 'en' },
        { key: 'es', text: 'Spanish', value: 'es' }
    ];

    const timeZoneOptions = moment.tz.names().map((tz) => {
        const offset = moment.tz(tz).utcOffset();
        const sign = offset >= 0 ? '+' : '-';
        const hours = Math.floor(Math.abs(offset) / 60);
        const minutes = Math.abs(offset) % 60;
        const offsetStr = `${sign}${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
        return {
            key: tz,
            text: `${tz} (UTC ${offsetStr})`,
            value: tz
        };
    });

    const handleImageUpload = async (event) => {
        const userId = id;
        const file = event.target.files[0];

        const validExtensions = ['jpg', 'jpeg', 'png'];
        const fileExtension = file.name.split('.').pop().toLowerCase();
    
        if (!validExtensions.includes(fileExtension)) {
            setResponseApiMessage('Invalid file type. Please upload a .jpg, .jpeg, or .png file.');
            setIsSuccess(false);
            setIsSaveButtonEnabled(false);
            setShowPopup(true);
            setTimeout(() => setShowPopup(false), 3000);
            return;
        }

        const formData = new FormData();
        formData.append('image', file);
        formData.append('userId', userId);

        try {
            const response = await apiCall('post', `${process.env.REACT_APP_PRODUCTION_SERVER_URL}/api/images/uploads/cloudinary-user`, formData);
            if (response.success) {
                setProfileImageUrl(response.imageUrl);
                setResponseApiMessage('Image uploaded successfully!');
                setIsSaveButtonEnabled(true);
                setIsSuccess(true);
                setShowPopup(true);
                dispatch(updateUser(response));
            } else {
                setResponseApiMessage(`Error uploading image: ${response.message}`);
                setIsSuccess(false);
            }
        } catch (error) {
            setResponseApiMessage('Error uploading image.');
            setIsSuccess(false);
        }
        setTimeout(() => setShowPopup(false), 3000);
    };

    const handleButtonClick = () => {
        fileInputRef.current.click();
    };

    const handleFNameChange = (e) => {
        setNewFName(e.target.value);
        setIsSaveButtonEnabled(true);
    };

    const handleLNameChange = (e) => {
        setNewLName(e.target.value);
        setIsSaveButtonEnabled(true);
    };

    const handleRemoveImage = () => {
        setProfileImageUrl('');
        setIsSaveButtonEnabled(true);
    };

    const handleSaveChanges = async () => {
        const userId = id;
        const body = {};

        if (newFName !== f_name) {
            body.f_name = newFName;
        }

        if (newLName !== l_name) {
            body.l_name = newLName;
        }

        if (profileImageUrl !== originalProfileImageUrl) {
            body.profileImageUrl = profileImageUrl;
        }

        if (Object.keys(body).length === 0) {
            setResponseMessage('No changes to save.');
            setIsSuccess(false);
            return;
        }

        try {
            dispatch(updateUser(body));
            dispatch(authUser(authType, currentUser));
            setResponseApiMessage('Changes saved successfully!');
        } catch (error) {
            console.log("error from handlesavechanges",error);
        }
    };

    const handleSaveLanguageAndTimeZone = async () => {

        const body = {};

        body.language = newLanguage;
        body.timeZone = selectedTimeZone;


        if (Object.keys(body).length === 0) {
            setResponseMessage('No changes to save.');
            setIsSuccess(false);
            return;
        }

        try {
            dispatch(updateUser(body));
            dispatch(setUserLanguage(newLanguage));
            setResponseApiMessage('Language and time zone updated successfully!');
            setIsSaveButtonEnabledRegion(false);
            dispatch(authUser(authType, currentUser))

        }
        catch (error) {
            setResponseApiMessage('Error saving changes.');
            setIsSuccess(false);
        }
    };


    const handleCancel = () => {
        setNewFName(f_name);
        setNewLName(l_name);
        setProfileImageUrl(originalProfileImageUrl);
        setIsSaveButtonEnabled(false);
        setResponseApiMessage('Changes have been discarded.');
        setIsSuccess(true);
    };

    return (
        <div style={{ flexGrow: 1, padding: '20px' }}>

            <div className='inside-content'>
                <h2>My settings</h2>
            </div>
            <div className='white-container'>
                <h3 className='content-space'>General information</h3>
                <div className='inside-content'>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '10px', marginTop: '10px', margin: '0 0 10px 20px' }}>
                        <Image
                            src={profileImageUrl || UserIcon}
                            style={{
                                width: '80px',
                                height: '80px',
                                borderRadius: '50%',
                                objectFit: 'cover',
                            }}
                        />
                        <div>
                            <Button className="button-user" style={{ marginLeft: '20px' }} onClick={handleButtonClick}>
                                {profileImageUrl ? 'Change profile image' : 'Add profile image'}
                            </Button>
                            {profileImageUrl && profileImageUrl !== UserIcon && (
                                <Button
                                    className="button-delete"
                                    style={{ marginLeft: '20px', marginTop: '10px', display: 'block' }}
                                    onClick={handleRemoveImage}
                                >
                                    Remove
                                </Button>
                            )}
                            <input
                                type='file'
                                ref={fileInputRef}
                                style={{ display: 'none' }}
                                accept='image/*'
                                onChange={handleImageUpload}
                            />
                        </div>
                    </div>
                </div>
                <div style={{ marginTop: '10px', textAlign: 'left', margin: '0 0 10px 20px' }}>
                    <label className='user-label'>First name</label>
                    <Input
                        type="text"
                        placeholder="Enter information here"
                        className='user-input'
                        value={newFName}
                        onChange={handleFNameChange}
                    />
                </div>
                <div style={{ marginTop: '10px', textAlign: 'left', margin: '0 0 10px 20px' }}>
                    <label className='user-label'>Last name</label>
                    <Input
                        type="text"
                        placeholder="Enter information here"
                        className='user-input'
                        value={newLName}
                        onChange={handleLNameChange}
                    />
                </div>
                <Divider section />
                <div className="button-group" style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    gap: '10px',
                    marginTop: '10px',
                    margin: '0 0 10px 20px'
                }}>
                    <Button
                        positive
                        className="save-button"
                        disabled={!isSaveButtonEnabled}
                        onClick={handleSaveChanges}
                    >
                        Save Changes
                    </Button>
                    {showPopup && (
                        <GlobalToastNotification
                            message={responseApiMessage}
                            notificationType={isSuccess ? 'success' : 'error'}
                            disableDisappear={false}
                        />
                    )}
                    <Button
                        className="cancel-button"
                        onClick={handleCancel}
                    >
                        Cancel
                    </Button>
                </div>
            </div>
            <div className='white-container' style={{ marginTop: '30px' }}>
                <h3 className='content-space'>Sign in & Security</h3>
                <div style={{ marginTop: '10px', textAlign: 'left', margin: '0 0 10px 20px' }}>
                    <label className='user-label'>Email</label>
                    <Input
                        type="text"
                        placeholder="Enter information here"
                        className='user-input disabled'
                        value={`${email}`}
                        disabled
                    />
                </div>
                <Divider section />
                <div style={{ marginTop: '10px', textAlign: 'left', margin: '0 0 10px 20px' }}>
                    <label className='user-label'>Password</label>
                    <ModalResetPassword />
                </div>
            </div>
            <div className='white-container' style={{ marginTop: '30px' }}>
                <h3 className='content-space'>Language & Region</h3>
                <div style={{ marginTop: '10px', textAlign: 'left', margin: '0 0 10px 20px' }}>
                    <label className='user-label'>Language</label>
                    <Select
                        placeholder="Select language"
                        options={options}
                        onChange={(e, data) => {
                            handleLanguageChange(e, data);
                        }}
                        value={newLanguage}
                        style={{ width: '400px' }}
                    />
                </div>
                <div style={{ marginTop: '10px', textAlign: 'left', margin: '0 0 10px 20px' }}>
                    <label className='user-label'>Time zone</label>
                    <Select
                        search
                        placeholder="Select time zone"
                        options={timeZoneOptions}
                        onChange={(e, data) => {
                            handleTimeZoneChange(e, data);
                        }}
                        value={selectedTimeZone}
                        style={{ width: '400px' }}
                    />
                </div>
                <Divider section />
                <div className="button-group" style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    gap: '10px',
                    marginTop: '10px',
                    margin: '0 0 10px 20px'
                }}>
                    <Button
                        positive
                        className="save-button"
                        onClick={handleSaveLanguageAndTimeZone}
                        disabled={!isSaveButtonEnabledRegion}
                    >
                        Save Changes
                    </Button>
                    <Button
                        className="cancel-button"
                        onClick={handleCancel}
                    >
                        Cancel
                    </Button>
                </div>
            </div>
            {role === 'admin' && (
                <div className='white-container' style={{ marginTop: '30px' }}>
                    <h3 className='content-space'>Delete account</h3>
                    <div style={{ marginTop: '10px', textAlign: 'left', margin: '0 0 10px 20px' }}>
                        <p>If you no longer want to use our services, you can delete your account. Please note that this action cannot be undone.</p>
                    </div>
                    <Divider section />
                    <div style={{ textAlign: 'left', margin: '20px' }}>
                        <ModalDeleteAccount currentUser={currentUser} />
                    </div>
                </div>
            )}
        </div>
    );
};

export default UserSettings;
