import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dropdown, Button, ButtonGroup, Icon,Segment
} from 'semantic-ui-react';
import Slider from '../../Slider/Slider';
import FormattedTime from '../FormattedTime/FormattedTime';
import './control.css';

const Control = ({
  className,
  isPlaying,
  played,
  playbackRate,
  duration,
  onSliderMouseUp,
  onSliderMouseDown,
  onSliderChange,
  onRewind,
  onPlayPause,
  onSpeedChange,
  onNextFrame,
  onPrevFrame,
  handleZoomIn,
  handleZoomOut,
  togglePanMode,
  resetCanvas,
  isPanning
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  
  return (
    <div className={`player-control${className ? ` ${className}` : ''}`}>
    <Segment>
      <Slider
        played={played}
        onMouseUp={onSliderMouseUp}
        onMouseDown={onSliderMouseDown}
        onChange={onSliderChange}
      />
      <div className='d-flex mt-2'>
        <div className='mr-auto d-flex align-items-center'>
          <Button.Group>
            <Button className='player-control__button d-flex align-items-center' basic onClick={onRewind}>
              <Icon name='undo' className='player-control__icon' size="large"/>
            </Button>
            <Button className='player-control__button d-flex align-items-center' basic onClick={onPrevFrame}>
              <Icon name='step backward' className='player-control__icon' size="large"/>
            </Button>
            <Button className='player-control__button d-flex align-items-center' basic onClick={onPlayPause}>
              {isPlaying ? <Icon name='pause' className='player-control__icon' size="large"/> : <Icon name='play' size="large" className='player-control__icon' />}
            </Button>
            <Button className='player-control__button d-flex align-items-center' basic onClick={onNextFrame}>
              <Icon name='step forward' className='player-control__icon' size="large"/>
            </Button>
            <Button className='player-control__button d-flex align-items-center' basic onClick={togglePanMode}>
              <Icon name={isPanning ? "hand rock outline" :'hand paper outline'} className='player-control__icon' size="large"/>
            </Button>
          </Button.Group>
          <Dropdown
            open={isDropdownOpen}
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            icon='caret down'
            size='mini'
          >
            <Dropdown.Menu>
              <Dropdown.Header>Speed</Dropdown.Header>
              <Dropdown.Item className='player-control__speed-item' onClick={() => onSpeedChange(0.25)}>0.25x</Dropdown.Item>
              <Dropdown.Item className='player-control__speed-item' onClick={() => onSpeedChange(0.5)}>0.5x</Dropdown.Item>
              <Dropdown.Item className='player-control__speed-item' onClick={() => onSpeedChange(1)}>1x</Dropdown.Item>
              <Dropdown.Item className='player-control__speed-item' onClick={() => onSpeedChange(1.5)}>1.5x</Dropdown.Item>
              <Dropdown.Item className='player-control__speed-item' onClick={() => onSpeedChange(2)}>2x</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className='d-flex align-items-center'>
          <div className='player-control__time'>
            <FormattedTime seconds={played * duration} />
            {' / '}
            <FormattedTime seconds={duration} />
          </div>
        </div>
      </div>
    </Segment>
    </div>
  );
};

Control.propTypes = {
  className: PropTypes.string,
  isPlaying: PropTypes.bool,
  played: PropTypes.number,
  playbackRate: PropTypes.number,
  duration: PropTypes.number,
  onSliderMouseUp: PropTypes.func,
  onSliderMouseDown: PropTypes.func,
  onSliderChange: PropTypes.func,
  onRewind: PropTypes.func,
  onPlayPause: PropTypes.func,
  onSpeedChange: PropTypes.func,
};

Control.defaultProps = {
  className: '',
  isPlaying: false,
  played: 0,
  playbackRate: 1,
  duration: 0,
  onSliderMouseUp: () => {},
  onSliderMouseDown: () => {},
  onSliderChange: () => {},
  onRewind: () => {},
  onPlayPause: () => {},
  onSpeedChange: () => {},
};

export default Control;
