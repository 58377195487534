import React, {useState, useEffect} from 'react';
import {Button} from 'semantic-ui-react';
import {apiCall} from "../../services/api";

const LoadMore = ({browserLang = "en", buttonText = "Load More", baseURL, getMorePath, countAllPath, query = {}, method = "get", onLoadMore, color="grey", loadedResults})=>{
	const [loading, setLoading] = useState(false);
	const [page, setPage] = useState(1);
	const [totalResults, setTotalResults] = useState(0);
	useEffect(()=>{
		try{
			const getCount = async () =>{
				const totalResultsCount = await apiCall(method, `${baseURL}/${countAllPath}`);
				setTotalResults(totalResultsCount.count);
			};
			getCount();
		}catch(err){
			console.log(err);
		}
	},[]);

	const handleLoadMore = async () =>{
		setLoading(true);
		let results = [];
		if(query && page){
			query.page = page;
		}
		if(baseURL){
			results = await apiCall(method, `${baseURL}/${getMorePath}`, {params:query});
		}
		if(onLoadMore){
			onLoadMore(results);
			setPage(page + 1);
		}
		setLoading(false);
	};

	return (
		<Button color={color} disabled = { loadedResults >= totalResults} loading={loading} onClick={handleLoadMore}>{buttonText}</Button>
)
}; 

export default LoadMore;