import React from 'react';

const GradientHeader = () => {

    let backgroundImg = `linear-gradient(45deg, rgb(229, 44, 78) 1%, rgb(233, 74, 99) 30%, rgb(174, 82, 157) 69%, rgb(154, 85, 177) 100%)`;
    const useGlobalNav = process.env.REACT_APP_USE_GLOBAL_NAV === 'true';

    if (useGlobalNav) {
        return null;
    }

    return (
        <header className="App-hero" style={{
            "minHeight": "75px",
            "backgroundImage":backgroundImg,
            "color": "white"
        }}>
        </header>
    );
}

export default GradientHeader;
