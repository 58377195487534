import React, {Fragment, useState} from 'react';
import {Grid} from 'semantic-ui-react';
import FilterItem from "./FilterItem";

const FilterSortSearchBar = ({anyOption=true, browserLang = "en", filterBy = [], onSelectionChange})=>{
	const [loading, setLoading] = useState(false);
  let hasMultipleFilters = filterBy.some((filterOpt)=>{
    return filterOpt.multiple;
  });
  let filterItems = filterBy.map((filterOpt, idx)=>{
    let options = [];
    if(anyOption && !filterOpt.multiple){
      options = [{text:browserLang === "es" ? "Todos" : "All", value:"all"},...filterOpt.options];
    }else{
      options = filterOpt.options;
    }
    return(
     <Grid.Column key={idx}>
        <FilterItem key={idx} multipleSelect={filterOpt.multiple || false} filterKey={filterOpt.filterKey} currentValue={filterOpt.currentValue} browserLang={browserLang} label={filterOpt.filterLabel} options={options} onSelectionChange={onSelectionChange}/> 
     </Grid.Column> 
      );
  });

  return (
    <div style={{backgroundColor:"#EBEBEB", padding:"5px", display:"flex", alignItems:"center", justifyContent:"space-between"}}>
      <Grid stackable style={{alignSelf:"start",display:"flex"}}>
        <Grid.Row columns='equal' >
          {filterItems}
        </Grid.Row>
      </Grid>
    </div>
  )
}; 

export default FilterSortSearchBar;