const avatarURLs = [
	"https://res.cloudinary.com/dqwgbheqj/image/upload/v1637455872/4_ny6h8l.svg",
	"https://res.cloudinary.com/dqwgbheqj/image/upload/v1637455872/2_bhwpor.svg",
	"https://res.cloudinary.com/dqwgbheqj/image/upload/v1637455872/3_xpausq.svg",
	"https://res.cloudinary.com/dqwgbheqj/image/upload/v1637455873/1_wrw4kt.svg"
];

const avatarColors = [
  {
    "colorId": 0,
    "hexString": "#000000",
    "rgb": {
      "r": 0,
      "g": 0,
      "b": 0
    },
    "hsl": {
      "h": 0,
      "s": 0,
      "l": 0
    },
    "name": "Black"
  },
  {
    "colorId": 1,
    "hexString": "#800000",
    "rgb": {
      "r": 128,
      "g": 0,
      "b": 0
    },
    "hsl": {
      "h": 0,
      "s": 100,
      "l": 25
    },
    "name": "Maroon"
  },
  {
    "colorId": 2,
    "hexString": "#008000",
    "rgb": {
      "r": 0,
      "g": 128,
      "b": 0
    },
    "hsl": {
      "h": 120,
      "s": 100,
      "l": 25
    },
    "name": "Green"
  },
  {
    "colorId": 3,
    "hexString": "#808000",
    "rgb": {
      "r": 128,
      "g": 128,
      "b": 0
    },
    "hsl": {
      "h": 60,
      "s": 100,
      "l": 25
    },
    "name": "Olive"
  },
  {
    "colorId": 4,
    "hexString": "#000080",
    "rgb": {
      "r": 0,
      "g": 0,
      "b": 128
    },
    "hsl": {
      "h": 240,
      "s": 100,
      "l": 25
    },
    "name": "Navy"
  },
  {
    "colorId": 5,
    "hexString": "#800080",
    "rgb": {
      "r": 128,
      "g": 0,
      "b": 128
    },
    "hsl": {
      "h": 300,
      "s": 100,
      "l": 25
    },
    "name": "Purple1"
  },
  {
    "colorId": 6,
    "hexString": "#008080",
    "rgb": {
      "r": 0,
      "g": 128,
      "b": 128
    },
    "hsl": {
      "h": 180,
      "s": 100,
      "l": 25
    },
    "name": "Teal"
  },
  {
    "colorId": 7,
    "hexString": "#c0c0c0",
    "rgb": {
      "r": 192,
      "g": 192,
      "b": 192
    },
    "hsl": {
      "h": 0,
      "s": 0,
      "l": 75
    },
    "name": "Silver"
  },
  {
    "colorId": 8,
    "hexString": "#808080",
    "rgb": {
      "r": 128,
      "g": 128,
      "b": 128
    },
    "hsl": {
      "h": 0,
      "s": 0,
      "l": 50
    },
    "name": "Grey"
  },
  {
    "colorId": 9,
    "hexString": "#ff0000",
    "rgb": {
      "r": 255,
      "g": 0,
      "b": 0
    },
    "hsl": {
      "h": 0,
      "s": 100,
      "l": 50
    },
    "name": "Red"
  },
  {
    "colorId": 10,
    "hexString": "#00ff00",
    "rgb": {
      "r": 0,
      "g": 255,
      "b": 0
    },
    "hsl": {
      "h": 120,
      "s": 100,
      "l": 50
    },
    "name": "Lime"
  },
  {
    "colorId": 11,
    "hexString": "#ffff00",
    "rgb": {
      "r": 255,
      "g": 255,
      "b": 0
    },
    "hsl": {
      "h": 60,
      "s": 100,
      "l": 50
    },
    "name": "Yellow"
  },
  {
    "colorId": 12,
    "hexString": "#0000ff",
    "rgb": {
      "r": 0,
      "g": 0,
      "b": 255
    },
    "hsl": {
      "h": 240,
      "s": 100,
      "l": 50
    },
    "name": "Blue"
  },
  {
    "colorId": 13,
    "hexString": "#ff00ff",
    "rgb": {
      "r": 255,
      "g": 0,
      "b": 255
    },
    "hsl": {
      "h": 300,
      "s": 100,
      "l": 50
    },
    "name": "Fuchsia"
  },
  {
    "colorId": 14,
    "hexString": "#00ffff",
    "rgb": {
      "r": 0,
      "g": 255,
      "b": 255
    },
    "hsl": {
      "h": 180,
      "s": 100,
      "l": 50
    },
    "name": "Aqua"
  },
  {
    "colorId": 15,
    "hexString": "#ffffff",
    "rgb": {
      "r": 255,
      "g": 255,
      "b": 255
    },
    "hsl": {
      "h": 0,
      "s": 0,
      "l": 100
    },
    "name": "White"
  },
  {
    "colorId": 16,
    "hexString": "#000000",
    "rgb": {
      "r": 0,
      "g": 0,
      "b": 0
    },
    "hsl": {
      "h": 0,
      "s": 0,
      "l": 0
    },
    "name": "Grey0"
  }];

  const generateRandomAvatar = () =>{
  	const avatarURLIdx = Math.floor(Math.random()*4);
  	const colorIdx = Math.floor(Math.random()*10);
  	return avatarURLs[avatarURLIdx];
  }

  export {generateRandomAvatar};