import React from 'react';
import { Segment, Button, Icon, Grid, Form, TextArea, Input } from 'semantic-ui-react';
import '../App.css';
import { HashLink as Link } from 'react-router-hash-link';
import { Helmet } from "react-helmet";

const DataLabeling = (props) => {
	const browserLang = props.browserLang;
	const spanish = browserLang.indexOf("es") > -1;
	const imgUrl = "images/background-image-hero.jpg";
	let backgroundImg = `linear-gradient(45deg, rgba(0, 26, 255, 0.7) 1%, rgb(99, 97, 238) 30%, rgb(119, 99, 213) 69%, rgb(161, 103, 161) 100%),url(${imgUrl})`;
	return (
		<>
			<header className="App-hero" style={{
				"minHeight": "100vh",
				"backgroundImage": backgroundImg,
				"color": "white"
			}}>
				<Helmet>
					<title>
						Engin.AI - Data Labeling Services
					</title>
					<meta name="description" content="A learning model will only be as good as the data used to train it. Our expert team will help you label your data so you only need to worry about training and using your models" />
					<meta property="og:title" content="Engin.AI - Data Labeling Services For AI" />
					<meta property="og:type" content="website" />
					<meta property="og:url" content="https://www.engin.ai" />
					<meta property="og:description" content="A learning model will only be as good as the data used to train it. Our expert team will help you label your data so you only need to worry about training and using your models" />
					<meta name="keywords" content="engin ai, enginai, engin.ai, data labeling services, AI solutions, computer vision, damage analysis in pavements" />
					<meta name="robots" content="index, follow" />
					<meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
					<meta name="language" content="English" />
					<meta name="author" content="Javier Urbina" />
					<meta property="og:image" content="%PUBLIC_URL%/full-color-logo-only-01-01.png" />
				</Helmet>
				<Segment basic style={{ width: "70%", "zIndex": "10" }} className="cta-hero-section">
					<Grid stackable>
						<Grid.Row columns={2}>
							<Grid.Column style={{ "textAlign": "left" }}>
								<h1 style={{ "textTransform": "uppercase" }}>
									{spanish ? (
										"Etiquetado de Datos"
									) :
										"Data labeling"}

								</h1>
								<br />
								<br />
								<h2 style={{ "lineHeight": "1.5" }}>{spanish ?
									"Los algoritmos de aprendizaje sólo serán tan buenos como los datos usados para entrenarlos. Nosotros le ayudamos con el proceso de etiquetado para que usted sólo se preocupe de entrenar sus modelos." :
									"A learning model will only be as good as the data used to train it. Our expert team will help you label your data so you only need to worry about training and using your models."}</h2>
								<br />


							</Grid.Column>
							<Grid.Column>
								<Segment>
									<Form action="https://formspree.io/engin.ai.solutions@gmail.com" method="POST" style={{ "color": "black" }}>
										<h2>
											{spanish ? "Contáctenos para Hacer un Demo"
												:
												"Request a Demo"
											}
										</h2>
										<Segment basic style={{ "textAlign": "left" }}>
											<Form.Field>
												<label htmlFor="user_name">Name</label>
												<Input name="name" id="user_name" placeholder="John Doe">
												</Input>
											</Form.Field>
											<Form.Field required>
												<label htmlFor="email">Email</label>
												<Input required name="name" id="email" placeholder="jdoe@example.com">
												</Input>
											</Form.Field>
											<Form.Field>
												<label htmlFor="company">Company</label>
												<Input name="name" id="company" placeholder="My Business">
												</Input>
											</Form.Field>
											<Form.Field>
												<label htmlFor="comment">Comment</label>
												<TextArea name="name" id="comment">

												</TextArea>

											</Form.Field>
										</Segment>

										<Button type="submit" color="red" size="huge" syle={{ "width": "50%" }} >
											{spanish ?
												<span className="buttonText">Contáctenos</span>
												:
												<span>Contact Us</span>}
											<span className="iconContainer" style={{ "display": "inline-block", "width": "10px" }}>
												<Icon name="chevron right">

												</Icon>
											</span>
										</Button>
									</Form>
								</Segment>
							</Grid.Column>
						</Grid.Row>
					</Grid>

					<Link to="/#contact-us">


					</Link>
				</Segment>

			</header>
		</>

	);
}

export default DataLabeling;