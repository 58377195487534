import React, { useState, useEffect } from "react";
import { Segment, Button, Loader, Header, Message } from "semantic-ui-react";
import {apiCall} from "../services/api";
import AddGCSMembers from "./AddGCSMembers";
// import FileUploaderGCS from "./FileUploaderGCS";

const CreateBucket = ({project,role}) => {
   const [loading, setLoading] = useState(false);
  const [bucketName, setBucketName] = useState("");
  const [bucketSize, setBucketSize] = useState(0);
  const [error,setError] = useState("");

  useEffect(()=>{
    if(project && "gcsBucketName" in project){
      setBucketName(project.gcsBucketName);
      if("gcsBucketSizeGb" in project){
        setBucketSize(Number(project.gcsBucketSizeGb));
      }
    }   
  },[project]);

  if(!project){
    return (
      <div>No project provided. Cannot create bucket</div>
      );
  }
 

  const handleCreateBucket = async () => {
    setLoading(true);
    try{
      const results = await apiCall("POST", `${process.env.REACT_APP_PRODUCTION_SERVER_URL}/api/projects/${project._id}/bucket`,{bucketName:project._id});
      setBucketName(results.bucketName);
      setLoading(false);

    }catch(err){

    }    
  };

  if(role === "admin"){
    return (
      <Segment>
        {!bucketName && (
          <>
          <Header as="h4" content={`Create a GCS Bucket`} />
          <Button positive onClick={handleCreateBucket} disabled={loading}>
                Create Bucket
              </Button>
          </>
              )}
        {loading ? (
          <Loader active inline="centered" />
        ) : (
          bucketName && (
            <>
            <Header as="h4" content={`GCS Bucket Name: ${bucketName}`} />
            <p>To view bucket, click <a  target="_blank" href={`https://console.cloud.google.com/storage/browser/${bucketName}`}>here</a></p>
            <p>Bucket Size: {bucketSize} GB</p>
            <AddGCSMembers project={project}/>
             {/*<FileUploaderGCS bucketName={project?.gcsBucketName}/>*/}
            </>
          )
        )}
        {error && (
          <Message negative>
            <p>{"message" in error ? error.message : "An unexpected error occured. Please refresh the page"}</p>
          </Message>
          )}
      </Segment>
    );
  }else{
    return (
      <Segment>
        {!bucketName && (<p>No storage bucket available at the moment</p>)}
        {loading ? (
          <Loader active inline="centered" />
        ) : (
          bucketName && (
            <>
            <p>Storage Size: {bucketSize} GB</p>
            <p>To view bucket, click <a  target="_blank" href={`https://console.cloud.google.com/storage/browser/${bucketName}`}>here</a></p>
            </>
          )
        )}
        {error && (
          <Message negative>
            <p>{"message" in error ? error.message : "An unexpected error occured. Please refresh the page"}</p>
          </Message>
          )}
      </Segment>
    );
  }

};

export default CreateBucket;