import React, { Component } from 'react';

import { Loader, Checkbox, Button, Header, Table } from 'semantic-ui-react';

import { AutoSizer, List } from 'react-virtualized';

import update from 'immutability-helper';
import shallowEqualObjects from 'shallow-equal/objects';

import {apiCall} from "../services/api";
import exportToCSV from "../services/exportToCSV";

import LoadMore from "../components/projects-list-components/LoadMore";


export default class ProjectVideos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      videos: [],
      videosOnPage:0,
      videosCount:0
    };

    this.handleLabeled = this.handleLabeled.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.markAllNotLabeled = this.markAllNotLabeled.bind(this);
    this.onLoadMoreVideos = this.onLoadMoreVideos.bind(this);
    this.generateImagesCSVPromise = this.generateImagesCSVPromise.bind(this);
    this.generateDamagesCSVPromise = this.generateDamagesCSVPromise.bind(this);
    this.getBboxPoints = this.getBboxPoints.bind(this);
    this.handleEmailCSV = this.handleEmailCSV.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {

    const { projectId, project } = this.props;
    if (
      projectId === nextProps.projectId &&
      shallowEqualObjects(this.state, nextState) &&
      shallowEqualObjects(nextProps.project, project )
    ) {
      return false;
    }
    return true;
  }

  

  async componentDidMount() {
    this.props.refetchRef(this.refetch.bind(this));
    await this.refetch();
  }

  async refetch() {
    const { projectId } = this.props;
    try {
      const videos = await apiCall("GET",`${process.env.REACT_APP_PRODUCTION_SERVER_URL}/api/videos/?projectId=${projectId}`);
      const videosCount = await apiCall("GET",`${process.env.REACT_APP_PRODUCTION_SERVER_URL}/api/videos/countAllForProject/${projectId}`);
      this.setState({
        isLoaded: true,
        videos,
        videosCount:videosCount.count,
        videosOnPage:videos.length
      });
    } catch (error) {
      this.setState({
        isLoaded: true,
        error,
      });
    }
  }

  async handleLabeled(videoId, labeled) {
    const { videos } = this.state;
    const idx = videos.findIndex(x => x._id === videoId);
    this.setState(state => ({
      videos: update(state.videos, {
        $splice: [[idx, 1, { ...state.videos[idx], labeled }]],
      }),
    }));

    await apiCall("PUT",`${process.env.REACT_APP_PRODUCTION_SERVER_URL}/api/videos/${videoId}`,{ labeled });
  }

  async handleDelete(videoId) {
    const { videos } = this.state;
    const idx = videos.findIndex(x => x._id === videoId);
    this.setState(state => ({
      videos: update(state.videos, {
        $splice: [[idx, 1]],
      }),
    }));

    await apiCall("DELETE",`${process.env.REACT_APP_PRODUCTION_SERVER_URL}/api/videos/${videoId}`);
  }

  onLoadMoreVideos(results){
    // console.log("results",results);
    const currentVideosList = this.state.videos.slice();
    const newVideosList = [...currentVideosList, ...results];
    const newVideosCount = parseInt(newVideosList.length);
      this.setState({
        videos: newVideosList,
        videosOnPage:newVideosCount
      });
  }

  markAllNotLabeled() {
    const { videos } = this.state;
    videos.forEach(video => this.handleLabeled(video._id, false));
  }

  getBboxPoints(labelType,points){
    //shape of the points:[{"lng":62,"lat":0},{"lng":974,"lat":1250}]
    let rightX = -1;
    let leftX = -1;
    let topY = -1;
    let bottomY=-1;
    switch(labelType) {
      case "bbox":
        // find the x and y extremes
        rightX = points[1]['lng'];
        leftX = points[0]['lng'];
        topY = points[0]['lat'];
        bottomY = points[1]['lat'];
        break;
      case ("polyline"):
        // code block
        for (let point of points){
          if (point['lng'] > rightX) {
            rightX = point['lng'];
          }
          if(leftX < 0){
            leftX = point['lng'];
          }else if (point['lng'] < leftX){
            leftX = point['lng'];
          }
          if (point['lat'] >bottomY) {
            bottomY = point['lat'];
          }
          if(topY < 0){
            topY = point['lat'];
          }else if (point['lat'] < topY){
            topY = point['lat'];
          }
        }
        break;
      case ("polygon"):
        // code block
        for (let point of points){
          if (point['lng'] > rightX) {
            rightX = point['lng'];
          }
          if(leftX < 0){
            leftX = point['lng'];
          }else if (point['lng'] < leftX){
            leftX = point['lng'];
          }
          if (point['lat'] >bottomY) {
            bottomY = point['lat'];
          }
          if(topY < 0){
            topY = point['lat'];
          }else if (point['lat'] < topY){
            topY = point['lat'];
          }
        }
        break;
      default:
        break;
    }
    return {
      rightX,
      leftX,
      topY,
      bottomY
    }
  }

  async handleEmailCSV(includeUnlabeled=false,isDistress=false){
    let params = {};
    let dataType = "imageList";
    if(isDistress){
      dataType = "detections"
    }
    const { projectId} = this.props;
    const projectJSON = await apiCall("POST",`${process.env.REACT_APP_PRODUCTION_SERVER_URL}/api/jobs/projects/${projectId}/reports/`,{includeUnlabeled,format:"csv",dataType});
  }

  generateDamagesCSVPromise = ()=>{
    return new Promise((resolve, reject)=>{
      const { projectId, project } = this.props;
    let images = []
    apiCall("GET",`${process.env.REACT_APP_PRODUCTION_SERVER_URL}/api/images/?projectId=${projectId}&limit=${this.state.imagesCount}`)
      .then((results)=>{
        images = results;
        const fileName = `${project.name}-Damages-Info.csv`;
        let assignedLabelerName = "Not Assigned";
        let createdByName = "";
        if("assignedLabeler" in project && project.assignedLabeler){
          assignedLabelerName = `${project.assignedLabeler.f_name} ${project.assignedLabeler.l_name}`;
        }
        if("createdBy" in project && project.createdBy){
          createdByName = `${project.createdBy.f_name} ${project.createdBy.l_name}`;
        }
        let csvHeaders = [
          ["Labeler Name", `${assignedLabelerName}`],
          ["Project Name", project.name],
          ["Project Id", project._id],
          ["fileName", "Image ID", "Image URL", "Rated","Damage Code", "Width (Px)","Height (Px)","Engin URL"]
          ];

        const projectLabels = project.form.formParts.slice() || [];
        let imageRows = [];
        for (let image of images){
          let imageName = "";
          let imageLink = "";
          let labeled = false;
          let labelData = {...image.labelData.labels} || {};
          if("imageMetadata" in image && image.imageMetadata && "name" in image.imageMetadata && image.imageMetadata.name){
            imageName = image.imageMetadata.name;
          }
          if("externalLink" in image && image.externalLink){
            imageLink = image.externalLink;
          }
          if("labeled" in image && image.labeled){
            labeled = image.labeled;
          }
          // console.log("imageRows",imageRows);
          // console.log("labelData",labelData);
          let filteredLabelData = {};
          for(let key in labelData){
            if(labelData[key].length > 0){
              filteredLabelData[key] = labelData[key];
            }
          }

          if(Object.keys(filteredLabelData).length >0){
            for(let key in filteredLabelData){
              const foundDamageLabel = projectLabels.find((label)=>{
                return label.id === key
              });
              const foundLabel = foundDamageLabel && "name" in foundDamageLabel ? foundDamageLabel.name : "N/A";
              for(let el of filteredLabelData[key]){
                const points = JSON.stringify(el.points);
                const bboxPoints = this.getBboxPoints(el.type,el.points);
                let width = bboxPoints.rightX - bboxPoints.leftX;
                let height = bboxPoints.bottomY - bboxPoints.topY;

                if(width <= 0){
                  width = 1;
                }
                if (height <= 0 ){
                  height = 1;
                }
                imageRows.push([imageName, image._id, imageLink, labeled,foundLabel,width,height,`${window.location.origin}/#/label/${project._id}/${image._id}`]);
              }
            }
          }else{
            imageRows.push([imageName, image._id, imageLink, labeled,"None",`${window.location.origin}/#/label/${project._id}/${image._id}`]);
          }
        }
        
        const finalCSV = csvHeaders.concat(imageRows);
        
        // console.log("finalCSV",finalCSV);
        exportToCSV(fileName, finalCSV);
        resolve(finalCSV);

      })
      .catch((err)=>{
        console.log(err);
        reject(err);
      }); 
    });
  }

  generateImagesCSVPromise = ()=>{
    return new Promise((resolve,reject)=>{
      const { projectId, project } = this.props;
      let images = []
      apiCall("GET",`${process.env.REACT_APP_PRODUCTION_SERVER_URL}/api/images/?projectId=${projectId}&limit=${this.state.imagesCount}`)
        .then((results)=>{
          images = results;
          const fileName = `${project.name}-Images-Info.csv`;
          let assignedLabelerName = "Not Assigned";
          let createdByName = "";
          if("assignedLabeler" in project && project.assignedLabeler){
            assignedLabelerName = `${project.assignedLabeler.f_name} ${project.assignedLabeler.l_name}`;
          }
          if("createdBy" in project && project.createdBy){
            createdByName = `${project.createdBy.f_name} ${project.createdBy.l_name}`;
          }
          let csvHeaders = [
            ["Labeler Name", `${assignedLabelerName}`],
            ["Project Name", project.name],
            ["Project Id", project._id],
            ["FileName", "Image ID", "Image URL", "Rated","Engin URL"]
            ];
          const imageRows = images.map((image)=>{
            let imageName = "";
            let imageLink = "";
            let labeled = false;
            if("imageMetadata" in image && image.imageMetadata && "name" in image.imageMetadata && image.imageMetadata.name){
              imageName = image.imageMetadata.name;
            }
            if("externalLink" in image && image.externalLink){
              imageLink = image.externalLink;
            }
            if("labeled" in image && image.labeled){
              labeled = image.labeled;
            }
            return [imageName, image._id, imageLink, labeled,`${window.location.origin}/#/label/${project._id}/${image._id}`];
          });

          const finalCSV = csvHeaders.concat(imageRows);

          exportToCSV(fileName, finalCSV);
          resolve(finalCSV);
        })
        .catch((err)=>{
          console.log(err);
          reject(err);
        });
    })
  }


  render() {
    const { projectId } = this.props;
    const { error, isLoaded, videos,language } = this.state;

    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <Loader active inline="centered" />;
    }

    if (!videos.length) {
      return (
        <Header className="centered" as="h5">
          No videos, upload videos using the form below
        </Header>
      );
    }

    return (
      <div className="project-videos">
        <Table
          celled
          style={{ display: 'flex', flexDirection: 'column', height: 600 }}
        >
          <Table.Header style={{ flex: '0 0 auto' }}>
            <Table.Row style={{ display: 'flex', background: '#f9fafb' }}>
              <Table.HeaderCell style={columnStyles[0]}>ID</Table.HeaderCell>
              <Table.HeaderCell style={columnStyles[1]}>
                Video Link
              </Table.HeaderCell>
              <Table.HeaderCell style={columnStyles[2]}>
                Rating Status
              </Table.HeaderCell>
              <Table.HeaderCell style={columnStyles[3]}>
                Actions
              </Table.HeaderCell>
              {/* extra header cell to even out the width with a fake scrollbar */}
              <Table.HeaderCell
                style={{
                  flex: '0 0 auto',
                  opacity: 0,
                  overflowY: 'scroll',
                  padding: 0,
                  border: 0,
                }}
              />
            </Table.Row>
          </Table.Header>
          <Table.Body style={{ height: '100%', flex: 1, outline: 0 }}>
            <AutoSizedList
              rowHeight={100}
              rowCount={videos.length}
              style={{ overflowY: 'scroll' }}
              rowRenderer={({ index, style, key }) => (
                <Row
                  key={key}
                  style={style}
                  video={videos[index]}
                  projectId={projectId}
                  onLabeled={this.handleLabeled}
                  onDelete={this.handleDelete}
                />
              )}
              overscanRowCount={10}
            />
          </Table.Body>
            <Table.Footer fullWidth>
              <Table.Row>
               <LoadMore browserLang = {language} buttonText = {language === "es" ? "Cargar Más" : "Load More"} baseURL = {`${process.env.REACT_APP_PRODUCTION_SERVER_URL}`} getMorePath={`api/videos/?projectId=${projectId}`} countAllPath={`api/videos/countAllForProject/${projectId}`} method = "get" onLoadMore={this.onLoadMoreVideos} color="teal" loadedResults={this.state.videosOnPage}/>
              </Table.Row>
        </Table.Footer>
        </Table>
      </div>
    );
  }
}

const columnStyles = [
  { flex: '0 0 250px', lineHeight: '32px', maxWidth:'250px', wordBreak : 'break-all', 'overflowY':'hidden' },
  { flex: '0 0 250px', lineHeight: '32px', maxWidth:'250px', wordBreak : 'break-all', 'overflowY':'hidden' },
  { flex: '0 0 120px', lineHeight: '32px', maxWidth:'120px', wordBreak : 'break-all', 'overflowY':'hidden' },
  { flex: '0 0 250px', lineHeight: '32px', maxWidth:'250px', wordBreak : 'break-all', 'overflowY':'hidden' },
];
const Row = ({ video, projectId, style, onLabeled, onDelete }) => (
  <Table.Row style={{ ...style, display: 'flex' }}>
    <Table.Cell style={columnStyles[0]}>{video._id}</Table.Cell>
    <Table.Cell style={columnStyles[1]}>
      <a target="_blank"rel="noopener noreferrer" href={`${video.externalLink}`}>{video.externalLink}</a>
    </Table.Cell>
    <Table.Cell style={columnStyles[2]}>
      <Checkbox
        checked={!!video.labeled}
        label="Rated"
        onChange={(e, { checked }) => onLabeled(video._id, checked)}
      />
    </Table.Cell>
    <Table.Cell style={columnStyles[3]}>
      <div>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`/#/label/projects/${projectId}/videos/${video._id}`}
        >
          <Button positive icon="pencil" label="Edit" size="tiny" />
        </a>
        <Button
          color="red"
          icon="trash"
          label="Delete"
          size="tiny"
          onClick={() => onDelete(video._id)}
        />
      </div>
    </Table.Cell>
  </Table.Row>
);

const AutoSizedList = props => (
  <AutoSizer>
    {({ height, width }) => <List height={height} width={width} {...props} />}
  </AutoSizer>
);
