import React from 'react';
import PropTypes from 'prop-types';
import { Button, List } from 'semantic-ui-react';
import './preview.css';

const Preview = ({
  className,
  header,
  notices,
  height,
  onPreviewClick,
}) => {
  const noticeItems = notices.map((notice, index) => (
    <List.Item key={index}>{notice}</List.Item>
  ));

  const rootClassName = `d-flex align-items-center justify-content-center preview${className ? ` ${className}` : ''}`;

  return (
    <div className={rootClassName} style={{ height }}>
      <div>
        <div className='mb-4'>{header}</div>
        <List className='text-left preview__list mb-5 pl-4'>{noticeItems}</List>
        <Button primary onClick={onPreviewClick}>
          Scanned the video and ready to start
        </Button>
      </div>
    </div>
  );
};

Preview.propTypes = {
  className: PropTypes.string,
  header: PropTypes.string,
  notices: PropTypes.arrayOf(PropTypes.string),
  height: PropTypes.number,
  onPreviewClick: PropTypes.func,
};

Preview.defaultProps = {
  className: '',
  header: '',
  notices: [],
  height: 0,
  onPreviewClick: () => {},
};

export default Preview;
