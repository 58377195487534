import React from 'react';
import '../App.css';

const DiagonalSpacer = (props)=>{
	const tilt = props.tilt || "tilt-down";
	const bColor = props.background || "#ffffff";
	const tiltClass =  `extra-border-inner-${tilt}`;
	return(
	<div className="border-diagonal">
		<div className="extra-border-outer">
			<div className={tiltClass} style={{
				"backgroundColor":`${bColor}`
			}}>
				
			</div>
		</div>
	</div>);
}
export default DiagonalSpacer;