import React, { useState, useEffect } from 'react';
import AssignedCompany from '../../components/AssignedCompany';

import {
    Button,
    Card,
    Container,
    Divider,
    Form,
    Grid,
    Input,
    Image,
    Radio,
} from 'semantic-ui-react';

import { apiCall } from "../../services/api";
import '../../styles/CreateProject.css';
import About from '../../images/about-project.svg';
import Road from '../../images/road-signs.svg';
import Privacy from '../../images/privacy.svg';
import { useCreateProjectContext } from '../../context/CreateProjectContext';


const AboutProject = ({ handleNextStep, role, id, bucketName }) => {
    const { formDataAboutContext, updateFormDataAbout, companyId, setCompanyId, aboutBucketName, setAboutBucketName } = useCreateProjectContext();

    const [errors, setErrors] = useState({});
    const [companyName, setCompanyName] = useState('');

    let blankSpace = true;

    useEffect(() => {
        if (!bucketName) {
            getCompany(companyId);
        }
    }, [companyId]);

    const handleOptionChange = (option) => {
        const updatedOptions = [...formDataAboutContext.selectedOptions];
        const index = updatedOptions.indexOf(option);
        if (index === -1) {
            updatedOptions.push(option);
        } else {
            updatedOptions.splice(index, 1);
        }
        updateFormDataAbout({
            ...formDataAboutContext,
            selectedOptions: updatedOptions,
        });
    };

    const handleProjectNameChange = (e) => {
        updateFormDataAbout({
            ...formDataAboutContext,
            projectName: e.target.value,
        });
    };

    const handleModelTypeChange = (type) => {
        updateFormDataAbout({
            ...formDataAboutContext,
            modelType: type,
        });
    };

    const validateField = (fieldName, value) => {
        let error = '';
        if (!value.trim()) {
            error = `${fieldName} is required`;
        }
        return error;
    };

    const validateForm = () => {
        let errors = {};
        const nameError = validateField('Project name', formDataAboutContext.projectName);
        const modelTypeError = validateField('Model Type', formDataAboutContext.modelType);

        if (!formDataAboutContext.projectName.trim()) {
            errors.projectName = 'Project name is required';
        }

        if (formDataAboutContext.modelType.length === 0) {
            errors.modelType = 'Model Type is required';
        }

        setErrors({
            name: nameError,
            modelType: modelTypeError,
            selectedOptions: errors.selectedOptions || '',
        });

        return Object.values(errors).every(error => !error);
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
        const isValid = validateForm();
        if (isValid) {
            handleNextStep();
        } else {
            console.log('Invalid form');
        }
    };


    const isFormComplete = () => {
        
        return (
            formDataAboutContext.projectName.trim() !== '' &&
            formDataAboutContext.modelType !== ''
        );
    };

    const [project, setProject] = useState({});
    const [language, setLanguage] = useState('en');
    const [user, setUser] = useState({ id: id });

    const handleCompanyAssignment = (companyId) => {
        setCompanyId(companyId || null);
        if (companyId) {
            getCompany(companyId).then(result => {
                if (result) {
                    const { bucketName, companyName } = result;
                }
            });
        } else {
            setAboutBucketName(null);
            setCompanyName(null);
        }
    };

    const getCompany = async (companyId) => {
        if (!companyId || companyId === 'null') {
            setAboutBucketName(null);
            setCompanyName(null);
            return null;
        }

        try {
            const response = await apiCall('get', `${process.env.REACT_APP_PRODUCTION_SERVER_URL}/api/companies/${companyId}/getCompanyById`);
            const bucketName = response.compGcsBucketName || null;
            const companyName = response.name || 'Unknown Company'; 
            setAboutBucketName(bucketName);
            setCompanyName(companyName);
            return { bucketName, companyName };
        } catch (error) {
            console.error('Error fetching company data:', error);
            setAboutBucketName(null);
            setCompanyName('none');
            return null;
        }
    };

    useEffect(() => {
        if (companyId && companyId !== 'null' && companyId !== '') {
            setCompanyId(companyId)
            getCompany(companyId).then((result) => {
            }).catch((error) => {
                console.error('Error getting company GCS bucket name:', error);
            });
        } else {
            setAboutBucketName(null);
            setCompanyName(null);
        }
    }, [companyId, setAboutBucketName, setCompanyName]);


    return (
        <Container className="inside-container">
            <Container textAlign='left' style={{ width: '100%' }} className="inside-container">
                <div>
                    <p className="subtitle">Project name</p>
                </div>
                <Form onSubmit={handleFormSubmit}>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column width={6}>
                                <Input
                                    className='input-create mt-4 mt-10'
                                    name="projectName"
                                    value={formDataAboutContext.projectName}
                                    placeholder='Untitled'
                                    onChange={handleProjectNameChange}
                                />
                            </Grid.Column>
                            {role === "admin" && (
                                <Grid.Column width={10} style={{ marginTop: '-25px' }}>
                                    <AssignedCompany
                                        basic={true}
                                        project={project}
                                        onSaveCompany={handleCompanyAssignment}
                                        language={language}
                                        currentUserId={user.id}
                                        blankSpace={blankSpace}
                                        companyName={companyName} // Sharing company Name
                                    />
                                </Grid.Column>
                            )}

                        </Grid.Row>
                    </Grid>
                    <div className='mt-30'>
                        <Divider />
                    </div>
                    <div className='mt-30'>
                        <p className="subtitle">Model Type</p>
                    </div>
                    <div className='mt-10'>
                        <Card.Group itemsPerRow={3}>
                            <Card className={formDataAboutContext.modelType === 'Pavement Condition Assessment' ? 'type-active' : ''} onClick={() => handleModelTypeChange('Pavement Condition Assessment')}>
                                <Card.Content>
                                    <Card.Header>
                                        <Image src={About} />
                                    </Card.Header>
                                    <Card.Meta className="mt-10">
                                        <span>Pavement Condition Assessment</span>
                                        <Card.Description>
                                            Evaluate road health for maintenance insights.
                                        </Card.Description>
                                    </Card.Meta>
                                </Card.Content>
                            </Card>
                            <Card className='type-inactive' disabled>
                                <Card.Content>
                                    <Card.Header>
                                        <Image src={Road} />
                                    </Card.Header>
                                    <Card.Meta className="mt-10">
                                        Road signs
                                        <Card.Description>
                                            Configure and analyze road signs for safety.
                                        </Card.Description>
                                    </Card.Meta>
                                </Card.Content>
                            </Card>
                            <Card className='type-inactive' disabled>
                                <Card.Content>
                                    <Card.Header>
                                        <Image src={Privacy} />
                                    </Card.Header>
                                    <Card.Meta className="mt-10">
                                        Privacy AI
                                        <Card.Description>
                                            Blur sensitive info in images for data privacy.
                                        </Card.Description>
                                    </Card.Meta>
                                </Card.Content>
                            </Card>
                        </Card.Group>
                    </div>
                    <div className='mt-30'>
                        <p className="label-grey">Additional detection options:</p>
                        <div className="radio-container">
                            <Radio
                                toggle
                                className="custom-toggle"
                                checked={formDataAboutContext.selectedOptions.includes('Road Striping')}
                                onChange={() => handleOptionChange('Road Striping')}
                            />
                            <span className="radio-label">Detect Road Striping</span>
                        </div>
                        <div className="radio-container">
                            <Radio
                                toggle
                                className="custom-toggle"
                                checked={formDataAboutContext.selectedOptions.includes('Manholes and Drains')}
                                onChange={() => handleOptionChange('Manholes and Drains')}
                            />
                            <span className="radio-label">Detect Manholes and Drains</span>
                        </div>
                        <div className="radio-container">
                            <Radio
                                toggle
                                className="custom-toggle"
                                checked={formDataAboutContext.selectedOptions.includes('Gutters')}
                                onChange={() => handleOptionChange('Gutters')}
                            />
                            <span className="radio-label">Detect Gutters</span>
                        </div>
                    </div>

                    <div className='mt-30'>
                        <Divider />
                    </div>
                    <div className='right-aligned-container'>
                        <Button className="ui teal button" disabled={!isFormComplete()}>
                            Continue
                        </Button>
                    </div>
                </Form>
            </Container>
        </Container>
    );
}

export default AboutProject;
