import React, { Component } from "react";
import PropTypes from "prop-types";
import { Form, Message, Button } from 'semantic-ui-react';
import ReactGA from 'react-ga';
import {apiCall} from "../services/api";

class AdminAuthForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      profileImageUrl: "",
      f_name:"",
      l_name:"",
      password_confirm:"",
      error:false,
      errorMsg:"",
      agreedToTerms:false,
      a_password:"",
      company:"",
      loading:false,
      message:"",
      companyList:[]
    };
    this.onCheckboxSelect = this.onCheckboxSelect.bind(this);
    this.handleDropdownChange = this.handleDropdownChange.bind(this);
  }

  async componentDidMount(){
    try{
      const companies = await apiCall("GET", `${process.env.REACT_APP_PRODUCTION_SERVER_URL}/api/admin/${this.props.adminUserId ?this.props.adminUserId : "" }/companies`);
      const companyList = Object.keys(companies).map((comp)=>{
        return {
          text:comp,
          value:comp
        }
      });
      this.setState({
        companyList
      });
    }catch(err){
      console.log(err);
    }

  }

  handleSubmit = e => {
    e.preventDefault();
    this.setState({
      loading:true
    });
    const authType = this.props.signUp ? "signup" : "signin";
    if(this.props.signUp){
      if(this.state.password !== this.state.password_confirm){
        this.setState({error: true, errorMsg:"Your passwords don't match. Try again"});
        return;
      }
      e.target.reset();
    }
    if(this.state.a_password !== ""){
      ReactGA.event({
                 category: 'Fake Sign Up',
                 action: 'Captcha triggered'
                }); 
      this.setState({error: true, errorMsg:"Your sign up is suspicious. Please try again later"});
      return;
    }
    if(authType === "signup"){
    apiCall("POST", `${process.env.REACT_APP_PRODUCTION_SERVER_URL}/api/admin/${this.props.adminUserId ?this.props.adminUserId : "" }/users`, {
        email:this.state.email.toLowerCase().trim(),
        password:this.state.password.trim(),
        f_name:this.state.f_name,
        l_name: this.state.l_name,
        profileImageUrl: this.state.profileImageUrl,
        company:this.state.company
        })
      .then((data) => {
        ReactGA.event({
                 category: 'Sign Up',
                 action: 'User Has Been Created'
                }); 
        this.props.history.push("/dashboard");
        this.setState({
          loading:false,
          message:data.message,
          email: "",
          password: "",
          profileImageUrl: "",
          f_name:"",
          l_name:"",
          password_confirm:"",
          agreedToTerms:false,
          a_password:"",
          company:""
        });
        setTimeout(()=>{ this.setState({"message":"", error:""}) }, 3000);
      })
      .catch((err) => {
        this.setState({
          loading:false,
          errorMsg:err,
          email: "",
          password: "",
          profileImageUrl: "",
          f_name:"",
          l_name:"",
          password_confirm:"",
          agreedToTerms:false,
          a_password:"",
          company:""
        });
         setTimeout(()=>{ this.setState({"message":"", error:""}) }, 3000);
        return;
      });
    }

  };

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleDropdownChange= (evt,data)=>{
    this.setState({[data.name]:data.value});
  };

   onCheckboxSelect(){
    this.setState({
      agreedToTerms: !this.state.agreedToTerms
    });
  }

  

  render() {
    const { email, password, f_name, l_name , password_confirm, a_password} = this.state;
    const {
      signUp,
      heading,
      buttonText,
      errors,
      history
    } = this.props;
    const removeError = ()=>{this.setState({error:null})};
    let pageLang = this.props.language;
    history.listen(() => {
      removeError();
    });
    const removeAdditionalField = this.props.removeAdditionalField ? this.props.removeAdditionalField  : false;
    const customWidth = this.props.customWidth ? this.props.customWidth : "50%";
    return (
      <div>
        <div className="section">
    <div className="ui container">
        
        <div className="ui one column centered grid">
        <div className="row">
            <div className="ui segments" id="authform" style={{"width": customWidth}}>
             <div className="ui segment">
                 <h1>{heading}</h1> 
                  {errors.message && (
                  <Message 
                  error
                  content={errors.message}/>) }
                  {this.state.error && this.state.errorMsg !== "" && (
                  <Message 
                  error
                  content={this.state.errorMsg}/>) }
                  {this.state.message !== "" && (
                  <Message 
                  success
                  content={this.state.message}/>) }
             </div>
               <div className="ui left aligned segment">
                    <div className="ui form registerForm">
          <Form error={this.state.error} onSubmit={this.handleSubmit}>
          <div style={{"display":"none"}}>
            <Form.Input
                      style={{"display":"none"}}
                      required={false}
                      alt = "Please leave this field empty"
                      tabIndex="-1"
                      autoComplete="somepassword"
                      id="a_password"
                      name="a_password"
                      onChange={this.handleChange}
                      value={a_password}
                      placeholder="**********"
                   />
          </div>
          {signUp && (
               <div className="required field">
                       <Form.Input
                          required={true}
                          label= {pageLang === "en" ? "First Name" : "Nombre"} 
                          autoComplete="off"
                          id="f_name"
                          name="f_name"
                          onChange={this.handleChange}
                          type="text"
                          value={f_name}
                          placeholder="John"
                       />
                    <div className="required field">
                      <Form.Input
                          required={true}
                          label= {pageLang === "en" ?  "Last Name": "Apellido"} 
                          autoComplete="off"
                          id="l_name"
                          name="l_name"
                          onChange={this.handleChange}
                          type="text"
                          value={l_name}
                          placeholder="Doe"
                       />
                  </div>
                  <div className="required field">
                      <Form.Dropdown
                          required={true}
                          label= {pageLang === "en" ?  "Company": "Empresa"} 
                          autoComplete="off"
                          id="company"
                          name="company"
                          onChange={this.handleDropdownChange}
                          options={this.state.companyList}
                          value={this.state.company}
                          placeholder="Engin.AI"
                       />
                  </div>
          </div>
            ) }
         
         
          <div className="required field">
              <Form.Input
                      required={true}
                      label= "Email" 
                      autoComplete="off"
                      id="email"
                      name="email"
                      onChange={this.handleChange}
                      type="email"
                      value={email}
                      placeholder="johndoe@engin-ai.com"
                   />
          </div>
          <div className="required field">  
            <Form.Input
                      required={true}
                      label= {pageLang === "en" ? "Password" : "Contraseña"} 
                      autoComplete="off"
                      id="password"
                      name="password"
                      onChange={this.handleChange}
                      type="password"
                      value={password}
                      placeholder="**********"
                   />
          </div>
          {/*!signUp && (
            <p><a href="/recover">{pageLang === "en" ? "Forgot your password?" : "¿Olvidaste tu contraseña?"}</a></p>
            )*/}
          {signUp && (
            <div className="required field">  
            <Form.Input
                      required={true}
                      label= {pageLang === "en"? "Confirm password" : "Confirma la Contraseña"} 
                      autoComplete="off"
                      id="password_confirm"
                      name="password_confirm"
                      onChange={this.handleChange}
                      type="password"
                      value={password_confirm}
                      placeholder="**********"
                   />
          </div>
            )}
          {signUp && (
            <Form.Field required>
              <div className= "ui checkbox">
                  <input checked={this.state.agreedToTerms} onChange={this.onCheckboxSelect} required type="checkbox" name="agree"/> 
                <label>
                  <span>
                    {pageLang === "en" ? "I accept the": "Acepto los"} <a href="/terms-of-use">{pageLang === "en"? "Terms and Conditions" : "Términos y Condiciones"}</a> {pageLang=== "en" ? "and the":"y la"} <a href="/privacy-policy">{pageLang === "en"? "Privacy Policy" : "Política de Privacidad"}</a>  
                  </span>
                </label> 
            </div>
            </Form.Field>
            )}

          <Button loading={this.state.loading} disabled={signUp ? (!this.state.agreedToTerms || this.state.email === "" || this.state.password === "" || this.state.f_name === "" || this.state.l_name === "") : (this.state.email === "" || this.state.password === "")} className="ui red large fluid button" type="submit">{buttonText}</Button>
         {signUp && (
                <Message 
                  error
                  content={pageLang === "en"? "Passwords do not match" : "Las contraseñas no son iguales"}
                  />)}
          </Form>
                </div>
                </div> 
                 </div>
        </div>
        {!removeAdditionalField && (
          <div className="row">
                   <div className="ui segment">
                    {signUp ? (pageLang === "en" ? "Already have an account?" : "¿Ya tienes una cuenta?") : (pageLang === "en"? "Not a member yet? Create a free account" : "Crea una cuenta gratuita")}  <a href={signUp? "/signin" : "/signup" }>{signUp? (pageLang === "en" ? "Sign In" : "Ingresa") : (pageLang === "en" ? "here" : "aquí")}</a>.
                 </div>    
            </div>
          )
                           
        }

      
        </div>
        
        
    </div>
   
</div>
      </div>
    );
  }
}
AdminAuthForm.propTypes = {
  buttonText: PropTypes.string,
  errors: PropTypes.object,
  heading: PropTypes.string,
  history: PropTypes.object,
  signIn: PropTypes.bool,
  removeError: PropTypes.func
};

export default AdminAuthForm;
