import React, {Fragment} from "react";
import {Divider, Grid, Button, Modal, Segment, Input} from 'semantic-ui-react';
import '../App.css';
import Dropzone from 'react-dropzone';
import ReactGA from 'react-ga';
import {multipartApiCall, apiCall} from "../services/api";
import UploadHistory from "../components/UploadHistory";
import _ from "lodash";
import AdminAuthForm from "../components/AdminAuthForm";
import CleanupDeletedImageIds from "../components/dashboard-components/CleanupDeletedImageIds";
import GradientHeader from "../containers/GradientHeader";
class Dashboard extends React.Component {
constructor(props){
    super(props);
    this.state = {
      acceptedFiles:[],
      images:[],
      arrayBuffers:[],
      loading:false,
      uploadedFiles:[],
      modalOn:false,
      foldername:""
      
    };
    this.handleDrop = this.handleDrop.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onModalClose = this.onModalClose.bind(this);
  }

    componentDidMount() {
      const currentUser = this.props.currentUser? this.props.currentUser : null; 
      const userid = currentUser && currentUser.id? currentUser.id:null;
      if(!userid){
        return;
      }
      apiCall("GET",`${process.env.REACT_APP_PRODUCTION_SERVER_URL}/api/images/uploads/drive/track?userid=${userid}`).then((data)=>{
        this.setState({
          uploadedFiles:data
        });
      }).catch((err)=>{console.log(err)});
      this.eventSource = new EventSource(`${process.env.REACT_APP_PRODUCTION_SERVER_URL}/api/images/uploads/drive/track/stream?userid=${userid}`);
      console.log("Created event stream");
      this.eventSource.onmessage = e =>{
        this.setState({uploadedFiles:JSON.parse(e.data)});
      }
    }

    componentDidUpdate(prevProps, prevState){
      const currentUser = this.props.currentUser ? this.props.currentUser : null; 
      const userid = currentUser && currentUser.id ? currentUser.id : null;
      const progressArr = this.state.uploadedFiles.every((uploadObj)=>{
        return uploadObj.numFiles === uploadObj.uploadedFileCount;
      });
      if(userid && _.isEqual(prevState.uploadedFiles,this.state.uploadedFiles) && progressArr){
        if(this.eventSource){
          console.log("event stream closed");
          this.eventSource.close();
          this.eventSource = null;
         }
      }
    }

    componentWillUnmount() {
       if(this.eventSource){
        this.eventSource.close();
       }
    }

  handleDrop(acceptedFiles){
    // console.log("acceptedFiles",acceptedFiles);
     let filesArray = [];
      // read file contents
      acceptedFiles.forEach(file => {
        filesArray.push(file);
      });
      // console.log(filesArray);.
       ReactGA.event({
                category: 'Visualization Tool',
                action: 'File Upload',
                label: 'File upload success'
              }); 
     this.setState({
      acceptedFiles:acceptedFiles,
      arrayBuffers:filesArray
     });
    
  }

  handleChange(evt, data){
    this.setState({
      [data.name] : data.value
    });
  }

  onModalClose(){
    window.location.reload();    
  }

  handleSubmit = async (evt)=>{  
    evt.preventDefault();
    const userid = this.props.currentUser.id;
    this.setState({
      loading:true
    });
    try{
      const formData = new FormData();
      for(let file of this.state.acceptedFiles){
        formData.append("image", file);
      }
      formData.append("userid", userid);
      formData.append("foldername",this.state.foldername);
      const uploadedFiles = await multipartApiCall("POST",`${process.env.REACT_APP_PRODUCTION_SERVER_URL}/api/images/uploads/drive/bulk`, formData);
      this.setState({
        loading:false,
        modalOn:true,
        acceptedFiles:[],
        arrayBuffers:[],
        uploadedFiles:[...this.state.uploadedFiles, uploadedFiles],
        foldername:""
      });
    }catch(err){
      console.log(err);
    }

  }

  
  render() {
    let backgroundImg = `linear-gradient(45deg, rgb(229, 44, 78) 1%, rgb(233, 74, 99) 30%, rgb(174, 82, 157) 69%, rgb(154, 85, 177) 100%)`;
    const currentUser = this.props.currentUser ? this.props.currentUser : null; 
    const browserLang = this.props.language || "en";
    const configSectionWidth = this.state.twoImages? 4 : 8;
    let tableData = [];
    const userid = this.props.currentUser.id;

    if(currentUser && ("role" in currentUser) && (currentUser.role === "labeler")){
        return (
            <>
              {(process.env.REACT_APP_USE_GLOBAL_NAV === 'false' || process.env.REACT_APP_USE_GLOBAL_NAV === '' || typeof process.env.REACT_APP_USE_GLOBAL_NAV === 'undefined')  && (
                <GradientHeader />
              )}
               <div style={{"paddingTop":"16px","marginLeft":"40px", "marginRight":"40px"}}>
                    <h1>{currentUser && "company" in currentUser ? currentUser.company : (browserLang === "en"? "Welcome" : "Bienvenido(a)") }</h1>
                    <Segment> 
                        <Button positive href="/#/label/">  
                          {browserLang === "es" ? "Comenzar a etiquetar" : "Start Labeling"}
                        </Button>
                    </Segment>  
               </div>
            </>
          );
    }
     
    // console.log(process.env.REACT_APP_PRODUCTION_SERVER_URL);
    let fileNames = this.state.acceptedFiles.map((file)=>{
      if("name" in file){
        return (<p key={file.name}>{file.name}</p>)
      }else if("path" in file){
        return (<p key={file.path}>{file.path}</p>)
      }else{
        return (<p key="no-name">{"no-name"}</p>)
      }
      
    });
    
    if(this.state.parsedCsvData && "length" in this.state.parsedCsvData){
      tableData = this.state.parsedCsvData.slice(this.state.startingRow, this.state.startingRow+this.state.maxRowCount);
    }
    return (
      <Fragment>
        <Modal open={this.state.modalOn} onClose={this.onModalClose}>
          <Modal.Header>{browserLang === "en" ? "Files Received": "Los archivos han sido recibidos"}</Modal.Header>
            <Modal.Content>
              {browserLang === "en" ? "We're now processing your files, the upload may take some time. Please check the 'Upload History' table to see the progress of your upload ":"Estamos procesando sus archivos, esto puede tomar un tiempo. Por favor revise la sección de 'Historial de Cargas' para ver el progreso"}
            </Modal.Content>
            <Modal.Actions>
              <Button positive onClick={() => window.location.reload()}>
                {browserLang === "en" ? "I Understand" : "Entendido"}
              </Button>
            </Modal.Actions>
        </Modal>
        {(process.env.REACT_APP_USE_GLOBAL_NAV === 'false' || process.env.REACT_APP_USE_GLOBAL_NAV === '' || typeof process.env.REACT_APP_USE_GLOBAL_NAV === 'undefined') && (
				  <GradientHeader />
			  )}
        <div style={{"paddingTop":"16px","marginLeft":"40px", "marginRight":"40px"}}>
          <Grid stackable>
            <Grid.Row columns={2}>

                <Grid.Column width={configSectionWidth} style={{"textAlign":"left"}}>
                  <Grid.Row>
                    <h1>{currentUser && "company" in currentUser ? currentUser.company : (browserLang === "en"? "Welcome" : "Bienvenido(a)") }</h1>
                    </Grid.Row>
                    <Divider hidden/>
                  <Grid.Row>
                    <h2>{browserLang === "en" ? "Select Files":"Seleccionar Archivos"}</h2>
                    </Grid.Row>
                    <Grid.Row>
                    <Segment basic>{browserLang === "en" ? "Each file upload creates a new folder. If you'd like to create a new folder, please perform separate uploads":"Cada subida de archivos crea una nueva carpeta. Si deseas crear nuevas carpetas, haz cargas separadas"}</Segment>
                    </Grid.Row>
                    <Grid.Row>
                      <Dropzone multiple={true} onDrop={acceptedFiles => this.handleDrop(acceptedFiles)}>
                      {({getRootProps, getInputProps}) => (
                        <section>
                          <div className="dropzone" {...getRootProps()}>
                            <input {...getInputProps()} />
                            <p>{browserLang === "en" ? "Drag 'n' drop some files here, or click to select files":"Arrastra los archivos aquí, o haz click para seleccionar archivo"}</p>
                          </div>
                        </section>
                      )}
                </Dropzone>
                    </Grid.Row>
                    <Grid.Row style={{"maxHeight":"100px", "overflowY":"scroll"}}>
                      {fileNames}
                    </Grid.Row>
                    <Divider></Divider>
                    <Grid.Row>
                      <Segment basic>
                        {browserLang === "en" ? "Please identify this upload with a name that describes the type of data that is being sent over":"Por favor identifique estos archivos con un nombre que describa el tipo de datos que se está subiendo"}
                      </Segment>
                    </Grid.Row>
                    <Grid.Row>
                      <Input onChange={this.handleChange} name="foldername" style={{"width":"100%"}} placeholder={browserLang === "en" ? "Dataset from highway A to B" :"Recorrido de via A a B"} label={browserLang === "en"? "Type a name for this folder":"Escribe un nombre para esta carpeta"}></Input>
                    </Grid.Row>
                    <Divider hidden></Divider>
                    <Grid.Row>
                      <Button fluid positive loading={this.state.loading} onClick={this.handleSubmit}>
                        {browserLang === "en"? "Upload": "Subir"}
                      </Button>
                    </Grid.Row>
                  </Grid.Column>
                  <Grid.Column>
                    <h2>{browserLang === "en"? "Upload History": "Historial de Cargas"}</h2>
                    <UploadHistory {...this.props} uploads={this.state.uploadedFiles}></UploadHistory>
                  </Grid.Column>
              </Grid.Row>
              {currentUser && currentUser.role === "admin" && (
              <Fragment>
                  <Grid.Row>
                    <h1>{browserLang === "en" ?  "Admin Panel" : "Tablero de Administrador"}</h1>
                  </Grid.Row>
                  <Grid.Row> 
                    <h2>{browserLang === "en" ? "Create Users": "Creador de Usuarios"}</h2>
                  </Grid.Row>
                  <Grid.Row> 
                    <AdminAuthForm adminUserId={currentUser.id} removeAdditionalField={true} signUp={true} heading={browserLang === "en" ? "Create User" : "Crear Usuario"} buttonText={browserLang === "en" ? "Create": "Crear"} errors ={{message:""}} history ={this.props.history} removeError={()=>console.log("Remove errors")}>
                    </AdminAuthForm>
                  </Grid.Row>
                  <Divider/>
                  <Grid.Row>
                    <h3>Clean up astray images</h3>
                  </Grid.Row>
                  <Grid.Row>
                    <CleanupDeletedImageIds buttonText="Clean!" apiCall={apiCall} endpoint={`${process.env.REACT_APP_PRODUCTION_SERVER_URL}/api/projects/admin/${userid}/cleanupDeletedImageIds`}/>
                  </Grid.Row>
                  <Divider></Divider>
              </Fragment>
                )}   
              
        </Grid>  
        </div>
          
      </Fragment>
      
     
    );
  }
}

export default Dashboard;