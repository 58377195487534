import React, {useState, useEffect} from 'react';
import { Segment, Button, Icon, Grid, Form, TextArea, Input} from 'semantic-ui-react';
import {apiCall} from "../services/api";

const DynamicTextInput = ({startingTextVal, objectLookupKey, onSaveChange, currentUserId,getOptionsApiUrl,primaryLabel,placeholderText,includeNull=false,optionsObjsList,getOptionsFromApi = false})=>{
	const [editMode, setEditMode] = useState(false);
	const [hovered, setHovered] = useState(false);
	const [textValue, setTextValue] = useState("");
	const [loading, setLoading] = useState(false);

	useEffect(()=>{
		setTextValue(startingTextVal);
	},[]);

	const onToggleEdit = ()=>{
		setHovered(!hovered);
	};
	
	const onOpenEditMode = async ()=>{
		setEditMode(true);
	};

	const onInputChange = (event, data)=>{
		setTextValue(data.value);
	};

	const onSave = ()=>{
		setLoading(true);
		onSaveChange(textValue);
		setLoading(false);
		setEditMode(false);
	};

	return (
		<Segment onMouseOver={onToggleEdit} textAlign='left' onMouseOut={onToggleEdit}>
			{
				editMode?
				(
					<Grid>
						<Grid.Row  verticalAlign='middle' columns={2}>
							<Grid.Column>
								<Segment basic>
									<p> {`${primaryLabel}`}</p>
								</Segment>  
							</Grid.Column>
							<Grid.Column>
								<Segment basic >
									<Input onChange={onInputChange} placeholder={`${placeholderText}`} />
								</Segment>  
							</Grid.Column>		
						</Grid.Row>
						<Grid.Row verticalAlign='middle' columns={2}>
							<Grid.Column>
								<Segment basic>
									<Button onClick={()=> setEditMode(false)} color='red'>Cancel</Button>
								</Segment>  
							</Grid.Column>
							<Grid.Column>
								<Segment basic>
									<Button loading={loading} onClick={onSave} color='green'>Save</Button>
								</Segment>  
							</Grid.Column>	
						</Grid.Row>
					</Grid>
				)
				:
				(
					<Grid>
						<Grid.Row  verticalAlign='middle' columns={2}>
							<Grid.Column>
								<Segment basic>{`${primaryLabel}`}: {`${textValue}`}</Segment>  
							</Grid.Column>
							<Grid.Column>
								<Segment basic >
									<Icon className="hoveredWidget" name="edit" onClick={onOpenEditMode}></Icon>
								</Segment>  
							</Grid.Column>		
						</Grid.Row>
					</Grid>
				)
			}
		</Segment>
		);
};

export default DynamicTextInput;