export const pciDistressCodeMapping = {
    "1": "Alligator Cracking",
    "2": "Bleeding",
    "3": "Block Cracking",
    "4": "Bumps and Sags",
    "5": "Corrugation",
    "6": "Depressions",
    "7": "Edge Cracking",
    "8": "Joint Reflection Cracking",
    "9": "Lane/Shoulder Drop-Off",
    "10": "Longitudinal and Transverse Cracking",
    "11": "Patching and Utility Cut Patching",
    "12": "Polished Aggregate",
    "13": "Potholes",
    "14": "Railroad Crossing",
    "15": "Rutting",
    "16": "Shoving",
    "17": "Slippage Cracking",
    "18": "Swelling",
    "19": "Raveling",
    "20": "Weathering"
  };

