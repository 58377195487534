import React, { useEffect, useRef, useState } from 'react';
import { Stage, Layer, Image, Circle, Line, Rect, Shape } from 'react-konva';
import AddShape from '../images/add-shape.svg';
import ClearShape from '../images/clear-shape.svg';
import { Message } from 'semantic-ui-react';

const GeneralCanvasEditor = ({ imageUrl, videoUrl, onChange, updateCalibrationData, handleSubmitCalibration, selectedUnit, fileName }) => {
    const stageRef = useRef();
    const videoRef = useRef();
    const [points, setPoints] = useState([]);
    const [selectedPointIndex, setSelectedPointIndex] = useState(null);
    const [showShape, setShowShape] = useState(false);
    const [imageObj, setImageObj] = useState(null);
    const [videoObj, setVideoObj] = useState(null);
    const [canvasWidth, setCanvasWidth] = useState(0);
    const [canvasHeight, setCanvasHeight] = useState(0);
    const [margin, setMargin] = useState({ x: 50, y: 50 });
    const [formData, setFormData] = useState({ height: '', width: '' });
    const [showForm, setShowForm] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    useEffect(() => {
        if (imageUrl) {
            const img = new window.Image();
            img.onload = () => {
                setImageObj(img);
                const aspectRatio = img.width / img.height;
                const windowAspectRatio = window.innerWidth / window.innerHeight;

                let newWidth, newHeight, marginX, marginY;

                if (aspectRatio > windowAspectRatio) {
                    newWidth = window.innerWidth - margin.x * 2;
                    newHeight = (window.innerWidth - margin.x * 2) / aspectRatio;
                    marginX = margin.x;
                    marginY = (window.innerHeight - newHeight) / 2;
                } else {
                    newWidth = (window.innerHeight - margin.y * 2) * aspectRatio;
                    newHeight = window.innerHeight - margin.y * 2;
                    marginX = (window.innerWidth - newWidth) / 2;
                    marginY = margin.y;
                }

                setCanvasWidth(newWidth);
                setCanvasHeight(newHeight);
                setMargin({ x: marginX, y: marginY });
            };
            img.src = imageUrl;
        } else if (videoUrl) {
            const video = document.createElement('video');
            video.src = videoUrl;
            video.onloadedmetadata = () => {
                setVideoObj(video);
                const aspectRatio = video.videoWidth / video.videoHeight;
                const windowAspectRatio = window.innerWidth / window.innerHeight;

                let newWidth, newHeight, marginX, marginY;

                if (aspectRatio > windowAspectRatio) {
                    newWidth = window.innerWidth - margin.x * 2;
                    newHeight = (window.innerWidth - margin.x * 2) / aspectRatio;
                    marginX = margin.x;
                    marginY = (window.innerHeight - newHeight) / 2;
                } else {
                    newWidth = (window.innerHeight - margin.y * 2) * aspectRatio;
                    newHeight = window.innerHeight - margin.y * 2;
                    marginX = (window.innerWidth - newWidth) / 2;
                    marginY = margin.y;
                }

                setCanvasWidth(newWidth);
                setCanvasHeight(newHeight);
                setMargin({ x: marginX, y: marginY });
            };
        }
    }, [imageUrl, videoUrl]);

    const handleDragMove = (index, x, y) => {
        // Restrict the points within the bounds of the image or video
        x = Math.max(0, Math.min(x, canvasWidth));
        y = Math.max(0, Math.min(y, canvasHeight));

        const newPoints = points.slice();
        newPoints[index] = { x, y };
        setPoints(newPoints);
        if (onChange) onChange(newPoints);
    };

    const handlePointMouseDown = (index) => {
        setSelectedPointIndex(index);
    };

    const handleAddShape = () => {
        setShowShape(true);
        setPoints([
            { x: canvasWidth / 4, y: canvasHeight / 4 },
            { x: (canvasWidth * 3) / 4, y: canvasHeight / 4 },
            { x: (canvasWidth * 3) / 4, y: (canvasHeight * 3) / 4 },
            { x: canvasWidth / 4, y: (canvasHeight * 3) / 4 },
        ]);
        setShowForm(true);
    };

    const handleClear = () => {
        setShowShape(false);
        setPoints([]);
        setShowForm(false);
        setErrorMessage('');
        setSuccessMessage('');
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
        if (formData.width.trim() === '' || formData.height.trim() === '') {
            setErrorMessage('The fields width and height are required.');
            setSuccessMessage('');
            return;
        }
        if (updateCalibrationData) {
            const orderedPoints = [points[0], points[1], points[3], points[2]]; // [topLeft, topRight, bottomLeft, bottomRight]
            const unit = selectedUnit || "Feet"; // or 'ft' depending on your requirements
            const imageSize = { width: imageObj.width, height: imageObj.height };
            updateCalibrationData({ fileName, points: orderedPoints, dimensions: formData, unit, imageSize });
            setSuccessMessage('Calibration Saved');
            setErrorMessage('');
            handleSubmitCalibration();
        }
    };

    const clipFunc = (ctx) => {
        ctx.beginPath();
        ctx.moveTo(points[0].x + margin.x, points[0].y + margin.y);
        points.forEach((point) => ctx.lineTo(point.x + margin.x, point.y + margin.y));
        ctx.closePath();
    };

    return (
        <div>
            <Stage width={window.innerWidth} height={window.innerHeight} ref={stageRef}>
                <Layer>
                    {imageObj && (
                        <Image
                            image={imageObj}
                            x={margin.x}
                            y={margin.y}
                            width={canvasWidth}
                            height={canvasHeight}
                            opacity={showShape ? 0.5 : 1}
                            listening={false}
                        />
                    )}
                    {videoObj && (
                        <Image
                            image={videoObj}
                            x={margin.x}
                            y={margin.y}
                            width={canvasWidth}
                            height={canvasHeight}
                            opacity={showShape ? 0.5 : 1}
                            listening={false}
                        />
                    )}
                    {showShape && (
                        <Rect
                            x={margin.x}
                            y={margin.y}
                            width={canvasWidth}
                            height={canvasHeight}
                            fill="rgba(0, 0, 0, 0.5)"
                        />
                    )}
                    {showShape && (
                        <Shape
                            sceneFunc={(ctx, shape) => {
                                clipFunc(ctx);
                                ctx.clip();
                                if (imageObj) {
                                    ctx.drawImage(
                                        imageObj,
                                        margin.x,
                                        margin.y,
                                        canvasWidth,
                                        canvasHeight
                                    );
                                }
                                if (videoObj) {
                                    ctx.drawImage(
                                        videoObj,
                                        margin.x,
                                        margin.y,
                                        canvasWidth,
                                        canvasHeight
                                    );
                                }
                                ctx.fillStrokeShape(shape);
                            }}
                        />
                    )}
                    {showShape && (
                        <Line
                            points={points.flatMap((p) => [p.x + margin.x, p.y + margin.y])}
                            stroke="#00FFF0"
                            strokeWidth={2}
                            closed
                        />
                    )}
                    {showShape && points.map((point, index) => (
                        <Circle
                            key={index}
                            x={point.x + margin.x}
                            y={point.y + margin.y}
                            radius={4}
                            fill="white"
                            draggable
                            onDragMove={(e) => handleDragMove(index, e.target.x() - margin.x, e.target.y() - margin.y)}
                            onMouseDown={() => handlePointMouseDown(index)}
                            onMouseEnter={(e) => { document.body.style.cursor = 'all-scroll'; }}
                            onMouseLeave={(e) => { document.body.style.cursor = 'default'; }}
                        />
                    ))}
                </Layer>
            </Stage>
            <div style={{ position: 'absolute', top: '50%', right: '30px', transform: 'translateY(-50%)', display: 'flex', flexDirection: 'column', gap: '10px' }}>
                <img src={AddShape} alt="Add Shape" style={{ cursor: 'pointer', width: '48px', height: '48px' }} onClick={handleAddShape} />
                <button onClick={handleClear} style={{ cursor: 'pointer', width: '48px', height: '48px', padding: 0, border: 'none', background: 'none' }}>
                    <img src={ClearShape} alt="Clear Shape" style={{ width: '100%', height: '100%' }} />
                </button>
            </div>
            {showForm && (
                <div style={{ position: 'absolute', top: '50px', left: '50%', transform: 'translateX(-50%)' }}>
                    <div className="ui card">
                        <div className="content">
                            <div className="header">Provide parameters - {selectedUnit}</div>
                        </div>
                        <div className="content">
                            <form className="ui form" onSubmit={handleFormSubmit}>
                                <div className="fields">
                                    <div className="field">
                                        <label>Width:</label>
                                        <input
                                            type="text"
                                            name="width"
                                            value={formData.width}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="field">
                                        <label>Height:</label>
                                        <input
                                            type="text"
                                            name="height"
                                            value={formData.height}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>
                                <div className="fields">
                                    <div className="field">
                                        <button className="ui button fluid" onClick={handleClear}>Delete</button>
                                    </div>
                                    <div className="field">
                                        <button className="ui teal button fluid" type="submit">Save</button>
                                    </div>
                                </div>
                                {errorMessage && (
                                    <Message negative>
                                        <Message.Header>Error</Message.Header>
                                        <p>{errorMessage}</p>
                                    </Message>
                                )}
                                {successMessage && (
                                    <Message positive>
                                        <Message.Header>Success</Message.Header>
                                        <p>{successMessage}</p>
                                    </Message>
                                )}
                            </form>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default GeneralCanvasEditor;
