import React, { useState, useEffect } from 'react';
import { Button, Message } from 'semantic-ui-react';

const LoaderButton = ({ text, onClick, color, iconName, disabled, hasPromise = false, successMessage = "Success!", disabledSeconds }) => {
  const [status, setStatus] = useState(null); // "success", "error", or null
  const [message, setMessage] = useState('');
  const [isButtonDisabled, setButtonDisabled] = useState(false);

  useEffect(() => {
    if (status === 'success' || status === 'error') {
      const timeout = setTimeout(() => {
        setStatus(null);
        setMessage('');
      }, 5000);

      return () => clearTimeout(timeout);
    }
  }, [status]);

  const showSuccessMessage = (message) => {
    setStatus('success');
    setMessage(message);
  };

  const showErrorMessage = (message) => {
    setStatus('error');
    setMessage(message);
  };

  const handleClick = async () => {
    if (isButtonDisabled) {
      return;
    }

    setButtonDisabled(true);

    try {
      await onClick();
      showSuccessMessage(successMessage);
    } catch (err) {
      console.log(err);
      showErrorMessage(err.message || 'An error occurred');
    }
  };

  useEffect(() => {
    if (isButtonDisabled) {
      const timeout = setTimeout(() => {
        setButtonDisabled(false);
      }, disabledSeconds * 1000);

      return () => clearTimeout(timeout);
    }
  }, [isButtonDisabled, disabledSeconds]);

  return (
    <>
      <Button
        color={color}
        icon={iconName}
        label={text}
        disabled={disabled || isButtonDisabled}
        onClick={handleClick}
      />
      {status === 'success' && <Message success content={message} />}
      {status === 'error' && <Message error content={message} />}
    </>
  );
};

export default LoaderButton;
