import React, { useState, useEffect } from 'react';
import { Button, Checkbox, Icon, Input, Modal } from 'semantic-ui-react';
import { apiCall } from '../../services/api';
import '../../styles/UsersStyles.css';
import GlobalToastNotification from '../global-components/GlobalToastNotification';

const ModalResetPassword = () => {
    const [open, setOpen] = useState(false);
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [showValues, setShowValues] = useState(false);
    const [errors, setErrors] = useState({
        currentPassword: '',
        newPassword: '',
        confirmNewPassword: '',
    });
    const [isFormValid, setIsFormValid] = useState(false);
    const [responseMessage, setResponseMessage] = useState('');
    const [responseApiMessage, setResponseApiMessage] = useState('');
    const [isSuccess, setIsSuccess] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [isTouched, setIsTouched] = useState({
        currentPassword: false,
        newPassword: false,
        confirmNewPassword: false,
    });

    useEffect(() => {
        if (Object.values(isTouched).every(touched => touched)) {
            validateForm();
        }
    }, [currentPassword, newPassword, confirmNewPassword, isTouched]);

    const validateForm = () => {
        const newErrors = {
            currentPassword: '',
            newPassword: '',
            confirmNewPassword: '',
        };
        let hasError = false;

        if (!currentPassword) {
            newErrors.currentPassword = 'Current password cannot be empty.';
            hasError = true;
        }

        if (newPassword !== confirmNewPassword) {
            newErrors.confirmNewPassword = 'New password and confirmation do not match.';
            hasError = true;
        }

        const passwordRegex = /^(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{8,}$/;
        if (!passwordRegex.test(newPassword)) {
            newErrors.newPassword = 'New password must be at least 8 characters long, include a digit and an uppercase letter.';
            hasError = true;
        }

        setErrors(newErrors);
        setIsFormValid(!hasError);
    };

    const handleChange = (setter) => (e) => {
        setter(e.target.value);
        setIsTouched(prev => ({ ...prev, [e.target.name]: true }));
    };

    const handleSubmit = async () => {
        if (!isFormValid) return;

        try {
            const body = {
                currentPassword,
                password: newPassword,
                confirmNewPassword,
                skipEmailWorkFlow: true
            };

            const response = await apiCall('post', `${process.env.REACT_APP_PRODUCTION_SERVER_URL}/api/auth/reset`, body);

            if (response.status === 'success') {
                setResponseApiMessage(response.message || 'Password changed successfully');
                setShowPopup(true);
                setIsSuccess(true);
                setCurrentPassword('');
                setNewPassword('');
                setConfirmNewPassword('');
                setErrors({});
                setOpen(false);

            } else {
                setResponseMessage(response.message || 'An error occurred while changing the password');
                setIsSuccess(false);
                handleCancel();
                setShowPopup(true);
            }

        } catch (error) {
            setResponseMessage(error.message || 'An error occurred while changing the password');
            setIsSuccess(false);
            handleCancel();
            setShowPopup(true);
        }

        setTimeout(() => {
            setResponseMessage('');
        }, 5000);
    };

    const handleCancel = () => {
        setCurrentPassword('');
        setNewPassword('');
        setConfirmNewPassword('');
        setErrors({});
        setIsTouched({
            currentPassword: false,
            newPassword: false,
            confirmNewPassword: false,
        });
        setOpen(false);
    };

    return (
        <>
            <Button onClick={() => setOpen(true)}>Change password</Button>
            {showPopup && (
                <GlobalToastNotification
                    message={responseMessage || responseApiMessage}
                    notificationType={isSuccess ? 'success' : 'error'}
                    position="bottomRight"
                    onClose={() => setShowPopup(false)}
                />
            )}
            <Modal
                open={open}
                onClose={() => setOpen(false)}
                size='small'
                style={{ width: '520px' }}
                closeOnDimmerClick={false}
                closeOnEscape={false}
            >
                <Modal.Header>
                    Change Password
                    <Icon
                        name='close'
                        onClick={() => setOpen(false)}
                        style={{
                            cursor: 'pointer',
                            float: 'right',
                            fontSize: '1rem',
                            fontWeight: 'thin',
                            marginRight: '10px'
                        }}
                    />
                </Modal.Header>
                <Modal.Content>
                    <p className='modal-font' style={{ marginBottom: '30px' }}>
                        Please enter your current password to verify your identity and choose a new password.
                    </p>

                    <label className='user-label'>Current Password</label>
                    <Input
                        type={showValues ? 'text' : 'password'}
                        placeholder='Current Password'
                        name="currentPassword"
                        value={currentPassword}
                        onChange={handleChange(setCurrentPassword)}
                        className={errors.currentPassword ? 'error-input' : ''}
                        style={{ marginBottom: '10px', width: '100%' }}
                        autoComplete="new-password"
                    />
                    {errors.currentPassword && (
                        <div className='error-message'>{errors.currentPassword}</div>
                    )}

                    <label className='user-label'>New Password</label>
                    <Input
                        type={showValues ? 'text' : 'password'}
                        placeholder='New Password'
                        name="newPassword"
                        value={newPassword}
                        onChange={handleChange(setNewPassword)}
                        className={errors.newPassword ? 'error-input' : ''}
                        style={{ marginBottom: '10px', width: '100%' }}
                    />
                    <div style={{ marginBottom: '30px' }}>
                        <label className='user-label'>Must be at least 8 characters. Include digit and uppercase letter.</label>
                    </div>
                    {errors.newPassword && (
                        <div className='error-message'>{errors.newPassword}</div>
                    )}
                    <label className='user-label'>Confirm New Password</label>
                    <Input
                        type={showValues ? 'text' : 'password'}
                        placeholder='Confirm New Password'
                        name="confirmNewPassword"
                        value={confirmNewPassword}
                        onChange={handleChange(setConfirmNewPassword)}
                        className={errors.confirmNewPassword ? 'error-input' : ''}
                        style={{ marginBottom: '10px', width: '100%' }}
                    />
                    {errors.confirmNewPassword && (
                        <div className='error-message'>{errors.confirmNewPassword}</div>
                    )}

                    <div style={{ marginTop: '30px' }}>
                        <Checkbox
                            label="Show passwords"
                            checked={showValues}
                            onChange={() => setShowValues(!showValues)}
                            style={{ marginBottom: '10px' }}
                        />
                    </div>
                </Modal.Content>
                <Modal.Actions>
                    <Button className='cancel-gray-button' onClick={handleCancel}>
                        Cancel
                    </Button>
                    <Button className='save-teal-button' onClick={handleSubmit} disabled={!isFormValid}>
                        Save
                    </Button>
                </Modal.Actions>
            </Modal>
        </>
    );
};

export default ModalResetPassword;
