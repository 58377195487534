import React, {useState} from 'react';
import { Segment, Button, Icon, Grid, Form, TextArea, Input, Dropdown } from 'semantic-ui-react';
import {apiCall} from "../services/api";

const AssignedLabeler = ({project, onSaveAssignment,basic})=>{
	const [editMode, setEditMode] = useState(false);
	const [hovered, setHovered] = useState(false);
	const [labelerList, setLabelerList] = useState([]);
	const [allLabelerObjs, setAllLabelerObjs] = useState([]);
	const [assignedLabeler, setAssignedLabeler] = useState("");
	const [loading, setLoading] = useState(false);
	const onToggleEdit = ()=>{
		setHovered(!hovered);
	};
	const onGetLabelers = async () =>{
		 const foundLabelers = await apiCall("GET",`${process.env.REACT_APP_PRODUCTION_SERVER_URL}/api/labeling/labelers`);
		 return foundLabelers;
	};
	const onOpenEditMode = async ()=>{
		setEditMode(true);
		const foundLabelers = await onGetLabelers();
		setAllLabelerObjs(foundLabelers);
	};
	const labelerListOptions = allLabelerObjs.map((labelerObj)=>{
		return {
			key:labelerObj._id,
			text:`${labelerObj.f_name} ${labelerObj.l_name}`,
			value:{
				_id:labelerObj._id,
				f_name:labelerObj.f_name,
				l_name:labelerObj.l_name
			}
		}
	});

	const onLabelerSelect = (event, data)=>{
		setAssignedLabeler(data.value);
	};

	const onSave = ()=>{
		setLoading(true);
		onSaveAssignment(assignedLabeler);
		setLoading(false);
		setEditMode(false);
	};
	return (
		<Segment onMouseOver={onToggleEdit} textAlign='left' onMouseOut={onToggleEdit} basic={basic}>
			{
				editMode?
				(
					<Grid>
						<Grid.Row  verticalAlign='middle' columns={2}>
							<Grid.Column>
								<Segment basic>
									<p> Assign Labeler</p>
								</Segment>  
							</Grid.Column>
							<Grid.Column>
								<Segment basic >
									<Dropdown onChange={onLabelerSelect} placeholder='Assigned Labeler' search selection options={labelerListOptions} />
								</Segment>  
							</Grid.Column>		
						</Grid.Row>
						<Grid.Row verticalAlign='middle' columns={2}>
							<Grid.Column>
								<Segment basic>
									<Button onClick={()=> setEditMode(false)} color='red'>Cancel</Button>
								</Segment>  
							</Grid.Column>
							<Grid.Column>
								<Segment basic>
									<Button loading={loading} onClick={onSave} color='green'>Save</Button>
								</Segment>  
							</Grid.Column>	
						</Grid.Row>
					</Grid>
				)
				:
				(
					<Grid>
						<Grid.Row  verticalAlign='middle' columns={2}>
							<Grid.Column>
								<Segment basic>Assigned Labeler: {"assignedLabeler" in project && project.assignedLabeler? `${project.assignedLabeler.f_name} ${project.assignedLabeler.l_name}` : "None"}</Segment>  
							</Grid.Column>
							<Grid.Column>
								<Segment basic >
									<Icon className="hoveredWidget" name="edit" onClick={onOpenEditMode}></Icon>
								</Segment>  
							</Grid.Column>		
						</Grid.Row>
					</Grid>
				)
			}
		</Segment>
		);
};

export default AssignedLabeler;