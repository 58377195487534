const getRandomInt = (max = 0) => Math.floor(Math.random() * Math.floor(max));
const getLinearInterpolatedValue = (x0, x1, y0, y1, x) => {
	const slope = (y1 - y0) / (x1 - x0);
	return slope * (x - x0) + y0;
};
const getFixedNumber = (number, digits) => Math.round(number * (10 ** digits)) / (10 ** digits);

const distanceBetweenPoints = (point1, point2) => {
	if (!Array.isArray(point1) || !Array.isArray(point2)) return 0;
	return Math.sqrt(Math.pow(point1[0] - point2[0], 2) + Math.pow(point1[1] - point2[1], 2));
};

export {
	getRandomInt,
	getLinearInterpolatedValue,
	getFixedNumber,
	distanceBetweenPoints,
};
