import React, { useState, useEffect } from 'react';

import {
    Button,
    Container,
    Divider,
    Grid,
    Icon,
    Header,
    Image,
    Message,
    Modal,
    Pagination,
    Segment,
    SegmentGroup
} from 'semantic-ui-react';

import Directory from '../../images/directory.svg';
import AnalyzeHandler from './AnalizeHandler';
import Analize from '../../images/analize.svg';
import ConfirmCalibration from '../../images/confirm-calibration.svg';
import { Link } from 'react-router-dom';

import { useCreateProjectContext } from '../../context/CreateProjectContext';

const Preview = ({ handlePrevStep, bucketName, companyId, setActiveStep }) => {

    const {
        formDataAboutContext,
        formDataConfigureContext,
        uploadedFiles,
        fileStructure,
        shpFieldFor,
        selectedFiles,
        currentPath,
        updateCurrentPath,
        currentPage,
        updateCurrentPage,
        setStepsCompleted
    } = useCreateProjectContext();

    const { selectedUnit, selectedCamera, selectedSensor, selectedLocation,selectedDataFormat,visibleLaneMarks} = formDataConfigureContext;

    const [isAnalyzing, setIsAnalyzing] = useState(false);
    const [filePreviews, setFilePreviews] = useState([]);
    const [uploadComplete, setUploadComplete] = useState(false);
    const [currentFolderPage, setCurrentFolderPage] = useState(1);
    const [currentFolderFilesPerPage] = useState(20);
    const [open, setOpen] = useState(false);
    const filesPerPage = 20;

    useEffect(() => {
        const previews = selectedFiles.map(file => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = (e) => {
                    resolve({ name: file.name, src: e.target.result });
                };
                reader.onerror = (error) => reject(error);

                if (file.type.startsWith('image/')) {
                    reader.readAsDataURL(file);
                } else if (file.type === 'text/plain' || file.type === 'application/pdf') {
                    reader.readAsDataURL(file);
                } else {
                    console.log('path_to_generic_icon.png');
                }
            });
        });

        Promise.all(previews).then(results => setFilePreviews(results));
    }, [selectedFiles]);

    const capitalize = (str) => {
        return str.replace(/\b\w/g, (char) => char.toUpperCase());
    };

    const getFileType = (fileType) => {
        switch (fileType) {
            case 'shp':
                return 'Shapefile';
            case 'shx':
                return 'Shape Index';
            case 'kml':
                return 'KML File';
            case 'csv':
                return 'CSV File';
            default:
                return 'Unknown File Type';
        }
    };

    const handleUploadComplete = () => {
        setIsAnalyzing(false);
        setUploadComplete(true);
    };

    const handleAnalyzeClick = () => {
        setOpen(true);
    };

    const handleConfirm = () => {
        setIsAnalyzing(true);
        setOpen(false);

        document.getElementById('file-upload-handler-upload-button').click();
        setStepsCompleted({
            1: false,
            2: false,
            3: false,
            4: false,
            5: false,
          });
    };

    const handleCancel = () => {
        setOpen(false);
    };

    const navigateTo = (newPath) => {
        updateCurrentPath(newPath);
        setCurrentFolderPage(1);
    };

    const handlePaginationChange = (e, { activePage }) => {
        updateCurrentPage(activePage);
    };

    const renderBreadcrumb = () => {
        return (
            <div className="breadcrumb mb-30" style={{ marginBottom: '10px' }}>
                <span className="breadcrumb-link" onClick={() => navigateTo([])}>
                    <Icon name='home' />
                </span>
                {currentPath.map((folder, index) => (
                    <span key={index}>
                        <span className="breadcrumb-separator">/</span>
                        <span className="breadcrumb-link" onClick={() => navigateTo(currentPath.slice(0, index + 1))}>{folder}</span>
                    </span>
                ))}
            </div>
        );
    };

    const renderFilesAndFolders = (structure, path) => {
        const currentFolder = path.reduce((acc, folder) => {
            if (acc && acc[folder]) {
                return acc[folder];
            } else {
                return null;
            }
        }, structure);

        if (!currentFolder) {
            return null;
        }

        const renderFolders = Object.keys(currentFolder)
            .filter(key => key !== 'files')
            .map((folder, index) => (
                <Grid.Column key={index} style={{ textAlign: 'center', flex: '0 0 auto', position: 'relative' }}>
                    <div onClick={() => navigateTo([...path, folder])} style={{ cursor: 'pointer', position: 'relative' }}>
                        <Image src={Directory} size='small' style={{ marginBottom: '10px' }} />
                        <strong>
                            <span className="folder-counter">
                                {countFilesInFolder(currentFolder[folder])} files
                            </span>
                        </strong>
                        <span style={{ display: 'block', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: '120px' }}>{folder}</span>
                    </div>
                </Grid.Column>
            ));

        const allFiles = currentFolder.files || [];
        const totalFiles = allFiles.length;
        const totalPages = Math.ceil(totalFiles / currentFolderFilesPerPage);
        const startIndex = (currentFolderPage - 1) * currentFolderFilesPerPage;
        const endIndex = startIndex + currentFolderFilesPerPage;
        const filesToRender = allFiles.slice(startIndex, endIndex);

        const renderFiles = filesToRender.map((file, index) => (
            <Grid.Column key={index} style={{ marginBottom: '10px', textAlign: 'center', position: 'relative' }}>
                {file.type.startsWith('image/') && (
                    <Image src={URL.createObjectURL(file)} size='small' rounded />
                )}
                {file.type.startsWith('video/') && (
                    <div style={{ marginBottom: '10px' }}>
                        <video width='120' height='90' controls>
                            <source src={URL.createObjectURL(file)} type={file.type} />
                            Your browser does not support the video tag.
                        </video>
                    </div>
                )}
                <span style={{ display: 'block', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: '120px' }}>{file.name}</span>
            </Grid.Column>
        ));

        return (
            <>
                <Grid>
                    <Grid.Row columns={5} style={{ justifyContent: 'flex-start' }}>
                        {renderFolders}
                    </Grid.Row>
                    <Grid.Row columns={5}>
                        {renderFiles}
                    </Grid.Row>
                </Grid>
                {totalPages > 1 && (
                    <div style={{ textAlign: 'center', marginTop: '20px', marginBottom: '20px' }}>
                        <Pagination
                            activePage={currentFolderPage}
                            onPageChange={(e, { activePage }) => setCurrentFolderPage(activePage)}
                            ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
                            firstItem={{ content: <Icon name='angle double left' />, icon: true }}
                            lastItem={{ content: <Icon name='angle double right' />, icon: true }}
                            prevItem={{ content: <Icon name='angle left' />, icon: true }}
                            nextItem={{ content: <Icon name='angle right' />, icon: true }}
                            totalPages={totalPages}
                        />
                    </div>
                )}
            </>
        );
    };

    const countFilesInFolder = (folder) => {
        return Object.keys(folder.files).length;
    };

    const renderFiles = () => {
        const startIndex = (currentPage - 1) * filesPerPage;
        const endIndex = startIndex + filesPerPage;
        const filesToRender = selectedFiles.slice(startIndex, endIndex);
        return filesToRender.map((file, fileIndex) => (
            <Grid.Column key={fileIndex} style={{ marginBottom: '10px', textAlign: 'center', position: 'relative' }}>
                {file.type.startsWith('image/') && (
                    <Image src={URL.createObjectURL(file)} size='small' rounded />
                )}
                {file.type.startsWith('video/') && (
                    <div style={{ marginBottom: '10px' }}>
                        <video width='120' height='90' controls>
                            <source src={URL.createObjectURL(file)} type={file.type} />
                            Your browser does not support the video tag.
                        </video>
                    </div>
                )}
                <span style={{ display: 'block', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: '120px' }}>{file.name}</span>
            </Grid.Column>
        ));
    };

    return (
        <>
            <Container className="inside-container">
                {!uploadComplete ? (
                    <>
                        <Container textAlign='left' style={{ width: '100%' }} className="inside-container">
                            <Message
                                content='Please check if everything is correct. After analysis, you will only be able to edit the «project name».'
                            />
                            <div className='mt-30'>
                                <Divider></Divider>
                            </div>
                            <div className='mt-30'>
                                <SegmentGroup style={{ backgroundColor: '#f8f8f9' }}>
                                    <Segment style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', backgroundColor: '#f8f8f9' }}>
                                        <Grid columns={2} style={{ width: '100%' }}>
                                            <Grid.Column textAlign='left' verticalAlign='middle'>
                                                <p style={{ marginBottom: 0 }} className="label-grey">About the project</p>
                                            </Grid.Column>
                                            <Grid.Column textAlign='right'>
                                                <Button basic onClick={() => setActiveStep(1)}><Icon name='edit' />Edit</Button>
                                            </Grid.Column>
                                        </Grid>
                                    </Segment>
                                    <Divider></Divider>
                                    <Container className="inside-container">
                                        <Segment vertical className='nmt-20'>
                                            <span className='subtitle'>
                                                Project name
                                            </span>
                                            <br />
                                            <span className='label-grey'>
                                                {capitalize(formDataAboutContext.projectName)}
                                            </span>
                                        </Segment>
                                        <Grid columns={3} stackable>
                                            <Grid.Column>
                                                <Segment vertical>
                                                    <span className='subtitle'>
                                                        Model types
                                                    </span>
                                                    <br />
                                                    <span className='label-grey'>
                                                        {formDataAboutContext.modelType}
                                                    </span>
                                                </Segment>
                                            </Grid.Column>
                                            <Grid.Column>
                                                <Segment vertical>
                                                    <span className='subtitle'>
                                                        Pavement Condition Assessment types:
                                                    </span>
                                                    <br />
                                                    <span className='label-grey'>
                                                        {Array.isArray(formDataAboutContext.selectedOptions)
                                                            ? formDataAboutContext.selectedOptions.join(', ')
                                                            : formDataAboutContext.selectedOptions}
                                                    </span>
                                                </Segment>
                                            </Grid.Column>
                                        </Grid>
                                    </Container>
                                </SegmentGroup>
                            </div>
                            <div className='mt-30'>
                                <SegmentGroup style={{ backgroundColor: '#f8f8f9' }}>
                                    <Segment style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', backgroundColor: '#f8f8f9' }}>
                                        <Grid columns={2} style={{ width: '100%' }}>
                                            <Grid.Column textAlign='left' verticalAlign='middle'>
                                                <p style={{ marginBottom: 0 }} className="label-grey">Configure Data</p>
                                            </Grid.Column>
                                            <Grid.Column textAlign='right'>
                                                <Button basic onClick={() => setActiveStep(2)}><Icon name='edit' />Edit</Button>
                                            </Grid.Column>
                                        </Grid>
                                    </Segment>
                                    <Divider></Divider>
                                    <Container className="inside-container">
                                                <Segment vertical className='nmt-20'>
                                                    <span className='subtitle'>
                                                        Selected Measuring Unit
                                                    </span>
                                                    <br />
                                                    <span className='label-grey'>
                                                        {capitalize(selectedUnit)}
                                                    </span>
                                                </Segment>
                                                <Divider hidden/>
                                                <Segment vertical className='nmt-20'>
                                                    <span className='subtitle'>
                                                        What does the data look like?
                                                    </span>
                                                    <br />
                                                    <span className='label-grey'>
                                                        {capitalize(selectedCamera)}
                                                    </span>
                                                </Segment>
                                                <Divider hidden/>
                                                <Segment vertical className='nmt-20'>
                                                    <span className='subtitle'>
                                                        Data Source
                                                    </span>
                                                    <br />
                                                    <span className='label-grey'>
                                                        {capitalize(selectedSensor)}
                                                    </span>
                                                </Segment>
                                                <Divider hidden/>
                                                <Segment vertical className='nmt-20'>
                                                    <span className='subtitle'>
                                                        Data Type
                                                    </span>
                                                    <br />
                                                    <span className='label-grey'>
                                                        {capitalize(selectedDataFormat)}
                                                    </span>
                                                </Segment>
                                                <Divider hidden/>
                                                <Segment vertical className='nmt-20'>
                                                    <span className='subtitle'>
                                                        Only Report distresses within visible lane marks
                                                    </span>
                                                    <br />
                                                    <span className='label-grey'>
                                                        {visibleLaneMarks === true ? "Yes" : "No"}
                                                    </span>
                                                </Segment>
                                                <Divider hidden/>
                                                <Segment vertical className='nmt-20'>
                                                    <span className='subtitle'>
                                                        Road distress reporting
                                                    </span>
                                                    <br />
                                                    <span className='label-grey'>
                                                        {capitalize(selectedLocation)}
                                                    </span>
                                                </Segment>
                                        <SegmentGroup>
                                            {Object.keys(uploadedFiles).map((fileType, index) => (
                                                uploadedFiles[fileType] && (
                                                    <Segment key={index}>
                                                        <span className='label-grey'>
                                                            {getFileType(fileType)}
                                                        </span>
                                                        <Segment>
                                                            <span className='label-grey' style={{ display: 'flex', alignItems: 'center' }}>
                                                                <span style={{ marginLeft: '0.5em' }}>{uploadedFiles[fileType]?.name}</span>
                                                            </span>
                                                        </Segment>
                                                    </Segment>
                                                )
                                            ))}
                                        </SegmentGroup>
                                        {/* <SegmentGroup style={{ backgroundColor: 'white', padding: '30px' }}>
                                            <p style={{ marginBottom: 0 }} className="label-grey">Metadata Settings</p>
                                            <Divider></Divider>
                                            <Grid columns={4} stackable>
                                                {Object.keys(shpFieldFor).map((field, index) => (
                                                    <Grid.Column key={index}>
                                                        <Segment vertical>
                                                            <span className='subtitle'>
                                                                {shpFieldFor[field].text}
                                                            </span>
                                                            <br />
                                                            <span className='label-grey'>
                                                                {shpFieldFor[field].value}
                                                            </span>
                                                        </Segment>
                                                    </Grid.Column>
                                                ))}
                                            </Grid>
                                        </SegmentGroup>
                                        <Divider></Divider> */}
                                    </Container>
                                </SegmentGroup>
                            </div>
                            <div className='mt-30'>
                                <SegmentGroup style={{ backgroundColor: '#f8f8f9' }}>
                                    <Segment style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', backgroundColor: '#f8f8f9' }}>
                                        <Grid columns={2} style={{ width: '100%' }}>
                                            <Grid.Column textAlign='left' verticalAlign='middle'>
                                                <p style={{ marginBottom: 0 }} className="label-grey">Upload files</p>
                                            </Grid.Column>
                                            <Grid.Column textAlign='right'>
                                                <Button basic onClick={() => setActiveStep(3)}><Icon name='edit' />Edit</Button>
                                            </Grid.Column>
                                        </Grid>
                                    </Segment>
                                    <Divider></Divider>
                                    <Container className="inside-container mt-20">
                                        {Object.keys(fileStructure).length > 0 && (
                                            <>
                                                {renderBreadcrumb()}
                                                <div>
                                                    <Header as='h3' style={{ color: '#101828' }}>{currentPath.length === 0 ? "Folders:" : "Files in folder:"}</Header>
                                                    {renderFilesAndFolders(fileStructure, currentPath)}
                                                </div>
                                            </>
                                        )}
                                        {selectedFiles.length > 0 && currentPath.length === 0 && (
                                            <div>
                                                <Header as='h3' style={{ color: '#101828' }}>Files:</Header>
                                                <Grid>
                                                    <Grid.Row columns={5}>
                                                        {renderFiles()}
                                                    </Grid.Row>
                                                </Grid>
                                                <div style={{ textAlign: 'center', marginTop: '20px', marginBottom: '20px' }}>
                                                    <Pagination
                                                        activePage={currentPage}
                                                        onPageChange={handlePaginationChange}
                                                        ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
                                                        firstItem={{ content: <Icon name='angle double left' />, icon: true }}
                                                        lastItem={{ content: <Icon name='angle double right' />, icon: true }}
                                                        prevItem={{ content: <Icon name='angle left' />, icon: true }}
                                                        nextItem={{ content: <Icon name='angle right' />, icon: true }}
                                                        totalPages={Math.ceil(selectedFiles.length / filesPerPage)}
                                                    />
                                                </div>
                                            </div>

                                        )}
                                    </Container>
                                </SegmentGroup>
                            </div>
                        </Container>
                        <div className='mt-30'>
                            <Divider />
                        </div>
                        <div className='right-aligned-container' style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                            <Button onClick={handlePrevStep} style={{ marginRight: '10px' }}>Back</Button>
                            <Button className="ui teal button" onClick={handleAnalyzeClick} loading={isAnalyzing} style={{ display: 'flex', alignItems: 'center' }}>
                                <Image src={Analize} style={{ marginRight: '10px' }} />
                                <span>Analyze</span>
                            </Button>
                            <Modal
                                open={open}
                                onClose={() => setOpen(false)}
                                closeOnDimmerClick={false}
                                size='small'
                            >
                                <Modal.Header>
                                    Ready to proceed?
                                    <Button
                                        icon
                                        className="close-button"
                                        onClick={() => setOpen(false)}
                                        style={{ float: 'right', marginTop: '-0.5em' }}
                                    >
                                    <Icon name='close' />
                                    </Button>
                                </Modal.Header>
                                <Modal.Content>
                                    <p>Now that all your data looks good, proceed to send it to our system for AI analysis.</p>
                                    <Message icon warning>
                                        <Icon name='warning circle' color='red' size='small' className='custom-small-icon' />
                                        <Message.Content>
                                            <p>Ensure that all your files are uploaded. Once the AI analyzes this project, you cannot add new files.</p>
                                            <p>Charges will apply once your data is sent.</p>
                                        </Message.Content>
                                    </Message>
                                </Modal.Content>
                                <Modal.Actions>
                                    <Button onClick={handleCancel}>
                                        Go back and check
                                    </Button>
                                    <Button className="ui teal button" onClick={handleConfirm}>
                                        Send
                                    </Button>
                                </Modal.Actions>
                            </Modal>
                        </div>
                    </>
                ) : (
                    <>
                        <Message positive>
                            <Message.Header>Congratulations!</Message.Header>
                            <p>Your files have been uploaded successfully, and the project has been created.</p>
                        </Message>
                        <div style={{ textAlign: 'center', marginTop: '20px' }}>
                            <Image src={ConfirmCalibration} size='small' centered />
                            <h2>Thank you, you will soon receive an email once your data is fully analyzed by our AI model</h2>
                            <Link to="/admin">
                                <Button className="ui teal button">Go to Admin</Button>
                            </Link>
                        </div>
                    </>
                )}
            </Container>
            <AnalyzeHandler onUploadComplete={handleUploadComplete} bucketName={bucketName} companyId={companyId} />
        </>
    );
};

export default Preview;
