import React from 'react';
import { Comment } from 'semantic-ui-react';
import moment from 'moment';

const CommentItem = ({browserLang, comment}) => {
	const {authorLogoURL, author_fname, author_lname, createdAt, text} = comment;
  const locale = browserLang === 'es' ? 'es-us' : 'en';
  return (
    <Comment>
      <Comment.Avatar src={authorLogoURL} />
      <Comment.Content>
        <Comment.Author>{`${author_fname} ${author_lname}`}</Comment.Author>
        <Comment.Metadata>
          <span>{moment(createdAt).locale(locale).fromNow()}</span>
        </Comment.Metadata>
        <Comment.Text>{text}</Comment.Text>
      </Comment.Content>
    </Comment>
	);
}
export default CommentItem;
