import React from "react";
import { Dropdown, Icon, Grid, GridColumn, Button } from "semantic-ui-react";

const MetadataLabels = (props) => {
  const [items, setItems] = props.itemsState;
  const [selectedItem, setSelectedItem] = props.selectedItemState;
  const [selectedLabels, setSelectedLabels] = React.useState([...(selectedItem.labels ?? [])]);

  const selectValue = (values) => {
    setSelectedLabels([...values]);
  }

  const saveLabels = async () => {
    const index = items.findIndex(item => item.key === selectedItem.key);
    if (index >= 0) {
      const newItems = [...items];
      newItems[index].labels = selectedLabels;
      setItems(newItems);
      setSelectedItem(undefined);
    }
  }

  return (
    <Grid >
      <GridColumn width={12} >
        <Dropdown
          search
          placeholder="Select labels"
          options={props.labels.map(label => {
            const name = label.name;
            return { key: name, value: name, text: name }
          })}
          selection
          multiple
          value={selectedLabels}
          onChange={(e, { value }) => selectValue(value)}
        />
      </GridColumn>
      <GridColumn width={4} style={{ display: "flex", flexDirection: "column", justifyContent: "flex-end" }} >
        <div>
          <Button icon labelPosition="left" onClick={saveLabels}>
            <Icon name="save" />
            Save
          </Button>
          <Button icon labelPosition="left" onClick={() => setSelectedItem(undefined)}>
            <Icon name="cancel" />
            Cancel
          </Button>
        </div>
      </GridColumn>
    </Grid>
  )
}

export default MetadataLabels;