import React, { useRef, useState, useEffect } from 'react';
import Screen from './ScreenGeneralEditor';
import VideoPlayerControl from '../videoLabel/VideoPlayer/Control/Control';
import { Message, Button } from 'semantic-ui-react';
import AddShape from '../images/add-shape.svg';
import ClearShape from '../images/clear-shape.svg';
import { Circle, Layer, Line, Shape, Stage } from 'react-konva';

const VideoCanvasEditor = ({ videoUrl, updateCalibrationData, handleSubmitCalibration, selectedUnit, fileName }) => {
    const playerRef = useRef(null);

    const [played, setPlayed] = useState(0);
    const [isPlaying, setIsPlaying] = useState(false);
    const [playbackRate, setPlaybackRate] = useState(1.0);
    const [isLoop, setIsLoop] = useState(false);
    const [width, setWidth] = useState(800);
    const [height, setHeight] = useState(450);
    const [formData, setFormData] = useState({ width: '', height: '' });
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [showShape, setShowShape] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [points, setPoints] = useState([]);
    const [cursorStyle, setCursorStyle] = useState('default');
    const [videoSize, setVideoSize] = useState({ width: 0, height: 0 });
    const [currentTime, setCurrentTime] = useState(0);
    

    useEffect(() => {
        if (playerRef.current) {
            const videoElement = playerRef.current.getInternalPlayer();
            if (videoElement) {
                videoElement.addEventListener('timeupdate', handleTimeUpdate);
                videoElement.addEventListener('loadedmetadata', handleVideoReady);
                return () => {
                    videoElement.removeEventListener('timeupdate', handleTimeUpdate);
                    videoElement.removeEventListener('loadedmetadata', handleVideoReady);
                };
            }
        }
    }, [videoUrl]);

    const handleVideoReady = () => {
        if (playerRef.current) {
            const videoElement = playerRef.current.getInternalPlayer();
            if (videoElement) {
                setWidth(videoElement.videoWidth);
                setHeight(videoElement.videoHeight);
                setVideoSize({ width: videoElement.videoWidth, height: videoElement.videoHeight });
            }
        }
    };

    const handleTimeUpdate = () => {
        if (playerRef.current) {
            const videoElement = playerRef.current.getInternalPlayer();
            if (videoElement) {
                setPlayed(videoElement.currentTime / videoElement.duration);
                setCurrentTime(videoElement.currentTime);
            }
        }
    };

    const handlePlayPause = () => {
        if (playerRef.current) {
            const videoElement = playerRef.current.getInternalPlayer();
            if (videoElement) {
                if (videoElement.paused || videoElement.ended) {
                    videoElement.play().then(() => {
                        setIsPlaying(true);
                    }).catch((error) => {
                        console.error('Failed to play video:', error);
                    });
                } else {
                    videoElement.pause();
                    setIsPlaying(false);
                    setCurrentTime(videoElement.currentTime); // Set currentTime when pausing
                }
            }
        }
    };

    const handlePlaybackRateChange = (rate) => {
        if (playerRef.current) {
            const videoElement = playerRef.current.getInternalPlayer();
            videoElement.playbackRate = rate;
            setPlaybackRate(rate);
        }
    };

    const handleLoopToggle = () => {
        if (playerRef.current) {
            const videoElement = playerRef.current.getInternalPlayer();
            videoElement.loop = !videoElement.loop;
            setIsLoop(videoElement.loop);
        }
    };

    const handleAddShape = () => {
        setShowShape(true);
        setShowForm(true);
        const centerX = width / 2;
        const centerY = height / 2;
        const sizeFactor = 0.5;
        setPoints([
            { x: centerX - (width * sizeFactor) / 4, y: centerY - (height * sizeFactor) / 4 },
            { x: centerX + (width * sizeFactor) / 4, y: centerY - (height * sizeFactor) / 4 },
            { x: centerX + (width * sizeFactor) / 4, y: centerY + (height * sizeFactor) / 4 },
            { x: centerX - (width * sizeFactor) / 4, y: centerY + (height * sizeFactor) / 4 },
        ]);
    };

    const handleClear = () => {
        setShowShape(false);
        setShowForm(false);
        setPoints([]);
        setErrorMessage('');
        setSuccessMessage('');
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
        if (formData.width.trim() === '' || formData.height.trim() === '') {
            setErrorMessage('The fields width and height are required');
            return;
        }
        const orderedPoints = [points[0], points[1], points[3], points[2]];
        const unit = selectedUnit || 'ft';
        updateCalibrationData({ fileName, points: orderedPoints, dimensions: formData, unit, videoSize, currentTime });
        handleSubmitCalibration();
        setErrorMessage('');
        setSuccessMessage('Calibration data saved successfully!');
    };

    const clipFunc = (ctx) => {
        ctx.beginPath();
        ctx.moveTo(points[0].x, points[0].y);
        points.forEach((point) => ctx.lineTo(point.x, point.y));
        ctx.closePath();
    };

    const handleDragMove = (index, x, y) => {
        const newPoints = [...points];
        newPoints[index] = { x, y };

        // Ensure the point stays within video boundaries
        if (x < 0) {
            newPoints[index].x = 0;
        } else if (x > width) {
            newPoints[index].x = width;
        }
        if (y < 0) {
            newPoints[index].y = 0;
        } else if (y > height) {
            newPoints[index].y = height;
        }

        setPoints(newPoints);
    };

    return (
        <div className="video-canvas-editor">
            <div className="video-wrapper" style={{ position: 'relative' }}>
                <Screen
                    ref={playerRef}
                    onReady={handleVideoReady}
                    url={videoUrl}
                    width={width}
                    height={height}
                    isPlaying={isPlaying}
                    isLoop={isLoop}
                    playbackRate={playbackRate}
                />
                {showShape && (
                    <Stage
                        width={width}
                        height={height}
                        style={{ position: 'absolute', top: 0, left: 0, zIndex: 1, cursor: cursorStyle }}
                    >
                        <Layer>
                            <Shape
                                sceneFunc={(ctx, shape) => {
                                    ctx.fillStyle = 'rgba(0, 0, 0, 0.5)';
                                    ctx.fillRect(0, 0, width, height);
                                    clipFunc(ctx);
                                    ctx.clip();
                                    ctx.clearRect(0, 0, width, height);
                                    ctx.fillStyle = 'transparent';
                                    ctx.fill();
                                    ctx.fillStrokeShape(shape);
                                }}
                            />
                            <Line
                                points={points.flatMap((p) => [p.x, p.y])}
                                stroke="#00FFF0"
                                strokeWidth={2}
                                closed
                            />
                            {points.map((point, index) => (
                                <Circle
                                    key={index}
                                    x={point.x}
                                    y={point.y}
                                    radius={4}
                                    fill="white"
                                    draggable
                                    onDragMove={(e) => handleDragMove(index, e.target.x(), e.target.y())}
                                    onMouseEnter={() => setCursorStyle('all-scroll')}
                                    onMouseLeave={() => setCursorStyle('default')}
                                />
                            ))}
                        </Layer>
                    </Stage>
                )}
            </div>
            <VideoPlayerControl
                isPlaying={isPlaying}
                played={played}
                playbackRate={playbackRate}
                onPlayPause={handlePlayPause}
                onPlaybackRateChange={handlePlaybackRateChange}
                onLoopToggle={handleLoopToggle}
                duration={0}
            />
            <div style={{ position: 'absolute', top: '50%', right: '30px', transform: 'translateY(-50%)', display: 'flex', flexDirection: 'column', gap: '10px', zIndex: 2 }}>
                <img src={AddShape} alt="Add Shape" style={{ cursor: 'pointer' }} onClick={handleAddShape} />
                <Button onClick={handleClear}><img src={ClearShape} alt="Clear Shape" /></Button>
            </div>
            {showForm && (
                <div style={{ position: 'absolute', top: '50px', left: '50%', transform: 'translateX(-50%)', zIndex: 2 }}>
                    <div className="ui card">
                        <div className="content">
                            <div className="header">Provide parameters</div>
                        </div>
                        <div className="content">
                            <form className="ui form" onSubmit={handleFormSubmit}>
                                <div className="fields">
                                    <div className="field">
                                        <label>Width:</label>
                                        <input
                                            type="text"
                                            name="width"
                                            value={formData.width}
                                            onChange={(e) => setFormData({ ...formData, width: e.target.value })}
                                        />
                                    </div>
                                    <div className="field">
                                        <label>Height:</label>
                                        <input
                                            type="text"
                                            name="height"
                                            value={formData.height}
                                            onChange={(e) => setFormData({ ...formData, height: e.target.value })}
                                        />
                                    </div>
                                </div>
                                <div className="fields">
                                    <Button onClick={handleClear}>Cancel</Button>
                                    <Button color="teal" type="submit">Save</Button>
                                    {errorMessage && (
                                        <Message negative>
                                            <Message.Header>Error</Message.Header>
                                            <p>{errorMessage}</p>
                                        </Message>
                                    )}
                                    {successMessage && (
                                        <Message positive>
                                            <Message.Header>Success</Message.Header>
                                            <p>{successMessage}</p>
                                        </Message>
                                    )}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default VideoCanvasEditor;
