import React, {useState,useEffect} from 'react';
import { Text , Image } from 'react-konva';

const ImageEditor = ({imageUrl,canvasWidth, canvasHeight,startingX,startingY,imgNum})=>{
	const [backgroundImage, setBackgroundImage] = useState(null);
	const [backgroundImageWidth, setBackgroundImageWidth] = useState(200);
	const [backgroundImageHeight, setBackgroundImageHeight] = useState(200);
	const [imgOriginalWidth,setImgOriginalWidth] = useState(200);
	const [imgOriginalHeight,setImgOriginalHeight] = useState(200);
	const [backgroundImageX, setBackgroundImageX] = useState(0);
	const [backgroundImageY, setBackgroundImageY] = useState(0);
	const [widthRatio, setWidthRatio] = useState(0);
	const [heightRatio, setHeightRatio] = useState(0);
	useEffect(()=>{
    const image = new window.Image();
          image.src = imageUrl;
          image.onload = () => {
            const imgWidth = image.naturalWidth;
            const imgHeight = image.naturalHeight;
            setImgOriginalWidth(imgWidth);
            setImgOriginalHeight(imgHeight);
            // console.log("imgWidth",imgWidth);
            // console.log("imgHeight",imgHeight);
            const aspectRatio = imgWidth/imgHeight;

            setBackgroundImage(image);
            //Fit in 1000 x 500

            //1900 x 800

            if(imgWidth > canvasWidth){
              //max width == canvasWidth
              setBackgroundImageWidth(canvasWidth);
              setWidthRatio(imgWidth/canvasWidth);
              //resize height
              const newHeight = canvasWidth / aspectRatio;
              setBackgroundImageHeight(newHeight);
              setHeightRatio(imgHeight/newHeight);
              const yDistance = canvasHeight - newHeight;
              setBackgroundImageY((window.innerHeight*(imgNum-1)));
              setBackgroundImageX(0);

            }else if(imgHeight > canvasHeight){
              //max height == canvasHeight
              setBackgroundImageHeight(canvasHeight);
              //resize width
              const newWidth = canvasHeight * aspectRatio;
              setBackgroundImageWidth(newWidth);
              setWidthRatio(imgWidth/newWidth);
              setHeightRatio(imgHeight/canvasHeight);

              const xDistance = canvasWidth - newWidth;
              setBackgroundImageX(xDistance/2);
              setBackgroundImageY((window.innerHeight*(imgNum-1)));
              }
            }
  },[imageUrl]);
	return(
		<>
        <Text 
            x={backgroundImageX+backgroundImageWidth}
            y={backgroundImageY}
            text={`${backgroundImageY}`}
            fontSize ={30}
            fill = 'black'
            width =  {100}
            align = 'center'
            />
		<Image image={backgroundImage} x={backgroundImageX} y={backgroundImageY} width={backgroundImageWidth} height={backgroundImageHeight}></Image>
		<Text 
            x={backgroundImageX+backgroundImageWidth+50}
            y={backgroundImageY+backgroundImageHeight}
            text={`${Math.floor(backgroundImageY+backgroundImageHeight)}`}
            fontSize ={30}
            fill = 'red'
            width =  {100}
            align = 'center'
            />
		</>

		);
};

export default ImageEditor;