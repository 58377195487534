import React, { useState, useEffect } from 'react';
import { Table, Dropdown, Button, Icon } from 'semantic-ui-react';
import {apiCall} from "../services/api";


const BASE_URL = `${process.env.REACT_APP_PRODUCTION_SERVER_URL}`;

const ProjectShortcuts = ({ dataLabels, project }) => {
  const availableLabels = dataLabels?.filter((label)=>{
    return label.id !== 'nonelabel';
  });
  const [shortcuts, setShortcuts] = useState([]);
  const [labelsInShortcuts, setLabelsInShortcuts] = useState([]);
  const [keysInShortcuts,setKeysInShortcuts] = useState([]);
  const availableKeys = [
                    { key: 'A', text: 'A', value: 'A' },
                    { key: 'B', text: 'B', value: 'B' },
                    { key: 'C', text: 'C', value: 'C' },
                    { key: 'D', text: 'D', value: 'D' },
                    { key: 'E', text: 'E', value: 'E' },
                    // { key: 'F', text: 'F', value: 'F' },
                    { key: 'G', text: 'G', value: 'G' },
                    // { key: 'H', text: 'H', value: 'H' },
                    { key: 'I', text: 'I', value: 'I' },
                    { key: 'J', text: 'J', value: 'J' },
                    { key: 'K', text: 'K', value: 'K' },
                    { key: 'L', text: 'L', value: 'L' },
                    { key: 'M', text: 'M', value: 'M' },
                    { key: 'N', text: 'N', value: 'N' },
                    { key: 'O', text: 'O', value: 'O' },
                    { key: 'P', text: 'P', value: 'P' },
                    { key: 'Q', text: 'Q', value: 'Q' },
                    // { key: 'R', text: 'R', value: 'R' },
                    { key: 'S', text: 'S', value: 'S' },
                    { key: 'T', text: 'T', value: 'T' },
                    { key: 'U', text: 'U', value: 'U' },
                    { key: 'V', text: 'V', value: 'V' },
                    { key: 'W', text: 'W', value: 'W' },
                    { key: 'X', text: 'X', value: 'X' },
                    { key: 'Y', text: 'Y', value: 'Y' },
                    { key: 'Z', text: 'Z', value: 'Z' }
                    ];
  //Preload shortcuts
  useEffect(()=>{
    if(project && ("projectConfiguration" in project) && (project.projectConfiguration) && ("shortcuts" in project.projectConfiguration) && project.projectConfiguration.shortcuts){
      const cleanedShortcuts = project.projectConfiguration.shortcuts.filter((shortcut)=> shortcut);
      const foundLabels = cleanedShortcuts.map((shortcut)=>{
        return shortcut.label
      });
      const foundKeyShortcuts = cleanedShortcuts.map((shortcut)=>{
        return shortcut.shortcut
      });
      setShortcuts(cleanedShortcuts);
      setLabelsInShortcuts([...foundLabels]);
      setKeysInShortcuts([...foundKeyShortcuts]);
    }
  },[project]);

  const handleAddRow = async () => {
     const avlLabels = availableLabels.filter((label)=>{
      return labelsInShortcuts.indexOf(label.name) < 0;  
    });
     const avKeys =  availableKeys.filter((keyObj)=>{
        return keysInShortcuts.indexOf(keyObj.value) <0;
     });
    const newShortcut = { shortcut: avKeys[0].value, label: ""};
    try{
        const updatedProject = await apiCall("POST",`${BASE_URL}/api/projects/${project._id}/keyboardShortcuts`,{
          shortcut:newShortcut
        });
        const cleanedShortcuts = updatedProject.projectConfiguration.shortcuts.filter((shortcut)=> shortcut);
        setShortcuts(cleanedShortcuts);
        setKeysInShortcuts([...keysInShortcuts,avKeys[0].value]);
      }catch(err){
        console.log(err);
      }
  };

  const handleDeleteRow = async (index) => {
    const updatedShortcuts = [...shortcuts];
    const updatedLabelsInShortcuts = [...labelsInShortcuts];
    const updatedKeysInShortcuts = [...keysInShortcuts];

     // Make API call to delete shortcut
    try{
      const updatedProject = await apiCall("DELETE",`${BASE_URL}/api/projects/${project._id}/keyboardShortcuts/${updatedShortcuts[index]._id}`);
      updatedShortcuts.splice(index, 1);
      updatedLabelsInShortcuts.splice(index,1);
      updatedKeysInShortcuts.splice(index,1);
      const cleanedShortcuts = updatedProject.projectConfiguration.shortcuts.filter((shortcut)=> shortcut);
      setShortcuts(cleanedShortcuts);
      setLabelsInShortcuts(updatedLabelsInShortcuts);
      setKeysInShortcuts(updatedKeysInShortcuts);
    }catch(err){
      console.log(err);
    }
  };

  const handleDropdownChange = async (index, field, value) => {
    const updatedShortcuts = [...shortcuts];
    updatedShortcuts[index][field] = value;
    const updatedLabelsInShortcuts = [...labelsInShortcuts];
    const updatedKeysInShortcuts = [...keysInShortcuts];
    if(field === 'label'){
      // Make API call to update shortcut - only save when a label is assigned
      updatedLabelsInShortcuts[index]=value;
      setLabelsInShortcuts(updatedLabelsInShortcuts);
    }else if(field === 'shortcut'){
      updatedKeysInShortcuts[index]=value;
      setKeysInShortcuts(updatedKeysInShortcuts);      
    }
    try{
          const updatedProject = await apiCall("PUT",`${BASE_URL}/api/projects/${project._id}/keyboardShortcuts/${updatedShortcuts[index]._id}`,{
            shortcut:updatedShortcuts[index]
          });
          const cleanedShortcuts = updatedProject.projectConfiguration.shortcuts.filter((shortcut)=> shortcut);
          setShortcuts(cleanedShortcuts);
        }catch(err){
          console.log(err);
        }
  };


  return (
    <>
      <Table celled unstackable textAlign="center">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Keyboard Shortcut</Table.HeaderCell>
            <Table.HeaderCell>Assigned Label</Table.HeaderCell>
            <Table.HeaderCell>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {shortcuts.map((shortcut, index) => (
            <Table.Row key={index}>
              <Table.Cell>
                <Dropdown
                  placeholder="Select a keyboard shortcut"
                  options={availableKeys.filter((keyObj)=>{
                    return ( (keyObj.value === shortcut.shortcut) || (keysInShortcuts.indexOf(keyObj.value)<0));
                  })}
                    selection
                    value={shortcut.shortcut}
                    onChange={(e, { value }) =>
                    handleDropdownChange(index, 'shortcut', value)
                    }
                    />
                    </Table.Cell>
                <Table.Cell>
                  <Dropdown
                    search
                    placeholder="Select a distress code"
                    options={availableLabels.map((code) => ({
                      key: code.id,
                      text: code.name,
                      value: code.name,
                    })).filter((code)=>{
                      return ( (code.value === shortcut.label) || (labelsInShortcuts.indexOf(code.value)<0));
                    })}
                    selection
                    disabled={!shortcut.shortcut}
                    value={shortcut.label}
                    onChange={(e, { value }) =>
                    handleDropdownChange(index, 'label', value)
                    }
                    />
                </Table.Cell>
                <Table.Cell>
                  <Button
                    icon
                    color="red"
                    onClick={() => handleDeleteRow(index)}
                    >
                    <Icon name="trash" />
                  </Button>
                </Table.Cell>
              </Table.Row>
                    ))}
                </Table.Body>
              </Table>
              <Button icon color="teal" labelPosition="left" onClick={handleAddRow} style={{ marginTop: '2em' }}>
               <Icon name="plus" />
                  Add Keyboard Shortcut
              </Button>
          </>
          )};

export default ProjectShortcuts;