import React, { PureComponent} from 'react';
import {
  Header,
  List,
  Label,
  Icon,
  Button,
  Form,
  Checkbox,
  Radio,
  Select,
  Divider,
  Popup
} from 'semantic-ui-react';
import { shortcuts, colors, colorMapping} from './utils';
import Hotkeys from 'react-hot-keys';

import _ from "lodash";

const headerIconStyle = { fontSize: '0.8em', float: 'right' };
export default class Sidebar extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      labelFilter:""
    };
  }

  render() {
    const {
      title,
      onSelect,
      labels,
      selected,
      toggles,
      figureToggles,
      onToggle,
      onFiguresToggle,
      filter,
      style,
      openHotkeys,
      onBack,
      onSkip,
      onSubmit,
      labelData,
      onFormChange,
      models,
      makePrediction,
      imageName,
      labeled,
      project,
      assignedLabelerName,
      onFigureVisibilityToggle,
      fileUrl,
      section,
      engineeringData
    } = this.props;
    
    let truncatedName = "";
    
    if (imageName){
      truncatedName = imageName.length > 20 ? '...' + imageName.slice(-20) : imageName;
    }
    

    const hotkeysButton = openHotkeys ? (
      <Icon
        link
        name="keyboard"
        style={headerIconStyle}
        onClick={openHotkeys}
      />
    ) : null;

    const figuresVisibility = Object.keys(figureToggles).some((key)=>{
      return figureToggles[key] === true;
    });

    const getSelectHandler = ({ type, id }) =>{
      if(type === 'bbox' || type === 'polygon' || type === 'polyline'){
        return () => onSelect(id);
      }else{
        return null;
      }
    }
    

    const labelsInImage = this.props.figures.map((figure)=>{
      // const matchingLabel = _.find(labels,{id:figure.id});
      const colorIdx = colors.indexOf(figure.color);
      const colorHex = colorMapping[colors[colorIdx]];
              return (
                  <List.Item key={figure.id} style={{cursor:"default"}} >
                     <Label key={figure.id} style={{'backgroundColor':colorHex, 'color':'white'}} horizontal/> {figure.label.name}
                     <span style={{ float: 'right' }}>
                         <Button
                            key="visibility-icon"
                            icon={ figureToggles && figureToggles[figure.id] ? 'eye' : 'eye slash'}
                            style={{ padding: 5 }}
                            onClick={e => {
                              onFiguresToggle([figure]);
                              e.stopPropagation();
                            }}
                          />
                      </span>
                  </List.Item>
                );
            });
      const openGoogleEarth = () => {
        window.open(`https://earth.google.com/web/@${engineeringData.latitude},${engineeringData.longitude}`, '_blank');
      };
    
      const openGoogleMaps = () => {
        window.open(`https://maps.google.com/?q=${engineeringData.latitude},${engineeringData.longitude}`, '_blank');
      };
    
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: '1em 0.5em',
          borderRight: '1px solid #ccc',
          height: '100%',
          minWidth:"300px",
          ...style,
        }}
      >
        <Header size="large" style={{ flex: '0 0 auto' }}>
          {title}
          {hotkeysButton}
        </Header>
        <div>
          <p> <Icon color="blue" name="user circle" /> {assignedLabelerName}</p>
          <Icon  name={labeled ? "check circle" : "cancel"} color={labeled ? "green" : "red"}/>
          <span>{labeled ? "Labeled" : "Not Labeled"}</span>
        </div>
        <div style={{ wordWrap: 'break-word', paddingTop: '10px' }}>
          <Popup
            content={imageName}
            trigger={<span><a href={fileUrl} target="_blank" rel="noopener noreferrer">{truncatedName}</a></span>}
            on='hover'
          />
        </div>

        {section && (<div style={{"wordWrap":"break-word", padding: "1px"}}>
          <span><strong>Section:</strong> {section?.name}</span>
        </div>)}
        {engineeringData && (
          <div style={{ wordWrap: 'break-word', padding: '1px' }}>
            <Popup
              on='click'
              position='top center'
              trigger={
                <span style={{ color: '#4183c4', cursor: 'pointer' }}>
                  {engineeringData.latitude}, {engineeringData.longitude}
                </span>
              }
            >
              <Button.Group vertical>
                <Button style={{ margin: '1px' }} onClick={openGoogleEarth}>Google Earth</Button>
                <Button style={{ margin: '1px' }} onClick={openGoogleMaps}>Google Maps</Button>
              </Button.Group>
            </Popup>
          </div>
        )}
        {/*<Segment basic>
                  <Input
                      onChange = {(event, typed)=>{
                        this.setState({
                          labelFilter:typed.value
                        });
                      }}
                      value={this.state.labelFilter}
                      icon={<Icon name='search' inverted circular />}
                      placeholder='Search...'
                    />
                </Segment>*/}
        <div>
          <List divided selection style={{ flex: 1, overflowY: 'auto', height:'20vh'}}>
          {this.state.labelFilter === "" ?
            labels.map((label, i) =>
              ListItem({
                shortcut: shortcuts[i],
                label,
                color: colors[i],
                colorHex:colorMapping[colors[i]],
                onSelect: getSelectHandler(label),
                selected: selected === label.id,
                disabled: filter ? !filter(label) : false,
                onToggle: onToggle,
                isToggled: toggles && toggles[label.id],
                labelData: labelData[label.id],
                onFormChange,
                models,
                makePrediction,
              }))
            :
            labels.reduce((accum, label, i)=>{
              if( _.includes(label.name,this.state.labelFilter)){
                return [...accum,  
                  ListItem({
                      shortcut: shortcuts[i],
                      label,
                      color: colors[i],
                      colorHex:colorMapping[colors[i]],
                      onSelect: getSelectHandler(label),
                      selected: selected === label.id,
                      disabled: filter ? !filter(label) : false,
                      onToggle: onToggle,
                      isToggled: toggles && toggles[label.id],
                      labelData: labelData[label.id],
                      onFormChange,
                      models,
                      makePrediction,
                    })];
              }else{
                return accum;
              }
            },[])

            // labels.filter((label, i)=>{
            //   return _.includes(label.name,this.state.labelFilter);
            // }).map((label, i) =>
            //   ListItem({
            //     shortcut: shortcuts[i],
            //     label,
            //     color: colors[i],
            //     colorHex:colorMapping[colors[i]],
            //     onSelect: getSelectHandler(label),
            //     selected: selected === label.id,
            //     disabled: filter ? !filter(label) : false,
            //     onToggle: onToggle,
            //     isToggled: toggles && toggles[label.id],
            //     labelData: labelData[label.id],
            //     onFormChange,
            //     models,
            //     makePrediction,
            //   }))
          }
          <Hotkeys keyName="esc" onKeyDown={() => onSelect(null)} />
        </List>
        <Header size="medium" style={{"wordWrap":"break-word"}}>
          Labels
        </Header>
        {labelsInImage && labelsInImage.length > 0 && (
          <p>
          Visibility <span>
            <Button
                              key="visibility-icon"
                              icon={ figuresVisibility ? 'eye' : 'eye slash'}
                              style={{ padding: 5 }}
                              onClick={e => {
                                if(onFigureVisibilityToggle){
                                  onFigureVisibilityToggle(!figuresVisibility);
                                }
                                e.stopPropagation();
                              }}
                            />
        </span>
        </p>
          )}
        <List divided selection style={{ flex: 1, overflowY: 'auto', maxHeight:'20vh'}}>
          {labelsInImage}
        </List>
        </div>
        <div style={{ flex: '0 0 auto', display: 'flex' }}>
          <Button onClick={onBack}>Back</Button>
          <span style={{ flex: 1 }} />
          <Button secondary onClick={onSkip}>
            Skip
          </Button>
          <Button primary onClick={onSubmit}>
            Submit
          </Button>
        </div>
        <Divider></Divider>
        <Button positive href={`/#/admin/${project._id}`}>Project Admin View</Button>
      </div>
    );
  }
}

const iconMapping = {
  bbox: 'object ungroup outline',
  polygon: 'edit',
  polyline: 'paint brush',
};

const typeHidable = {
  bbox: true,
  polygon: true,
  polyline: true,
  text: false,
  select: false,
  'select-one': false,
};

function ListItem({
  shortcut,
  label,
  onSelect,
  onToggle,
  color,
  selected = false,
  disabled = false,
  isToggled = false,
  labelData,
  onFormChange,
  models,
  makePrediction,
  colorHex
}) {
  const icons = [];

  if (onToggle && typeHidable[label.type]) {
    icons.push(
      <Button
        key="visibility-icon"
        icon={isToggled ? 'eye' : 'eye slash'}
        style={{ padding: 5 }}
        onClick={e => {
          onToggle(label);
          e.stopPropagation();
        }}
      />
    );
  }

  const iconType = iconMapping[label.type];
  const figureIcon = iconType ? (
    <Icon
      key="type-icon"
      name={iconType}
      style={{ opacity: 0.5, display: 'inline-block', marginLeft: 5 }}
    />
  ) : null;

  function genSublist(label) {
    const sublistStyle = { fontSize: '12px' };
    if (label.type === 'text') {
      const filteredModels = (models || []).filter(
        ({ type }) => type === 'object_classification'
      );
      const options = filteredModels.map(({ id, name }) => ({
        value: id,
        text: name,
      }));
      const fillInDOM =
        filteredModels.length > 0 ? (
          <div>
            Fill in using a model prediction:
            <Select
              options={options}
              placeholder="Select a model"
              onChange={async (e, { value }) => {
                const m = models.find(({ id }) => id === value);
                const text = (await makePrediction(m)).join(', ');
                onFormChange(label.id, [text]);
              }}
            />
          </div>
        ) : null;
      return (
        <List style={sublistStyle}>
          <List.Item>
            <Form>
              <Form.Input
                label={label.prompt}
                style={{ width: '100%' }}
                value={labelData[0] || ''}
                onChange={(e, { value }) => {
                  onFormChange(label.id, [value])
                }}
              />
              {fillInDOM}
            </Form>
          </List.Item>
        </List>
      );
    }

    if (label.type === 'select') {
      const { options } = label;
      const handleChange = function(option) {
        return (e, { checked }) =>
          onFormChange(
            label.id,
            checked
              ? labelData.concat([option])
              : labelData.filter(x => x !== option)
          );
      };

      const items = options.map(option => (
        <List.Item key={option}>
          <Checkbox
            label={option}
            checked={labelData.indexOf(option) !== -1}
            onChange={handleChange(option)}
          />
        </List.Item>
      ));
      return <List style={sublistStyle}>{items}</List>;
    }

    if (label.type === 'select-one') {
      const { options } = label;
      const items = options.map(option => (
        <List.Item key={option}>
          <Radio
            label={option}
            checked={labelData.indexOf(option) !== -1}
            onChange={(e, { checked }) => onFormChange(label.id, [option])}
          />
        </List.Item>
      ));
      return <List style={sublistStyle}>{items}</List>;
    }

    return null;
  }
  return (
    <List.Item
      onClick={onSelect}
      disabled={disabled}
      active={selected}
      key={label.id}
      style={{ fontSize: '1.3em' }}
    >
      <Hotkeys
        keyName={shortcut}
        onKeyDown={() => !disabled && onSelect && onSelect()}
      >
        <Label style={{'backgroundColor':colorHex, 'color':'white'}} horizontal>
          {shortcut}
        </Label>
        {label.name}
        {figureIcon}
        <span style={{ float: 'right' }}>{icons}</span>
        {genSublist(label)}
      </Hotkeys>
    </List.Item>
  );
}
