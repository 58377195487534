import React, { Component } from 'react';
import { Grid, Button, Loader, Header, Segment, Image, Divider } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { apiCall } from "../services/api";
import FilterSortSearchBar from "../components/projects-list-components/FilterSortSearchBar";
import ProjectCard from "../components/ProjectCard";
import LoadMore from "../components/projects-list-components/LoadMore";
import formattedStatusList from "../services/projectStatusList";
import GlobalSideBar from '../components/global-navbar/GlobalSideBar';

export default class ProjectsGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      projects: [],
      filterBy: { assignedLabelerId: null, projectStatus: null, companyIds: null },
      labelers: [],
      companies: [],
      limit: 10,
      projectsOnPage: 0,
      imgCountsPerProject: []
    };

    this.onNewProject = this.onNewProject.bind(this);
    this.getAllProjects = this.getAllProjects.bind(this);
    this.onHandleFilters = this.onHandleFilters.bind(this);
    this.setProjects = this.setProjects.bind(this);
    this.getLabelerList = this.getLabelerList.bind(this);
    this.onLoadMoreProjects = this.onLoadMoreProjects.bind(this);
    this.getStatusTextAndIcons = this.getStatusTextAndIcons.bind(this);
    this.getImageCounts = this.getImageCounts.bind(this);
    this.getVideoCounts = this.getVideoCounts.bind(this);
    this.getCompanyList = this.getCompanyList.bind(this);
  }

  async componentDidMount() {
    this._isMounted = true;
    try {
      await this.setProjects({ limit: 10 });
      const projectsCount = await apiCall("get", `${process.env.REACT_APP_PRODUCTION_SERVER_URL}/api/projects/countAll`);
      let labelers = [];
      if (this.props.currentUser && "user" in this.props.currentUser && "role" in this.props.currentUser.user && this.props.currentUser.user.role === "labeler") {
        if (this._isMounted) {
          this.setState({
            labelerIds: this.props.currentUser.user.id,
            filterBy: { assignedLabelerId: this.props.currentUser.user.id, projectStatus: null }
          });
        }
      } else if (this.props.currentUser && "user" in this.props.currentUser && "role" in this.props.currentUser.user && (this.props.currentUser.user.role === "admin" || this.props.currentUser.user.role === "labeler_lead")) {
        this.getLabelerList();
        this.getCompanyList();
      } else if (this.props.currentUser && "user" in this.props.currentUser && "role" in this.props.currentUser.user && this.props.currentUser.user.role === "client") {
        this.setState({
          filterBy: { companyIds: this.props.currentUser.user.companyIds }
        });
      }
    } catch (error) {
      if (this._isMounted) {
        this.setState({
          isLoaded: true,
          error,
        });
      }

    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onLoadMoreProjects(results) {
    const currentProjectList = this.state.projects.slice();
    const newProjectList = [...currentProjectList, ...results];
    const newProjectsCount = parseInt(newProjectList.length);
    if (this._isMounted) {
      this.setState({
        projects: newProjectList,
        projectsOnPage: newProjectsCount
      });
    }
  }

  getStatusTextAndIcons(status = "new", language = "en") {
    let statusObj = {};
    switch (status) {
      case 'importing':
        if (language === "es") {
          statusObj.text = "Importando";
        } else {
          statusObj.text = "Importing";
        }
        statusObj.icon = "sync";
        statusObj.color = "#e73b58";
        break;
      case 'new':
        if (language === "es") {
          statusObj.text = "Nuevo";
        } else {
          statusObj.text = "New";
        }
        statusObj.icon = "idea";
        statusObj.color = "#e73b58";
        break;
      case 'active':
        if (language === "es") {
          statusObj.text = "Activo";
        } else {
          statusObj.text = "Active";
        }
        statusObj.icon = "pencil alternate";
        statusObj.color = "#9c55af";
        break;
      case 'review':
        if (language === "es") {
          statusObj.text = "En Revisión";
        } else {
          statusObj.text = "In Review";
        }
        statusObj.icon = "clipboard";
        statusObj.color = "#e19264";
        break;
      case 'completed':
        if (language === "es") {
          statusObj.text = "Completo";
        } else {
          statusObj.text = "Completed";
        }
        statusObj.icon = "checkmark";
        statusObj.color = "green";
        break;
    }

    return statusObj;
  }


  async getLabelerList() {
    try {
      const foundLabelers = await apiCall("GET", `${process.env.REACT_APP_PRODUCTION_SERVER_URL}/api/labeling/labelers`);
      if (this._isMounted) {
        this.setState({
          labelers: foundLabelers
        });
      }
    } catch (err) {
      console.log(err);
    }
  }

  async getCompanyList() {
    try {
      let userId = null;
      if (this.props.currentUser && ("user" in this.props.currentUser)) {
        userId = this.props.currentUser.user.id;
      }
      if (!userId) {
        console.log("user Id not found");
        return;
      }
      if (this.props?.currentUser?.user?.role !== "admin") {
        return;
      }
      const foundCompanies = await apiCall("GET", `${process.env.REACT_APP_PRODUCTION_SERVER_URL}/api/admin/${userId}/companies`);
      if (this._isMounted) {
        this.setState({
          companies: foundCompanies
        });
      }
    } catch (err) {
      console.log(err);
    }
  }

  async setProjects(query = {}) {
    try {
      let projects = [];
      if (this.props.currentUser && "user" in this.props.currentUser && "role" in this.props.currentUser.user && this.props.currentUser.user.role === "labeler") {
        projects = await this.getAllProjects({ assignedLabelerId: this.props.currentUser.user.id });
      } else if (this.props.currentUser && "user" in this.props.currentUser && "role" in this.props.currentUser.user && (this.props.currentUser.user.role === "admin" || this.props.currentUser.user.role === "labeler_lead")) {
        projects = await this.getAllProjects(query);
      } else if (this.props.currentUser && "user" in this.props.currentUser && "role" in this.props.currentUser.user && this.props.currentUser.user.role === "client" && this.props.currentUser.user.companyIds) {
        projects = await this.getAllProjects({
          companyIds: this.props.currentUser.user.companyIds || []
        });
      }
      //console.log("projects",projects);
      if (projects.error && projects.status === 401) {
        window.location = '/#/signin';
        return;
      }
      if (this._isMounted) {
        this.setState({
          isLoaded: true,
          projects,
          projectsOnPage: projects.length
        });
      }
    } catch (error) {
      console.log(error);
      if (this._isMounted) {
        this.setState({
          isLoaded: true,
          error,
        });
        window.location = '/#/admin/login/';
      }
    }
  }

  async getAllProjects(query = {}) {
    let params = {};
    if ("assignedLabelerId" in query && query.assignedLabelerId) {
      params.assignedLabelerId = query.assignedLabelerId;
    }
    if ("projectStatus" in query && query.projectStatus) {
      params.projectStatus = query.projectStatus;
    }

    if ("limit" in query && query.limit) {
      params.limit = query.limit;
    }

    if ("companyIds" in query && query.companyIds) {
      params.companyIds = query.companyIds;
    }

    const projects = await apiCall("get", `${process.env.REACT_APP_PRODUCTION_SERVER_URL}/api/projects/`, { params });
    return projects;
  }

  async getImageCounts(projectId) {
    if (!projectId) {
      console.log("A project Id is needed");
      return;
    }
    let params = { labeled: true };

    const imageCounters = await apiCall("GET", `${process.env.REACT_APP_PRODUCTION_SERVER_URL}/api/images/countAllForProject/${projectId}`, { params });
    let imgCountsPerProject = this.state.imgCountsPerProject.slice();
    const newProject = {
      projectId: projectId,
      imagesCount: imageCounters.count,
      labelsCount: imageCounters.labelsCount
    }
    const projectIndex = imgCountsPerProject.findIndex((countObj) => countObj.projectId === projectId);
    if (projectIndex < 0) {
      imgCountsPerProject.push(newProject);
    } else {
      imgCountsPerProject[projectIndex] = newProject
    }
    this.setState({
      imgCountsPerProject
    });
  }

  async getVideoCounts(projectId) {
    if (!projectId) {
      console.log("A project Id is needed");
      return;
    }
    let params = { labeled: true };

    const videoCounters = await apiCall("GET", `${process.env.REACT_APP_PRODUCTION_SERVER_URL}/api/videos/countAllForProject/${projectId}`, { params });
    let imgCountsPerProject = this.state.imgCountsPerProject.slice();
    const newProject = {
      projectId: projectId,
      imagesCount: videoCounters.count,
      labelsCount: videoCounters.labelsCount
    }
    const projectIndex = imgCountsPerProject.findIndex((countObj) => countObj.projectId === projectId);
    if (projectIndex < 0) {
      imgCountsPerProject.push(newProject);
    } else {
      imgCountsPerProject[projectIndex] = newProject
    }
    this.setState({
      imgCountsPerProject
    });
  }

  async onNewProject() {	
    const {currentUser } = this.props;	
    let currentUserId = null;	
    let companyId = null;	
    if (currentUser && "user" in this.props.currentUser) {	
      currentUserId = this.props.currentUser.user.id;	
    }	
    if(currentUser?.user?.companyIds && currentUser?.user?.companyIds.length > 0){	
      companyId = currentUser?.user?.companyIds[0];	
    }	
    const newProject = await apiCall("post", `${process.env.REACT_APP_PRODUCTION_SERVER_URL}/api/projects`, { createdBy: currentUserId, companyId:companyId,status:"new" });	
    if (this._isMounted) {	
      this.setState({	
        projects: this.state.projects.concat([newProject]),	
      });	
    }	
  }

  onHandleFilters(filterObj) {
    this.setState({
      isLoaded: false
    })
    let filterBy = this.state.filterBy;

    if ("assignedLabelerId" in filterObj) {
      if (filterObj.assignedLabelerId === "all") {
        filterBy.assignedLabelerId = null;
      } else {
        filterBy.assignedLabelerId = filterObj.assignedLabelerId;
      }
    }
    if ("projectStatus" in filterObj) {
      if (filterBy.projectStatus === "all") {
        filterBy.projectStatus = null;
      } else {
        filterBy.projectStatus = filterObj.projectStatus;
      }
    }
    if ("companyIds" in filterObj) {
      if (filterBy.companyIds === "all") {
        filterBy.companyIds = null;
      } else {
        filterBy.companyIds = filterObj.companyIds;
      }
    }
    this.setProjects(filterBy);
  }

  render() {
    const { error, isLoaded, projects } = this.state;
    const { linkPrefix, newButton, title, language } = this.props;
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <Loader active inline="centered" />;
    }

    // const renderProjectCard = project => {
    //   const imagesCount = 0;
    //   const labelsCount = 0;
    //   const { name, form,status } = project;
    //   // const statusObj = getStatusTextAndIcons(status, language);
    //   const id = project._id;
    //   let createdBy = "createdBy" in project? `Created by: ${project.createdBy.f_name} ${project.createdBy.l_name}.` : "";
    //   if(language === "es"){
    //       createdBy = "createdBy" in project? `Creado por: ${project.createdBy.f_name} ${project.createdBy.l_name}.` : "";
    //   }
    //   const info = `${createdBy} ${imagesCount} ${language === "es" ? "imágenes": "images"}, ${labelsCount} ${language === "es" ? "etiquetadas": "labeled"}`;
    //   const desc = <div>
    //                   <div style={{padding:"5px"}}>
    //                     <Icon name="user circle"/> {language === "es" ?  "Etiquetador Asignado:" : "Assigned Labeler:"} {"assignedLabeler" in project ? `${project.assignedLabeler.f_name} ${project.assignedLabeler.l_name}` : ""}
    //                   </div>
    //                   <Label style={{backgroundColor:statusObj.color, color:"white", padding:"5px"}}>
    //                     {/*language === "es" ?  "Estado:" : "Status:"*/} {statusObj.text}
    //                   </Label> 
    //                 </div>;

    //   return (
    //     <Grid.Column key={id}>
    //       <a href={`/#${linkPrefix}${id}`}>
    //         <Card fluid link header={name} meta={info} description={desc} className="projectCard" />
    //       </a>
    //     </Grid.Column>
    //   );
    // };

    const renderedButton = newButton ? (
      <Link to="/admin/create-project" style={{ textDecoration: 'none' }}>
        {process.env.REACT_APP_USE_SIDEBAR === 'true' ? (
            <Link to="/admin/create-project" style={{ textDecoration: 'none', width: '100%' }}>
            <Button style={{ width: '100%', padding: '1.5em', fontSize: '1.5em' }}>
              <span style={{ fontSize: '2.5em' }}>+</span>
              <div>Make a new project</div>
            </Button>
          </Link>
        ) : (
          <Button style={{ padding: '1.5em' }}>
            <span style={{ fontSize: '4em' }}>+</span>
            <div>Make a new project</div>
          </Button>)
        }
      </Link>
    ) : null;

    const clientRenderedButton = newButton ? (	
      <>
      {process.env.REACT_APP_USE_SIDEBAR === 'true' ? 
        (
          <Button style={{ width: '100%', fontSize: '1.5em' }} onClick={this.onNewProject}>	
          <span style={{ fontSize: '4em' }}>+</span>	
          <div>Make a new project</div>	
        </Button>	
        )
        :
        (
          <Button style={{ padding: '1.5em' }} onClick={this.onNewProject}>	
          <span style={{ fontSize: '4em' }}>+</span>	
          <div>Make a new project</div>	
        </Button>	
        )

      }
      </>
      ) : null;

    let filteredProjects = projects.slice();
    const projectCards = filteredProjects.map((project) => {
      let cardLink = `${linkPrefix}${project?._id}`;
      if ((project?.projectReportJSONURL) && (linkPrefix.indexOf("admin") < 0)) {
        cardLink = `/projects/${project?._id}/Visualize`
      }
      return (
        <ProjectCard key={project._id} userRole={(this.props.currentUser && "user" in this.props.currentUser && "role" in this.props.currentUser.user) ? this.props.currentUser.user.role : null} project={project} getImageCounts={this.getImageCounts} getVideoCounts={this.getVideoCounts} getStatusTextAndIcons={this.getStatusTextAndIcons} language={language} linkPrefix={cardLink} imgCountsPerProject={this.state.imgCountsPerProject} />
      )
    });

    const formattedLabelerList = this.state.labelers.map((labelerObj) => {
      return {
        text: `${labelerObj.f_name} ${labelerObj.l_name}`,
        value: labelerObj._id
      };
    });

    const formattedCompanyList = this.state.companies.map((company) => {
      return {
        text: `${company?.name}`,
        value: company._id
      };
    });

    const statusList = formattedStatusList(language);
    const formattedProjectStatusList = statusList.map((statusObj) => {
      return {
        text: statusObj.text,
        value: statusObj.value
      };
    });

    const filterBy = [
      {
        filterLabel: "Labeler",
        filterKey: "assignedLabelerId",
        options: formattedLabelerList,
        currentValue: this.state.filterBy.assignedLabelerId,
        multiple: true
      },
      {
        filterLabel: "Status",
        filterKey: "projectStatus",
        options: formattedProjectStatusList,
        currentValue: this.state.filterBy.projectStatus,
        multiple: true
      },
      {
        filterLabel: "Company",
        filterKey: "companyIds",
        options: formattedCompanyList,
        currentValue: this.state.filterBy.companyIds,
        multiple: true
      }
    ];
    return (
      <>
        {process.env.REACT_APP_USE_SIDEBAR === 'true' ? (
          <div style={{ display: 'flex', height: '100vh', overflow: 'hidden', margin: '0 0px 0px 20px'}}>
            <div style={{ flexShrink: 0 }}>
              <GlobalSideBar currentUser={this.props.currentUser} />
            </div>

            <div style={{ flex: 1, padding: '1em', overflowY: 'auto', boxSizing: 'border-box', zIndex: '500' }}>

              {this.props.currentUser && "user" in this.props.currentUser && "role" in this.props.currentUser.user && this.props.currentUser.user.role === "admin" ?
                <FilterSortSearchBar browserLang={language} filterBy={filterBy} onSelectionChange={this.onHandleFilters} />
                :
                null
              }
              <Header
                as="h1"
                style={{ textAlign: process.env.REACT_APP_USE_SIDEBAR === 'true' ? 'left' : 'center' }}
              >
                {title}
              </Header>
              <Grid stackable columns={3} textAlign="left" style={{ display: 'flex', textAlign: 'left',minHeight:"10px",height:"auto", alignContent:"flex-start"}}>
                {projectCards}
                {this.props.currentUser && "user" in this.props.currentUser && "role" in this.props.currentUser.user && this.props.currentUser.user.role === "admin" ?
                  <Grid.Column>
                    <div style={{ width: '100%' }}>
                      {renderedButton}
                    </div>
                  </Grid.Column>
                  :
                  <Grid.Column>{clientRenderedButton}</Grid.Column>
                }
              </Grid>

              {filteredProjects.length < 1 && (
                <Segment style={{ top: "-700px" }}>
                  {language === "es" ? "No hay proyectos asignados aún." : "No projects assigned to you yet."}
                  <Image src="https://res.cloudinary.com/dqwgbheqj/image/upload/v1631249910/Illustration_7_qg7d8y.png" />
                </Segment>
              )}
              <Divider hidden />
              <div>
                <LoadMore
                  browserLang={language}
                  buttonText={language === "es" ? "Cargar Más" : "Load More"}
                  baseURL={`${process.env.REACT_APP_PRODUCTION_SERVER_URL}`}
                  getMorePath={`api/projects`}
                  countAllPath={`api/projects/countAll`}
                  query={this.state.filterBy}
                  method="get"
                  onLoadMore={this.onLoadMoreProjects}
                  color="teal"
                  loadedResults={this.state.projectsOnPage}
                />
              </div>
            </div>
          </div>
        ) : (
          <div>
            {this.props.currentUser && "user" in this.props.currentUser && "role" in this.props.currentUser.user && this.props.currentUser.user.role === "admin" ?
              <FilterSortSearchBar browserLang={language} filterBy={filterBy} onSelectionChange={this.onHandleFilters} />
              :
              null
            }
            <Header as="h1">{title}</Header>
            <Grid stackable columns={2}>
              {projectCards}
              {this.props.currentUser && "user" in this.props.currentUser && "role" in this.props.currentUser.user && this.props.currentUser.user.role === "admin" ?
                <Grid.Column>{renderedButton}</Grid.Column>
                :
                <Grid.Column>{clientRenderedButton}</Grid.Column>
              }
            </Grid>
            {filteredProjects.length < 1 && (
              <Segment style={{ marginTop: "24px" }}>
                {language == "es" ? "No hay proyectos asignados aún." : "No projects assigned to you yet."}
                <Image src="https://res.cloudinary.com/dqwgbheqj/image/upload/v1631249910/Illustration_7_qg7d8y.png" />
              </Segment>
            )}
            <Divider hidden></Divider>
            <LoadMore browserLang={language} buttonText={language === "es" ? "Cargar Más" : "Load More"} baseURL={`${process.env.REACT_APP_PRODUCTION_SERVER_URL}`} getMorePath={`api/projects`} countAllPath={`api/projects/countAll`} query={this.state.filterBy} method="get" onLoadMore={this.onLoadMoreProjects} color="teal" loadedResults={this.state.projectsOnPage} />
          </div>
        )}

      </>
    );
  }
}

