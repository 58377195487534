import React, {Fragment, useState} from 'react';
import {Segment,Icon, Button} from 'semantic-ui-react';
import {orderedLabels} from "../services/defaultLabels";



const DamageItem = ({label, width, height,onDelete, id}) => {
	const [editMode, setEditMode] = useState(false);
	const [hovered, setHovered] = useState(false);
	const [viewDetailsMode,setViewDetailsMode] = useState(false);

	let allLabels = orderedLabels.map((labelObj)=>{
    	return {text: labelObj.name, value:labelObj.name};
    });

    const handleDelete = ()=>{
    	if(onDelete){
    		onDelete(id);
    	}
    };
	return (
		<Fragment>
			<Segment className="damageCard">
					<div>
						<p style={{cursor:"pointer"}} onClick={()=>setViewDetailsMode(!viewDetailsMode)}>{`${label}`} <Icon name={`angle ${viewDetailsMode ? "down" : "right"}`}/></p>
						<Icon style={{cursor:"pointer",position:"absolute","top":0,right:0}} name="cancel" color="red" onClick={()=>handleDelete()}/>
						{viewDetailsMode && (
							<>
							<p>Width: {width}</p>
							<p>Height: {height}</p>
							</>
							)}
					</div>
				<div>
					{editMode && (<Button.Group>
					    <Button color="green" onClick={()=>setEditMode(false)}>Save</Button>
					  </Button.Group>)}
				</div>
			</Segment>	
		</Fragment>
		);
}

export default DamageItem;