import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { normalize, denormalize, schema } from 'normalizr';
import { Button, Icon } from 'semantic-ui-react';
import PopupDialog from '../PopupDialog/PopupDialog';
import { highContrastingColors as colors } from '../../services/utils/colorUtils';
import { getRandomInt, getFixedNumber } from '../utils/mathUtils';
import { genId, colors as labelsColors } from '../../label/utils';
import { UndoRedo } from '../../models/UndoRedo';
import { Rectangle } from '../../models/rectangle';
import { Incident, SPLIT, HIDE, SHOW, BBOX } from '../../models/incident';
import TwoDimensionalVideoContext from './twoDimensionalVideoContext';
import { getInterpolatedData, INTERPOLATION_TYPE } from '../utils/interpolationUtils';
import Preview from '../Preview/Preview';
import Review from '../Review/Review';
import AnnotationList from '../AnnotationList/AnnotationList';
import DrawableVideoPlayer from '../DrawableVideoPlayer/DrawableVideoPlayer';
import { getLastAnnotationLabel, getUniqueKey } from '../utils/utils';
import './twoDimensionalVideo.css';
import Hotkeys from 'react-hot-keys';
import {apiCall} from "../../services/api";
import ReplaceLabelModal from '../../label/ReplaceLabelModal';
import { VIDEO_PLAYER_WIDTH, getVideoOffset } from '../utils/canvas';

class TwoDimensionalVideo extends Component {
  constructor(props) {
    super(props);
    const {
      defaultAnnotations,
      previewHeader,
      previewNoticeList
    } = props;
    /* ===  normalize annotation props === */
    const entities = { annotations: {} };
    let annotations = [];
    if (defaultAnnotations && defaultAnnotations.length !== 0) {
      const annotation = new schema.Entity('annotations');
      const normalizedAnn = normalize(defaultAnnotations, [annotation]);
      entities.annotations = normalizedAnn.entities.annotations;
      annotations = normalizedAnn.result;
      annotations.forEach((id) => {
        entities.annotations[id].isManipulatable = props.isDefaultAnnotationsManipulatable;
      });
    }
    this.state = {
      isPreviewed: previewNoticeList && previewNoticeList.length === 0 && !previewHeader,
      isSubmitted: false,
      videoWidth: VIDEO_PLAYER_WIDTH,
      videoHeight: undefined,
      annotationWidth: undefined,
      annotationHeight: undefined,
      entities,
      annotations,
      labelMetadata: this.props.labelMetadata,
      played: 0,
      isPlaying: false,
      playbackRate: 1,
      duration: 0,
      isLoop: false,
      isSeeking: false,
      isAdding: false,
      focusing: '',
      isDialogOpen: false,
      dialogTitle: '',
      dialogMessage: '',
      defaultNumAnnotations: annotations.length,
      defaultNumRootAnnotations: getLastAnnotationLabel(annotations, entities),
      isPanning:false
    };
    this.UndoRedoState = new UndoRedo();
    this.addAnnotation = this.addAnnotation.bind(this);
    this.updateAnnotation = this.updateAnnotation.bind(this);
    this.deleteAnnotation = this.deleteAnnotation.bind(this);
    this.addIncident = this.addIncident.bind(this);
    this.updateIncident = this.updateIncident.bind(this);
    this.deleteIncident = this.deleteIncident.bind(this);
    this.updateMetadata = this.updateMetadata.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
  }

  componentDidMount(){
    const {video} = this.props;
    const {annotations} = video;
    const annotationIds = annotations.map((ann)=>{
      return ann?.id;
    });
    let entityAnnotations = {};
    for(let ann of annotations){
      entityAnnotations[ann?.id] = Rectangle({...ann,isManipulatable:true})
    }
    this.setState({
      entities:{
        annotations:entityAnnotations
      },
      annotations:annotationIds
    });
    /* Hearing for viewport size changes */
    window.addEventListener('resize', this.handleResize);
  }

  componentDidUpdate(prevProps,prevState){
    const {selectedLabel} = this.props;
    if(prevProps.selectedLabel !== selectedLabel){ 
      if(selectedLabel){
        this.setState({
          isAdding:true
        })
      }
    }
    if (prevState.videoHeight === undefined && this.state.videoHeight !== undefined) {
      this.handleResize();
    }

  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    const { videoWidth, videoHeight } = getVideoOffset(this.state.videoWidth, this.state.videoHeight, window.innerHeight);
    this.setState({ videoWidth, videoHeight });
  };

  /* ==================== video player ==================== */
  handlePlayerRef = (player) => {
    this.player = player;
  }

  handleVideoReady = () => {
    const videoPlayer = document.getElementById('react-player').children[0];
    const { clientWidth, clientHeight } = videoPlayer;
    const ratio = clientWidth / clientHeight;
    this.setState({ annotationWidth: clientWidth, annotationHeight: clientHeight, videoHeight: VIDEO_PLAYER_WIDTH / ratio });
    const video = this.props.video;
    if (!video.attributes?.width) {
      this.updateVideo({...video, attributes: {width: clientWidth, height: clientHeight}});
    }
  }

  handleVideoProgress = (state) => {
    const { played } = state;
    this.setState((prevState) => {
      if (prevState.isSeeking) return null;
      return { played: getFixedNumber(played, 5) };
    });
  }

  handleNextFrame = () => {
  this.setState(prevState => {
    const baseIncrementValue = 0.1;  // You can adjust this value based on your needs
    const increment = baseIncrementValue / (this.state.duration + 1);
    const nextPlayed = Math.min(prevState.played + increment, 1);
    return { played: nextPlayed, isPlaying: false };
  }, () => this.player.seekTo(this.state.played));
};


  handlePreviousFrame = () => {
  this.setState(prevState => {
    const baseDecrementValue = 0.1;  // You can adjust this value based on your needs
    const decrement = baseDecrementValue / (this.state.duration + 1); 
    const prevPlayed = Math.max(prevState.played - decrement, 0);
    return { played: prevPlayed, isPlaying: false };
  }, () => this.player.seekTo(this.state.played));
};

  handleVideoDuration = (duration) => {
    this.setState({ duration });
  }

  handleVideoEnded = () => {
    this.setState(prevState => ({ isPlaying: prevState.isLoop }));
  }

  handleVideoRewind = () => {
    this.setState({ isPlaying: false, played: 0 });
    this.player.seekTo(0);
  }

  handleVideoPlayPause = () => {
    this.setState(prevState => ({ isPlaying: !prevState.isPlaying }));
  }

  handleVideoSpeedChange = (s) => {
    this.setState({ playbackRate: s });
  }

  handleVideoSliderMouseUp = () => {
    this.setState({ isSeeking: false });
  }

  handleVideoSliderMouseDown = () => {
    this.setState({ isPlaying: false, isSeeking: true });
  }

  handleVideoSliderChange = (e) => {
    const played = getFixedNumber(e.target.value, 5);
    this.setState((prevState) => {
      const { entities } = prevState;
      let { focusing } = prevState;
      if (focusing) {
        const { incidents } = entities.annotations[focusing];
        for (let i = 0; i < incidents.length; i += 1) {
          if (played >= incidents[i].time) {
            if (i !== incidents.length - 1 && played >= incidents[i + 1].time) continue;
            if (incidents[i].status !== SHOW) focusing = '';
            break;
          } else if (i === incidents.length - 1) focusing = '';
        }
      }
      return { played, focusing };
    }, () => { this.player.seekTo(played); });
  }

  /* ==================== canvas ==================== */

  handleCanvasStageMouseDown = (e) => {
    const { isAdding } = this.state;
    const {selectedLabel,labels} = this.props;
    if (!isAdding) return;
    const stage = e.target.getStage();
    const position = stage.getPointerPosition();
    const uniqueKey = getUniqueKey();
     const labelIdx = labels.findIndex(label => label.id === selectedLabel);  
    const color = labelsColors[labelIdx];   
    let selectedLabelObj = null; 
    if(selectedLabel){  
      selectedLabelObj = labels.find((label)=>{ 
        return label.id === selectedLabel;  
      }); 
    }
    this.setState((prevState) => {
      this.UndoRedoState.save({ ...prevState, isAdding: false }); // Undo/Redo
      const {
        annotations, entities,
      } = prevState;
      const incidents = [];
      incidents.push(Incident({
        id: `${uniqueKey}`, 
        name: `${uniqueKey}`, 
        x: position.x, 
        y: position.y, 
        height: 1, 
        width: 1, 
        time: prevState.played,
        label:selectedLabelObj?.name,
        type:BBOX
      }));
      entities.annotations[`${uniqueKey}`] = Rectangle({
        id: `${uniqueKey}`, 
        name: `${uniqueKey}`, 
        label:selectedLabelObj?.name|| getLastAnnotationLabel(annotations, entities),
        type:BBOX, 
        color, 
        incidents,
      });
      this.props.handleSelected(null);
      return {
        isAdding: false,
        focusing: `${uniqueKey}`,
        annotations: [...annotations, `${uniqueKey}`],
        entities: { ...entities, annotations: entities.annotations },
      };
    }, async () => {
      const {
        annotations, entities,
      } = this.state;
      const group = stage.findOne(`.${uniqueKey}`);
      if (group) {
          const bottomRight = group?.findOne('.bottomRight');
          if (bottomRight) {
              group.moveToTop();
              bottomRight.moveToTop();
              bottomRight.startDrag();
          } else {
              console.log("Could not find bottomRight in group");
          }
      } else {
          console.log("Could not find group with uniqueKey:", uniqueKey);
      }
      await this.addAnnotation({
        id: `${uniqueKey}`, 
        name: `${uniqueKey}`, 
        label: selectedLabelObj?.name || getLastAnnotationLabel(annotations, entities),
        type: BBOX, 
        color, 
      });
      this.addIncident({
        id: `${uniqueKey}`, 
        name: `${uniqueKey}`, 
        x: position.x, 
        y: position.y, 
        height: 1, 
        width: 1, 
        time: this.state.played,
        label: selectedLabelObj?.name,
        type: BBOX,
        status: "Show"
      }, uniqueKey);
    });
  }

  handleCanvasGroupMouseDown = (e) => {
    const group = e.target.findAncestor('Group');
    this.setState({ isPlaying: false, focusing: group.name() });
  }

 handleCanvasGroupDragEnd = (e) => {
    if (e.target.getClassName() !== 'Group') return;
    const group = e.target;
    const rect = group.findOne('Rect');
    const position = group.position();
    const uniqueKey = getUniqueKey();
    this.setState((prevState) => {
      this.UndoRedoState.save(prevState);
      const { entities, played } = prevState;
      const annotationId = group.name();
      const { incidents } = entities.annotations[group.name()];
      for (let i = 0; i < incidents.length; i += 1) {
        if (played >= incidents[i].time) {
          // skip elapsed incidents
          if (i !== incidents.length - 1 && played >= incidents[i + 1].time) continue;
          if (played === incidents[i].time) {
            incidents[i].x = position.x; incidents[i].y = position.y; incidents[i].width = rect.width(); incidents[i].height = rect.height();
            this.updateIncident(incidents[i],annotationId,incidents[i].id);
            break;
          }
          if (i === incidents.length - 1) {
            this.addIncident({
              id: `${uniqueKey}`, name: `${uniqueKey}`, x: position.x, y: position.y, width: rect.width(), height: rect.height(), time: played,status:"Show"
            },annotationId);
            incidents.push(Incident({
              id: `${uniqueKey}`, name: `${uniqueKey}`, x: position.x, y: position.y, width: rect.width(), height: rect.height(), time: played,
            }));
            break;
          }
          this.addIncidentAtPosition({
            id: `${uniqueKey}`, name: `${uniqueKey}`, x: position.x, y: position.y, height: rect.height(), width: rect.width(), time: played,index:i + 1,status:"Show"
          },annotationId);
          incidents.splice(i + 1, 0, Incident({
            id: `${uniqueKey}`, name: `${uniqueKey}`, x: position.x, y: position.y, height: rect.height(), width: rect.width(), time: played,
          }));

          break;
        }
      }
      return {};
    });
  }

  handleCanvasDotMouseDown = (e) => {
    const group = e.target.findAncestor('Group');
    this.setState({ focusing: group.name() });
  }

  handleCanvasDotDragEnd = (e) => {
    const activeAnchor = e.target;
    const group = activeAnchor.getParent();
    const uniqueKey = getUniqueKey();
    group.draggable(true);
    const topLeft = group.findOne('.topLeft'); 
    const topRight = group.findOne('.topRight'); 
    const bottomRight = group.findOne('.bottomRight'); 
    const bottomLeft = group.findOne('.bottomLeft');
    const maxX = Math.max(topLeft.getAbsolutePosition().x, topRight.getAbsolutePosition().x, bottomRight.getAbsolutePosition().x, bottomLeft.getAbsolutePosition().x);
    const minX = Math.min(topLeft.getAbsolutePosition().x, topRight.getAbsolutePosition().x, bottomRight.getAbsolutePosition().x, bottomLeft.getAbsolutePosition().x);
    const maxY = Math.max(topLeft.getAbsolutePosition().y, topRight.getAbsolutePosition().y, bottomRight.getAbsolutePosition().y, bottomLeft.getAbsolutePosition().y);
    const minY = Math.min(topLeft.getAbsolutePosition().y, topRight.getAbsolutePosition().y, bottomRight.getAbsolutePosition().y, bottomLeft.getAbsolutePosition().y);
    this.setState((prevState, props) => {
      this.UndoRedoState.save(prevState);
      const { entities, played } = prevState;
      const { annotations } = entities;
      const annotationId = group.name();
      const { incidents } = entities.annotations[group.name()];
      for (let i = 0; i < incidents.length; i += 1) {
        if (played >= incidents[i].time) {
          // skip elapsed incidents
          if (i !== incidents.length - 1 && played >= incidents[i + 1].time) continue;
          if (played === incidents[i].time) {
            incidents[i].x = minX; incidents[i].y = minY; incidents[i].height = maxY - minY; incidents[i].width = maxX - minX;
            this.updateIncident(incidents[i],annotationId,incidents[i].id);
            break;
          }
         this.addIncidentAtPosition({
            id: `${uniqueKey}`, name: `${uniqueKey}`, x: minX, y: minY, height: maxY - minY, width: maxX - minX, time: played, index:i+1,status:"Show"
          },annotationId);
          incidents.splice(i + 1, 0, Incident({
            id: `${uniqueKey}`, name: `${uniqueKey}`, x: minX, y: minY, height: maxY - minY, width: maxX - minX, time: played
          }));
          break;
        }
      }
      annotations[group.name()].incidents = incidents;
      return { entities: { ...entities, annotations }};
    });
  }

  handleAnnotationItemClick = name => this.setState({ focusing: name });

  handleAnnotationItemHideTemp = name => {
    this.setState((prevState) => {
      const annotations = prevState.entities.annotations
      return {...prevState, entities: {...prevState.entities, 
        annotations: {...annotations, [name]: {...annotations[name], hideTemp: !Boolean(annotations[name].hideTemp)}}
      }}
    })
  };

  handleIncidentItemClick = (incident) => {
    const { annotationName, time, id } = incident;
    this.setState({ isPlaying: false, focusing: annotationName, played:time},
      () => { this.player.seekTo(parseFloat(time)); });
  }

  handleIncidentUpdateTime = (e) => {
    const { annotationName, incidentName, newTime } = e;
    this.setState((prevState) => {
      this.UndoRedoState.save(prevState);
      const { entities } = prevState;
      const { annotations } = entities;
      const incidents = annotations[annotationName].incidents;
      const i = incidents.findIndex(i => i.name === incidentName);
      incidents[i].time = newTime;
      this.updateIncident(incidents[i], annotationName, incidents[i].id)
      this.handleIncidentItemClick({annotationName, time: newTime});
      return { entities: { ...entities, annotations } };
    })
  } 

  handleIncidentItemDelete = (e) => {
    const { annotationName, incidentName } = e;
    this.setState((prevState) => {
      this.UndoRedoState.save(prevState);
      this.deleteIncident(annotationName,incidentName);
      const { entities } = prevState;
      const { annotations } = entities;
      const incidents = entities.annotations[annotationName].incidents.filter((t) => {
        if (t.name !== incidentName) return true;
        return false;
      });
      annotations[annotationName].incidents = incidents;
      return { entities: { ...entities, annotations } };
    });
  }


  handleListAnnotationDelete = (name) => {
    this.setState((prevState) => {
      this.UndoRedoState.save(prevState);
      const { entities, annotations } = prevState;
      const entitiesAnnotations = entities.annotations;
      const { label } = entitiesAnnotations[name];
      // reorder the list
      if (!isNaN(label)) {
        const lastLabel = getLastAnnotationLabel(annotations, entities);
        if (`${lastLabel}` !== '1' && `${lastLabel}` !== label) {
          const lastName = annotations.find(a => entitiesAnnotations[a].label === `${lastLabel}`);
          this.renameLabel(annotations, entitiesAnnotations, lastName, label);
        }
      }
      // remove name from the parent's childrenNames
      if (entitiesAnnotations[name].parentName) {
        const parent = entitiesAnnotations[entitiesAnnotations[name].parentName];
        const i = parent.childrenNames.indexOf(name);
        if (i !== -1) {
          parent.childrenNames.splice(i, 1);
          if (parent.childrenNames.length == 0 && parent.incidents[parent.incidents.length - 1].status === SPLIT) parent.incidents[parent.incidents.length - 1].status = SHOW;
        }
      }
      // remove all its children and itself recusively
      this.removeAnnotation(annotations, entitiesAnnotations, name);
      return { annotations, entities: { ...entities, annotations: entitiesAnnotations }, focusing: '' };
    });
  }

  handleUpdateMetadata = (annotationId, metadata) => {
    this.setState((prevState) => {
      this.UndoRedoState.save(prevState);
      this.updateMetadata(metadata, annotationId)
      const { entities } = prevState;
      const { annotations } = entities;
      annotations[annotationId].metadata = metadata;
      return { entities: { ...entities, annotations } };
    })
  }

  removeAnnotation = (annotations, entitiesAnnotations, name) => {
    if (entitiesAnnotations[name].childrenNames.length !== 0) {
      entitiesAnnotations[name].childrenNames.forEach((c) => {
        this.removeAnnotation(annotations, entitiesAnnotations, c);
        this.deleteAnnotation(c);
      });
    }
    this.deleteAnnotation(name);
    delete entitiesAnnotations.name;
    const i = annotations.indexOf(name);
    annotations.splice(i, 1);
  }

  toggleReplaceModal(status){
    if(status === true){
      this.setState({
        replaceLabelModalOn:true
      });
    }else if (status === false){
      this.setState({
        replaceLabelModalOn:false
      });
    }
  }

  renameLabel = (annotations, entitiesAnnotations, name, label) => {
    if (entitiesAnnotations[name].childrenNames.length !== 0) {
      entitiesAnnotations[name].childrenNames.forEach((c, index) => {
        this.renameLabel(annotations, entitiesAnnotations, c, `${label}-${index + 1}`);
      });
    }
    entitiesAnnotations[name].label = label;
    this.updateAnnotation(entitiesAnnotations[name],name);
  }

  handleListAnnotationShowHide = (e) => {
    const { name } = e;
    const { status } = e;
    const uniqueKey = new Date().getTime().toString(36);
    this.setState((prevState) => {
      this.UndoRedoState.save(prevState);
      const { played, entities } = prevState;
      const { incidents } = entities.annotations[name];
      for (let i = 0; i < incidents.length; i += 1) {
        if (i === 0 && played < incidents[i].time) {
          this.addIncidentAtPosition({
            id: `${uniqueKey}`, name: `${uniqueKey}`, x: incidents[i].x, y: incidents[i].y, height: incidents[i].height, width: incidents[i].width, time: played, status,index:0
          },name)
          incidents.splice(0, 0, Incident({
            id: `${uniqueKey}`, name: `${uniqueKey}`, x: incidents[i].x, y: incidents[i].y, height: incidents[i].height, width: incidents[i].width, time: played, status,
          }));
          break;
        }
        if (played >= incidents[i].time) {
          // skip elapsed incidents
          if (i !== incidents.length - 1 && played >= incidents[i + 1].time) continue;
          if (played === incidents[i].time) {
            this.addIncidentAtPosition({
              ...incidents[i], id: `${uniqueKey}`, name: `${uniqueKey}`, status,index:i,overwrite:true
            },name)
            incidents.splice(i, 1, Incident({
              ...incidents[i], id: `${uniqueKey}`, name: `${uniqueKey}`, status,
            }));
            break;
          }
          if (i === incidents.length - 1) {
            this.addIncident({
              id: `${uniqueKey}`, name: `${uniqueKey}`, x: incidents[i].x, y: incidents[i].y, height: incidents[i].height, width: incidents[i].width, time: played, status,
            },name)
            incidents.push(Incident({
              id: `${uniqueKey}`, name: `${uniqueKey}`, x: incidents[i].x, y: incidents[i].y, height: incidents[i].height, width: incidents[i].width, time: played, status,
            }));
            break;
          }
          const interpoArea = getInterpolatedData({
            startIncident: incidents[i],
            endIncident: incidents[i + 1],
            currentTime: played,
            type: INTERPOLATION_TYPE.LENGTH,
          });
          const interpoPos = getInterpolatedData({
            startIncident: incidents[i],
            endIncident: incidents[i + 1],
            currentTime: played,
            type: INTERPOLATION_TYPE.POSITION,
          });
          this.addIncidentAtPosition({
            id: `${uniqueKey}`, name: `${uniqueKey}`, x: interpoPos.x, y: interpoPos.y, height: interpoArea.height, width: interpoArea.width, time: played, status,index:i+1
          },name)
          incidents.splice(i + 1, 0, Incident({
            id: `${uniqueKey}`, name: `${uniqueKey}`, x: interpoPos.x, y: interpoPos.y, height: interpoArea.height, width: interpoArea.width, time: played, status,
          }));
          break;
        }
      }
      if (status === HIDE) entities.annotations[name].clearRedundantIncidents(status);
      this.updateAnnotation(entities.annotations[name],name);
      return { entities: { ...entities, annotations: entities.annotations } };
    });
  }


  handleListAnnotationSplit = (name) => {
    const timeNow = (new Date()).getTime().toString(36);
    const timeNowChild1 = ((new Date()).getTime() + 1).toString(36);
    const timeNowChild2 = ((new Date()).getTime() + 2).toString(36);
    const status = SPLIT;
    this.setState((prevState) => {
      this.UndoRedoState.save(prevState);
      const { played, entities, annotations } = prevState;
      const parent = entities.annotations[name];
      // remove ex-childrenNames
      if (parent.childrenNames.length !== 0) {
        for (const c of parent.childrenNames) {
          delete entities.annotations[c];
          const i = annotations.indexOf(c);
          annotations.splice(i, 1);
        }
      }
      // make sure parent's color is different from its children
      let randomColor = colors[getRandomInt(colors.length)];
      while (parent.color === randomColor) randomColor = colors[getRandomInt(colors.length)];
      const childrenColor = randomColor;

      let parentX; let parentY; let parentWidth; let
        parentHeight;
      let { incidents } = parent;
      for (let i = 0; i < incidents.length; i++) {
        if (played >= incidents[i].time) {
          if (i !== incidents.length - 1 && played >= incidents[i + 1].time) continue;
          parentX = incidents[i].x;
          parentY = incidents[i].y;
          parentWidth = incidents[i].width;
          parentHeight = incidents[i].height;
          if (played === incidents[i].time) {
            incidents.splice(i, 1, Incident({
              ...incidents[i], id: `${timeNow}`, name: `${timeNow}`, status,
            }));
            incidents = incidents.slice(0, i + 1);
            break;
          }
          if (i === incidents.length - 1) {
            incidents.push(Incident({
              id: `${timeNow}`, name: `${timeNow}`, x: incidents[i].x, y: incidents[i].y, height: incidents[i].height, width: incidents[i].width, time: played, status,
            }));
            break;
          }
          const interpoArea = getInterpolatedData({
            startIncident: incidents[i],
            endIncident: incidents[i + 1],
            currentTime: played,
            type: INTERPOLATION_TYPE.LENGTH,
          });
          const interpoPos = getInterpolatedData({
            startIncident: incidents[i],
            endIncident: incidents[i + 1],
            currentTime: played,
            type: INTERPOLATION_TYPE.POSITION,
          });
          parentX = interpoPos.x;
          parentY = interpoPos.y;
          parentWidth = interpoArea.width;
          parentHeight = interpoArea.height;
          incidents.splice(i + 1, 0, Incident({
            id: `${timeNow}`, name: `${timeNow}`, x: interpoPos.x, y: interpoPos.y, height: interpoArea.height, width: interpoArea.width, time: played, status,
          }));
          incidents = incidents.slice(0, i + 2);
          break;
        }
      }
      parent.childrenNames = [`${timeNowChild1}`, `${timeNowChild2}`];
      parent.incidents = incidents;
      const childIncidents1 = [Incident({
        id: `${timeNow}`, name: `${timeNow}`, x: parentX, y: parentY, height: parentHeight / 2, width: parentWidth / 2, time: played,
      })];
      const childIncidents2 = [Incident({
        id: `${timeNow}`, name: `${timeNow}`, x: parentX + parentWidth / 2 - 20, y: parentY + parentHeight / 2 - 20, height: parentHeight / 2, width: parentWidth / 2, time: played,
      })];
      entities.annotations[`${timeNowChild1}`] = Rectangle({
        id: `${timeNowChild1}`, name: `${timeNowChild1}`, label: `${parent.label}-1`, color: childrenColor, incidents: childIncidents1, parentName: parent.name,
      });
      entities.annotations[`${timeNowChild2}`] = Rectangle({
        id: `${timeNowChild2}`, name: `${timeNowChild2}`, label: `${parent.label}-2`, color: childrenColor, incidents: childIncidents2, parentName: parent.name,
      });
      const parentIndex = annotations.find(a => a === parent.name);
      annotations.splice(parentIndex, 0, `${timeNowChild1}`);
      annotations.splice(parentIndex, 0, `${timeNowChild2}`);
      return { annotations, entities, focusing: `${timeNowChild2}` };
    });
  }


  handlePreviewClick = () => this.setState({ isPreviewed: true });

  /* ==================== review ==================== */
  handleReviewCancelSubmission = () => this.setState({ isLoop: false, isSubmitted: false, isPlaying: false });

  //===================HotKeys =======================//
   onKeyDown(key, e, handle){
    const {
      url,
      bounds,
      height,
      width,
      figures,
      unfinishedFigure,
      onChange,
      onReassignment,
      style,
      projectCustomShortcuts,
      handleSelected,
      labels
    } = this.props;

    const {isAdding,focusing} = this.state;
          if (isAdding) {
            if (key === 'f') {
              console.log(key);
            }
          } else {
            if(key === "f"){ 
              this.setState({
                isPanning:true
              })
            }  
            if(key === "space"){ 
              this.setState({
                isPlaying:!this.state.isPlaying
              })
            } 
            if (key === 'r') {
              if(this.state.focusing){
                this.toggleReplaceModal(true);
              }
            }

            // if (key === 'h') {
            //   // this.hideSelectedFigure();
            //   console.log(key);
            // }
            if ((key === 'backspace' || key === 'del') && (this.state.focusing && this.state.focusing !== "")) {
              this.handleListAnnotationDelete(this.state.focusing);
            }
           if (key === 'left' || key === 'a') {
              this.handlePreviousFrame();
            }
            if (key === 'right' || key === 'd') {
              this.handleNextFrame();
            }
          }

          if(key === 'esc'){
            e.stopPropagation();
              this.setState({ isAdding: false,focusing:null }, () => {
                handleSelected(null);
              });
            }

          // if(key === 'ctrl+z'){
          //   this.handleUndo();
          // }
          // if(key === 'ctrl+y'){
          //   this.handleRedo();
          // }

           // Check if the pressed key matches any shortcut
          
          if (projectCustomShortcuts){
            projectCustomShortcuts.forEach((shortcut) => {
            if (shortcut && key === shortcut?.shortcut?.toLowerCase()) {
              //Find the label ID in the formparts
              const foundLabel = labels.find((label)=>{
                return label?.name === shortcut.label;
              });
              if(foundLabel && handleSelected){
                if(focusing){
                  this.handleReplaceAnnotationsLabel(focusing,foundLabel?.name);
                }else{
                  handleSelected(foundLabel?.id);
                }
              }
            }
          });
          }
  }

  /* ==================== others ==================== */
  isEmptyAnnotationOrIncident = () => {
    const { annotations, defaultNumAnnotations, entities } = this.state;
    const { isEmptyCheckEnable } = this.props;
    if (!isEmptyCheckEnable) return false;
    if (annotations.length !== 0 && defaultNumAnnotations < annotations.length) {
      for (const ann of annotations) {
        if (entities.annotations[ann].incidents.length < 2) return true;
      }
      return false;
    }
    return true;
  }

  togglePanMode = () => {
    this.setState({isPanning:!this.state.isPanning});
  };

  handleSubmit = () => {
    const { annotations, isSubmitted } = this.state;
    const { onSubmit, hasReview, emptyCheckSubmissionWarningText } = this.props;

    if (this.isEmptyAnnotationOrIncident()) {
      this.setState({ isDialogOpen: true, dialogTitle: 'Submission warning', dialogMessage: emptyCheckSubmissionWarningText });
      return;
    }
    if (!isSubmitted && hasReview) {
      this.setState({
        isLoop: true, isSubmitted: true, played: 0, isPlaying: true, focusing: '',
      });
      return;
    }
    const { videoWidth, annotationHeight, entities } = this.state;
    const { url } = this.props;
    const annotation = new schema.Entity('annotations');
    const denormalizedAnnotations = denormalize({ annotations }, { annotations: [annotation] }, entities).annotations;
    denormalizedAnnotations.forEach((ann) => {
      delete ann.isManipulatable;
    });
    const data = {
      url, videoWidth, annotationHeight, annotations: denormalizedAnnotations,
    };
    onSubmit(data);
  }

    handleDialogToggle = () => this.setState(prevState => ({ isDialogOpen: !prevState.isDialogOpen }));

  handleAddClick = () => this.setState(prevState => ({ isAdding: !prevState.isAdding, isPlaying: false }));

  handleReplaceAnnotationsLabel = (annotationId,newLabel)=>{
    const {entities} = this.state;
    const {labels} = this.props;
    const entitiesAnnotations = entities?.annotations;
    const foundAnnotation= entitiesAnnotations[annotationId];
    const labelIdx = labels.findIndex(label => label.name === newLabel); 
    const color = labelsColors[labelIdx];  
    if(foundAnnotation){
      this.updateAnnotation({...foundAnnotation,label:newLabel,color:color},annotationId);
      entitiesAnnotations[annotationId] = {...foundAnnotation,label:newLabel,color:color}
      this.setState({entities:{...entities,annotations:entitiesAnnotations}});
    }
  };

  handleReplaceLabel(newSelectedLabelName) {
    const annotationId = this.state.focusing;
    const {entities} = this.state;
    const {labels} = this.props;
    const entitiesAnnotations = entities?.annotations;
    const labelIdx = labels.findIndex(label => label.name === newSelectedLabelName); 
    const color = labelsColors[labelIdx];  
    const foundAnnotation= entitiesAnnotations[annotationId];
    this.updateAnnotation({...foundAnnotation,label:newSelectedLabelName,color},annotationId);
    entitiesAnnotations[annotationId] = {...foundAnnotation,label:newSelectedLabelName,color:color}
    this.setState({entities:{...entities,annotations:entitiesAnnotations}});
  }

  renderAddButtonUI = () => {
    const {
      isAdding,
      defaultNumRootAnnotations,
      annotations,
      entities,
    } = this.state;
    const { numAnnotationsCanBeAdded } = this.props;
    const isAddButtonAvailable = (defaultNumRootAnnotations + numAnnotationsCanBeAdded) > getLastAnnotationLabel(annotations, entities);
    if (isAdding || (!isAdding && isAddButtonAvailable)) {
      return (
        <Button
          disabled={isAdding}
          primary
          onClick={this.handleAddClick}
          className='d-flex align-items-center float-left'
        >
          <Icon name={isAdding ? 'minus' : 'plus'} />
          {isAdding ? 'Adding Box' : 'Add Box'}
        </Button>
      );
    }
    return null;
  }

  // VIDEO METHODS
  async updateVideo(data) {
    const updatedVideo = await apiCall("PUT",`${process.env.REACT_APP_PRODUCTION_SERVER_URL}/api/videos/${data._id}`, data);
    return updatedVideo;
  };
  
  async addAnnotation(data){
        const {video } = this.props;
        const videoId = video?._id;
        const updatedVideo = await apiCall("POST",`${process.env.REACT_APP_PRODUCTION_SERVER_URL}/api/videos/${videoId}/annotations`,data);
        const {annotations} = updatedVideo;
        const annotationIds = annotations.map((ann)=>{
          return ann?.id;
        });
        };


  async updateAnnotation(data,annotationId){
        const {video } = this.props;
        const videoId = video?._id;
        const updatedVideo = await apiCall("PUT",`${process.env.REACT_APP_PRODUCTION_SERVER_URL}/api/videos/${videoId}/annotations/${annotationId}`,data);
        const {annotations} = updatedVideo;
        const annotationIds = annotations.map((ann)=>{
          return ann?.id;
        });
      }
  async deleteAnnotation(annotationId){
        const {video } = this.props;
        const videoId = video?._id;
        const updatedVideo = await apiCall("DELETE",`${process.env.REACT_APP_PRODUCTION_SERVER_URL}/api/videos/${videoId}/annotations/${annotationId}`);
      }
  async addIncident(data,annotationId){
        const {video } = this.props;
        const videoId = video?._id;
        const videoWithNewIncident = await apiCall("POST",`${process.env.REACT_APP_PRODUCTION_SERVER_URL}/api/videos/${videoId}/annotations/${annotationId}/incidents`,data);
      }
  async addIncidentAtPosition(data,annotationId){
        const {video } = this.props;
        const videoId = video?._id;
        const videoWithNewIncident = await apiCall("POST",`${process.env.REACT_APP_PRODUCTION_SERVER_URL}/api/videos/${videoId}/annotations/${annotationId}/incidents/insertAtPosition`,data);
      }
  async updateIncident(data,annotationId,incidentId){
        const {video } = this.props;
        const videoId = video?._id;
        const updatedVideo = await apiCall("PUT",`${process.env.REACT_APP_PRODUCTION_SERVER_URL}/api/videos/${videoId}/annotations/${annotationId}/incidents/${incidentId}`,data);
      }

  async deleteIncident(annotationId,incidentId){
        const {video } = this.props;
        const videoId = video?._id;
        const updatedVideo = await apiCall("DELETE",`${process.env.REACT_APP_PRODUCTION_SERVER_URL}/api/videos/${videoId}/annotations/${annotationId}/incidents/${incidentId}`);
      }

  async updateMetadata(data, annotationId){
        const {video } = this.props;
        const videoId = video?._id;
        const updatedVideo = await apiCall("PUT",`${process.env.REACT_APP_PRODUCTION_SERVER_URL}/api/videos/${videoId}/annotations/${annotationId}/metadata`, {metadata: data});
      }

  render() {
    const {
      isPreviewed,
      isSubmitted,
      videoWidth,
      videoHeight,
      annotationWidth,
      annotationHeight,
      isPlaying,
      played,
      playbackRate,
      duration,
      isLoop,
      isAdding,
      focusing,
      entities,
      annotations,
      labelMetadata,
      isDialogOpen,
      dialogTitle,
      dialogMessage,
      isPanning
    } = this.state;
    const {
      className,
      url,
      previewHeader,
      previewNoticeList,
      isEmptyCheckEnable,
      isSplitEnable,
      isShowHideEnable,
      emptyCheckAnnotationItemWarningText,
      emptyAnnotationReminderText,
      projectCustomShortcuts,
      selectedLabel,
      labels
    } = this.props;
    const twoDimensionalVideoContext = {
      playerRef: this.handlePlayerRef,
      entities,
      annotations,
      labelMetadata,
      isPanning,
      duration,
      played,
      focusing,
      videoWidth,
      videoHeight,
      width: annotationWidth,
      height: annotationHeight,
      isEmptyCheckEnable,
      url,
      isPlaying,
      isLoop,
      playbackRate,
      isAdding,
      isSplitEnable,
      isShowHideEnable,
      emptyCheckAnnotationItemWarningText,
      emptyAnnotationReminderText,
      onVideoReady: this.handleVideoReady,
      onVideoProgress: this.handleVideoProgress,
      onVideoDuration: this.handleVideoDuration,
      onVideoEnded: this.handleVideoEnded,
      onVideoSliderMouseUp: this.handleVideoSliderMouseUp,
      onVideoSliderMouseDown: this.handleVideoSliderMouseDown,
      onVideoSliderChange: this.handleVideoSliderChange,
      onVideoRewind: this.handleVideoRewind,
      onVideoPlayPause: this.handleVideoPlayPause,
      onVideoSpeedChange: this.handleVideoSpeedChange,
      onCanvasStageMouseDown: this.handleCanvasStageMouseDown,
      onCanvasGroupMouseDown: this.handleCanvasGroupMouseDown,
      onCanvasGroupDragEnd: this.handleCanvasGroupDragEnd,
      onCanvasDotMouseDown: this.handleCanvasDotMouseDown,
      onCanvasDotDragEnd: this.handleCanvasDotDragEnd,
      onAnnotationItemClick: this.handleAnnotationItemClick,
      onAnnotationDeleteClick: this.handleListAnnotationDelete,
      onAnnotationShowHideClick: this.handleListAnnotationShowHide,
      onAnnotationSplitClick: this.handleListAnnotationSplit,
      onAnnotationItemHideTemp: this.handleAnnotationItemHideTemp,
      onIncidentItemClick: this.handleIncidentItemClick,
      onIncidentUpdateTime: this.handleIncidentUpdateTime,
      onIncidentItemDeleteClick: this.handleIncidentItemDelete,
      onMetadataUpdate: this.handleUpdateMetadata,
      onNextFrame:this.handleNextFrame,
      onPrevFrame:this.handlePreviousFrame,
      togglePanMode:this.togglePanMode
    };

     let customProjectShortcuts = [];
      if(projectCustomShortcuts){
        customProjectShortcuts=projectCustomShortcuts.map((shortcut)=>{
          if(shortcut){
            return shortcut?.shortcut;
          }else{
            return ""
          }
        });
      }
      const flattenedShortcuts = customProjectShortcuts.join(",");
    let controlPanelUI = null;
    if (isSubmitted) {
      controlPanelUI = (
        <Review
          height={ annotationHeight }
          onConfirmSubmit={ this.handleSubmit }
          onCancelSubmit={ this.handleReviewCancelSubmission }
        />
      );
    } else{
          {/*<div className='pb-3 clearfix'>
            {this.renderAddButtonUI()}
            <Button.Group className='float-right'>
              <Button disabled={ this.UndoRedoState.previous.length === 0 } onClick={ this.handleUndo }> <Icon name="undo" /></Button>
              <Button disabled={ this.UndoRedoState.next.length === 0 } onClick={ this.handleRedo }><Icon name="redo" /> </Button>
            </Button.Group>
          </div>*/}
      controlPanelUI = <AnnotationList />;
    } 
    // else {
    //   controlPanelUI = (
    //     <Preview
    //       height={ annotationHeight }
    //       notices={ previewNoticeList }
    //       header={ previewHeader }
    //       onPreviewClick={ this.handlePreviewClick }
    //     />
    //   );
    // }

    const rootClassName = `two-dimensional-video${className ? ` ${className}` : ''}`;
    return (
    <TwoDimensionalVideoContext.Provider value={ twoDimensionalVideoContext }>
      {this.state.entities?.annotations[this.state.focusing] && (
        <ReplaceLabelModal 
          modalEnabled={this.state.replaceLabelModalOn} 
          onClose={()=> this.toggleReplaceModal(false)} 
          selectedFigures={[{ type: "bbox", label: {
            id: this.state.entities?.annotations[this.state.focusing]?.id,
            name: this.state.entities?.annotations[this.state.focusing]?.label,
          }}]} 
          labels={this.props.labels} 
          handleClick={(selectedLabels) => this.handleReplaceLabel(selectedLabels[0].name)}
        />
      )}
      <div className={ rootClassName }>
        <div className='d-flex flex-wrap justify-content-around py-3 two-dimensional-video__main' >
          <div className='mb-3' style={{ margin:"0 auto", padding: "15px" }}>
            <DrawableVideoPlayer />
          </div>
          <div className='mb-3 two-dimensional-video__control-panel'>
            { controlPanelUI }
          </div>
        </div>
        <div className='d-flex justify-content-center pt-3'>
          {isSubmitted || !isPreviewed ? '' : (<div><Button onClick={ this.handleSubmit }>Submit</Button></div>)}
        </div>
        <PopupDialog isOpen={ isDialogOpen } title={ dialogTitle } message={ dialogMessage } onToggle={ this.handleDialogToggle } hasCloseButton />
      </div>
      <Hotkeys
        allowRepeat={true}
        keyName={`ctrl+z,ctrl+y,a,s,d,w,esc,backspace,del,c,f,-,=,left,right,up,down,space,h,r,h,1,2,3${customProjectShortcuts.length > 0? `,${flattenedShortcuts.toLowerCase()}`:""}`}
        onKeyDown={this.onKeyDown}
        onKeyUp ={
          (key, e, handle) => {
            if(key === "f"){ 
              this.setState({
                isPanning:false 
              });
            }
          }
        }
      />
    </TwoDimensionalVideoContext.Provider>
    );
  }
}

TwoDimensionalVideo.propTypes = {
  className: PropTypes.string,
  defaultAnnotations: PropTypes.arrayOf(PropTypes.object),
  videoWidth: PropTypes.number,
  isDefaultAnnotationsManipulatable: PropTypes.bool,
  previewHeader: PropTypes.string,
  previewNoticeList: PropTypes.arrayOf(PropTypes.string),
  isEmptyCheckEnable: PropTypes.bool,
  isSplitEnable: PropTypes.bool,
  isShowHideEnable: PropTypes.bool,
  hasReview: PropTypes.bool,
  url: PropTypes.string,
  numAnnotationsCanBeAdded: PropTypes.number,
  onSubmit: PropTypes.func,
  emptyCheckSubmissionWarningText: PropTypes.string,
  emptyCheckAnnotationItemWarningText: PropTypes.string,
  emptyAnnotationReminderText: PropTypes.string,
};
TwoDimensionalVideo.defaultProps = {
  className: '',
  defaultAnnotations: [],
  videoWidth: 400,
  isDefaultAnnotationsManipulatable: false,
  previewHeader: '',
  previewNoticeList: [],
  isEmptyCheckEnable: false,
  isSplitEnable: false,
  isShowHideEnable: false,
  hasReview: false,
  url: '',
  numAnnotationsCanBeAdded: 1000,
  onSubmit: () => {},
  emptyCheckSubmissionWarningText: '',
  emptyCheckAnnotationItemWarningText: '',
  emptyAnnotationReminderText: '',
};
export default TwoDimensionalVideo;
