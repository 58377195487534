import React, {Fragment, useState, useRef, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { Comment, Header, Form, Button, Grid, Icon, Popup} from 'semantic-ui-react';
import CommentItem from './CommentItem';

const Comments = ({browserLang, comments, threadId, onNewComment, onToggleResolve, isResolved}) => {
	const [commentText,setCommentText] = useState("");
	const commentList = useRef(null);
	const lastDiv = useRef(null);
	const scrollToBottom = () => {
		let count = lastDiv.offsetTop - commentList.scrollTop - 20;
	    lastDiv.current.scrollIntoView({ behavior: "smooth", block: 'center', inline: 'center',})
	    // lastDiv.scrollIntoView({top:count, left:0, behavior:'smooth'});
	  }
	useEffect(()=>{
		scrollToBottom();
	}, [comments]);
	if(!comments){
		return null;
	}
	const formattedComments = comments.map((com)=>{
		return <CommentItem key={com._id} browserLang={browserLang} comment = {com}></CommentItem>
	});
	const handleNewComment = ()=>{

		if(onNewComment){
			onNewComment(threadId,commentText);
		}
		setCommentText("");
	};
	const toggleResolve = ()=>{
		if(onToggleResolve){
			onToggleResolve(threadId);
		}
	};

	return (
		<div style={{width:'300px'}}>
		<Grid>
			<Grid.Column width={13}>
				<Header as='h4' textAlign="left">
			      {browserLang === 'es' ? "Comentarios" : "Comments"}
			    </Header>
			</Grid.Column>
			<Grid.Column>
				<Popup
				    trigger={<Icon onClick={toggleResolve} className="resolveIcon" circular name='check' inverted color={isResolved ? "green" : "grey"}/>}
				    content={browserLang === "es"? `Marcar como ${isResolved? "No Resuelto" : "Resuelto"}` : `Mark as ${isResolved? "Not resolved" : "Resolved"}`}
				    basic
				  />
			</Grid.Column>
		</Grid>
	    	<div ref={commentList} style={{minHeight:"140px", maxHeight:"300px",overflowY:"scroll"}}>
	    		<Comment.Group size='large'>
				    {formattedComments}
				    <div style={{ float:"left", clear: "both" }}
                         ref={lastDiv}>
                    </div>
				  </Comment.Group>
				  
	    	</div>
		  {!isResolved && <Form reply>
  		      <Form.Input value={commentText} placeholder="Escribe algo y presiona Enter" onChange={(evt, {value})=>{
  		      	setCommentText(value);
  		      }} style={{height:'42px'}}/>
  		      <Button size="small" onClick={handleNewComment} content={browserLang === 'es'? "Agregar Comentario" : 'Add Comment'} labelPosition='left' icon='edit' primary />
  		    </Form>}
		</div>
	);
}
export default Comments;
