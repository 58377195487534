import React, { useEffect } from 'react';

const PreventClose = (props) => {

  useEffect(() => {
        window.onbeforeunload = alertUser;
        function alertUser(){
            return "Are you sure you want to leave? Your data may be lost."
        }
    })

  return (
    <div style={{display:"none"}}>
      PreventClose
    </div>
  );
};

export default PreventClose;
