import React, { Fragment } from 'react';
import AuthForm from "./AuthForm";
import { Container } from 'semantic-ui-react';
import GradientHeader from '../containers/GradientHeader';

const AuthPage = (props) => {
	const browserLang = props.browserLang;
	const signUp = props.signUp ? props.signUp : false;
	const imgUrl = "images/background-image-hero.jpg";
	let backgroundImg = `linear-gradient(45deg, rgb(108, 16, 204) 1%, rgb(126, 81, 177) 30%, rgb(162, 98, 153) 69%, rgba(220, 40, 55, 0.7) 100%),url(${imgUrl})`;
	const removeAdditionalField = props.removeAdditionalField ? props.removeAdditionalField : false;
	const heading = props.heading ? props.heading : "";
	const buttonText = props.buttonText ? props.buttonText : "Signin";
	const removeError = props.removeError ? props.removeError : () => console.log("remove error");
	const onAuth = props.onAuth ? props.onAuth : () => console.log("error");
	const errors = props.errors ? props.errors : null;

	return (
		<div style={{ "background": "whitesmoke", "height": "100vh" }}>
			{(process.env.REACT_APP_USE_GLOBAL_NAV === 'false' || process.env.REACT_APP_USE_GLOBAL_NAV === '' || typeof process.env.REACT_APP_USE_GLOBAL_NAV === 'undefined') && (
				<GradientHeader/>
			)}
			<Container style={{ "paddingTop": "50px" }}>
				<AuthForm onAuth={onAuth} removeAdditionalField={removeAdditionalField} errors={errors} removeError={removeError} heading={heading} buttonText={buttonText} signUp={signUp}{...props}></AuthForm>
			</Container>
		</div>
	);
};

export default AuthPage;