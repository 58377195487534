import React, {useState, useMemo, useRef, useCallback, useEffect } from 'react';
import { Marker} from 'react-leaflet';
import CommentPinIcon from './CommentPinIcon';

const DraggableMarker = ({startingPosition, children, isResolved})=>{
	const [icon, setIcon] = useState(CommentPinIcon(isResolved));
	useEffect(()=>{
		setResolved(isResolved);
		setIcon(CommentPinIcon(resolved)); 
	}, [isResolved]);
	const [resolved, setResolved] = useState(false);
	const [draggable, setDraggable] = useState(true)
	const [position, setPosition] = useState(startingPosition)
	const markerRef = useRef(null);
	const eventHandlers = useMemo(
	    () => ({
	      dragend() {
	        const marker = markerRef.current;
	        console.log("marker", marker);
	        if (marker != null) {
	        	console.log(marker.getLatLng());
	          setPosition(marker.getLatLng());
	        }
	      },
	    }),
	    [],
	  )
	  const toggleDraggable = useCallback(() => {
	    setDraggable((d) => !d)
	  }, [])

	  return (
	    <Marker
	      draggable={draggable}
	      eventHandlers={eventHandlers}
	      position={position}
	      icon={icon} 
	      ref={markerRef}>
	     {children}
	    </Marker>
	  )
};

export default DraggableMarker;