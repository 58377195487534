import React, { useState, useEffect } from 'react';
import mapboxgl from 'mapbox-gl';
import { Button } from 'semantic-ui-react';

// Replace with your own Mapbox access token
mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_PUBLIC_TOKEN;


const MapDistressView = ({data, distanceMeasuringUnit = 'ft'}) => {
  const distressCodeMapping = {
    "1": "Alligator Cracking",
    "2": "Bleeding",
    "3": "Block Cracking",
    "4": "Bumps and Sags",
    "5": "Corrugation",
    "6": "Depressions",
    "7": "Edge Cracking",
    "8": "Joint Reflection Cracking",
    "9": "Lane/Shoulder Drop-Off",
    "10": "Longitudinal and Transverse Cracking",
    "11": "Patching and Utility Cut Patching",
    "12": "Polished Aggregate",
    "13": "Potholes",
    "14": "Railroad Crossing",
    "15": "Rutting",
    "16": "Shoving",
    "17": "Slippage Cracking",
    "18": "Swelling",
    "19": "Raveling",
    "20": "Weathering"
  };
  
  const [map, setMap] = useState(null);
  useEffect(() => {
    // Create a new Mapbox map and set it to the state
    const firstSection = data && data[Object.keys(data)[0]];
    console.log("firstSection",firstSection);
    const firstImage = firstSection && firstSection?.images[0];
    const firstGPSPoint = firstImage && firstImage?.gpsPoints;
    const longLatPoint = firstImage && [firstImage?.gpsPoints[1],firstImage.gpsPoints[0]]; 
    if(data){
      const newMap = new mapboxgl.Map({
        container: 'map',
        style: 'mapbox://styles/mapbox/dark-v11',
        center: longLatPoint, // Change to your desired default center point
        zoom: 15 // Change to your desired default zoom level
      });
      // Add navigation control (the +/- zoom buttons)
      newMap.addControl(new mapboxgl.NavigationControl(), 'top-right');


      setMap(newMap);
      return () => newMap.remove();
    }

    // Clean up the map on unmount
  }, [data]);

  useEffect(() => {
    // Display the GPS points on the map when the data and map are ready
    if (data && map) {
      // Object.values(data).forEach(section => {
      //   const pciScore = section.pciScore;
      //   const color =
      //     pciScore >= 81 ? 'green' :
      //     pciScore >= 31 ? 'yellow' : 'red';
      //   section.images.forEach(image => {
      //     const gpsPoints = image.gpsPoints;
      //     const marker = new mapboxgl.Marker({ color })
      //       .setLngLat(gpsPoints)
      //       .addTo(map);
      //   });
      // });

      const featuresArr = [];
      Object.keys(data).forEach(sectionName => {
      const section = data[sectionName];
      section.images.forEach(image => {
        let tableHtml = '';
      
        if (image.distressFrameMap) {
          // Updated table headers for separate columns
          tableHtml = `
            <table class="ui celled table">
              <thead>
                <tr>
                  <th>Distress Type</th>
                  <th>Severity</th>
                  <th>Area (${distanceMeasuringUnit === 'mt' ? 'm²':'ft²'})</th>
                  <th>Length (${distanceMeasuringUnit === 'mt' ? 'm':'ft'})</th>
                  <th>Count</th>
                </tr>
              </thead>
              <tbody>`;
      
          Object.keys(image.distressFrameMap).forEach(key => {
            const [distressCode, severityCode] = key.split('_');
            const distressData = image.distressFrameMap[key];
            const distressName = distressCodeMapping[distressCode];
            const severity = ['Low', 'Medium', 'High'][parseInt(severityCode) - 1];
            let measuringUnitAreaProperty = 'area_ft2';
            let measuringUnitLengthProperty = 'length_ft';
            if(distanceMeasuringUnit === 'mt'){
              measuringUnitAreaProperty = 'area_m2';
              measuringUnitLengthProperty = 'length_m';
            }
      
            // Separate columns for area, length, and count
            tableHtml += `
              <tr>
                <td>${distressName}</td>
                <td>${severity}</td>
                <td>${distressData[measuringUnitAreaProperty]?.toFixed(2)}</td>
                <td>${distressData[measuringUnitLengthProperty]?.toFixed(2)}</td>
                <td>${distressData?.counts?.toFixed(2)}</td>
              </tr>`;
          });
      
          tableHtml += '</tbody></table>';
        }
        const projectId = window.location.hash.split("/")[2];
        let enhancedButtonHTML = "";
        if(image?.enginImageId){
          enhancedButtonHTML = `<a href="/#/label/${projectId}/${image?.enginImageId}" target="_blank"><button id="detectionsLink-${image?.enginImageId}" class="ui positive button">View Detections</button></a>`;
        }

      
        const feature = {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [image.gpsPoints[1], image.gpsPoints[0]]
          },
          properties: {
            pciScore: section.pciScore,
            imageUrl: image.imageUrl,
            pavementType:image?.pavementType,
            description: image.distressFrameMap ? 
            `<div class="ui segment" style="z-index: 2000; position: relative;">
              <div class="ui stackable two column grid">
                <div class="column">
                  <img class="ui medium centered image" src="${image.imageUrl}" alt="Pavement Image"/>
                  <strong> Section PCI: ${section.pciScore}</strong>
                  <p>${sectionName}</p>
                </div>
                <div class="column">
                  ${tableHtml}
                </div>
              </div>
            </div>`:
            `<div class="ui segment" style="z-index: 2000; position: relative;">
                  <img class="ui medium centered image" src="${image.imageUrl}" alt="Pavement Image"/>
                  <strong> Section PCI: ${section.pciScore}</strong>
                  <p>${sectionName}</p>
            </div>`
            ,
            enhancedDescription:`
            <div class="ui segment" style="z-index: 2000; position: relative;">
              <div class="ui stackable two column grid">
                <div class="column">
                  <img class="ui medium centered image" src="${image.imageUrl}" alt="Pavement Image"/>
                  <strong> Section PCI: ${section.pciScore}</strong>
                  <p>${sectionName}</p>
                  ${enhancedButtonHTML}
                </div>
                <div class="column">
                  ${tableHtml}
                </div>
              </div>
            </div>`,
            enginImageId:"enginImageId" in image ? image?.enginImageId : null
          }
        };
        featuresArr.push(feature);
      });
      
    });

      //map.on
      map.on('load', function () {
        map.addSource('places', {
          'type': 'geojson',
          'data': {
            'type': 'FeatureCollection',
            'features': featuresArr
          }});

        // Add a layer showing the places.
        map.addLayer({
          'id': 'places',
          'type': 'circle',
          'source': 'places',
          'paint': {
             'circle-color': [
                'case',
                ['==', ['get', 'pavementType'], 'rigid'], // Check if pavementType is "rigid"
                '#000FFF', // Color for rigid pavementType
                ['==', ['get', 'pavementType'], 'brick'], // Check if pavementType is "rigid"
                '#FF00F3', // Color for brick pavementType
                ['<=', ['get', 'pciScore'], 10],
                'gray',
                ['<=', ['get', 'pciScore'], 15],
                '#8B0000',
                ['<=', ['get', 'pciScore'], 30],
                'red',
                ['<=', ['get', 'pciScore'], 45],
                'orange',
                ['<=', ['get', 'pciScore'], 60],
                'yellow',
                ['<=', ['get', 'pciScore'], 85],
                '#7fff00',
                ['<=', ['get', 'pciScore'], 100],
                'green',
                'black'
              ],
            'circle-radius': 4,
            'circle-stroke-width': 0,
            'circle-stroke-color': '#ffffff',
            }
          });});

      // Create a popup, but don't add it to the map yet.
      var markerHeight = 2000, markerRadius = 10, linearOffset = 25;

      var popupOffsets = {
        'top': [0, 10],
        // 'top-left': [0,-800],
        // 'top-right': [0,0],
        // 'bottom': [0, -markerHeight],
        // 'bottom-left': [linearOffset, (markerHeight - markerRadius + linearOffset) * -1],
        // 'bottom-right': [-linearOffset, (markerHeight - markerRadius + linearOffset) * -1],
        // 'left': [markerRadius, (markerHeight - markerRadius) * -1],
        // 'right': [-markerRadius, (markerHeight - markerRadius) * -1]
        };
      var popup = new mapboxgl.Popup({
        offset:popupOffsets,
        closeButton: false,
        closeOnClick: true,
        anchor:'top',
        maxWidth:'1000px',
        position:'relative',
        zIndex:200
      });
      var infoPopup = new mapboxgl.Popup({
        offset:popupOffsets,
        closeButton: true,
        closeOnClick: true,
        anchor:'top',
        maxWidth:'1000px',
        position:'relative',
        zIndex:200
      });

      map.on('mouseenter', 'places', function (e) {
        // Change the cursor style as a UI indicator.
        map.getCanvas().style.cursor = 'pointer';
         
        var coordinates = e.features[0].geometry.coordinates.slice();
        var description = e.features[0].properties.description;
        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }
        popup.setLngLat(coordinates).setHTML(description).addTo(map);
      });

      map.on('mouseout', 'places', function() {
        popup.remove();
      });

      map.on('click', 'places', function (e) {
        map.getCanvas().style.cursor = 'pointer';
         
        var coordinates = e.features[0].geometry.coordinates.slice();
        var enhancedDescription = e.features[0].properties.enhancedDescription;
        // console.log("popup coordinates", coordinates);
        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }
        infoPopup.setLngLat(coordinates).setHTML(enhancedDescription).addTo(map);
      });
    }
  }, [data, map]);

  return (
    <div id="map" style={{ width: '100%', height: '100%' }} />
  );
};

export default MapDistressView;
