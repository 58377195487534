import React, { Fragment } from 'react';
import GradientHeader from '../containers/GradientHeader';

const NotFound = (props) => {
	const browserLang = props.browserLang;
	const imgUrl = "images/background-image-hero.jpg";
	return (
		<>
			{process.env.REACT_APP_USE_GLOBAL_NAV === 'false' && (
				<GradientHeader />
			)}
			<div style={{ "paddingTop": "16px", "marginLeft": "40px", "marginRight": "40px" }}>
				<h1>{browserLang === "en" ? "404 - Not Found!" : "404 - No Existe Esta Página"}</h1>
				<a href="/">
					{browserLang === "en" ? "Go Home" : "Volver al Inicio"}
				</a>
			</div>
		</>
	);
}
export default NotFound;
