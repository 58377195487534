import React, { useState, useEffect } from 'react';
import { Form, Select, Input, Button, Checkbox, Grid, Segment, Header } from 'semantic-ui-react';
import {useTrafficSettingsContext} from '../../context/TrafficSettingsContext';

const AnnotateStudy = () => {
  const {legData,handleSetLegData} = useTrafficSettingsContext();
  const [legs, setLegs] = useState([{ direction: '', name: '', oneWay: false, uTurnAvailable:true,crosswalk:true }]);
  
  const legOptions = [
    { key: 'n', text: 'Northbound', value: 'N' },
    { key: 'e', text: 'Eastbound', value: 'E' },
    { key: 'w', text: 'Westbound', value: 'W' },
    { key: 's', text: 'Southbound', value: 'S' },
    { key: 'ne', text: 'North Eastbound', value: 'NE' },
    { key: 'nw', text: 'North Westbound', value: 'NW' },
    { key: 'se', text: 'South Eastbound', value: 'SE' },
    { key: 'sw', text: 'South Westbound', value: 'SW' },
  ];

  useEffect(() => {
    if (legData) {
        setLegs(legData);
    }
  }, [legData]);

  const handleLegChange = (index, field, value) => {
    const newLegs = [...legs];
    newLegs[index] = { ...newLegs[index], [field]: value };
    setLegs(newLegs);
    handleSetLegData(newLegs);
  };

  const addLeg = () => {
    setLegs([...legs, { direction: '', name: '', oneWay: false,uTurnAvailable:true,crosswalk:true }]);
    handleSetLegData([...legs, { direction: '', name: '', oneWay: false,uTurnAvailable:true,crosswalk:true }]);
  };

  const removeLeg = (index) => {
    const newLegs = [...legs];
    newLegs.splice(index, 1);
    setLegs(newLegs);
    handleSetLegData(newLegs);
  };

  return (
    <Segment>
      <Header as="h3">Add Video Study - Annotate Study</Header>
      <Grid columns={1} relaxed='very'>
        <Grid.Column>
          <Form>
            <Form.Field>
              <label>Select Total Number of Legs:</label>
              <Select
                placeholder="Select number of legs"
                options={Array.from({ length: 8 }, (_, i) => ({
                  key: i,
                  text: `${i + 1}`,
                  value: i + 1,
                }))}
                onChange={(e, { value }) => {
                    setLegs(Array.from({ length: value }, () => ({ direction: '', name: '', oneWay: false,uTurnAvailable:true,crosswalk:true })));
                    handleSetLegData(Array.from({ length: value }, () => ({ direction: '', name: '', oneWay: false,uTurnAvailable:true,crosswalk:true})));
                }}
                value={legData.length}
              />
            </Form.Field>
            {legs.map((leg, index) => (
              <Form.Group key={index} widths='equal'>
                <Form.Field
                  control={Select}
                  options={legOptions}
                  label="Direction"
                  placeholder="Direction"
                  value={leg?.direction}
                  onChange={(e, { value }) => handleLegChange(index, 'direction', value)}
                />
                <Form.Field
                  control={Input}
                  label="Leg Name"
                  placeholder="Leg Name"
                  value={leg?.name}
                  onChange={(e) => handleLegChange(index, 'name', e.target.value)}
                />
                <Form.Field
                  control={Checkbox}
                  label="One-Way"
                  checked={leg?.oneWay}
                  onChange={(e, { checked }) => handleLegChange(index, 'oneWay', checked)}
                />
                <Form.Field
                  control={Checkbox}
                  label="Has U-Turn"
                  onChange={(e, { checked }) => handleLegChange(index, 'uTurnAvailable', checked)}
                  checked={leg?.uTurnAvailable}
                />
                <Form.Field
                  control={Checkbox}
                  label="Crosswalk"
                  onChange={(e, { checked }) => handleLegChange(index, 'crosswalk', checked)}
                  checked={leg?.crosswalk}
                />
                <Button type="button" icon="minus" onClick={() => removeLeg(index)} />
              </Form.Group>
            ))}
            {/* <Form.TextArea label="Additional Instructions" placeholder="Please consider including additional instructions..." /> */}
            <Form.Group>
              <Form.Button primary onClick={addLeg} disabled={legData.length >= 8}>Add Leg</Form.Button>
            </Form.Group>
          </Form>
        </Grid.Column>
      </Grid>
    </Segment>
  );
};

export default AnnotateStudy;
