import React, {Fragment, useState, useEffect} from 'react';
import { Modal, Header, Button, Grid, Icon, Dropdown} from 'semantic-ui-react';
import { capitalizeFirstLetter } from '../videoLabel/utils/text';
import { cloneDeep } from 'lodash';

const figureTypes = ['polyline', 'polygon', 'bbox'];

const ReplaceLabelModal = ({modalEnabled, onClose, selectedFigures, labels, handleClick}) => {
  const selectedLabelIds = selectedFigures?.map(f => f.label.id) ?? [];

  const [selectedLabels, setSelectedLabels] = useState([]);
  const [originalLabelIds] = useState([...selectedLabelIds]);
  const [open, setOpen] = useState(false);

  const selectLabel = (newLabel) => {
    const labelIndex = selectedLabels.findIndex(l => l.type === newLabel.type);
    if (labelIndex === -1) {
      setSelectedLabels([...selectedLabels, newLabel]);
    } else {
      const newSelectedLabels = cloneDeep(selectedLabels);
      newSelectedLabels.splice(labelIndex, 1, newLabel);
      setSelectedLabels([...newSelectedLabels]);
    }
    setTimeout(() => setOpen(false), 0); // Closing dropdown asynchronously
  }

  const initLabels = () => {
    const newSelectedLabels = [];
    selectedLabelIds.forEach((id) => {
      const label = labels.find(l => l.id === id);
      if (label && !newSelectedLabels.find(l => l.type === label.type)) newSelectedLabels.push(label);
    })
    setSelectedLabels([...newSelectedLabels]);
  }

  const handleClose = () => {
    setSelectedLabels([]);
    onClose();
  }

  useEffect(() => {
    initLabels();
  }, [selectedFigures]);

  const returnRowLabel = (figureType, idx) => {
    const figures = selectedFigures?.filter((f)=> f.type === figureType) ?? [];
    let filteredLabels = [];
    if(selectedFigures?.length && labels?.length){
      filteredLabels = labels.filter(label => label.type === figureType).map((label, idx)=>{
        return {
          text:label.name,
          value:label.id,
          key:label.id
        }
      });
    }

    const newSelectedLabelId = selectedLabels.find(l => l.type === figureType)?.id;

    if (figures.length) return (
      <Grid key={idx} >
        <Grid.Row columns={3} >
          <Grid.Column>{capitalizeFirstLetter(figureType)}</Grid.Column>
          <Grid.Column />
          <Grid.Column />
        </Grid.Row>
        <Grid.Row columns={3} >
          <Grid.Column>{figures.map(f => f.label.name).join(', ')}</Grid.Column>
          <Grid.Column><Icon name='arrow right'/></Grid.Column>
          <Grid.Column>
            <Dropdown
              open={open}
              options={filteredLabels}
              selection
              search
              value={newSelectedLabelId}
              onOpen={() => setOpen(!open)}
              onClose={() => setOpen(false)}
              onChange={(evt,data)=> {
                const foundLabel = labels.filter((label)=>{
                  return label.id ===  data.value;
                })[0];
                selectLabel({
                  id: data.value,
                  type: foundLabel?.type || figureType,
                  name: foundLabel?.name,
                });
              }}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    )
  }

  return (
    <Modal
          onClose={handleClose}
          open={modalEnabled}
        >
        <Modal.Header>Replace Label</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Grid>
              <Grid.Row columns={3}>
                <Grid.Column><strong>From</strong></Grid.Column>
                <Grid.Column></Grid.Column>
                <Grid.Column><strong>To</strong></Grid.Column>
              </Grid.Row>
            </Grid>
            {figureTypes.map((figureType, idx) => (returnRowLabel(figureType, idx)))}
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button color='red' onClick={handleClose}>
            Cancel
          </Button>
          <Button
            disabled={originalLabelIds.map(l => l.id) === selectedLabels.map(l => l.id)}
            onClick={() => {
              if (handleClick) handleClick(selectedLabels);
              onClose();
            }}
            content="Yes, swap label"
            labelPosition='right'
            icon='checkmark'
            positive
          />
        </Modal.Actions>
      </Modal>
	);
}
export default ReplaceLabelModal;
