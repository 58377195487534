import React, {useState, useEffect} from 'react';
import { Segment, Button, Icon, Grid, Form, TextArea, Input, Dropdown } from 'semantic-ui-react';
import {apiCall} from "../services/api";

const AssignedCompany = ({project, onSaveCompany, currentUserId, basic=false, blankSpace = false, companyName=""})=>{

	const [editMode, setEditMode] = useState(false);
	const [hovered, setHovered] = useState(false);
	const [allCompanyObjs, setAllCompanyObjs] = useState([]);
	const [companyId, setCompanyId] = useState("");
	const [loading, setLoading] = useState(false);

	const onToggleEdit = ()=>{
		setHovered(!hovered);
	};
	const onGetCompanies = async () =>{
		 const foundCompanies = await apiCall("GET",`${process.env.REACT_APP_PRODUCTION_SERVER_URL}/api/admin/${currentUserId}/companies`);
		 return foundCompanies;
	};
	const onOpenEditMode = async ()=>{
		setEditMode(true);
	};

	useEffect(()=>{
		const getComps = async ()=>{
			const foundCompanies = await onGetCompanies();
			setAllCompanyObjs(foundCompanies);
		};
		try{
			getComps();
		}catch(err){
			console.log(err);
		}
	},[]);

	let companyListOptions = allCompanyObjs.map((companyObj)=>{
		return {
			key:companyObj._id,
			text:`${companyObj.name}`,
			value:companyObj._id
		}
	});

	
	if (blankSpace) {
        companyListOptions = [
            { key: 'blank', text: 'No company', value: 'null' },
            ...companyListOptions
        ];
    }

	const onCompanySelect = (event, data)=>{
		setCompanyId(data.value);
	};

	const onSave = ()=>{
		setLoading(true);
		onSaveCompany(companyId);
		setLoading(false);
		setEditMode(false);
	};

	if (!companyName) {
		let companyName = "None";
	}
	

	if("companyId" in project && project.companyId){
		const foundCompany = allCompanyObjs.find((obj)=>{
			return obj._id === project.companyId;
		});
		if(foundCompany){
			companyName = foundCompany.name;
		}
	}

	return (
		<Segment onMouseOver={onToggleEdit} textAlign='left' onMouseOut={onToggleEdit} basic={basic}>
			{
				editMode?
				(
					<Grid>
						<Grid.Row  verticalAlign='middle' columns={2}>
							<Grid.Column>
								<Segment basic>
									<p> Assign Company (Only for Client-Facing Projects)</p>
								</Segment>  
							</Grid.Column>
							<Grid.Column>
								<Segment basic >
									<Dropdown onChange={onCompanySelect} placeholder='Assigned Company' search selection options={companyListOptions} />
								</Segment>  
							</Grid.Column>		
						</Grid.Row>
						<Grid.Row verticalAlign='middle' columns={2}>
							<Grid.Column>
								<Segment basic>
									<Button onClick={()=> setEditMode(false)} color='red'>Cancel</Button>
								</Segment>  
							</Grid.Column>
							<Grid.Column>
								<Segment basic>
									<Button loading={loading} onClick={onSave} color='green'>Save</Button>
								</Segment>  
							</Grid.Column>	
						</Grid.Row>
					</Grid>
				)
				:
				(
					<Grid>
						<Grid.Row  verticalAlign='middle' columns={2}>
							<Grid.Column>
								<Segment basic>Assigned Company (Only for Client-Facing Projects): {`${companyName ? companyName : "None"}`}</Segment>  
							</Grid.Column>
							<Grid.Column>
								<Segment basic >
									<Icon className="hoveredWidget" name="edit" onClick={onOpenEditMode}></Icon>
								</Segment>  
							</Grid.Column>		
						</Grid.Row>
					</Grid>
				)
			}
		</Segment>
		);
};

export default AssignedCompany;