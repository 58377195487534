import React, { useState, useEffect } from 'react';
import { useCreateProjectContext } from '../context/CreateProjectContext';
import AboutProject from '../components/create-project-components/AboutProject';
import Calibrate from '../components/create-project-components/Calibrate';
import ConfigureDataProject from '../components/create-project-components/ConfigureDataProject';
import Preview from '../components/create-project-components/Preview';
import UploadFiles from '../components/create-project-components/UploadFiles';
import axios from "axios";
import jwtDecode from "jwt-decode";
import { apiCall } from '../services/api';
import { useHistory } from 'react-router-dom';
import GlobalSideBar from '../components/global-navbar/GlobalSideBar';

import {
    Container,
    Message,
    Segment,
    Step,
    StepGroup,
    StepTitle,
} from 'semantic-ui-react';

import '../styles/CreateProject.css';

const CreateProject = ({ currentUser }) => {
    const history = useHistory();

    const id = currentUser.user.id
    const role = currentUser.user.role

    const { stepsCompleted, setStepsCompleted, companyId, setCompanyId } = useCreateProjectContext();
    const [activeStep, setActiveStep] = useState(1);
    const [bucketName, setBucketName] = useState('');

    const [errors, setErrors] = useState({});
    const [errorMessage, setErrorMessage] = useState('');

    const handleNextStep = () => {
        setActiveStep((prevStep) => Math.min(prevStep + 1, numberOfSteps));
        setStepsCompleted((prevSteps) => ({
            ...prevSteps,
            [activeStep]: true
        }));
    };

    const handlePrevStep = () => {
        setActiveStep((prevStep) => Math.max(prevStep - 1, 1));
    };

    const handleStepClick = (stepNumber) => {
        setActiveStep(stepNumber);
    };

    const isStepComplete = (stepNumber) => {
        return stepsCompleted[stepNumber];
    };

    const numberOfSteps = 5;

    const initialProjectContext = {
        activeStep,
        setActiveStep
    }

    useEffect(() => {
        const token = localStorage.getItem('enginJwtToken');
        if (token) {
            const decodedJwt = jwtDecode(token);
            if (decodedJwt.companyIds && decodedJwt.companyIds.length > 0) {
                setCompanyId(decodedJwt.companyIds[0]);
                getCompGcsBucketName(decodedJwt.companyIds[0])
            }
        }
    }, []);

    const onNewProject = async () => {
        let currentUserId = null;
        if (currentUser && "user" in currentUser) {
            currentUserId = currentUser?.user?.id;
        }
        const newProject = await apiCall("post", `${process.env.REACT_APP_PRODUCTION_SERVER_URL}/api/projects`, { createdBy: currentUserId });
        if (newProject) {
            history.push("/admin");
        }
    }

    const getCompGcsBucketName = async (companyId) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_PRODUCTION_SERVER_URL}/api/companies/${companyId}/getCompanyById`);
            const bucketName = response.data.compGcsBucketName;
            setBucketName(bucketName)
            return bucketName;
        } catch (error) {
            console.error('Error fetching company GCS bucket name:', error);
            if (error.response) {
                console.error('Error response:', error.response.data);
                setErrorMessage(`Error: ${error.response.data.message}`);
            } else if (error.request) {
                console.error('No response received:', error.request);
                setErrorMessage(`Error: ${error.response.data.message}`);
            } else {
                console.error('Request setup error:', error.message);
                setErrorMessage(`Error: ${error.response.data.message}`);
            }
            throw error;
        }
    };

    if (process.env.REACT_APP_USE_SIDEBAR === 'true'){
        return (
            <div style={{ display: 'flex', height: '100vh', boxSizing: 'border-box'}}>

                    <div style={{ flexShrink: 0, backgroundColor: '#f4f4f4', padding: '1em' }}>
                        <GlobalSideBar currentUser={currentUser} />
                    </div>
                    <div style={{ flex: 1, padding: '1em', boxSizing: 'border-box' }}>
                        <div className='main-title'>
                            <h3>Create your project</h3>
                        </div>
                        <Container fluid className="white-container" >
                            <div>
                                <StepGroup attached='top' fluid>
                                    {[...Array(numberOfSteps)].map((_, index) => (
                                        <Step
                                            key={index}
                                            active={activeStep === index + 1}
                                            disabled={!isStepComplete(index + 1)}
                                            onClick={() => handleStepClick(index + 1)}
                                        >
                                            <StepTitle>
                                                {activeStep === index + 1 && `${index + 1} . `}
                                                {index === 0 && "About the project"}
                                                {index === 1 && "Configure data"}
                                                {index === 2 && "Upload files"}
                                                {index === 3 && "Calibrate"}
                                                {index === 4 && "Preview & analyze"}
                                            </StepTitle>
                                        </Step>
                                    ))}
                                </StepGroup>
                            </div>
                            <Segment attached>
                                {errorMessage && <Message error content={errorMessage} />}
                                {activeStep === 1 && <AboutProject handleNextStep={handleNextStep} role={role} id={id} bucketName={bucketName} />}
                                {activeStep === 2 && <ConfigureDataProject handlePrevStep={handlePrevStep} handleNextStep={handleNextStep} bucketName={bucketName} />}
                                {activeStep === 3 && <UploadFiles handlePrevStep={handlePrevStep} handleNextStep={handleNextStep} />}
                                {activeStep === 4 && <Calibrate handlePrevStep={handlePrevStep} handleNextStep={handleNextStep} />}
                                {activeStep === 5 && <Preview handlePrevStep={handlePrevStep} bucketName={bucketName} companyId={companyId} />}
                            </Segment>
                            {(currentUser?.user?.role === "admin" || currentUser?.user?.role === "labeler_lead") && (
                                <p style={{ color: "red", cursor: "pointer" }} onClick={onNewProject}>Skip project creation workflow</p>
                            )}
                        </Container>
                    </div>
                </div>
        )
    }else{
        return (
            <>
            <Container>
              <div className='main-title'>
                  <h3>Create your project</h3>
              </div>
          </Container>
          <Container fluid className="white-container">
              <div>
                  <StepGroup attached='top' fluid>
                      {[...Array(numberOfSteps)].map((_, index) => (
                          <Step
                              key={index}
                              active={activeStep === index + 1}
                              disabled={!isStepComplete(index + 1)}
                              onClick={() => handleStepClick(index + 1)}
                          >
                              <StepTitle>
                                  {activeStep === index + 1 && `${index + 1} . `}
                                  {index === 0 && "About the project"}
                                  {index === 1 && "Configure data"}
                                  {index === 2 && "Upload files"}
                                  {index === 3 && "Calibrate"}
                                  {index === 4 && "Preview & analyze"}
                              </StepTitle>
                          </Step>
                      ))}
                  </StepGroup>
              </div>
              <Segment attached>
                  {errorMessage && <Message error content={errorMessage} />}
                  {activeStep === 1 && <AboutProject handleNextStep={handleNextStep} role={role} id={id} bucketName={bucketName}></AboutProject>}
                  {activeStep === 2 && <ConfigureDataProject handlePrevStep={handlePrevStep} handleNextStep={handleNextStep} bucketName={bucketName}></ConfigureDataProject>}
                  {activeStep === 3 && <UploadFiles handlePrevStep={handlePrevStep} handleNextStep={handleNextStep}></UploadFiles>}
                  {activeStep === 4 && <Calibrate handlePrevStep={handlePrevStep} handleNextStep={handleNextStep} ></Calibrate>}
                  {activeStep === 5 && <Preview setActiveStep={setActiveStep} handlePrevStep={handlePrevStep} bucketName={bucketName} companyId={companyId}></Preview>}
              </Segment>
              {(currentUser?.user?.role === "admin" || currentUser?.user?.role === "labeler_lead") && (
                  <p style={{ color: "red", cursor: "pointer" }} onClick={onNewProject}>Skip project creation workflow</p>
              )}
          </Container>
          </>
        )
    }}

export default CreateProject;
