import React, {Fragment, useEffect,useState} from 'react';
import {apiCall} from "../services/api";
import { useParams } from 'react-router-dom';
import RatingCanvas from './RatingCanvas';

const ContinuousLabelingApp = (props) => {
	const imgUrl = "images/background-image-hero.jpg";
	let backgroundImg = `linear-gradient(45deg, rgba(0, 26, 255, 0.7) 1%, rgb(99, 97, 238) 30%, rgb(119, 99, 213) 69%, rgb(161, 103, 161) 100%),url(${imgUrl})`;
	const { projectId} = useParams();
	console.log("projectId",projectId);
	const [project,setProject] = useState({});
	const [imageList,setImageList] = useState([]);
	const getProject = async ()=>{
		try{
			const project = await apiCall("get",`${process.env.REACT_APP_PRODUCTION_SERVER_URL}/api/projects/${projectId}`);
			setProject(project);
			let params = {};
			if(project.images.length > 20){
				const slicedList = project.images.slice(0,19);
				params = {imageIds:slicedList};
			}else{
				params = {imageIds:project.images};

			}
			const images = await apiCall("get",`${process.env.REACT_APP_PRODUCTION_SERVER_URL}/api/images/getByIdList`,{params});
			setImageList(images);
		}catch(err){
			console.log(err);
		}
	};

	useEffect(()=>{
		getProject();
	},[]);

	const imageUrlList = imageList.map((imgObj)=>{
		return imgObj.externalLink;
	});

	return (
		<Fragment>
			<header className="App-hero" style={{
		          "minHeight":"75px",
		          "backgroundImage":backgroundImg,
		          "color":"white"
		        }}>
			</header>
			<RatingCanvas imageUrlList={imageUrlList}/>
		</Fragment>
	);
}
export default ContinuousLabelingApp;
