import React from 'react'
import { Segment } from 'semantic-ui-react';
import MetadataHeader from './label-metadata-components/MetadataHeader';
import MetadataItems from './label-metadata-components/MetadataItems';
import MetadataLabels from './label-metadata-components/MetadataLabels';
import { apiCall } from '../services/api';

const BASE_URL = process.env.REACT_APP_PRODUCTION_SERVER_URL;

const ProjectLabelMetadata = (props) => {

  const [selectedItem, setSelectedItem] = React.useState()
  const [labelMetadata, setLabelMetadata] = React.useState()
  const [items, setItems] = React.useState([]);

  const getLabelMetadata = async () => {
    const response = await apiCall("GET", `${BASE_URL}/api/label-metadata/${props.projectId}`);
    if (response) {
      setLabelMetadata(response);
      setItems(response.items ?? []);
    }
  }

  const putLabelMetadata = async (items) => {
    if (labelMetadata) {
      const response = await apiCall("PUT", `${BASE_URL}/api/label-metadata/${props.projectId}`, {
        ...labelMetadata,
        items,
      });
    }
  }

  React.useEffect(() => {
    if (props.projectId) getLabelMetadata();
  }, [])

  React.useEffect(() => {
    putLabelMetadata(items);
  }, [JSON.stringify(items)])

  return (
    <Segment.Group>
      {
        ["admin", "labeler_lead", "client"].indexOf(props.userRole) >= 0 &&
        <Segment textAlign='left'>
          <MetadataHeader />
          {
            labelMetadata?.items && (
              selectedItem !== undefined ?
                <MetadataLabels
                  itemsState={[items, setItems]}
                  labels={props.labels}
                  selectedItemState={[selectedItem, setSelectedItem]}
                />
                :
                <MetadataItems
                  itemsState={[items, setItems]}
                  setSelectedItem={setSelectedItem}
                />
            )
          }
        </Segment>
      }
    </Segment.Group>
  )
}

export default ProjectLabelMetadata;