import React from "react";
import {Form, Button} from 'semantic-ui-react';
import '../App.css';
import ReactGA from 'react-ga';

class FormSpreeForm extends React.Component {
constructor(props){
		super(props);
		this.state = {};
    
	}
  render() {
    return (
      
        <Form style={{"display":"inline-block"}} size="huge" action="https://formspree.io/engin.ai.solutions@gmail.com" method="POST">
        	<Form.Group  widths='equal'>
	        	<Form.Field required>
            <label htmlFor="email" style={{display:"none"}}>Email</label>
	        		<Form.Input required id="email" name="name" type="email" placeholder='Email*' />
	        	</Form.Field>
        		
      			<label htmlFor="company_name" style={{display:"none"}}>Company Name</label>
      			<Form.Input name="name" id="company_name"  placeholder='Company Name' />
        	</Form.Group>
        	<Form.Field required>
              <label htmlFor="comments"  style={{display:"none"}}>Comments</label>
	        		<Form.TextArea rows={10} id="comments" required name="name" placeholder='Comment*' />
	        	</Form.Field>
        	<Button onClick={()=>{
                ReactGA.event({
                  category: 'Lead',
                  action: 'Contact Us Form Start Submission',
                  label: 'Start of Contact Us Submission'
                });
              }} 
              size="huge" type="submit" className="CTA-2">Contact Us</Button>
        </Form>
     
    );
  }
}

export default FormSpreeForm;