import React from 'react';
import { Header, Icon, Segment, Table } from 'semantic-ui-react';

const style = {
  height: '92vh',
  overflowY: 'scroll',
  overflowX: 'hidden',
  borderRight: '1px solid #ccc',
};
const headerIconStyle = { fontSize: '0.8em', float: 'right' };

export default function HotkeysPanel({ labels, onClose,projectCustomShortcuts=null }) {

  return (
    <div style={style}>
      <Header as="h2" attached="top">
        Hotkeys
        <Icon link name="close" style={headerIconStyle} onClick={onClose} />
      </Header>
      <Segment attached>
        {projectCustomShortcuts && (
          <>
          <Header as="h3"> Custom Shortcuts </Header>
            <Table>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Label</Table.HeaderCell>
                  <Table.HeaderCell>Key</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {projectCustomShortcuts.map((shortcut,idx)=>{
                  if(shortcut){
                    return(
                      <Table.Row key={shortcut?._id || idx}>
                        <Table.Cell key={`${shortcut?._id}_${idx*100}` || `${idx*100}`}>{shortcut?.label}</Table.Cell>
                        <Table.Cell key={`${shortcut?._id}_${idx*1000}` || `${idx*1000}`}>{shortcut?.shortcut}</Table.Cell>
                      </Table.Row>
                    );
                  }
                })}
              </Table.Body>
            </Table>
            </>
            )}
        <Header as="h3"> General </Header>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Action</Table.HeaderCell>
              <Table.HeaderCell>Key</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            <Table.Row>
              <Table.Cell>Complete shape</Table.Cell>
              <Table.Cell>f or Right Click</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Replace Label</Table.Cell>
              <Table.Cell>r</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Hide Label</Table.Cell>
              <Table.Cell>h or toggle off from the side panel</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Delete figure</Table.Cell>
              <Table.Cell>Delete</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Cancel selection</Table.Cell>
              <Table.Cell>Escape</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Undo the edit</Table.Cell>
              <Table.Cell>Ctrl+Z</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Move Image</Table.Cell>
              <Table.Cell>Spacebar</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
        <Header as="h3"> Navigation </Header>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Action</Table.HeaderCell>
              <Table.HeaderCell>Key</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            <Table.Row>
              <Table.Cell>Zoom In</Table.Cell>
              <Table.Cell>+/=</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Zoom Out</Table.Cell>
              <Table.Cell>-</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Move the Image</Table.Cell>
              <Table.Cell>←→↑↓</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </Segment>
    </div>
  );
}
