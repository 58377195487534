import React from 'react';
import { Popup } from 'semantic-ui-react';

const SidebarCategory = ({ category, isSidebarExpanded, menuItems, location, icons, handleItemClick }) => {

    return (
        <>
            {isSidebarExpanded && (
                <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                    <p style={{ margin: '10px' }}>{category}</p>
                </div>
            )}
            {menuItems.filter(item => item.category === category).map(item => {
                if (!item.hideTitle) {
                    const isActive = location.pathname === item.link;
                    
                    return (
                        <div
                            key={item._id}
                            onClick={() => handleItemClick(item.link)}
                            style={{
                                padding: '10px 30px',
                                position: 'relative',
                                cursor: 'pointer',
                                transition: 'background-color 0.3s ease',
                                backgroundColor: isActive ? 'rgba(255,255,255,.08)' : 'transparent',
                            }}
                            onMouseEnter={(e) => e.currentTarget.style.backgroundColor = 'rgba(255,255,255,.08)'}
                            onMouseLeave={(e) => e.currentTarget.style.backgroundColor = isActive ? 'rgba(255,255,255,.08)' : 'transparent'}
                        >
                            {isSidebarExpanded ? (
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <img src={icons[item.icon]} alt={item.title} style={{ width: '20px', height: '20px', marginRight: '10px' }} />
                                    <span style={{ color: isActive ? '#FFFFFF' : '#98A2B3' }}>{item.title}</span>
                                </div>
                            ) : (
                                <Popup
                                    trigger={
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <img src={icons[item.icon]} alt={item.title} style={{ width: '20px', height: '20px', marginRight: '10px' }} />
                                        </div>
                                    }
                                    content={item.title}
                                    position="right center"
                                    style={{
                                        pointerEvents: 'none',
                                        zIndex: 1000,
                                    }}
                                    on="hover"
                                    hideOnScroll
                                />
                            )}
                        </div>
                    );
                }
                return null;
            })}
        </>
    );
};

export default SidebarCategory;
