import React, { useState, useEffect } from 'react';
import { Image, Menu, Popup, Sidebar } from 'semantic-ui-react';
import { useLocation } from 'react-router-dom';
import HomeIcon from '../../images/global-images/menus/home-icon.svg';
import ProjectsIcon from '../../images/global-images/menus/projects-icon.svg';
import SettingsIcon from '../../images/global-images/menus/settings-icon.svg';
import MembersIcon from '../../images/global-images/menus/members-icon.svg';
import HideSide from '../../images/global-images/menus/hideside-icon.svg';
import DiagnosticsIcon from '../../images/global-images/menus/diagnostics-icon.svg';
import AdminIcon from '../../images/global-images/menus/admin-icon.svg';
import HideSideCollapsed from '../../images/global-images/menus/hidesidecollapsed.svg';
import EnginLogo from '../../images/full-color-logo-only-vector-01.svg';
import { apiCall } from '../../services/api';
import axios from 'axios';
import { connect } from "react-redux";
import { setSidebarStatus } from "../../store/actions/users";
import ProjectOverview from './ProjectOverview';
import SidebarCategory from './SidebarCategory';

const icons = {
    "home-icon.svg": HomeIcon,
    "projects-icon.svg": ProjectsIcon,
    "settings-icon.svg": SettingsIcon,
    "members-icon.svg": MembersIcon,
    "diagnostics-icon.svg": DiagnosticsIcon,
    "admin-icon.svg": AdminIcon
};

const GlobalSideBar = ({ currentUser, setSidebarStatus, navigationSettings: { isSidebarExpanded },displayMatchingPathCategoriesOnly=false }) => {
    const [menuItems, setMenuItems] = useState([]);
    const [menuCategories, setMenuCategories] = useState([]);
    const [companyData, setCompanyData] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchMenuItems = async () => {
            try {
                const response = await apiCall('get', `${process.env.REACT_APP_PRODUCTION_SERVER_URL}/api/admin/menus`);
                setMenuItems(response);
            } catch (err) {
                setError(err.message);
            }
        };

        fetchMenuItems();
    }, []);

    useEffect(() => {
        const fetchMenuCategories = async () => {
            try {
                const response = await apiCall('get', `${process.env.REACT_APP_PRODUCTION_SERVER_URL}/api/admin/menu-categories`);
                setMenuCategories(response);
            } catch (err) {
                setError(err.message);
            }
        };

        fetchMenuCategories();
    }, []);

    const { user } = currentUser;
    const { company, companyIds } = user;
    const companyId = (user?.companyIds && user.companyIds.length > 0) ? user.companyIds[0]._id : '';
    const companyInitial = company ? company.charAt(0) : '';

    const toggleSidebar = () => {
        setSidebarStatus(!isSidebarExpanded)
    }

    useEffect(() => {
        const fetchCompanyById = async () => {
            try {
                if (companyId) {
                    const response = await apiCall('get', `${process.env.REACT_APP_PRODUCTION_SERVER_URL}/api/companies/${companyId}/getCompanyById`);
                    setCompanyData(response);
                }
            } catch (err) {
                setError(err.message);
            }
        };

        fetchCompanyById();
    }, [companyId]);


    const { name, logoUrl } = companyData;
    const companyName = name || company;
    const isNotClientRole = user.role !== 'client';
    const logoSrc = isNotClientRole ? EnginLogo : logoUrl;


    const handleItemClick = (link) => {
        window.location.href = `#${link}`;
    };

    // Path location
    const location = useLocation();
    const currentPath = location.pathname;
    const adminProjectPathRegex = /^\/admin\/[a-fA-F0-9]{24}$/;
    const isAdminProjectPath = adminProjectPathRegex.test(currentPath);

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (companyData === '') {
        return <div>Loading...</div>;
    }


    const filteredCategories = menuCategories.filter(category => {
        const hasItems = menuItems.some(item => item.category === category.name);
        if (!hasItems) {
            return false;
        }
        if (category.pagePath && category.pagePath === currentPath) {
            return true;
        }else if(!(category.pagePath) && !(displayMatchingPathCategoriesOnly)){
            return true;
        }else{
            return false;
        }
    });

    return (
        <>
            <div style={{ display: 'flex', height: '100vh' }}>
                {/* Sidebar */}
                <Sidebar
                    as={Menu}
                    animation="overlay"
                    icon="labeled"
                    inverted
                    vertical
                    visible={true}
                    style={{
                        position: 'fixed',
                        top: '0',
                        left: '0',
                        height: '100vh',
                        zIndex: 500,
                        transition: 'width 0.5s ease',
                        overflow: 'hidden',
                        backgroundColor: '#101D42',
                        color: '#98A2B3',
                        width: isSidebarExpanded ? '250px' : '72px'
                    }}
                >

                    {!isAdminProjectPath ? (
                        <>
                            {/* Sidebar content */}
                            <Menu.Item style={{ padding: '10px', textAlign: 'center', border: 'none' }}>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    marginTop: '100px',
                                    marginBottom: '12px',
                                    borderRadius: '8px',
                                    background: isSidebarExpanded ? 'rgba(255,255,255,.08)' : 'transparent',
                                    paddingTop: '15px'
                                }}>
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: isSidebarExpanded ? 'flex-start' : 'center',
                                        marginBottom: '10px',
                                        marginLeft: isSidebarExpanded ? '30px' : '0px',
                                        width: '100%'
                                    }}>
                                        {logoSrc ? (
                                            <Image
                                                src={logoSrc}
                                                alt="Company Logo"
                                                style={{
                                                    width: '35px',
                                                    height: '35px',
                                                    borderRadius: '50%',
                                                    marginRight: '10px',
                                                    objectFit: 'cover',
                                                    backgroundColor: '#fff'
                                                }}
                                            />
                                        ) : (
                                            <div
                                                style={{
                                                    width: '35px',
                                                    height: '35px',
                                                    borderRadius: '50%',
                                                    backgroundColor: '#007bff',
                                                    color: '#fff',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    fontSize: '25px',
                                                    fontWeight: 'bold',
                                                    marginRight: '10px',
                                                    paddingLeft: '0px'
                                                }}
                                            >
                                                <span style={{ margin: 0, textTransform: 'capitalize' }}>{companyInitial}</span>
                                            </div>
                                        )}
                                        {isSidebarExpanded && <p style={{ margin: 0, textTransform: 'capitalize' }}>{companyName}</p>}
                                    </div>

                                    {isSidebarExpanded && (
                                        <>
                                            <div style={{
                                                width: '100%',
                                                height: '1px',
                                                backgroundColor: 'rgba(255, 255, 255, 0.2)',
                                                margin: '10px 0',
                                            }}>
                                            </div>
                                            <div style={{
                                                padding: '0px 5px 10px 5px',
                                                display: 'flex',
                                                alignItems: 'center'
                                            }}>
                                                <span style={{
                                                    fontSize: '25px',
                                                    marginRight: '5px',
                                                }}>
                                                    +
                                                </span>
                                                Invite members
                                            </div>
                                        </>
                                    )}
                                </div>
                            </Menu.Item>

                            {/* Menu Items */}
                            <div style={{ marginTop: '15px' }}>
                                {filteredCategories.map(category => (
                                    <SidebarCategory
                                        key={category._id}
                                        category={category.name}
                                        isSidebarExpanded={isSidebarExpanded}
                                        menuItems={menuItems}
                                        location={location}
                                        icons={icons}
                                        handleItemClick={handleItemClick}
                                    />
                                ))}
                            </div>
                        </>
                    ) : (
                        <>
                            <ProjectOverview user={user} isSidebarExpanded={isSidebarExpanded}></ProjectOverview>
                        </>
                    )}

                    <div
                        onClick={toggleSidebar}
                        style={{
                            padding: isSidebarExpanded ? '20px 30px 10px 10px' : '20px 30px',
                            position: 'absolute',
                            width: '100%',
                            bottom: '20px',
                            cursor: 'pointer',
                            borderTop: '1px solid rgba(255,255,255,.08)',
                            display: 'flex',
                            alignItems: 'left',
                            justifyContent: isSidebarExpanded ? 'left' : 'center',
                        }}
                    >
                        <img src={isSidebarExpanded ? HideSide : HideSideCollapsed} alt="Toggle Sidebar" style={{ width: '20px', height: '20px' }} />
                        {isSidebarExpanded && <span style={{ marginLeft: '10px', color: '#98A2B3' }}>Hide sidebar</span>}
                    </div>

                </Sidebar>
                <div style={{ marginLeft: isSidebarExpanded ? '250px' : '72px', transition: 'margin-left 0.5s ease', flex: 1 }}>
                </div>

            </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    currentUser: state.currentUser,
    navigationSettings: state.navigationSettings,
    language: state.language
});

export default connect(mapStateToProps, { setSidebarStatus })(GlobalSideBar);