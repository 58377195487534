import React  from 'react';
import {Segment, Header,Icon, Checkbox} from 'semantic-ui-react';
import _ from 'lodash';
import ProjectFilters from './ProjectFilters';
import ProjectShortcuts from './ProjectShortcuts';



const sortByKey = (list, key) => {
  return _.orderBy(list, [key]);
}


const ProjectConfiguration = ({labelsList,filterNone,project,userRole})=>{
	let sortedLabels =  sortByKey(labelsList,'name');
	if(filterNone){
		sortedLabels.unshift({name:'None','id':'nonelabel'});
	}
	const imagesOrVideosUI = (projectDataFormat = 'image')=>{
		if(projectDataFormat === 'image'){	
			return(
				<>
					<Segment textAlign='left'>
						<Header as='h4'><Icon name='filter'/>Rating Filters</Header>
						<p>Filter images with the selected distresses to aid the Quality Control process. This will either exclude or show only images with those distresses when pressing "Skip", "Back", or "Submit" from the Canvas page. You must select the filter type and the distress to filter for the field to be saved. <strong>Please wait a few minutes for the filters to be applied.</strong></p>
						<ProjectFilters dataLabels={sortedLabels} project={project}/>
					</Segment>
					<Segment textAlign='left'>
						<Header as='h4'><Icon name='keyboard'/>Custom Keyboard Shortcuts</Header>
						<p>Only shortcuts with a keyboard shortcut and an assigned disterss code will be saved.</p>
						<ProjectShortcuts dataLabels={sortedLabels} project={project}/>
					</Segment>
					</>
				);
		}else if(projectDataFormat === 'video'){
			return(
				<>
					<Segment textAlign='left'>
						<Header as='h4'><Icon name='keyboard'/>Custom Keyboard Shortcuts</Header>
						<p>Only shortcuts with a keyboard shortcut and an assigned disterss code will be saved.</p>
						<ProjectShortcuts dataLabels={sortedLabels} project={project}/>
					</Segment>
					</>
				);
		}else{
			return (null);
		}
	};

	return(
		<Segment.Group>
			{["admin","labeler_lead","client"].indexOf(userRole) >= 0 && (
				imagesOrVideosUI(project?.projectLabelingDataFormat)
				)			
		}
			{/*<Segment textAlign='left'>Bottom</Segment>*/}
		</Segment.Group>
		);	
}; 

export default ProjectConfiguration;