import { SET_USER_LANGUAGE } from "../actionTypes";

const language = (state = {}, action) => {
  //debugger;
  switch (action.type) {
  	case SET_USER_LANGUAGE:
		return action.language
    default:
      return state;
  }
};

export default language;