import { combineReducers } from "redux";
import currentUser from "./currentUser";
import errors from "./errors";
import language from "./language";
import navigationSettings from "./navigation";

const rootReducer = combineReducers({
  currentUser,
  errors,
  language,
  navigationSettings
});

export default rootReducer;
