import React, { Component } from 'react';

import { Header, Form, Segment } from 'semantic-ui-react';
import AuthForm from '../components/AuthForm';

export default class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      error: null,
    };
    this.onSubmit = this.onSubmit.bind(this);
  }

  render() {
    const authUser = this.props.authUser? this.props.authUser : (()=>console.log("No auth user"));
    const browserLang = this.props.language ?this.props.language : "en";
    const removeError = this.props.removeError? this.props.removeError : (()=>console.log("No remove error")); 
    return (
        <AuthForm removeError={removeError} removeAdditionalField={true} language={browserLang} buttonText={browserLang === "en" ? "Log in" : "Ingresa"} heading={browserLang === "en" ? "Welcome Back!" : "¡Aprende Ahora!"} onAuth={authUser} {...this.props}/>
    );
  }
}
