import { SET_USER_SIDEBAR } from "../actionTypes";

const navigationSettings = (state = {}, action) => {
  //debugger;
  switch (action.type) {
  	case SET_USER_SIDEBAR:
		return {isSidebarExpanded: action.isSidebarExpanded}
    default:
      return state;
  }
};

export default navigationSettings;