import React, { createContext, useContext, useState } from 'react';

const CreateProjectContext = createContext({});

export const CreateProjectProvider = ({ activeStep, setActiveStep,children }) => {

    // Step 1: Project information
    const [formDataAboutContext, setFormDataAboutContext] = useState({
        projectName: '',
        modelType: '',
        selectedOptions: [],
    });

    const updateFormDataAbout = (data) => {
        setFormDataAboutContext(data);
    };

    const [aboutBucketName, setAboutBucketName] = useState('');

    // Step 2: Configuration information
    const [formDataConfigureContext, setFormDataConfigureContext] = useState({
        selectedUnit: 'ft',
        selectedCamera: 'Panoramic',
        selectedSensor: 'GoPro',
        visibleLaneMarks: false,
        selectedLocation: 'Centerline',
        selectedDataFormat:'image',
        pavementRatingMethodology: 'PCI (ASTM D6433)'
    });

    const [selectedKeys, setSelectedKeys] = useState(new Set());
    const [urlConfigFiles, setUrlConfigFiles] = useState('');

    const updateFormDataConfigure = (data) => {
        setFormDataConfigureContext(prevState => ({
            ...prevState,
            ...data
        }));
    };

    const pavementRatingMethodologies = [
        'PCI (ASTM D6433)',
        'VIZIR',
        'INVIAS'
    ];

    const [uploadedFiles, setUploadedFiles] = useState({
        shp: null,
        shx: null,
        kml: null,
        csv: null,
    });

    const [baseUrlFile, SetBaseUrlFile] = useState('');

    const [shpFieldFor, setShpFieldFor] = useState({
        sphUniqueIdentifier: { text: 'SHP Unique Identifier', value: '' },
        roadName: { text: 'Road Name', value: '' },
        roadSurface: { text: 'Road Surface', value: '' },
        fromAddressNumber: { text: 'From Address Number', value: '' },
        toAddressNumber: { text: 'To Address Number', value: '' },
        speedLimit: { text: 'Speed Limit', value: '' },
        length: { text: 'Length', value: '' },
        width: { text: 'Width', value: '' },
        functionalClassification: { text: 'Functional Classification', value: '' },
        observedRSL: { text: 'Observed RSL', value: '' },
    });
    

    const updateUploadedFiles = (files) => {
        setUploadedFiles(files);
    };

    // File upload states

    const [selectedFiles, setSelectedFiles] = useState([]);
    const [uploadComplete, setUploadComplete] = useState(false);
    const [errorMessages, setErrorMessages] = useState([]);
    const [fileStructure, setFileStructure] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [currentPath, setCurrentPath] = useState([]);
    const [companyId, setCompanyId] = useState(null);
    // File upload functions
    const updateSelectedFiles = (files) => {
        setSelectedFiles(files);
    };

    const updateUploadComplete = (value) => {
        setUploadComplete(value);
    };

    const updateErrorMessages = (messages) => {
        setErrorMessages(messages);
    };

    const updateFileStructure = (structure) => {
        setFileStructure(structure);
    };

    const updateCurrentPage = (page) => {
        setCurrentPage(page);
    };

    const updateCurrentPath = (path) => {
        setCurrentPath(path);
    };

    // Calibration functions
    const [calibrationData, setCalibrationData] = useState(null);
    const [calibrationComplete, setCalibrationComplete] = useState(false);

    const updateCalibrationData = (data) => {
        setCalibrationData(data);
    };

    const [mediaType, setMediaType] = useState('video');

    const [currentStep, setCurrentStep] = useState(1);

    const handleNextStep = () => {
        setCurrentStep(prevStep => prevStep + 1);
    };

    const handlePrevStep = () => {
        setCurrentStep(prevStep => prevStep - 1);
    };
    const [stepsCompleted, setStepsCompleted] = useState({
        1: false,
        2: false,
        3: false,
        4: false,
        5: false
    });
    const [confirmed, setConfirmed] = useState();
    // Context value
    const contextValue = {
        formDataAboutContext,
        updateFormDataAbout,
        formDataConfigureContext,
        updateFormDataConfigure,
        selectedFiles,
        updateSelectedFiles,
        baseUrlFile, 
        SetBaseUrlFile,
        uploadComplete,
        uploadedFiles,
        updateUploadedFiles,
        urlConfigFiles,
        updateUploadComplete,
        errorMessages,
        setErrorMessages,
        updateErrorMessages,
        fileStructure,
        updateFileStructure,
        currentPage,
        updateCurrentPage,
        currentPath,
        updateCurrentPath,
        calibrationData,
        updateCalibrationData,
        calibrationComplete,
        setCalibrationComplete,
        mediaType,
        setMediaType,             
        selectedKeys,
        setSelectedKeys, 
        currentStep, 
        handleNextStep,
        handlePrevStep,
        confirmed,
        setConfirmed,
        activeStep, 
        setActiveStep,
        shpFieldFor, 
        setShpFieldFor,
        companyId,
        setCompanyId,
        aboutBucketName,
        setAboutBucketName,
        stepsCompleted, 
        setStepsCompleted,
        pavementRatingMethodologies
    };

    return (
        <CreateProjectContext.Provider value={contextValue}>
            {children}
        </CreateProjectContext.Provider>
    );
};

export const useCreateProjectContext = () => useContext(CreateProjectContext);

