import React, {useState, useEffect} from 'react';
import { Segment, Button, Icon, Grid, Form, TextArea, Input, Dropdown } from 'semantic-ui-react';
import {apiCall} from "../services/api";

const DropdownSelector = ({startingId, objectLookupKey, onSaveSelection, currentUserId,getOptionsApiUrl,primaryLabel,placeholderText,includeNull=false,optionsObjsList,getOptionsFromApi = false,basic=false})=>{
	const [editMode, setEditMode] = useState(false);
	const [hovered, setHovered] = useState(false);
	const [allOptionObjs, setallOptionObjs] = useState([]);
	const [optionId, setoptionId] = useState("");
	const [loading, setLoading] = useState(false);

	const onToggleEdit = ()=>{
		setHovered(!hovered);
	};
	const onGetOptions = async () =>{
		 const foundOptions = await apiCall("GET",getOptionsApiUrl);
		 return foundOptions;
	};
	const onOpenEditMode = async ()=>{
		setEditMode(true);
	};

	useEffect(()=>{
		if(getOptionsFromApi){
			const getOpts = async ()=>{
			const foundOptions = await onGetOptions();
			setallOptionObjs(foundOptions);
			};
			try{
				getOpts();
			}catch(err){
				console.log(err);
			}
		}else if(optionsObjsList){
			setallOptionObjs(optionsObjsList);
		}
		setoptionId(startingId);
	},[]);

	let optionsList = allOptionObjs.map((optionObj,idx)=>{
		return {
			key:"_id" in optionObj ? optionObj._id:"_id",
			text:"name" in optionObj ? `${optionObj.name}` : "name",
			value: "_id" in optionObj ?  optionObj._id : "value"
		}
	});

	if (includeNull) {
		optionsList.unshift({
			key:"nullOption",
			text:"None",
			value:null
		})
	}

	const onOptionSelect = (event, data)=>{
		setoptionId(data.value);
	};

	const onSave = ()=>{
		setLoading(true);
		onSaveSelection(optionId);
		setLoading(false);
		setEditMode(false);
	};
	let optionName = "None";
	const foundOption = allOptionObjs.find((obj)=>{
			return obj._id === optionId;
		});
		if(foundOption){
			optionName = foundOption.name;
		}

	return (
		<Segment onMouseOver={onToggleEdit} textAlign='left' onMouseOut={onToggleEdit} basic={basic}>
			{
				editMode?
				(
					<Grid>
						<Grid.Row  verticalAlign='middle' columns={2}>
							<Grid.Column>
								<Segment basic>
									<p> {`${primaryLabel}`}</p>
								</Segment>  
							</Grid.Column>
							<Grid.Column>
								<Segment basic >
									<Dropdown onChange={onOptionSelect} placeholder={`${placeholderText}`} search selection options={optionsList} />
								</Segment>  
							</Grid.Column>		
						</Grid.Row>
						<Grid.Row verticalAlign='middle' columns={2}>
							<Grid.Column>
								<Segment basic>
									<Button onClick={()=> setEditMode(false)} color='red'>Cancel</Button>
								</Segment>  
							</Grid.Column>
							<Grid.Column>
								<Segment basic>
									<Button loading={loading} onClick={onSave} color='green'>Save</Button>
								</Segment>  
							</Grid.Column>	
						</Grid.Row>
					</Grid>
				)
				:
				(
					<Grid>
						<Grid.Row  verticalAlign='middle' columns={2}>
							<Grid.Column>
								<Segment basic>{`${primaryLabel}`}: {`${optionName}`}</Segment>  
							</Grid.Column>
							<Grid.Column>
								<Segment basic >
									<Icon className="hoveredWidget" name="edit" onClick={onOpenEditMode}></Icon>
								</Segment>  
							</Grid.Column>		
						</Grid.Row>
					</Grid>
				)
			}
		</Segment>
		);
};

export default DropdownSelector;