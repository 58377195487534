import React, { useState, useEffect } from 'react';
import { useCreateProjectContext } from '../../context/CreateProjectContext';

import {
    Button,
    Breadcrumb,
    Container,
    Divider,
    Grid,
    Icon,
    Image,
    Message,
    Modal,
    Pagination } from 'semantic-ui-react';

import GeneralCanvasEditor from '../GeneralCanvasEditor';
import VideoCanvasEditor from '../VideoCanvasEditor';
import Directory from '../../images/directory.svg';
import ConfirmCalibration from '../../images/confirm-calibration.svg';

const Calibrate = ({ handlePrevStep, handleNextStep }) => {
    const { selectedFiles, fileStructure, updateCalibrationData, formDataConfigureContext, calibrationComplete, setCalibrationComplete } = useCreateProjectContext();
    const [currentPage, setCurrentPage] = useState(1);
    const [currentFolderPath, setCurrentFolderPath] = useState([]);
    const [selectedImages, setSelectedImages] = useState([]);
    const [open, setOpen] = useState(false);
    const [fileToProcess, setFileToProcess] = useState(null);
    const [shapeData, setShapeData] = useState({ points: [], dimensions: { height: '', width: '' } });
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [selectedFileName, setSelectedFileName] = useState('');
    const { selectedUnit } = formDataConfigureContext;

    const filesPerPage = 20;

    const handlePaginationChange = (e, { activePage }) => {
        setCurrentPage(activePage);
    };

    const handleFolderClick = (folder) => {
        setCurrentFolderPath([...currentFolderPath, folder]);
        setCurrentPage(1);
    };

    const handleBreadcrumbClick = (index) => {
        setCurrentFolderPath(currentFolderPath.slice(0, index + 1));
        setCurrentPage(1);
    };

    const handleFileSelectForProcessing = (file) => {
        setFileToProcess(file);
        setSelectedFileName(file.name);
        setOpen(true);
    };

    const getCurrentFolderContent = (structure, path) => {
        let current = structure;
        for (const folder of path) {
            current = current[folder];
        }
        return current;
    };

    const currentFolderContent = getCurrentFolderContent(fileStructure, currentFolderPath);

    const countFilesAndFoldersInFolder = (folderContent) => {
        let fileCount = 0;
        let folderCount = 0;

        const traverse = (folder) => {
            Object.keys(folder).forEach(key => {
                if (key === 'files') {
                    fileCount += folder[key].length;
                } else {
                    folderCount += 1;
                    traverse(folder[key]);
                }
            });
        };

        traverse(folderContent);
        return { fileCount, folderCount };
    };

    const renderBreadcrumb = () => {
        return (
            <Breadcrumb className='breadcrumb' style={{ marginTop: '20px', marginBottom: '20px' }}>
                <Breadcrumb.Section link onClick={() => setCurrentFolderPath([])}>
                    <Icon name='home' />
                </Breadcrumb.Section>
                {currentFolderPath.map((folder, index) => (
                    <React.Fragment key={index}>
                        <Breadcrumb.Divider icon='right angle' />
                        <Breadcrumb.Section link onClick={() => handleBreadcrumbClick(index)}>
                            {folder}
                        </Breadcrumb.Section>
                    </React.Fragment>
                ))}
            </Breadcrumb>
        );
    };

    const renderCurrentFolder = (folderContent) => {
        if (!folderContent) return null;
        const files = folderContent.files || [];
        const folders = Object.keys(folderContent).filter(key => key !== 'files');

        return (
            <>
                {folders.length > 0 && (
                    <>
                        {renderBreadcrumb()}
                        <Grid columns={5}>
                            {folders.map((folder, index) => (
                                <Grid.Column key={index} style={{ marginBottom: '10px', textAlign: 'center', cursor: 'pointer' }} onClick={() => handleFolderClick(folder)}>
                                    <Image src={Directory} size='small' style={{ marginBottom: '10px' }} />
                                    <strong>
                                        <span className="folder-counter-calibrate">
                                            {folderContent[folder] && folderContent[folder].files && folderContent[folder].files.length > 0
                                                ? `${countFilesAndFoldersInFolder(folderContent[folder]).fileCount} files`
                                                : `${countFilesAndFoldersInFolder(folderContent[folder]).folderCount} folders`}
                                        </span>
                                    </strong>
                                    <span style={{ display: 'block', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: '120px' }}>{folder}</span>
                                </Grid.Column>
                            ))}
                        </Grid>
                    </>
                )}
                {folders.length > 0 && (
                    <div style={{ textAlign: 'center', marginTop: '20px', marginBottom: '20px' }}>
                        <Pagination
                            activePage={currentPage}
                            onPageChange={handlePaginationChange}
                            ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
                            firstItem={{ content: <Icon name='angle double left' />, icon: true }}
                            lastItem={{ content: <Icon name='angle double right' />, icon: true }}
                            prevItem={{ content: <Icon name='angle left' />, icon: true }}
                            nextItem={{ content: <Icon name='angle right' />, icon: true }}
                            totalPages={Math.ceil(files.length / filesPerPage)}
                        />
                    </div>
                )}
                <div style={{ marginTop: '20px' }}>
                    <Grid columns={5}>
                        {renderFiles(files)}
                    </Grid>
                </div>
            </>
        );
    };

    const renderFiles = (files) => {
        const startIndex = (currentPage - 1) * filesPerPage;
        const endIndex = startIndex + filesPerPage;
        const filesToRender = files.slice(startIndex, endIndex);

        return filesToRender.map((file, index) => (
            <Grid.Column
                key={index}
                style={{
                    marginBottom: '10px',
                    textAlign: 'center',
                    position: 'relative',
                    cursor: 'pointer'
                }}
                onClick={() => handleFileSelectForProcessing(file)}
            >
                {file.type.startsWith('image/') && (
                    <div style={{ position: 'relative' }}>
                        <Image src={URL.createObjectURL(file)} size='small' rounded />
                        {selectedImages.includes(file) && <Icon name='check circle' size='big' color='green' style={{ position: 'absolute', top: '5px', right: '5px' }} />}
                    </div>
                )}
                {file.type.startsWith('video/') && (
                    <div style={{ marginBottom: '10px' }}>
                        <video width='120' height='90' controls>
                            <source src={URL.createObjectURL(file)} type={file.type} />
                            Your browser does not support the video tag.
                        </video>
                    </div>
                )}
                <span style={{ display: 'block', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: '120px' }}>{file.name}</span>
            </Grid.Column>
        ));
    };

    const handleShapeChange = (points, dimensions) => {
        setShapeData({ points, dimensions });
    };

    const handleSubmitCalibration = () => {
        setOpen(false);
        setShowConfirmation(true);
        setCalibrationComplete(true);
    };

    const handleReset = () => {
        setShowConfirmation(false);
        setCalibrationComplete(false);
    };

    return (
        <Container className="inside-container">
            {!calibrationComplete ? (
                <>
                    <Container textAlign='left' style={{ width: '100%' }} className="inside-container">
                        <Message className='mt-30' content='This is dependent upon detecting striping, if striping is not available the model will report all of the visible distresses on the road' />
                        <h2>Uploaded Files</h2>
                        {renderCurrentFolder(currentFolderContent)}
                        <h2>Selected Files</h2>
                        <Grid columns={5}>
                            {selectedFiles.map((file, index) => (
                                <Grid.Column
                                    key={index}
                                    style={{
                                        marginBottom: '10px',
                                        textAlign: 'center',
                                        position: 'relative',
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => handleFileSelectForProcessing(file)}
                                >
                                    {file.type.startsWith('image/') && (
                                        <div style={{ position: 'relative' }}>
                                            <Image src={URL.createObjectURL(file)} size='small' rounded />
                                        </div>
                                    )}
                                    {file.type.startsWith('video/') && (
                                        <div style={{ marginBottom: '10px' }}>
                                            <video width='120' height='90' controls>
                                                <source src={URL.createObjectURL(file)} type={file.type} />
                                                Your browser does not support the video tag.
                                            </video>
                                        </div>
                                    )}
                                    <span style={{ display: 'block', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: '120px' }}>{file.name}</span>
                                </Grid.Column>
                            ))}
                        </Grid>
                    </Container>

                    <Modal
                        onClose={() => setOpen(false)}
                        onOpen={() => setOpen(true)}
                        open={open}
                        size='fullscreen'
                    >
                        <Modal.Header>Process File</Modal.Header>
                        <Modal.Content>
                            {fileToProcess && fileToProcess.type.startsWith('image/') && (
                                <GeneralCanvasEditor
                                    imageUrl={URL.createObjectURL(fileToProcess)}
                                    onChange={handleShapeChange}
                                    updateCalibrationData={updateCalibrationData}
                                    handleSubmitCalibration={handleSubmitCalibration}
                                    selectedUnit={selectedUnit}
                                    fileName={selectedFileName}
                                />
                            )}
                            {fileToProcess && fileToProcess.type.startsWith('video/') && (
                                <VideoCanvasEditor
                                    videoUrl={URL.createObjectURL(fileToProcess)}
                                    updateCalibrationData={updateCalibrationData}
                                    handleSubmitCalibration={handleSubmitCalibration}
                                    selectedUnit={selectedUnit}
                                    fileName={selectedFileName}
                                />
                            )}
                        </Modal.Content>
                    </Modal>


                </>
            ) : (
                <div style={{ textAlign: 'center', marginTop: '20px' }}>
                    <Image src={ConfirmCalibration} size='small' centered />
                    <h2>Thank you for calibrating</h2>
                    <Button onClick={handleReset}>Reset</Button>

                </div>
            )}

            <div className='mt-30'>
                <Divider />
            </div>

            <div className='right-aligned-container'>
                <Button onClick={handlePrevStep} style={{ marginRight: '10px' }}>Back</Button>
                <Button className="ui teal button" onClick={handleNextStep} disabled={(selectedFiles.length === 0 && Object.keys(fileStructure).length === 0) || !calibrationComplete } >Continue</Button>
            </div>
        </Container>
    );
};

export default Calibrate;
