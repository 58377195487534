import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Button, List, Icon, Accordion } from 'semantic-ui-react';

import IncidentList from '../../IncidentList/IncidentList';
import AnnotationMetadata from '../../AnnotationMetadata';
import TwoDimensionalVideoContext from '../../TwoDimensionalVideo/twoDimensionalVideoContext';
import OpenDialogButton from '../../OpenDialogButton/OpenDialogButton';
import { isDialogDisabledConst } from '../isDialogDisabledReducer';
import {
  SPLIT,
  HIDE,
  SHOW
} from '../../../models/2DVideo';
import './annotationItem.css';

const AccordionContainerStyle = {
  padding: '0px 20px 0px 0px',
}

const AccordionTitleStyle = {
  display: 'flex',
  alignItems: 'center',
  marginTop: '10px',
  padding: '0px 20px',
  fontWeight: 600,
  borderRadius: '5px',
  height: '40px',
  flexDirection: 'row-reverse',
  justifyContent: 'space-between',
  backgroundColor: '#EEE',
}

const AccordionTitle = (props) => {
  return (
    <Accordion.Title
      active={props.active}
      content={props.title}
      index={props.index}
      icon={`chevron ${props.active ? "up" : "down"}`}
      onClick={props.onClick}
      style={AccordionTitleStyle}
    />
  )
}

const AnnotationItem = ({
  className,
  itemData,
  isDialogDisabled,
  dispatchIsDialogDisabled,
}) => {
  const twoDimensionalVideoContext = useContext(TwoDimensionalVideoContext);
  const {
    played,
    entities,
    focusing,
    isEmptyCheckEnable,
    isSplitEnable,
    isShowHideEnable,
    emptyCheckAnnotationItemWarningText,
    onAnnotationItemClick,
    onAnnotationDeleteClick,
    onAnnotationItemHideTemp,
    onAnnotationShowHideClick,
    onAnnotationSplitClick,
  } = twoDimensionalVideoContext;
  const [isIncidentListOpen, setIsIncidentListOpen] = useState(false);
  const [isMetadataOpen, setIsMetadataOpen] = useState(false);

  const {
    incidents,
    name,
    hideTemp,
    label,
    color,
    parentName,
    childrenNames,
  } = itemData;

  const parentAnnotation = entities.annotations[parentName];
  const childrenUI = childrenNames.map(c => (
    <Button
      key={c}
      color='blue'
      onClick={() => onAnnotationItemClick(c)}
      className='video-ann-relatives'
    >
      {entities.annotations[c].label}
    </Button>
  ));

  const currentIncident =
    [...incidents].reverse().find(incident => played >= incident?.time) || {};

  let showButtonUI = (
    <OpenDialogButton
      className='annotation-item__control-button'
      color='blue'
      title='Show Object'
      message='Showing this object means that the object is visible on the frame. Please confirm that you want it to show.'
      isDialogDisabled={isDialogDisabled.show}
      onYesClick={() => onAnnotationShowHideClick({ name, status: SHOW })}
      onDontShowAgainChange={e =>
        dispatchIsDialogDisabled({
          type: isDialogDisabledConst.SHOW,
          value: e.target.checked,
        })
      }
    >
      <Icon name='sign-in' />
      Show in Frame
    </OpenDialogButton>
  );

  let hideButtonUI = (
    <OpenDialogButton
      className='d-flex align-items-center annotation-item__control-button'
      color='blue'
      title='Hide object'
      message='Hiding this object means that the object is no longer visible on the frame. Please confirm that you want to hide it.'
      isDialogDisabled={isDialogDisabled.hide}
      onYesClick={() => onAnnotationShowHideClick({ name, status: HIDE })}
      onDontShowAgainChange={e =>
        dispatchIsDialogDisabled({
          type: isDialogDisabledConst.HIDE,
          value: e.target.checked,
        })
      }
    >
      <Icon name='sign-out' />
      Leave Frame
    </OpenDialogButton>
  );

  let splitButtonUI = (
    <OpenDialogButton
      className='d-flex align-items-center annotation-item__control-button'
      color='blue'
      title='Split Title'
      message='Split Message'
      isDialogDisabled={isDialogDisabled.split}
      onYesClick={() => onAnnotationSplitClick(name)}
      onDontShowAgainChange={e =>
        dispatchIsDialogDisabled({
          type: isDialogDisabledConst.HIDE,
          value: e.target.checked,
        })
      }
    >
      <Icon name='random' />
      {SPLIT}
    </OpenDialogButton>
  );

  switch (currentIncident.status) {
    case SHOW:
      showButtonUI = null;
      break;
    case HIDE:
      hideButtonUI = null;
      splitButtonUI = null;
      break;
    case SPLIT:
      showButtonUI = null;
      hideButtonUI = null;
      break;
    default:
      showButtonUI = null;
      hideButtonUI = null;
      splitButtonUI = null;
      break;
  }

  const warningText =
    isEmptyCheckEnable && incidents.length < 2 ? (
      <div>{emptyCheckAnnotationItemWarningText}</div>
    ) : null;

  let rootClassName = `annotation-item${
    className ? ` ${className}` : ''
  }`;

  const showHideAction = (e) => {
    e.stopPropagation();
    onAnnotationItemHideTemp(name);
  }

  const EyeIcon = (
    <div className='list-item-labelname__show-hide'>
      <Icon 
        name={hideTemp ? 'eye slash' :  'eye'}
        onClick={showHideAction}
      />
    </div>
  )

  if (name !== focusing) {
    return (
      <List.Item
        className={rootClassName}
        name={name}
        onClick={() => onAnnotationItemClick(name)}
        as='a'
      >
        <div className='list-item-labelname-alone'>
          {EyeIcon} {label} <span style={{color:"black",opacity:0.5}}> {name}</span>
        </div>
        <div>{warningText}</div>
      </List.Item>
    );
  }

  rootClassName = `${rootClassName} annotation-item--highlight`;

  return (
        <List.Item
        className={rootClassName}
        name={name}
        style={{ borderColor: color }}
      >
        <div className='list-item-heading'>
          <div className='list-item-labelname'>
            { EyeIcon }
            <strong>{label}</strong>
            <span style={{color:"black",opacity:0.5}}> {name} </span>
          </div>
          {isSplitEnable && splitButtonUI}
          {isShowHideEnable && hideButtonUI}
          {showButtonUI}
          <OpenDialogButton
            className='annotation-item__delete-button'
            color='blue'
            title='Delete Label'
            message='Delete this label. This cannot be undone. Are you sure you want to continue?'
            isDialogDisabled={isDialogDisabled.delete}
            onYesClick={() => onAnnotationDeleteClick(name)}
            onDontShowAgainChange={e =>
              dispatchIsDialogDisabled({
                type: isDialogDisabledConst.DELETE,
                value: e.target.checked,
              })
            }
          >
            <Icon name='trash' />
          </OpenDialogButton>
        </div>
        <div>
          {parentAnnotation && (
            <div>
              <Icon name='linkify' color='grey' />
              <Button
                color='link'
                onClick={() => onAnnotationItemClick(parentAnnotation.name)}
                className='annotation-item__parent-button'
              >
                {parentAnnotation.label}
              </Button>
            </div>
          )}
        </div>
        <div>
          {childrenUI.length > 0 && (
            <div>
              <Icon name='sitemap' color='grey' />
              {childrenUI}
            </div>
          )}
        </div>
        <Accordion vertical exclusive={false} style={AccordionContainerStyle} >
          <AccordionTitle
            active={isMetadataOpen}
            title="Metadata"
            index={0}
            onClick={() => setIsMetadataOpen(!isMetadataOpen)}
          />
          <Accordion.Content active={isMetadataOpen} content={
            <AnnotationMetadata annotationId={name} labelKey={label} />
          } />
          <AccordionTitle
            active={isIncidentListOpen}
            title="Incident History"
            index={1}
            onClick={() => setIsIncidentListOpen(!isIncidentListOpen)}
          />
          <Accordion.Content active={isIncidentListOpen} content={
            <IncidentList incidents={incidents} annotationName={name} />
          } />
        </Accordion>
        <div className='mt-3'>{warningText}</div>
      </List.Item>
  );
};

AnnotationItem.propTypes = {
  className: PropTypes.string,
  itemData: PropTypes.object,
  dispatchIsDialogDisabled: PropTypes.func,
  isDialogDisabled: PropTypes.object,
};

AnnotationItem.defaultProps = {
  className: '',
  itemData: {},
  dispatchIsDialogDisabled: () => {},
  isDialogDisabled: {},
};

export default AnnotationItem;