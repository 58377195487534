import * as turf from "@turf/turf";
import { genId } from "../../label/utils";
import { cloneDeep } from "lodash";

export const mergeLines = (points1, points2) => {
  let newPoints = [];
  if (points1.length < 2 || points2.length < 2) return newPoints;
  const start1 = points1[0], start2 = points2[0];
  const end1 = points1[points1.length - 1], end2 = points2[points2.length - 1];
  if (JSON.stringify(start1) === JSON.stringify(start2)) points1 = points1.reverse();
  else if (JSON.stringify(end1) === JSON.stringify(end2)) points2 = points2.reverse();
  else if (JSON.stringify(start1) === JSON.stringify(end2)) {
    const auxPoints = [...points1];
    points1 = [...points2];
    points2 = [...auxPoints];
  }
  return newPoints = [...points1, ...points2.slice(1)];
}

export const getLongestCoors = (features) => {
  let coors = [];
  features.forEach(feature => {
    if (feature.geometry.coordinates.length > coors.length) {
      coors = feature.geometry.coordinates;
    }
  })
  return coors;
}

export const transformCoordinates = (points, noClose) => {
  const newCoors = points.map(point => { return [point.lng, point.lat] });
  if (!noClose) newCoors.push(newCoors[0]);
  return newCoors;
}

export const transformCoordinatesLatLng = (coors, noOpen) => {
  const newCoors = coors.map(point => { return { lng: point[0], lat: point[1] } });
  if (!noOpen) newCoors.pop();
  return newCoors;
}

const clipLineWithPolygon = (points1, points2) => {
  const turfLine = turf.lineString(transformCoordinates(points1, true));
  const turfPolygon = turf.polygon([transformCoordinates(points2)]);
  const intersectingParts = turf.lineSplit(turfLine, turfPolygon);
  if (intersectingParts.features.length > 0) {
    const noOverlappingParts = intersectingParts.features.filter(currentFeature => {
      let pointsInPolygon = 0;
      currentFeature.geometry.coordinates.forEach(coor => {
        if (turf.booleanPointInPolygon(coor, turfPolygon)) pointsInPolygon++;
      });
      return pointsInPolygon < 2;
    });
    return noOverlappingParts.map(f => transformCoordinatesLatLng(f.geometry.coordinates, true));
  } else {
    if (turfLine.geometry.coordinates.every(coor => turf.booleanPointInPolygon(coor, turfPolygon))) return null
    return [points1];
  }
}

const expandLineWithPolygon = (points1, points2) => {
  const turfLine1 = turf.lineString(transformCoordinates(points1, true));
  const turfLine2 = turf.lineString(transformCoordinates(points2, true));
  const intersectingPoints1 = turf.lineSplit(turfLine1, turfLine2);
  if (intersectingPoints1.features.length === 2) {
    const intersectingPoints2 = turf.lineSplit(turfLine2, turfLine1);
    const intersection1 = getLongestCoors(intersectingPoints1.features);
    const intersection2 = getLongestCoors(intersectingPoints2.features);
    return [transformCoordinatesLatLng(mergeLines(intersection1, intersection2), true)];
  } else {
    return [points1];
  }
}

const calculatePolygons = (action, points1, points2) => {
  const turfPolygon1 = turf.polygon([transformCoordinates(points1)]);
  const turfPolygon2 = turf.polygon([transformCoordinates(points2)]);
  if (action === "union") {
    const doesIntersect = turf.intersect(turfPolygon1, turfPolygon2);
    if (!doesIntersect) return [points1];
  }
  const intersection = turf[action](turfPolygon1, turfPolygon2);
  if (intersection) {
    if (intersection.geometry.coordinates.length === 1)
      return intersection.geometry.coordinates.map(transformCoordinatesLatLng);
    else if (intersection.geometry.coordinates.length > 1 && intersection.geometry.coordinates.every(coor => coor.length === 1))
      return intersection.geometry.coordinates.map(coor => transformCoordinatesLatLng(coor[0]));
  } else {
    return null;
  }
}

export const manageIntersection = (type, labelType, figure, intersection) => {
  let resultPolygons = [];
  let newFigures = [];
  if (labelType === 'polyline') {
    if (type === 'erase')
      resultPolygons = clipLineWithPolygon(figure.points, intersection.points);
    else if (type === 'expand')
      resultPolygons = expandLineWithPolygon(figure.points, intersection.points);
  }
  else if (labelType === 'polygon' && figure.points.length > 2) resultPolygons = calculatePolygons(type === 'erase' ? 'difference' : 'union', figure.points, intersection.points);
  if (resultPolygons?.length > 0) {
    resultPolygons.forEach(points => {
      newFigures.push({
        ...figure,
        points,
      });
    })
  }
  return newFigures;
}

export const handleIntersectionTools = (type, stateFigureToggles, figures, intersectionFigure) => {
  const toggleFigures = new Map(Object.entries(stateFigureToggles));
  Object.entries(figures).forEach(([key, labelFigures]) => {
    if (labelFigures.length) {
      const newLabelFigures = [...labelFigures];
      for (let i = 0; i < newLabelFigures.length; i++) {
        const labelFigure = newLabelFigures[i];
        const labelType = labelFigure.type;
        if (toggleFigures.get(labelFigure.id) && labelFigure.points.length > 2) {
          const newFigures = manageIntersection(type, labelType, labelFigure, intersectionFigure);
          if (newFigures.length === 0) {
            toggleFigures.delete(labelFigure.id);
            newLabelFigures.splice(i, 1);
            i--;
          }
          newFigures.forEach((newFigure, index) => {
            if (index === 0) newLabelFigures[i] = newFigure;
            if (index > 0) {
              const newLabelFigure = { ...newFigure };
              newLabelFigure.id = genId();
              toggleFigures.set(newLabelFigure.id, true);
              newLabelFigures.splice(i, 0, newLabelFigure);
              i++;
            }
          })
        }
      }
      figures[key] = newLabelFigures;
    }
  })
  return [Object.fromEntries(toggleFigures)];
}