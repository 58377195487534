import React, {Fragment} from "react";
import {Grid, Button, Divider, Segment, Checkbox, Dropdown,Table, Input, Icon, TextArea,Form} from 'semantic-ui-react';
import '../App.css';
import Dropzone from 'react-dropzone';
import csv from "csv";
import {Magnifier} from "react-image-magnifiers";
import { CSVLink } from "react-csv";
import ReactGA from 'react-ga';
// import Map from '../maps/Map';


class ImageVisualizer extends React.Component {
constructor(props){
    super(props);
    this.state = {
      acceptedFiles:[],
      images:[],
      arrayBuffers:[],
      loading:false,
      detection:null,
      type:null,
      confidence:null,
      length:null,
      width:null,
      imgSize:null,
      parsedCsvData:null,
      withHeaders:true,
      withImages:false,
      headers:[],
      imagesColumn:null,
      secondImageColumn:null,
      maxRowCount : 10,
      startingRow:1,
      selectedRow:[],
      selectedRowIdx:0,
      nameCol:0,
      damageCol:1,
      qaGenerated:[],
      classificationGenerated:[],
      feedbackGenerated:[],
      usage:[
        {value:"qa", text:"Quality Assurance"},
        {value:"quick-classification", text:"Quick Classification"},
        {value:"visual", text:"Only Visualize"},
        {value:"feedback", text:"Feedback"}
        ],
      objective:"visual",
      qaDamageDetectedCorrectly:true,
      qaCorrectedDamage:"none",
      classificationIsDamaged:false,
      classificationDamageName:"none",
      feedbackImageHasDamage:false,
      feedbackDamageName1:"none",
      feedbackDamageName2:"none",
      feedbackDamageName3:"none",
      feedbackDamage1Length:0,
      feedbackDamage2Length:0,
      feedbackDamage3Length:0,
      feedbackDamage1Width:0,
      feedbackDamage2Width:0,
      feedbackDamage3Width:0,
      feedbackComments:"",
      disableAdd:false,
      twoImages:false,
      startingRowCustomize:1,
      readURLs:[]
    };
    this.handleDrop = this.handleDrop.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleDrop(acceptedFiles){
    console.log(acceptedFiles);
     const reader = new FileReader();
     let filesArray = [];
     reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading failed");
      reader.onload = () => {
        // Parse CSV file
        csv.parse(reader.result, (err, data) => {
          if(data && "length" in data && data.length){
            let columnList = data[0].map((str,idx)=>{
            if(this.state.withHeaders){
              return str;
            }else{
              return idx;
            }
          });  
          // console.log("Parsed CSV data: ", data);
          const parsedData = this.state.withHeaders? data : [columnList,...data];
          this.setState({
            parsedCsvData:parsedData,
            headers:columnList,
            qaGenerated:[[...columnList,"damage_detected_correctly","corrected_damage"],...this.state.qaGenerated],
            classificationGenerated:[[...columnList,"has_damage","damage_type"],...this.state.qaGenerated],
            feedbackGenerated:[[...columnList,"damage_1_type","damage_1_length","damage_1_width","damage_2_type","damage_2_length","damage_2_width","damage_3_type","damage_3_length","damage_3_width","comments"],...this.state.qaGenerated],
          });
          }
        });
      };

      // read file contents
      acceptedFiles.forEach(file => {
        console.log("file",file);
        const parsedFile = reader.readAsBinaryString(file);
        console.log("parsed file",parsedFile);
        filesArray.push(parsedFile);
      });
      // console.log(filesArray);.
       ReactGA.event({
                category: 'Visualization Tool',
                action: 'File Upload',
                label: 'File upload success'
              }); 
     this.setState({
      acceptedFiles:acceptedFiles,
      arrayBuffers:filesArray
     });
    
  }

  handleChange(evt, data){
    this.setState({
      [data.name] : data.value
    });
  }

  handleSubmit(evt){  
    evt.preventDefault();
    this.setState({
      loading:true
    });

    let selectedImgURL = this.state.selectedRow[this.state.imagesColumn];
    let readURLs = [...this.state.readURLs, selectedImgURL];
    
    if(this.state.objective === "qa"){
      ReactGA.event({
        category: 'Visualization Tool',
        action: 'Row added to QA CSV',
        label: 'Submitted new row to QA CSV'
      }); 
      let grade = this.state.qaDamageDetectedCorrectly ? this.state.qaDamageDetectedCorrectly : false;
      let newDamage = this.state.qaCorrectedDamage;
      let newRow = [...this.state.selectedRow, grade, newDamage];
      let qaArr = [...this.state.qaGenerated,newRow];
      
      this.setState({
        qaGenerated:qaArr,
        loading:false,
        qaDamageDetectedCorrectly:true,
        qaCorrectedDamage:"",
        disableAdd:true,
        readURLs
      });
    }else if(this.state.objective === "quick-classification"){
      ReactGA.event({
        category: 'Visualization Tool',
        action: 'Row added to Quick Classification CSV',
        label: 'Submitted new row to Quick Classification CSV'
      });
      let foundDamage = this.state.classificationIsDamaged ? this.state.classificationIsDamaged : false;
      let newDamage = foundDamage ? this.state.classificationDamageName : "None";
      let newRow = [...this.state.selectedRow, foundDamage, newDamage];
      let classificationGenerated = [...this.state.classificationGenerated, newRow];
      this.setState({
        classificationGenerated:classificationGenerated,
        loading:false,
        classificationIsDamaged:false,
        disableAdd:true,
        readURLs
      });
    }else if(this.state.objective === "feedback"){
      ReactGA.event({
        category: 'Visualization Tool',
        action: 'Row added to Feedback CSV',
        label: 'Submitted new row to Feedback CSV'
      });
      let foundDamage = this.state.feedbackImageHasDamage ? this.state.feedbackImageHasDamage : false;
      let newDamage1 = foundDamage ? this.state.feedbackDamageName1 : "None";
      let newDamage1Length = foundDamage ? this.state.feedbackDamage1Length : 0;
      let newDamage1Width = foundDamage ? this.state.feedbackDamage1Width : 0;
      let newDamage2 = foundDamage ? this.state.feedbackDamageName2 : "None";
      let newDamage2Length = foundDamage ? this.state.feedbackDamage2Length : 0;
      let newDamage2Width = foundDamage ? this.state.feedbackDamage2Width : 0;
      let newDamage3 = foundDamage ? this.state.feedbackDamageName3 : "None";
      let newDamage3Length = foundDamage ? this.state.feedbackDamage3Length : 0;
      let newDamage3Width = foundDamage ? this.state.feedbackDamage3Width : 0;
      let comments = foundDamage ? this.state.feedbackComments : "";
      let newRow = [...this.state.selectedRow, newDamage1,newDamage1Length, newDamage1Width, newDamage2, newDamage2Length,newDamage2Width, newDamage3, newDamage3Length, newDamage3Width, comments];
      let feedbackGenerated = [...this.state.feedbackGenerated, newRow];
      this.setState({
        feedbackGenerated:feedbackGenerated,
        loading:false,
        feedbackImageHasDamage:false,
        disableAdd:true,
        feedbackDamageName1:"none",
        feedbackDamageName2:"none",
        feedbackDamageName3:"none",
        feedbackDamage1Length:0,
        feedbackDamage2Length:0,
        feedbackDamage3Length:0,
        feedbackDamage1Width:0,
        feedbackDamage2Width:0,
        feedbackDamage3Width:0,
        feedbackComments:"",
        readURLs
      });
    }
  }

  render() {
    const currentUser = this.props.currentUser ? this.props.currentUser :null;
    let backgroundImg = `linear-gradient(45deg, rgb(229, 44, 78) 1%, rgb(233, 74, 99) 30%, rgb(174, 82, 157) 69%, rgb(154, 85, 177) 100%)`;
    const browserLang = this.props.browserLang || "en";
    const configSectionWidth = this.state.twoImages? 4 : 8;
    const visualSectionWidth = this.state.twoImages? 12: 8;
    let tableData = [];
    const usage = this.state.usage.map((usgObj)=>{
      if(browserLang === "en"){
        return {
          text:usgObj.text,
          value:usgObj.value
        };
      }else{
          if(usgObj.value === "qa"){
            return {
              text:"Aseguramiento de Calidad",
              value:usgObj.value
            }
          }else if(usgObj.value === "quick-classification"){
            
            return {text:"Clasificación Rápida",
            value:usgObj.value}
          }else if(usgObj.value === "feedback"){
            return {text:"Retroalimentación",
            value:usgObj.value}
          }else{
            return {text:"Visualización",
            value:usgObj.value}
          }
        }
      }
    );
    const imageDropdownOptions = this.state.headers.map((header, idx)=>{
      return {
        text:header,
        value:idx
      }
    });    
    // console.log(process.env.REACT_APP_PRODUCTION_SERVER_URL);
    let fileNames = this.state.acceptedFiles.map((file)=>{
      if("name" in file){
        return (<p key={file.name}>{file.name}</p>)
      }else if("path" in file){
        return (<p key={file.path}>{file.path}</p>)
      }else{
        return (<p key="no-name">{"no-name"}</p>)
      }
      
    });
    let images = this.state.images.map((imgUrl, idx)=>{
      return (
        <Magnifier
          key={idx}
          style={{"maxWidth":"700px"}}
          imageSrc={imgUrl}
          imageAlt="image"
          mouseActivation="click" // Optional
          touchActivation="tap" // Optional
        />
        )
    });
    if(this.state.parsedCsvData && "length" in this.state.parsedCsvData){
      tableData = this.state.parsedCsvData.slice(this.state.startingRow, this.state.startingRow+this.state.maxRowCount);
    }
    return (
      <Fragment>
        <header className="App-hero" style={{
          "minHeight":"75px",
          "backgroundImage":backgroundImg,
          "color":"white"
        }}>
        </header>
        <div style={{"paddingTop":"16px","marginLeft":"40px", "marginRight":"40px"}}>
          <Grid stackable>
            <Grid.Row columns={2}>
                <Grid.Column width={configSectionWidth} style={{"textAlign":"left"}}>
                  <Grid.Row>
                    <h2>{browserLang === "en" ? "Select Files (Only CSV)":"Seleccionar Archivo (Solo CSV)"}</h2>
                    </Grid.Row>
                    <Grid.Row>
                      <Dropzone  onDrop={acceptedFiles => this.handleDrop(acceptedFiles)}>
                      {({getRootProps, getInputProps}) => (
                        <section>
                          <div className="dropzone" {...getRootProps()}>
                            <input {...getInputProps()} />
                            <p>{browserLang === "en" ? "Drag 'n' drop some files here, or click to select files":"Arrastra los archivos aquí, o haz click para seleccionar archivo"}</p>
                          </div>
                        </section>
                      )}
                </Dropzone>
                    </Grid.Row>
                    <Grid.Row>
                      <h2>{browserLang === "en" ? "Selected Files" : "Archivos seleccionados"}</h2>
                    </Grid.Row>
                     <Grid.Row>
                      <Segment>
                        {fileNames}  
                      </Segment>
                    </Grid.Row>
                    <Divider></Divider>
                    <h2>{browserLang === "en" ? "Configure Usage of This Tool" : "Configurar Uso de la Herramienta"}</h2>
                    <Grid.Row>
                      <Dropdown
                        search
                        options = {usage}
                        selection
                        placeholder = {browserLang === "en" ? "What do you want to accomplish with this tool?" : "¿Cuál es tu objectivo?"}
                        onChange = {(event, data)=>{
                          this.setState({
                            objective:data.value
                          });
                        }}
                      >  
                      </Dropdown>
                    </Grid.Row>
                    <Divider></Divider>
                    <h2>{browserLang === "en" ? "Configure Visualization" : "Configurar Visualización"}</h2>
                    <Grid.Row>
                      <Checkbox checked={this.state.withHeaders}  onChange={(event, data)=>{
                        this.setState({
                          withHeaders:!this.state.withHeaders,
                        })
                      }} label={browserLang === "en" ? "Data has headers" : "Las columnas tienen encabezados"} toggle />
                    </Grid.Row>
                    <Divider hidden></Divider>
                    <Grid.Row>
                      <Checkbox checked={this.state.withImages}  onChange={(event, data)=>{
                        this.setState({
                          withImages:!this.state.withImages
                        })
                      }} label={browserLang === "en" ? "Has Images?" : "¿Hay imágenes?"} toggle />
                    </Grid.Row>
                    {this.state.parsedCsvData && this.state.parsedCsvData.length > 0  && this.state.withImages && (
                      <Fragment>
                        <Divider></Divider>
                        <p> {browserLang === "en" ?  "Select column that specifies image URL": "Selecciona la columna que especifica el URL de las imágenes"}</p>
                        <Dropdown
                          search
                          onChange={(event,data)=>{
                            // console.log(data);
                            this.setState({
                              imagesColumn:data.value
                            });
                          }}
                          placeholder={browserLang === "en" ?  "Select column that specifies image URL": "Selecciona la columna que especifica el URL de las imágenes"}
                          fluid
                          label={browserLang === "en" ?  "Select column that specifies image URL": "Selecciona la columna que especifica el URL de las imágenes"}
                          selection
                          options={imageDropdownOptions}
                        >
                          
                        </Dropdown>
                        <Divider></Divider>
                        <h3>Configure second image</h3>
                        <Grid.Row>
                          <Checkbox name="twoImages" checked={this.state.twoImages}  onChange={(event, data)=>{
                            this.setState({
                              twoImages:!this.state.twoImages
                            })
                          }} label={browserLang === "en" ? "Visualize 2 images?" : "¿Ver 2 imágenes?"} toggle />
                        </Grid.Row>
                        {this.state.twoImages &&(
                          <Fragment>
                            <Divider hidden></Divider>
                            <p> {browserLang === "en" ?  "Select column that specifies image URL of the second image": "Selecciona la columna que especifica el URL de las imágenes 2"}</p>
                              <Dropdown
                                name = "secondImageColumn"
                                search
                                onChange={this.handleChange}
                                placeholder={browserLang === "en" ?  "Select column that specifies image URL": "Selecciona la columna que especifica el URL de las imágenes"}
                                fluid
                                label={browserLang === "en" ?  "Select column that specifies image URL": "Selecciona la columna que especifica el URL de las imágenes"}
                                selection
                                options={imageDropdownOptions}
                              >
                                
                              </Dropdown>
                          </Fragment>
                          )
                        }
                         <Divider></Divider>
                         <p>{browserLang === "en" ?  "Column with image name": "Columna con nombre de la imagen"}</p>
                        <Dropdown
                          onChange={(event,data)=>{
                            // console.log(data);
                            this.setState({
                              nameCol:data.value
                            });
                          }}
                          search
                          placeholder={browserLang === "en" ?  "Column with image name": "Columna con nombre de la imagen"}
                          fluid
                          label={browserLang === "en" ?  "Column with image name": "Columna con nombre de la imagen"}
                          selection
                          options={imageDropdownOptions}
                        >
                          
                        </Dropdown>
                        <Divider></Divider>
                        <p>{browserLang === "en" ?  "Column with damage name": "Columna con nombre del daño"}</p>
                        <Dropdown
                          search
                          onChange={(event,data)=>{
                            // console.log(data);
                            this.setState({
                              damageCol:data.value
                            });
                          }}
                          placeholder={browserLang === "en" ?  "Column with damage name": "Columna con nombre del daño"}
                          label={browserLang === "en" ?  "Column with damage name": "Columna con nombre del daño"}
                          fluid
                          selection
                          options={imageDropdownOptions}
                        >
                          
                        </Dropdown>
                      </Fragment>
                      )}
                    <Divider hidden></Divider>
                </Grid.Column> 
                <Grid.Column width={visualSectionWidth}>
                {/*currentUser && "user" in currentUser && "id" in currentUser.user && currentUser.user.id && currentUser.user.role === "admin" && this.state.parsedCsvData && (
                            <Fragment>
                              <Map zoom={14} centerLat={4.667745} imgUrlColIdx={61} centerLong={-74.060677} csv = {this.state.parsedCsvData} latColIdx = {40} longColIdx = {41} headerColIdx = {4} descriptionColIdx={7} severityColIdx = {19}/>
                            </Fragment>
                            )
                          */}
                {
                  this.state.withImages && (
                      <Segment style={{"position":"fixed","zIndex":"2","overflowY":"scroll","maxHeight":"88vh"}}>
                          <h2>{browserLang === "en" ? "Visualization":"Visualización"}</h2>
                          <Grid>
                            <Grid.Row columns={this.state.twoImages ? 2:1}>
                            {images.map((img,idx)=>{
                              return (<Grid.Column key={idx}>
                                {img}
                              </Grid.Column>)
                            })}  
                          </Grid.Row>
                          </Grid>
                          {this.state.parsedCsvData && this.state.parsedCsvData.length > 1 && this.state.selectedRow &&  (
                            <Fragment>
                              <Divider></Divider>
                                <p>Image name: {this.state.selectedRow[this.state.nameCol]}</p>  
                                <p>Damage: {this.state.selectedRow[this.state.damageCol]}</p>
                            </Fragment>
                            )
                          }
                          {this.state.parsedCsvData && this.state.parsedCsvData.length > 1 && this.state.selectedRow && this.state.objective === "qa" &&  (
                            <Fragment>
                              <Divider></Divider>
                              <p>{browserLang === "en" ? "Is the damage correctly identified?":"¿Se identificó el daño de forma correcta?"}</p>
                              <Grid.Row>
                                <Dropdown
                                  search
                                  name="qaDamageDetectedCorrectly"
                                  onChange={this.handleChange}
                                  selection
                                  value={this.state.qaDamageDetectedCorrectly}
                                  options={[
                                    {
                                      text:browserLang === "en" ? "Yes":"Si", 
                                      value:true
                                    },{
                                      text:browserLang === "en" ? "No":"No", 
                                      value:false
                                    }
                                  ]}
                                >                            
                                </Dropdown>
                              </Grid.Row>
                              {this.state.qaDamageDetectedCorrectly === false && (
                                <Fragment>
                                    <p>{browserLang === "en" ? "What's the correct damage?" : "¿Cuál es el daño correcto?"}</p>
                                    <Input name="qaCorrectedDamage" onChange={this.handleChange} value={this.state.qaCorrectedDamage}>
                                    </Input>  
                                </Fragment>
                                
                                )}
                              <Divider hidden></Divider>
                              <Grid.Row>
                                <Button disabled={this.state.disableAdd} loading={this.state.loading} onClick={this.handleSubmit} positive>{browserLang === "en" ? "Add to new list" : "Agregar a lista nueva"}</Button>
                              </Grid.Row>
                            </Fragment>
                            )
                          }
                          {this.state.parsedCsvData && this.state.parsedCsvData.length > 1 && this.state.selectedRow && this.state.objective === "quick-classification" &&  (
                            <Fragment>
                              <Divider></Divider>
                              <p>{browserLang === "en" ? "Is there a damage?":"¿Se identifica un daño?"}</p>
                              <Grid.Row>
                                <Dropdown
                                  search
                                  name="classificationIsDamaged"
                                  onChange={this.handleChange}
                                  selection
                                  value={this.state.classificationIsDamaged}
                                  options={[
                                    {
                                      text:browserLang === "en" ? "Yes":"Si", 
                                      value:true
                                    },{
                                      text:browserLang === "en" ? "No":"No", 
                                      value:false
                                    }
                                  ]}
                                >                            
                                </Dropdown>
                              </Grid.Row>
                              {this.state.classificationIsDamaged === true && (
                                <Fragment>
                                    <p>{browserLang === "en" ? "What's the damage?" : "¿Cuál es el daño?"}</p>
                                    <Input name="classificationDamageName" onChange={this.handleChange} value={this.state.classificationDamageName}>
                                    </Input>  
                                </Fragment>
                                
                                )}
                              <Divider hidden></Divider>
                              <Grid.Row>
                                <Button disabled={this.state.disableAdd} loading={this.state.loading} onClick={this.handleSubmit} positive>{browserLang === "en" ? "Add to new list" : "Agregar a lista nueva"}</Button>
                              </Grid.Row>
                            </Fragment>
                            )
                          }
                          {this.state.parsedCsvData && this.state.parsedCsvData.length > 1 && this.state.selectedRow && this.state.objective === "feedback" &&  (
                            <Fragment>
                              <Divider></Divider>
                              <p>{browserLang === "en" ? "Is there a damage?":"¿Se identifica un daño?"}</p>
                              <Grid.Row>
                                <Dropdown
                                  search
                                  name="feedbackImageHasDamage"
                                  onChange={this.handleChange}
                                  selection
                                  value={this.state.feedbackImageHasDamage}
                                  options={[
                                    {
                                      text:browserLang === "en" ? "Yes":"Si", 
                                      value:true
                                    },{
                                      text:browserLang === "en" ? "No":"No", 
                                      value:false
                                    }
                                  ]}
                                >                            
                                </Dropdown>
                              </Grid.Row>
                              {this.state.feedbackImageHasDamage === true && (
                                <Fragment>
                                <Grid>
                                  <Grid.Row columns='equal'>
                                    <Grid.Column >
                                      <p>{browserLang === "en" ? "Damage 1" : "Daño 1"}</p>
                                      <Input name="feedbackDamageName1" onChange={this.handleChange} value={this.state.feedbackDamageName1}>
                                      </Input>
                                    </Grid.Column>
                                    <Grid.Column>
                                      <p>{browserLang === "en" ? "Length (m) 1" : "Longitud (m) 1"}</p>
                                      <Input name="feedbackDamage1Length" onChange={this.handleChange} value={this.state.feedbackDamage1Length}>
                                      </Input>  
                                    </Grid.Column>
                                    <Grid.Column>
                                      <p>{browserLang === "en" ? "Width (m) 1" : "Ancho (m) 1"}</p>
                                        <Input name="feedbackDamage1Width" onChange={this.handleChange} value={this.state.feedbackDamage1Width}>
                                        </Input>  
                                    </Grid.Column>
                                  </Grid.Row>
                                  <Grid.Row columns='equal'>
                                    <Grid.Column>
                                      <p>{browserLang === "en" ? "Damage 2" : "Daño 2"}</p>
                                      <Input name="feedbackDamageName2" onChange={this.handleChange} value={this.state.feedbackDamageName2}>
                                      </Input>
                                    </Grid.Column>
                                    <Grid.Column>
                                      <p>{browserLang === "en" ? "Length (m) 2" : "Longitud (m) 2"}</p>
                                      <Input name="feedbackDamage2Length" onChange={this.handleChange} value={this.state.feedbackDamage2Length}>
                                      </Input>  
                                    </Grid.Column>
                                    <Grid.Column>
                                      <p>{browserLang === "en" ? "Width (m) 2" : "Ancho (m) 2"}</p>
                                        <Input name="feedbackDamage2Width" onChange={this.handleChange} value={this.state.feedbackDamage2Width}>
                                        </Input>  
                                    </Grid.Column>
                                  </Grid.Row>
                                  <Grid.Row columns='equal'>
                                    <Grid.Column>
                                      <p>{browserLang === "en" ? "Damage 3" : "Daño 3"}</p>
                                      <Input name="feedbackDamageName3" onChange={this.handleChange} value={this.state.feedbackDamageName3}>
                                      </Input>
                                    </Grid.Column>
                                    <Grid.Column>
                                      <p>{browserLang === "en" ? "Length (m) 3" : "Longitud (m) 3"}</p>
                                      <Input name="feedbackDamage3Length" onChange={this.handleChange} value={this.state.feedbackDamage3Length}>
                                      </Input>  
                                    </Grid.Column>
                                    <Grid.Column>
                                      <p>{browserLang === "en" ? "Width (m) 3" : "Ancho (m) 3"}</p>
                                        <Input name="feedbackDamage3Width" onChange={this.handleChange} value={this.state.feedbackDamage3Width}>
                                        </Input>  
                                    </Grid.Column>
                                  </Grid.Row>
                                  <Grid.Row columns='equal'>
                                    <Grid.Column>
                                      <Form>
                                        <p>{browserLang === "en" ? "Comments" : "Comentarios"}</p>
                                        <TextArea placeholder={browserLang === "en" ? "Comments" : "Comentarios"} label= {browserLang === "en" ? "Comments" : "Comentarios"} name="feedbackComments" onChange={this.handleChange} value={this.state.feedbackComments}></TextArea>
                                      </Form>
                                    </Grid.Column>
                                  </Grid.Row>
                                </Grid>
                                </Fragment>
                                
                                )}
                              <Divider hidden></Divider>
                              <Grid.Row>
                                <Button disabled={this.state.disableAdd} loading={this.state.loading} onClick={this.handleSubmit} positive>{browserLang === "en" ? "Add to new list" : "Agregar a lista nueva"}</Button>
                              </Grid.Row>
                            </Fragment>
                            )
                          }
                          <Divider></Divider>
                          {/*currentUser && "user" in currentUser && "id" in currentUser.user && currentUser.user.id && (
                            <Fragment>
                              <Grid.Row>
                                <h2>{browserLang === "en" ? "Save To Google Drive" : "Guardar A Google Drive"}</h2>
                              </Grid.Row>
                              <Grid.Row>
                                <SaveToDrive browserLang={browserLang} userId = {currentUser.user.id} fileDataArr={[{filename: "engin-ai-qa-export" , data: this.state.qaGenerated, type:"csv"}, {filename: "engin-ai-classification-export", data: this.state.classificationGenerated,  type:"csv"}, {filename: "engin-ai-feedback-export", data: this.state.feedbackGenerated,  type:"csv"}]} ></SaveToDrive>
                              </Grid.Row>
                              <Divider hidden></Divider>
                            </Fragment>
                            )
                          */}
                          <Grid.Row>
                          <h2>{browserLang === "en" ? "Download" : "Descarga"}</h2>
                        </Grid.Row>
                         {this.state.qaGenerated.length > 0 && (
                            <Fragment>
                              <Grid.Row>
                                 <p>{browserLang === "en" ?  "Quality Assurance" : "Aseguramiento de Calidad"}</p>
                              </Grid.Row>
                              <Grid.Row>
                                <CSVLink filename={"engin-ai-qa-export.csv"} data={this.state.qaGenerated}>{browserLang==="en" ? "Download QA document" : "Descargar documento QA"}</CSVLink>
                              </Grid.Row>
                              <Divider hidden></Divider>
                            </Fragment>
                            )}
                          {this.state.classificationGenerated.length > 0 && (
                            <Fragment>
                              <Grid.Row>
                                <p>{browserLang === "en" ?  "Quick Classification" : "Clasificación Rápida"}</p>
                              </Grid.Row>
                              <Grid.Row>
                                <CSVLink filename={"engin-ai-classification-export.csv"} data={this.state.classificationGenerated}>{browserLang==="en" ? "Download Classification document" : "Descargar documento clasificado"}</CSVLink>
                              </Grid.Row>
                            </Fragment>                  
                            )}
                           {this.state.feedbackGenerated.length > 0 && (
                            <Fragment>
                              <Grid.Row>
                                <p>{browserLang === "en" ? "Feedback" : "Retroalimentación"}</p>
                              </Grid.Row>
                              <Grid.Row>
                                <CSVLink filename={"engin-ai-feedback-export.csv"} data={this.state.feedbackGenerated}>{browserLang==="en" ? "Download feedback document" : "Descargar documento de retroalimentación"}</CSVLink>
                              </Grid.Row>
                            </Fragment>                  
                            )}
                        </Segment>
                    )
                }
                </Grid.Column>  
            </Grid.Row>
            {this.state.parsedCsvData && "length" in this.state.parsedCsvData && (
              <Fragment>
               <Grid.Row>
                  <h2>{browserLang === "en" ? "Table Controllers" : "Controladores de tabla"}</h2>
                </Grid.Row>
                <Grid.Row>
                    <Button color="blue" onClick={()=>{
                      this.setState({
                        startingRow:1
                      });
                    }}>Start</Button>
                    <Button color="yellow" onClick={()=>{
                      let newStartingRow = this.state.startingRow - this.state.maxRowCount;
                      if(newStartingRow > 1){
                        this.setState({
                        startingRow:newStartingRow
                      });
                      }
                    }}>Previous</Button>
                    <Button color="green" onClick={()=>{
                      let newStartingRow = this.state.startingRow + this.state.maxRowCount;
                      if(newStartingRow < this.state.parsedCsvData.length){
                        this.setState({
                          startingRow:newStartingRow
                        });
                      }
                    }}>Next</Button>
                     <Button color="blue" onClick={()=>{
                      this.setState({
                        startingRow:this.state.parsedCsvData.length - this.state.maxRowCount
                      });
                    }}>End</Button>
                </Grid.Row>
                <Grid.Row>
                  <p>{browserLang === "en" ? `Count` : `Cuenta`}</p>
                </Grid.Row>
                <Grid.Row>
                  <p>{`${this.state.startingRow} - ${this.state.maxRowCount + this.state.startingRow}/${this.state.parsedCsvData.length}`}</p>
                </Grid.Row>
                <Grid.Row>
                  <Input
                    type="number"
                    value={this.state.startingRowCustomize}
                    name ="startingRowCustomize"
                    onChange={(event, data)=>{
                      this.setState({
                        startingRowCustomize:parseInt(data.value, 10)
                      });
                    }}
                    placeholder = {browserLang === "en" ? "Customize Starting Row":"Personaliza la fila de inicio"}
                    label = {browserLang === "en" ? "Customize Starting Row":"Personaliza la fila de inicio"}
                    icon={<Icon name='search' inverted circular link onClick={()=>{
                      if(this.state.startingRowCustomize <= this.state.parsedCsvData.length && this.state.startingRowCustomize > 0 ){
                        this.setState({
                          startingRow:this.state.startingRowCustomize
                        });
                      }
                    }}/>}
                  >  
                  </Input>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={configSectionWidth} style={{"overflowX":"scroll"}}>
                       <Table
                        color="purple"
                        headerRow={this.state.headers}
                        compact
                        selectable
                        tableData = {tableData}
                        renderBodyRow = {(data, index)=>{
                          let cells = data.map((item, idx)=>{
                            return (
                              <Table.Cell key={idx}>
                                {item}
                              </Table.Cell>
                              );
                          });
                          const imageHasBeenRead = this.state.readURLs.indexOf(data[this.state.imagesColumn]) >= 0;
                          return (
                            <Table.Row key={index}  disabled={imageHasBeenRead} positive={imageHasBeenRead} warning={index === this.state.selectedRowIdx && !imageHasBeenRead} style={{"cursor":"pointer", "color": index === this.state.selectedRowIdx ? "red":"black", "background":index === this.state.selectedRowIdx ? "whitesmoke":"white"}} onClick={()=>{
                              let overallPosition = index;
                              if(this.state.withImages && this.state.imagesColumn >=0){
                                let imageArr = [data[this.state.imagesColumn]];
                                if(this.state.twoImages){
                                  imageArr = [...imageArr, data[this.state.secondImageColumn]]
                                }
                                this.setState({
                                  images:imageArr,
                                  selectedRow:data,
                                  disableAdd:false,
                                  selectedRowIdx:index
                                });
                              }
                              }
                            }>
                            {cells}
                            </Table.Row>
                            )
                        }}
                  >
                  </Table>
                  <Divider></Divider>
                        </Grid.Column>
                    </Grid.Row>
                    </Fragment>
                    )
                  }
        </Grid>  
        </div>
          
      </Fragment>
      
     
    );
  }
}

export default ImageVisualizer;