import React, { Component, Fragment } from 'react';
import { HashRouter as Router, Switch, Route } from 'react-router-dom';
import { Container } from 'semantic-ui-react';
import '../styles/AdminApp.css';
import Menubar from './Menubar';
import ProjectsGrid from './ProjectsGrid';
import ProjectPage from './ProjectPage';
import LoginPage from './LoginPage';
import CreateProject from '../containers/CreateProject';
import GradientHeader from '../containers/GradientHeader';
import { CreateProjectProvider } from '../context/CreateProjectContext';

class AdminApp extends Component {

  render() {
    const { currentUser: { user } } = this.props;
    return (
      <Fragment>
        {(process.env.REACT_APP_USE_GLOBAL_NAV === 'false' || process.env.REACT_APP_USE_GLOBAL_NAV === '' || typeof process.env.REACT_APP_USE_GLOBAL_NAV === 'undefined') && (
          <GradientHeader />
        )}

        {(process.env.REACT_APP_USE_SIDEBAR === 'false' || process.env.REACT_APP_USE_SIDEBAR === '' || typeof process.env.REACT_APP_USE_SIDEBAR === 'undefined') ? (
          <div>
            <Menubar profileImageUrl={user.profileImageUrl} username={`${user.f_name} ${user.l_name}`} location={window.location} user={user} pageLang="en" history={this.props.history} userRole={user.role} active="admin">
              <Router>
                <Switch>
                  <Route path="/admin/create-project"
                    render={
                      props => {
                        return (
                          <CreateProjectProvider>
                            <CreateProject browserLang={this.props.browserLang} {...this.props} />
                          </CreateProjectProvider>
                        )
                      }
                    }
                  />
                  <Route path="/admin/:projectId"
                    render={
                      props => {
                        return (
                          <ProjectPage browserLang={this.props.browserLang} {...this.props}></ProjectPage>
                        )
                      }
                    }
                  />
                  <Route
                    exact
                    path="/admin/"
                    render={() => (
                      <ProjectsGrid
                        linkPrefix="/admin/"
                        title="Edit Projects:"
                        newButton={true}
                        currentUser={this.props.currentUser}
                      />
                    )}
                  />
                  <Route exact path="/admin/login"
                    render={
                      props => {
                        return (
                          <LoginPage browserLang={this.props.browserLang} {...this.props}></LoginPage>
                        )
                      }
                    }
                  />
                </Switch>
              </Router>
            </Menubar>
          </div>
        ) : (
          <>
            <div style={{ background: '#f7f7f7', minHeight: '100vh' }}>
                <Router>
                  <Switch>
                    <Route path="/admin/create-project"
                      render={
                        props => {
                          return (
                            <CreateProjectProvider>
                              <CreateProject browserLang={this.props.browserLang} {...this.props} />
                            </CreateProjectProvider>
                          )
                        }
                      }
                    />
                    <Route path="/admin/:projectId"
                      render={
                        props => {
                          return (
                            <ProjectPage browserLang={this.props.browserLang} {...this.props}></ProjectPage>
                          )
                        }
                      }
                    />
                    <Route
                      exact
                      path="/admin/"
                      render={() => (
                        <ProjectsGrid
                          linkPrefix="/admin/"
                          title="Edit Projects:"
                          newButton={true}
                          currentUser={this.props.currentUser}
                        />
                      )}
                    />
                    <Route exact path="/admin/login"
                      render={
                        props => {
                          return (
                            <LoginPage browserLang={this.props.browserLang} {...this.props}></LoginPage>
                          )
                        }
                      }
                    />
                  </Switch>
                </Router>
            
            </div>
          </>
        )}

      </Fragment>
    );
  }
}

export default AdminApp;
