export const VIDEO_PLAYER_WIDTH = 800;
export const VIDEO_OFFSET_HEIGHT = 200;

export const getInitTransform = (width, videoWidth, videoHeight) => {
  let x = 0, y = 0, scale = 1;
  if (videoWidth !== width) {
    scale = videoWidth / width;
    x = videoWidth * (scale - 1) / 2;
    y = videoHeight * (scale - 1) / 2;
  }
  return { x, y, scale }
}

export const getVideoOffset = (videoWidth, videoHeight, innerHeight) => {
  const videoHeightOffset = innerHeight - VIDEO_OFFSET_HEIGHT;
  let videoWidthOffest = videoWidth;
  const ratio = videoWidth / videoHeight;
  videoWidthOffest = videoHeightOffset * ratio;
  if (videoWidthOffest >= VIDEO_PLAYER_WIDTH) return { videoWidth: VIDEO_PLAYER_WIDTH, videoHeight: VIDEO_PLAYER_WIDTH / ratio }
  return { videoWidth: videoWidthOffest, videoHeight: videoHeightOffset }
}
