import React from 'react';
import { Segment, Button, Icon, Grid, Form, TextArea, Input } from 'semantic-ui-react';
import '../App.css';import { HashLink as Link } from 'react-router-hash-link';
import {Helmet} from "react-helmet";

const AiConsulting = (props)=>{
	const browserLang = props.browserLang;
	const spanish = browserLang.indexOf("es") > -1;
	const imgUrl = "images/background-image-hero.jpg";
	let backgroundImg = `linear-gradient(45deg, rgb(108, 16, 204) 1%, rgb(126, 81, 177) 30%, rgb(162, 98, 153) 69%, rgba(220, 40, 55, 0.7) 100%),url(${imgUrl})`;
	return(
		<header className="App-hero" style={{
			"minHeight":"100vh",
			"backgroundImage":backgroundImg,
			"color":"white"
		}}>
         <Helmet>
			<title>
				Engin.AI - AI Consulting Services
			</title>
			<meta name="description" content="At Engin.AI we help clients at all levels of AI adoption. We provide consulting in data collection strategy, data curation, AI strategy, AI implementation and deployment" />
		    <meta property="og:title" content="Engin.AI - AI Consulting | Data Strategy | AI Strategy"/>
		    <meta property="og:type" content="website"/>
		    <meta property="og:url" content="https://www.engin.ai"/>
		    <meta property="og:description" content="At Engin.AI we help clients at all levels of AI adoption. We provide consulting in data collection strategy, data curation, AI strategy, AI implementation and deployment"/>
		    <meta name="keywords" content="engin ai, enginai, engin.ai, AI consulting services, AI solutions, computer vision, deep learning, data collection, data strategy, ai strategy, ai deployment"/>
		    <meta name="robots" content="index, follow"/>
		    <meta http-equiv="Content-Type" content="text/html; charset=utf-8"/>
		    <meta name="language" content="English"/>
		    <meta name="author" content="Javier Urbina"/>
		    <meta property="og:image" content="%PUBLIC_URL%/full-color-logo-only-01-01.png" />
		</Helmet>
		<Segment basic style={{width:"70%","zIndex":"10"}} className="cta-hero-section">
		<Grid stackable>
			<Grid.Row columns={2}>
				<Grid.Column style={{"textAlign":"left"}}>
					<h1 style={{"textTransform": "uppercase"}}>
			{spanish ? (
				"Consultoría en AI"
				):
				"AI Consulting"}
			
			</h1>
			<br/>
			<br/>
			<h2 style={{"lineHeight":"1.5"}}>{spanish ? 
				"¿Quisiera potenciar su empresa con soluciones personalizads? Nuestros consultores le pueden ayudar a crear soluciones para sus necesidades operacionales y de negocios." : 
				"Would you like to find custom AI solutions for your company? Our AI consultants will work with you to create taylor-made solutions to your business and operational needs."}</h2>
			<br/>
		
			
				</Grid.Column>
				<Grid.Column>
					<Segment>
						<Form style={{"color":"black"}} action="https://formspree.io/engin.ai.solutions@gmail.com" method="POST">
							<h2>
								{spanish? "Solicite una Asesoría Inicial Gratuita"
									:
									"Request a Free Initial Assessment"
								}
							</h2>
							<Segment basic style={{"textAlign":"left"}}>
							<Form.Field>
								<label htmlFor="user_name">Name</label>	
								<Input name="name" id="user_name" placeholder="John Doe">
								</Input>
							</Form.Field>
							<Form.Field>
								<label htmlFor="email" >Email</label>	
								<Input required name="name" id="email" placeholder="jdoe@example.com">
								</Input>
							</Form.Field>
							<Form.Field>
								<label htmlFor="company">Company</label>	
								<Input name="name" id="company" placeholder="My Business">
								</Input>
							</Form.Field>
							<Form.Field>
								<label htmlFor="comment">Comment</label>	
								<TextArea name="name" id="comment">
									
								</TextArea>
								
							</Form.Field>
							</Segment>
							
								<Button type="submit" color="red" size="huge" syle={{"width":"50%"}} >
								{spanish?
									<span className="buttonText">Contáctenos</span> 
									 : 
									<span>Contact Us</span>}
								<span className ="iconContainer" style={{"display":"inline-block", "width":"10px"}}>
									<Icon name="chevron right">
										
									</Icon>
								</span>
							</Button>
						</Form>
					</Segment>
				</Grid.Column>
			</Grid.Row>
		</Grid>
			
					<Link to="/#contact-us">
				

			</Link>
		</Segment>

      </header>
		);	
} 

export default AiConsulting;