import React, {Fragment} from 'react';
import { Segment, Button, Icon, Grid, Container, Divider, Image, Embed } from 'semantic-ui-react';
import './App.css';
import DiagonalSpacer from "./components/DiagonalSpacer";
import CloudComputing from "./images/cloud-computing-01-01.png";
import AiProcess from "./images/ai-process-01-01.png";
import ReportVector from "./images/report-vector-01-01.png";
import ConsultingImg from "./images/Creative_process_SVG.svg";
import DataAnalysis from "./images/Data_analysis_SVG.svg";
import City from "./images/City_SVG.svg";
import FormSpreeForm from './containers/FormSpreeForm';
import { HashLink as Link } from 'react-router-hash-link';
import {Helmet} from "react-helmet";
import ReactGA from 'react-ga';

const Homepage = (props)=>{
	const browserLang = props.browserLang;
  const {language} = props; 
	const spanish = language.indexOf("es") > -1;
	return (
		<Fragment>
    <Helmet>
      <title>Engin.ai - AI Solutions for Engineers | AI Consulting, data labeling, damage analysis in pavements</title>
      <meta name="description" content="At Engin.AI we create custom AI solutions for engineering companies . We offer data labeling services, AI consulting, and pavement analysis with computer vision" />
      <meta property="og:title" content="Engin.AI - AI Solutions for Engineers | Your AI Strategic Partner"/>
      <meta property="og:type" content="website"/>
      <meta property="og:url" content="https://www.engin.ai"/>
      <meta property="og:description" content="At Engin.AI we create custom AI solutions for engineering companies . We offer data labeling services, AI consulting, and pavement analysis with computer vision"/>
      <meta name="keywords" content="engin ai, enginai, engin.ai, ai for engineers, ai consulting,  data labeling services, AI solutions, computer vision, damage analysis in pavements"/>
      <meta name="robots" content="index, follow"/>
      <meta http-equiv="Content-Type" content="text/html; charset=utf-8"/>
      <meta name="language" content="English"/>
      <meta name="author" content="Javier Urbina"/>
      <meta property="og:image" content="%PUBLIC_URL%/full-color-logo-only-01-01.png" />
    </Helmet>
			<header className="App-header">
        
		<Segment basic style={{width:"70%","zIndex":"10"}}>
			<h1 style={{"textTransform": "uppercase"}}>
			{spanish ? (
				"Empoderamos su negocio con inteligencia artificial"
				):
				"Empowering your business with artificial intelligence"}
			
			</h1>
			<br/>
			<br/>
			<h2>{spanish ? 
				"Haga parte de la 4ta revolución industrial con Engin.ai, su aliado estratégico" : 
				"Enter the 4th industrial revolution with Engin.ai, your strategic partner"}</h2>
			<br/>
			<Link to="/#contact-us">
				<Button className="mainCTABtn" inverted size="huge" syle={{"width":"50%"}} onClick={()=>{
          ReactGA.event({
            category: 'Contact Us',
            action: 'Contact Us Main Btn Click',
            label: 'Homepage Main Btn'
          }); 
        }} >
				{spanish?
					<span className="buttonText">Contáctenos</span> 
					 : 
					<span>Contact Us</span>}
				<span className ="iconContainer" style={{"display":"inline-block", "width":"10px"}}>
					<Icon name="chevron right">
						
					</Icon>
				</span>
			</Button>

			</Link>
			
				
		</Segment>

      </header>
      <DiagonalSpacer tilt="tilt-down"></DiagonalSpacer>
      <section>
      
        <Container>
          <h1>{spanish? "Lo que ofrecemos":"What we offer"}</h1>  
          <Grid centered stackable columns={2} divided>
            <Grid.Column style={{"zIndex":"3"}}>
              <Segment basic>
                <Grid container centered>
                  <Grid.Row>
                    <Image  alt="people around a table analyzing data" className="custom-icon" size="small" src={DataAnalysis}></Image>
                  </Grid.Row>
                  <Grid.Row>
                    <h2>{spanish? "Etiquetado de datos":"Data Labeling"}</h2>
                  </Grid.Row>
                  <Grid.Row>
                    <Segment compact basic size="large" style={{"padding":"0"}}>
                      {spanish? "Los algoritmos de aprendizaje sólo serán tan buenos como los datos usados para entrenarlos. Nosotros le ayudamos con el proceso de etiquetado para que usted sólo se preocupe de entrenar sus modelos.":
                      "A learning model will only be as good as the data used to train it. Our expert team will help you label your data so you only need to worry about training and using your model."}
                    </Segment>

                    
                  </Grid.Row>
                  <Grid.Row>
                  	<Button onClick={()=>{
                        ReactGA.event({
                          category: 'Learn More',
                          action: 'Learn More - Data Labeling',
                          label: 'Homepage Learn More Btn - Data Labeling'
                        }); 
                      }}
                      href="/#data-labeling" color="green">{spanish?"Aprender Más":"Learn More"}</Button>
                  </Grid.Row>
                </Grid>
              </Segment>

            </Grid.Column>
            
            <Grid.Column style={{"zIndex":"3"}}>
              <Segment basic>
                 <Grid container centered>
                  <Grid.Row>
                    <Image alt="buildings in a city" className="custom-icon" size="small" src={City}></Image>
                  </Grid.Row>
                  <Grid.Row>
                    <h2>{spanish? "Análisis de daño de pavimentos":"Damage Analysis in Pavements"}</h2>
                  </Grid.Row>
                  <Grid.Row>
                    <Segment compact basic size="large" style={{"padding":"0"}}>
                      {spanish? "¿Tiene fotos de pavimentos para analizar? Con nuestros algoritmos de visión computacional, nosotros podemos analizarlos con gran precisión y en tiempo récord":
                      "Do you have photos pavements to analyze? With our computer vision algorithms, we can analyze them with great accuracy and in a record time"}
                    </Segment>
                    
                  </Grid.Row>
                	<Grid.Row>
                  	<Button onClick={()=>{
                        ReactGA.event({
                          category: 'Learn More',
                          action: 'Learn More - Damage Analysis in Pavements',
                          label: 'Homepage Learn More Btn - Damage Analysis in Pavements'
                        }); 
                      }} href="/#pavements-analysis" color="green">{spanish?"Aprender Más":"Learn More"}</Button>
                  </Grid.Row>
                </Grid>
              </Segment>
            </Grid.Column>

            <Divider></Divider>
            <Divider></Divider>
          </Grid>
        </Container>
       
      </section>
       <DiagonalSpacer background="whitesmoke" tilt="tilt-up"></DiagonalSpacer>
      <section>
        <Grid container stackable columns={2} verticalAlign='middle'>
          <Grid.Column style={{"zIndex":"3"}}>
            <Image src="https://res.cloudinary.com/dqwgbheqj/image/upload/v1668901474/demo-photos/pavement-without-ai_lwbtlh.jpg">
            </Image>
          </Grid.Column>
          <Grid.Column style={{"zIndex":"3"}}>
              <h1>{spanish? "El Programa en Acción":"Our Software in Action"}</h1>
              <Segment basic size="massive" style={{"lineHeight":"1.5"}}>
                {spanish? "Usamos algoritmos de visión computacional para analizar y clasificar daños en diversos pavimentos.":
                "We use computer vision algorithms to analyze and classify damages in different pavements."}
              </Segment>
              <Button onClick={()=>{
                        ReactGA.event({
                          category: 'Learn More',
                          action: 'Learn More - Pavements Analysis',
                          label: 'Homepage Learn More Btn - Pavements Analysis'
                        }); 
                      }}
                      className="CTA-2" size="huge" href="/#/pavements-analysis">
                {spanish? "Aprender más": "Learn more"}
              </Button>
          </Grid.Column>
        </Grid>
      </section>
      <DiagonalSpacer background="white" tilt="tilt-up"></DiagonalSpacer>
      <section className="purpleBack" >
        <Grid container stackable columns={2} verticalAlign='middle'>
          
          <Grid.Column style={{"zIndex":"3"}}>
              <h1>{spanish? "Por qué escoger a Engin.ai":"Why Choose Engin.ai"}</h1>
              <Segment basic size="massive" style={{"lineHeight":"1.5"}}>
                {spanish? "Tenemos un equipo de profesionales cualificados en diferentes áreas de conocimientos. Esto nos permite trabajar con clientes en diversas industrias, y facilita el desarrollo de soluciones innovadoras para nuestros clientes."
                :
                "We have a qualified team of professionals in diverse areas. This allows us to work with our clients across different industries and enables us to create innovative enterprise solutions for our clients."}
              </Segment>
          </Grid.Column>
          <Grid.Column style={{"zIndex":"3"}}>
         
            <Segment basic>
             <Image alt="people around a table with a light bulb in the middle" src={ConsultingImg}></Image>
            </Segment>
  
          </Grid.Column>
        </Grid>
      </section>
      <DiagonalSpacer background="#5a72ff" tilt="tilt-up"></DiagonalSpacer>
       <section style={{"background":"#5a72ff"}}>
      
        <Container>
          <h1 style={{"color":"white"}}>{spanish? "Cómo Funciona":"How it works"}</h1>  
          <Grid centered stackable columns={3}>
            <Grid.Column style={{"zIndex":"3"}}>
              <Segment>
                <Grid container centered>
                  <Grid.Row>
                    <Image alt="a cloud with a database icon" className="custom-icon" size="tiny" src={CloudComputing}></Image>
                  </Grid.Row>
                  <Grid.Row>
                    <h2>{spanish? "Envío":"Upload"}</h2>
                  </Grid.Row>
                  <Grid.Row>
                    <Segment compact basic size="large" style={{"padding":"0"}}>
                      {spanish? "Envíanos de forma segura los datos que necesitas analizar.":
                      "Send us the data that you need to analyze."}
                    </Segment>
                  </Grid.Row>
                </Grid>
              </Segment>
            </Grid.Column>
            <Grid.Column style={{"zIndex":"3"}}>
              <Segment>
                 <Grid container centered>
                  <Grid.Row>
                    <Image alt="a brain merged with a gear" className="custom-icon" size="tiny" src={AiProcess}></Image>
                  </Grid.Row>
                  <Grid.Row>
                    <h2>{spanish? "Análisis":"Analyze"}</h2>
                  </Grid.Row>
                  <Grid.Row>
                    <Segment compact basic size="large" style={{"padding":"0"}}>
                      {spanish? "Nosotros analizamos los datos con nuestros algoritmos de visión computacional.":
                      "We analyze the data using our computer vision algorithms."}
                    </Segment>
                  </Grid.Row>
                </Grid>
              </Segment>
            </Grid.Column>
            <Grid.Column style={{"zIndex":"3"}}>
              <Segment>
                 <Grid container centered>
                  <Grid.Row>
                    <Image alt="icon with a report and a checkmark" className="custom-icon" size="tiny" src={ReportVector}></Image>
                  </Grid.Row>
                  <Grid.Row>
                    <h2>{spanish? "Reporte":"Report"}</h2>
                  </Grid.Row>
                  <Grid.Row>
                    <Segment compact basic size="large" style={{"padding":"0"}}>
                      {spanish? "Recibe un reporte con el insight que necesitas para tomar decisiones informadas.":
                      "Receive a report with the insight that you need to make informed decisions."}
                    </Segment>
                  </Grid.Row>
                </Grid>
              </Segment>
            </Grid.Column>
          </Grid>
        </Container>
       
      </section>
      <DiagonalSpacer background="white" tilt="tilt-down"></DiagonalSpacer>
      <section className="announcement-section" id="announcements">
          <Grid.Row centered>
            <h1>{spanish? "Finalistas Fase 1 en Competencia Mundial de Inteligencia Artificial": "Phase 1 Finalists in Worldwide AI Competition"}</h1>
          </Grid.Row>
          <Grid container stackable columns={2} verticalAlign='middle'>
          
          <Grid.Column style={{"zIndex":"3"}}>
              <h1>{spanish? "OpenCV AI Competition":"OpenCV AI Competition"}</h1>
              <Segment basic size="massive" style={{"lineHeight":"1.5"}}>
                {spanish?
                  <Fragment>
                    <p>
                      Nuestro producto, RoadEngin, ha sido seleccionado como finalista en la Fase 1 de la competencia mundial de OpenCV. ¡Con este producto esperamos revolucionar la industria de Ingeniería de Pavimentos!
                    </p>
                    <p>
                      ¡Síganos en <a href="https://www.linkedin.com/company/engin-ai">LinkedIn</a> para mantenerse al tanto de nuestro producto!  Para más información sobre la competencia, visite  
                      <a style={{"paddingLeft":"10px"}} href="https://opencv.org/opencv-ai-competition-2021/">{spanish? "la página oficial de la competencia":"the official competition page"}</a>                  
                    </p>
                  </Fragment> 
                :
                <Fragment>
                  <p>
                    Our product, RoadEngin, has been selected as a Phase 1 finalist in the Worldwide competition hosted by OpenCV. Our goal is to revolutionize the Road Survey industry with this product! 
                  </p>
                  <p>
                    Follow us in <a href="https://www.linkedin.com/company/engin-ai">LinkedIn</a> for more updates! For more information about the competition, see  
                    <a style={{"paddingLeft":"10px"}} href="https://opencv.org/opencv-ai-competition-2021/">{spanish? "la página oficial de la competencia":"the official competition page"}</a>                  
                  </p>
                </Fragment>

                  }
              </Segment>
          </Grid.Column>
          <Grid.Column style={{"zIndex":"3"}}>
         
            <Segment basic>
             <Image alt="people around a table with a light bulb in the middle" src="https://opencv.org/wp-content/uploads/2021/03/OAK2021-5-@2x.png"></Image>
            </Segment>
  
          </Grid.Column>
        </Grid>
      </section>
      <DiagonalSpacer background="white" tilt="tilt-down"></DiagonalSpacer>
      <section className="cta-section" id="contact-us">
      <Grid.Row centered>
        <h1>{spanish? "Entre a la era AI con Engin.ai": "Enter the AI era with Engin.ai"}</h1>
      </Grid.Row>
      <Grid.Row centered>
        <p>{spanish? "Permítanos ayudarle a automatizar tareas para que usted pueda enfocarse en lo que sabe hacer mejor.": 
        "We can help you automate tasks so you can focus on doing what you do best."}</p>
      </Grid.Row>
      <Divider></Divider>
      <Grid.Row centered>
      <Container textAlign="center">
      	
      		<FormSpreeForm style={{"display":"inline-block"}}></FormSpreeForm>
      	
      </Container>
      	
      		
            	
      </Grid.Row>
       
      </section>
		</Fragment>
		
		);

}


export default Homepage;