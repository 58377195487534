import React, {useState} from 'react';
import { Button, Message, Grid} from 'semantic-ui-react';

const CleanupDeletedImageIds = ({buttonText, endpoint, apiCall})=>{
	const [loading, setLoading] = useState(false);
	const [message, setMessage] = useState("");
	const handleCleanImages = async () =>{
	    setLoading(true);
	    const cleanImagesMessage = await apiCall("POST",endpoint);
	    setLoading(false);
	    if(cleanImagesMessage && "message" in cleanImagesMessage && cleanImagesMessage.message){
	    	setMessage(cleanImagesMessage.message);
	    }
	  };
	return (
		<Grid textAlign='left' style={{width:"100%"}}>
			<Grid.Row columns='equal' style={{width:"100%"}}>
				<Grid.Column basic>
					<Button loading={loading} color="yellow" onClick={handleCleanImages}>{buttonText}</Button>
				</Grid.Column>
				{message && message !== "" &&(
					<Grid.Column basic>
						<Message positive>
							{message}
						</Message>
					</Grid.Column>
					)}
			</Grid.Row>
		</Grid>
		);
};

export default CleanupDeletedImageIds;