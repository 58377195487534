import React, { useState } from 'react';
import axios from 'axios';
import { useCreateProjectContext } from '../../context/CreateProjectContext';
import { Modal, Progress } from 'semantic-ui-react';


const AnalyzeHandler = ({ onUploadComplete, bucketName }) => {

    const { 
            formDataAboutContext, 
            formDataConfigureContext, 
            uploadedFiles, 
            shpFieldFor, 
            selectedFiles, 
            fileStructure, 
            baseUrlFile, 
            calibrationData, 
            aboutBucketName,
            companyId,
        } = useCreateProjectContext();

    const [uploading, setUploading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [error, setError] = useState(null);
    const [projectId, setProjectId] = useState('');
    const [inProgressFolderName,setInProgressFolderName] = useState(null);
    const [inProgressFileName,setInProgressFileName] = useState(null);
    const token = localStorage.getItem("enginJwtToken");

    let compGcsBucketName = bucketName;

    if (compGcsBucketName === '') {
        compGcsBucketName = aboutBucketName || process.env.REACT_APP_APLICATION_BUCKET_DEFAULT
    }

    const lastSlashIndex = baseUrlFile.lastIndexOf('/');
    const baseUrl = baseUrlFile.substring(0, lastSlashIndex + 1);

    const extractFileNames = (files) => {
        let fileNames = {};
        Object.entries(files).forEach(([key, value]) => {
            if (value) {
                fileNames[key] = `${baseUrl}${value.name}`;
            }
        });
        return fileNames;
    };

    const extractMetadataValues = (settings) => {
        let values = {};
        Object.entries(settings).forEach(([key, value]) => {
            values[key] = value.value;
        });
        return values;
    };

    const uploadFiles = async (files, compGcsBucketName, folderPath) => {
        try {
            let uploadedCount = 0;
            let bucketName = compGcsBucketName || process.env.REACT_APP_APLICATION_BUCKET_DEFAULT;
            for (const file of files) {
                const filename = file?.name;
                if(filename){
                    setInProgressFileName(filename);
                }
                const response = await axios.get(`${process.env.REACT_APP_PRODUCTION_SERVER_URL}/api/gcloud/signUrl?file-name=${folderPath}${file.name}&file-type=${file.type}&bucketName=${compGcsBucketName}`, {
                    headers: {
                        "Authorization": `Bearer ${token}`,
                    }
                });

                const { signedUrl } = response.data;

                try {
                    const response = await fetch(signedUrl, {
                        method: 'PUT',
                        body: file,
                        headers: {
                            "Content-Type": "application/octet-stream",
                        }
                    });
                
                    if (response.ok) {
                        uploadedCount++;
                        setUploadProgress(Math.round((uploadedCount / files.length) * 100));
                
                        const fileUrl = new URL(signedUrl);
                        fileUrl.search = '';
                        const finalUrl = fileUrl.toString();
                    } else {
                        console.error("File upload failed", response.statusText);
                    }
                } catch (error) {
                    console.error("File upload error", error);
                }
                
            }
            setInProgressFileName(null);

        } catch (error) {
            console.error('Error uploading files:', error);
        }
    };


    const uploadFolderStructure = async (structure, compGcsBucketName, folderPath) => {
        for (const key in structure) {
            if (key === 'files' && structure[key].length > 0) {
                const filename = structure[key]?.name;
                if(filename){
                    setInProgressFileName(filename);
                }
                await uploadFiles(structure[key], compGcsBucketName, folderPath);
            } else if (typeof structure[key] === 'object') {
              setInProgressFolderName(key)  
              await uploadFolderStructure(structure[key], compGcsBucketName, `${folderPath}${key}/`);
            }
        }
        setInProgressFolderName(null);
        setInProgressFileName(null);
    };

    const handleFileUpload = async () => {
        setUploading(true);
        setError(null);

        const fileNames = extractFileNames(uploadedFiles);
        const metadataValues = extractMetadataValues(shpFieldFor);
        const companyIdToSave = companyId || null;
        if (companyIdToSave === "null") {
            companyIdToSave = null
        }

        try {
            const payload = {
                name: formDataAboutContext.projectName || 'New Project',
                modelType: {
                    categories: formDataAboutContext.modelType ? [formDataAboutContext.modelType] : [],
                    aditionalDetectionOptions: formDataAboutContext.selectedOptions || []
                },
                assessmentConfiguration: {
                    selectedCamera: formDataConfigureContext.selectedCamera || '',
                    selectedLocation: formDataConfigureContext.selectedLocation || '',
                    selectedSensor: formDataConfigureContext.selectedSensor || '',
                    selectedUnit: formDataConfigureContext.selectedUnit || '',
                    visibleLaneMarks: formDataConfigureContext.visibleLaneMarks || false,
                    pavementRatingMethodology: formDataConfigureContext.pavementRatingMethodology || '',
                },
                bucketName: compGcsBucketName || process.env.REACT_APP_APLICATION_BUCKET_DEFAULT,
                companyId: companyIdToSave,
                fileNames: fileNames || [],
                metadataSettings: metadataValues || {},
                calibrationData: {
                    fileName: calibrationData.fileName || '',
                    dimensions: {
                        width: calibrationData.dimensions?.width || 0,
                        height: calibrationData.dimensions?.height || 0,
                    },
                    unit: calibrationData.unit || '',
                    videoSize: {
                        width: calibrationData.videoSize?.width || 0,
                        height: calibrationData.videoSize?.height || 0,
                    },
                    imageSize: {
                        width: calibrationData.imageSize?.width || 0,
                        height: calibrationData.imageSize?.height || 0,
                    },
                    currentTime: calibrationData.currentTime || 0,
                    points: calibrationData.points || [],
                },
                
            };
            

            const response = await axios.post(`${process.env.REACT_APP_PRODUCTION_SERVER_URL}/api/projects`, payload); 
            const { _id } = response.data;
            setProjectId(_id);

            const folderPath = `${_id}/clientData/`;

            // Upload file structure
            await uploadFolderStructure(fileStructure, compGcsBucketName, folderPath);

            // Upload selected files if any
            if (selectedFiles.length > 0) {
                await uploadFiles(selectedFiles, compGcsBucketName, folderPath);
            }

            if (onUploadComplete) {
                onUploadComplete();
            }

        } catch (error) {
            console.error('Error saving project:', error);
            setError('Error saving project:');
        } finally {
            setUploading(false);
        }
    };

    return (
        <div>
            <button
                id="file-upload-handler-upload-button"
                onClick={handleFileUpload}
                disabled={uploading}
                style={{ display: 'none' }}
            >
                {uploading ? 'Uploading...' : 'Upload files'}
            </button>
            {error && <div>{error}</div>}
            
            <Modal 
                open={uploading}
                closeOnDimmerClick={false} 
                size="small"
            >    
                <Modal.Header>Uploading Files</Modal.Header>
                <Modal.Content>
                    <p>Please do not close the browser until the upload is complete.</p>
                    {inProgressFolderName && (<p>Uploading contents for <strong>{inProgressFolderName}</strong></p>)}
                    <p>Uploading {inProgressFileName}</p>
                    <Progress percent={uploadProgress} indicating progress />
                </Modal.Content>
            </Modal>
        </div>
    );
};


export default AnalyzeHandler;
