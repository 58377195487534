import React, {useState} from 'react';
import {Modal, Button, Image, Header} from 'semantic-ui-react';

const DeleteProjectModal = ({browserLang, isOpen, onDelete, onToggleModal})=>{
	const [loading, setLoading] = useState(false);
	const deleteProject = ()=>{
		setLoading(true)
		if(onDelete){
			onDelete();
			setLoading(false);
			if(onToggleModal){
				onToggleModal(false);
			}
		}
	};
  return (
    <Modal
    	closeOnDimmerClick={true}
    	open={isOpen}
      	onClose={()=> onToggleModal(false)}
    >
      <Modal.Header>{browserLang === "en" ?  "Delete Project" : "Borrar Proyecto"}</Modal.Header>
      <Modal.Content image>
        <Image size='medium' src='https://res.cloudinary.com/dqwgbheqj/image/upload/v1633405815/Illustration_19_me2cel.png' wrapped />
        <Modal.Description>
          <Header>{browserLang === "en" ? "Careful! You're about to delete a project" : "¡Cuidado! Está a punto de borrar un proyecto"}</Header>
          <p>
            {browserLang ==="en" ? "If you delete this project you will be deleting all the photos and labels in it." : "Si borra este proyecto también estará borrando todas las imágenes y eitquetas en el mismo."}
          </p>
          <p>{browserLang === "en" ? "Are you sure you want to delete this project?" : "¿Está seguro de que desea borrar este proyecto?"}</p>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button color='black' onClick={() => onToggleModal(false)}>
          {browserLang === "en" ? "No, cancel" : "No, cancelar"}
        </Button>
        <Button
          content={browserLang === "en" ? "Yes, delete project" : "Sí, borrar proyecto"}
          labelPosition='right'
          icon='checkmark'
          onClick={() => deleteProject()}
          loading={loading}
          color="red"
        />
      </Modal.Actions>
    </Modal>
  )
}; 

export default DeleteProjectModal;