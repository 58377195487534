import React, { useState, useEffect } from 'react';

import {
    Button,
    Container,
    Divider,
    Form,
    Message,
    Radio,
} from 'semantic-ui-react';

import '../../styles/CreateProject.css';
import options from './utils/options';
import axios from "axios";
import { useCreateProjectContext } from '../../context/CreateProjectContext';
import UploaderFilesProccesor from './UploaderFilesProccesor';

const ConfigureDataProject = ({ handlePrevStep, handleNextStep, bucketName }) => {

    const { formDataConfigureContext, updateFormDataConfigure, selectedKeys, setSelectedKeys, setBucketName, pavementRatingMethodologies } = useCreateProjectContext();

    const [errors, setErrors] = useState({
        selectedUnit: '',
        selectedCamera: '',
        selectedSensor: '',
        selectedLocation: '',
        selectedDataFormat: ''
    });

    const handleChangePavement = (event) => {
        updateFormDataConfigure({ pavementRatingMethodology: event.target.value });
    };

    const [submitError, setSubmitError] = useState('');
    const [filesUploaded, setFilesUploaded] = useState(false);
    const [confirmed, setConfirmed] = useState(false);

    useEffect(() => {
        setFilesUploaded(!validateFormData());

    }, [formDataConfigureContext]);

    useEffect(() => {
        if (selectedKeys && selectedKeys.length > 0) {
            setConfirmed(true);
        } else {
            setConfirmed(false);
        }
    }, [selectedKeys]);

    const validateFormData = () => {
        const { selectedUnit, selectedCamera, selectedSensor, selectedLocation, selectedDataFormat } = formDataConfigureContext;
        const newErrors = {
            selectedUnit: selectedUnit ? '' : 'Please select a measurement unit.',
            selectedCamera: selectedCamera ? '' : 'Please select an option for What does the data look like?.',
            selectedSensor: selectedSensor ? '' : 'Please select a data source.',
            selectedLocation: selectedLocation ? '' : 'Please select an option for Road Distress Reporting.',
            selectedDataFormat: selectedDataFormat ? '' : 'Please select an option for Data Format'
        };
        setErrors(newErrors);
        return Object.values(newErrors).every(error => error === '');
    };

    const handleRadioChange = (event, { name, value }) => {
        updateFormDataConfigure({ [name]: value });
    };

    const handleToggleChange = () => {
        updateFormDataConfigure({ visibleLaneMarks: !formDataConfigureContext.visibleLaneMarks });
    };

    const handleNext = (e) => {
        e.preventDefault();
        if (validateFormData()) {
            handleNextStep();
        } else {
            setSubmitError('Please select at least one option for all categories.');
        }
    };

    const handleFilesUploaded = (status) => {
        setFilesUploaded(status);
    };

    const handleResetFiles = () => {

        setFilesUploaded(false);
    };

    const { selectedSensor } = formDataConfigureContext;

    return (
        <>
            <Container className="inside-container">
                <Container textAlign='left' style={{ width: '100%' }} className="inside-container">
                    <div>
                        <p className="label-grey">Pavement Condition Assessment types:</p>
                    </div>
                    <Form>
                        <div className='mt-30'>
                            <p className="subtitle">Measurement units </p>
                        </div>
                        <div className='mt-10'>
                            {options.units.map(option => (
                                <Radio
                                    key={option.value}
                                    label={option.label}
                                    className='mr-label'
                                    name='selectedUnit'
                                    value={option.value}
                                    checked={formDataConfigureContext.selectedUnit === option.value}
                                    onChange={handleRadioChange}
                                />
                            ))}
                            {errors.selectedUnit && <p style={{ color: 'red' }}>{errors.selectedUnit}</p>}
                        </div>
                        <div className='mt-30'>
                            <p className="subtitle">What does the data look like?</p>
                        </div>
                        <div className='mt-10'>
                            {options.cameraOptions.map(option => (
                                <Radio
                                    key={option.value}
                                    label={option.label}
                                    className='mr-label'
                                    name='selectedCamera'
                                    value={option.value}
                                    checked={formDataConfigureContext.selectedCamera === option.value}
                                    onChange={handleRadioChange}
                                />
                            ))}
                            {errors.selectedCamera && <p style={{ color: 'red' }}>{errors.selectedCamera}</p>}
                        </div>
                        <div className='mt-30'>
                            <p className="subtitle">Data source</p>
                        </div>
                        <div className='mt-10'>
                            {options.sensorOptions.map(option => (
                                <Radio
                                    key={option.value}
                                    label={option.label}
                                    className='mr-label'
                                    name='selectedSensor'
                                    value={option.value}
                                    checked={formDataConfigureContext.selectedSensor === option.value}
                                    onChange={handleRadioChange}
                                />
                            ))}
                            {errors.selectedSensor && <p style={{ color: 'red' }}>{errors.selectedSensor}</p>}
                        </div>
                        {selectedSensor === "GoPro" && <Message color="yellow" content="We extract lat/long data embedded in GoPro files. Please ensure they all have telemetry data. If telemetry is not available, coordinates will not be matched for the video."></Message>}
                        {selectedSensor === "ROMDAS" && <Message color="yellow" content="We currently only accept images or videos for this type of data. Please contact us after sending the data for custom metadata mappings for lat/long coordinates."></Message>}
                        {selectedSensor === "LCMS" && <Message color="yellow" content="We extract rutting data from the XML data if available. Otherwise, such distress is not identified."></Message>}
                        {selectedSensor === "Other" && <Message color="yellow" content="We will contact you once you submit the files to work out the necessary details of processing this data."></Message>}
                        <div className='mt-30'>
                            <p className="subtitle">Data type</p>
                        </div>
                        <div className='mt-10'>
                            {options.dataTypes.map(option => (
                                <Radio
                                    key={option.value}
                                    label={option.label}
                                    className='mr-label'
                                    name='selectedDataFormat'
                                    value={option.value}
                                    checked={formDataConfigureContext.selectedDataFormat === option.value}
                                    onChange={handleRadioChange}
                                />
                            ))}
                            {errors.selectedSensor && <p style={{ color: 'red' }}>{errors.selectedSensor}</p>}
                        </div>
                        <div className='mt-30'>
                            <p className="subtitle">Pavement Rating Methodology</p>
                        </div>
                        <div className='mt-10'>
                            <select
                                value={formDataConfigureContext.pavementRatingMethodology}
                                onChange={handleChangePavement}
                            >
                                {pavementRatingMethodologies.map((methodology) => (
                                    <option key={methodology} value={methodology}>
                                        {methodology}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className='mt-30'>
                            <Divider />
                        </div>
                        <div className='mt-30'>
                            <p className="subtitle">AI Model Vision Boundaries</p>
                        </div>
                        <div className="radio-container mt-30">
                            <Radio
                                toggle
                                className="custom-toggle"
                                checked={formDataConfigureContext.visibleLaneMarks}
                                onChange={handleToggleChange}
                            />
                            <span className="radio-label">Only report distresses within visible lane marks</span>
                        </div>

                        <div className='mt-30'>
                            <p className="subtitle">Road Distress Reporting</p>
                        </div>
                        <div className='mt-10'>
                            {options.distressReportingOptions.map(option => (
                                <div key={option.value} style={{ marginBottom: '10px' }}>
                                    <Radio
                                        label={option.label}
                                        className='mr-label'
                                        name='selectedLocation'
                                        value={option.value}
                                        checked={formDataConfigureContext.selectedLocation === option.value}
                                        onChange={handleRadioChange}
                                    />
                                </div>
                            ))}
                            {errors.selectedLocation && <p style={{ color: 'red' }}>{errors.selectedLocation}</p>}
                        </div>

                        <Message className='mt-30'
                            content='This is dependent upon detecting striping, if striping is not available the model will report all of the visible distresses on the road'
                        />
                        <div className='mt-30'>
                            <Divider />
                        </div>
                        {/* <div className='mt-30'>
                            <p className="label-grey">Upload Metadata</p>
                        </div>
                        <div className='mt-30'>
                            <p className="subtitle">Please upload a KML or Shapefile that comprises all of your road network for the project. Only one file is allowed.</p>
                        </div>
                        <div className='mt-10'>
                            <UploaderFilesProccesor setFilesUploaded={handleFilesUploaded} bucketName={bucketName} />
                            {filesUploaded && (
                                <Message positive>
                                    <Message.Header>Files Uploaded Successfully</Message.Header>
                                    <p>Your files have been successfully uploaded.</p>
                                </Message>
                            )}
                        </div>

                        <div className='mt-30'>
                            <Divider />
                        </div> */}

                        <div className='right-aligned-container'>
                            <Button onClick={handlePrevStep} style={{ marginRight: '10px' }}>Back</Button>
                            <Button className="ui teal button" onClick={handleNext}>Continue</Button>
                        </div>
                    </Form>
                </Container>
            </Container>
        </>
    );
}

export default ConfigureDataProject;
