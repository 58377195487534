import React from "react";
import { Input } from "semantic-ui-react";

const MetadataInput = (props) => {
  const [inputValue, setInputValue] = React.useState(props.value);

  const handleChange = (e, { value }) => {
    setInputValue(value);
  }

  const handleBlur = () => {
    props.changeValues(props.keyValue, props.index, inputValue);
  }

  return (
    <Input
      value={inputValue}
      onChange={handleChange}
      onBlur={handleBlur}
      type={props.type}
      style={props.style}
    />
  )
}

export default MetadataInput;