import React, { useEffect, useState } from 'react';
import '../../styles/GlobalToastNotification.css';
import PropTypes from 'prop-types';

const GlobalToastNotification = ({
    message = '',
    disappearTimeSecs = 3,
    disableDisappear = false,
    position = 'bottomRight',
    notificationType = 'success',
    onClose
}) => {
    const [isVisible, setIsVisible] = useState(true);

    useEffect(() => {
        if (!disableDisappear) {
            const timer = setTimeout(() => {
                setIsVisible(false);
                if (onClose) onClose();
            }, disappearTimeSecs * 1000);

            return () => clearTimeout(timer);
        }
    }, [disappearTimeSecs, disableDisappear, onClose]);

    if (!isVisible) return null;

    const positionStyles = {
        topRight: { top: '20px', right: '20px' },
        bottomRight: { bottom: '20px', right: '20px' },
        topLeft: { top: '20px', left: '20px' },
        bottomLeft: { bottom: '20px', left: '20px' }
    };

    const handleClose = () => {
        setIsVisible(false);
        if (onClose) onClose();
    };

    return (
        <div
            className={`notification-confirm notification-${notificationType} notification-${position}`}
            style={{
                ...positionStyles[position],
            }}
        >
            <span>{message}</span>
            <button
                className='notification-close-btn'
                onClick={handleClose}
                aria-label="Close notification"
            >
                ×
            </button>
        </div>
    );
};

GlobalToastNotification.propTypes = {
    message: PropTypes.string.isRequired,
    disappearTimeSecs: PropTypes.number,
    disableDisappear: PropTypes.bool,
    position: PropTypes.oneOf(['topRight', 'bottomRight', 'topLeft', 'bottomLeft']),
    notificationType: PropTypes.oneOf(['success', 'warning', 'error']),
    onClose: PropTypes.func
};

export default GlobalToastNotification;
