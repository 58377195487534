export const DefaultMetadataItem = {
  key: '',
  name: '',
  description: '',
  type: 'string',
  defaultValue: '',
}

export const LabelMetadataTypes = [
  {key: 'boolean', value: 'boolean', text: 'BOOLEAN'},
  {key: 'number', value: 'number', text: 'NUMBER'},
  {key: 'string', value: 'string', text: 'STRING'},
]

export const LabelMetadataBooleanValues = [
  {key: 'true', value: true, text: 'TRUE'},
  {key: 'false', value: false, text: 'FALSE'},
]
