import React, { Component } from 'react';
import { CRS, LatLngBounds  } from 'leaflet';
import { Map, ImageOverlay, ZoomControl, Popup } from 'react-leaflet';
import Control from 'react-leaflet-control';
import Hotkeys from 'react-hot-keys';
import update from 'immutability-helper';
import 'leaflet-path-drag';
import 'leaflet/dist/leaflet.css';
import { Icon } from 'semantic-ui-react';
import { BBoxFigure, PolygonFigure, UnclosedPolyline } from './Figure';
import { convertPoint, lighten, colorMapping } from './utils';
import { withBounds, maxZoom } from './CalcBoundsHOC';
import ErrorBoundary from '../containers/ErrorBoundary';
import Comments from './Comments';
import DraggableMarker from './DraggableMarker';
import ReplaceLabelModal from './ReplaceLabelModal';
import OptionsModal from './OptionsModal';
import { cloneDeep } from 'lodash';

class Canvas extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      pressedKey: null,
      zoom: -1,
      selectedFigures: [],
      selectedFigureIds: [],
      cursorPos: { lat: 0, lng: 0 },
      mouseDownFlag:false,
      draggableView:false,
      commenting:false,
      areCommentsVisible:true,
      replaceLabelModalOn:false,
      optionsModalOn:false,
      isShiftDown: false,
      editorOptions:{
        lcmsIntDisplay:false,
        lcms3dDisplay:false,
        lcmsRightOfWayDisplay:false,
        lcms3dOverlayDisplay:false
      }
    }
    this.prevSelectedFigure = null;
    this.skipNextClickEvent = false;

    this.mapRef = React.createRef();
    this.handleChange = this.handleChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleMouseMove = this.handleMouseMove.bind(this);
    this.focusCanvas = this.focusCanvas.bind(this);
    this.handlePopupClose = this.handlePopupClose.bind(this);
    this.toggleReplaceModal = this.toggleReplaceModal.bind(this);
    this.handleReplaceLabel = this.handleReplaceLabel.bind(this);
    this.toggleOptionsModal = this.toggleOptionsModal.bind(this);
    this.hideSelectedFigure = this.hideSelectedFigure.bind(this);
  }

  onKeyDownListener = (event) => {
    if(event.keyCode === 16 && this.state.isShiftDown === false){
      this.setState({isShiftDown: true});
    }
  }

  onKeyUpListener = (event) => {
    if(event.keyCode === 16 && this.state.isShiftDown === true){
      this.setState({isShiftDown: false});
    }
  }

  componentWillUnmount(){
    document.removeEventListener('keydown', this.onKeyDownListener);
    document.removeEventListener('keyup', this.onKeyUpListener);
  }

  componentDidMount(){
    const {project} = this.props;
    let editorOptions = {
      lcmsIntDisplay:false,
      lcms3dDisplay:false,
      lcmsRightOfWayDisplay:false
    };


    if(project && "editorOptions" in project && project.editorOptions){
      editorOptions = {...project.editorOptions};      
    // console.log("editorOptions",editorOptions);
      this.setState({
        editorOptions: {...editorOptions}
      });
    }
    document.addEventListener('keydown', this.onKeyDownListener);
    document.addEventListener('keyup', this.onKeyUpListener);
  }

  componentDidUpdate(prevProps, prevState) {
    const { onSelectionChange, editorOptions } = this.props;
    const { selectedFigureIds } = this.state;

    if (this.state.selectedFigureIds !== prevState.selectedFigureIds && onSelectionChange) {
      this.setState({ selectedFigureIds : selectedFigureIds, selectedFigures: this.getSelectedFigures() });
      onSelectionChange(selectedFigureIds);
    }

    if(!prevProps.unfinishedFigure && !!this.props.unfinishedFigure){
      let labelApp = document.querySelector("#labelingApp");
      // console.log(labelApp);
      document.querySelector("#labelingApp").focus();
    }

    if(prevProps.editorOptions !== editorOptions){
      this.setState({
        editorOptions
      })
    }

    if (this.state.pressedKey !== prevState.pressedKey && this.state.pressedKey !== null) this.onKeyDown(this.state.pressedKey);
  }

  focusCanvas(){
    this.canvasRef.current.focus();
  }

  getSelectedFigures() {
    const { selectedFigureIds } = this.state;
    const { figures } = this.props;
    return cloneDeep(figures.filter(f => selectedFigureIds.includes(f.id)));
  }

  handleChange(eventType, { point, pos, figure, points},newLabelId) {
    const { onChange, unfinishedFigure } = this.props;
    const drawing = !!unfinishedFigure;
    // console.log("Canvas handlechange - figure", figure);
    // console.log("Canvas handlechange - points", points);

    switch (eventType) {
      case 'add':
        if (drawing) {
          const newPoints = update(unfinishedFigure.points, { $push: [point] });
          const newUnfinished = update(unfinishedFigure, {
            points: {
              $set: newPoints,
            },
          });
          if (newUnfinished.type === "bbox" && newPoints.length >= 2) {
            onChange('new', [newUnfinished]);
          } else {
            onChange('unfinished', [newUnfinished]);
          }
        } else {
          onChange(
            'replace',
            [update(figure, { points: { $splice: [[pos, 0, point]] } })]
          );
        }
        break;

      case 'end':
        const f = unfinishedFigure;
        onChange('new', [f]);
        break;
      case 'erase':
        onChange('erase', [unfinishedFigure]);
        break;
      case 'expand':
        onChange('expand', [unfinishedFigure]);
        break;
      case 'finishHandrawnLine':
        const figureObject = unfinishedFigure;
        onChange('finishHandrawnLine', [figureObject]);
        break;

      case 'move':
        onChange(
          'replace',
          [update(figure, { points: { $splice: [[pos, 1, point]] } })]
        );
        break;

      case 'replace':
        onChange(
          'replace', 
          [update(figure, { points: { $set: points } })]
        );
        break;

      case 'remove':
        onChange(
          'replace',
          [update(figure, { points: { $splice: [[pos, 1]] } })]
        );
        break;

      default:
        throw new Error('unknown event type ' + eventType);
    }
  }

  onKeyDown(key, e, handle){
    const {
      unfinishedFigure,
      onChange,
      projectCustomShortcuts,
      handleSelected,
      labels
    } = this.props;

    const customKeysArr = projectCustomShortcuts.map((shortcut)=>{
      return shortcut?.shortcut?.toLowerCase();
    });

    const { selectedFigures, selectedFigureIds } = this.state;

    const drawing = !!unfinishedFigure;

          const tagName = document.activeElement
            ? document.activeElement.tagName.toLowerCase()
            : null;
          if (['input', 'textarea'].includes(tagName)) {
            return false;
          }
          if (drawing) {
            if (key === 'f') {
              const { type, points } = unfinishedFigure;
              if (points.length >= 3) {
                if ((['polygon', 'polyline'].includes(type)))
                  this.handleChange('end', {});
                if ((['erase', 'expand'].includes(type)))
                  this.handleChange(type, {})
              }
            }
            if(key === "space"){ 
              this.setState({
                draggableView:true,
                drawing:false 
              });
            }
          } else {
            if (key === 'r' && selectedFigureIds.length) {
              this.toggleReplaceModal(true);
            }

            if (key === 'h' && selectedFigureIds.length) {
              this.hideSelectedFigure();
            }
            // if (key === 'c') {
            //   this.setState({
            //     drawing:false,
            //     commenting:!this.state.commenting,
            //     areCommentsVisible:true
            //   });
            // } else if (key === 'backspace' || key === 'del') {
            //   if (selectedFigureIds && this.getSelectedFigure()) {
            //     onChange('delete', this.getSelectedFigure());
            //   }
            // }
            if (key === 'backspace' || key === 'del') {
              const selectedFigures = this.getSelectedFigures();
              if (selectedFigureIds.length && selectedFigures.length) {
                onChange('delete', selectedFigures);
              }
            }
          }

          const map = this.mapRef.current.leafletElement;
          if (key === 'left' || (key === 'a' && customKeysArr.indexOf(key) <0)) {
            map.panBy([80, 0]);
          }
          if (key === 'right' || (key === 'd' && customKeysArr.indexOf(key) <0)) {
            map.panBy([-80, 0]);
          }
          if (key === 'up' || (key === 'w' && customKeysArr.indexOf(key) <0)) {
            map.panBy([0, 80]);
          }
          if (key === 'down' || (key === 's' && customKeysArr.indexOf(key) <0)) {
            map.panBy([0, -80]);
          }
          if (key === '=') {
            map.setZoom(map.getZoom() + 1);
          }
          if (key === '-') {
            map.setZoom(map.getZoom() - 1);
          }
          if(key === "space"){ 
            this.setState({
              draggableView:true 
            });
          }

          if ((key === "1" || key === "2" || key === "3") && selectedFigureIds) {
            //Find the label that corresponds to the LOW severity
            const foundLabels = this.getSeverityLabels(selectedFigures, labels, key);
            //Replace the label
            if (foundLabels?.length) {
              this.handleReplaceLabel(foundLabels, true);
            }
          }

          // Check if the pressed key matches any shortcut
          
          if (projectCustomShortcuts) {
            projectCustomShortcuts.forEach((shortcut) => {
            if (shortcut && key === shortcut?.shortcut?.toLowerCase()) {
              //Find the label ID in the formparts
              const foundLabel = labels.find((label)=>{
                return label?.name === shortcut.label;
              });
              if(foundLabel && handleSelected){
                //Call handleSelected(id)
                if (selectedFigures?.length) {
                  this.handleReplaceLabel([{ ...foundLabel }]);
                } else {
                  handleSelected(foundLabel?.id);
                }
              }
            }
          });
          }
    if (key === "esc") {
      this.setState({
        selectedFigureIds: [],
        replaceLabelModalOn: false,
      })
      this.props.handleSelected(false);
    }
    this.setState({pressedKey: null})
  }

  toggleReplaceModal(status){
    if(status === true){
      this.setState({
        replaceLabelModalOn:true
      });
    }else if (status === false){
      this.setState({
        replaceLabelModalOn:false
      });
    }
  }

  hideSelectedFigure(){
    if(this.state.selectedFigureIds){
      const selectedFigures = this.getSelectedFigures();
      this.props.onFiguresToggle(selectedFigures);
    }
  }

  toggleOptionsModal(status){
    if(status === true){
      this.setState({
        optionsModalOn:true
      });
    }else if (status === false){
      this.setState({
        optionsModalOn:false
      });
    }
  }

  handleClick(e) {
    const { unfinishedFigure } = this.props;
    const drawing = !!unfinishedFigure;
    if (this.skipNextClickEvent) {
      // a hack, for whatever reason it is really hard to stop event propagation in leaflet
      this.skipNextClickEvent = false;
      return;
    }
    if (e.type === 'contextmenu' && drawing) {
      const { type, points } = unfinishedFigure;
      if ((type === 'polygon' || type === 'polyline') && points.length >= 3) {
        this.handleChange('end', {});
        }  
        return;    
    }
    if (drawing && isWithinBounds(this.props.bounds,e.latlng)) {
      this.handleChange('add', { point: convertPoint(e.latlng) });
      return;
    }

    if (!drawing) {
      if(this.state.commenting && this.props.onNewCommentThread){
        this.props.onNewCommentThread(e);
      }
      this.props.setFigureToolbarOpen(false);
      this.setState({ selectedFigureIds: [] });
      return;
    }
  }

   handlePopupClose = (threadId, threadLength) => {
    if(threadLength === 0 && this.props.onRemoveCommentThread){
      this.props.onRemoveCommentThread(threadId);
    }
  }

  handleMouseMove(e){
    const { unfinishedFigure } = this.props;
    const drawing = !!unfinishedFigure;
    this.setState({ cursorPos: e.latlng });

    if (drawing && this.state.mouseDownFlag && isWithinBounds(this.props.bounds,e.latlng)) {
      this.setState({
                  draggableView:false,
                  cursorPos: e.latlng 
                })
      this.handleChange('add', { point: convertPoint(e.latlng) });
      return;
    }

    if (!drawing) {
      this.setState({ cursorPos: e.latlng  });
      return;
    }

  }

  renderFigure(figure, options) {

    if(!options.visible){
      return null;
    }
    const { unfinishedFigure } = this.props;
    const drawing = !!unfinishedFigure;
    const Comp = figure.type === 'bbox' ? BBoxFigure : (figure.type === 'polyline' ? UnclosedPolyline: PolygonFigure);

    return (
      <Comp
        key={figure.id}
        figure={figure}
        options={options}
        isUserDrawing={drawing}
        skipNextClick={() => (this.skipNextClickEvent = true)}
      />
    );
  }

  getSeverityLabel(selectedFigure, labels, sevLevel) {
    const sevLabel = labels.find((label) => {
      const splitLabelName = label?.name.split("_");
      const splitSelectedFigureName = selectedFigure.label?.name.split("_");
      if (splitLabelName.length < 1 || splitSelectedFigureName < 1) {
        return;
      }
      const labelDistressCode = splitLabelName[0];
      const labelSevCode = splitLabelName[splitLabelName.length - 1];
      const selectedFigureDistressCode = splitSelectedFigureName[0];
      const expectedSevCodeObj = {
        "1":["1","L","B"],
        "2":["2","M"],
        "3":["3","H","A"]
      };
      if ((labelDistressCode === selectedFigureDistressCode) && (expectedSevCodeObj[sevLevel].indexOf(labelSevCode) >=0) && (label?.type === selectedFigure.label?.type)) {
        return true;
      } else {
        return false;
      }
    });
    return sevLabel;
  }

  getSeverityLabels(selectedFigures, labels, sevLevel) {
    return selectedFigures.map((selectedFigure) => {
      return this.getSeverityLabel(selectedFigure, labels, sevLevel);
    })
  }

  handleReplaceLabel(selectedLabels, directReplace) {
    const newFigures = this.getSelectedFigures();
    newFigures.forEach((figure, index) => {
      if (directReplace) {
        const foundLabel = selectedLabels[index];
        if (foundLabel && figure.label?.name !== foundLabel.name) {
          figure.label = foundLabel;
        }
      } else {
        const labelFound = selectedLabels.find(l => l.type === figure.type);
        if (labelFound) figure.label = labelFound;
      }
    })
    this.props.onChange('recolor', newFigures);
  }

  render() {
    const {
      url,
      bounds,
      height,
      width,
      figures,
      unfinishedFigure,
      onChange,
      onReassignment,
      style,
      onNewComment,
      onToggleResolve,
      lcms3dImageUrl,
      lcms3dOverlayImgUrl,
      lcmsIntImageUrl,
      lcmsRightOfWayImageUrl,
      pavementType,
      editorOptions,
      projectCustomShortcuts,
      setFigureToolbarOpen
    } = this.props;

    const { zoom, selectedFigures, selectedFigureIds, cursorPos } = this.state;

    const drawing = !!unfinishedFigure;

    const calcDistance = (p1, p2) => {
      const map = this.mapRef.current.leafletElement;
      return map.latLngToLayerPoint(p1).distanceTo(map.latLngToLayerPoint(p2));
    };
    const enabledEditorOptions = Object.keys(this.state.editorOptions).filter((key)=>{
      return this.state.editorOptions[key] === true;
    });

    const additionalImages = enabledEditorOptions.map((opt,idx)=>{
      let url = lcmsIntImageUrl;
      switch(opt){
        case "lcmsIntDisplay":
          url = lcmsIntImageUrl;
          break;
        case "lcms3dDisplay":
          url = lcms3dImageUrl;
          break;
        case "lcmsRightOfWayDisplay":
          url = lcmsRightOfWayImageUrl;
          break;
        case "lcms3dOverlayDisplay":
          url = lcms3dOverlayImgUrl;
          break;
      }


      return(
        <ImageOverlay key={idx} url={url} bounds={calcBounds(height,width,true,(idx+2+(idx/10 + 0.1)))}/>
        );
    });

    const formattedComments = this.props.commentThread.map((thread, idx)=>{
      const commentThread = thread.commentsArr || [];
            return (
              <DraggableMarker key={thread._id} isResolved={thread.isResolved} startingPosition={thread.position}>
                <Popup key={thread._id} onClose={() => {
                  this.handlePopupClose(thread._id, commentThread.length)
                }}
                  onOpen={()=>{
                    console.log("opened thread.id", thread._id);
                  }}
                >
                  <Comments key={thread._id} isResolved={thread.isResolved} onToggleResolve={onToggleResolve} onNewComment={onNewComment} threadId={thread._id} comments={commentThread}/>
                </Popup>
              </DraggableMarker>
            );  
          });

    const unfinishedDrawingDOM = drawing
      ? this.renderFigure(unfinishedFigure, {
          finished: false,
          editing: false,
          interactive: true,
          color: colorMapping[unfinishedFigure.color],
          onChange: this.handleChange,
          calcDistance,
          newPoint: cursorPos,
          visible:true
        })
      : null;

    const getColor = f =>
      f.tracingOptions && f.tracingOptions.enabled
        ? lighten(colorMapping[f.color], 80)
        : colorMapping[f.color];
    const figuresDOM = figures.map((f, i) =>
      this.renderFigure(f, {
        visible:f.isVisible,
        editing: selectedFigureIds.includes(f.id) && !drawing,
        finished: true,
        interactive: !drawing,
        sketch: f.tracingOptions && f.tracingOptions.enabled,
        color: getColor(f),
        vertexColor: colorMapping[f.color],
        onSelect: () => {
          if (this.state.isShiftDown) {
            const newFigureIds = new Set([...selectedFigureIds, f.id]);
            this.setState({ selectedFigureIds: [...newFigureIds] });
          } else {
            this.setState({ selectedFigureIds: [f.id] });
          }
          setFigureToolbarOpen(true,f);
        },
        onChange: this.handleChange,
        calcDistance,
      })
    );

    let customProjectShortcuts = [];
    if(projectCustomShortcuts){
      customProjectShortcuts=projectCustomShortcuts.map((shortcut)=>{
        if(shortcut){
          return shortcut?.shortcut;
        }else{
          return ""
        }
      });
    }
    const flattenedShortcuts = customProjectShortcuts.join(",");

    const hotkeysDOM = (
      <Hotkeys
        // filter={(e)=>{
        //   e.preventDefault();
        //   const allowedKeys = ["backspace", "escape", "del","c","f","-","=","left","right","up","down","space"];
        //   console.log(e);
        //   console.log("nodename",e.target.nodeName);
        //   if(e.target.nodeName === "INPUT"){
        //     console.log("skipping input");
        //     return false;
        //   }else{
        //     return true;
        //   }
        // }}
        allowRepeat={true}
        keyName={`esc,a,s,d,w,backspace,del,c,f,-,=,left,right,up,down,space,r,h,1,2,3${customProjectShortcuts.length > 0? `,${flattenedShortcuts.toLowerCase()}`:""}`}
        onKeyDown={(key) => this.setState({pressedKey: key})}
        onKeyUp ={
          (key, e, handle) => {
            if(key === "space"){ 
              this.setState({
                draggableView:false 
              });
            }
          }
        }
      />
    );

    let renderedTrace = null;

    if (selectedFigures && selectedFigures.type === 'polygon') {
      const trace = selectedFigures.tracingOptions
        ? selectedFigures.tracingOptions.trace || []
        : [];
      const figure = {
        id: 'trace',
        type: 'line',
        points: trace,
      };
      const traceOptions = {
        editing: false,
        finished: true,
        color: colorMapping[selectedFigures.color],
      };
      renderedTrace = <PolygonFigure figure={figure} options={traceOptions} />;
    }
    if (selectedFigures && selectedFigures.type === 'polyline') {
      const trace = selectedFigures.tracingOptions
        ? selectedFigures.tracingOptions.trace || []
        : [];
      const figure = {
        id: 'trace',
        type: 'line',
        points: trace,
      };
      const traceOptions = {
        editing: false,
        finished: true,
        color: colorMapping[selectedFigures.color],
      };
      renderedTrace = <UnclosedPolyline isUserDrawing={drawing} figure={figure} options={traceOptions} />;
    }

    let cursorIcon = 'auto';
    if(drawing){
      cursorIcon = 'crosshair';
    }else if(this.state.draggableView){
      cursorIcon = 'grab';
    }else if(this.state.commenting){
      cursorIcon = 'crosshair'
    }

    const handleStartErase = () => {
      this.props.handleSelected("erase")
    }

    const handleStartExpand = () => {
      this.props.handleSelected("expand")
    }

    return (
      <ErrorBoundary>
        <div
        style={{
          cursor: cursorIcon,
          height: '100%',
          ...style,
        }}

        id="canvasMap"
      >
        <ReplaceLabelModal
          modalEnabled={this.state.replaceLabelModalOn}
          onClose={()=> this.toggleReplaceModal(false)}
          selectedFigures={selectedFigures}
          labels={this.props.labels}
          handleClick={(selectedLabels) => this.handleReplaceLabel(selectedLabels)}
        />
        <OptionsModal
          modalEnabled={this.state.optionsModalOn}
          intImgEnabled={this.state.editorOptions.lcmsIntDisplay}
          threeDImgEnabled={this.state.editorOptions.lcms3dDisplay}
          threeDImgOverlayEnabled={this.state.editorOptions.lcms3dOverlayDisplay}
          rowImgEnabled={this.state.editorOptions.lcmsRightOfWayDisplay}
          onClose={() => this.toggleOptionsModal(false)}
          labels={this.props.labels}
          handleClick={this.props.onEditorOptionsChange}
        />
        <Map
          crs={CRS.Simple}
          zoom={zoom}
          minZoom={-50}
          maxZoom={maxZoom}
          center={[height / 2, width / 2]}
          zoomAnimation={false}
          zoomSnap={0.1}
          zoomControl={false}
          keyboard={false}
          attributionControl={false}
          onClick={this.handleClick}
          onContextMenu={this.handleClick}
          onZoom={e => this.setState({ zoom: e.target.getZoom() })}
          onMouseMove={this.handleMouseMove}
          onMouseDown = {e => this.setState({mouseDownFlag: true})}
          onMouseUp = {e => this.setState({mouseDownFlag: false})}
          dragging={this.state.draggableView}
          ref={this.mapRef}
        >
          {this.state.areCommentsVisible? formattedComments : null}
          <ZoomControl position="bottomright" />
          <Control className="leaflet-bar" position="bottomright">
            {/*<a
                          role="button"
                          title="comment"
                          onClick={() => {
                            this.setState({
                              areCommentsVisible:!this.state.areCommentsVisible
                            })
                          }}
                        >
                          <Icon.Group>
                            <Icon name={this.state.areCommentsVisible ? "eye":"eye slash"} fitted style={{ fontSize: '1.2em' }} />
                            <Icon name={this.state.areCommentsVisible ? "comment outline":"comment outline"} fitted style={{ fontSize: '1.2em' }} />
                          </Icon.Group>
                        </a>
                        <a
                          role="button"
                          title="comment"
                          onClick={() => {
                            this.setState({
                              commenting:!this.state.commenting,
                              areCommentsVisible:this.state.commenting ? false:true
                            })
                          }}
                        >
                          <Icon name={this.state.commenting ? "comment":"comment outline"} fitted style={{ fontSize: '1.2em' }} />
                        </a>*/}
            <a
              role="button"
              title="Zoom reset"
              onClick={() => {
                const map = this.mapRef.current.leafletElement;
                console.log(map);
                map.setView(map.options.center, map.options.zoom);
              }}
            >
              <Icon name="redo" fitted style={{ fontSize: '1.2em' }} />
            </a>
            <a
              role="button"
              title="Hand"
              onClick={() => {
                this.setState({
                  draggableView:!this.state.draggableView
                })
              }}
            >
              <Icon name={this.state.draggableView? "stop":"hand pointer outline"} fitted style={{ fontSize: '1.2em' }} />
            </a>
          </Control>
          <Control className="leaflet-bar" position="bottomright">
            <a
              role="button"
              title="Erase"
              onClick={handleStartErase}
            >
              <Icon name="eraser" fitted style={{ fontSize: '1.2em' }} />
            </a>
            <a
              role="button"
              title="Expand"
              onClick={handleStartExpand}
            >
              <Icon name="external square" fitted style={{ fontSize: '1.2em' }} />
            </a>
          </Control>
          {pavementType === "lcms" && (<Control className="leaflet-bar" position="topright">
            <a
              role="button"
              title="Options"
              onClick={()=>this.toggleOptionsModal(true)}
            >
              <Icon name="setting" fitted style={{ fontSize: '1.2em' }} />
            </a>
          </Control>)}
          {<ImageOverlay url={url} bounds={bounds}/>}
          {additionalImages}
          {unfinishedDrawingDOM}
          {renderedTrace}
          {figuresDOM}
          {hotkeysDOM}
        </Map>
      </div>
      </ErrorBoundary>
    );
  }
}


function isWithinBounds(bounds,currentPosition) {

 //bounds is expected to be in the form:
 // {
    // _northEast:{ lat: xxx , lng: xxx}
  //}
  // currentposition is expected as {lat:xxx, lng:xxx}

  if(((bounds._southWest.lat < currentPosition.lat) && (currentPosition.lat  < bounds._northEast.lat) ) && ((bounds._southWest.lng < currentPosition.lng) && (currentPosition.lng< bounds._northEast.lng))){
    return true;
  }else{
    return false;
  }

}

function calcBounds(height,width,isSideImage,multiplier = 2) {
      const crs = CRS.Simple;

      if (!height || !width) return null;
      let southWestMultiplier = 1;
      let northEastMultiplier = multiplier;

      if(multiplier > 2){
        southWestMultiplier = multiplier-1;
      }
 
      const southWest = crs.unproject({ x: isSideImage ?width*southWestMultiplier :  0 , y: height }, maxZoom - 1);
      const northEast = crs.unproject({ x: isSideImage ? width*northEastMultiplier:width, y: 0 }, maxZoom - 1);
      const bounds = new LatLngBounds(southWest, northEast);

      return bounds;
    }

export default withBounds(Canvas);
