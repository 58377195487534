import React, { createContext, useContext } from 'react';

const TrafficSettingsContext = createContext({});

export const TrafficSettingsProvider = ({children,value={}}) => {
    return (
        <TrafficSettingsContext.Provider value={value}>
            {children}
        </TrafficSettingsContext.Provider>
    );
}

export const useTrafficSettingsContext = () => useContext(TrafficSettingsContext);