import React, {useState,useCallback} from 'react';
import Dropzone from 'react-dropzone';

const FileUploadAndReader = ({label, maxFiles=1, callback,isJSON})=>{
	const [parsedData, setParsedData] = useState({});

	const handleDrop = useCallback((acceptedFiles) => {
		acceptedFiles.forEach((file) => {
		  const reader = new FileReader()
	
		  reader.onabort = () => console.log('file reading was aborted')
		  reader.onerror = () => console.log('file reading has failed')
		  reader.onload = () => {
		  // Do whatever you want with the file contents
			const parsedData = JSON.parse(reader.result);
			  console.log("parsedData",parsedData);
	          setParsedData(parsedData);	
	          if(callback){
	          	callback(parsedData);
	          }
		  }
		  reader.readAsText(file)
		})
		
	  }, [callback]);
// 	const handleDrop = (acceptedFiles) =>{
// 		console.log("handleDrop",acceptedFiles);
// 		acceptedFiles = [acceptedFiles[0]];
// 		console.log("acceptedFiles",acceptedFiles);
// 	     const reader = new FileReader();
// 		 console.log("reader",reader);
// 	     let filesArray = [];
// 	     reader.onabort = () => console.log("file reading was aborted");
// 		reader.onerror = () => console.log("file reading failed");
// 		reader.onloadstart = () => console.log("file reading started");
// 		reader.onloadend = () => console.log("file reading ended");
// 	      reader.onload = () => {
// 			console.log("loading file");
// 	          const parsedData = JSON.parse(reader.result);
// 			  console.log("parsedData",parsedData);
// 	          setParsedData(parsedData);	
// 	          if(callback){
// 	          	callback(parsedData);
// 	          }
//       };

//       // read file contents
//       acceptedFiles.forEach(file => {
// 		console.log("parsedData from accepted files foreach",parsedData);
//         filesArray.push(parsedData);
//       });
//   }
	return (
		<div style={{width:"100%"}}>
			<Dropzone onDrop={acceptedFiles => handleDrop(acceptedFiles)}>
                      {({getRootProps, getInputProps}) => (
                        <section>
                          <div className="dropzone" {...getRootProps()}>
                            <input {...getInputProps()} />
                            <p>{label}</p>
                          </div>
                        </section>
                      )}
                </Dropzone>
		</div>
		);
};

export default FileUploadAndReader;