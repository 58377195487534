import React, { useState } from 'react';
import { Button, Modal, Message } from 'semantic-ui-react';
import { apiCall } from '../../services/api';
import { logout } from "../../store/actions/auth";
import { connect } from "react-redux";
import { useHistory } from 'react-router-dom';

const ModalDeleteAccount = ({ currentUser, logout }) => {
    const { id } = currentUser.user;
    const [open, setOpen] = useState(false);
    const [responseMessage, setResponseMessage] = useState('');
    const [isSuccess, setIsSuccess] = useState(null);
    const history = useHistory();

    const handleDelete = async () => {
        const userId = id;
        try {
            const response = await apiCall('delete', `${process.env.REACT_APP_PRODUCTION_SERVER_URL}/api/users/${userId}`);

            if (response && response.message === 'Successful deletion') {
                setResponseMessage('Account deleted successfully!');
                setIsSuccess(true);
                // Close the modal
                setOpen(false);
                // Logout the user
                logout();
                // Redirect after a short delay
                setTimeout(() => {
                    history.push('/');
                }, 5000); // 5 seconds delay
            } else {
                setResponseMessage(response.message || 'Unknown error occurred.');
                setIsSuccess(false);
            }
        } catch (error) {
            console.error('Error in DELETE request:', error);
            setResponseMessage('Error deleting account. Please try again.');
            setIsSuccess(false);
        }
    };

    return (
        <div>
            <Button negative onClick={() => setOpen(true)}>
                Delete account
            </Button>

            <Modal
                open={open}
                onClose={() => setOpen(false)}
                size='small'
                style={{ width: '520px' }}
                closeOnDimmerClick={false}
                closeOnEscape={false}
            >
                <Modal.Header>Confirm Delete</Modal.Header>
                <Modal.Content>
                    <p>Are you sure you want to delete your account? This action cannot be undone.</p>
                    {isSuccess === false && (
                        <Message negative>
                            <Message.Header>Error</Message.Header>
                            <p>{responseMessage}</p>
                        </Message>
                    )}

                    {isSuccess === true && (
                        <Message positive>
                            <Message.Header>Success</Message.Header>
                            <p>{responseMessage}</p>
                        </Message>
                    )}
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={() => setOpen(false)}>Cancel</Button>
                    <Button negative onClick={handleDelete}>
                        Delete
                    </Button>
                </Modal.Actions>
            </Modal>
        </div>
    );
};

const mapStateToProps = (state) => ({
    currentUser: state.currentUser,
});

export default connect(mapStateToProps, { logout })(ModalDeleteAccount);
