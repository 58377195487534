import React, {Fragment} from 'react';
import {Grid, Container,Segment} from 'semantic-ui-react';
import DataTable from "../containers/DataTable";
import GradientHeader from '../containers/GradientHeader';

const AdminDash = (props)=>{
	const imgUrl = "images/background-image-hero.jpg";
	let backgroundImg = `linear-gradient(45deg, rgb(108, 16, 204) 1%, rgb(126, 81, 177) 30%, rgb(162, 98, 153) 69%, rgba(220, 40, 55, 0.7) 100%),url(${imgUrl})`;
	let userFields = [
	  "email",
	  "f_name",
	  "l_name",
	  "_id",
	  "profileImageUrl",
	  "role",
	  "companyIds",
	  "last_login",
	  "Edit",
	  "Delete"];

	let companyFields = [
	  "name",
	  "logoUrl",
	  "isActive",
	  "_id",
	  "Edit",
	  "Delete"];

	if(!props.currentUser || props.currentUser.role !== "admin"){
		return(
			<div style={{"background":"whitesmoke","height":"100vh"}}>
				{(process.env.REACT_APP_USE_GLOBAL_NAV === 'false' || process.env.REACT_APP_USE_GLOBAL_NAV === '' || typeof process.env.REACT_APP_USE_GLOBAL_NAV === 'undefined') && (
					<GradientHeader/>
				)}
				<h1>Unauthorized</h1>
			</div>
			)
	}

	return(
		<div style={{"background":"whitesmoke","minHeight":"100vh"}}>
			{(process.env.REACT_APP_USE_GLOBAL_NAV === 'false' || process.env.REACT_APP_USE_GLOBAL_NAV === '' || typeof process.env.REACT_APP_USE_GLOBAL_NAV === 'undefined') && (
				<GradientHeader/>
			)}
			<Grid style={{"paddingTop":"50px"}}>
				<Container>
					<Grid.Row className="row">
					      <h1 className="ui huge header">
					                Hi Mr. Admin!
					      </h1>
					    </Grid.Row>
					    {/*<a href="/#users">Users</a> | <a href="/#companies">Companies</a>*/}

					    <Grid.Row className="row">
					      <h1 className="ui medium header" id="users">
					                All Users
					      </h1>
					    </Grid.Row>
					    <Grid.Row className="row">
					       <DataTable 
					        key={0}
					        requestPath={`${process.env.REACT_APP_PRODUCTION_SERVER_URL}/api/admin/${props.currentUser.id}/users`}
					        tableLabels={userFields} 
					        postRequestPath = {`${process.env.REACT_APP_PRODUCTION_SERVER_URL}/api/admin/${props.currentUser.id}/users`}
					        putRequestPath = {`${process.env.REACT_APP_PRODUCTION_SERVER_URL}/api/admin/:id/users/:userid`}
					        deleteRequestPath = {`${process.env.REACT_APP_PRODUCTION_SERVER_URL}/api/admin/:id/users/:userid`}
					        passwordField={true}
					        createButtonText= "Add User"
					        {...props}></DataTable>
					    </Grid.Row>
					    <Grid.Row className="row">
					      <h1 className="ui medium header" id="companies">
					                All Companies
					      </h1>
					    </Grid.Row>
					    <Grid.Row className="row">
					       <DataTable 
					        key={1}
					        requestPath={`${process.env.REACT_APP_PRODUCTION_SERVER_URL}/api/admin/${props.currentUser.id}/companies`}
					        tableLabels={companyFields} 
					        postRequestPath = {`${process.env.REACT_APP_PRODUCTION_SERVER_URL}/api/admin/${props.currentUser.id}/companies`}
					        putRequestPath = {`${process.env.REACT_APP_PRODUCTION_SERVER_URL}/api/admin/:id/companies/:companyId`}
					        deleteRequestPath = {`${process.env.REACT_APP_PRODUCTION_SERVER_URL}/api/admin/:id/companies/:companyId`}
					        createButtonText= "Add Company"
					        {...props}></DataTable>
					    </Grid.Row>
				</Container>
			</Grid>
		</div>
		);	
}; 

export default AdminDash;