import React, { useState } from "react";
import { Container, List } from "semantic-ui-react";
import Dropzone from "react-dropzone";

const FileTree = () => {
  const [files, setFiles] = useState([]);

  const onDrop = (acceptedFiles) => {
    setFiles(acceptedFiles.map((file, index) => ({
      id: Date.now() + index,
      name: file.name,
      type: "file"
    })));
  };

  return (
    <Container>
      <Dropzone onDrop={onDrop}>
        {({ getRootProps, getInputProps }) => (
          <section>
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <p>Drag and drop files here, or click to select files</p>
            </div>
          </section>
        )}
      </Dropzone>
      <List>
        {files.map(file => (
          <List.Item key={file.id}>
            <List.Icon name="file" />
            <List.Content>
              <List.Header as="h4">{file.name}</List.Header>
            </List.Content>
          </List.Item>
        ))}
      </List>
    </Container>
  );
};

export default FileTree;
