import React, { Component } from 'react';
import _ from "lodash";
import ErrorBoundary from '../containers/ErrorBoundary';
import {apiCall} from "../services/api";

export function withLoadImageData(Comp) {
  return class LoadImageLayer extends Component {
    constructor(props) {
      super(props);
      this.state = {
        height: 0,
        width: 0,
        imageFound: true,
      };

      this.componentDidUpdate({}, this.state);
    }

    componentDidMount(){
      const {video} = this.props;
      if(video){
        this.setState({
          width:500,
          height:500
        });
      }
    }

    componentDidUpdate(prevProps, prevState) {
      const { demo, externalImageUrl, image, video} = this.props;
      if(image){
        let imageUrl = this.props.imageUrl? this.props.imageUrl : "https://res.cloudinary.com/dqwgbheqj/image/upload/v1633492043/no_image_found-01_ehvdre.png";
        let imageMetadata = image?.imageMetadata;
        if (imageUrl !== prevProps.imageUrl) {
          const img = new Image();
          // console.log("img",img);

          const setState = this.setState.bind(this);
          img.onload = async function() {
            const { height, width } = this;
            if(setState){
              setState({ height, width });
            }

            const resetImage = () => {
              // console.log("resetImage 1");
              const canvas = document.getElementById('test-canvas');
              const ctx = canvas.getContext('2d');
              const scale = demo ? 0.5 : Math.min(800, height) / height;
              const sHeight = Math.floor(scale * height);
              const sWidth = Math.floor(scale * width);
              canvas.height = sHeight;
              canvas.width = sWidth;
              ctx.imageSmoothingQuality = 'high';
              ctx.imageSmoothingEnabled = true;
              ctx.drawImage(img, 0, 0, sWidth, sHeight);
              const imgB64 = canvas
                .toDataURL()
                .substring('data:image/png;base64,'.length);

              canvas.height = height;
              canvas.width = width;
              // console.log("width: ", width);
              // console.log("height: ", height);
              // console.log("Scale: ", scale);

              ctx.drawImage(img, 0, 0, width, height);
              const data = ctx.getImageData(0, 0, width, height).data;

              setState({ imageData: data, imgB64, b64Scaling: scale });
            };

            if (document.readyState !== 'loading') {
              resetImage();
            } else {
              document.addEventListener('DOMContentLoaded', resetImage);
            }
          };
          img.onerror = async (err)=>{
            // console.log("error loading image", err);
            let { height, width } = this;
            setState({ height, width });

            const resetImage = () => {
              // console.log("resetImage 2");
              const canvas = document.getElementById('test-canvas');
              const ctx = canvas.getContext('2d');
              const scale = demo ? 0.5 : Math.min(800, height) / height;
              const sHeight = Math.floor(scale * height);
              const sWidth = Math.floor(scale * width);
              canvas.height = sHeight;
              canvas.width = sWidth;
              ctx.imageSmoothingQuality = 'high';
              ctx.imageSmoothingEnabled = true;
              ctx.drawImage(img, 0, 0, sWidth, sHeight);
              const imgB64 = canvas
                .toDataURL()
                .substring('data:image/png;base64,'.length);
              canvas.height = height;
              canvas.width = width;
              
              // console.log("width: ", width);
              // console.log("height: ", height);
              // console.log("Scale: ", scale);

              ctx.drawImage(img, 0, 0, width, height);
              const data = ctx.getImageData(0, 0, width, height).data;

              setState({ imageData: data, imgB64, b64Scaling: scale });
            };

            if (document.readyState !== 'loading') {
                if(externalImageUrl && !imageMetadata && (externalImageUrl.indexOf("https://drive.google.com/uc?export=view&id=")>=0)){
                try{
                  const fileId = externalImageUrl.replace("https://drive.google.com/uc?export=view&id=","");
                  const fileMetadata = await apiCall("GET", `${process.env.REACT_APP_PRODUCTION_SERVER_URL}/api/images/drive/getFileMetadata?fileId=${fileId}`)
                  if("imageMediaMetadata" in fileMetadata){
                    setState({
                      "width":fileMetadata.imageMediaMetadata.width,
                      "height":fileMetadata.imageMediaMetadata.height,
                      "imageFound":true
                    })
                  }
                  // window.location.reload();
                }catch(err){
                  console.log(err);
                  setState({
                    width:500,
                    height:500,
                    imageFound:false
                  });
                }
              }else if(externalImageUrl && imageMetadata){
                if("imageMediaMetadata" in imageMetadata){
                    setState({
                      "width":imageMetadata.imageMediaMetadata.width,
                      "height":imageMetadata.imageMediaMetadata.height
                    })
                  }
              }else if(externalImageUrl){
                imageUrl = externalImageUrl;
              }
            } else {
              document.addEventListener('DOMContentLoaded', resetImage);
            }
          };
          img.crossOrigin = 'Anonymous';
          img.alt = "Image did not load";
          // console.log("imageUrl", imageUrl);
          img.src = imageUrl;
          
        }
      }
    }

    render() {
      const { props, state } = this;
      const {image,video} = props;
      const { height, width, imageData, imgB64, b64Scaling, imageFound } = state;

      if (!height) return null;
      if(image){
        return (
          <ErrorBoundary>
              <Comp
              height={height}
              width={width}
              imageData={imageData}
              imgB64={imgB64}
              b64Scaling={b64Scaling}
              imageFound={imageFound}
              {...props}
            />
          </ErrorBoundary>
        );
      }else if(video){
        return (
          <ErrorBoundary>
              <Comp
              height={height}
              width={width}
              imageFound={imageFound}
              imageData={null}
              imgB64={null}
              b64Scaling={null}
              {...props}
            />
          </ErrorBoundary>
        );
      }
    }
  };
}


