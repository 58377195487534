import React, { Component } from "react";
import { connect } from "react-redux";
import { setUserLanguage, updateCurrentUser } from "../store/actions/users";

export default function withAuth(ComponentToBeRendered) {
  class Authenticate extends Component {
    componentWillMount() {
      // console.log("componentWillMount", this.props);
      if (this.props.isAuthenticated === false && this.props.location.pathname !== "/signin") {
        this.props.history.push("/signin");
      }
    }
    componentWillUpdate(nextProps) {
      // console.log("componentWillUpdate", nextProps);
      if (nextProps.isAuthenticated === false && this.props.location.pathname !== "/signin") {
        this.props.history.push("/signin");
      }
    }
    render() {
      return (
          <ComponentToBeRendered {...this.props} />
        );
    }
  }

  function mapStateToProps(state) {
    return { 
    isAuthenticated: state.currentUser.isAuthenticated,
    currentUser: state.currentUser.user,
    language: state.language 
  };
  }

  return connect(mapStateToProps,{setUserLanguage, updateCurrentUser})(Authenticate);
}
