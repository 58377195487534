import React, { Component } from 'react';

import {
  Header,
  Button,
  Loader,
  Input,
  List,
  Segment,
  Divider
} from 'semantic-ui-react';

import DocumentMeta from 'react-document-meta';

import { sortableContainer, sortableElement } from 'react-sortable-hoc';

import update from 'immutability-helper';
import arrayMove from 'array-move';

import AssignedLabeler from '../components/AssignedLabeler';
import AssignedCompany from '../components/AssignedCompany';
import ProjectStatusUpdater from '../components/project-details-components/ProjectStatusUpdater';
import DeleteProjectModal from '../components/project-details-components/DeleteProjectModal';


import ProjectImages from './ProjectImages';
import ProjectVideos from './ProjectVideos';
import UploadImages from './UploadImages';
import LabelFormItem from './LabelFormItem';
import ImportData from './ImportData';
import UploadReference from './UploadReference';
import MLAssist from './MLAssist';

import { apiCall } from "../services/api";

import { saveAs } from 'file-saver'
import * as JSZip from 'jszip'
import * as JSZipUtils from 'jszip-utils'

import { HashLink } from 'react-router-hash-link';

import { orderedLabels } from "../services/defaultLabels";
import LoaderButton from '../components/LoaderButton';

import DropdownSelector from "../components/DropdownSelector";
import DynamicTextInput from "../components/DynamicTextInput";
import CreateBucket from "../components/CreateBucket";
import ProjectConfiguration from "../components/ProjectConfiguration";
import ProjectLabelMetadata from '../components/ProjectLabelMetadata';
import GlobalSideBar from '../components/global-navbar/GlobalSideBar';




export default class ProjectPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      project: null,
      deleteModalOpen: false,
      loading: false
    };

    this.onSortEnd = this.onSortEnd.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleNew = this.handleNew.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleReferenceChange = this.handleReferenceChange.bind(this);
    this.handleProjectExport = this.handleProjectExport.bind(this);
    this.handleEmailProjectJSON = this.handleEmailProjectJSON.bind(this);
    this.handleMassLabelUpload = this.handleMassLabelUpload.bind(this);
    this.handleLabelerAssignment = this.handleLabelerAssignment.bind(this);
    this.onToggleModal = this.onToggleModal.bind(this);
    this.handleProjectStatusUpdate = this.handleProjectStatusUpdate.bind(this);
    this.handleDistanceMeasuringUnitChange = this.handleDistanceMeasuringUnitChange.bind(this);
    this.handleCompanyAssignment = this.handleCompanyAssignment.bind(this);
    this.handleDataTypeAssignment = this.handleDataTypeAssignment.bind(this);
    this.handleProjectAcceptedDataFormat = this.handleProjectAcceptedDataFormat.bind(this);
    this.handleVideoProjectExport = this.handleVideoProjectExport.bind(this);
  }

  async componentDidMount() {
    const projectId = this.props.location.pathname.replace("/admin/", "").replace("login/", "");
    // console.log("componentDidMount projectId", projectId);
    // console.log("componentDidMount location.pathname", this.props.location.pathname);
    try {
      const project = await apiCall("GET", `${process.env.REACT_APP_PRODUCTION_SERVER_URL}/api/projects/${projectId}`);
      // console.log("componentDidMount project", project);
      if (project && "form" in project && project.form) {
        project.form = JSON.parse(project.form);
      }
      // const project = await (await fetch(`${process.env.REACT_APP_PRODUCTION_SERVER_URL}/api/projects/${projectId}`)).json();
      this.setState({
        isLoaded: true,
        project,
      });
    } catch (error) {
      this.setState({
        isLoaded: true,
        error,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {

    // console.log("componentDidUpdate");
    // console.log("prevProps",prevProps);
    const { project } = this.state;
    if (!project) return;
    const projectId = this.props.location.pathname.replace("/admin/", "");

    // console.log("prevState",prevState);    
    // console.log("project",project);    
    if (prevState.project && (prevState.project !== project)) {
      apiCall("PATCH", `${process.env.REACT_APP_PRODUCTION_SERVER_URL}/api/projects/${projectId}`, { project: { ...project, form: JSON.stringify(project.form) } });
    }
  }

  onSortEnd({ oldIndex, newIndex }) {
    this.setState(({ project }) => ({
      project: update(project, {
        form: {
          formParts: {
            $set: arrayMove(project.form.formParts, oldIndex, newIndex),
          },
        },
      }),
    }));
  }

  onToggleModal(openStatus) {
    this.setState({
      deleteModalOpen: openStatus
    })
  }
  async handleProjectExport(includeUnlabeled = false) {
    let params = {};
    const projectId = this.state.project._id;
    if (includeUnlabeled) {
      params.includeUnlabeled = true;
    } else {
      params.includeUnlabeled = false;
    }
    const projectJSON = await apiCall("GET", `${process.env.REACT_APP_PRODUCTION_SERVER_URL}/api/projects/${projectId}/export`, { params });
    const foundProject = this.state.project;
    var zip = new JSZip();
    var count = 0;
    var zipFilename = `engin-ai-labeling-project-${projectId}`;

    let finalJSON = {};
    let assignedLabelerName = "";
    let createdByName = "";
    if ("assignedLabeler" in foundProject && foundProject.assignedLabeler) {
      assignedLabelerName = `${foundProject.assignedLabeler.f_name} ${foundProject.assignedLabeler.l_name}`;
    }
    if ("createdBy" in foundProject && foundProject.createdBy) {
      createdByName = `${foundProject.createdBy.f_name} ${foundProject.createdBy.l_name}`;
    }
    finalJSON['images'] = [];

    projectJSON.forEach((imgLabels) => {
      let filename = imgLabels.name;
      let fileContent = JSON.parse(imgLabels.contents);
      let fileId = fileContent.imageId || Math.random();
      let finalObj = {};
      finalJSON['images'].push(fileContent);
    });

    finalJSON['assignedLabeler'] = assignedLabelerName;
    finalJSON['createdBy'] = createdByName;
    finalJSON['projectName'] = foundProject.name;
    finalJSON['projectId'] = foundProject._id;

    const json = JSON.stringify(finalJSON);
    const blob = new Blob([json], { type: 'application/json' });
    saveAs(blob, zipFilename);
    // const fileName = `engin-ai-labeling-project-${projectId}`;
    // const json = JSON.stringify(projectJSON);
    // const blob = new Blob([json],{type:'application/json'});
    // const href = await URL.createObjectURL(blob);
    // const link = document.createElement('a');
    // link.href = href;
    // link.download = fileName + ".json";
    // document.body.appendChild(link);
    // link.click();
    // document.body.removeChild(link);

  }
  async handleVideoProjectExport(includeUnlabeled = false) {
    let params = {};
    const projectId = this.state.project._id;
    if (includeUnlabeled) {
      params.includeUnlabeled = true;
    } else {
      params.includeUnlabeled = false;
    }
    const projectJSON = await apiCall("GET", `${process.env.REACT_APP_PRODUCTION_SERVER_URL}/api/projects/${projectId}/export/videos`, { params });
    var zip = new JSZip();
    var count = 0;
    var zipFilename = `engin-ai-labeling-video-project-${projectId}`;
    const json = JSON.stringify(projectJSON);
    const blob = new Blob([json], { type: 'application/json' });
    saveAs(blob, zipFilename);
  }

  async handleEmailProjectJSON(includeUnlabeled = false) {
    let params = {};
    const projectId = this.state.project._id;
    const projectJSON = await apiCall("POST", `${process.env.REACT_APP_PRODUCTION_SERVER_URL}/api/jobs/projects/${projectId}/reports/`, { includeUnlabeled, format: "json", dataType: "detections" });
  }

  handleChange(oldValue, newValue) {
    const { project } = this.state;
    const edit = newValue ? [1, newValue] : [1];
    this.setState({
      project: update(project, {
        form: {
          formParts: {
            $splice: [
              [
                project.form.formParts.findIndex(x => x.id === oldValue.id),
                ...edit,
              ],
            ],
          },
        },
      }),
    });
  }

  handleLabelerAssignment(labelerObj) {
    const { project } = this.state;
    if (labelerObj === "") {
      console.log("No labeler was assigned");
      return;
    } else {
      this.setState({
        project: update(project, {
          assignedLabeler: {
            $set: labelerObj
          }
        })
      })
    }
  }

  handleCompanyAssignment(companyId) {
    const { project } = this.state;
    if (companyId === "") {
      console.log("A company ID is needed");
      return;
    } else {
      this.setState({
        project: update(project, {
          companyId: {
            $set: companyId
          }
        })
      })
    }
  }

  // This is for pavement image types specifically. Not to be confused with broad Project accepted data formats such as VIDEO or IMAGE
  handleDataTypeAssignment(dataType) {
    const { project } = this.state;
    this.setState({
      project: update(project, {
        pavementDataType: {
          $set: dataType
        }
      })
    });
  }

  handleProjectAcceptedDataFormat(dataFormat = 'image') {
    const { project } = this.state;
    this.setState({
      project: update(project, {
        projectLabelingDataFormat: {
          $set: dataFormat
        }
      })
    });
  }

  handleDistanceMeasuringUnitChange(distanceMeasuringUnit = 'ft') {
    const { project } = this.state;
    this.setState({
      project: update(project, {
        distanceMeasuringUnit: {
          $set: distanceMeasuringUnit
        }
      })
    });
  }

  handleProjectStatusUpdate(status) {
    const { project } = this.state;
    if (status === "" || !status) {
      console.log("No status provided");
      return;
    } else {
      this.setState({
        project: update(project, {
          status: {
            $set: status
          }
        })
      })
    }
  }

  handleNew() {
    const { project } = this.state;
    this.setState({
      project: update(project, {
        form: {
          formParts: {
            $push: [newFormPart()],
          },
        },
      }),
    });
  }

  handleMassLabelUpload(customLabelsArray) {
    const { project } = this.state;
    let formPartsArr = [];
    if (project && "form" in project && "formParts" in project.form) {
      formPartsArr = project.form.formParts.map((label) => {
        return label.name;
      });
    }
    let labelsArr = [];
    let allLabels = [];
    if (customLabelsArray) {
      // console.log("custom labels array found");
      allLabels = customLabelsArray;
    } else {
      allLabels = orderedLabels;
    }
    // console.log("allLabels",allLabels);
    allLabels.forEach((labelObj) => {
      if (formPartsArr.indexOf(labelObj.name) < 0) {
        labelsArr.push(newFormPartWithPredeterminedLabel(labelObj.name, labelObj.type));
      }
    });
    this.setState({
      project: update(project, {
        form: {
          formParts: {
            $push: [...labelsArr],
          },
        },
      }),
    });
  }

  async handleDelete() {
    const { match, history } = this.props;
    const projectId = this.props.location.pathname.replace("/admin/", "");
    await apiCall("DELETE", `${process.env.REACT_APP_PRODUCTION_SERVER_URL}/api/projects/${projectId}`);

    history.push('/admin/');
  }

  handleNameChange(e) {
    const { value } = e.target;
    const { project } = this.state;
    this.setState({
      project: update(project, {
        name: {
          $set: value,
        },
      }),
    });
  }

  handleReferenceChange({ referenceLink, referenceText }) {
    const { project } = this.state;
    this.setState({
      project: update(project, {
        referenceText: {
          $set: referenceText,
        },
        referenceLink: {
          $set: referenceLink,
        },
      }),
    });
  }

  render() {
    const { match } = this.props;
    const projectId = this.props.location.pathname.replace("/admin/", "");

    const { error, isLoaded, project } = this.state;

    const { language } = this.props;
    const { currentUser: { user } } = this.props;

    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <Loader active inline="centered" />;
    }

    if (user.role === "client" && project.status === "importing") {
      return (
        <>
          <div style={{ display: 'flex', margin: '0 5%' }}>
            {process.env.REACT_APP_USE_GLOBAL_NAV === 'true' && (
              <GlobalSideBar currentUser={this.props.currentUser} />
            )}
            <div style={{ flex: 1, paddingLeft: process.env.REACT_APP_USE_GLOBAL_NAV === 'true' ? '20px' : '0px' }}>
              <DocumentMeta title={`Edit project ${project.name}`}>
                <div style={{ paddingBottom: 30 }}>
                  <Input
                    placeholder="Project name"
                    control="input"
                    defaultValue={project.name}
                    style={{ fontSize: 24, width: '100%', marginTop: 10 }}
                    onChange={this.handleNameChange}
                  />
                </div>

                <div id="configuration" style={{ padding: '0 0' }}>
                  <Header>We're working on it</Header>
                  <Segment textAlign="left">
                    <p style={{ fontSize: '1.3rem', padding: '10px' }}>
                      Our model is currently analyzing all of your data. You should receive an email once your data is all analyzed. <br />
                      Typically you'll hear back within the next three business days.
                    </p>
                    <div style={{ textAlign: 'center', marginTop: '20px' }}>
                      <HashLink to={`/admin`}>
                        <Button color="teal" size="medium">
                          Back to admin
                        </Button>
                      </HashLink>
                    </div>
                  </Segment>
                </div>
              </DocumentMeta>
            </div>
          </div>
        </>

      )
    }

    if (user.role !== "admin" && user.role !== "labeler_lead" && user.role !== "client") {
      if (user.role === "client") {
        if ("companyId" in project && project.companyId) {
          // console.log("user.companyIds.indexOf(project.companyId)<0",user.companyIds.indexOf(project.companyId)<0);

          if (user.companyIds.indexOf(project.companyId) < 0) {
            return <div>Error: {language === "es" ? "No está autorizado" : "Not authorized"}</div>;
          }
        } else {
          return <div>Error: {language === "es" ? "No está autorizado" : "Not authorized"}</div>;
        }
      } else {
        return <div>Error: {language === "es" ? "No está autorizado" : "Not authorized"}</div>;
      }
    }

    const items = project && project.form && project.form.formParts ? project.form.formParts : [];
    const renderedItems = items && items.length ? (
      items.map((value, index) => (
        <SortableItem
          key={value.id}
          index={index}
          value={value}
          onChange={this.handleChange}
        />
      ))
    ) : (
      <Header className="centered" as="h5">
        {language === "es" ? "No hay clases, agregue clases usando el botón de más abajo" : "No labels, add labels using the plus button below"}
      </Header>
    );

    return (
      <DocumentMeta title={`Edit project ${project.name}`}>
        <div style={{ display: 'flex', margin: '0px 5%' }}>
          {process.env.REACT_APP_USE_GLOBAL_NAV === 'true' && (
            <GlobalSideBar currentUser={this.props.currentUser} />
          )}
          <div className="ui" style={{ paddingBottom: 200, flex: 1 }}>
            <Input
              placeholder="Project name"
              control="input"
              defaultValue={project.name}
              style={{ fontSize: 24, width: '100%', marginTop: 10 }}
              onChange={this.handleNameChange}
            />
            <div
              style={{
                display: 'flex',
                flexDirection: 'row-reverse',
                marginTop: 5,
              }}
            >
              <Input
                label="Labeling link"
                value={window.location.origin + '/label/' + projectId}
                onClick={e => e.target.select()}
              />
            </div>

            {/* {user.role === "admin" && ( <CreateBucket project={project} role={user.role}/>)}
                        <MediaManagerModal browserLang={language} isOpen={false} project={project}/> */}


            {user.role === "admin" && (
              <>
                <Header disabled>PROJECT DETAILS</Header>
                <Segment>
                  <AssignedLabeler basic={true} project={project} onSaveAssignment={this.handleLabelerAssignment}></AssignedLabeler>
                  <ProjectStatusUpdater basic={true} project={project} onSaveProject={this.handleProjectStatusUpdate} language={language} />
                  <DropdownSelector basic={true} startingId={project?.projectLabelingDataFormat} onSaveSelection={this.handleProjectAcceptedDataFormat} language={language} currentUserId={user.id} optionsObjsList={[{ "_id": "image", "name": "Images (JPEG, JPG, PNG)", "value": "image" }, { "_id": "video", "name": "Videos (AVI, MP4)", "value": "video" }]} primaryLabel={"Project Data Type"} placeholderText={"Images/Videos"} includeNull={false} />
                  <DropdownSelector basic={true} startingId={project?.distanceMeasuringUnit} onSaveSelection={this.handleDistanceMeasuringUnitChange} language={language} currentUserId={user.id} optionsObjsList={[{ "_id": "ft", "name": "Feet (Imperial)", "value": "ft" }, { "_id": "mt", "name": "Meters (Metric)", "value": "mt" }]} primaryLabel={"Project Distance Measuring Unit"} placeholderText={"Ft or Mt"} includeNull={false} />
                  <Divider />
                  <p><strong>For Client-Facing Projects Only</strong></p>
                  <AssignedCompany basic={true} project={project} onSaveCompany={this.handleCompanyAssignment} language={language} currentUserId={user.id} />
                  <DropdownSelector basic={true} startingId={"pavementDataType" in project ? project.pavementDataType : null} onSaveSelection={this.handleDataTypeAssignment} language={language} currentUserId={user.id} optionsObjsList={[{ "_id": "lcms", "name": "LCMS", "value": "lcms" }, { "_id": "rgb", "name": "RGB", "value": "RGB - Color Image" }]} primaryLabel={"Pavement Photo Type (Only for Client-Facing Projects)"} placeholderText={"LCMS/RGB"} includeNull={true} />
                </Segment>
              </>
            )}
            {(user.role === "admin" || user.role === "labeler_lead") && (
              <div id="labels" style={{ padding: '2em 0 110px 0' }}>
                <Header disabled>LABELS</Header>
                <Button
                  color="green"
                  size="medium"
                  onClick={() => this.handleMassLabelUpload(null)}
                >
                  {language === "es" ? "Agregar etiquetas por defecto" : "Add default labels"}
                </Button>
                <div style={{ "maxHeight": "500px", "overflowY": "scroll" }}>
                  <SortableContainer onSortEnd={this.onSortEnd} useDragHandle>
                    {renderedItems}
                  </SortableContainer>
                </div>
                <Button
                  circular
                  icon="plus"
                  color="teal"
                  size="massive"
                  style={{ float: 'right', marginTop: '2em' }}
                  onClick={this.handleNew}
                />
              </div>
            )}
            {
              project?.projectLabelingDataFormat === 'video' &&
              <div id="project-label-metadata" style={{ padding: '2em 0' }}>
                <Header disabled>{language === "es" ? "METADATA PARA LABELS" : "LABELS METADATA"}</Header>
                <ProjectLabelMetadata userRole={user?.role} projectId={this.state.project._id} labels={items ?? []} />
              </div>
            }

            <div id="configuration" style={{ padding: '2em 0' }}>
              <Header disabled>{language === "es" ? "CONFIGURACION DEL PROYECTO" : "PROJECT CONFIGURATION"}</Header>
              <ProjectConfiguration labelsList={items} filterNone={true} project={project} userRole={user?.role} />
            </div>

            {project?.projectLabelingDataFormat === 'image' && (
              <>
                <div id="images" style={{ padding: '2em 0' }}>
                  <Header disabled>{language === "es" ? "IMAGENES" : "IMAGES"}</Header>
                  <ProjectImages
                    projectId={projectId}
                    project={project}
                    refetchRef={f => this.setState({ handleImagesChange: f })}
                  />
                </div>
                <div id="upload-images" style={{ padding: '2em 0' }}>
                  <Header disabled>{language === "es" ? "SUBIR IMAGENES" : "UPLOAD IMAGES"}</Header>
                  {user.role !== 'client' && (<UploadImages
                    projectId={projectId}
                    onChange={this.state.handleImagesChange}
                  />)}
                </div>
              </>
            )}

            {project?.projectLabelingDataFormat === 'video' && (
              <>
                <div id="videos" style={{ padding: '2em 0' }}>
                  <Header disabled>{language === "es" ? "VIDEOS" : "VIDEOS"}</Header>
                  <ProjectVideos
                    projectId={projectId}
                    project={project}
                    refetchRef={f => this.setState({ handleVideosChange: f })}
                  />
                </div>
                <div id="upload-videos" style={{ padding: '2em 0' }}>
                  <Header disabled>{language === "es" ? "SUBIR VIDEOS" : "UPLOAD VIDEOS"}</Header>
                  <UploadImages
                    projectId={projectId}
                    onChange={this.state.handleVideosChange}
                    isVideo={true}
                  />
                </div>
              </>
            )}

            {project?.projectReportJSONURL && (
              <>
                <div id="configuration" style={{ padding: '2em 0' }}>
                  <Header disabled>{language === "es" ? "PCI" : "PCI Report"}</Header>
                  <Segment>
                    <HashLink to={`/projects/${project?._id}/Visualize`}>
                      <Button
                        color="green"
                        size="medium"
                      >
                        Visualize PCI Report
                      </Button>
                    </HashLink>
                  </Segment>
                </div>
              </>
            )}
            {(user.role === 'admin' || user.role === 'client') && (<div id="import-data" style={{ padding: '2em 0' }}>
              <Header disabled>IMPORT DATA</Header>
              {/*<Button
                                          color="teal"
                                          size="medium"
                                          onClick = {()=>{
                                            const bboxes = orderedLabels.map((labelObj)=>{
                                              let copiedLabel = JSON.parse(JSON.stringify(labelObj));
                                              copiedLabel.type = "bbox";  
                                              return copiedLabel;
                                            });
                                            this.handleMassLabelUpload(bboxes);
                                          }}
                                        >
                                        {language ==="es" ?  "Agregar etiquetas de bbox por defecto" :"Add default bbox labels"}
                                      </Button>*/}
              <ImportData
                projectId={projectId}
                onChange={() => {
                  this.state.handleImagesChange();
                  this.componentDidMount();
                }}
              />
            </div>)}
            {(user.role === 'admin' || user.role === 'client') && <div id="export-data" style={{ padding: '2em 0' }}>
              <Header disabled>{language === "es" ? "EXPORTAR DATOS" : "EXPORT DATA"}</Header>
              {project?.projectLabelingDataFormat === 'image' && project?.images?.length >= 0 && project?.images?.length <= 500 && (
                <div>
                  <LoaderButton hasPromise={true} color="green" iconName="download" text="Download a JSON-encoded file" disabled={(user.role !== "admin" && user.role !== "client")} onClick={() => this.handleProjectExport(false)} />
                </div>)
              }
              {project?.projectLabelingDataFormat === 'image' && project?.images?.length > 500 && (<div>
                <LoaderButton hasPromise={true} color="green" iconName="mail" text="Email JSON-encoded file of Labeled Images" disabled={user.role !== "admin"} onClick={() => this.handleEmailProjectJSON(false)} successMessage="Please check your email in a few minutes. If there are thousands of images, this may take a few hours." disabledSeconds={20} />
              </div>)
              }
              {project?.projectLabelingDataFormat === 'video' && project?.videos?.length >= 0 && project?.videos?.length <= 500 && (
                <div>
                  <LoaderButton hasPromise={true} color="green" iconName="download" text="Download a JSON-encoded file" disabled={user.role !== "admin"} onClick={() => this.handleVideoProjectExport(false)} />
                </div>)
              }
              {/*{project?.projectLabelingDataFormat === 'video' && project?.videos?.length > 500 && (
                    <div>
                      <LoaderButton hasPromise={true} color="green" iconName="mail" text="Email JSON-encoded file of Labeled Images" disabled ={user.role !== "admin"} onClick={()=>this.handleEmailProjectJSON(false)} successMessage="Please check your email in a few minutes. If there are thousands of images, this may take a few hours." disabledSeconds={20}/>
                    </div>)
                  }*/}
            </div>
            }

            {user.role === 'admin' && (
              <div id="export-all-data" style={{ padding: '2em 0' }}>
                {project?.projectLabelingDataFormat === 'image' && project?.images?.length >= 0 && project?.images?.length <= 500 && (
                  <div>
                    <LoaderButton hasPromise={true} color="blue" iconName="download" text="Download a JSON file with ALL images (including unlabeled)" disabled={user.role !== "admin"} onClick={() => this.handleProjectExport(true)} />
                  </div>)
                }
                {project?.projectLabelingDataFormat === 'image' && project?.images?.length > 500 && (
                  <div>
                    <LoaderButton hasPromise={true} color="blue" iconName="mail" text="Email a JSON file with ALL images (including unlabeled)" disabled={user.role !== "admin"} onClick={() => this.handleEmailProjectJSON(true)} successMessage="Please check your email in a few minutes. If many images, this may take a few hours." disabledSeconds={20} />
                  </div>)
                }
                {project?.projectLabelingDataFormat === 'video' && project?.videos?.length >= 0 && project?.videos?.length <= 500 && (
                  <div>
                    <LoaderButton hasPromise={true} color="blue" iconName="download" text="Download a JSON file with ALL videos (including unlabeled)" disabled={user.role !== "admin"} onClick={() => this.handleVideoProjectExport(true)} />
                  </div>)
                }
                {/*{project?.projectLabelingDataFormat ==='video' && project?.videos?.length > 500 && (
                        <div>
                          <LoaderButton hasPromise={true} color="blue" iconName="mail" text="Email a JSON file with ALL videos (including unlabeled)" disabled ={user.role !== "admin"} onClick={()=>this.handleEmailProjectJSON(true)} successMessage="Please check your email in a few minutes. If many videos, this may take a few hours." disabledSeconds={20}/>
                        </div>)
                      }*/}
              </div>
            )}
            <div id="reference-information" style={{ padding: '2em 0' }}>
              {/*<Header disabled>REFERENCE INFORMATION</Header>
              <UploadReference
                project={project}
                onChange={this.handleReferenceChange}
                onUpload={() => this.componentDidMount()}
              />
            </div>
            <div id="ml-assistance" style={{ padding: '2em 0' }}>
              <Header disabled>ML ASSISTANCE MODELS</Header>
              <MLAssist />
            </div>
            <div id="delete-project" style={{ padding: '2em 0' }}>
              <Header disabled>DELETE PROJECT</Header>
              <p>
                The button bellow will delete all metadata about labels, images
                and the project.
              </p>*/}

              <DeleteProjectModal browserLang={language} isOpen={this.state.deleteModalOpen} onDelete={this.handleDelete} onToggleModal={this.onToggleModal} />

              <Button negative onClick={() => this.onToggleModal(true)} disabled={user.role !== "admin"}>
                {language === "es" ? "Borrar Proyecto" : "Delete project"}
              </Button>
            </div>
          </div>
        </div>
      </DocumentMeta>
    );
  }
}

const SortableItem = sortableElement(LabelFormItem);

const SortableContainer = sortableContainer(({ children }) => {
  return <div>{children}</div>;
});

const newFormPart = () => {
  const id = Math.random()
    .toString(36)
    .substr(2, 9);
  return {
    id,
    name: 'New label',
    type: 'polygon',
  };
};

const formPartIdGenerator = () => {
  const id = Math.random()
    .toString(36)
    .substr(2, 9);
  return id;
};

const newFormPartWithPredeterminedLabel = (name, type) => {
  const id = formPartIdGenerator();
  return {
    id,
    name,
    type
  };
}


