import React, { useState, useEffect} from 'react';
import { Modal, Button, Checkbox} from 'semantic-ui-react';


const OptionsModal = ({modalEnabled,onClose, browserLang, handleClick,intImgEnabled,threeDImgEnabled,rowImgEnabled,threeDImgOverlayEnabled}) => {
  const [intImgDisplay,setIntImgDisplay] = useState(false);
  const [threedImgDisplay,setThreedImgDisplay] = useState(false);
  const [rowImgDisplay, setRowImgDisplay] = useState(false);
  const [threeDImgOverlayDisplay, setThreeDImgOverlayDisplay] = useState(false);

  useEffect(()=>{
    setIntImgDisplay(intImgEnabled);
    setThreedImgDisplay(threeDImgEnabled);
    setRowImgDisplay(rowImgEnabled);
    setThreeDImgOverlayDisplay(threeDImgOverlayEnabled);
  },[intImgEnabled,threeDImgEnabled,rowImgEnabled,threeDImgOverlayEnabled]);

  return (
    <Modal
          onClose={() => {
            onClose();
          }}
          open={modalEnabled}
        >
        <Modal.Header>Options</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <p>LCMS Only:</p>
            <Checkbox style={{paddingTop:"10px",paddingBottom:"10px"}} checked={intImgDisplay} label="Intensity Image" toggle onChange={()=>setIntImgDisplay(!intImgDisplay)}/>
            <br/>
            <Checkbox  style={{paddingTop:"10px",paddingBottom:"10px"}} checked={threedImgDisplay} label="3D Image" toggle onChange={()=>setThreedImgDisplay(!threedImgDisplay)}/>
            <br/>
            <Checkbox  style={{paddingTop:"10px",paddingBottom:"10px"}} checked={threeDImgOverlayDisplay} label="3D Image Overlay" toggle onChange={()=>setThreeDImgOverlayDisplay(!threeDImgOverlayDisplay)}/>
            <br/>
            <Checkbox style={{paddingTop:"10px",paddingBottom:"10px"}} checked={rowImgDisplay} label="Right of Way Image" toggle onChange={()=>setRowImgDisplay(!rowImgDisplay)}/>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button color='red' onClick={onClose}>
            Cancel
          </Button>
          <Button
            disabled={false}
            onClick={()=>{
              if(handleClick){
                handleClick({
                  lcmsIntDisplay:intImgDisplay,
                  lcms3dDisplay:threedImgDisplay,
                  lcmsRightOfWayDisplay:rowImgDisplay,
                  lcms3dOverlayDisplay:threeDImgOverlayDisplay
                });
              }
              onClose();
            }}
            content="Save"
            labelPosition='right'
            icon='checkmark'
            positive
          />
        </Modal.Actions>
      </Modal>
	);
}
export default OptionsModal;
