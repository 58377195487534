import React, { Fragment, useContext } from 'react';
import PropTypes from 'prop-types';
import { Button, List, Icon } from 'semantic-ui-react';

import RoundedNumber from '../Math/RoundedNumber/RoundedNumber';
import TwoDimensionalVideoContext from '../TwoDimensionalVideo/twoDimensionalVideoContext';
import Duration from '../VideoPlayer/FormattedTime/FormattedTime';
import './incidentList.css';

const IncidentList = ({
  className,
  annotationName,
  incidents,
}) => {
  const twoDimensionalVideoContext = useContext(TwoDimensionalVideoContext);
  const {
    played,
    duration,
    onIncidentItemClick,
    onIncidentUpdateTime,
    onIncidentItemDeleteClick
  } = twoDimensionalVideoContext;

  const incidentListUI = incidents.map((incident, index) => {
    const {
      name,
      time,
      width,
      height,
      status,
      x,
      y,
      id
    } = incident;

    let itemButtonStyle = {
      color: time === played ? 'rgb(33, 37, 41)' : '#000000',
    };

    const isNotLastIncidentAndPlayedBetweenCurrentAndNext = (
      index !== incidents.length - 1 &&
      played > time &&
      played < incidents[index + 1].time
    ) || (played === time && index < incidents.length -1);

    const isLastIncidentAndPlayedOverCurrent = (
      index === incidents.length - 1 && played > time
    );

    const arrowDown =
      isNotLastIncidentAndPlayedBetweenCurrentAndNext ||
      isLastIncidentAndPlayedOverCurrent ? (
        <List.Item className='incident-list__item-arrow' key={time + 1}>
          <Icon name='arrow right' size='small' />
        </List.Item>
      ) : null;

    const handleIncidentClick = () => onIncidentItemClick({ time, annotationName, id });

    const handleSaveTime = (newTime) => {
      const min = index === 0 ? 0 : incidents[index - 1].time;
      const max = index === incidents.length - 1 ? 1 : incidents[index + 1].time;
      const newValue = newTime / duration;
      if (newValue < min || newValue > max) return;
      onIncidentUpdateTime({ annotationName, incidentName: name, newTime: newValue })
    }

    return (
      <Fragment key={time}>
        <List.Item
          className='incident-list__item'
          key={time}
        >
          <div
            className='incident-list__item-button d-flex justify-content-between'
            style={itemButtonStyle}
            onClick={handleIncidentClick}
          >
            <div className='incident-list__item-status pr-1'>
              <b>{status}</b>{' '}
              <Duration
                className='incident-list__item-duration'
                seconds={duration * time}
                handleSaveTime={handleSaveTime}
              />
            </div>
            <div className='incident-list__item-size pr-1'>
              <b>Incident Size</b>{' '}
              <RoundedNumber number={width} />
              {'x'}
              <RoundedNumber number={height} />
            </div>
            <div className='incident-list__item-position'>
              <b>Incident Position</b>{' '}
              <RoundedNumber number={x} />
              {':'}
              <RoundedNumber number={y} />
            </div>
          </div>
          <Button
            className='incident-list__item-delete-button'
            color='blue'
            onClick={() =>
              onIncidentItemDeleteClick({
                annotationName,
                incidentName: name,
              })
            }
          >
            <Icon name='trash' />
          </Button>
          {arrowDown}
        </List.Item>
      </Fragment>
    );
  });

  const rootClassName = `incident-list px-3 py-2 text-center${
    className ? ` ${className}` : ''
  }`;

  return (
    <List className={rootClassName}>
      {incidentListUI}
    </List>
  );
};

IncidentList.propTypes = {
  className: PropTypes.string,
  annotationName: PropTypes.string,
  incidents: PropTypes.arrayOf(PropTypes.object),
};

IncidentList.defaultProps = {
  className: '',
  annotationName: '',
  incidents: [],
};

export default IncidentList;
