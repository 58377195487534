import React, { Component, Fragment } from 'react';

import { Message } from 'semantic-ui-react';

export default class OverScreen extends Component {
  componentDidMount(){
      const {currentUser:{user}} = this.props;
      if(user && user.company !== "Engin.AI"){
        this.props.history.push("/");
      }
  }
  render() {
    const { projectId } = this.props.match.params;
    const {currentUser:{user}} = this.props;
    const {browserLang} = this.props;
    let backgroundImg = `linear-gradient(45deg, rgb(229, 44, 78) 1%, rgb(233, 74, 99) 30%, rgb(174, 82, 157) 69%, rgb(154, 85, 177) 100%)`;

    return (
      <Fragment>
          <header className="App-hero" style={{
            "minHeight":"75px",
            "backgroundImage":backgroundImg,
            "color":"white"
          }}>
          </header>
          <Message style={{ maxWidth: 600, margin: '100px auto' }}>
            <Message.Header>{browserLang === "es"? "No hay más imágenes para etiquetar en este proyecto" : "No more images in this project"}</Message.Header>
            <p>
              {browserLang === "es"? "Para ver los detalles del proyecto o los proyectos existentes, visita la " : "To upload more images or view previously tagged ones head to the "} 
                {
                  user.role === "labeler"? (
                    <>
                      <a href={`/#/label`}> {browserLang === "es"? "pantalla de proyectos " : "labeling page"} </a>
                    </>
                    ):
                  (
                    <>
                      <a href={`/#/admin/${projectId}`}> {browserLang === "es"? "pantalla de administración del proyecto " : "admin panel"}</a>
                    </>
                    )
                }
              
            </p>
          </Message>
      </Fragment>
    );
  }
}
