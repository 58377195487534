import React, {useState,useEffect} from 'react';
import MapDistressView from './MapDistressView';
import {Grid, Dropdown,Icon, Divider, Button, Header, Modal,ModalHeader,ModalContent, ModalActions} from 'semantic-ui-react';
import GaugeChart from 'react-gauge-chart';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,Brush } from 'recharts';
import {apiCall} from "../services/api";
import {pciDistressCodeMapping} from "../services/utils/pciDistresses.js";
import { CSVLink } from 'react-csv';



const BASE_URL = process.env.REACT_APP_PRODUCTION_SERVER_URL;

const DownloadButton = () => {
    const [open, setOpen] = useState(false);

    const densitiesData = [
        { id: 1, density: 10 },
        { id: 2, density: 15 },
        { id: 3, density: 20 },
    ];

    const distressData = [
        { id: 1, distress_level: 'low' },
        { id: 2, distress_level: 'medium' },
        { id: 3, distress_level: 'high' },
    ];

    const headers = [
        { label: "ID", key: "id" },
        { label: "Value", key: "density" }, // Adjust according to the specific CSV content
    ];

    return (
        <Modal
            closeIcon
            open={open}
            trigger={<Button color="purple" icon labelPosition='left'><Icon name='download' />Download</Button>}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
        >
            <Header icon='download' content='Download Report' />
            <Modal.Content>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <CSVLink
                        filename={"densities_report.csv"}
                        data={densitiesData}
                        headers={headers}
                        className="ui button"
                    >
                        Download Densities Report
                    </CSVLink>
                    <CSVLink
                        filename={"distress_inventory.csv"}
                        data={distressData}
                        headers={headers.map(header => ({ ...header, key: header.key.replace('density', 'distress_level') }))}
                        className="ui button"
                        style={{ marginTop: '10px' }}
                    >
                        Download Distress Inventory
                    </CSVLink>
                </div>
            </Modal.Content>
        </Modal>
    );
};

const ReportVisualizer = ({currentUser,isAuthenticated}) => {
  const [data, setData] = useState(null);
  const [selectedSectionName,setSelectedSectionName] = useState("All");
  const [avgPciScore, setAvgPciScore] = useState(null);
  const [chartData, setChartData] = useState(null);
  const [originalJsonData,setOriginalJsonData] = useState(null);
  const [project,setProject] = useState(null);
  const [modalOn, setModalOn] = useState(false);
  const [distressTypeFilters,setDistressTypeFilters] = useState([]);
  const [severityFilters,setSeverityFilters] = useState([]);
  const [distanceMeasuringUnit,setDistanceMeasuringUnit] = useState("ft"); // enum ['ft','mt']
  const getProjectById = async (projectId)=>{
  	const foundProject = await apiCall("GET",`${BASE_URL}/api/projects/${projectId}`);
  	if(foundProject){
  		setProject(foundProject);
		if("distanceMeasuringUnit" in foundProject && foundProject.distanceMeasuringUnit){
			setDistanceMeasuringUnit(foundProject.distanceMeasuringUnit);
		}
  	}
  };

  const sortArrayByProperty = (array, property)=>{
    return array.sort((a, b) => {
        if (a[property] > b[property]) {
            return -1; // for descending order
        }
        if (a[property] < b[property]) {
            return 1;
        }
        return 0;
    });
	};
  useEffect(() => {
  	// Find Project  	
	const projectId = window.location.hash.split("/")[2];
	if(!project){
		getProjectById(projectId);
	}
	if(project){
		const {projectReportJSONURL} = project;
		if(!projectReportJSONURL){
			return;
		}
		// Fetch the data from the endpoint and set it to the state
	    fetch(projectReportJSONURL)
	      .then(response => response.json())
	      .then(data => {
	        setData(data);
	        setOriginalJsonData(data);
	        setAvgPciScore(calculateAveragePciScore(data));
	        setChartData(createObjectFromJSON(data));
	      })
	      .catch(error => console.error(error));
	}
      return ()=>{
      	return [];
      }
  }, [project]);

  const handleDropdownChange = (e, { value }) => {
  	if(value === 'All'){
  		setData({...originalJsonData});
  		setAvgPciScore(calculateAveragePciScore(originalJsonData));
		setChartData(createObjectFromJSON(originalJsonData));
		setSelectedSectionName('All');
		setSeverityFilters([]);
		setDistressTypeFilters([]);
  	}else{
  		const newData={[value]:{...originalJsonData[value]}};
		const chartData=newData[value]?.distressMap;
  		setData(newData);
  		setAvgPciScore(calculateAveragePciScore(newData));
		setSelectedSectionName(value);
		setChartData(createObjectFromJSON(chartData,true));
		setSeverityFilters([]);
		setDistressTypeFilters([]);
  	}
  };

  const filterImagesByAllowedDistressSev = (images)=>{
	const distressFilters = distressTypeFilters;
	const sevFilters = severityFilters;
	const newImages = [];
	for(const image of images){
		if(image?.distressFrameMap){
			const distressesInImage = Object.keys(image?.distressFrameMap);
			const containsApplicableDistress=distressesInImage.some((distressSev)=>{
				const distressType = distressSev.split("_")[0];
				const distressSeverity = distressSev.split("_")[1];
				let isAllowedDistress = false;
				let isAllowedSeverity =  false;
				if(distressFilters.length <= 0 ){
					isAllowedDistress = true;
				}else if(distressFilters.indexOf(distressType) >=0){
					isAllowedDistress=true;							
				}

				if(sevFilters.length <= 0 ){
					isAllowedSeverity = true;
				}else if(sevFilters.indexOf(distressSeverity) >=0){
					isAllowedSeverity=true;							
				}
				return (isAllowedDistress ===true && isAllowedSeverity === true)
			});
			if(containsApplicableDistress){
				newImages.push(image);
			}
		}
	}
	return newImages;
  };

  const handleApplyFilters = ()=>{
	if(selectedSectionName === 'All'){
		let newData = {};
		for (const key in originalJsonData){
			let newImages = filterImagesByAllowedDistressSev(originalJsonData[key]?.images);
			const sectionData = {...originalJsonData[key]};
			sectionData.images = newImages;
			if(newImages.length > 0){
				newData = {...newData,[key]:{...sectionData}};
			}
		}
		setData({...newData});
	}else if(selectedSectionName){
		const newData = {
			[selectedSectionName] : {
				...originalJsonData[selectedSectionName],images:filterImagesByAllowedDistressSev(originalJsonData[selectedSectionName]?.images)
			}
		};
		setData({...newData});
	}
  }

  const calculateAveragePciScore = (jsonData)=> {
	  let totalPciScore = 0;
	  let numSections = 0;

	  for (const section of Object.values(jsonData)) {
	    if ('pciScore' in section) {
	      totalPciScore += section['pciScore'];
	      numSections += 1;
	    }
	  }

	  if (numSections > 0) {
	    return totalPciScore / numSections;
	  } else {
	    return null;
	  }
	};
	const createObjectFromJSON = (json, isDistress=false)=> {
	  let result = [];
	  if(!isDistress){
		for (const sectionName in json) {
			if (json.hasOwnProperty(sectionName)) {
			  const section = json[sectionName];
			  const sectionObject = {
				sectionName,
				pciScore: section.pciScore
			  };
			  result.push(sectionObject);
			}
		  }
		 result = sortArrayByProperty(result,'pciScore');
	  }else{
		let sortingProperty = 'area_ft2';
		for (const distress in json) {
			const [distressCode, severityCode] = distress.split('_');
			const distressName = pciDistressCodeMapping[distressCode];
            const severity = ['Low', 'Medium', 'High'][parseInt(severityCode) - 1];
			let sectionObject = {};
			if(distanceMeasuringUnit === 'ft'){
				sectionObject = {
					name:`${distressName} - ${severity}`,
					area_ft2:json[distress]?.area_ft2,
					length_ft:json[distress]?.length_ft,
					count:json[distress]?.counts
				  };
			}else if(distanceMeasuringUnit === "mt"){
				sortingProperty='area_m2';
				sectionObject = {
					name:`${distressName} - ${severity}`,
					area_m2:json[distress]?.area_m2,
					length_m:json[distress]?.length_m,
					count:json[distress]?.counts
				  };
			}
			  result.push(sectionObject);
		  }
		  result =  sortArrayByProperty(result,sortingProperty);
	  }
	  return result;
	}

	let sectionsList = [];
	if(originalJsonData){
		sectionsList = Object.keys(originalJsonData).map((sectionName)=>{
			return{
				key:sectionName,
				text:sectionName,
				value:sectionName
			}			
		});
		sectionsList.unshift({key:"All","text":"All",value:"All"});
	}

	if(!currentUser || !isAuthenticated || !project || (project && !("projectReportJSONURL" in project))){
		return (
			<div>
				No PCI Report Available. Check back soon!
			</div>
			);
	}
	const distressTypeList=Object.keys(pciDistressCodeMapping).map((key)=>{
		return {
			text:pciDistressCodeMapping[key],
			value:key,
			key
		}
	});


	return (
		<div style={{paddingTop:'0',marginTop:'15px'}}>
			<Modal
				onClose={()=>setModalOn(false)}
				onOpen={()=>setModalOn(true)}
				open={modalOn}
				>
					<ModalHeader>Apply Filters</ModalHeader>
					<ModalContent>
						<h3>By Type</h3>
						<Dropdown
							multiple
							selection
							search
							placeholder="Select Distress Type"
							options={distressTypeList}
							onChange={(e, { value }) => {
								setDistressTypeFilters(value);
							}}
							value={distressTypeFilters}
							></Dropdown>
						<h3>By Severity</h3>
						<Dropdown
							multiple
							selection
							search
							placeholder="Select Severity"
							options={[
								{
									text:"Low",
									value:"1",
									key:1
								},
								{
									text:"Medium",
									value:"2",
									key:2
								},
								{
									text:"High",
									value:"3",
									key:3
								}
							]}
							onChange={(e, { value }) => {
								setSeverityFilters(value);
							}}
							value={severityFilters}
							></Dropdown>
					</ModalContent>
					<ModalActions>
					<Button color='red' onClick={() => setModalOn(false)}>
						Cancel
					</Button>
					<Button
						content="Apply Filters"
						onClick={() => {
							handleApplyFilters();
							setModalOn(false)
						}}
						positive
						/>
					</ModalActions>
			</Modal>
			<Grid>
				<Grid.Row style={{"padding": "5px 0px 0px"}} centered columns={1}>
						<a href={`/#/admin/${project?._id}`}><h2>{project?.name}</h2></a>
				</Grid.Row>	
				<Grid.Row columns={3}>
					<Grid.Column>
						<h2>Select Section</h2>
						<Dropdown placeholder='Select Section' search selection options={sectionsList} value={selectedSectionName} onChange={handleDropdownChange}/>
						{currentUser?.role === 'admin' && (
						<>
						<Divider hidden/>
						{/* <DownloadButton/> */}
						<Button 
							color="teal" 
							content='Filter' 
							icon='filter' 
							labelPosition='left' 
							onClick={()=>setModalOn(true)}/>
						</>
						)}
					</Grid.Column>
					<Grid.Column>
							<h2>PCI</h2>
							<GaugeChart id="gauge-chart2" 
								colors={['#FF0000','#FFA500','#FFFF00','#00FF00']}
							  nrOfLevels={10} 
							  percent={avgPciScore/100} 
							  style={{width:'200px',margin:'0 auto'}}
							  textColor='black'
							  formatTextValue={(value)=>Math.floor(value)}
							/>
					</Grid.Column>
					<Grid.Column>
						 <BarChart
					          width={500}
					          height={200}
					          data={chartData}
					          margin={{
					            top: 5,
					            right: 30,
					            left: 20,
					            bottom: 5,
					          }}
					        >
					          <CartesianGrid strokeDasharray="3 3" />
					          <XAxis dataKey={selectedSectionName === "All" ? "sectionName" : "name"} />
					          {selectedSectionName === "All"  ?
							 	<YAxis />
								:
								<>
								<YAxis yAxisId="left" orientation="left" stroke="#8884d8" />
								<YAxis yAxisId="right" orientation="right" stroke="#d36135" />
								</> 
								}
					          <Tooltip />
					          <Legend verticalAlign="top" wrapperStyle={{ lineHeight: '40px' }} />
							  <Brush dataKey={selectedSectionName === "All" ? "sectionName" : "name"} height={30} stroke="#8884d8" />
					          {selectedSectionName === "All"  ? 
							  	<Bar dataKey="pciScore" fill="#8884d8" />
							  	:
								<>
									<Bar yAxisId="left" dataKey={distanceMeasuringUnit === 'mt' ? "area_m2": "area_ft2"} fill="#8884d8" />
									<Bar yAxisId="left" dataKey={distanceMeasuringUnit === 'mt' ? "length_m": "length_ft"} fill="#94fbab" />
									<Bar yAxisId="right" dataKey="count" fill="#d36135" />
								</>
								}
					        </BarChart>
					</Grid.Column>
				</Grid.Row>
			</Grid>
			<div style={{height:'80vh'}}>
				<MapDistressView data={data} distanceMeasuringUnit={distanceMeasuringUnit}/>
			</div>
		</div>
		);
}

export default ReportVisualizer;