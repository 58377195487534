import React, {useState} from 'react';
import { Segment, Button, Icon, Grid, Dropdown } from 'semantic-ui-react';

const ProjectStatusUpdater = ({project, onSaveProject,language,basic})=>{
  const [editMode, setEditMode] = useState(false);
  const [hovered, setHovered] = useState(false);
  const [projectStatus, setProjectStatus] = useState(null);
  const [loading, setLoading] = useState(false);
  const onToggleEdit = ()=>{
    setHovered(!hovered);
  };

  const onOpenEditMode = async ()=>{
    setEditMode(true);
  };

  const statusOptions = ["new","active","review","completed"];

  const allStatusOptions = statusOptions.map((option)=>{
    let optionText = getVanityProjectName(option, language);
    return {
      key:option,
      text:optionText,
      value:option
    }
  });

  const onStatusSelect = (event, data)=>{
    setProjectStatus(data.value);
  };

  const onSave = ()=>{
    setLoading(true);
    onSaveProject(projectStatus);
    setLoading(false);
    setEditMode(false);
  };
  return (
    <Segment onMouseOver={onToggleEdit} textAlign='left' onMouseOut={onToggleEdit} basic={basic}>
      {
        editMode?
        (
          <Grid>
            <Grid.Row  verticalAlign='middle' columns={2}>
              <Grid.Column>
                <Segment basic>
                  <p> {language === "es" ? "Asignar Estado del Proyecto" : "Assign Project Status"}</p>
                </Segment>  
              </Grid.Column>
              <Grid.Column>
                <Segment basic >
                  <Dropdown onChange={onStatusSelect} placeholder={project && ("status" in project) && project.status ? getVanityProjectName(project.status) : "Status"} search selection options={allStatusOptions} />
                </Segment>  
              </Grid.Column>    
            </Grid.Row>
            <Grid.Row verticalAlign='middle' columns={2}>
              <Grid.Column>
                <Segment basic>
                  <Button onClick={()=> setEditMode(false)} color='red'>Cancel</Button>
                </Segment>  
              </Grid.Column>
              <Grid.Column>
                <Segment basic>
                  <Button loading={loading} onClick={onSave} color='green'>Save</Button>
                </Segment>  
              </Grid.Column>  
            </Grid.Row>
          </Grid>
        )
        :
        (
          <Grid>
            <Grid.Row  verticalAlign='middle' columns={2}>
              <Grid.Column>
                <Segment basic>{language === "es" ? "Estado:" : "Status:"} {"status" in project && project.status? getVanityProjectName(project.status, language) : "None"}</Segment>  
              </Grid.Column>
              <Grid.Column>
                <Segment basic >
                  <Icon className="hoveredWidget" name="edit" onClick={onOpenEditMode}></Icon>
                </Segment>  
              </Grid.Column>    
            </Grid.Row>
          </Grid>
        )
      }
    </Segment>
    );
};

function getVanityProjectName(status = 'new', language = 'en'){
  let optionText = "new";
    switch(status){
      case 'importing':
        if(language === "es"){
          optionText = "Importando";
        }else{
          optionText = "Importing";
        }
        break;
      case 'new':
        if(language === "es"){
          optionText = "Nuevo";
        }else{
          optionText = "New";
        }
        break;
      case 'active':
        if(language === "es"){
          optionText = "Activo";
        }else{
          optionText = "Active";
        }
        break;
      case 'review':
        if(language === "es"){
          optionText = "En Revisión";
        }else{
          optionText = "In Review";
        }
        break;
      case 'completed':
        if(language === "es"){
          optionText = "Completo";
        }else{
          optionText = "Completed";
        }
        break;
      }
      return optionText;
}

export default ProjectStatusUpdater;