import React, {Fragment, useState, useEffect} from 'react';
import FileUploadAndReader from './FileUploadAndReader';
import {Grid, Segment,Button,Input, Select,Modal,Form,Progress} from 'semantic-ui-react';
import {Magnifier} from "react-image-magnifiers";
import DamageItem from './DamageItem';
import {orderedLabels} from "../services/defaultLabels";
import { v4 as uuidv4 } from 'uuid';
import CanvasEditor from './CanvasEditor';
import { saveAs } from 'file-saver';
import * as JSZip from 'jszip';

const RatingTool = ({browserLang,currentUser}) => {
	const imgUrl = "images/background-image-hero.jpg";
	let backgroundImg = `linear-gradient(45deg, rgba(0, 26, 255, 0.7) 1%, rgb(99, 97, 238) 30%, rgb(119, 99, 213) 69%, rgb(161, 103, 161) 100%),url(${imgUrl})`;
	const [newLabel, setNewLabel] = useState(null);
	const [newWidth, setNewWidth] = useState('');
	const [newHeight, setNewHeight] = useState('');
	const [parsedData, setParsedData] = useState(null);
	const [seenData, setSeenData] = useState({});
	const [originalWidth,setOriginalWidth] = useState(0);
	const [originalHeight,setOriginalHeight] = useState(0);
	const [modalOpen,setModalOpen] = useState(false);
	const [labelerName, setLabelerName] = useState("");
	const [projectName, setProjectName]  = useState("");

	useEffect(() => {
	    window.addEventListener('beforeunload', alertUser)
	    window.addEventListener('unload', handleTabClosing)
	    return () => {
	        window.removeEventListener('beforeunload', alertUser)
	        window.removeEventListener('unload', handleTabClosing)
	    }
	})

	const handleImageLoad = (width,height)=>{
			setOriginalWidth(width);
			setOriginalHeight(height);
	}

	const handleTabClosing = () => {
		alert("Are you sure?");
	    console.log("Tab closed");
	}

	const alertUser = (event:any) => {
	    event.preventDefault()
	    event.returnValue = '';
	    alert("Are you sure?");
	}
	const [selectedImageId, setSelectedImageId] = useState(null);
	const [selectedImageArr, setSelectedImageArr] = useState(null);

	const onNewWidth = (value)=>{
		if(value > originalWidth){
			alert("Damage width cannot be larger than image width");
			return;
		}
		setNewWidth(value);
	}
	const onNewHeight = (value)=>{
		if(value > originalHeight){
			alert("Damage height cannot be larger than image height");
			return;
		}
		setNewHeight(value);
	}

	const onAddLabel=()=>{
		if(newLabel === null || newWidth === "" || newHeight === ""){
			return;
		}
		const newDamage = {
			"id":uuidv4(),
			"label":newLabel,
			"width":newWidth,
			"height":newHeight,
			"originalImageWidth":originalWidth,
			"originalImageHeight":originalHeight
		};
		const updatedImageArr = [newDamage, ...selectedImageArr];
		setSelectedImageArr(updatedImageArr);		
		setParsedData({...parsedData,[selectedImageId]:updatedImageArr});
		setNewLabel(null);
		setNewHeight("");
		setNewWidth("");
	};
	const onDeleteLabel = (labelId)=>{
		const selectedImageCopy= selectedImageArr.filter((damage)=>{
			return !(damage.id === labelId);
		});
		setSelectedImageArr(selectedImageCopy);
		setParsedData({...parsedData,[selectedImageId]:selectedImageCopy});
	};

	let urls = [];
	let damages = [];
	
	if (parsedData && !(Object.keys(parsedData).length === 0)){
		urls = Object.keys(parsedData);
		if(selectedImageId){
			console.log("selectedImageId",selectedImageId);
			console.log("selectedImageArr",selectedImageArr);
			damages = selectedImageArr.map((damage, idx)=>{
				return(
					<DamageItem key={damage.id} label = {damage.label} width={damage.width} height={damage.height} onDelete={onDeleteLabel} id={damage.id}/>
					);
			});		
		}
	}

	let images = urls.map((imgUrl, idx)=>{
      return (
        <Magnifier
          key={idx}
          //style={{"maxWidth":"700px"}}
          imageSrc={imgUrl}
          imageAlt="image"
          mouseActivation="click" // Optional
          touchActivation="tap" // Optional
        />
        )
    });

    let allLabels = orderedLabels.map((labelObj)=>{
    	return {text: labelObj.name, value:labelObj.name};
    });

    const onNextImage = ()=>{
    	console.log("parsedData",parsedData);
		const selectedImageIndex = urls.indexOf(selectedImageId);
		setSeenData({...seenData,[selectedImageId]:selectedImageArr});
		if(selectedImageIndex < urls.length){
			setSelectedImageId(urls[selectedImageIndex+1]);
			setSelectedImageArr(parsedData[urls[selectedImageIndex+1]]);
		}
	};

	const onPrevImage = ()=>{
		const selectedImageIndex = urls.indexOf(selectedImageId);
		setSeenData({...seenData,[selectedImageId]:selectedImageArr});
		if(selectedImageIndex > 0){
			setSelectedImageId(urls[selectedImageIndex-1]);
			setSelectedImageArr(parsedData[urls[selectedImageIndex-1]]);
		}
	};

	const selectedImageUrl = urls[urls.indexOf(selectedImageId)];

	const onFileRead = (jsonData)=>{
		if(!jsonData){
			return;
		}
		//Assign unique IDs to all damages
		let readData = {...jsonData};
		for(const url in readData){
			const foundDamages =readData[url].map((dmg)=>{
				return{
					...dmg,
					"id":uuidv4()
				}
			}); 
			readData[url] = foundDamages;
		}
		console.log("readData",readData);
		setParsedData(readData);
		const urlList = Object.keys(readData);
		console.log("urlList",urlList);
		const firstImageUrl = urlList[0];
		const firstDamagesArr = readData[firstImageUrl];
		console.log("firstDamagesArr",firstDamagesArr);
		setSelectedImageArr(firstDamagesArr);
		setSelectedImageId(firstImageUrl);
	};

	const jsonDownload = (data,fileName="engin-ai-qa-file") =>{
		var zip = new JSZip();
      	var count = 0;
      	var zipFilename = `${fileName}`;
  	 	const json =JSON.stringify(data);
        const blob = new Blob([json],{type:'application/json'});
        saveAs(blob, zipFilename);
	};

	const copyPrevDamages=()=>{
		const currentImgIdx = urls.indexOf(selectedImageId);
		if(currentImgIdx === 0){
			return;
		}
		const prevImgId = urls[urls.indexOf(selectedImageId) - 1];
		const prevDamages = parsedData[prevImgId].map((obj)=>{
			return{
				"id":uuidv4(),
				"label":obj.label,
				"width":obj.width,
				"height":obj.height,
				"originalImageWidth":originalWidth,
				"originalImageHeight":originalHeight
			}
		});
		const updatedImageArr = [...prevDamages, ...selectedImageArr];
		setSelectedImageArr(updatedImageArr);		
		setParsedData({...parsedData,[selectedImageId]:updatedImageArr});
	}

	const handleModalSave = ()=>{
		jsonDownload(seenData,`${labelerName}-${projectName}`);
		setModalOpen(false);
	};

	 const handleChange = (e, { name, value }) =>{
	 	switch(name) {
		  case "labeler_name":
		    setLabelerName(value);
		    break;
		  case "project_name":
		    setProjectName(value);
		    break;
		  default:
		    return;
		}
	 };

	if(!currentUser || (currentUser && !currentUser.isAuthenticated)){
		return (
			<>
			<header className="App-hero" style={{
		          "minHeight":"75px",
		          "backgroundImage":backgroundImg,
		          "color":"white"
		        }}>
			</header>
			<h1>Please Login First</h1>
			</>
			);
	}

	return (
<Fragment>
	<header className="App-hero" style={{
          "minHeight":"75px",
          "backgroundImage":backgroundImg,
          "color":"white"
        }}>
	</header>
	  <Grid style={{marginTop:"10px"}}>
	    {!parsedData ?
	    	(<Grid.Row textAlign="left" style={{marginLeft:"40px",marginRight:"40px"}}>
	    			<h1>Upload JSON File</h1>
	    		    <FileUploadAndReader label="Upload a JSON file" callback={onFileRead} isJSON={true}/>
	    	    </Grid.Row>)
	    	:
	    	(
	    		<Grid.Row columns={2} verticalAlign="middle">
			    	<Grid.Column width={13}>
			    		<Grid.Row>
			    			<Progress attached='top' indicating percent={Math.floor((urls.indexOf(selectedImageId)+1)/urls.length*100)} />
			    			<div>{`${urls.indexOf(selectedImageId)+1} of ${urls.length}`}</div>
				    		<CanvasEditor imageUrl={selectedImageUrl} onImageLoad={handleImageLoad}/>
			    		</Grid.Row>
			    		<Grid.Row columns={2}>
					    	<Grid.Row>
					    		<Button.Group>
								    <Button disabled={urls.indexOf(selectedImageId) ===0} labelPosition='left' icon='left chevron' content={browserLang === "es"? "Atrás" :'Back'} onClick={()=>onPrevImage()}/>
								    <Button disabled={urls.indexOf(selectedImageId) === (urls.length -1)} labelPosition='right' icon='right chevron' content={browserLang === "es"? "Siguiente" : 'Forward'} onClick={()=>onNextImage()}/>
								  </Button.Group>
					    	</Grid.Row>
					    	<Grid.Row style={{height:"100px"}}>
					    			<Input type='text' placeholder='Search...' action>
									    <Select compact search selection options={allLabels} placeholder={browserLang === "es" ? "Agregar Etiqueta" :"Add labels"} value={newLabel} onChange={(e,{value})=>setNewLabel(value)}/>
									    <Input placeholder={browserLang === "es" ?"Ancho": "Width"} value={newWidth} onChange={(e,{value}) => onNewWidth(value)}/>
									    <Input placeholder={browserLang === "es" ?"Alto": "Height"} value={newHeight} onChange={(e,{value})=> onNewHeight(value)}/>
									    <Button color="green" disabled={(newLabel === null || newWidth === "" || newHeight === "")} onClick={()=>onAddLabel()}>{browserLang === "es" ?"Agregar": "Add"}</Button>									    
									  </Input>
									  <Button style={{marginLeft:"20px"}} disabled={urls.indexOf(selectedImageId) ===0} color="yellow" onClick={copyPrevDamages}>{browserLang === "es" ?"Copiar Daños de Imagen Anterior": "Copy Previous Image Damages"}</Button>

					    	</Grid.Row>
					    	<Grid.Row>
							    {/*<Button color="teal" onClick={()=>jsonDownload(parsedData)}>Save Full JSON</Button>*/}
							    <Button color="teal" onClick={()=>setModalOpen(true)}>{browserLang === "es" ?"Guardar en JSON": "Save JSON"}</Button>
					    	</Grid.Row>
					    </Grid.Row>
			    	</Grid.Column>
			    	<Grid.Column width={3}>
			    		<Segment style={{"position":"fixed", top:"79px",overflowY:"scroll","maxHeight":"70vh","maxWidth":"219px"}}>
			    			<h2>{browserLang === "es" ? "Reporte" : "Report"}</h2>
			    			{damages}
			    		</Segment>
			    	</Grid.Column>	  
			    	<Modal
			          closeOnEscape={true}
			          closeOnDimmerClick={true}
			          open={modalOpen}
			          onClose={()=>setModalOpen(false)}
			        >
			          <Modal.Header>Download JSON</Modal.Header>
			          <Modal.Content>
			            <Form onSubmit={handleModalSave}>
				            <Form.Input
				              placeholder='Labeler Name'
				              name='labeler_name'
				              value={labelerName}
				              onChange={handleChange}
				            />
				            <Form.Input
				              placeholder='Project Name'
				              name='project_name'
				              value={projectName}
				              onChange={handleChange}
				            />
				            <Form.Button color="green" disabled={projectName === "" || labelerName === ""} content='Submit'/>
				        </Form>
			          </Modal.Content>
			        </Modal>  	
			    </Grid.Row>
	    	)
		}
		  </Grid>
</Fragment>
	);
}
export default RatingTool;
