import React, { Component, Fragment } from 'react';
import { Menu, Segment, Image, Responsive, Dropdown } from 'semantic-ui-react';
import Logo from "./images/white-logo-plus-name-final-01.svg";
import Burger from "./images/burger-icon-01.svg";
import './App.css';
import { HashLink as Link } from 'react-router-hash-link';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { logout } from "./store/actions/auth";

class Navbar extends Component {
  constructor(props){
    super(props);
    this.state = {
        mobileNav : false,

      }
     
  }
  targetElement = null;
  



   componentDidMount() {
    // 2. Get a target element that you want to persist scrolling for (such as a modal/lightbox/flyout/nav). 
    // Specifically, the target element is the one we would like to allow scroll on (NOT a parent of that element).
    // This is also the element to apply the CSS '-webkit-overflow-scrolling: touch;' if desired.
    this.targetElement = document.querySelector('.mobile-overlay');
  }
 componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    
    this.setState({mobileNav:false});
    clearAllBodyScrollLocks();
  }

  navbarOn = ()=>{
    let mobile = this.state.mobileNav;
    //console.log(mobile);
    
    
    this.setState({mobileNav:!mobile});
    mobile = !mobile;
    if(mobile){
      disableBodyScroll(this.targetElement);  
    }else{
      clearAllBodyScrollLocks();
    }
  }

  onLinkClick = (e)=>{
    // console.log(e.target.name);
    // console.log("clicked");
    // console.log(e);

    e.preventDefault();
    let l = e.target.name;
    clearAllBodyScrollLocks();  
    this.setState({mobileNav:false});
    this.props.history.push(l);



  }

  render() {
    const browserLang = this.props.language? this.props.language : "en";
    const spanish = browserLang.indexOf("es") > -1;
    const indSpecDropdownOptions = [
      { key: 1, text: 'Pavement Damage Analysis', value: 1, target:"_self",href:"#/pavements-analysis", className: "navDropDownItem" },
    ]
    let mobileNav = this.state.mobileNav;
    let menuOpen = mobileNav? "open" : "closed";
    let mobileClassname = "mobile-overlay-closed";
    let location = this.props.location;
    if(mobileNav){
      mobileClassname = "mobile-overlay-open";
    }
    // console.log(location);
    return (
      <Fragment>
        <Responsive {...Responsive.onlyComputer}>
        <Segment className="App-nav" inverted style={{margin:"0",position:"absolute","width":"100%", "zIndex":"100"}}>
        <Menu inverted secondary>
        <Menu.Item>
        <a href={this.props.currentUser && this.props.currentUser.isAuthenticated && this.props.currentUser.user.role === "admin" ? "/#/dashboard" : "/#" }>
          <Image size="small" alt="engin.ai logo" src={Logo}>
          </Image>
        </a>
        </Menu.Item>         
          <Menu.Menu position='right'>
          <Menu.Item
            name='Data-labeling'
            active={location.pathname === "/data-labeling"}
            href="#/data-labeling"
            target="_self"
          >
            {browserLang ==="en"? "Data Labeling" : "Etiquetado de Datos"}
          </Menu.Item>
          {this.props.currentUser.isAuthenticated &&(
            <Menu.Item
            name='Data-labeling'
            active={location.pathname === "/label"}
            href="#/label"
            target="_self"
          >
          {browserLang ==="en"? "AI-assisted Rating" : "Herramienta de Etiquetado"}
          </Menu.Item>
            ) 
            }
           <Menu.Item>
            <Dropdown
              item
              text={browserLang ==="en"? "Industry-Specific Solutions" : "Soluciones Especializadas"}
              options={indSpecDropdownOptions}
            />
          </Menu.Item>
          {this.props.currentUser && this.props.currentUser.isAuthenticated?
            (
              <Menu.Item
                name='Logout'
                style={{
                  "cursor":"pointer"
                }}
                onClick={()=>{
                  this.props.logout()
                  this.props.history.push("/");
                }}               
              >
                {browserLang === "en" ? "Logout": "Cerrar Sesión"}
              </Menu.Item>
              )
              :
            (
              <Menu.Item
                name='Login'
                onClick={()=>{
                  this.props.history.push("/signin");
                }}   
              >
                {browserLang === "en" ? "Login": "Iniciar Sesión"}
              </Menu.Item>
              )
          }
        </Menu.Menu>
        </Menu>
         
      </Segment>
       </Responsive>
       <Responsive {...Responsive.onlyMobile}>
       <div style={{"overflow":"hidden","maxHeight":"100vh","zIndex":"200"}}>
         <div className={`mobile-overlay ${mobileClassname}`} style={{"maxHeight":"150vh"}}></div>
       {mobileNav ? 
        <Fragment>
          
        <div style={{"height":"100vh","zIndex":"120", "position":"absolute","width":"100%"}}>
         <Segment className="App-nav" inverted >
        <Menu inverted secondary>
          <Menu.Item>
          
            <Image onClick={this.onLinkClick} name={this.props.currentUser && this.props.currentUser.isAuthenticated && this.props.currentUser.user.role === "admin" ? "/#/dashboard" : "/#" } size="small" src={Logo}>
              
            </Image>
            
            
          </Menu.Item>
          <Menu.Menu position="right">
            <Menu.Item>
               <Image onClick={this.navbarOn} style={{"width":"38px", "height":"29px"}} size="tiny" src={Burger}/>
            </Menu.Item>
          </Menu.Menu>
        </Menu>

        <Menu vertical inverted className="menu-item-group">
          <Menu.Item className={`mobile-menu-item ${menuOpen}`} href="#/data-labeling" >
           {spanish? "Etiquetado de Datos":"Data Labeling"} 
          </Menu.Item>
          <Menu.Item className={`mobile-menu-item ${menuOpen}`} href="#/pavements-analysis" >
            {spanish?"Análisis de Daños en Pavimentos":"Pavement Damage Analysis"} 
          </Menu.Item>
          <Menu.Item className={`mobile-menu-item ${menuOpen}`}>
          <Link to="/#contact-us">
            {spanish? "Contáctenos":"Contact Us"} 
          </Link>
            
          </Menu.Item>
        </Menu>

        
         
      </Segment>
       </div>
        </Fragment>
        
          : null
       }
       </div>
       
       
        <Segment className="App-nav" inverted style={{margin:"0",position:"absolute","width":"100%","zIndex":"100"}}>
        <Menu inverted secondary>
          <Menu.Item>
          <Link to="/#" target="_self">
            <Image size="small" alt="engin.ai logo" src={Logo}>
              
            </Image>
          </Link>
            
            
          </Menu.Item>
          <Menu.Menu position="right">
            <Menu.Item>
               <Image onClick={this.navbarOn} style={{"width":"38px", "height":"29px"}} size="tiny" src={Burger}/>
            </Menu.Item>
          </Menu.Menu>
        </Menu>
        
       
      </Segment>
      

       </Responsive>
      </Fragment>
       
      
    )
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.currentUser,
    errors: state.errors,
    language:state.language
  };
}


export default withRouter( connect(mapStateToProps, {logout})(Navbar));